.vara-butiker {
    min-height: calc(100vh - 126px);
    header {
        box-shadow: none;
        z-index: 103;
        background: #f6f6f6;
    }

    .letter-menu {
        position: sticky;
        background: linear-gradient(0deg, #ffffff, #f6f6f6);
        box-shadow: 0px 0px 20px rgb(0 0 0 / 15%);
        z-index: 102;
        top: 94px;
        height: 44px;
        user-select: none;
        @media(max-width: 1300px){
            top: 50px;
        }

        .container {
            display: flex;
            align-items: center;
            height: 100%;

            @media (max-width: 1300px) {
                justify-content: space-between;
                padding: 0;
            }

            @media (max-width: 900px) {
                overflow-x: scroll;
            }

            &::-webkit-scrollbar {
                background: #fff;
                height: 1px !important;
            }

            &::-webkit-scrollbar-thumb {
                background: #fff;
            }
        }

        a {
            font-weight: 500;
            padding-left: 1rem;
            padding-right: 1rem;
            height: 100%;
            width: 100%;
            display: flex;
            border-right: 4px solid #f6f6f6;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 0.9rem;
            cursor: pointer;
            color: black;
            &:hover {
                background: #f6f6f6;
            }

            &:last-child{
                border: 0;
            }

            @media (max-width: 1100px) {
                padding-left: 0.75rem;
                padding-right: 0.75rem;
            }
        }
    }

    .category-filter {
        background: #222;
        position: relative;
        z-index: 100;
        top: 0;
        height: 36px;
        display: none !important;

        ul {
            list-style: none;
            display: flex;
            height: 100%;
            @media (max-width: 1300px) {
                overflow-x: scroll;
                padding: 0;
            }

            &::-webkit-scrollbar {
                height: 1px !important;
                background: #111;
            }

            &::-webkit-scrollbar-thumb {
                background: #111;
            }

            li {
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: 0 0.75rem;
                cursor: pointer;
                transition: 0.3s ease-in-out;
                white-space: nowrap;
                font-size: 0.9rem;

                &:last-child {
                    margin-right: 0;
                }

                &.checked {
                    background: #333;
                }

                &:hover {
                    @media (min-width: 900px) {
                        background: #333;
                    }
                }
            }
        }
    }

    main {
        min-height: calc(100vh - 180px);

        h2 {
            margin-top: 0;
            margin-bottom: 2rem;

            @media (max-width: 920px) {
                margin-bottom: 1rem;
            }
        }

        > div {
            margin: 0 auto;
            max-width: 1500px;
            padding-left: 2rem;
            padding-right: 2rem;
            display: flex;

            @media (max-width: 920px) {
                flex-direction: column;
            }
        }

        aside {
            margin-top: -4px;
            margin-left: 3rem;
            max-width: 320px;
            width: 100%;
            align-self: flex-start;
            position: sticky;
            top: calc(88px + 5rem);
            @media (max-width: 950px) {
                display: none;
            }

            h2 {
                margin: 0;
                height: 88px;
                padding-left: 2rem;
                font-size: 1.6rem;
                user-select: none;
                line-height: 1.25;
                position: absolute;
                z-index: 99;
                background: white;
                display: flex;
                align-items: center;
                right: 0;
                left: 0;
                top: 0;
            }

            .swiper-button-next,
            .swiper-button-prev {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                transition: 0.3s ease-in-out;
                background: #e6e6e6;
                top: 3rem;
                z-index: 150;

                &:hover {
                    background: #cdcdcd;
                }

                &:after {
                    font-size: 1rem;
                    font-weight: 600;
                    color: #454545;
                }
            }

            .swiper-button-next {
                transform: rotate(90deg);
                right: 2rem;
            }

            .swiper-button-prev {
                transform: rotate(90deg);
                right: 5rem;
                left: unset;
            }

            .swiper-container {
                padding-top: 5.5rem;
            }

            .swiper-wrapper {
                max-height: 444px;
                height: 100%;
            }

            .swiper-slide {
                display: flex;
                align-items: center;
                background: white;
                margin-top: 4px;
                padding: 1rem 2rem;
                cursor: pointer;
                width: unset;

                &:hover {
                    background: #ffffff75;

                    .heading {
                        text-decoration: underline;
                    }
                }

                > div {
                    &:nth-child(1) {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        font-size: 1.2rem;
                        font-weight: 500;
                        border: 2px solid rgba(black, 0.1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 1rem;
                    }

                    svg {
                        height: 25px;
                        max-width: 50%;
                    }
                }

                p,
                h4 {
                    margin: 0;
                }

                p {
                    font-size: 1rem;
                }
            }
        }

        .butiker-wrap {
            padding-left: 3rem;
            width: 100%;
            position: relative;
            @media (max-width: 920px) {
                padding: 0;
                border-left: 0;
            }

            &:before {
                content: "";
                height: calc(100% + 6.5rem);
                width: 2px;
                background: #e6e6e6;
                position: absolute;
                left: 0;
                bottom: 0;
                top: -8.5rem;
            }

            > div {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                flex-direction: column;
                @media (max-width: 920px) {
                    margin-left: 50px;
                }
            }

            .section-letter {
                margin-bottom: 2rem;
                padding-bottom: 2rem;
                @media (max-width: 1300px) {
                    margin-bottom: 1.5rem;
                    padding-bottom: 1.5rem;
                }

                &:not(.active) {
                    display: none;
                }

                &:last-child {
                    border: 0;
                    padding: 0;
                    margin: 0;
                }

                .letter {
                    position: sticky;
                    top: 148px;
                    height: 40px;
                    width: 40px;
                    margin-left: -71px;
                    color: black;
                    border-radius: 50%;
                    background: #f6f6f6;
                    border: 2px solid #e6e6e6;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.2rem;
                    font-weight: 500;
                    margin-bottom: -50px;
                }

                .stores {
                    display: flex;
                    flex-wrap: wrap;

                    article {
                        margin: 2px 0;
                        margin-right: 4px;
                        width: calc(100% / 4 - 2rem - 4px);
                        background: white;
                        padding: 0.5rem 1rem;
                        cursor: pointer;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 500;
                        opacity: 1;

                        &.disabled {
                            opacity: 0.1;
                            @media (max-width: 1300px) {
                                display: none;
                            }
                        }

                        span {
                            font-weight: 400;
                            color: #757575;
                        }

                        @media (max-width: 1400px) {
                            width: calc(100% / 3 - 2rem - 4px);
                        }

                        @media (max-width: 1100px) {
                            width: calc(100% / 2 - 2rem - 4px);
                        }

                        @media (max-width: 600px) {
                            width: 100%;
                            margin-right: 0;
                        }

                        &:hover {
                            background: #ffffff75;
                        }
                    }
                }
            }
        }
    }
}
