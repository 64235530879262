.deal-item,
.advertisement-item {
  article {
    margin: 2px;
    box-sizing: border-box;
    display: block;
    position: relative;
    background-color: white;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    height: calc(100% - 4px);
    // min-height: 356px;

    &:hover {
      .sizes {
        opacity: 1;
      }

      .favorite-button {
        opacity: 1;
      }

      .quickview-button {
        opacity: 1;
      }
    }

    .sizes {
      position: absolute;
      bottom: 7.2rem;
      left: 1rem;
      overflow: hidden;
      z-index: 149;
      transition: 0.3s ease-in-out;
      max-width: calc(100% - 2rem);
      opacity: 0;

      @media (max-width: 600px) {
        display: none;
      }

      ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        border: 1px solid #e6e6e6;
        background: white;
        position: relative;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          height: 0px;
        }

        li {
          font-weight: 400;
          height: 26px;
          padding: 0 6.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #e6e6e6;
          font-size: 12px;
          font-weight: 500;
          color: #7c7c7c;
          user-select: none;
          white-space: nowrap;

          &:last-child {
            border-right: 0;
          }
        }
      }
    }

    .badge-wrapper {
      position: absolute;
      top: 2.5rem;
      left: 0rem;
      display: flex;
      flex-direction: column;
      z-index: 20;

      > div {
        margin-bottom: 0.5rem;
        @media (max-width: 600px) {
          margin-bottom: 0.3rem;
        }
      }
    }

    .discount-badge {
      font-weight: 500;
      font-size: 12px;
      background: #e41513;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 40px;
      color: #fff;
      z-index: 2;
    }

    .discount-text {
      padding-right: 0.5rem;
    }

    .not-in-stock {
      font-weight: 500;
      font-size: 12px;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      color: #fff;
      z-index: 2;
      padding: 0 0.25rem;

      .bell {
        height: 1rem;
        width: 1rem;
        margin-left: 0.25rem;
        path {
          fill: white;
        }
      }
    }

    .order-item {
      font-weight: 500;
      font-size: 12px;
      background: #e96100;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      color: #fff;
      z-index: 2;
      padding: 0 0.25rem;

      .calendar {
        height: 1rem;
        width: 1rem;
        margin-left: 0.25rem;
        path {
          fill: white;
        }
      }
    }

    .free-shipping {
      background: #cef7e7;
      color: rgba(0, 0, 0, 0.55);
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0rem;
      top: 4.5rem;
      height: 25px;
      width: 50px;
    }

    .vintage-product {
      background: #f7eace;
      color: rgba(0, 0, 0, 0.55);
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 50px;
    }

    .quickview-button {
      background: #f3f3f3;
      z-index: 2;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      top: unset;
      transition: 0.3s ease-in-out;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 920px) {
        display: none;
      }

      svg {
        width: 20px;
        fill: #454545;
      }

      &:hover {
        svg {
          fill: #e41513;
        }
      }
    }

    .favorite-button {
      background: #f3f3f3;
      z-index: 2;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      position: absolute;
      top: 2.5rem;
      transition: 0.3s ease-in-out;
      right: 1rem;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 420px) {
        opacity: 1;
      }

      &.favorised {
        opacity: 1;

        svg {
          path {
            &:first-child {
              fill-opacity: 0;
            }

            &:last-child {
              fill-opacity: 100;
            }
          }
        }
      }

      &:hover {
        svg {
          fill: #e41513;
        }
      }

      svg {
        fill: #9c9c9c;
        width: 20px;

        path {
          transition: 0.3s ease-in-out;

          &:first-child {
          }

          &:last-child {
            fill-opacity: 0;
          }
        }
      }
    }

    .deal-header {
      padding: 1rem 1rem 0.5rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      min-height: 25px;
      z-index: 1;

      @media (max-width: 400px) {
        padding: 1rem 0rem 0.5rem;
      }

      a {
        margin-top: -7px;
      }

      svg,
      img {
        height: fit-content;
        max-height: 20px !important;
        max-width: 120px !important;
        width: 100%;
      }
    }

    .image-box {
      padding: 0 10px;

      @media (max-width: 370px) {
        padding: 0 8px;
      }
    }

    .content-box {
      padding: 0 1rem;
      transition: 0.3s ease-in-out;

      @media (max-width: 370px) {
        padding: 0 0.5rem;
      }
    }

    .price-box {
      padding: 0 1rem 1rem 1rem;

      @media (max-width: 370px) {
        padding: 0 0.5rem 0.5rem 0.5rem;
      }

      .prices {
        display: flex;
        align-items: center;
        transition: 0.3s ease-in-out;

        .o-price {
          text-decoration: line-through;
          font-size: 0.9rem;
          margin-left: 10px;
        }

        .s-price {
          font-weight: 500;
          border-bottom: 2px solid #e41513;
          font-size: 1.2rem;

          // @media (max-width: 550px) {
          //   font-size: 0.9rem;
          // }
        }
      }
    }

    .price-save {
      margin-top: 0.2rem;
      font-size: 0.9rem;
      color: #757575;
      transition: 0.3s ease-in-out;
    }
  }

  .image-box {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .deal-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: black;
    line-height: 1.2;
    font-size: 1rem;
    font-weight: 500;
    min-height: 40px;
    margin-top: 10px;
    margin-bottom: 5px;
    width: calc(100% - 2rem);
    margin-left: 1rem;
    word-break: break-word;

    @media (min-width: 1400px) {
      font-size: 1.1rem;
      min-height: 43px;
    }

    @media (max-width: 370px) {
      margin-left: 0.5rem;
      width: calc(100% - 1rem);
      max-width: calc(100% - 3rem);
    }

    @media (max-width: 350px) {
      max-width: 125px;
    }
  }
}
