.cookies {
    min-height: calc(100vh - 126px);

    .arabic {
        direction: rtl;

        section {
            border-left: 0;
            border-right: 2px solid #e6e6e6;
            padding-left: 0;
            padding-right: 2rem;
        }
    }

    .arabicflag {
        .st0 {
            fill: #007a3d;
        }
        .st1 {
            fill: #ffffff;
        }
    }

    .dropbtn {
        color: black;
        padding: 0.75rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;
        border: 0;
        font-weight: 600;
        font-size: 0.9rem;
        letter-spacing: 0.02rem;
        width: 85px;

        svg {
            margin-left: 0.5rem;
            fill: #9c9c9c;
            height: 16px;
            -webkit-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;

            &:first-child {
                margin-left: 0;
                height: 20px;
            }
        }
    }

    /* The container <div> - needed to position the dropdown content */
    .dropdown {
        position: relative;
        display: inline-block;
        position: absolute;
        right: 2rem;
        top: 30px;
        @media (max-width: 800px) {
            right: 0rem;
        }
    }

    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #111;
        z-index: 1;
        overflow: hidden;
        border-top: 0;
        width: 60px;
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-top: -1px;
    }

    /* Links inside the dropdown */
    .dropdown-content {
        &__option {
            color: black;
            padding: 12px 8px;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 20px;
            }
        }
    }

    /* Change color of dropdown links on hover */
    .dropdown-content {
        &__option:hover {
            background-color: #333;
            cursor: pointer;
        }
    }

    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
        display: block;
    }
}
