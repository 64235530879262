.switch {
    display: flex;
    // place-content: center;
    // margin: 1rem 0 0.25rem 0;
    // padding-left: 25.5px;
    // font-size: 1.3rem;
    color: white;

    a {
        color: white;
        height: 1.15em;
    }

    .sign-up-thank-you {
        display: flex;
    }

    span[role="img"] {
        margin-left: 0.3rem;
    }

    // .status-wrapper {
    //     display: flex;
    //     height: 30px;
    //     align-items: center;
    //     // justify-content: space-between;
    //     padding: 0 0.5rem;
    //     justify-content: flex-end;

    //     &.checked {
    //         justify-content: flex-start;
    //     }
    // }

    /* toggle in label designing */
    .toggle {
        position: relative;
        display: inline-block;
        // width: 68px;
        // height: 30px;
        height: 20px;
        width: 34px;
        background-color: #b2b2b2;
        border-radius: 30px;
        cursor: pointer;
    }

    /* After slide changes */
    .toggle:after {
        content: "";
        position: absolute;
        // width: 22px;
        // height: 22px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: white;
        top: 4px;
        left: 5px;
        transition: 0.3s ease-in-out;
    }

    /* Toggle text */
    p {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
    }

    /* Checkbox checked effect */
    .checkbox:checked + .toggle::after {
        left: 18px;
    }

    /* Checkbox checked toggle label bg color */
    .checkbox:checked + .toggle {
        background-color: #E41513;
    }

    /* Checkbox vanished */
    .checkbox {
        display: none;
    }
}
