.testimonials-carousel {
    .stars {
        font-size: 1.2rem;
        padding: 0.25rem 0;
    }

    &__article {
        margin: 0.25rem;
        padding: 0.25rem;
        border-radius: 5px;
        border: 1px solid #efeeee;
        min-height: 99px;
    }

    &__paragraph {
        font-size: 0.75rem;
        padding: 0.25rem;
    }

    &__reviewer {
        display: flex;
        padding: 0.25rem;
    }

    &__reviewer__name {
        font-size: 0.75rem;
        color: #565655;
    }

    &__reviewer__location {
        display: flex;
        font-size: 0.75rem;
        margin-left: 1rem;
        align-items: center;
        color: #565655;
    }

    &__svg {
        height: 0.75rem;
        width: 0.75rem;
        fill: #565655;
    }
}
