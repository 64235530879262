.kontakta-oss {
    background: #f6f6f6;
    input,
    select,
    textarea {
        border: 2px solid black;
        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: black;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: black;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: black;
        }
    }

    header {
        padding: 44px 2rem 0rem 2rem;
        min-height: calc(55vh - 44px);
        // background: linear-gradient(147deg, #c62128, #a00000, #c62128, #a00000);
        background-size: 400% 400%;
        animation: Gradient 10s ease infinite;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background: #8b0000;

        @media (max-width: 912px) {
            min-height: 100%;
            height: 220px;
            max-height: 220px;
        }
    }

    #intro {
        background: #f6f6f6;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: calc(30vh - 6rem);
        padding-left: 2rem;
        padding-right: 2rem;

        h2 {
            max-width: 640px;
            font-weight: 500;
            margin: 0;

            span {
                display: block;
                margin-top: 1rem;
                font-size: 1.2rem;
                font-weight: 400;

                i {
                    font-style: italic;
                    display: block;
                    margin-top: 0.5rem;
                    min-height: 40px;

                    @media (max-width: 400px) {
                        min-height: 70px;
                    }
                }
            }
        }
    }

    #social-media {
        background: #f6f6f6;
        > div {
            padding: 1px;
            display: flex;
            max-width: 1000px;
            margin: 0 auto;

            @media (max-width: 900px) {
                flex-wrap: wrap;
                justify-content: center;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 150px;
                margin: 2px;
                width: calc(100% / 3 - 4px);
                color: white;
                font-size: 1.3rem;
                font-weight: 500;

                @media (max-width: 900px) {
                    height: 120px;

                    &:nth-child(3) {
                        border-right: 0;
                    }

                    &:nth-child(4),
                    &:nth-child(5) {
                        border-bottom: 0;
                    }
                }

                @media (min-width: 901px) {
                    width: calc(100% / 3);
                }

                &:hover {
                    svg {
                        opacity: 0.5;
                    }
                }
            }

            svg {
                width: 50px;
                height: 50px;

                path,
                circle,
                rect,
                polygon {
                    fill: black;
                }
            }
        }
    }
    
    .the-company {
        &__heading {
            color: white;
            padding: 1rem 2rem;
            text-align: center;
            margin: 0;
            background: #8b0000;
        }

        &__content{
            max-width: 1000px;    
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            place-content: space-between;
            padding-left: 2rem;
            padding-right: 2rem;
            flex-wrap: wrap;
            padding: 2.5rem 3rem;

            @media (min-width: 700px) {
                flex-direction: row;
                align-content: unset;
                padding: 0;
            }
        }

        &__content__item{
            display: flex;
            padding: 0.5rem 0;

            @media (min-width: 700px) {
                place-content: center;
                padding: 3rem;
            }
        }
    }

    #maps {
        background: #e0e0e0;

        > div {
            padding: 2px;

            > div {
                display: flex;

                @media (max-width: 700px) {
                    flex-direction: column;
                }

                > div {
                    margin: 2px;

                    &:nth-child(1) {
                        height: 350px;
                        width: 350px;
                        background: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;

                        @media (max-width: 1100px) {
                            width: 250px;
                        }

                        @media (max-width: 700px) {
                            width: calc(100vw - 8px);
                            height: 150px;
                        }

                        h4 {
                            font-weight: 500;
                            font-size: 1.6rem;
                            margin: 0;

                            @media (max-width: 700px) {
                                font-size: 1.3rem;
                            }
                        }

                        p {
                            font-size: 1.2rem;
                            margin: 0;

                            @media (max-width: 700px) {
                                font-size: 1.1rem;
                            }
                        }
                    }

                    &:nth-child(2) {
                        width: calc(100vw - 350px);
                        height: 350px;

                        @media (max-width: 1100px) {
                            width: calc(100vw - 250px);
                        }

                        @media (max-width: 700px) {
                            width: calc(100vw - 8px);
                        }
                    }
                }
            }
        }

        .leaflet-container {
            font-family: futura-pt, sans-serif;
        }

        .leaflet-bottom.leaflet-right {
            z-index: 990;
        }

        .leaflet-popup-content-wrapper {
            font-size: 1rem;
            background: rgba(#222, 0.75);
            color: white;

            > div {
                min-width: 180px !important;
            }
        }

        .leaflet-popup-tip-container,
        .leaflet-popup-close-button {
            display: none !important;
        }
    }

    #contact-form {
        color: white;

        > div {
            max-width: 700px;
            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 6rem;
            padding-bottom: 6rem;
            margin: 0 auto;

            @media (max-width: 900px) {
                padding-top: 3rem;
                padding-bottom: 3rem;
            }
        }

        .input-fields {
            display: flex;
            position: relative;

            svg {
                position: absolute;
                right: 1.5rem;
                top: 15px;
            }

            #subject-picker {
                border-radius: 3rem;
                background: transparent;
                width: 100%;
                height: 55px;
                margin-bottom: 2rem;
                transition: 0.3s ease-in-out;
                font-size: 1.1rem;
                font-weight: 400;
                color: black;
                @media (max-width: 500px) {
                    margin-bottom: 1rem;
                }

                // &:focus {
                //     outline: none;
                //     background: transparent;
                //     border: 2px solid white;
                //     color: white;
                // }

                option {
                    background: white;
                    color: black;
                    border: 0;
                }
            }

            textarea.error,
            input.error {
                border: 2px solid #e41513;
            }

            input {
                width: 55%;
                margin-bottom: 2rem;
                margin-right: 2rem;

                @media (max-width: 500px) {
                    margin-right: 1rem;
                    margin-bottom: 1rem;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:focus {
                    &::placeholder {
                        color: black;
                    }
                }
            }

            textarea {
                &:focus {
                    &::placeholder {
                        color: black;
                    }
                }
            }
        }

        .gdpr-wrap {
            margin-top: 2rem !important;
            margin-left: auto;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media (max-width: 990px) {
                margin: 0 auto;
                margin-bottom: 0.5rem;
            }

            @media (max-width: 380px) {
                flex-direction: column;
            }

            > div {
                margin-right: 2rem;
                text-align: right;
                max-width: 180px;
                width: 100%;
                color: black;

                @media (max-width: 380px) {
                    margin: 0 0 2rem 0;
                    text-align: center;
                }

                a {
                    color: #8b0000;
                    text-decoration: underline;

                    &:hover {
                        color: #8b0000;
                    }
                }
            }
        }
    }

    #company-details {
        background: #8b0000;
        color: white;
        font-weight: 500;
        text-align: center;
        padding: 1rem 0;
        margin: 0;

        ul {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 1000px;

            li {
                font-size: 1.2rem;

                @media (max-width: 700px) {
                    margin-bottom: 1rem;
                    width: 50%;

                    &:nth-child(3),
                    &:nth-child(4) {
                        margin: 0;
                    }
                }

                @media (max-width: 500px) {
                    &:nth-child(1),
                    &:nth-child(2) {
                        display: none;
                    }
                }

                a {
                    color: white;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
