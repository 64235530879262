.media-section {
    background: #8b0000;
    padding: 1rem 0;

    svg {
        height: 42px;
        &#breakit,
        &#e-commerce-success,
        &#handelstrender,
        &#butikstrender {
            .cls-1 {
                fill: #fff;
            }
        }
        @media (max-width: 440px) {
            height: 30px;
        }
    }

    &__first-sentence {
        color: rgb(184 184 184);
        text-transform: uppercase;
        font-size: 0.8rem;
    }
    &__parts {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    &__part {
        display: flex;
        place-content: center;
        min-width: calc(25% - 1rem);
        height: 42px;
        padding: 0.25rem 0.5rem;
        // place-items: center;

        @media (max-width: 900px) {
            width: calc(50% - 1rem);
        }
    }
}
