// #jiroy {
//   &:not(.category-page):not(.search-page):not(.shopping) {
//     .bg-layer {
//       display: none !important;
//     }
//   }
// }

// MOBILE FILTER DRAWER
.filter-drawer {
  .MuiDrawer-paper {
    background: #f6f6f6;
    box-shadow: none;
    min-width: 280px;
    max-width: 280px;

    #klader,
    #byxor,
    #bh {
      padding-left: 0.25rem;
    }
  }
}
.category-header {
  margin-top: 20px;
  color: rgba(80, 80, 80, 1);
  @media (max-width: 600px) {
    margin-top: 0px;
  }
  h1 {
    color: rgba(80, 80, 80, 1);
  }
  p{
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

.filter-drawer-wrapper__collection-filter {
  margin-top: 50px;

  @media (max-width: 991px) {
    margin-top: 100px;
  }

  &__header {
    background: white;
    color: black;
    padding: 10px 1.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  &__item {
    padding: 0.75rem 0.5rem;
    display: flex;
    align-items: center;
  }

  &__item__text {
    margin-left: 1rem;
  }
}

.filter-drawer-wrapper {
  // margin-top: 50px;
  padding: 0;

  .remove-filter-button-mobile {
    display: none !important;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    label {
      padding: 0.75rem 1.5rem;
      width: calc(100% - 3rem);

      &:hover {
        background: white;
      }
    }
  }

  button.remove-filter-button-mobile {
    position: absolute;
    height: 48px;
    width: 48px;
    display: flex;
    background: transparent;
    align-items: center;
    justify-content: center;
    right: 2rem;
    z-index: 999;
    border: 0;

    &:hover {
      div {
        background: #cdcdcd;
      }
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 30px;
      max-height: 30px;
      min-width: 30px;
      max-width: 30px;
      background: #e6e6e6;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      svg {
        height: 16px;

        path {
          fill: #454545;
        }
      }
    }
  }

  .form-control {
    input {
      border: 2px solid rgba(black, 0.3);
    }
  }

  .MuiAccordionSummary-root {
    .MuiAccordionSummary-content {
      span {
        height: 20px;
        width: 20px;
        background: #ababab;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        border-radius: 50%;

        &:empty {
          display: none;
        }
      }
    }
  }

  .MuiAccordionDetails-root {
    padding: 0;

    > div {
      width: 100%;
    }
  }

  .MuiAccordion-root {
    background: transparent;
    box-shadow: none;
    padding-top: 0;

    &:hover {
      background: white;
    }

    &.Mui-expanded {
      background: transparent;
    }

    .MuiAccordionDetails-root {
      .MuiAccordionSummary-content {
        font-weight: 400 !important;
      }
    }

    .MuiButtonBase-root {
      margin-right: 0;

      &.Mui-expanded {
        background: white;
      }
    }

    .MuiSvgIcon-root {
      color: #ababab;
    }

    &.Mui-expanded {
      margin: 0;
    }

    .MuiAccordionSummary-root {
      padding: 0;

      &.Mui-expanded {
        min-height: unset;
        min-height: 43px;

        .MuiIconButton-root {
          display: none !important;
        }

        .MuiButtonBase-root {
          display: none !important;
        }
      }

      .MuiAccordionSummary-content {
        justify-content: space-between;
        align-items: center;
        display: flex;
        margin: 10px 0 !important;
        padding-left: 1.5rem;
        color: black;
        font-size: 1rem;
        font-weight: 600;

        &.Mui-expanded {
          margin: 10px 0 !important;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 0;
    }

    &:before {
      display: none;
    }

    &.expanded {
      margin: auto;
    }
  }

  > div {
    .mobile-filter-size {
      display: flex;
      flex-wrap: wrap;
      padding: 1rem 1rem 1.5rem 1.5rem;
      max-height: 228px;
      width: 100%;
      overflow-y: scroll;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #656565;
        transition: 0.3s ease-in-out;
        padding: 3px 6px;
        font-size: 14px;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        height: 1.5rem;
        width: calc(100% / 3 - 1.5rem);
        user-select: none;

        &:nth-child(3n) {
          margin-right: 0;
        }

        &.active {
          background: #656565;
          color: white;
        }

        &:hover {
          background: #656565;
          color: white;
        }
      }
    }

    .mobile-filter-color {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 1rem;

      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% / 4);
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;

        svg {
          height: 17px;
          opacity: 0;

          path {
            fill: rgba(black, 0.35) !important;
          }
        }

        &[title="Svart"] {
          div {
            background: black;

            svg {
              path {
                fill: rgba(white, 0.5) !important;
              }
            }
          }
        }

        &[title="Blå"] {
          div {
            background: blue;

            svg {
              path {
                fill: rgba(white, 0.5) !important;
              }
            }
          }
        }

        &[title="Grå"] {
          div {
            background: gray;
          }
        }

        &[title="Beige"] {
          div {
            background: beige;
          }
        }

        &[title="Vit"] {
          div {
            background: white;
          }
        }

        &[title="Grön"] {
          div {
            background: green;
          }
        }

        &[title="Brun"] {
          div {
            background: saddlebrown;
          }
        }

        &[title="Rosa"] {
          div {
            background: pink;
          }
        }

        &[title="Röd"] {
          div {
            background: red;
          }
        }

        &[title="Lila"] {
          div {
            background: purple;
          }
        }

        &[title="Orange"] {
          div {
            background: orange;
          }
        }

        &[title="Gul"] {
          div {
            background: yellow;
          }
        }

        > div {
          &:nth-child(1) {
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.75rem;

            svg {
              height: 20px;
              fill: white;
              opacity: 0;
              transition: 0.3s ease-in-out;
            }

            &.active {
              svg {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .mobile-filter-gender {
      display: flex;
      flex-direction: column;
      width: 100%;

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        label {
          padding: 0.75rem 1.5rem;
          width: calc(100% - 3rem);

          &:hover {
            background: white;
          }
        }

        span {
          position: absolute;
          right: 1rem;
        }

        div {
          &:nth-child(1) {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            border: 1px solid rgba(black, 0.15);
          }

          &:nth-child(2) {
            margin-top: 5px;
            font-size: 0.9rem;
          }
        }
      }
    }

    .mobile-filter-price,
    .mobile-filter-sales {
      a {
        label {
          padding: 0.75rem 1.5rem;
          width: calc(100% - 3rem);

          &:hover {
            background: white !important;
          }
        }
      }

      .price-range {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 0;
        padding: 0 1rem;

        @media (max-width: 600px) {
          padding: 0 1.5rem;
        }

        label {
          width: calc(50% - 12px);
          position: relative;
        }

        input {
          padding: 0.5rem 1rem;
          width: calc(100% - 2rem - 4px);
          color: black;
          border: 2px solid rgba(black, 0.1);
          font-size: 1rem;

          &:focus {
            border: 2px solid rgba(black, 1);
          }
        }
      }
    }
  }
}

.mobile-filter-price {
  button {
    width: calc(100% - 2rem);
    margin: 0 auto;
    display: block;
    margin-top: 0.75rem;

    @media (max-width: 600px) {
      width: calc(100% - 3rem);
      margin-bottom: 1rem;
    }

    &.disabled {
      background: #2c2c2c;
      cursor: default;
      pointer-events: none;
    }
  }
}

.clear-filter {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

// GENERAL DROPDOWN FILTER
.dropdown-filter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    @extend .menu-button;
  }

  .dropdown-filter-slider {
    overflow: hidden;
    height: 180px;
    position: absolute;
    width: 310px;
    right: 0rem;
    top: 44px;
    pointer-events: none;

    > div {
      position: absolute;
      background: #f6f6f6;
      z-index: -2;
      transition: 600ms ease 0s;
      transform: translateY(-100%);
      pointer-events: none;
      right: 0;
      top: -190px;
      color: #6f6f70;
      // width: 100%;
      border: 1px solid black;

      .MuiAccordion-root {
        &:last-child {
          border-bottom: 0 !important;
        }
      }

      label,
      a {
        color: #6f6f70;
      }

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;

        li {
          list-style: none;
          border-bottom: 1px solid #e7e7e7;

          &:last-child {
            border: 0;
          }

          a {
            display: flex;
            color: #6f6f70;
            justify-content: space-between;
            align-items: center;
            position: relative;
            cursor: pointer;
            padding: 0 1rem;
            transition: 0.3s ease-in-out;

            &:hover {
              background: #e7e7e7;
            }
          }
        }
      }
      input {
        border: #d3d3d3 solid 1px;
      }
    }

    &.opened {
      display: block;

      > div {
        display: block;
        pointer-events: all;
        transform: translateY(0%);
        visibility: visible;
        top: 0;
      }
    }

    &.dedicated-page {
      max-width: 130px !important;

      label {
        padding: 0.75rem 0.5rem;
      }
    }
  }

  // BRAND FILTER DESKTOP

  &.brand-filter {
    .brand-search {
      padding: 1rem;
      display: flex;
      align-items: center;

      svg {
        position: absolute;
        height: 17px;
        left: 2rem;
      }

      input {
        padding: 0.5rem 1rem;
        padding-left: 3rem;
        width: calc(100% - 2rem);
      }
    }

    .dropdown-filter-slider {
      height: 350px;

      ul {
        overflow-y: scroll;
        max-height: 202px;

        &::-webkit-scrollbar {
          background: #333;
        }
      }

      .form-control {
        > div {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }

      .brand-filter-action {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          padding: 0.5rem 1rem;
          background: #2c2c2c;
          border-radius: 3rem;
          line-height: normal !important;
          display: block;
        }

        button {
          font-size: 1rem;
          padding: 0.5rem 1rem;
          background: #e41513;
          border-radius: 3rem;
          cursor: pointer;
          color: white;

          &:hover {
            background: darken(#e41513, 10%);
          }
        }
      }
    }
  }

  // COLOR FILTER DESKTOP
  &.color-filter {
    .dropdown-filter-slider {
      height: 150px;
      width: 302px;
      left: auto;
      right: auto;

      > div {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem;
        width: calc(100% - 2rem);
        padding-bottom: 0.5rem;

        a {
          padding-bottom: 1rem;
          width: 45px;
          display: flex;
          align-items: center;
          flex-direction: column;
          font-size: 0.9rem;

          svg {
            height: 17px;
            opacity: 0;

            path {
              fill: rgba(black, 0.35) !important;
            }
          }

          &[title="Svart"] {
            div {
              background: black;

              svg {
                path {
                  fill: rgba(white, 0.5) !important;
                }
              }
            }
          }

          &[title="Blå"] {
            div {
              background: blue;

              svg {
                path {
                  fill: rgba(white, 0.5) !important;
                }
              }
            }
          }

          &[title="Grå"] {
            div {
              background: gray;
            }
          }

          &[title="Beige"] {
            div {
              background: beige;
            }
          }

          &[title="Vit"] {
            div {
              background: white;
            }
          }

          &[title="Grön"] {
            div {
              background: green;
            }
          }

          &[title="Brun"] {
            div {
              background: saddlebrown;
            }
          }

          &[title="Rosa"] {
            div {
              background: pink;
            }
          }

          &[title="Röd"] {
            div {
              background: red;
            }
          }

          &[title="Lila"] {
            div {
              background: purple;
            }
          }

          &[title="Orange"] {
            div {
              background: orange;
            }
          }

          &[title="Gul"] {
            div {
              background: yellow;
            }
          }

          &:hover {
            div {
              opacity: 0.5;
            }
          }

          div {
            transition: 0.3s ease-in-out;

            &:nth-child(1) {
              height: 30px;
              width: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 0.75rem;

              &.active {
                svg {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  // GENDER FILTER DESKTOP
  &.gender-filter {
    .dropdown-filter-slider {
      height: 201px;
      width: 172px;
      left: auto;
      right: auto;

      > div {
        display: flex;
        flex-direction: column;
        width: 172px;

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 1rem;
          border-bottom: 1px solid #333;

          &:hover {
            background: #333;
          }

          &:last-child {
            border: 0;
          }

          label {
            input {
              margin: 0;
              margin-right: 1rem;
            }

            span {
              margin-left: auto;
              opacity: 0.75;
            }
          }
        }
      }
    }
  }

  // SIZE FILTER DESKTOP
  &.size-filter {
    .dropdown-filter-slider {
      min-height: 500px;
      left: auto;
      right: auto;

      .MuiAccordion-root {
        background: transparent;
        color: white;
        box-shadow: none !important;
        border-bottom: 1px solid #333 !important;

        svg {
          path {
            fill: #9c9c9c;
          }
        }

        &:hover {
          background: #333;
        }

        &.Mui-expanded {
          margin: 0 !important;

          &:hover {
            background: transparent !important;
          }
        }

        .MuiAccordionSummary-content {
          margin: 12px 0 !important;
        }

        .MuiAccordionSummary-root {
          &.Mui-expanded {
            min-height: 48px !important;
          }
        }
      }

      .MuiAccordionDetails-root {
        display: flex;
        flex-wrap: wrap;
        max-height: 204px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          background: #333;
        }
      }

      > div {
        padding: 0;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid #656565;
          transition: 0.3s ease-in-out;
          padding: 3px 6px;
          font-size: 14px;
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
          height: 1.5rem;
          width: calc(100% / 3 - 1.4rem);

          &:nth-child(3n) {
            margin-right: 0;
          }

          &.active {
            background: #656565;
            color: white;
          }

          &:hover {
            background: #656565;
          }
        }
      }
    }
  }

  // PRICE FILTER DESKTOP
  &.price-filter {
    .dropdown-filter-slider {
      width: 270px;
      height: 305px;
      left: auto;
      right: 0;

      > div {
        display: flex;
        flex-direction: column;
        padding-bottom: 0.75rem;

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #e7e7e7;
          padding: 0 1rem;

          &:hover {
            background: #e7e7e7;
          }

          label {
            input {
              margin: 0;
              margin-right: 1rem;
            }

            span {
              opacity: 0.75;
              margin-left: auto;
            }
          }
        }

        .price-range,
        .sale-range {
          justify-content: space-between;
          display: flex;
          align-items: center;
          padding: 0.75rem 1rem 0 1rem;
          color: #6f6f70;

          label {
            width: calc(50% - 1.5rem);

            input {
              font-size: 1rem;
              padding: 0.25rem 0.75rem;
              width: calc(100% - 1.5rem - 4px);
              color: #6f6f70;
              background: white;
            }
          }
        }
      }
    }
  }

  // SORTING BUTTON DESKTOP
  &.sorting-filter {
    @media (max-width: 600px) {
      display: none;
    }

    .dropdown-filter-slider {
      width: 140px;
      height: 123px;
      left: auto;
      right: 0;
    }
  }
}

.menu-button {
  border: 0;
  // border-left: 4px solid #f6f6f6;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  height: 44px;
  background: transparent;
  font-weight: 500;
  font-size: 1rem;
  user-select: none;
  cursor: pointer;
  position: relative;
  padding-left: 0.75rem;
  padding-right: 0.5rem;

  svg {
    fill: rgba(white, 0.3);
    height: 17px;
    transition: 0.3s ease-in-out;
  }

  span {
    font-weight: 500;
  }

  &.opened {
    .MuiSvgIcon-root {
      transform: rotate(180deg);
    }
  }

  &.filter-mobile-button {
    justify-content: center;

    > span {
      &:nth-child(1) {
        display: none;
        // opacity: 0.55;
        @media (min-width: 901px) {
          display: block;
        }
        padding-right: 2rem;
      }
      &:nth-child(3) {
        padding-left: 0.5rem;
      }
    }

    svg {
      fill: #333;
      height: 20px;
    }
  }
}

.shopping {
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  main {
    &:before {
      transition: 0.3s ease-in-out;
      left: -350px;
    }
  }

  &.no-result {
    header,
    #search-filter,
    nav {
      display: none !important;
    }
  }

  &.drawer-open {
    .category-overlay {
      @media (max-width: 1200px) {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .extra-margin {
    margin-top: 18px;
    @media (max-width: 600px) {
      margin-top: 5px;
    }
  }

  .sub-breadcrumbs {
    // margin-bottom: 2px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem 8px;

    .swiper-wrapper {
      margin-bottom: 2px;

      a {
        border: 1px solid #cbcbcb;
      }
    }
  }

  .shopping-header {
    position: relative;
    background: white;
    z-index: 999;
    color: black;
    overflow: hidden;
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8px;
    padding-right: 8px;
    .container {
      display: flex;
      /* justify-content: space-between; */
      /* align-items: center; */
      width: calc(100% - 4rem);
      max-width: unset;
      min-height: 62px;
      flex-direction: column;

      // @media (max-width: 991px) {
      //   padding-top: 100px;
      // }

      @media (max-width: 900px) {
        flex-direction: column;
        padding-bottom: 0.5rem;
        // padding-top: 3rem;
        min-height: unset;

        > div {
          width: 100%;
        }
      }

      @media (max-width: 600px) {
        width: calc(100% - 2rem);
        min-height: unset;
      }

      .breadcrumb-wrapper {
        position: absolute;
        justify-content: flex-start;
        padding: 0;
        top: 0;
        left: 2rem;

        @media (max-width: 900px) {
          left: 1.5rem;
        }

        @media (max-width: 600px) {
          left: 0;
          width: 100%;
        }

        > div {
          // overflow-x: auto;
          padding-right: 2rem;

          @media (max-width: 600px) {
            padding-left: 0.5rem;
          }
        }
      }

      h1 {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        color: black;
        font-size: 2rem;
        white-space: nowrap;

        @media (max-width: 600px) {
          font-size: 1.6rem;
        }

        i {
          font-style: normal;
          margin-left: 10px;
        }

        span {
          color: rgba(black, 0.55);
          margin-left: 10px;
          font-size: 1rem;
          letter-spacing: 0.05rem;
        }
      }

      p {
        margin: 0;
        font-size: 1rem;
        color: #707070;
        margin: 0.5rem 0;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 20px;
    height: 8px;
    @media (max-width: 600px) {
      height: 4px;
    }
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #bebebe;
    height: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #8b0000;
  }
  .internal-links {
    margin: 0;
    overflow-x: auto;
    margin-right: auto;
    display: flex;

    .categoryTab {
      padding: 0.5rem 1rem;
      margin: 8px 8px 8px 0px;
      display: inline-flex;
      color: #505050;
      white-space: nowrap;
      display: flex;
      align-items: center;
      user-select: none;
      // border-left: 4px solid #f6f6f6;
      height: 100%;
      border: 1px solid #505050;
      border-radius: 5px;

      &:hover {
        background: #f6f6f6;
        text-decoration: underline;
      }
    }

    &:after {
      // background: linear-gradient(90deg, hsla(0, 0%, 7%, 0) 0, #111);
      right: 0;
      display: none;

      @media (max-width: 1100px) {
        display: none;
      }
    }
  }

  main {
    display: flex;
    width: 100vw;
    position: relative;

    .category-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 4;
      opacity: 0;
      pointer-events: none;
      z-index: 999;
      width: 100%;
      height: 100%;
      transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background: rgba(black, 0.5);
    }
  }

  #main-content {
    &.container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0px 8px 0px 8px;
    }

    // .sub-title{
    //   display: flex;
    //   justify-content: center;
    //   padding: 1rem 0rem;

    //   @media (min-width: 901px) {
    //     display: none;
    //   }
    // }

    .breadcrumbs {
      overflow-x: scroll;
      width: calc(100% - 1rem);

      @media (min-width: 700px) {
        justify-content: center;
      }
    }
  }
}

#search-filter {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  top: 93px;
  left: 0;
  right: 0;
  height: 44px;
  overflow-x: clip;
  overflow-y: visible;

  @media (max-width: 1401px) {
    top: 49px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 5px 5px 0;
    max-width: unset;
    border-bottom: 1px solid rgba(80, 80, 80, 0.5);
    @media (max-width: 600px) {
      border-bottom: 0;
    }
  }

  .categories {
    display: flex;
    align-items: center;

    button {
      border: 0;
      color: black;
      display: flex;
      align-items: center;
      // border-right: 4px solid #f6f6f6;
      justify-content: space-between;
      transition: 0.3s ease-in-out;
      height: 44px;
      background: transparent;
      font-weight: 500;
      font-size: 1rem;
      padding: 0 0.6rem;
      user-select: none;
      cursor: pointer;
      position: relative;

      svg {
        height: 20px;
        fill: black;
        // display: none;
      }

      > span {
        padding-left: 0.5rem;
        color: black;
        // display: none;

        // @media (min-width: 601px) {
        //   display: block;
        // }
      }

      // @media (max-width: 600px) {
      //   padding: 0 1rem;
      //   font-size: 0;

      //   // svg {
      //   //   display: block;
      //   // }
      // }

      &:hover {
        text-decoration: underline;
      }

      &.drawer-open {
        // text-decoration: underline;
        background: #f6f6f6;
      }

      div {
        margin-left: 0.5rem;
        display: none;

        span {
          width: 16px;
          height: 2px;
          display: flex;
          align-items: center;
          background: #9c9c9c;
          margin-bottom: 4px;
          position: relative;
          transition: 0.3s ease-in-out;

          &:before {
            content: "";
            width: 2px;
            height: 5px;
            background: #9c9c9c;
            display: block;
            position: absolute;
          }

          &:first-child {
            &:before {
              left: 3px;
              transition: 0.3s ease-in-out;
            }
          }

          &:last-child {
            margin: 0;

            &:before {
              left: 3px;
              transition: 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }

  .filters {
    display: flex;
    align-items: center;
.amount-info{
  color: black;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  height: 44px;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  user-select: none;
  cursor: pointer;
  position: relative;
  padding-left: 0.75rem;
  padding-right: 0.5rem;

}
    // PRICE FILTER
    .dropdown-filter,
    .dropdown-genders {
      position: relative;

      &.size-filter,
      &.color-filter {
        display: none !important;
        visibility: hidden;
        opacity: 0;
      }

      .reset-filter {
        width: 15px;
        height: 15px;
        padding-left: 1rem;
        display: block;

        @media (max-width: 440px) {
          padding-left: 13px;
          margin-right: -10px;
        }

        span {
          width: 16px;
          height: 2px;
          display: flex;
          align-items: center;
          background: #9c9c9c;
          margin-bottom: 4px;
          position: relative;
          transition: 0.3s ease-in-out;

          &:nth-child(1) {
            transform: rotate(45deg);
            top: 6px;
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
            bottom: 0px;
          }
        }
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 21;

        a {
          cursor: pointer;
          line-height: 0;

          &:hover {
            svg {
              path {
                fill: white;
              }
            }
          }

          svg {
            height: 20px;

            path {
              transition: 0.3s ease-in-out;
              fill: rgba(white, 0.5);
            }
          }
        }
      }

      > button {
        border: 0;
        color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.3s ease-in-out;
        height: 44px;
        background: transparent;
        font-weight: 500;
        font-size: 1rem;
        user-select: none;
        cursor: pointer;
        position: relative;
        padding: 0 1rem;
        // border-left: 4px solid #f6f6f6;
        white-space: nowrap;

        svg {
          fill: #9c9c9c;
          width: 1em;
          height: 1em;
          transition: 0.3s ease-in-out;
        }

        span {
          font-weight: 500;
        }

        &.opened {
          text-decoration: underline;

          .MuiSvg-root {
            transform: rotate(180deg);
          }
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &__options-list {
        overflow: hidden;
        height: 330px;
        position: absolute;
        width: 220px;
        right: 0rem;
        top: 44px;
        pointer-events: none;

        .orders {
          position: absolute;
          background: #111;
          z-index: -2;
          width: 130px;
          padding: 0 1rem;
          transition: 900ms ease 0s;
          transform: translateY(-100%);
          pointer-events: none;
          right: 1rem;
          top: -500px;

          @media (max-width: 600px) {
            right: 0;
          }
        }

        .genders {
          position: absolute;
          background: #111;
          z-index: -2;
          width: 180px;
          transition: 900ms ease 0s;
          transform: translateY(-100%);
          pointer-events: none;
          right: 1rem;
          top: -500px;

          @media (max-width: 600px) {
            right: 0;
          }

          color: white;

          ul {
            list-style-type: none;
          }

          li {
            padding: 1rem 1rem;
            display: flex;
            align-items: center;
            place-content: space-between;
            cursor: pointer;
          }
        }

        &.opened {
          > div {
            display: block;
            pointer-events: all;
            transform: translateY(0%);
            visibility: visible;
            top: 0;
          }
        }

        ul {
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;

          li {
            list-style: none;
            border-bottom: 1px solid #333;

            a {
              padding: 1rem 0;
              display: flex;
              color: white;
              justify-content: space-between;
              align-items: center;
              position: relative;
              cursor: pointer;
              height: 20px;

              &:hover {
                svg {
                  fill: white;
                  width: 15px;
                }
              }

              svg {
                width: 15px;
                fill: rgba(white, 0.2);
                transition: 0.3s ease-in-out;
              }
            }

            &:last-child {
              border: 0;
            }
          }
        }
      }
    }

    .dropdown-genders {
      button {
        padding-right: 1rem;
      }
    }

    // RESPONSIVE FIX FOR MOBILE DEVICES
    .dropdown-filter {
      @media (max-width: 400px) {
        &.selected {
          button {
            span {
              max-width: 50px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .reset-button {
      height: 100%;
      width: 35px;
      margin-left: -0.5rem;
      padding-right: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      position: relative;

      &:before {
        content: "";
        height: 42px;
        width: 42px;
        cursor: disabled;
        position: absolute;
        bottom: -1px;
        pointer-events: none;
        z-index: 9999;
      }

      &:hover {
        span {
          background: rgba(black, 0.2);
        }
      }

      span {
        min-height: 16px;
        min-width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(black, 0.1);
        cursor: pointer;
        line-height: 0;
        border-radius: 50%;
        transition: 0.3s ease-in-out;

        svg {
          height: 12px !important;
          width: 12px !important;

          path {
            fill: rgba(black, 0.4) !important;
          }
        }
      }
    }
  }
}

.product-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  min-height: 100vh;
  justify-content: center;
  margin-top: 15px;
  @media (max-width: 600px) {
    margin-top: 0px;
  }

  div[style*="flex:1;width:0;"] {
    display: block !important;
    width: 100% !important;
  }

  div[style*="flex: 1 1 0%;"] {
    display: block !important;
    width: 100% !important;
  }

  > .deal-item,
  .advertisement-item {
    article {
      margin: 2px;
    }

    @media (max-width: 609px) {
      flex-basis: calc(50%);
      &.mobile {
        min-height: 64px;
        flex-basis: calc(100%);
      }
    }

    // @media (min-width: 900px) {
    //   flex-basis: calc(100% / 3);
    // }

    // @media (min-width: 1000px) {
    //   flex-basis: calc(100% / 4);
    // }

    // @media (min-width: 1300px) {
    //   flex-basis: calc(100% / 5);
    // }

    // @media (min-width: 1500px) {
    //   flex-basis: calc(100% / 6);
    // }

    // @media (min-width: 1700px) {
    //   flex-basis: calc(100% / 7);
    // }
  }
  .product-contaner {
    margin: 4px 0px;
  }
}

// FOR SEARCH PAGE
.award_box {
  overflow: hidden;

  .award_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: black;
    max-height: 44px;
    height: 44px;
    padding-left: 2rem;
    padding-right: 1rem;

    div {
      font-size: 14px;
      padding: 0.3rem 0.8rem;
      background: #e2e2e2;
      border-radius: 1rem;
      color: #454545;
      font-weight: 400;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      &:hover {
        background: #cdcdcd;
      }
    }
  }

  .sorting-button {
    display: flex;
    align-items: center;
    padding: 0 2rem;
    height: 44px;
    font-weight: 400;
    color: black;
    cursor: pointer;

    &.checked {
      background-color: white !important;

      .filter-name {
        color: black;
      }
    }

    &:hover {
      background: white;

      > div {
        &:first-child {
          &:before {
            content: "";
            height: 9px;
            width: 9px;
            background: #ccc;
            border-radius: 50%;
            opacity: 1;
          }
        }
      }
    }

    &.checked {
      > div {
        &:first-child {
          &:before {
            content: "";
            height: 9px;
            width: 9px;
            background: #3e3e3e;
            border-radius: 50%;
            opacity: 1;
          }
        }
      }
    }

    > div {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:first-child {
        min-height: 15px;
        max-height: 15px;
        min-width: 15px;
        max-width: 15px;
        border-radius: 50%;
        border: 2px solid #ccc;
        margin-right: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none !important;

        &:before {
          transition: 0.3s ease-in-out;
          opacity: 0;
        }
      }

      .filter-name {
        font-weight: 500;
        color: rgba(black, 0.75);
      }

      .filter-amount {
        font-size: 0.85rem;
        opacity: 0.75;
        margin-left: 0.5rem;
      }
    }

    &:hover {
      .filter-name {
        color: black;
      }
    }

    > span,
    input {
      display: none;
    }
  }
}

// COPY TEXT, CATEGORY PAGE BOTTOM
#category-copy {
  position: relative;
  background: #8b0000;
  color: white;
  padding: 2rem 0;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (max-width: 600px) {
    padding: 3rem 0;
  }

  > button {
    background: #222;
    margin: 0 auto;

    &:hover {
      background: #333;
    }
  }

  .share-post {
    margin: 3rem 0;
    background: #222;
    padding: 3rem;
    display: flex;
    justify-content: space-between;

    h4,
    p {
      margin: 0;
    }
  }

  .container {
    max-width: 980px;
    transition: 0.3s ease-in-out;
    max-height: 220px;
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      height: 300px;
      width: 100%;
      background: linear-gradient(
        to bottom,
        rgba(17, 17, 17, 0) 0%,
        #8b0000 100%
      );
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      transition: 0.3s ease-in-out;
      pointer-events: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500 !important;
  }

  p,
  ul,
  ol {
    color: white;
  }

  ul,
  ol {
    font-size: 1.1rem;
  }

  li {
    margin-bottom: 1rem;
  }

  figure {
    width: 100% !important;
    margin: 2rem 0;
    padding: 0;

    img {
      width: 100% !important;
      height: auto !important;
    }
  }

  a {
    color: rgba(white, 0.75);
    text-decoration: underline;

    i {
      font-style: normal !important;
    }
  }

  &.show {
    .container {
      max-height: 100%;
      overflow: visible;
      transition: max-height 0.25s ease-in;

      &:after {
        opacity: 0;
      }
    }
  }
}

.category-menu {
  width: 0px;
  min-width: 320px;
  margin-left: -320px;
  position: sticky;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  background: #f6f6f6 !important;
  padding-bottom: 2rem;
  transition: 0.3s margin ease-in-out;

  @media (max-width: 1200px) {
    position: fixed;
    z-index: 1000;
    top: 50px !important;
    height: calc(100vh - 44px) !important;
  }

  @media (max-width: 991px) {
    margin-top: 50px;
  }

  > ul {
    padding-bottom: 2rem;
  }

  &.isOpen {
    margin-left: 0;
    overflow-y: scroll;
  }

  &.disable-animation {
    transition: none !important;
  }

  .MuiTreeItem-iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;

    &:empty {
      display: none;
    }
  }

  &::-webkit-scrollbar {
    background: #f6f6f6;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
  }
}

.no-products {
  height: calc(100vh - 200px - 300px);
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  margin-top: 94px;
  align-items: center;
  min-height: 50vh;
  margin-top: 100px;

  @media (min-width: 991px) and (max-width: 1401px) {
    margin-top: 50px;
  }

  h3 {
    margin: 0 0 2rem 0;
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;

    span {
      opacity: 0.75;
      font-weight: 400;
      margin-top: 0.5rem;
    }
  }

  p {
    margin: 0;
    max-width: 60%;

    span {
      display: block;
    }
  }

  button {
    margin-top: 2rem;
  }

  a {
    &:hover {
      background: darken(#e41513, 10%);

      svg {
        transform: rotate(-360deg);
      }
    }

    svg {
      transition: 0.3s ease-in-out;
      margin-left: 1rem;
      height: 17px;
      fill: white;
    }
  }

  ul {
    list-style-type: auto;
  }

  .ascii-smiley-face {
    color: #8b0000;
    transform: rotate(90deg);
    &__wrapper {
      font-size: 10rem;
      display: flex;
      justify-content: center;
    }
  }
}
.no-program {
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
  margin: auto;
  width: 330px;
}

// Show more
.show-more-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2.5rem;
  flex-basis: 100% !important;
  text-align: center;

  .progress-wrapper {
    position: relative;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:before {
      content: "";
      height: 4px;
      width: 10px;
      display: block;
      background: darken(#f6f6f6, 35%);
    }
  }

  progress {
    appearance: none;
    border: 0;
    height: 4px;
    color: #f1f1f1;
    overflow: hidden;
    width: 180px;

    &::-webkit-progress-bar {
      background-color: darken(#f6f6f6, 15%);
    }

    &::-webkit-progress-value {
      background: darken(#f6f6f6, 35%);
    }
  }
}
