.subscriber-input {
  &__content__lower-section__left {
    display: flex;
    flex: auto;
    gap: 16px;
  }
}

.subscriber-input-wrapper {
  display: flex;
  gap: 16px;
  color: white;
  height: 35px;
  max-width: 500px;
  width: 380px;

  @media(max-width: 425px){
    width: 335px;
}
}

.subscriber-input-wrapper input[type=text] {
  flex: auto;
  color: white;
  background: none;
  border: 1px solid white;
  border-radius: 3px;
  font-size: 15px;
}

.subscriber-input-wrapper input[type=text]::placeholder {
  color: white;
  opacity: 1;
}

.subscriber-input-wrapper input[type=text]:focus-visible {
  outline: none;
}

.subscriber-input-wrapper button {
  color: white;
  background: none;
  border: 1px solid white !important;
  border-radius: 3px;
  cursor: pointer;
  font-size: 15px;
}