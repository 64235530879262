@import "./fonts/index";
@import "./global/index";
@import "./components/index";
@import "./pages/index";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  font-family: futura-pt, sans-serif;
  font-style: normal;
  background: white;
  overflow-x: hidden;
}

// GENERIC STYLING

::-webkit-scrollbar-thumb {
  background: #bdbdbd;

  &:hover {
    background: #e41513;
  }
}

::-webkit-scrollbar {
  width: 8px !important;
  background: #e0e0e0;
}

::-moz-selection {
  color: white;
  background: black;
}

::selection {
  color: white;
  background: black;
}

// GENERIC FONT STYLE

p {
  line-height: 1.5;
  font-size: 1.1rem;
}

b,
strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.5rem 0;
  font-style: normal;
  font-weight: 500;
}

h1 {
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.2;

  @media (max-width: 600px) {
    font-size: 2rem;
  }
}

h2 {
  font-size: 2rem;
  line-height: 1.25;

  @media (max-width: 600px) {
    font-size: 1.6rem;
  }
}

h3 {
  font-size: 1.6rem;
  line-height: 1.25;
}

h4 {
  font-size: 1.2rem;
  line-height: 1.5;
}

.heading-4 {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  display: block;
}

h5 {
  font-size: 1.2rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.2rem;
  line-height: 1.5;
}

// GENERIC BUTTONS

a {
  color: initial;
  text-decoration-line: none;
  transition: 0.3s ease-in-out;
}

.home button {
  border: 0;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.action-button {
  background: black;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  border-radius: 3rem;
  color: white;

  &:hover {
    background: darken(#e41513, 10%);
  }

  &.medium {
    font-weight: 500;
    font-size: 1.1rem;
    padding: 0.75rem 1rem;
    color: white;
    background: black;

    span {
      &:nth-child(2) {
        text-transform: initial;
        font-weight: 400;
        margin-left: 8px;
      }

      &.count {
        text-transform: initial;
        font-weight: 400;
        margin-left: 8px;
      }
    }
  }

  &.small {
    font-size: 14px;
    padding: 0.3rem 0.8rem;

    span {
      font-weight: 400;
      margin-left: 5px;
    }
  }

  i {
    font-style: normal;
  }

  &.secondary {
    background: #e6e6e6;
    color: #454545;
    display: inline-flex;
    justify-content: space-between;

    span {
      &:nth-child(1) {
        font-weight: 500;
        color: black;

        i {
          margin-left: 5px;
        }
      }

      &:nth-child(2) {
        margin-left: 1rem;
      }

      &.count {
        color: #606060;
      }
    }

    &:hover {
      background: darken(#f3f3f3, 10%);
    }
  }
}

.buy-button {
  background: linear-gradient(-45deg, black, black, #515151, black);
  animation: Gradient 4.5s ease infinite;
  background-size: 400% 400%;
  transition: 0.3s ease-in-out;
  padding: 1rem 3rem;
  border-radius: 3rem;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  color: white;
  display: flex;

  @media (max-width: 450px) {
    width: calc(100% - 6rem);
    justify-content: center;
  }

  .button-svg {
    margin-left: 1rem;
    height: 15px;
    width: 15px;
    align-self: center;

    path {
      fill: white;
    }
  }

  &.medium {
    padding: 0.75rem 2rem;
  }

  &:hover {
    animation-play-state: revert;
  }
}

.favour {
  visibility: visible !important;
  opacity: 1 !important;

  &__favorited {
    color: #d85656;
  }

  &__regular {
    color: #757575;
  }
}

// GENERIC INPUT FIELDS ETC
.home input,
.home textarea,
.home button,
.home select {
  font-family: futura-pt, sans-serif;
  -webkit-appearance: none;
}

select {
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 400;
}

.home input {
  font-size: 1.1rem;
  font-weight: 400;
  // transition: 0.3s ease-in-out;
  border-radius: 3rem;
  padding: 0.75rem 1.5rem;
  border: 2px solid rgba(white, 0.1);
  background: transparent;
  // transition: 0.3s ease-in-out;
  // color: white;

  &::placeholder {
    transition: 0.3s ease-in-out;
  }

  &:focus {
    // background: transparent;
    // border: 2px solid darken(white, 10%);
    // outline: none;

    &::placeholder {
      color: black;
    }

    &:-webkit-autofill {
      background: transparent;
      box-shadow: none;
    }
  }

  &:-webkit-autofill {
    background: transparent;

    &::first-line {
      font-size: 1rem;
      font-family: futura-pt, sans-serif !important;
    }

    &:hover,
    &:focus,
    &:active {
      font-family: futura-pt, sans-serif !important;
      -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
      -webkit-transition-delay: 9999s;
    }
  }
}

.form-control {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 0.75rem 0;

  &:hover {
    input {
      &:before {
        transform: scale(1);
      }
    }
  }

  input {
    margin-bottom: -3px !important;
    margin-right: 1rem !important;
  }
}

input[type="radio"],
input[type="checkbox"] {
  appearance: none;
  background: white;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  min-width: 1.15em;
  min-height: 1.15em;
  border: 2px solid rgba(white, 0.3);
  border-radius: 50%;
  padding: 0;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &:before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #e41513;
  }

  &:checked {
    &:before {
      transform: scale(1);
    }
  }
}

textarea {
  border: 0;
  width: 100%;
  padding: 1.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  border-radius: 1rem;
  border: 2px solid rgba(white, 0.1);
  background: transparent;
  transition: 0.3s ease-in-out;
  // color: white;

  &::placeholder {
    transition: 0.3s ease-in-out;
  }

  &:focus {
    // border: 2px solid darken(white, 10%);
    outline: none;

    &::placeholder {
      color: white;
    }
  }
}

// GRID AND LAYOUT HELPERS

.container {
  max-width: 1500px;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;

  @media (max-width: 340px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (max-width: 850px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.bg-contrast {
  background: #f6f6f6;
}

.hidden {
  display: none;
  pointer-events: none;
}

.visible {
  display: block;
}

.bg-layer {
  background: rgba(black, 0.5);
  position: fixed;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease-in-out;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.wip-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  z-index: 950;
  width: 100%;
  height: 100%;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: rgba(black, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  h3 {
    font-style: italic;
  }
}

.no-scroll {
  position: relative;
  height: 100%;
  overflow-y: hidden !important;
  touch-action: none;
  -ms-touch-action: none;
}

hr {
  height: 1px;
  border: 0;
  margin: 2rem 0;
  background: #e6e6e6;
}

.section-title {
  display: block;
  background: #8b0000;
  color: white;
  font-weight: 500;
  text-align: center;
  padding: 1rem 2rem !important;
  font-size: 1.6rem;
  margin: 0;
}

// SWIPER JS GENERIC

.swiper-button-next {
  &:after {
    color: #ddd;
  }
}

.swiper-button-prev {
  &:after {
    color: #ddd;
  }
}

// ANIMATIONS
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes Pagination {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

// CONTENT PAGE

.content-page {
  header {
    .container {
      position: relative;
    }
  }

  p {
    color: #6a6a6a;
  }

  main {
    min-height: calc(100vh - 240px);

    section {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border-left: 2px solid #e6e6e6;
      padding-left: 2rem;

      @media (max-width: 600px) {
        padding: 0;
        border: 0;
        margin: 0;
      }

      p {
        &:first-child {
          margin-top: 0;
        }

        a {
          text-decoration: underline;
        }
      }
    }

    h2 {
      margin: 2rem 0;
    }

    h3 {
      font-weight: 400;
    }

    ul,
    ol {
      li {
        margin-bottom: 0.75rem;
      }
    }
  }
}

// Embedded video
.embedded-video {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  background: black;
  overflow: hidden;
  line-height: 0;

  video {
    max-width: 100vw;
    width: 100%;
    // height: calc(100vh - 88px);
  }
}

// Search box

.search-box {
  width: 90%;
  max-width: 400px;
  height: 51px;
  position: relative;
  border-radius: 3rem 0 0 3rem;
  background: white;
  cursor: text;
  margin: 1rem 25.5px 2rem 0rem;
  // margin-right: 25.5px;
  // margin-top: 1rem;

  &.show-input {
    .typewriter {
      display: none;
    }

    input {
      opacity: 1;
    }
  }

  .input-icon {
    height: 51px;
    width: 51px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: -25.5px;
    background: #8b0000;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    // animation: fadeInInputIcon 0.3s ease-in-out 0s both;
    cursor: default;

    svg {
      height: 24px;
      fill: white;
    }
  }

  input {
    border-top-left-radius: 5rem;
    padding: 0.75rem 2rem;
    /* border-radius: 5rem; */
    font-size: 1.1rem;
    width: calc(100% - 4.5rem);
    background: transparent;
    position: absolute;
    border: 0;
    /* left: 0; */
    top: 2px;
    /* -webkit-animation: showInput 0.3s cubic-bezier(0.66, 0, 0.34, 1) 1s both; */
    /* animation: showInput 0.3s cubic-bezier(0.66, 0, 0.34, 1) 1s both; */
    color: black;
    font-weight: 500;
    background-color: white;
    border-bottom-left-radius: 5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
      outline: 0;
      background: transparent !important;
      border-radius: 0 !important;
      border: 0 !important;
    }
  }
}

// @keyframes expandInput {
//   0% {
//     width: 0%;
//     color: transparent;
//   }

//   100% {
//     transform: translate(-12.5px, 0);
//     width: 100%;
//     color: black;
//   }
// }

// @keyframes fadeInInputIcon {
//   0% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }

@keyframes showInput {
  0% {
    visibility: hidden;
    width: 0px !important;
    max-width: 0px !important;
    opacity: 0;
    height: 0px !important;
    padding: 0 !important;
  }

  90% {
    visibility: hidden;
    width: 0px !important;
    max-width: 0px !important;
    opacity: 0;
    height: 0px !important;
    padding: 0 !important;
  }

  100% {
    visibility: visible;
  }
}

@keyframes hideTypeWriter {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
    position: relative;
  }
}

.map-marker {
  height: 0.1rem;
  width: 0.1rem;
  border-radius: 50%;
  background: #e41513;
  transition: 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out 1.5s both;
  cursor: default;
}

// custom swiper styles
.next {
  right: 0rem;
  transform: rotate(-90deg);
}

.prev {
  left: 0rem;
  transform: rotate(90deg);
}

.next,
.prev {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  background: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
  top: 0;

  &.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }

  svg {
    height: 22px;
    width: 22px;
    fill: #454545;
  }

  &:hover {
    background: darken(#e6e6e6, 10%);
  }
}

.modal-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;

}

.modal-description-content {
  height: 200px;
  overflow-y: scroll;
}