.artiklar {


    main {
        .container {
            display: flex;

            >div {
                width: 100%;
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between;
            }

            aside {
                margin-top: -4px;
                margin-left: 3rem;
                max-width: 320px;
                width: 100%;
                align-self: flex-start;
                position: sticky;
                top: calc(44px + 3rem);

                @media (max-width: 950px) {
                    display: none;
                }

                h3 {
                    margin: 0;
                    height: 88px;
                    padding-left: 2rem;
                    font-size: 1.6rem;
                    user-select: none;
                    line-height: 1.25;
                    position: absolute;
                    z-index: 99;
                    background: white;
                    display: flex;
                    align-items: center;
                    right: 0;
                    left: 0;
                    top: 0;
                }

                .swiper-button-next,
                .swiper-button-prev {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    transition: 0.3s ease-in-out;
                    background: #e6e6e6;
                    top: 3rem;
                    z-index: 150;

                    &:hover {
                        background: #cdcdcd;
                    }

                    &:after {
                        font-size: 1rem;
                        font-weight: 600;
                        color: #454545;
                    }
                }

                .swiper-button-next {
                    transform: rotate(90deg);
                    right: 2rem;
                }

                .swiper-button-prev {
                    transform: rotate(90deg);
                    right: 5rem;
                    left: unset;
                }

                .swiper-container {
                    padding-top: 5.5rem;
                }

                .swiper-wrapper {
                    max-height: 444px;
                    height: 100%;
                }

                .swiper-slide {
                    display: flex;
                    align-items: center;
                    background: white;
                    margin-top: 4px;
                    padding: 1rem 2rem;
                    cursor: pointer;
                    width: unset;

                    &:hover {
                        background: #ffffff75;

                        .heading {
                            text-decoration: underline;
                        }
                    }

                    >div {
                        &:nth-child(1) {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            font-size: 1.2rem;
                            font-weight: 500;
                            border: 2px solid rgba(black, 0.1);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 1rem;
                        }

                        svg {
                            height: 25px;
                            max-width: 50%;
                        }
                    }

                    a {
                        width: 100%;

                        .heading-4 {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                    }

                }

                ul{
                    display: flex;
                    flex-wrap: wrap;
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li{
                        margin-top: 4px;
                        margin-right: 4px;
                        width: calc(50% - 2px);

                        &:nth-child(even){
                            margin-right: 0;
                        }
                        

                        a{
                            display: block;
                            padding: 5px 10px;
                            color: black;
                            background: #e6e6e6;
                            text-transform: capitalize;
                            display: flex;
                            justify-content: space-between;

                            &:hover{
                                background: darken(#e6e6e6, 10%);
                            }

                            span{
                                opacity: 0.75;
                            }
                        }
                    }
                }
            }
        }
    }

    article {
        background: black;
        margin-bottom: 2rem;
        overflow: hidden;
        position: relative;
        flex-basis: calc(50% - 1rem);
        height: 300px;

        @media (max-width: 1100px) {
            flex-basis: calc(50% - 0.75rem);
            margin-bottom: 1.5rem;
        }

        @media (max-width: 700px) {
            flex-basis: 100% !important;
        }

        &:nth-child(1) {
            flex-basis: 100%;
        }

        &:nth-child(5) {
            margin-bottom: 0 !important;
        }

        &:hover {
            img {
                opacity: 0.35;
                filter: grayscale(0);
            }
        }

        div>span {
            img {
                opacity: 0.25;
                filter: grayscale(50%);
            }
        }

        a {
            padding: 3rem;
            display: flex;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: calc(100% - 6rem);
            z-index: 1;
            @media(max-width: 1180px){
                height: calc(100% - 4rem);
                padding: 2rem;
            }

            header {
                h2 {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                }
            }

            p {
                margin: 0;
                padding: 0;
                color: white;
                opacity: 0.75;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            h2 {
                margin-bottom: 1rem;
                color: white;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            footer{
                display: flex;
                align-items: center;
                margin-top: auto;

                .avatar{
                    border-radius: 50%;
                    margin-right: 1rem;
                    background: pink;
                    height: 35px;
                    width: 35px;                    
                }

                .article-meta{
                    display: flex;
                    flex-direction: column;
                    color: white;
                    font-weight: 500;
                    font-size: 1.1rem;

                    > div{
                        font-weight: 400;
                    }

                    time{
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

.sign-up-rea {
    text-align: center;
}