.ambition-section {
    background: #fefaf9;
    padding: 2.5rem 0;
    font-weight: 500;
    svg {
        &#convinience {
            .cls-1 {
                fill: none;
            }
            .cls-2 {
                fill: #fff;
            }
            .cls-3 {
                fill: #222;
            }
            .cls-4 {
                fill: #7f170e;
            }
            .cls-5 {
                fill: #eee;
            }
        }
        &#quality {
            .cls-1 {
                fill: none;
            }
            .cls-2 {
                fill: #eee;
            }
            .cls-3 {
                fill: #222;
            }
            .cls-4 {
                fill: #fff;
            }
            .cls-5 {
                fill: #7f170e;
            }
        }
        &#customer-satisfaction {
            .cls-1 {
                fill: none;
            }
            .cls-2 {
                fill: #eee;
            }
            .cls-3 {
                fill: #222;
            }
            .cls-4 {
                fill: #fff;
            }
            .cls-5 {
                fill: #7f170e;
            }
        }
        &#user-interface {
            .cls-1 {
                fill: none;
            }
            .cls-2 {
                fill: #eee;
            }
            .cls-3 {
                fill: #fff;
            }
            .cls-4 {
                fill: #222;
            }
            .cls-5 {
                fill: #7f170e;
            }
        }
    }

    &__parts {
        display: flex;
        padding: 2rem 0 1rem 0;
        justify-content: space-around;

        @media (max-width: 1100px) {
            flex-flow: wrap;
        }
    }

    &__first-sentence {
        font-size: 0.8rem;
        color: #8b0000;
        text-transform: uppercase;
    }

    &__second-sentence {
        padding-top: 0.5rem;
        font-size: 1.5rem;
    }

    &__part {
        display: flex;
        flex-direction: column;
        place-items: center;
        min-width: calc(25% - 1rem);
        padding: 0.5rem;

        @media (max-width: 1100px) {
            min-width: calc(50% - 1rem);
        }
    }

    &__part__icon {
        height: 50px;
        width: 50px;
    }

    &__part__text {
        padding-top: 1rem;
        font-size: 1.1rem;
        color: #565655;
    }
}
