.lediga-tjanster {
    min-height: calc(100vh - 126px);

    main {
        .container {
            display: flex;
            flex-wrap: wrap;
        }
    }

    article {
        padding: 2rem;
        background: white;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        width: calc(100%);

        &:last-child {
            margin-bottom: 0;
        }

        @media (max-width: 420px) {
            padding: 1.5rem;
        }

        @media (min-width: 800px) {
            margin-right: 2rem;
            width: calc(50% - 5rem);

            &:nth-child(2n) {
                margin-right: 0;
            }
        }

        > a {
            display: none !important;
            @media (max-width: 520px) {
                display: flex !important;
                margin-top: 1.5rem;
            }
        }

        > div {
            width: 100%;

            .job-title {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                border-bottom: 1px solid #ddd;
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                @media (max-width: 520px) {
                    a {
                        display: none;
                    }
                }
            }
        }

        @media (max-width: 760px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        h2 {
            margin: 0;
        }

        h3 {
            margin-top: 0.5rem;
            margin-bottom: 0;
            font-weight: 400;
            color: black;
            font-size: 1.2rem;
        }

        ul {
            padding: 0;
            margin: 0;
            column-count: 2;

            @media (max-width: 360px) {
                column-count: 1;
            }

            li {
                list-style: none;
                margin-bottom: 10px;
                line-height: 1.5;

                @media (max-width: 1100px) {
                    display: flex;
                    flex-direction: column;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .philosophy {
        background: #f6f6f6;
        h3 {
            @media (min-width: 900px) {
                width: calc(100% - 2rem);
            }
        }

        .swiper-container {
            margin: 0;
        }

        .values {
            .swiper-slide {
                height: 300px;

                > div {
                    height: calc(100% - 6rem);
                    // color: white;
                    margin: 0 auto;
                    margin: 0px auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 3rem;
                    max-width: 650px;

                    @media (max-width: 900px) {
                        height: calc(100% - 4rem);
                        padding: 2rem;
                        flex-direction: column;
                        align-items: flex-start;
                        max-width: 500px;
                    }

                    h4 {
                        margin: 0;
                        margin-right: 3rem;

                        @media (max-width: 900px) {
                            margin-right: 0;
                        }
                    }

                    p {
                        // color: white;
                        margin-top: 0.5rem;
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__buttons-wrapper {
            display: flex;
            flex-wrap: wrap;
            transform: translateZ(0) !important;
        }

        &__button {
            max-width: calc(11.1111111111% - 4px) !important;
            width: 100% !important;
            /* max-height: calc(33.3333333333% - 4px); */
            /* display: flex; */
            justify-content: center;
            align-items: center;
            /* height: 100%; */
            background: #8b0000;
            color: #fff;
            margin: 2px;
            /* cursor: pointer; */
            font-weight: 500;
            font-size: 1.1rem;
            height: 60px;
            transition: 0.3s ease-in-out;

            @media (max-width: 1350px) {
                max-width: calc(33.3333333333% - 4px) !important;
            }
            &.selected {
                background: black;
            }
        }
    }
}
