.productCardModal {
  margin: 0px !important;
  margin-top: 4px !important;
  width: 99% !important;
  height: 300px !important;
  overflow-y: scroll !important;
  overflow: hidden !important;
  .productCardModal__description {
    display: block !important;
    padding: 8px;
  }
}

.cardMain {
  width: 500px;
  background: #ffffff;
  border: 1px solid #efeeee;
  border-radius: 5px;
  box-shadow: 0px -3px #8b0000;
  overflow: hidden;
  margin: 10px 20px 0 20px;
  padding-bottom: 35px;

  @media screen and (min-width: 790px) and (max-width: 1500px) {
    // width: 350px;
    margin: 10px;
    // height: max-content;
  }
  @media (max-width: 790px) {
    width: 100%;
    max-height: 370px;
  }

  @media (max-width: 500px) {
    margin: 20px 0px;
  }
  .cardMain__heading__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cardMain__cancel {
      font-weight: 700;
      font-size: 24px;
      color: #505050;
      cursor: pointer;
      padding: 10px 19px;
      width: 100%;
      text-align: right;
      border-bottom: 1px solid #8b0000;
      @media screen and (min-width: 768px) and (max-width: 1500px) {
        font-size: 18px;
      }
    }
    .cardMain__heading {
      font-weight: 600;
      font-size: 18px;
      color: #505050;
      padding: 10px 19px;
      width: 100%;
      text-align: left;
      border: 1px solid #efeeee;
      // @media screen and (min-width: 768px) and (max-width: 1500px) {
      //   font-size: 18px;
      // }
    }
  }
  .cardMain__content__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    .cardMain__description {
      font-weight: 400;
      font-size: 16px;
      color: #505050;
      margin: 24px 24px 30px 24px;
      line-height: 26px;
      // @media screen and (min-width: 768px) and (max-width: 1500px) {
      //   font-size: 15px;
      // }
    }
    .cardMain__description--elipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
    }
    .cardMain__rater {
      font-weight: 400;
      font-size: 20px;
      color: #505050;
      margin: 0px 24px;
      @media screen and (min-width: 768px) and (max-width: 1500px) {
        font-size: 15px;
      }
      .cardMain__rater__rating {
        margin-top: 10px;
        @media screen and (min-width: 768px) and (max-width: 1500px) {
          margin-top: 5px;
        }
      }
    }
    .cardMain__loadMore {
      background: #505050;
      border-radius: 5px;
      cursor: pointer;
      // padding: 10px 20px;
      padding: 8px 18px;
      font-weight: 700;
      // font-size: 16px;
      font-size: 15px;
      color: #ffffff;
      margin-right: 30px;
      float: right;
      // @media screen and (min-width: 768px) and (max-width: 1500px) {
      //   font-size: 15px;
      //   padding: 8px 18px;
      // }
    }
  }
}
