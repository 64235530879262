.outnorth {
    .st0 {
        fill: white;
    }
}

.tretti {
    .st0 {
        fill: white;
    }
}

.furniturebox {
    .cls-1 {
        fill: #a2ba5b;
    }
}

.webbhallen {
    .cls-1 {
        fill: #0070ba;
    }
    .cls-2 {
        fill: #fff;
    }
}

.iphonebutiken {
    .cls-1 {
        fill: #00f33f;
    }
    .cls-2 {
        fill: #25314f;
    }
}

.beijer-bygg {
    .st0 {
        fill: #002f6a;
    }
    .st1 {
        fill: #fed141;
    }
}

.greatlife {
    .cls-1 {
        fill: none;
    }
    .cls-2 {
        fill: #000;
    }
}

.kjell-company {
    .st0 {
        fill: #1975bc;
    }
    .st1 {
        fill: #fffffe;
    }
}

.verktygsproffsen {
    .st0 {
        fill: black;
    }
    .st1 {
        fill: #eb6d1e;
    }
}

.yvesrocher {
    .cls-1 {
        fill: black;
    }
}

.litenleker {
    .st0 {
        fill: #665c5d;
    }
    .st1 {
        fill: #ca5410;
    }
}

.babyland {
    .st0 {
        fill: #ffffff;
    }
    .st1 {
        fill: #007bc0;
    }
    .st2 {
        fill: #e40520;
    }
}

.frankdandy {
    .strokey {
        fill: #231f20;
    }
}

.storliten {
    .st0 {
        fill: #d6171a;
    }
}

.staples {
    .st0 {
        fill: #cc0000;
    }
}

.confidentliving {
    .cls-1 {
        fill: #a7a9ac;
    }
    .cls-2 {
        fill: #231f20;
    }
}

.guldfynd {
    .st0 {
        fill: #ffffff;
    }
    .st1 {
        fill: #f6d565;
    }
}

.battrenatter {
    .st0 {
        fill: #271a5c;
    }
    .st1 {
        fill: #ff7200;
    }
}

.sportshopen {
    path {
        fill: black;
    }
}

.byggmax {
    #surface1 {
        path {
            &:nth-child(1) {
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(100%, 88.235294%, 19.607843%);
                fill-opacity: 1;
            }
            &:nth-child(2) {
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(92.156863%, 0%, 11.764706%);
                fill-opacity: 1;
            }
            &:nth-child(3) {
                stroke: none;
                fill-rule: nonzero;
                fill: rgb(92.156863%, 0%, 11.764706%);
                fill-opacity: 1;
            }
        }
    }
}

.proteinworks {
    .a,
    .c {
        fill: #262120;
    }
    .b {
        fill: #e04403;
    }
    .c {
        font-size: 1px;
        font-family: TradeGothicLTStd-BoldExt, Trade Gothic LT Std;
        font-weight: 700;
    }
}

.magnussonsur {
    .cls-1 {
        fill: #14416c;
    }
    .cls-2 {
        fill: #868686;
    }
}

.lookfantastic {
    .cls-1 {
        fill: #000014;
    }
}

.varmepumpshopen {
    .st0 {
        fill: #0f4876;
    }
    .st1 {
        fill: #fec524;
    }
}

.beautycos {
    .st0 {
        fill: black;
    }
    .st1 {
        enable-background: new;
    }
}

.wiggle {
    .st0 {
        display: none;
    }
    .st1 {
        display: inline;
        fill: #3c3c3b;
    }
    .st2 {
        fill: #f49600;
    }
    .st3 {
        fill: black;
    }
}

.burton {
    .st0 {
        fill: #ffffff;
    }
}

.kockumsjernverk {
    .cls-1 {
        fill: none;
    }
    .cls-2 {
        clip-path: url(#clipPath);
    }
    .cls-3 {
        fill: #1d1d1b;
    }
}

.mediamarkt {
    .st0 {
        fill: #df0000;
    }
}

.resvaskor {
    .st0 {
        fill: #ffffff;
    }
    .st1 {
        fill: #5d98d1;
    }
    .st2 {
        fill: #231f20;
    }
    .st3 {
        fill: #020203;
    }
}

.autoexperten {
    .cls-1 {
        fill: black;
    }
}

.casall {
    .line {
        fill: black;
    }
}

.cocopanda {
    .st0 {
        fill: #db006a;
    }
    .st1 {
        fill: #db006a;
        stroke: #db176c;
        stroke-miterlimit: 10;
    }
}

.padelxpert {
    .st0 {
        fill: #e84b4a;
    }
    .st1 {
        fill: #636362;
    }
    .st2 {
        fill: #191919;
    }
    .st3 {
        fill: #3c3c3b;
    }
    .st4 {
        fill: #ffd429;
    }
}

.badmintonshoppen {
    .Arched_x0020_Green {
        fill: url(#SVGID_1_);
        stroke: #ffffff;
        stroke-width: 0.25;
        stroke-miterlimit: 1;
    }
    .st0 {
        fill: #18346d;
    }
    .st1 {
        fill: #4c6196;
    }
    .st2 {
        fill: none;
        stroke: #18346d;
        stroke-width: 2.8347;
    }
}

.svenskhalsokost {
    .st0 {
        fill: black;
    }
}

.svensktkosttillskott {
    .st0 {
        fill: black;
    }
    .st1 {
        fill: #ffd100;
    }
}

.grandpa {
    .st0 {
        fill: #111111;
    }
}

.lekia {
    .cls-1 {
        fill: #ffed00;
    }
    .cls-2,
    .cls-3 {
        fill: #e30613;
    }
    .cls-2 {
        stroke: #e30613;
    }
    .cls-2,
    .cls-4 {
        stroke-miterlimit: 3.864;
        stroke-width: 0.227px;
    }
    .cls-3 {
        fill-rule: evenodd;
    }
    .cls-4 {
        fill: none;
        stroke: #e52613;
    }
}

.eleven {
    .st0 {
        fill: #005a64;
    }
}

.inkclub {
    .cls-1 {
        fill: #e40082;
    }
    .cls-2 {
        fill: #fff;
    }
}

.footpatrol {
    .st0,
    .st1,
    .st2,
    .st3 {
        fill: white;
    }
}
.gaminggadgets {
    .cls-1 {
        isolation: isolate;
    }
    .cls-2 {
        fill: url(#Namnlös_övertoning_44);
    }
    .cls-3 {
        mix-blend-mode: multiply;
    }
    .cls-4 {
        fill: url(#Namnlös_övertoning_66);
    }
    .cls-5 {
        fill: url(#Namnlös_övertoning_66-2);
    }
    .cls-6 {
        fill: #f29241;
    }
    .cls-7 {
        opacity: 0.58;
        fill: url(#Namnlös_övertoning_35);
    }
    .cls-8 {
        fill: black;
    }
}

.tcmcykel{
    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
    .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#510C76;}
    .st2{fill:#FFFFFF;}
    .st3{fill-rule:evenodd;clip-rule:evenodd;}
    .st4{fill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:#000000;stroke-width:16;stroke-miterlimit:10;}
}