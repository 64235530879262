.konsult {
    padding: 44px 2rem 0rem 2rem;
    background-color: #000;
    color: white;
    height: 100vh;

    @media (max-width: 450px) {
        padding: 10rem 2rem 0rem 2rem;
    }

    .container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        height: calc(100vh - 44px - 6rem);

        div {
            display: flex;
            align-items: center;
            flex-direction: column;

            h1 {
                font-size: 7.5rem;
                padding: 0;
                margin: 0;
                font-weight: 500;

                @media (max-width: 900px) {
                    font-size: 2.5rem;
                }
            }

            p {
                font-size: 1.2rem;
                @media (max-width: 900px) {
                    text-align: left;
                    font-size: 1rem;
                }
            }
        }
    }
    .button-cta {
        background: #e41513;
        border: 0;
        padding: 1rem 2.5rem;
        color: white;
        font-weight: 500;
        font-size: 1.1rem;
        border-radius: 3rem;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;

        @media (max-width: 400px) {
            font-size: 1rem;
            padding: 1rem;
            text-align: center;
        }

        &:hover {
            background: darken(#e41513, 10%);
        }
    }
}
