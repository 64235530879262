.cheeseburger-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    pointer-events: all;
    transition: 0.3s ease-in-out;
    background: transparent;
    height: 45px;
    width: 45px;
    margin-right: -12.5px;

    @media (min-width: 1402px) {
        display: none;
    }

    .menu-toggle {
        width: 20px;
        height: 18px;
        position: relative;
        top: 1.5px;

        span {
            height: 0.17rem;
            width: 20px;
            display: block;
            background: white;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: 0.2s ease-in-out;
            position: absolute;

            &:nth-child(1) {
                top: 0px;
                transform-origin: left center;
            }

            &:nth-child(2) {
                top: 7px;
                transform-origin: left center;
            }

            &:nth-child(3) {
                top: 14px;
                transform-origin: left center;
            }
        }

        &.isOpen {
            span {
                &:nth-child(1) {
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    width: 0%;
                    opacity: 0;
                    transition: 0.2s;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

#mobile-menu #nav {
    left: 0;
    top: 99px;
}

#main-menu #nav {
    right: 0;
    top: 50px;
}

#nav {
    display: none;
    position: fixed;
    background-color: #f6f6f6;
    // background: #fff;
    width: 25rem;
    // right: 0;
    // top: 50px;
    padding: 0rem 1rem;
    max-width: calc(100vw - 2rem);

    .font-weight {
        &__500 {
            font-weight: 500;
        }
    }

    .svg {
        &__go-next {
            transform: rotate(-90deg);
        }
    }

    & > ul:nth-child(3) li {
        display: unset;
        background-color: #111111;
        & > a {
            padding: 0rem 1rem;
            margin-top: 1rem;
            width: unset;
            // background-color: #f6f6f6;
            background: #fff;
        }
    }

    &.isOpen {
        display: unset;
        height: 100vh;
        overflow-y: auto;
    }

    .first-child {
        display: none;

        &.visible {
            display: flex;
        }
    }

    .category-title {
        place-items: center;
        place-content: space-between;
        display: none;
        &.visible {
            display: flex;
        }
        border-bottom: 2px solid black;

        > div {
            padding: 12px;
            display: flex;
            align-items: center;
        }

        > span {
            font-size: 1.2rem;
            font-weight: 500;
        }

        path {
            fill: #000;
            stroke: #000;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        width: 100%;
        list-style: none;
        display: none;

        &.visible {
            display: unset;
        }

        &:nth-child(2) {
            padding-top: 0;
        }
    }

    li {
        margin: 0;
        padding: 0;
        display: none;
        place-content: space-between;
        align-items: center;
        // background-color: #f6f6f6;
        background: #fff;
        border-bottom: 2px solid #f6f6f6;
        cursor: pointer;
        &.visible {
            display: flex;
        }

        & > a {
            width: 100%;
        }
    }

    .breadcrumbs {
        display: none;
        place-content: center;
        height: 2rem;

        & > li {
            height: 2rem;
            display: flex;
            padding: 0rem 0.25em;
            border-bottom: none;
        }

        & > li + li:before {
            content: "\3E";
            padding-right: 0.5rem;
        }

        &.visible {
            display: flex;
        }
    }

    svg {
        height: 18px;
        width: 18px;
    }

    path,
    polygon {
        fill: #ababab;
        stroke: #ababab;
    }

    a,
    span {
        min-height: 42px;
        place-items: center;
        display: flex;
    }

    h2 {
        font-size: 1.5rem;
        font-weight: 500;
    }

    h2,
    .category-title,
    li,
    a {
        color: #000;
        & > span {
            display: flex;
            padding: 0rem 1rem;
        }
    }
}

.nav-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 998;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    opacity: 0;
    pointer-events: none;

    &.isOpen {
        pointer-events: all;
        transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        opacity: 1;
    }
}
