.reaperioder {
    min-height: calc(100vh - 126px);
    background: #f6f6f6;

    .breadcrumbs{
        padding-left: 2rem;
    }

    header {
        .container {
            display: flex;
            justify-content: space-between;
        }

        .meta {
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            color: #7c7c7c;

            span {
                display: flex;
                align-items: center;

                &:before {
                    content: "";
                    height: 12px;
                    width: 12px;
                    display: block;
                    background: lightgreen;
                    border-radius: 50%;
                    margin-right: 0.75rem;
                }
            }
        }
    }

    .breadcrum_info {
        display: flex;
        justify-content: center;

        .breadcrumbs__child {
            &:first-child {
                display: none;
            }
        }
    }

    .reaperiod-hero {
        background-color: #222;
        height: 350px;
        position: relative;

        > span {
            height: 100% !important;
            width: 100% !important;
        }

        .container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            color: white;

            p {
                max-width: 60rem;
                width: 100%;
                font-size: 1.4rem;
                @media (max-width: 950px) {
                    font-size: 1.2rem;
                }
            }
        }

        .skeleton-wrapper{
            height: 100%;

            > span{
                width: 100vw !important;
                height: 100% !important;
            }

            img {
                height: 350px;
                opacity: 0.15;
                width: 100%;
                height: 100%;
                position: absolute;
                filter: grayscale(100%);
                object-position: bottom;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }

    }

    main {
        &.container {
            display: flex;
            padding-bottom: 3rem;

            .article-wrapper {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .article-content {
                position: relative;
                width: 100%;

                p{
                    color: #6a6a6a;
                }

                .article-intro {
                    background-color: white;
                    padding: 3rem;
                    margin-bottom: 3rem;
                    @media (max-width: 950px) {
                        padding: 2rem;
                    }

                    p {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                img {
                    margin-bottom: 1rem !important;
                }

                div {
                    > ul {
                        padding-left: 1rem;

                        li {
                            line-height: 1.5;
                            color: #6a6a6a;

                            h3 {
                                margin: 0;
                            }

                            p {
                                margin-top: 0.5rem;
                            }
                        }
                    }
                }

                a {
                    text-decoration: underline;
                }
            }

            aside {
                margin-top: -4px;
                margin-left: 3rem;
                max-width: 320px;
                width: 100%;
                align-self: flex-start;
                position: sticky;
                top: calc(44px + 3rem);

                @media (max-width: 950px) {
                    display: none;
                }

                h3 {
                    margin: 0;
                    height: 88px;
                    padding-left: 2rem;
                    font-size: 1.6rem;
                    user-select: none;
                    line-height: 1.25;
                    position: absolute;
                    z-index: 99;
                    background: white;
                    display: flex;
                    align-items: center;
                    right: 0;
                    left: 0;
                    top: 0;
                }

                .swiper-button-next,
                .swiper-button-prev {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    transition: 0.3s ease-in-out;
                    background: #e6e6e6;
                    top: 3rem;
                    z-index: 150;

                    &:hover {
                        background: #cdcdcd;
                    }

                    &:after {
                        font-size: 1rem;
                        font-weight: 600;
                        color: #454545;
                    }
                }

                .swiper-button-next {
                    transform: rotate(90deg);
                    right: 2rem;
                }

                .swiper-button-prev {
                    transform: rotate(90deg);
                    right: 5rem;
                    left: unset;
                }

                .swiper-container {
                    padding-top: 5.5rem;
                }

                .swiper-wrapper {
                    max-height: 428px;
                    height: 100%;
                }

                .swiper-slide {
                    display: flex;
                    align-items: center;
                    background: white;
                    margin-top: 4px;
                    padding: 1rem 2rem;
                    cursor: pointer;
                    width: unset;

                    &:hover {
                        background: #ffffff75;

                        .heading {
                            text-decoration: underline;
                        }
                    }

                    >div {
                        &:nth-child(1) {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            font-size: 1.2rem;
                            font-weight: 500;
                            border: 2px solid rgba(black, 0.1);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 1rem;
                        }

                        svg {
                            height: 25px;
                            max-width: 50%;
                        }
                    }

                    a {
                        width: 100%;

                        .heading-4 {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                    }

                }
            }
        }
    }

    article {
        transition: 0.3s ease-in-out;
        background: black;
        overflow: hidden;
        margin-bottom: 2rem;
        flex-basis: calc(50% - 1rem);
        height: 250px;
        overflow: hidden;
        position: relative;
        color: white;

        @media (max-width: 1200px) {
            flex-basis: 100%;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:hover {
            div > span {
                img {
                    opacity: 0.35;
                    filter: grayscale(0);
                }
            }
        }

        div > span {
            img {
                transition: 0.3s ease-in-out;
                opacity: 0.25;
                filter: grayscale(50%);
            }
        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            color: white;
            padding: 3rem;
            height: calc(100% - 6rem);
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media(max-width: 1180px){
                height: calc(100% - 4rem);
                padding: 2rem;
            }

            time {

                span {
                    display: flex;
                    align-items: center;

                    &:before {
                        content: "";
                        height: 10px;
                        width: 10px;
                        display: block;
                        background: lightgreen;
                        border-radius: 50%;
                        margin-right: 0.5rem;
                    }
                }
            }

            > div {
                margin: 0.5rem 0;
            }

            h2 {
                margin: 0;
            }

            p {
                margin: 0;
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                opacity: 0.75;
            }

            footer{
                margin-top: auto;
            }

        }
    }
}
