.ProductImageSection {
  display: flex;
  justify-content: center;
  // align-self: center;
  // width: 45%;
  // height: 765px;
  height: 525px;
  // @media screen and (min-width: 768px) and (max-width: 1500px) {
  //   height: 560px;
  // }
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    height: max-content;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }

  .ProductImageSection__sideImages {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin-left: -8px;

    @media (max-width: 1100px) {
      margin-left: 0px;
      flex-direction: row;
    }

    .ProductImageSection__sideImages__sideImage {
      width: 130px;
      height: 130px;
      border: 1px solid #efeeee;
      background-color: white;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 8px;
      cursor: pointer;
      @media screen and (min-width: 768px) and (max-width: 1500px) {
        width: 90px;
        height: 90px;
      }
      @media (max-width: 1000px) {
        width: 80px;
        height: 80px;
        padding: 10px;
      }

      @media (max-width: 500px) {
        margin: 4px;
        width: 60px;
        height: 60px;
        padding: 10px;
      }
    }
    .ProductImageSection__sideImages__sideImage--active {
      border: 2px solid #505050;
    }
  }

  .ProductImageSection__image {
    background-color: white;
    border-left: 1px solid #efeeee;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    align-items: center;
    @media (max-width: 1100px) {
      width: 100%;
      border-left: none;
    }

    @media (max-width: 1000px) {
      height: 600px;
      width: 100%;
    }
    @media (max-width: 500px) {
      height: 400px;
    }

    .ProductImageSection__image__discount {
      padding: 4px 20px;
      font-weight: 400;
      font-size: 22px;
      color: black;
      background: #ffdd05;
      border-radius: 0px 5px 5px 0px;
      position: absolute;
      left: 0;
      top: 30px;
      z-index: 1;

      @media (max-width: 500px) {
        font-size: 17px;
        top: 20px;
      }
    }
    .ProductImageSection__image__stock {
      padding: 4px 20px;
      width: 150px;
      text-align: center;
      transform: rotate(-45deg);
      font-size: 18px;
      color: #ffffff;
      transform: rotate(-45deg);
      position: absolute; 
      right: -40px;
      bottom: 40px;
      z-index: 1;

      @media (max-width: 500px) {
        width: 130px;
        right: -35px;
        bottom: 35px;
      }
    }
    .ProductImageSection__image__zoom {
      margin: auto 0;
    }
    .ProductImageSection__image__instock {
      background: #68c000;
    }
    .ProductImageSection__image__outofstock {
      background: #eb1313;
    }
    .ProductImageSection__image__order-item {
      background: #e96100;
    }
  }
}
