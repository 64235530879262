.artikel {
  background: #f6f6f6;

  .breadcrumbs {
    padding-left: 2rem;
  }

  .main-header {
    position: relative;
    z-index: 3;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }

      > div {
        display: flex;
        align-items: center;
        min-width: 225px;
        justify-content: flex-end;

        @media (max-width: 600px) {
          min-width: auto;
          margin-top: 2rem;
          justify-content: flex-start;
        }

        .avatar {
          border-radius: 50%;
          margin-right: 1rem;
          background: rgba(black, 0.1);
          height: 35px;
          width: 35px;
        }

        .article-meta {
          display: flex;
          flex-direction: column;
          color: black;
          font-weight: 500;
          font-size: 1.1rem;

          > div {
            font-weight: 400;
          }

          time {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .breadcrum_info {
    justify-content: center;

    .breadcrumbs__child {
      &:first-child {
        display: none;
      }
    }
  }

  .content-wrapper {
    min-height: calc(100vh - 131px - 490px);
  }

  .intro-text {
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  .main-content {
    display: flex;
    padding-bottom: 3rem;

    @media (max-width: 1000px) {
      padding-top: 2rem;
      flex-direction: column;
    }

    section {
      margin-top: 1rem;
    }

    main {
      flex-basis: auto;

      @media (max-width: 1000px) {
        flex-basis: 100%;
        margin-right: unset;
      }
    }

    .categories {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;

      &:empty {
        display: none;
      }

      a {
        padding: 5px 10px;
        color: black;
        background: #e6e6e6;
        text-transform: capitalize;
        display: flex;
        justify-content: space-between;
      }
    }

    aside {
      margin-top: -4px;
      margin-left: 3rem;
      max-width: 320px;
      width: 100%;
      align-self: flex-start;
      position: sticky;
      top: calc(44px + 3rem);

      @media (max-width: 1000px) {
        display: none;
      }

      h3 {
        margin: 0;
        height: 88px;
        padding-left: 2rem;
        font-size: 1.6rem;
        user-select: none;
        line-height: 1.25;
        position: absolute;
        z-index: 99;
        background: white;
        display: flex;
        align-items: center;
        right: 0;
        left: 0;
        top: 0;
      }

      .swiper-button-next,
      .swiper-button-prev {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        transition: 0.3s ease-in-out;
        background: #e6e6e6;
        top: 3rem;
        z-index: 150;

        &:hover {
          background: #cdcdcd;
        }

        &:after {
          font-size: 1rem;
          font-weight: 600;
          color: #454545;
        }
      }

      .swiper-button-next {
        transform: rotate(90deg);
        right: 2rem;
      }

      .swiper-button-prev {
        transform: rotate(90deg);
        right: 5rem;
        left: unset;
      }

      .swiper-container {
        padding-top: 5.5rem;
      }

      .swiper-wrapper {
        max-height: 444px;
        height: 100%;
      }

      .swiper-slide {
        display: flex;
        align-items: center;
        background: white;
        margin-top: 4px;
        padding: 1rem 2rem;
        cursor: pointer;
        width: unset;

        &:hover {
          background: #ffffff75;

          .heading {
            text-decoration: underline;
          }
        }

        > div {
          &:nth-child(1) {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-size: 1.2rem;
            font-weight: 500;
            border: 2px solid rgba(black, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1rem;
          }

          svg {
            height: 25px;
            max-width: 50%;
          }
        }

        a {
          width: 100%;

          .heading-4 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-top: 4px;
          margin-right: 4px;
          width: calc(50% - 2px);

          &:nth-child(even) {
            margin-right: 0;
          }

          a {
            display: block;
            padding: 5px 10px;
            color: black;
            background: #e6e6e6;
            text-transform: capitalize;
            display: flex;
            justify-content: space-between;

            &:hover {
              background: darken(#e6e6e6, 10%);
            }

            span {
              opacity: 0.75;
            }
          }
        }
      }
    }
  }

  &__top-image {
    height: 25rem;
    overflow: hidden;
    margin: 0 -18vw;

    .skeleton-wrapper {
      > div {
        position: unset !important;

        img {
          position: unset !important;
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }

  &__content-wrapper {
    img {
      max-width: 100%;
      height: auto;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.5;
      margin: 1rem 0;
      display: block;
    }

    h2 {
      font-size: 1.5rem;
      color: #e41513;
    }

    h3 {
      font-size: 1.4rem;
    }

    h4 {
      font-size: 1.3rem;
    }

    p {
      color: #707070;
    }

    a {
      text-decoration: underline;
    }

    hr {
      height: 1px;
      border: 0;
      margin: 2rem 0;
      background: #ddd;
    }
  }
}
