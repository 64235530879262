.custom500 {
    background: black;
    padding: 1rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: futura-pt, sans-serif;
    font-size: 1.1rem;
    height: 95vh;
    overflow: hidden;

    main {
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        position: relative;
    }

    h1 {
        font-size: 2rem;
        font-weight: 500;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        line-height: 1.8;
    }

    h2 {
        font-size: 0.9rem;
        font-weight: 300;
        opacity: 0.75;
    }

    .jiroy-logo {
        fill: white;
    }

    .jiroy-logo .st1 {
        fill: #e41513;
    }

    .nav-list.social {
        margin-top: 2rem;
        display: -moz-box;
        display: flex;
        justify-content: center;
    }

    .nav-list.social li {
        height: 40px;
        display: -moz-box;
        display: flex;
        -moz-box-align: center;
        align-items: center;
        margin-right: 1.5rem;
    }

    .nav-list.social li a {
        height: 37px;
        width: 37px;
        background: #1a1a1a;
        display: -moz-box;
        display: flex;
        -moz-box-align: center;
        align-items: center;
        -moz-box-pack: center;
        justify-content: center;
        border-radius: 50%;
    }

    .nav-list.social li a svg {
        height: 17px;
        fill: #fff;
        line-height: 1;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
    }

    .error {
        opacity: 0.75;
    }

    .cf-error-details {
        opacity: 0.2;
    }

    .cf-error-details h1 {
        display: none !important;
    }

    .cf-error-details ul {
        list-style: none !important;
        padding: 0 !important;
    }

    .info {
        max-width: 260px;
        margin: 0 auto;
    }
}
