p {
  margin: 0;
}

.newPrLabel {
  font-weight: 600;
  // font-size: 20px;
  font-size: 16px;
  line-height: 26px;
  color: #505050;
  // margin-top: 22px;
  margin-top: 8px;
  // margin-bottom: 10px;
  margin-bottom: 1px;
  // @media screen and (min-width: 768px) and (max-width: 1500px) {
  //   margin-bottom: 1px;
  //   margin-top: 8px;
  //   font-size: 18px;
  // }
  @media (max-width: 768px) {
    margin-bottom: 6px;
    margin-top: 16px;
    font-size: 17px;
  }
}

.product-details__top-right {
  &__heading {
    margin-top: 8px;
    font-weight: 700;
    // font-size: 36px;
    font-size: 24px;
    color: #505050;
    // @media screen and (min-width: 768px) and (max-width: 1500px) {
    //   font-size: 24px;
    // }
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  &__price {
    font-weight: 700;
    font-size: 40px;
    color: #8b0000;
    @media (max-width: 768px) {
      font-size: 34px;
    }

    span {
      font-weight: 700;
      font-size: 24px;
      text-decoration-line: line-through;
      color: #a2a1a1;
      margin-left: 20px;
      @media screen and (min-width: 768px) and (max-width: 1500px) {
        font-size: 18px;
      }
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  &__amountSaved {
    font-weight: 700;
    font-size: 16px;
    color: #505050;
  }

  &__description {
    @media (min-width: 1001px) {
      min-height: 344px;
    }
  }

  .product-details__top-right__bottomButtons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    padding-bottom: 11px;

    a {
      @media (max-width: 561px) {
        width: 100%;
      }
    }

    @media (max-width: 1000px) {
      justify-content: center;
    }
    .product-details__top-right__button {
      &.dark {
        background-color: black;
        color: white;
      }

      &.light {
        background-color: white;
        color: #505050;
      }
      width: 248px;
      display: flex;
      border-radius: 5px;
      border: 1px solid #505050;
      margin: 11px 11px 0px 0px;
      cursor: pointer;
      min-height: 2.5rem;

      @media (max-width: 561px) {
        margin: 11px 0px 0px 0px;
        width: 100%;
      }

      .product-details__top-right__button__text {
        padding-left: 10px;
        font-weight: 700;
        font-size: 18px;
      }
    }
    .product-details__top-right__button__left-part {
      padding: 4px 16px;
      display: flex;
      align-items: center;
      min-width: 1.3rem;

      svg {
        height: 18px;
        width: 20px;
      }
    }
    .product-details__top-right__button__text {
      &.light {
        color: black;
      }
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 16px 4px 0px;
      a {
        color: #ffffff;
      }
    }
  }
}

.product-details__bottom-right {
  // @media (max-width: 1000px) {
  //   margin-left: 0px;
  //   width: 100%;
  //   margin-top: 23px;
  // }

  .product-details__bottom-right__heading {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    color: #505050;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
  .product-details__bottom-right__price {
    font-weight: 700;
    font-size: 40px;
    color: #8b0000;
    @media (max-width: 768px) {
      font-size: 34px;
    }

    span {
      font-weight: 700;
      font-size: 24px;
      text-decoration-line: line-through;
      color: #a2a1a1;
      margin-left: 20px;
      @media screen and (min-width: 768px) and (max-width: 1500px) {
        font-size: 18px;
      }
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
  .product-details__bottom-right__amountSaved {
    font-weight: 700;
    font-size: 16px;
    color: #505050;
  }
  .product-details__bottom-right__sizesAndPerson {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .product-details__bottom-right__sizesAndPerson__availableSizes {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-left: -10px;

      .product-details__bottom-right__sizesAndPerson__availableSizes__size {
        width: 80px;
        height: 45px;
        background: rgba(239, 238, 238, 0.7);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 6px 12px;
        @media (max-width: 768px) {
          width: 65px;
          height: 28px;
        }
        @media screen and (min-width: 768px) and (max-width: 1500px) {
          margin: 3px 8px;
          height: 25px;
          font-size: 14px;
        }
      }
    }
    .product-details__bottom-right__sizesAndPerson__soldBy {
      padding: 6px 10px;
      background: rgba(239, 238, 238, 0.7);
      border-radius: 5px;
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        color: #505050;
        margin-left: 10px;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
  .product-details__bottom-right__payment {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -10px;

    .product-details__bottom-right__payment__method {
      display: flex;
      justify-content: center;
      width: 70px;
      height: 18px;
      background: rgba(239, 238, 238, 0.7);
      border-radius: 5px;
      padding: 10px;
      margin: 0px 9px 10px 9px;
      @media (max-width: 768px) {
        width: 65px;
        height: 15px;
        padding: 10px 6px;
        margin: 0px 7px 7px 7px;
      }
    }

    .product-details__bottom-right__payment__delivery {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 230px;
      background: rgba(239, 238, 238, 0.7);
      border-radius: 5px;
      margin: 0px 0px 12px 9px;
      padding: 6px 10px;

      @media (max-width: 768px) {
        padding: 5px 10px;
        margin: 0px 5px 10px 7px;
        width: 40%;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        color: #505050;
        margin-left: 10px;
        @media screen and (min-width: 768px) and (max-width: 1500px) {
          font-size: 16px;
        }
      }
    }
  }
}
