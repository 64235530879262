.statsUpOrDown {
  padding: 1px 7px;
  width: max-content;
  background: rgba(131, 191, 79, 0.25);
  color: #68c000;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 8px 10px;

  p {
    font-size: 16px;
  }

  .statsUpOrDown__text--margin {
    margin-left: 5px;
  }
}

.statsUpOrDown--notInStock {
  background: rgba(139, 0, 0, 0.25);
  color: #8b0000;
}

.statisticsCard {
  background-color: white;

  &__header {
    display: flex;
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #505050;
  }

  &__chart {
    height: 360px;
    cursor: pointer;
  }
}
