.categories-wrapper {
  &__desktop {
    padding: 1rem 0rem;
    background: #560d09;
    min-height: 86.4px;

    .skeleton-wrapper {
      height: 86.4px; // removes the extra padding under the image
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
      > span {
        border-radius: 7px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-evenly;
    }

    a {
      color: white;
    }

    &__item {
      position: relative;

      background: black;
      border-radius: 7px;
    }

    &__title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.3rem;
      font-weight: 500;
      pointer-events: none;
    }
  }

  &__mobile {
    background: #560d09;
    padding: 1rem 0rem calc(1rem - 5px) 0rem;
    min-height: 82.6px;

    .skeleton-wrapper {
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
      > span {
        border-radius: 7px;
      }
    }

    .swiper {
      width: 100%;
      height: 100%;
    }

    a {
      color: white;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      background: #560d09;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    // .swiper-slide img {
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   object-fit: cover;
    // }
    // .swiper-slide {
    //   width: 10rem !important;
    //   display: flex;
    //   height: 3rem;
    //   background: red;
    // }
  }
}
