.error-404 {
    padding: 44px 2rem 0rem 2rem;

    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: calc(100vh - 44px - 6rem);

        .button-cta {
            background: #e41513;
            border: 0;
            padding: 1rem 2.5rem;
            color: white;
            font-weight: 500;
            font-size: 1.1rem;
            display: flex;
            align-items: center;
            border-radius: 3rem;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            margin-top: 1rem !important;

            &:hover {
                background: darken(#e41513, 10%);
            }
        }

        div {
            &:nth-child(1) {
                display: flex;
                align-items: center;
                flex-direction: column;

                @media (min-width: 900px) {
                    flex-direction: row;
                }

                h1 {
                    font-size: 7.5rem;
                    padding: 0;
                    margin: 0;
                    font-weight: 500;

                    @media (min-width: 900px) {
                        margin-right: 3.5rem;
                    }
                }

                p {
                    font-size: 1.2rem;
                    @media (max-width: 900px) {
                        text-align: center;
                    }
                }
            }
        }
    }
}
