.home {
  overflow: hidden;
  width: 100%;

  .display__flex {
    display: flex;
  }

  #hero {
    position: relative;
    height: calc(100vh - 144px);
    display: flex;
    align-items: center;
    margin-top: 161px;

    @media (max-width: 709px) {
      margin-top: calc(207px);
    }

    @media (max-width: 1401px) {
      height: calc(100vh - 175px);
      margin-top: 117px;
    }

    @media (max-width: 991px) {
      height: calc(100vh - 175px);
      margin-top: 167px;
    }

    &__text {
      z-index: 3;
      color: white;
      margin-bottom: 3rem;
      margin-left: 1rem;

      @media (min-width: 801px) {
        margin-left: 5rem;
      }

      h1 {
        // font-size: 8rem;
        font-size: 5rem;

        // @media (min-width: 560px) and (max-width: 650px) {
        //   font-size: 7rem;
        // }
        // @media (min-width: 480px) and (max-width: 559px) {
        //   font-size: 6rem;
        // }
        // @media (min-width: 405px) and (max-width: 479px) {
        //   font-size: 5rem;
        // }
        // @media (min-width: 330px) and (max-width: 404px) {
        //   font-size: 4rem;
        // }
        @media (min-width: 441px) and (max-width: 545px) {
          font-size: 4rem;
        }

        @media (min-width: 336px) and (max-width: 440px) {
          font-size: 3rem;
        }

        @media (max-width: 335px) {
          font-size: 2.5rem;
        }
      }

      h2 {
        font-size: 1.5rem;

        // @media (min-width: 520px) and (max-width: 650px) {
        //   font-size: 1.5rem;
        // }
        @media (min-width: 491px) and (max-width: 519px) {
          font-size: 1.4rem;
        }

        @media (min-width: 460px) and (max-width: 491px) {
          font-size: 1.3rem;
        }

        @media (min-width: 430px) and (max-width: 459px) {
          font-size: 1.2rem;
        }

        @media (min-width: 400px) and (max-width: 429px) {
          font-size: 1.1rem;
        }

        @media (max-width: 399px) {
          font-size: 1rem;
        }

        &:nth-child(2) {
          @media (min-width: 375px) {
            margin: 0.5rem 2rem;
          }

          @media (min-width: 355px) and (max-width: 374px) {
            margin: 0.5rem 1.5rem;
          }

          @media (min-width: 345px) and (max-width: 354px) {
            margin: 0.5rem 1.25rem;
          }

          @media (min-width: 100px) and (max-width: 344px) {
            margin: 0.5rem 0.5rem;
          }
        }
      }
    }

    // Animation class for toggle video
    &.show-video {
      .button-wrapper {
        button {
          &:nth-child(1) {
            opacity: 0 !important;
            pointer-events: none;
            display: none !important;
          }

          &:nth-child(2) {
            opacity: 1 !important;
            display: block !important;
          }
        }
      }

      .overlay {
        opacity: 0;
        pointer-events: none;
      }

      .swiper-overlay {
        animation-play-state: paused;
        opacity: 0 !important;
      }
    }

    .video-overlay {
      z-index: 3;
      background-color: #eee8e8;
      display: flex;
      flex-direction: column;
      padding: 0.75rem 0 1.5rem 0;
      // padding: 0.75rem;
      border-radius: 10px;
      margin-bottom: 2rem;
      width: 34rem;
      align-items: center;
      margin: 0 0.5rem;

      // @media (max-width: 425px) {
      //   max-width: 18rem;
      // }

      h1 {
        color: black;
        font-size: 2.5rem;
        // padding-left: 25.5px;
        margin: 0.5rem 0;

        @media (max-width: 532px) {
          font-size: 1.9rem;
        }
      }

      h2 {
        color: black;
        font-weight: 500;
        font-size: 1.3rem;
        margin-left: 0.1rem;

        @media (max-width: 414px) {
          font-size: 0.94rem;
        }

        @media (min-width: 390px) and (max-width: 413px) {
          font-size: 0.86rem;
        }

        @media (min-width: 375px) and (max-width: 389px) {
          font-size: 0.82rem;
        }

        @media (max-width: 374px) {
          font-size: 0.77rem;
        }
      }

      &__sentences {
        display: flex;

        .check {
          height: 1rem;
          width: 1rem;

          path {
            fill: #008484;
          }
        }

        &__sentence {
          display: flex;
          align-items: center;
          font-size: 0.5rem;
          margin: 0 0.5rem;

          // @media (max-width: 425px) {
          //   &:nth-child(2){
          //     margin: 0;
          //   }
          // }
        }
      }
    }

    .video-swiper {
      background: #e41513;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      display: flex;

      video {
        object-fit: cover;
        width: 100vw !important;
      }

      .swiper-overlay {
        // background-size: 400% 400%;
        // background: linear-gradient(
        //   147deg,
        //   rgba(#c62128, 0.75),
        //   rgba(#a00000, 0.75),
        //   rgba(#c62128, 0.75),
        //   rgba(#a00000, 0.75)
        // );
        // background-size: 400% 400%;
        // animation: Gradient 10s ease infinite;
        // transition: 0.3s ease-in-out;
        // position: absolute;
        // z-index: 2 !important;
        // left: 0;
        // right: 0;
        // top: 0;
        // bottom: 0;
        // height: 100%;
        // width: 100%;
        // opacity: 1;

        position: absolute;
        z-index: 2 !important;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        opacity: 0.65;
        background: black;
      }

      .swiper-slide {
        overflow: hidden;
      }

      .prev,
      .next {
        height: 120px;
        width: 120px;
        border-radius: 50%;
        background: black;
        top: calc(50% - 4rem);
        display: flex;
        justify-content: center;
        align-items: center;

        &.swiper-button-disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        svg {
          fill: white;
          height: 42px;
          width: 42px;
          position: relative;
        }
      }

      .prev {
        left: -60px;

        svg {
          margin-top: -3rem;
        }
      }

      .next {
        right: -60px;

        svg {
          margin-top: -3rem;
        }
      }

      .swiper-pagination {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 2rem;
        z-index: 99;
        display: flex;
        justify-content: center;

        >span {
          height: 4px;
          width: 70px;
          margin: 0 0.5rem;
          display: block;
          transition: 0.3s ease-in-out;
          background: rgba(black, 0.15);
          position: relative;

          &:hover {
            cursor: pointer;
            background: rgba(black, 0.45);
          }

          &:after {
            content: "";
            position: absolute;
            z-index: -1;
            top: -18px;
            height: 40px;
            width: 100%;
          }

          &.swiper-pagination-bullet-active {
            &:before {
              content: "";
              height: 100%;
              animation: Pagination 30s ease-out;
              background: black;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }

    .video-button-wrapper {
      position: absolute;
      z-index: 150;
      bottom: 4rem;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: fadeIn 0.3s ease-in-out 2.5s both;

      button {
        border: 0;
        padding: 0.5rem 1.5rem;
        background: rgba(black, 0.35);
        color: white;
        font-weight: 500;
        font-size: 1.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3rem;
        cursor: pointer;

        &:nth-child(2) {
          display: none;
          opacity: 0;
        }

        &:hover {
          background: black;
        }
      }
    }

    .typewriter {
      margin-left: 2rem;
      font-size: 1.1rem;
      position: relative;
      min-height: 23px;
      pointer-events: none;
      user-select: none;
      animation: hideTypeWriter 0.3s ease-in-out 0.9s both;

      &:after {
        content: "";
        height: 100%;
        width: 1px;
        display: block;
        animation: blink 1s step-start 0s infinite;
        background: #000;
        position: absolute;
        right: -2px;
        top: 0;
        bottom: 0;
      }
    }
  }

  .seo {
    background: black;
    color: white;
    padding: 3rem 0;

    .container {
      display: flex;

      article {
        padding: 3rem;

        p {
          color: rgba(white, 0.55);

          &.intro-paragraph {
            font-size: 1.4rem;
            color: white;
            margin-top: 0;
          }
        }

        &:nth-child(1) {}

        a {
          color: white;
        }
      }
    }
  }
}