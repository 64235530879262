#marquee-wrapper {
    position: relative;
    overflow-x: visible !important;
    // min-height: 92px;
    min-height: 50px;
    background: #8b0000;
    // border-bottom: 1px solid black;

    // &:before {
    //     content: "";
    //     height: 100%;
    //     width: 60px;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     background: linear-gradient(to right, #8b0000 0%, rgba(0, 0, 0, 0) 100%);
    //     z-index: 3;
    // }

    // &:after {
    //     content: "";
    //     height: 100%;
    //     width: 60px;
    //     position: absolute;
    //     right: 0;
    //     top: 0;
    //     background: linear-gradient(to left, #8b0000 0%, rgba(0, 0, 0, 0) 100%);
    //     z-index: 3;
    // }

    p {
        background: black;
        width: 100%;
        color: rgba(white, 0.75);
        z-index: 2;
        display: block;
        left: 0;
        right: 0;
        text-align: center;
        padding-top: 0.75rem;
        padding-bottom: 5px;
        font-size: 1rem;
        margin: 0;
        @media (max-width: 850px) {
            font-size: 14px;
        }
    }

    .marquee-container {
        height: 50px;
        // background: #8b0000;
        color: white;
        font-size: 1.6rem;

        .marquee {
            height: 50px;
        }

        svg {
            height: 18px;
            margin: 0 2vw;
            animation: fadeIn 0.3s ease-in-out 0s both;
        }

        svg {
            path,
            rect,
            polygon {
                fill: white !important;
            }

            path {
                &.guldfynd-bokstav {
                    fill: black !important;
                }
            }
        }

        .marquee-string {
            margin: 0 10vw;
        }

        .hifiklubben {
            .polygon {
                fill: black;
            }
        }
    }
}
