.MuiTabs-indicator {
  background-color: transparent !important;
}

.MuiPaper-root.produkt {
  height: 100%;
  min-height: 218px;
}

.MuiList-root {
  .MuiTypography-root {
    font-weight: 400 !important;
  }

  .MuiListItemIcon-root {
    min-width: 0;
  }
}

.MuiTab-wrapper {
  font-weight: 400;
  text-transform: none;
}

.MuiAutocomplete-inputRoot {
  padding: 0 !important;
}

.MuiAutocomplete-endAdornment {
  display: none;
}

.MuiAutocomplete-input {
  padding: 7px !important;
}

.MuiInputAdornment-root {
  margin-left: 8px;
}

.MuiBox-root {
  margin: 0 !important;
}

.MuiToolbar-root {
  padding: 0 !important;
  margin: 0 !important;
}

.MuiDrawer-paper div[role="presentation"] {
  width: 100%;
  min-width: 252px;
}

.MuiToolbar-root {
  max-width: 100% !important;
  background-color: white;
  padding-left: 0 !important;
}

.MuiPaper-root {
  flex-grow: 0 !important;
}

.MuiDrawer-paper {
  max-width: 400px;
  @media (max-width: 500px) {
    max-width: 320px;
  }

  @media (max-width: 350px) {
    max-width: 300px;
  }
}

.MuiTextField-root,
.MuiFormControl-root {
  margin: 0 !important;
  padding: 0 !important;
}

.MuiButtonBase-root {
  &:focus {
    outline: none;
  }
}

.MuiTooltip-tooltip {
  font-size: 0.9rem !important;
  line-height: 1.8 !important;
  padding: 1rem !important;
  font-weight: 400 !important;
  border-radius: 0 !important;
  background-color: rgba(black, 0.85) !important;

  .MuiTooltip-arrow:before {
    background-color: rgba(black, 0.85) !important;
  }

  @media (max-width: 460px) {
    min-width: 250px;
  }
}
