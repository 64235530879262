.header-section {
  height: 350px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8b0000;

  .header-section-content {
    z-index: 1;
    max-width: 50rem;
    color: white;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      max-height: 3.5rem;
      height: 100%;
      max-width: 20rem;

      path,
      polygon,
      polyline,
      rect,
      circle {
        fill: white;
      }


      &.tommy-hilfiger {
        height: 30px;
      }

      &.kjell-company {
        rect {
          fill: none;
        }
      }

      &.burton {
        .st0 {
          fill: black !important;
        }
      }

      &.tretti {
        path {
          fill: black;
        }

        .st0 {
          fill: white;
        }
      }

      &.resvaskor {

        .st1,
        .st2,
        .st3,
        .st4,
        .st5 {
          fill: black !important;
        }
      }

      .dark-detail,
      .guldfynd-bokstav {
        fill: black;
      }

      .no-fill,
      #clarins_background {
        fill: none;
      }
    }
  }

  >.skeleton-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      position: relative;
      max-height: unset;
      object-fit: cover !important;
      opacity: 0.25;
    }

    >div {
      width: 100%;
      height: 300px;
      overflow: hidden;
      position: relative !important;
    }
  }
}

.shopping-header {

  // Custom height for dedicated shop page
  &.dedicated-shop-header {
    .container {
      min-height: auto !important;

      @media(max-width: 600px){
        align-items: flex-start;
      }

      @media(min-width: 600px) and (max-width: 1100px) {
        flex-direction: row !important;
      }

      h1 {
        @media(min-width: 601px) {
          padding: 0 !important;
        }
      }

      span {
        font-size: 1.2rem;
      }
    }
  }
}