.stars {
    display: flex;
    font-size: 6px;
    min-height: 6px;

    @media screen and (min-width: 465px) {
        font-size: 15px;
        min-height: 15px;
    }



    &__star {
        padding: 0 0.25rem;
        color: gold;
        &--outlined {
            color: rgb(157, 133, 0);
        }
    }
}
