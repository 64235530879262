.press {
    main {
        #intro {
            padding: 44px 2rem 0rem 2rem;
            height: 45vh;
            display: flex;
            justify-content: center;
            align-items: center;
            // background: linear-gradient(147deg, #c62128, #a00000, #c62128, #a00000);
            background-size: 400% 400%;
            animation: Gradient 10s ease infinite;
            color: white;
            background: #8b0000;

            @media (max-width: 912px) {
                min-height: 100%;
                height: 220px;
                max-height: 220px;
            }
        }

        .button-wrap {
            display: inline-flex;
            flex-direction: column;
            margin-top: 1rem;
            color: rgba(black, 0.75);

            a {
                border-radius: 3rem;
                padding: 1rem 2rem;
                background: #e6e6e6;
                font-weight: 500;
                font-size: 1.1rem;
                text-decoration: none;
                display: flex;
                justify-content: space-between;
                color: black;
                position: relative;
                min-width: 280px;

                @media (max-width: 600px) {
                    min-width: auto;
                }

                &:hover {
                    background: darken(#e6e6e6, 10%);
                }

                span {
                    i {
                        font-style: normal;
                        padding-left: 4px;

                        @media (max-width: 400px) {
                            display: none;
                        }
                    }

                    &:nth-child(2) {
                        font-weight: 400;
                        margin-left: 1rem;
                    }
                }
            }
        }

        section {
            &:nth-child(even) {
                background: #f6f6f6;
                width: 100%;
            }
        }

        #typografi {
            .MuiAccordionDetails-root {
                flex-direction: column;
            }
        }

        .MuiAccordion-root {
            margin: 0;
            background: #8b0000;
            border-radius: 0 !important;

            // &:hover {
            //     background: #222;
            // }

            &:before {
                display: none;
            }

            .section-title {
                text-align: center;
                background: transparent;
            }

            .MuiAccordionSummary-content {
                margin: 0;
                flex-grow: 0;
            }

            .MuiAccordionSummary-expandIcon {
                position: absolute;
                right: 2rem;

                svg {
                    fill: rgba(white, 0.75);
                }
            }
        }

        .MuiAccordionDetails-root {
            background: #f6f6f6;
            padding: 0;

            section {
                margin: 0 auto;
                overflow: hidden;

                article {
                    padding: 3rem 0;

                    @media (max-width: 1150px) {
                        padding: 0;
                    }
                }
            }
        }

        article {
            header {
                h3 {
                    font-weight: 500;
                    display: none;
                }
            }
        }

        .item {
            margin-bottom: 2rem;

            span {
                @media (max-width: 460px) {
                    display: none;
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                margin-top: 0.5rem;
                opacity: 0.75;

                li {
                    b {
                        font-weight: 600;
                    }
                }
            }
        }

        .container {
            max-width: 1500px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            @media (max-width: 1150px) {
                flex-direction: column-reverse;
            }

            @media (max-width: 850px) {
                padding: 3rem 2rem;
            }

            .left-column {
                flex-basis: 35%;
                padding: 3rem;

                header {
                    h3 {
                        opacity: 0.5;
                        margin-top: 0;
                    }
                }
            }

            .right-column {
                flex-basis: 55%;
                width: 100%;
                position: relative;

                small {
                    color: #8b0000;
                    font-weight: 600;
                    font-size: 1.1rem;
                    margin-bottom: 0.5rem;
                    display: block;
                }

                .font-info {
                    margin-top: 0.5rem;
                    display: block;
                    opacity: 0.75;
                }
            }
        }

        #logotyp {
            article {
                align-items: center;

                > div {
                    &:nth-child(2) {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
            }

            .right-column {
                .logo-wrapper {
                    height: 400px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    transition: 0.3s ease-in-out;
                    // background: #f6f6f6;

                    @media (max-width: 1120px) {
                        height: 350px;
                    }

                    span {
                        transition: 0.3s ease-in-out;
                        position: absolute;
                        bottom: 2rem;
                        right: 2rem;
                        padding-left: 2rem;
                        color: black;
                        opacity: 0;
                    }

                    button {
                        color: #fff;
                        background: #222;
                        position: absolute;
                        right: 6.5rem;
                        top: 2rem;
                        z-index: 99;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.5;
                        }
                        &.background-changer {
                            right: 4rem;
                            background: #8b0000;
                            width: 27px;
                            height: 27px;
                            padding: 0;
                            border-radius: 50%;
                        }

                        &.color-changer {
                            right: 2rem;
                            background: white;
                            color: #8b0000;
                            width: 27px;
                            height: 27px;
                            border-radius: 50%;
                            border: 1px solid #999;
                            font-weight: 500;
                        }
                    }

                    &.show-guides {
                        span {
                            opacity: 1;
                        }

                        svg {
                            path,
                            polygon {
                                fill: black;
                                opacity: 1;
                            }

                            .st0 {
                                opacity: 0.2;
                            }
                        }

                        .grid-dots {
                            opacity: 1;
                        }
                    }

                    &.inverted {
                        background: #8b0000;

                        .background-changer {
                            background: white;
                        }

                        span {
                            color: white;
                        }

                        .logo-guide {
                            svg,
                            polygon,
                            path {
                                fill: white;
                            }
                        }
                    }

                    &.color-inverted {

                        .color-changer {
                            color: black;
                        }
                        
                        .logo-guide {
                            svg,
                            polygon,
                            path {
                                fill: #8b0000;
                            }
                        }
                    }

                    .logo-guide {
                        width: 80%;
                        z-index: 2;
                        position: relative;

                        @media (max-width: 950px) {
                            width: 90%;
                        }

                        svg,
                        polygon,
                        path {
                            transition: 0.3s ease-in-out;
                        }

                        .st0 {
                            fill: black;
                            transition: 0.3s ease-in-out;
                            opacity: 0;
                        }
                    }

                    .grid-dots {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        display: none !important;

                        pattern rect {
                            transition: 0.3s ease-in-out;
                            fill: #acacac;
                        }
                    }
                }
            }

            #bg-grid {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
            }
        }

        #bild {
            width: 100%;

            article {
                align-items: center;

                @media (max-width: 1150px) {
                    flex-direction: column;
                }
            }

            .right-column {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: -4px;
                width: calc(100% + 4px);

                > div {
                    width: calc(100% / 3 - 4px);
                    margin: 0;
                    position: relative;
                    cursor: pointer;
                    height: 150px;
                    transition: 0.3s ease-in-out;
                    background: black;
                    margin: 2px;

                    @media (max-width: 600px) {
                        height: 100px;
                    }

                    img {
                        object-fit: cover !important;
                    }

                    &:hover {
                        opacity: 0.75;
                    }
                }
            }
        }

        #film {
            width: 100%;

            article {
                align-items: center;

                @media (max-width: 1150px) {
                    flex-direction: column-reverse;
                }

                .right-column {
                    &.copied {
                        .copy-button {
                            background: lightgreen !important;
                            color: black !important;
                        }

                        pre {
                            animation: blinkGreen 0.3s ease-in-out 0s both;
                        }

                        @keyframes blinkGreen {
                            0% {
                                color: white;
                            }

                            25% {
                                color: lightgreen;
                            }

                            50% {
                                color: white;
                            }

                            100% {
                                color: lightgreen;
                            }
                        }
                    }
                }
            }

            .swiper-container {
                max-width: 825px;

                @media (max-width: 1150px) {
                    max-width: 100vw;
                }

                .swiper-button-prev {
                    left: 2rem;

                    @media (max-width: 1150px) {
                        left: -40px;
                    }

                    &:after {
                        font-size: 1rem;
                        font-weight: 600;
                        color: white;

                        @media (max-width: 1150px) {
                            margin-right: -2rem;
                        }
                    }
                }

                .swiper-button-next {
                    right: 2rem;

                    @media (max-width: 1150px) {
                        right: -40px;
                    }

                    &:after {
                        font-size: 1rem;
                        font-weight: 600;
                        color: white;

                        @media (max-width: 1150px) {
                            margin-left: -2rem;
                        }
                    }
                }

                .swiper-button-prev,
                .swiper-button-next {
                    height: 40px;
                    width: 40px;
                    background: black;
                    border-radius: 50%;
                    transition: 0.3s ease-in-out;

                    @media (max-width: 1150px) {
                        height: 80px;
                        width: 80px;
                    }

                    &:hover {
                        background: darken(black, 10%);
                    }
                }

                .swiper-slide {
                    position: relative;
                    width: 100% !important;
                    max-width: 825px;
                    line-height: 0;

                    @media (max-width: 1150px) {
                        max-width: 100vw;
                    }

                    .showEmbed {
                        padding: 0.5rem 1rem;
                        font-size: 14px;
                        font-weight: 400;
                        color: #fff;
                        border-radius: 3rem;
                        background: rgba(black, 0.35);
                        border: 0;
                        position: absolute;
                        cursor: pointer;
                        z-index: 999;
                        top: 2rem;
                        right: 2rem;

                        @media (max-width: 500px) {
                            display: none !important;
                        }

                        &:hover {
                            background: black;
                        }
                    }

                    video {
                        width: 100%;

                        @media (max-width: 1150px) {
                            width: 100vw;
                        }
                    }
                }

                &.show {
                    .swiper-wrapper {
                        z-index: 10;
                    }

                    .showEmbed {
                        opacity: 0;
                    }
                }
            }
        }

        #farg {
            article {
                @media (min-width: 801px) {
                    flex-direction: row-reverse;
                }

                .right-column {
                    padding: 0;

                    @media (max-width: 1150px) {
                        padding: 3rem 3rem 0 3rem;
                        width: calc(100% - 6rem);
                    }
                }

                .left-column {
                }

                > div {
                    &:nth-child(2) {
                        display: flex;
                        flex-direction: column;

                        h4 {
                            margin-bottom: 1rem;
                            margin-top: 0;
                        }

                        > div {
                            max-width: 800px;
                            width: 100%;
                            display: flex;
                        }

                        .color-circle {
                            height: 200px;
                            width: calc(100% / 2);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 1.4rem;
                            font-weight: 500;
                            color: white;
                            margin-bottom: 1rem;

                            @media (max-width: 500px) {
                                font-size: 0.9rem;
                                height: 154px;
                            }

                            ul {
                                list-style: none;
                                padding: 0;
                                margin: 0;

                                li {
                                    margin-bottom: 0.5rem;

                                    &:last-child {
                                        margin: 0;
                                    }
                                }
                            }

                            span {
                                font-weight: 500;
                            }

                            &.main-red {
                                background: #e41513;
                            }

                            &.main-black {
                                background: black;
                            }

                            &:last-child {
                                margin-right: 0;
                            }
                        }

                        .color-wrapper {
                            display: flex;
                            flex-wrap: wrap;
                        }

                        .color-circle-small {
                            height: 154px;
                            width: calc(100% / 4);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: black;
                            font-size: 0.9rem;
                            font-weight: 500;
                            color: white;

                            @media (max-width: 1100px) {
                                width: calc(100% / 2);
                            }

                            &:nth-child(1) {
                                background: #111;
                            }

                            &:nth-child(2) {
                                background: #222;
                            }

                            &:nth-child(3) {
                                background: #f6f6f6;
                                color: black;
                            }

                            &:nth-child(4) {
                                background: white;
                                color: black;
                            }

                            &:last-child {
                                margin-right: 0;
                            }

                            ul {
                                list-style: none;
                                padding: 0;
                                margin: 0;

                                li {
                                    margin-bottom: 0.5rem;

                                    &:last-child {
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        #typografi-accordion {
            > div {
                display: flex;
                flex-direction: column;
            }

            .right-column {
                @media (min-width: 1151px) and (max-width: 1500px) {
                    padding-right: 2rem;
                }
            }

            #typografi-1 {
                .right-column {
                    @media (max-width: 1150px) {
                        padding: 3rem 3rem 0 3rem;
                        width: calc(100% - 6rem);
                    }
                }

                .huvudrubrik,
                .underrubrik,
                .brodtext,
                .fallback {
                    font-size: 2rem;
                    display: flex;
                    justify-content: space-between;
                }

                .huvudrubrik {
                    font-weight: 500;
                }

                .underrubrik {
                    font-weight: 500;
                }

                .brodtext {
                    font-weight: 400;
                }

                .fallback {
                    font-family: sans-serif;
                }
            }

            #typografi-2 {
                .right-column {
                    @media (max-width: 1150px) {
                        padding: 3rem 3rem 0 3rem;
                        width: calc(100% - 6rem);
                    }

                    p {
                        margin: 0;
                        padding: 0;
                    }
                }

                .listor {
                    margin-top: 2rem;
                    max-width: 400px;
                    display: flex;
                    justify-content: space-between;

                    > div {
                        h4 {
                            margin-bottom: 1rem;
                        }
                    }

                    ul,
                    ol {
                        margin: 0;
                        margin-left: 1.5rem;
                        padding: 0;

                        li {
                            font-size: 1.2rem;
                            margin-bottom: 0.5rem;

                            &:last-child {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        hr {
            height: 1px;
            border: 0;
            background: #ccc;
            margin: 2rem 0;
        }

        #press-manager {
            article {
                flex-direction: column;

                @media (max-width: 1150px) {
                    padding: 3rem;
                }

                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

.images-modal {
    &.modal-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        animation: fadeIn 0.3s ease-in-out 0s both;
    }

    .image-modal {
        max-width: 900px;
        width: 100%;
        animation: fadeIn 0.3s ease-in-out 0.3s both;
        position: relative;
        margin: 1rem;
        outline: none;
        margin-bottom: 70px;

        img {
            z-index: -1;
        }

        .modal-footer {
            display: flex;
            align-items: center;
            padding: 1rem 2rem;
            justify-content: space-between;
            background: white;
            position: absolute;
            bottom: -70px;
            left: 0;
            right: 0;

            @media (max-width: 600px) {
                padding: 1rem;
            }

            > span {
                display: flex;
                flex-direction: column;
                font-size: 0.9rem;

                b {
                    font-size: 1.1rem;
                }
            }
        }
    }
}
