.swiper-container {
  z-index: 0;
}

.productMain {
  background: white;
  padding-top: 94px;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 40px;

  @media (max-width: 1402px) {
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 991px) {
    padding-top: calc(88px + 0.7rem);
    padding-left: 8px;
    padding-right: 8px;
  }
}
.productMain_slider_nextButton,
.productMain_slider_prevButton {
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  z-index: 1;
  background: rgba(239, 238, 238, 0.5);
  overflow: hidden;
  @media (max-width: 1100px) {
    width: 80px;
    height: 80px;
  }
}
.productMain_slider_nextButton {
  left: -35px;
  @media (max-width: 600px) {
    width: 50px;
    height: 50px;
    left: 0px;
  }
}

.productMain_slider_prevButton {
  right: -35px;
  @media (max-width: 600px) {
    width: 50px;
    height: 50px;
    right: 0px;
  }
}

.productMain_slider_disabled {
  display: none;
}

.productMain__swipe {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__article {
    width: 100%;
    min-height: 100px;
    margin-bottom: 20px;
    padding: 0 0.5rem;
    border-radius: 5px;
    border: 1px solid #efeeee;

    @media (min-width: 1001px) {
      width: calc(50% - 1.5rem);
    }
  }
}
