.ordinary-price-modal,
.analytics-modal {
  &.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.3s ease-in-out 0s both;
  }

  .sign-up-modal {
    max-width: 420px;
    width: calc(100% - 4rem);
    background: white;
    padding: 2rem;
    animation: fadeIn 0.3s ease-in-out 0.3s both;
    @media (max-width: 485px) {
      &:not(.verified) {
        position: absolute;
        top: 125px;
        max-width: 80%;
        overflow-y: scroll;
      }
    }

    &:focus-visible {
      outline: 0 !important;
    }

    .center-text {
      text-align: center;
    }

    @media (max-width: 420px) {
      padding: 2rem 1rem;
      width: calc(100% - 2rem);
    }

    &.verified {
      max-width: 260px;
    }

    .close-modal {
      display: none;
      @media (max-width: 485px) {
        display: block;
        position: absolute;
        right: 1rem;
        top: 1rem;
        border: 0;
        background: transparent;
        svg {
          height: 25px;
          opacity: 0.5;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    h2 {
      margin-top: 0;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 0.5rem;
      @media (max-width: 485px) {
        text-align: left;
        font-size: 1.6rem;
      }
    }

    p {
      margin-top: 0;
    }

    h4 {
      margin-top: 0;
      font-weight: 500;
    }

    form {
      display: flex;
      flex-direction: column;

      .error-msg {
        color: #e41513;
        font-size: 14px;
        font-weight: 500;
      }

      input {
        margin-bottom: 5px;
        margin-top: 1rem;
        border-color: rgba(black, 0.1);

        &:focus {
          border-color: black;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      button {
        margin: 1rem 0;
      }

      small {
        color: #999;
      }
    }

    input {
      color: #000;
    }

    .sign-up-thank-you {
      display: flex;
      flex-direction: column;
      align-items: center;
      svg {
        width: 100px;
        margin: 0 auto;
        animation: heartBeat 1s ease-in-out 0s infinite;
        fill: #e41513;
        opacity: 0.2;
        position: relative;
        left: 0;
        right: 0;
        z-index: -1;
      }

      p {
        &.registrerad {
          font-size: 1.2rem;
          text-align: center;
        }

        &:last-child {
          margin-bottom: 0;
          text-align: center;
        }
      }
    }
  }
}
