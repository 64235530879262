
// Payment SVG

.visa {
    .st0 {
      fill: #0458a0;
    }
  
    .st1 {
      fill: #faa71b;
    }
  }
  
  .mastercard {
    .st0 {
      fill: #231f20;
    }
  
    .st1 {
      fill: #f26122;
    }
  
    .st2 {
      fill: #ea1d25;
    }
  
    .st3 {
      fill: #f69e1e;
    }
  }
  
  .thrustly {
    .st0 {
      fill: #010101;
    }
  }
  
  .qliro {
    .st0 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #78cab7;
    }
  }
  
  .paypal {
    .st0 {
      fill: #253d80;
    }
  
    .st1 {
      fill: #189bd7;
    }
  
    .st2 {
      fill: #242e65;
    }
  }
  
  .klarna {
    .st0 {
      fill: #010101;
    }
  }
  
  .swish {
    .st0 {
      fill: #4a4a49;
    }
  
    .st1 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: url(#SVGID_1_);
    }
  
    .st2 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: url(#SVGID_2_);
    }
  
    .st3 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: url(#SVGID_3_);
    }
  
    .st4 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: url(#SVGID_4_);
    }
  }
  
  .svea {
    .st0 {
      fill: #002c50;
    }
  
    .st1 {
      fill: #00aece;
    }
  }
  
  .google-pay {
    .st0 {
      fill: #5f6368;
    }
  
    .st1 {
      fill: #4285f4;
    }
  
    .st2 {
      fill: #34a853;
    }
  
    .st3 {
      fill: #fbbc04;
    }
  
    .st4 {
      fill: #ea4335;
    }
  }
  
  .collector-bank {
    .st0 {
      fill: #1d1d1b;
    }
  
    .st1 {
      opacity: 0.4;
    }
  }
  
  .amex {
    .st0 {
      fill: #016fd0;
    }
  
    .st1 {
      fill: #ffffff;
    }
  
    @media (max-width: 500px) {
      display: none;
    }
  }
  