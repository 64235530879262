.es-navigation {
    margin: 0;
    position: sticky;
    @media(max-width: 1100px) {
        padding-bottom: 3rem;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li a {
            &:hover {
                opacity: 1;
                background: white;
            }
        }
    }

    li {
        &.selected {
            background: white;
            font-weight: 600;
            color: black;

            a {
                color: black;
                opacity: 1;
            }
        }
    }

    li a {
        height: 44px;
        padding-left: 1rem;
        font-size: 1rem;
        list-style-type: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        place-content: space-between;
        color: black;

        &>span {
            display: flex;
            place-items: center;
            fill: black;
            font-size: 0.85rem;
            opacity: 0.75;
            margin-left: 0.5rem;

            &>span {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                min-width: 1.5rem;
            }
        }
    }

    svg {
        fill: #ababab;
        width: 1em;
        height: 1em;
        display: inline-block;
        font-size: 1.5rem;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        flex-shrink: 0;
        user-select: none;

        &.expanded {
            transform: rotate(180deg);
        }
    }
}