.shop-modal {
    // Modal
    &.modal-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        animation: fadeIn 0.3s ease-in-out 0s both;
    }

    .shop-information {
        background: white;
        max-width: 520px;
        width: 100%;
        padding: 2rem 3rem;
        position: relative;
        @media (max-width: 600px) {
            padding: 2rem 1rem 1rem 1rem;
            margin: 1rem;
        }

        @media (max-width: 485px) {
            top: 25px;
        }

        &:focus-visible {
            outline: none;
        }
        &:focus {
            outline: none;
        }
        &:focus-within {
            outline: none;
        }
        &:active {
            outline: none;
        }

        .shop-description {
            position: relative;

            p {
                margin-bottom: 0 !important;
            }

            @media (max-width: 600px) {
                p {
                    max-height: 100px;
                    overflow-y: scroll;
                    padding-bottom: 2rem;
                    padding-right: 1rem;
                }

                &:after {
                    content: "";
                    height: 100px;
                    width: 100%;
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
                    display: flex;
                    z-index: 999;
                    position: absolute;
                    bottom: -1px;
                    pointer-events: none;
                }
            }
        }

        .skeleton-wrapper {
            display: flex;
            justify-content: center;

            img {
                filter: grayscale(0);
            }
        }

        svg {
            max-height: 160px;
            max-width: 90%;
            display: flex;
            margin: 0 auto;
            @media (max-width: 600px) {
                max-height: 80px;
            }
        }

        &__button-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 2rem;
            @media (max-width: 600px) {
                flex-direction: column;
                margin-top: 1rem;
            }

            a {
                @media (min-width: 600px) {
                    &:first-child {
                        margin-right: 2rem;
                    }
                }
            }
        }
    }
}
