.SimilarProducts {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0;
  .SimilarProducts__heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: #505050;
  }
}

.SimilarProducts__slider {
  position: relative;
  .SimilarProducts__slider_nextButton,
  .SimilarProducts__slider_prevButton {
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    position: absolute;
    top: 40%;
    right: 0;
    z-index: 1;
    background: #d9d9d9;
    @media (max-width: 1100px) {
      width: 50px;
      height: 50px;
    }
  }
  .SimilarProducts__slider_nextButton {
    left: -25px;
    @media (max-width: 1100px) {
      top: 45%;
      left: 0px;
    }
  }
  .SimilarProducts__slider_prevButton {
    right: -25px;
    @media (max-width: 1100px) {
      top: 45%;
      right: 0;
    }
  }
}
