/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * futura-pt:
 *   - http://typekit.com/eulas/00000000000000000001008f
 *   - http://typekit.com/eulas/000000000000000000010091
 *   - http://typekit.com/eulas/000000000000000000013365
 *   - http://typekit.com/eulas/000000000000000000012192
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-02-17 10:15:50 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=req8ogn&ht=tk&f=10879.10881.10884.15586&a=32661693&app=typekit&e=css");

@font-face {
font-family:"futura-pt";
src:url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/l?primer=8c6cdece1802ac03951c30593622b1b6185a07621a4f8858e37fe29080c384e2&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/d?primer=8c6cdece1802ac03951c30593622b1b6185a07621a4f8858e37fe29080c384e2&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/a?primer=8c6cdece1802ac03951c30593622b1b6185a07621a4f8858e37fe29080c384e2&fvd=n5&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
font-family:"futura-pt";
src:url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/l?primer=8c6cdece1802ac03951c30593622b1b6185a07621a4f8858e37fe29080c384e2&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/d?primer=8c6cdece1802ac03951c30593622b1b6185a07621a4f8858e37fe29080c384e2&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/309dfe/000000000000000000010091/27/a?primer=8c6cdece1802ac03951c30593622b1b6185a07621a4f8858e37fe29080c384e2&fvd=n7&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"futura-pt";
src:url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=8c6cdece1802ac03951c30593622b1b6185a07621a4f8858e37fe29080c384e2&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=8c6cdece1802ac03951c30593622b1b6185a07621a4f8858e37fe29080c384e2&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=8c6cdece1802ac03951c30593622b1b6185a07621a4f8858e37fe29080c384e2&fvd=n4&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"futura-pt";
src:url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/l?primer=8c6cdece1802ac03951c30593622b1b6185a07621a4f8858e37fe29080c384e2&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/d?primer=8c6cdece1802ac03951c30593622b1b6185a07621a4f8858e37fe29080c384e2&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/a?primer=8c6cdece1802ac03951c30593622b1b6185a07621a4f8858e37fe29080c384e2&fvd=n6&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:600;font-stretch:normal;
}

.tk-futura-pt { font-family: "futura-pt",sans-serif; }