.search-list.MuiList-root {
    z-index: 10;
    background-color: white;
    font-size: 15px;
    padding-bottom: 33px;
    padding-top: 0;
    width: 100%;

    @media (min-width: 1100px) {
        width: 700px;
    }

    @media (max-width: 600px) {
        max-width: 90%;
        width: 500px;
    }

    @media (max-width: 500px) {
        width: 400px;
    }

    .deal-off__discount {
        display: none;
    }
}

.search-list {
    height: 350px;
    overflow: auto;
    z-index: 11;

    &::-webkit-scrollbar {
        display: none;
    }

    .MuiListItem-root {
        margin: 0 !important;
        letter-spacing: initial;
        line-height: initial;
        justify-content: center;
        border-bottom: 1px solid rgba(black, 0.1);
    }

    .MuiCircularProgress-root {
        height: 20px !important;
        width: 20px !important;
    }

    &__content {
        display: flex;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__img-wrapper {
        display: flex;
        flex-direction: column;
        min-width: 50px;
        place-content: center;
    }

    &__img {
        width: 100%;
    }

    &__text-holder {
        align-self: center;
        margin-left: 10px;
        margin-right: 10px;
        align-self: center;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
    }

    &__flex {
        display: flex;
        justify-content: space-between;
    }

    &__info {
        padding: 0.5rem 0;
        // padding-top: 1rem;
        // padding-bottom: 1rem;
        max-width: 55vw;
        width: 100%;

        @media (min-width: 1100px) {
            margin-left: 1rem;
        }

        &.product {
            @media (max-width: 760px) {
                max-width: calc(100vw - 65px - 20px - 2rem - 145px);
            }

            @media(max-width: 500px) {
                max-width: calc(100vw - 65px - 20px - 2rem - 70px);
            }
        }

        .display{
            &__flex {
                display: flex;
            }
        }
        
        .price-badges-wrapper {
        //   margin-left: 1rem;
          flex-wrap: wrap;

          @media (max-width: 599px) {
            margin: 0.5rem 0;
          }
          .badge:nth-child(1) {
            margin-right: 0.25rem;
          }
          .badge:not(.badge:nth-child(1)) {
            margin: 0rem 0.25rem;
          }
          .badge {
            display: flex;
            align-items: center;
            // margin: 0rem 0.25rem;
            // font-size: 1rem;
            margin: 0.2rem;

            span {
              background: #008484;
              color: white;
              text-align: center;
              padding: 0.25rem 0.5rem;


              @media (max-width: 670px) {
                padding: 0.1rem 0.25rem;
              }

            }

            &.discount span {
              background: #e41513;
              color: white;
            }

            &.saving span {
              background: #e41513;
              color: white;
            }

            &.free-shipping span {
              background: #cef7e7;
              color: black;
            }

            &.vintage span {
              background: #f7eace;
              color: black;
            }

            &.not-in-stock span {
              background: #000;
              color: #fff;
              display: flex;
              align-items: center;

              .bell {
                height: 1rem;
                width: 1rem;
                margin-left: 0.25rem;
                path {
                  fill: white;
                }
              }
            }

            &.order-item span {
              background: #E96100;
              color: #fff;
              display: flex;
              align-items: center;

              .calendar {
                height: 1rem;
                width: 1rem;
                margin-left: 0.25rem;
                path {
                  fill: white;
                }
              }
            }
          }
        }
    }

    &__prices {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 160px;

        @media (max-width: 900px) {
            width: auto;
        }

        .free-shipping {
            position: absolute;
            background: #cef7e7;
            color: black;
            font-size: 0.9rem;
            font-weight: 500;
            border-radius: 1rem;
            padding: 4px 10px;
            text-align: right;
            left: -90px;
            z-index: 2;

            @media (max-width: 900px) {
                display: none;
            }
        }

        text-align: right;
        
        .original-price {
            text-decoration: line-through;
            // padding-right: 1rem;
        }

        .price {
            text-decoration: underline;
            text-decoration-color: red;
            font-weight: 500;
        }

    }

    &__name {
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: 1rem;
        // padding-bottom: 1rem;

        em {
            font-style: normal;
            background: rgba(pink, 0.5);
            padding: 4px;
        }
    }

    &__category-path {
        font-size: 0.95rem;
        font-weight: 400;
        margin-top: 5px;
        white-space: break-spaces;
    }
}