.newPreMain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 0;
  // padding: 24px 40px;
  // width: 90%;
  flex-wrap: wrap;
  place-items: center;

  @media (max-width: 800px) {
    // padding: 20px;
    // width: 80%;
  }

  .newPreMain__textDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .newPreMain__textDiv__text {
    align-items: center;
    margin: 0 10px;
    font-weight: 500;
    font-size: 16px;
    color: #505050;
    cursor: pointer;
    @media (max-width: 800px) {
      margin: 0 6px;
    }
  }

  .newPreMain__textDiv__text--active {
    color: #8b0000;
  }
}
