html {
  overscroll-behavior: none;
  margin: 0;

  // Loading Animation
  &.nprogress-busy {
    #loading-bar {
      animation-play-state: running;
      opacity: 1;
    }
  }
}

#loading-bar {
  animation: loading-bar 2000ms linear infinite;
  animation-play-state: paused;
  fill: #008484;
  opacity: 0;
  transition: 0.6s opacity ease-in-out;
}

@keyframes loading-bar {
  0% {
    transform: translate(16%, 83.5px);
  }

  50% {
    transform: translate(100%, 83.5px);
  }

  100% {
    transform: translate(16%, 83.5px);
  }
}

#jiroy #mobile-menu {
  position: fixed;
  margin: 0 auto;
  z-index: 1500;
  top: 0;
  left: 0;
  right: 0;
  background: #8b0000;

  &.search-open {
    @media (max-width: 600px) {
      // .right-buttons {
      //   margin-right: -90px;
      // }

      .right-drawer__buttons-wrapper {
        opacity: 0;
        width: 0;
        pointer-events: none;
      }
    }

    .search-field {
      opacity: 1;
      pointer-events: all;
      visibility: visible;

      .magnifier-icon {
        opacity: 1;
      }
    }
  }

  .first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    position: relative;
    transition: 0.3s;

    > div {
      display: flex;
      align-items: center;
      z-index: 2;
      min-width: 45px;
    }
  }

  .second-row {
    height: 38px;
    display: flex;
    place-content: center;
    margin-bottom: 12px;
    // padding: 0 10px;
  }

  .search-field {
    // transition: 0.3s ease-in-out;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 520px;
    width: 100%;

    @media (min-width: 2000px) {
      max-width: 600px;
    }

    // @media (max-width: 320px) {
    //   left: 0;
    //   opacity: 0;
    //   pointer-events: none;
    //   width: 0;
    // }

    .magnifier-icon {
      // background: #e41513;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 1rem;
      z-index: 1002;
      cursor: pointer;

      svg {
        height: 25px;
        fill: #8b0000;
      }
    }

    label {
      width: calc(100% - 2rem);
    }

    input {
      padding: 0 0 0 1rem;
      width: calc(100% - 2rem);
      background: white;
      border-radius: 1rem;
      font-size: 1rem;
      color: rgba(black, 0.75);
      border: 0;
      height: 36px;
      z-index: 1001;
      // transition: none;

      &::placeholder {
        color: rgba(black, 0.65);
      }

      &:-ms-input-placeholder {
        color: rgba(black, 0.65);
      }

      &::-ms-input-placeholder {
        color: rgba(black, 0.65);
      }

      &:focus {
        outline: 0;
      }
      &.open {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 0rem;
        border-bottom-left-radius: 0rem;
      }
    }
  }

  .jiroy-logo {
    display: block;
    position: relative;
    padding-left: 0;
    opacity: 1;
    // margin-right: 10px;
    transition: 0.3s ease-in-out;

    svg {
      // width: 120px;
      height: 21px;
      position: relative;
      margin: 0;
      top: 0;

      path,
      polygon {
        transition: 0.3s ease-in-out;
        fill: white;
      }
    }

    &:hover {
      svg {
        path,
        polygon {
          fill: rgba(white, 0.4);
        }
      }
    }
  }
}

#jiroy #main-menu {
  position: fixed;
  margin: 0 auto;
  z-index: 1500;
  top: 0;
  left: 0;
  right: 0;
  background: #8b0000;

  &.search-open {
    @media (max-width: 600px) {
      .right-buttons {
        margin-right: -90px;
      }

      .right-drawer__buttons-wrapper {
        opacity: 0;
        width: 0;
        pointer-events: none;
      }
    }

    .search-field {
      opacity: 1;
      pointer-events: all;
      visibility: visible;

      .magnifier-icon {
        opacity: 1;
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    position: relative;
    transition: 0.3s;

    &.container {
      max-width: unset;

      @media (max-width: 600px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .right-buttons {
        max-width: 120px;
        width: 100%;
        justify-content: flex-end;
        transition: 0.3s ease-in-out;

        @media (max-width: 900px) {
          max-width: unset;
          width: unset;
        }
      }
    }

    > div {
      display: flex;
      align-items: center;
      z-index: 2;
    }
  }

  .search-field {
    // transition: 0.3s ease-in-out;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 520px;
    width: 100%;

    @media (min-width: 2000px) {
      max-width: 600px;
    }

    // @media (max-width: 320px) {
    //   left: 0;
    //   opacity: 0;
    //   pointer-events: none;
    //   width: 0;
    // }

    .magnifier-icon {
      // background: #e41513;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0.5rem;
      z-index: 1002;
      cursor: pointer;

      svg {
        height: 25px;
        fill: #8b0000;
      }
    }

    label {
      width: calc(100% - 2rem);
    }

    input {
      padding: 0 0 0 1rem;
      width: calc(100% - 2rem);
      background: white;
      border-radius: 1rem;
      font-size: 1rem;
      color: rgba(black, 0.75);
      border: 0;
      height: 36px;
      z-index: 1001;

      &::placeholder {
        color: rgba(black, 0.65);
      }

      &:-ms-input-placeholder {
        color: rgba(black, 0.65);
      }

      &::-ms-input-placeholder {
        color: rgba(black, 0.65);
      }

      &:focus {
        outline: 0;
      }
      &.open {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 0rem;
        border-bottom-left-radius: 0rem;
      }
    }
  }

  .jiroy-logo {
    display: block;
    position: relative;
    padding-left: 0;
    opacity: 1;
    // margin-right: 10px;
    transition: 0.3s ease-in-out;

    svg {
      width: 120px;
      height: 21px;
      position: relative;
      margin: 0;
      top: 0;

      path,
      polygon {
        transition: 0.3s ease-in-out;
        fill: white;
      }
    }

    &:hover {
      svg {
        path,
        polygon {
          fill: rgba(white, 0.4);
        }
      }
    }
  }
}

.search-modal {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: -15px;
  // pointer-events: none;
  display: flex;
  place-content: center;

  &.open {
    // top: 94px;
    background: rgba(black, 0.5);
    left: 0;
    right: 0;
    bottom: 0;

    // @media (max-width: 1401px) {
    //   top: 50px;
    // }

    // @media (max-width: 991px) {
    //   top: 100px;
    // }

    // .search-wrapper {
    //   animation: fadeIn 0.3s ease-in-out 0s both;
    //   opacity: 1 !important;
    //   pointer-events: all;
    // }

    // &.closing {
    //   animation: slideOutUp 0.3s ease-in-out 0s both;
    // }
  }
}

// NAV
#jiroy #desktop-nav {
  position: fixed;
  z-index: 1000;
  padding-left: 1rem;
  padding-right: 1rem;
  width: calc(100% - 2rem);
  top: 50px;
  background: #8b0000;
  display: flex;
  /* justify-content: center; */
  justify-content: center;

  .right-arrow {
    fill: white;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transform: rotate(270deg);

    &.active {
      fill: #8b0000;
    }
  }

  .text-decoration {
    &__underline {
      text-decoration: underline;
    }
  }

  @media (max-width: 1260px) {
    padding-left: 1.25rem;
  }

  @media (max-width: 1300px) {
    display: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > ul {
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin: 0;

    @media (max-width: 1300px) {
      &:first-child {
        width: 100%;
      }

      &:last-child {
        display: none;
      }
    }

    > li {
      > a {
        height: 44px;
        display: flex;
        align-items: center;
        font-weight: 500;
        color: white;
        padding: 0 1.5rem;

        @media (max-width: 1300px) {
          padding: 0 0.75rem;
        }
      }

      // @keyframes delayVisability {
      //   99% {
      //     visibility: hidden;
      //   }

      //   100% {
      //     visibility: visible;
      //   }
      // }

      &.active {
        &:after {
          content: "";
          height: 100vh;
          width: 100%;
          position: absolute;
          z-index: -1;
          background: rgba(black, 0.5);
          // visibility: hidden;
          // animation: delayVisability 0.2s ease-in-out 0.2s both;
          top: 44px;
          left: 0;
          right: 0;
          bottom: 0;
        }

        > a {
          background: white;
          color: black;
        }

        > ul {
          display: flex;
          flex-direction: column;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0;
          padding: 0;
          background: #8b0000;
          // visibility: hidden;
          // animation: delayVisability 0.2s ease-in-out 0.2s both;
          width: 400px;

          @media (max-width: 1600px) {
            width: 320px;
          }

          @media (max-width: 1400px) {
            width: 260px;
          }

          > li {
            &.active {
              a {
                background: white;
                color: black;
              }
            }

            > a {
              padding: 0 2rem;
              color: white;
              height: 44px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            .submenu {
              background: white;
              position: absolute;
              top: 0;
              flex-direction: column;
              width: calc(100vw - 400px - 6rem);
              padding: 1.5rem 3rem;
              left: 400px;
              display: none;
              max-height: 65vh;
              overflow-y: auto;

              @media (max-width: 1600px) {
                width: calc(100vw - 320px - 4rem);
                padding: 1.5rem 2rem;
                left: 320px;
              }

              @media (max-width: 1400px) {
                width: calc(100vw - 260px - 4rem);
                left: 260px;
              }

              h3 {
                margin: 0;
                display: inline-block;

                a {
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }

              & > ul {
                display: flex;
                flex-wrap: wrap;
                list-style: none;

                > li {
                  width: calc(100% / 4 - 1.5rem);
                  margin-right: 2rem;

                  &:nth-child(4n) {
                    margin-right: 0;
                  }

                  a {
                    background: transparent;
                    padding: 0;
                  }

                  > a {
                    font-weight: 500;
                    font-size: 1.2rem;
                    border-bottom: 1px solid black;
                    padding-bottom: 0.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 1.5rem;

                    &:hover {
                      text-decoration: underline;
                    }
                  }

                  ul {
                    li {
                      a {
                        padding: 0.5rem 0;

                        &:hover {
                          text-decoration: underline;
                          opacity: 0.75;
                        }
                      }
                    }
                  }
                }

                li,
                ul {
                  display: flex;
                  flex-direction: column;
                }
              }

              &.active {
                display: flex;
              }
            }
          }
        }
      }

      ul {
        display: none;
      }
    }
  }
}

// Temporary, login button

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  pointer-events: all;
  transition: 0.3s ease-in-out;
  background: transparent;
  height: 45px;
  width: 45px;
  display: none;
  opacity: 0;
  visibility: hidden;

  svg {
    height: 25px;
  }
}
