.search-suggestions {
  /* height: 30rem; */
  pointer-events: all;
  background: white;
  width: 30rem;
  position: absolute;
  top: 36px;
  max-width: 504px;
  width: calc(100% - 1rem);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 1rem 0;
  max-height: 30rem;
  overflow-y: auto;
  z-index: 1001;

  .cursor {
    &__pointer {
      cursor: pointer;
    }
  }

  .earlier-searches {
    padding: 0.5rem 1rem;
    display: flex;
    place-items: center;
    justify-content: space-between;

    &__watch-icon {
      fill: #a1a8b3;
      height: 15px;
    }

    &__remove-button {
      background: transparent;
      display: flex;
      padding: 0.5rem;
    }

    &__remove-icon {
      fill: #a1a8b3;
      height: 10px;
    }
  }

  .left-side {
    display: flex;
    // place-items: center;
  }

  @media (min-width: 2000px) {
    max-width: 584px;
  }

  &__item {
    padding: 0.5rem 1rem;
    display: flex;
    // padding: 0.5rem 0;
    place-items: center;
    justify-content: space-between;

    &.product {
      place-content: space-between;
    }

    &.hover {
      &:hover {
        background: #f4f7fa;
      }
    }
    svg {
      height: 20px;
      fill: #a1a8b3;
    }
  }

  &__item__arrow {
    height: 20px;
    // margin-right: 8px;
    transform: rotate(270deg);
    path {
      fill: #ababab;
    }
  }

  &__item__left-icon {
    display: flex;
    min-width: 15px;
    margin-right: 0.25rem;
    place-items: center;
  }

  &__item__category-key {
    margin-right: 0.25rem;
    font-size: 1.2rem;
    display: flex;
    place-items: center;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  &__item__sub-category {
    margin-left: 0.25rem;
    font-size: 0.9rem;
    align-self: center;
    display: flex;
    max-width: 280px;
  }

  // &__item__category-key__arrow {
  //   height: 20px;
  //   margin-right: 8px;
  //   path {
  //     fill: black;
  //   }
  // }

  &__item__left-section {
    display: flex;
    place-items: center;
  }

  &__item__left-section__text {
    // max-width: 280px;
    margin-left: 0.875rem;
    // white-space: pre-wrap;
    // overflow-wrap: break-word;
    // word-break: break-word;
  }

  &__item__left-section__text__name {
    // font-weight: 500;
    // font-size: 1.2rem;
  }

  &__item__left-section__text__category {
    // font-weight: 500;
    font-size: 0.75rem;

    @media (max-width: 991px) {
      font-size: 0.8rem;
    }
  }

  .price {
    text-align: right;
    font-weight: 600;
    min-width: 68px;
    // border-bottom: 2px solid #e41513;
    // font-size: 1.2rem;
  }

  .original-price {
    font-size: 0.8rem;
    text-decoration: line-through;
  }

  // &__item__right-section {
  //   // display: flex;
  // }
}
