.coupon-modal {
    .modal-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        animation: fadeIn 0.3s ease-in-out 0s both;
    }

    .coupon-wrap {
        max-width: 420px;
        width: calc(100% - 4rem);
        background: white;
        box-shadow: 0px 0px 10px rgba(black, 0.1);
        padding: 2rem;
        animation: fadeIn 0.3s ease-in-out 0.3s both;
        margin: 0 1rem;
        outline: none;

        &:focus {
            outline: none;
        }

        @media (max-width: 420px) {
            padding: 1.5rem;
            width: calc(100% - 2rem);
        }

        .discount-description {
            font-weight: 500;
        }

        &.kampanj {
            p {
                font-size: 1.2rem;
                margin: 0;

                &.terms {
                    text-align: center;
                    font-size: 1rem;
                    color: black;
                    opacity: 0.75;
                    margin-bottom: 0;
                }
            }
        }

        > div {
            background: lightgreen;

            > div {
                &:nth-child(1) {
                    padding: 2rem 2rem 1rem 2rem;
                    text-align: center;

                    p {
                        font-size: 1.2rem;
                        margin-bottom: 0.5rem;

                        b {
                            font-weight: 500;
                        }

                        &.coupon-description {
                            font-weight: 500;
                            font-size: 1.4rem;
                            @media (max-width: 500px) {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }

                &:nth-child(2) {
                    padding: 1.5rem 1.5rem;
                    border-top: 1px dashed rgba(black, 0.1);
                    position: relative;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    @media (max-width: 700px) {
                        padding: 1rem 2rem;
                    }

                    input {
                        width: 100%;
                        text-align: center;
                        letter-spacing: 0.2rem;
                        font-weight: 700;
                        font-size: 1.2rem;
                        background: #72bd72;
                        border-color: #72bd72;
                        border-radius: 0.5rem;
                        max-height: 53px;
                        height: 53px;
                        padding: 0;
                        border: 0;
                        margin-bottom: 1rem;
                        @media (max-width: 600px) {
                            font-size: 1rem;
                        }
                    }

                    button {
                        border-radius: 3rem;
                        padding: 0.75rem 1.5rem;
                        font-size: 1.1rem;
                        font-weight: 500;
                        max-height: 43px;
                        height: 43px;
                        cursor: pointer;
                        background: rgba(black, 0.75);
                        color: white;
                    }


                    &:after {
                        content: "";
                        height: 20px;
                        width: 20px;
                        background: white;
                        position: absolute;
                        top: -11px;
                        left: -10px;
                        border-radius: 50%;
                    }

                    &:before {
                        content: "";
                        height: 20px;
                        width: 20px;
                        background: white;
                        position: absolute;
                        top: -11px;
                        right: -10px;
                        border-radius: 50%;
                    }
                }
            }
        }

        .close-modal {
            display: none;
            @media (max-width: 485px) {
                display: block;
                position: absolute;
                right: 1rem;
                top: 1rem;
                border: 0;
                background: transparent;
                svg {
                    height: 25px;
                    opacity: 0.5;
                }

                &:hover {
                    opacity: 1;
                }
            }
        }

        h2 {
            margin-top: 0;
            text-align: center;
            margin-bottom: 1rem;
        }

        h3 {
            font-size: 4rem;
        }

        p {
            margin-top: 0;
        }

        .coupon-subscribe {
            font-size: 0.9rem;
            margin-bottom: 0;
            margin-top: 1rem;
            opacity: 0.75;

            a {
                text-decoration: underline;
            }
        }
    }
}
