.favorite-button {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	pointer-events: all;
	transition: 0.3s ease-in-out;
	background: transparent;
	height: 45px;
	width: 45px;

	> div {
		cursor: pointer;

		.selected {
			path {
				fill: #e41513;
			}
		}

		&.heart {
			position: relative;
			height: 45px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				height: 25px;
				fill: white;
			}

			> div {
				line-height: 0;
			}

			span {
				color: white;
				font-size: 8px;
				height: 15px;
				width: 15px;
				font-family: Arial, Helvetica, sans-serif;
				background: #e41513;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 6px;
				right: -10px;
			}

			&.filled {
				div {
					animation: heartBeat 1.5s ease-in-out 0s both;

					svg,
					path {
						fill: #e41513 !important;
					}
				}
			}
		}

		path {
			transition: 0.3s ease-in-out;
		}

		&:nth-child(1) {
			margin: 0;
		}

		&:hover {
			path {
				fill: #e41513;
			}
		}
	}
}

.right-menu {
	.MuiPaper-root {
		box-shadow: none;
	}

	.MuiDrawer-paper {
		&::-webkit-scrollbar {
			height: 12px !important;
			background: #e0e0e0;

			@media (max-width: 1100px) {
				border-radius: 10px 0 0 10px;
			}
		}
	}

	.no-results {
		margin-top: 50px;
		padding: 2rem;
		display: flex;
		align-items: center;
		color: #6a6a6a;

		// // @media (max-width: 1401px) {
		// // 	margin-top: 50px;
		// // }

		@media (max-width: 991px) {
			margin-top: 100px;
		}
	}

	.right-drawer-list {
		list-style: none;
		margin: 0;
		padding: 0;
		overflow-x: hidden;
		min-width: 320px;
		padding-top: 50px;

		&.add-padding {
			padding-top: 99px;
		}

		&:empty {
			display: none;
		}

		li {
			position: relative;
			border-bottom: 1px solid #e6e6e6;
			transition: 0.3s ease-in-out;

			&.hippidy {
			}

			&:hover {
				background: #f2f2f2;

				button {
					opacity: 1 !important;
				}
			}

			button.close {
				opacity: 0;
				position: absolute;
				border-radius: 50% 0 0 50%;
				background: #e2e2e2;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 35px;
				height: 35px;
				border-radius: 50%;
				top: calc(70% - 17.5px);
				right: 1rem;
				transition: 0.3s ease-in-out;
				cursor: pointer;

				@media (max-width: 600px) {
					opacity: 1;
				}

				svg {
					height: 17px;
					display: block !important;

					path {
						fill: #454545;
					}
				}

				&:hover {
					background: darken(#e2e2e2, 10%);
				}
			}

			a {
				position: relative;
				display: flex;
				align-items: center;
				padding-top: 1rem;
				padding-bottom: 1rem;
				padding-right: 1rem;
				padding-left: 0.5rem;
			}

			.brand img,
			svg {
				max-height: 30px;
				display: none !important;
			}

			.list-image {
				min-height: 80px;
				min-width: 80px;
				margin-right: 1rem;
			}
		}

		.list-title {
			font-weight: 500;
			color: black;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			min-height: 40px;
			margin: 0;
		}

		.list-price-wrapper {
			margin-bottom: 0.5rem;
			color: black;

			.price {
				font-weight: 500;
				border-bottom: 2px solid #e41513;
			}

			.original-price {
				margin-right: 0.5rem;
				text-decoration: line-through;
			}
		}
	}
}
