.breadcrumbs {
  display: flex;
  align-items: center;
  // overflow-x: scroll;

  @media(max-width: 800px) {
    justify-content: flex-start;
    font-size: 0.95rem;
  }

  &::-webkit-scrollbar {
    height: 0;
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem 0 1rem 0.5rem;
    white-space: nowrap;
    display: inline-block;

    &:hover {
      color: #008484;
    }

    &:first-child {
      @media(min-width: 901px) {
        padding-left: 0;
      }
    }


    &:last-child {
      @media(max-width: 900px) {
        color: #707070;
      }

      &:after {
        display: none !important;
      }

    }

    &:after {
      content: ">";
      margin-left: 0.5rem;
      color: #ddd;
      pointer-events: none;
    }

  }
}