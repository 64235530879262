.color {
    &__error .MuiSnackbarContent-root {
        background-color: #f44336;
    }
    &__warning .MuiSnackbarContent-root {
        background-color: #ff9800;
    }
    &__information .MuiSnackbarContent-root {
        background-color: #2196f3;
    }
    &__success .MuiSnackbarContent-root {
        background-color: #4caf50;
    }
}
