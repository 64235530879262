.produkt {
  background: #f6f6f6;
  overflow: hidden;
  padding-bottom: 44px;
  font-size: 1rem !important;
  padding-top: 94px;

  &.error {
    @media (min-width: 1402px) {
      padding-top: 94px;
    }
  }

  p {
    font-size: 1rem !important;
  }

  @media (max-width: 1402px) {
    padding-top: 50px;
  }

  @media (max-width: 991px) {
    padding-top: calc(88px + 0.7rem);
  }

  .our-container {
    overflow: hidden;
  }

  .breadcrumbs {
    padding-left: 2rem;

    @media (max-width: 900px) {
      padding-left: 1.5rem;
      padding-right: 2rem;
    }
    @media (max-width: 600px) {
      padding-left: 0.5rem;
    }
  }

  .container {
    @media (max-width: 1500px) {
      margin-right: 2rem;
      margin-left: 2rem;
    }

    @media (max-width: 600px) {
      margin-right: 1rem;
      margin-left: 1rem;
    }
  }

  .error-message {
    width: calc(100% - 2rem);
    padding: 0.75rem 1rem;
    background: rgba(228, 21, 19, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: white;
    top: 50px;

    @media (min-width: 1402px) {
      top: 94px;
    }

    @media (max-width: 800px) {
      text-align: center;
    }
  }

  .sku-swiper {
    // & > .swiper-wrapper > .swiper-slide {
    //   width: 100% !important;
    // }
    & > .swiper-wrapper > .swiper-slide {
      width: 100% !important;
    }

    .swiper-slide {
      display: flex;
      place-content: center;
      // width: 100% !important;
    }
    .extra {
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 505px;
      text-align: center;

      .extra-heading {
        font-size: 2.6rem;
        font-weight: 500;
        margin-bottom: 0;
      }

      p {
        font-size: 1.4rem;
        max-width: 80%;
        margin-top: 0;
      }
    }

    .display {
      &__flex {
        display: flex;
      }
    }

    .navigation-button {
      background: #e2e2e2;
      height: 120px;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 200px;
      position: absolute;
      transition: 0.3s ease-in-out;
      border: 0;
      z-index: 12;
      cursor: pointer;
      border-radius: 50%;

      svg {
        height: 42px;
        width: 42px;
        fill: #454545;
      }

      &.left {
        left: -60px;

        &.disabled {
          left: -120px;
        }

        svg {
          transform: rotate(90deg);
          margin-right: -3rem;
        }
      }

      &.right {
        right: -60px;

        &.disabled {
          right: -120px;
        }

        svg {
          transform: rotate(-90deg);
          margin-left: -3rem;
        }
      }
    }

    .favorite-button {
      background: #f3f3f3;
      z-index: 150;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      position: absolute;
      top: 2rem;
      transition: 0.3s ease-in-out;
      right: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 600px) {
        top: 1rem;
        right: 1rem;
      }

      &:hover svg {
        fill: red;
      }

      &.favorised svg path:last-child {
        fill-opacity: 100;
      }

      svg {
        width: 24px;
        fill: #9c9c9c;

        path:last-child {
          fill-opacity: 0;
        }
      }
    }

    .swiper-slide-wrapper {
      background: white;
      max-width: 1500px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      // flex-direction: row;
      margin: 0 2rem;
      position: relative;

      @media (max-width: 600px) {
        margin: 0 1rem;
      }

      @media (max-width: 1160px) {
        flex-direction: column;
      }

      .swiper-thumbs-gallery {
        padding: 1rem 2rem 1rem 1rem;
        // max-width: 560px;

        @media (max-width: 1160px) {
          padding: 1rem;
          display: flex;
          flex-direction: column;
          align-self: center;
        }

        &__swiper__main-images,
        &__main-image {
          margin: 0;
          .swiper-slide {
            width: 100% !important;
          }
        }

        &__swiper__thumb-images {
          width: 100%;
          padding-top: calc(1rem - 2px);

          .swiper-wrapper {
            display: flex;
            place-content: center;
          }

          .swiper-slide {
            opacity: 0.4;
          }

          .swiper-slide-thumb-active {
            opacity: 1;
          }
        }
      }

      .swiper-slide {
        text-align: center;
        // font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }

      .product-information {
        position: relative;
        padding: 1rem 1rem 1rem 2rem;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;

        @media (max-width: 1160px) {
          padding: 1rem;
        }

        &__first,
        &__second,
        &__third,
        &__fifth,
        &__sixth {
          border-bottom: 1px solid #ddd;
        }

        section {
          padding: 0.5rem 1rem 0.5rem 0rem;

          @media (max-width: 600px) {
            padding: 0.5rem 0;
          }
        }

        .program-logo {
          display: flex;
          align-items: center;
          justify-content: center;
          // height: 21px;

          & > svg {
            max-height: 10px;
          }

          & > .skeleton-wrapper,
          & > svg {
            max-width: 180px;
            width: 100%;
            height: auto;
            transition: 0.3s ease-in-out;
            margin-left: 0.25rem;

            & > span {
              max-height: 21px;
            }

            // @media (max-width: 450px) {
            //   margin-top: 1rem;
            //   margin-left: 0;
            // }
          }
        }

        &__first {
          display: flex;
          h1,
          .heading-1 {
            display: flex;
            place-items: center;
            font-weight: 500;
            font-size: 2rem;
            margin: 0.5rem 0;
            max-width: 656px;
            // min-height: 100px;
            // margin: 0.5rem 5rem 0.5rem 0rem;

            @media (min-width: 1161px) {
              padding-right: 50px;
            }

            @media (max-width: 600px) {
              font-size: 1.5rem;
            }

            .rea {
              color: rgba(0, 0, 0, 0.55);
              margin-left: 10px;
              font-size: 1.5rem;
              font-weight: 500;

              @media (max-width: 625px) {
                position: absolute;
                top: 13px;
                margin-left: 1px;
                font-size: 1rem;
              }
            }

            // .sku-title {
            //   font-size: 2rem;
            //   display: flex;
            //   place-items: center;
            //   padding: 0;
            //   margin: 0;
            // }
          }
        }

        &__second {
          .first-row {
            @media (max-width: 599px) {
              flex-direction: column;
            }

            .price {
              font-weight: 500;
              font-size: 2rem;
              text-decoration: underline;
              text-decoration-color: red;

              @media (max-width: 1220px) {
                font-size: calc(1.8rem);
              }
            }
            .original-price {
              margin-left: 1rem;
              display: flex;
              align-items: center;
              font-size: 1.5rem;
              text-decoration: line-through;

              @media (max-width: 1220px) {
                font-size: calc(1.3rem);
              }
            }
            .price-badges-wrapper {
              margin-left: 1rem;
              flex-wrap: wrap;

              @media (max-width: 599px) {
                margin: 0.5rem 0;
              }
              .badge:nth-child(1) {
                margin-right: 0.25rem;
              }
              .badge:not(.badge:nth-child(1)) {
                margin: 0rem 0.25rem;
              }
              .badge {
                display: flex;
                align-items: center;
                // margin: 0rem 0.25rem;
                // font-size: 1rem;

                span {
                  background: #008484;
                  color: white;
                  text-align: center;
                  padding: 0.25rem 0.5rem;
                }

                &.discount span {
                  background: #e41513;
                  color: white;
                }

                &.saving span {
                  background: #e41513;
                  color: white;
                }

                &.free-shipping span {
                  background: #cef7e7;
                  color: black;
                }

                &.vintage span {
                  background: #f7eace;
                  color: black;
                }

                &.not-in-stock span {
                  background: #000;
                  color: #fff;
                  display: flex;
                  align-items: center;

                  .bell {
                    height: 1rem;
                    width: 1rem;
                    margin-left: 0.25rem;
                    path {
                      fill: white;
                    }
                  }
                }

                &.order-item span {
                  background: #e96100;
                  color: #fff;
                  display: flex;
                  align-items: center;

                  .calendar {
                    height: 1rem;
                    width: 1rem;
                    margin-left: 0.25rem;
                    path {
                      fill: white;
                    }
                  }
                }
              }
            }
          }
          .second-row {
            margin-top: 0.5rem;
            flex-flow: wrap;

            @media (max-width: 599px) {
              margin: 0;
            }

            .title {
              margin-right: 0.3rem;
            }
            .payment-method {
              margin: 0rem 0.25rem;
            }

            .payment-method {
              svg,
              img {
                height: 10px;
                // opacity: 0.75;
              }

              img {
                background-color: transparent;
                background-image: transparent;
                animation: none;
              }
            }
          }
        }

        &__third {
          & > div {
            padding: 1.5px 0;
          }
          .title {
            margin-right: 0.5rem;
          }
          .shipping-title {
            margin-right: 0.3rem;
          }

          .shipping-method {
            margin: 0rem 0.25rem;
          }

          .shipping-method {
            svg,
            img {
              height: 10px;
              // opacity: 0.75;
            }

            img {
              background-color: transparent;
              background-image: transparent;
              animation: none;
            }
          }
        }

        &__fourth {
          p {
            max-width: 40rem;
          }
        }

        &__fifth,
        &__sixth {
          display: flex;
          flex-flow: wrap;
          .title {
            margin-right: 0.3rem;
          }
          .size,
          .colour {
            margin: 0rem 0.25rem;
          }
        }

        &__cta-section {
          display: flex;
          place-items: center;
          margin: 2rem 0 0.5rem 0;

          @media (max-width: 450px) {
            flex-direction: column;
            margin: 0.5rem 0;
          }
        }
      }
    }
  }

  .monitor-button-wrapper {
    display: flex;
    flex-direction: column;

    a {
      text-decoration: underline;
      align-self: center;
    }
  }

  .monitor-button {
    background: linear-gradient(-45deg, black, black, #515151, black);
    -webkit-animation: Gradient 4.5s ease infinite;
    animation: Gradient 4.5s ease infinite;
    background-size: 400% 400%;
    transition: 0.3s ease-in-out;
    padding: 1rem 3rem;
    border-radius: 3rem;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;

    .bell {
      height: 20px;
      width: 20px;
      margin-left: 1rem;

      path {
        fill: white;
      }
    }
  }

  .description {
    /* background: white; */
    /* max-width: 1500px; */
    margin: 2rem;
    display: flex;
    min-height: 17.5rem;
    place-content: center;

    @media (max-width: 600px) {
      margin: 1rem;
    }

    &__content {
      display: flex;
      max-width: 1500px;
      background: white;
      width: 100%;

      // @media (max-width: 600px) {
      //   margin: 2rem 1rem;
      // }

      @media (max-width: 1160px) {
        flex-direction: column;
      }
    }

    // @media (max-width: 1500px) {
    //   margin-left: 2rem;
    //   margin-right: 2rem;
    // }

    // @media (max-width: 1500px) {
    //   margin-left: 2rem;
    //   margin-right: 2rem;
    // }

    .box {
      padding: 1rem;
      width: calc(50% - 2rem);

      @media (max-width: 1160px) {
        width: unset;
      }
    }

    .glimpse {
      position: relative;
      .content {
        max-height: 10rem;
        overflow-y: hidden;
        &.opened {
          max-height: unset;
        }
      }

      .fade {
        height: 5rem;
        margin-top: -5rem;
        position: absolute;
        width: 100%;
        background: linear-gradient(0deg, white, transparent);
      }

      button {
        background: transparent;
        color: black;
        font-weight: 500;
        font-size: 1.1rem;
        display: inline-flex;
        align-items: center;
        border-radius: 3rem;
        cursor: pointer;
        text-decoration: underline;
        transition: 0.3s ease-in-out;
        padding: 0;
        margin: 0;
      }
    }

    &__product {
      margin-right: 2rem;

      @media (max-width: 1200px) {
        margin-right: unset;
      }
    }

    &__program {
      margin-left: 2rem;

      @media (max-width: 1200px) {
        margin-left: unset;
      }

      .content {
        .paragraph-wrapper {
          min-height: 9rem;
          margin-top: 1.5rem;
        }
      }
      .show-all-link {
        font-size: 1.1rem;
        font-weight: 500;
        text-decoration: underline;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .price-history {
    margin: 2rem;
    display: flex;
    justify-content: center;

    @media (max-width: 600px) {
      margin: 1rem;
    }

    &__content {
      & > div {
        padding: 1rem;
      }
      max-width: 1500px;
      width: 100%;
      background: white;
    }
  }

  .enviremental-info {
    margin: 2rem;
    display: flex;
    // min-height: 17.5rem;
    place-content: center;

    .wrapper {
      display: flex;
      max-width: 1500px;
      background: #cef7e7;
      width: 100%;

      @media (max-width: 1160px) {
        flex-direction: column;
      }
    }

    h3 {
      margin: 1rem 0rem;
      white-space: nowrap;
    }

    .box {
      padding: 1rem;
    }

    &__left {
      margin-right: 3rem;

      @media (max-width: 1160px) {
        margin-right: 0;
      }

      .content {
        display: flex;
        place-items: center;
        justify-content: center;
      }
    }

    &__right {
      margin-left: 3rem;

      @media (max-width: 1160px) {
        margin-left: 0;
      }
    }
  }

  .related-products-wrapper {
    z-index: 2;
    position: relative;

    &.container {
      padding: 0;
      margin: 0 auto;
    }

    &.contains-extra-card {
      .swiper-wrapper {
        .swiper-slide {
          max-width: 297px;
          margin-right: 4px;

          &:nth-child(1) {
            @media (max-width: 1500px) {
              padding-left: 2rem;
            }

            @media (max-width: 600px) {
              padding-left: 1rem;
            }
          }
        }

        .swiper-slide:last-child {
          display: none;
        }
      }
    }

    .deal-item {
      .deal-name {
        max-width: unset !important;
      }
      .price-box {
        min-height: 3rem;
      }
    }

    .favorite-button,
    .quickview-button {
      display: none !important;
    }

    .container {
      &:nth-child(2) {
        max-width: 1500px;
        padding-left: 0;
        padding-right: 0;

        @media (max-width: 900px) {
          padding-left: 0rem !important;
        }
      }
    }

    .related-products {
      .next {
        right: 1rem;
      }

      .prev {
        left: 1rem;
      }

      .next,
      .prev {
        top: 50%;
      }
    }

    .related-title {
      padding: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 1160px) {
        justify-content: center;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }

      .related-products {
        margin: 0;
        font-size: 1.3rem;
        font-weight: 500;
        margin-right: 1rem;

        @media (max-width: 460px) {
          font-size: 1.4rem;

          i {
            display: none;
          }
        }

        i {
          font-style: normal;
        }
      }
    }
  }

  #geo-map {
    margin-top: 2rem;
    background: white;
    padding: 0;
    @media (max-width: 600px) {
      margin-top: 1rem;
    }

    .leaflet-control-attribution {
      display: none;
    }

    .leaflet-pane {
      animation: fadeIn 0.3s ease-in-out 2s both;
    }

    .leaflet-left {
      margin: 0;
      left: unset;
      right: 1rem;
      top: 1rem;

      @media (max-width: 1100px) {
        top: auto;
        bottom: 1rem;
      }

      > div {
        margin: 0;
        border: 0;

        a {
          font-weight: 300;
          border: 0;
          border-radius: 50%;

          &:nth-child(1) {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

// #sku-swipe {
//   .swiper-slide {
//     transition: 0.3s ease-in-out;

//     @media (min-width: 1120px) {
//       min-width: 100vw !important;
//     }

//     &.swiper-slide-active {
//       opacity: 1;
//     }
//   }

//   .navigation-button {
//     background: #e2e2e2;
//     height: 120px;
//     width: 120px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     top: 200px;
//     position: absolute;
//     transition: 0.3s ease-in-out;
//     border: 0;
//     z-index: 12;
//     cursor: pointer;

//     @media (min-width: 500px) {
//       &:hover {
//         background: darken(#e2e2e2, 10%);
//       }
//     }

//     @media (max-width: 475px) {
//       top: 150px;
//       height: 100px;
//       width: 100px;
//     }

//     &.left {
//       left: -60px;
//       border-radius: 50%;

//       @media (max-width: 475px) {
//         left: -50px;
//       }

//       &:hover {
//         background: darken(#e2e2e2, 10%);
//       }

//       &.disabled {
//         left: -120px;
//       }

//       svg {
//         transform: rotate(90deg);
//         height: 42px;
//         width: 42px;
//         margin-right: -3rem;
//         fill: #454545;

//         @media (max-width: 475px) {
//           margin-right: -2.5rem;
//         }
//       }
//     }

//     &.right {
//       right: -60px;
//       border-radius: 50%;

//       @media (max-width: 475px) {
//         right: -50px;
//       }

//       &:hover {
//         background: darken(#e2e2e2, 10%);
//       }

//       &.disabled {
//         right: -120px;
//       }

//       svg {
//         transform: rotate(-90deg);
//         height: 42px;
//         width: 42px;
//         margin-left: -3rem;
//         fill: #454545;

//         @media (max-width: 475px) {
//           margin-left: -2.5rem;
//         }
//       }
//     }
//   }

//   article {
//     z-index: 2;
//     position: relative;
//     margin: 0 auto;

//     &.main-sku {
//       background: white;
//       position: relative;
//       min-height: 505px;
//       overflow: hidden;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       padding: 0;

//       @media (max-width: 1500px) {
//         margin-right: 2rem;
//         margin-left: 2rem;
//       }

//       @media (max-width: 1120px) {
//         flex-direction: column;
//         align-items: unset;
//         min-height: auto;
//       }

//       @media (max-width: 600px) {
//         margin-right: 1rem;
//         margin-left: 1rem;
//       }

//       .badge-wrapper {
//         position: absolute;
//         top: 2rem;
//         left: 0rem;
//         display: flex;
//         flex-direction: column;
//         z-index: 150;

//         @media (max-width: 600px) {
//           top: 1rem;
//         }

//         > div {
//           margin-bottom: 0.5rem;
//           padding: 0 0.75rem;
//           height: 30px;
//           font-weight: 500;
//           font-size: 0.85rem;

//           @media (max-width: 600px) {
//             margin-bottom: 0.3rem;
//             padding: 0 0.6rem;
//           }
//         }

//         .free-shipping {
//           background: #cef7e7;
//           color: rgba(black, 0.55);
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           width: 45px;
//           margin: 0;
//         }

//         .amount-saved {
//           background: #111;
//           color: white;
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           cursor: default;
//         }

//         .discount {
//           color: #fff;
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           background: #008484;
//           z-index: 20;
//           pointer-events: none;

//           &:before {
//             content: "-";
//           }
//         }
//       }

//       .favorite-button {
//         background: #f3f3f3;
//         z-index: 150;
//         height: 48px;
//         width: 48px;
//         border-radius: 50%;
//         position: absolute;
//         top: 2rem;
//         transition: 0.3s ease-in-out;
//         right: 2rem;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         cursor: pointer;

//         @media (max-width: 600px) {
//           top: 1rem;
//           right: 1rem;
//         }

//         &.favorised {
//           svg {
//             path {
//               &:first-child {
//                 fill-opacity: 0;
//               }

//               &:last-child {
//                 fill-opacity: 100;
//               }
//             }
//           }
//         }

//         &:hover {
//           svg {
//             fill: #e41513;
//           }
//         }

//         svg {
//           fill: #9c9c9c;
//           width: 24px;

//           path {
//             transition: 0.3s ease-in-out;

//             &:first-child {
//             }

//             &:last-child {
//               fill-opacity: 0;
//             }
//           }
//         }
//       }

//       &.extra {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         min-height: 505px;
//         text-align: center;

//         .extra-heading {
//           font-size: 2.6rem;
//           font-weight: 500;
//           margin-bottom: 0;

//           @media (max-width: 600px) {
//             font-size: 1.6rem;
//           }
//         }

//         p {
//           font-size: 1.4rem;
//           max-width: 80%;
//           margin-top: 0;
//         }
//       }
//     }

//     .sku-image {
//       position: relative;
//       max-width: 600px;
//       width: 100%;
//       margin-right: 2rem;

//       @media (min-width: 1120px) and (max-width: 1400px) {
//         max-width: 550px;
//       }

//       @media (max-width: 1120px) {
//         max-width: 100%;
//         margin-right: 0;
//       }

//       .single-image {
//         display: flex;
//         justify-content: center;
//         width: 100%;
//       }

//       .next,
//       .prev {
//         top: auto;
//         bottom: 20px;
//         left: 0;
//         right: 0;
//         margin: 0 auto;
//       }

//       .next {
//         transform: translate(94px, 1px) rotate(-90deg);
//       }

//       .prev {
//         transform: translate(-94px, 1px) rotate(90deg);
//       }

//       .swiper-slide {
//         min-width: auto !important;
//       }

//       .swiper-wrapper {
//         margin: 0;
//         padding: 0;

//         .skeleton-wrapper {
//           > div,
//           span {
//             display: flex !important;
//             justify-content: center;
//             align-items: center;
//           }
//         }
//       }

//       #thumbs {
//         position: absolute;
//         bottom: 1rem;
//         z-index: 999;
//         padding: 0;
//         left: 0;
//         right: 0;
//         width: 128px;

//         img {
//           padding-top: 0;
//         }

//         .swiper-slide {
//           transition: 0.3s ease-in-out;
//           opacity: 1;
//           cursor: pointer;
//           pointer-events: all;
//           overflow: hidden;

//           > div {
//             display: flex;
//             align-items: center;
//             justify-content: center;

//             span {
//               max-width: 55px;
//               max-height: 55px;
//               display: block !important;

//               img {
//                 background: transparent !important;
//                 object-fit: cover !important;
//                 border-radius: 50%;
//                 max-width: 55px;
//                 max-height: 55px;
//               }
//             }
//           }
//         }

//         .swiper-slide-thumb-active {
//           cursor: default;

//           img {
//             opacity: 1 !important;
//           }
//         }
//       }
//     }

//     .product-title-wrapper {
//       height: 50px;
//     }

//     .sku-title {
//       h1,
//       .heading-1 {
//         display: flex;
//         align-items: center;
//         margin: 0;
//         font-size: 2.2rem;
//         font-weight: 500;
//         height: 80px;
//         width: 90%;

//         @media (max-width: 1120px) {
//           width: 100%;
//           font-size: 1.6rem;
//           height: auto;
//           margin-bottom: 1rem;
//         }

//         @media (max-width: 600px) {
//           font-size: 1.5rem;
//         }

//         .product-title {
//           text-transform: capitalize;

//           @media (min-width: 600px) {
//             display: -webkit-box;
//             -webkit-line-clamp: 2;
//             -webkit-box-orient: vertical;
//             overflow: hidden;
//           }
//         }
//       }

//       .rea {
//         color: rgba(black, 0.55);
//         margin-left: 10px;
//         font-size: 1rem;
//         letter-spacing: 0.05rem;

//         @media (max-width: 600px) {
//           position: absolute;
//           top: -4px;
//           margin: 0;
//         }
//       }
//     }

//     .sku-small-description {
//       margin: 1rem 0;
//       margin-top: 0;
//       line-height: 1.6;
//       min-height: 8rem;
//       color: black;
//       list-style: none;
//       padding: 0;

//       @media (min-width: 1121px) {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//       }

//       li {
//         display: flex;
//         align-items: center;
//         line-height: 1.2;
//         margin-bottom: 0.5rem;

//         &:last-child {
//           margin: 0;
//         }

//         &:before {
//           content: "";
//           margin-right: 0.5rem;
//           min-height: 4px;
//           max-height: 4px;
//           min-width: 4px;
//           max-width: 4px;
//           border-radius: 50%;
//           background: black;
//           display: flex;
//         }
//       }

//       @media (max-width: 1120px) {
//         order: 2;
//         margin-top: 0;
//         font-size: 1rem;
//       }

//       @media (max-width: 600px) {
//         height: auto;
//       }

//       @media (max-width: 500px) {
//         min-height: 4rem;
//       }
//     }

//     .sku-content {
//       width: 100%;
//       z-index: 3;
//       position: relative;
//       align-self: center;
//       padding: 3rem 3rem 3rem 0rem;

//       @media (max-width: 1120px) {
//         padding: 1rem 2rem 2rem 2rem;
//         width: calc(100% - 4rem);
//         display: flex;
//         flex-direction: column;
//       }

//       @media (max-width: 500px) {
//         padding: 1rem;
//         width: calc(100% - 2rem);
//       }

//       .sku-content-wrapper {
//         > div {
//           // PRICE SECTION
//           &:nth-child(1) {
//             display: flex;
//             align-items: center;
//             justify-content: space-between;
//             font-size: 1.3rem;
//             border: 1px solid #e6e6e6;
//             padding: 1rem 1.5rem;

//             @media (max-width: 800px) {
//               flex-direction: column;
//               align-items: flex-start;
//               padding: 1rem;
//             }

//             @media (max-width: 500px) {
//               font-size: 1.15rem;
//             }

//             .save {
//               margin-left: 1rem;
//               margin-right: 1rem;
//             }

//             > div {
//               display: flex;
//               align-items: center;

//               &:nth-child(1) {
//                 min-width: 280px;
//               }

//               > div {
//                 display: flex;
//                 align-items: center;
//               }
//             }

//             .question {
//               margin-left: 1rem;
//               height: 25px;
//               width: 25px;
//               font-size: 0.9rem;
//               font-weight: 500;
//               color: #454545;
//               border-radius: 50%;
//               background: #f3f3f3;
//               display: flex;
//               cursor: pointer;
//               justify-content: center;
//               align-items: center;
//               transition: 0.3s ease-in-out;
//               z-index: 55;
//               position: relative;

//               &:hover {
//                 background: darken(#e6e6e6, 5%);
//               }

//               @media (max-width: 800px) {
//                 display: none;
//               }
//             }
//           }

//           // SHIPPING SECTION
//           &:nth-child(2) {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             padding: 0.75rem 1.5rem;
//             border: 1px solid #e6e6e6;
//             border-top: 0;

//             @media (max-width: 460px) {
//               padding: 0.75rem 1rem;
//               justify-content: space-between;
//               flex-direction: row;
//               align-items: center;
//             }

//             .delivery-options {
//               list-style: none;
//               margin: 0;
//               padding: 0;
//               display: flex;

//               @media (max-width: 460px) {
//                 flex-direction: column;
//                 width: 100%;
//               }

//               li {
//                 position: relative;
//                 margin-right: 0.75rem;
//                 padding-right: 0.75rem;
//                 border-right: 1px solid #e6e6e6;
//                 display: flex;
//                 align-items: center;
//                 color: #7c7c7c;
//                 font-size: 14px;

//                 @media (max-width: 460px) {
//                   margin-bottom: 0.5rem;
//                   padding-bottom: 0.5rem;
//                   margin-right: 0;
//                   border-bottom: 1px solid #e6e6e6;
//                   border-right: 0;
//                   padding-right: 0;

//                   &:last-child {
//                     margin-bottom: 0;
//                     padding-bottom: 0;
//                     border: 0;
//                   }
//                 }

//                 span {
//                   color: black;
//                 }

//                 svg {
//                   height: 16px;
//                   fill: #7c7c7c;

//                   @media (max-width: 460px) {
//                     height: 13px;
//                   }
//                 }

//                 div {
//                   display: flex;
//                   flex-direction: column;
//                   align-items: flex-start;

//                   @media (min-width: 461px) {
//                     justify-content: space-between;
//                     height: 36px;
//                     min-width: 70px;
//                   }

//                   @media (max-width: 460px) {
//                     flex-direction: row;
//                     align-items: center;

//                     span {
//                       margin-right: 0.5rem;
//                     }
//                   }
//                 }

//                 &:last-child {
//                   border-right: 0;
//                   padding-right: 0;
//                   margin-right: 1rem;
//                 }

//                 span {
//                   font-weight: 500;
//                 }
//               }
//             }

//             .shipping-methods {
//               display: flex;
//               flex-wrap: wrap;
//               justify-content: flex-end;

//               @media (max-width: 1420px) {
//                 max-width: 100%;
//               }

//               @media (max-width: 460px) {
//                 flex-direction: column;
//                 max-width: 280px;
//                 margin-left: 1rem;
//                 padding-top: 0;
//               }

//               &:empty {
//                 display: none;
//               }

//               .shipping-method {
//                 display: flex;
//                 align-items: center;
//                 margin-right: 0.5rem;
//                 margin-top: 0.25rem;
//                 margin-bottom: 0.25rem;

//                 @media (max-width: 460px) {
//                   margin-right: 0;

//                   &:first-child {
//                     margin-top: 0;
//                   }
//                 }

//                 &:empty {
//                   display: none;
//                 }

//                 &:last-child {
//                   margin-right: 0;

//                   @media (max-width: 460px) {
//                     margin-bottom: 0;
//                   }
//                 }

//                 svg {
//                   height: 13px;
//                   opacity: 0.75;
//                 }
//               }
//             }
//           }

//           // SIZES & COLOURS
//           &:nth-child(3),
//           &:nth-child(4) {
//             border: 1px solid #e6e6e6;
//             border-top: 0;
//             font-size: 14px;
//             font-weight: 500;

//             > div {
//               span {
//                 margin-right: 5px;
//               }

//               @media (max-width: 500px) {
//                 text-transform: capitalize;

//                 span {
//                   display: none;
//                 }
//               }
//             }

//             ul {
//               list-style: none;
//               display: flex;
//               padding: 0;
//               margin: 0;
//               position: relative;
//               overflow-x: scroll;
//               border-left: 1px solid #e7e7e7;
//               -ms-overflow-style: none;
//               scrollbar-width: none;
//               width: 100%;

//               &::-webkit-scrollbar {
//                 height: 0;
//               }

//               li {
//                 font-weight: 500;
//                 font-size: 12px;
//                 padding: 0.75rem;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 cursor: default;
//                 color: #7c7c7c;
//                 border-right: 1px solid #e7e7e7;
//                 user-select: none;

//                 &:last-child {
//                   border-right: 0;
//                 }
//               }
//             }
//           }

//           &.sku-sizes,
//           &.sku-colors {
//             position: relative;
//             padding: 0;
//             margin: 0;
//             height: 100%;
//             display: grid;
//             grid-template-columns: [col] 160px [col] calc(100% - 160px);

//             @media (max-width: 500px) {
//               grid-template-columns: [col] 85px [col] calc(100% - 85px);
//             }

//             > div {
//               display: flex;
//               align-items: center;
//               padding-left: 1.5rem;
//               padding-right: 0.75rem;

//               @media (max-width: 850px) {
//                 padding-left: 1rem;
//               }
//             }

//             ul {
//               width: 100%;
//             }

//             &:after {
//               content: "";
//               height: 100%;
//               width: 50px;
//               background: linear-gradient(to right, rgba(white, 0) 0%, rgba(white, 1) 100%);
//               pointer-events: none;
//               position: absolute;
//               right: 0;
//             }
//           }

//           &.sku-colors {
//             li {
//               span {
//                 height: 15px;
//                 width: 15px;
//                 margin-right: 0.5rem;

//                 &.Svart {
//                   background: black;
//                 }

//                 &.Grå {
//                   background: grey;
//                 }

//                 &.Beige {
//                   background: beige;
//                 }

//                 &.Orange {
//                   background: orange;
//                 }

//                 &.Vit {
//                   background: white;
//                   border: 1px solid #e7e7e7;
//                   height: 14px;
//                   width: 14px;
//                 }

//                 &.Lila {
//                   background: purple;
//                 }

//                 &.Blå {
//                   background: blue;
//                 }

//                 &.Röd {
//                   background: red;
//                 }

//                 &.Rosa {
//                   background: pink;
//                 }

//                 &.Grön {
//                   background: green;
//                 }

//                 &.Gul {
//                   background: yellow;
//                 }

//                 &.Brun {
//                   background: brown;
//                 }

//                 &.Flerfärgad {
//                   display: flex;
//                 }
//               }
//             }
//           }

//           &:last-child {
//             margin-bottom: 1.5rem;
//           }
//         }
//       }

//       &__show-more-button {
//         display: flex;
//         place-content: center;
//         cursor: pointer;
//         flex-direction: column;
//         align-items: center;
//         padding: 1rem 0;

//         &:empty {
//           cursor: default;
//         }
//       }

//       &__numbers__price {
//         font-weight: 500;
//         border-bottom: 2px solid #e41513;
//         margin-left: 1rem;
//         font-size: 1.8rem;
//       }

//       &__numbers__original-price {
//         text-decoration: line-through;
//       }

//       &__buy {
//         display: flex;
//         align-items: center;

//         @media (max-width: 1120px) {
//           order: 3;
//         }

//         @media (max-width: 450px) {
//           flex-direction: column;
//         }

//         a {
//           &:nth-child(2) {
//             display: flex;
//             align-items: center;
//             justify-content: center;

//             @media (max-width: 450px) {
//               margin-top: 1rem;
//             }
//           }
//         }

//         .button-svg {
//           margin-left: 1rem;
//           height: 15px;
//           width: 15px;
//           align-self: center;
//           path {
//             fill: white;
//           }
//         }

//         svg,
//         .skeleton-wrapper {
//           max-height: 25px;
//           max-width: 180px;
//           width: 100%;
//           height: auto;
//           transition: 0.3s ease-in-out;
//           margin-left: 2rem;

//           @media (max-width: 450px) {
//             margin-left: 0;
//           }

//           img {
//             max-height: 25px;
//             max-width: 180px;
//             object-position: left;

//             @media (max-width: 450px) {
//               object-position: center;
//             }
//           }

//           &:hover {
//             opacity: 0.5;
//           }
//         }
//       }

//       &__additional-wrapper {
//         display: flex;
//         flex-direction: column;
//       }

//       &__additional {
//         display: flex;

//         @media (max-width: 850px) {
//           margin-top: 1rem;
//         }

//         ul {
//           list-style: none;
//           padding: 0;
//           margin: 0;
//           display: flex;
//           margin-bottom: 1rem;
//         }

//         .payment-options {
//           display: flex;
//           flex-wrap: wrap;
//           justify-content: flex-end;

//           @media (max-width: 1600px) {
//             max-width: 500px;
//           }

//           @media (max-width: 500px) {
//             justify-content: flex-start;
//           }

//           .payment-alternative {
//             margin-right: 0.5rem;

//             &:empty {
//               display: none;
//             }

//             &:last-child {
//               margin-right: 0;
//             }

//             svg,
//             img {
//               height: 13px;
//               opacity: 0.75;
//             }

//             img {
//               background-color: transparent;
//               background-image: transparent;
//               animation: none;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// #sku-description {
//   margin-top: 2rem;
//   background: white;
//   @media (max-width: 600px) {
//     margin-top: 1rem;
//   }

//   &.container {
//     padding: 0;
//   }

//   div {
//     &:empty {
//       display: none;
//     }
//   }

//   > div {
//     display: flex;
//     justify-content: space-between;
//     display: flex;
//     flex-wrap: wrap;
//     padding: 3rem;

//     @media (max-width: 1195px) {
//       flex-direction: column;
//       padding: 2rem;
//     }

//     @media (max-width: 500px) {
//       padding: 1.5rem 1rem;
//     }

//     > div {
//       width: calc(50% - 2rem);
//       @media (max-width: 1195px) {
//         width: 100%;
//         margin-bottom: 2rem;

//         &:last-child {
//           margin: 0;
//         }
//       }

//       &:nth-child(1) {
//         order: 1;
//       }

//       &:nth-child(2) {
//         order: 2;

//         @media (max-width: 1195px) {
//           order: 6;
//         }
//       }

//       &:nth-child(3) {
//         order: 2;
//       }

//       &:nth-child(4) {
//         order: 4;
//       }
//     }
//   }

//   canvas {
//     user-select: none;
//     min-height: 370px;
//   }

//   hr {
//     height: 1px;
//     border: 0;
//     background: #f3f3f3;
//     margin: 2rem 0;
//   }

//   h2,
//   h3 {
//     margin-top: 0;
//     font-weight: 500;

//     @media (max-width: 1120px) {
//       font-size: 1.4rem;
//     }
//   }

//   p {
//     color: #707070;

//     &:last-child {
//       margin-bottom: 0;
//     }
//   }
// }

// .small-coupon {
//   background: lightgreen;
//   border-radius: 0.5rem;
//   margin-left: 1rem;
//   padding: 4px 10px;
//   font-size: 0.9rem;
//   font-weight: 600;
// }

// .coupon-card {
//   background: lightgreen;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin: 1rem 0rem;
//   position: relative;

//   @media (max-width: 700px) {
//     flex-direction: column;
//   }

//   &:last-child {
//     margin-bottom: 0;
//   }

//   p.coupon-description {
//     font-weight: 600;
//     text-transform: lowercase;
//     margin: 0;
//     color: black !important;
//     text-transform: capitalize;
//     width: 100%;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;

//     @media (min-width: 1280px) {
//       max-width: 280px;
//     }

//     @media (max-width: 900px) {
//       max-width: 350px;
//     }

//     @media (max-width: 500px) {
//       max-width: 200px;
//     }
//   }

//   >div {
//     &:nth-child(1) {
//       padding: 1rem 1.5rem;
//       font-size: 1rem;
//       width: 100%;
//       font-weight: 400;
//       display: flex;
//       flex-direction: column;

//       @media (max-width: 700px) {
//         width: auto;
//         text-align: center;
//       }

//       b {
//         font-size: 1.2rem;
//         padding-right: 0.2rem;
//       }
//     }

//     &:nth-child(2) {
//       padding: 1.5rem 1.5rem;
//       border-left: 1px dashed rgba(black, 0.1);
//       position: relative;
//       min-width: 60px;

//       @media (max-width: 700px) {
//         border-left: 0;
//         border-top: 1px dashed rgba(black, 0.1);
//         width: calc(100% - 4rem);
//         text-align: center;
//         padding: 1rem 2rem;
//       }

//       &:before {
//         content: "";
//         height: 20px;
//         width: 20px;
//         background: white;
//         position: absolute;
//         border-radius: 50%;
//         top: -10px;
//         left: -11px;

//         @media (max-width: 700px) {
//           top: -10px;
//           right: -11px;
//           left: auto;
//           bottom: auto;
//         }
//       }

//       &:after {
//         content: "";
//         height: 20px;
//         width: 20px;
//         background: white;
//         position: absolute;
//         border-radius: 50%;
//         bottom: -10px;
//         left: -11px;

//         @media (max-width: 700px) {
//           top: -10px;
//           left: -11px;
//           bottom: auto;
//         }
//       }

//       button {
//         font-size: 1rem;
//         padding: 0.5rem 1rem;
//         border-radius: 3rem;
//         font-weight: 500;
//         background: rgba(black, 0.1);
//         border: 0;
//         cursor: pointer;
//         color: black;

//         &:hover {
//           background: rgba(black, 0.2);
//         }
//       }
//     }
//   }
// }

// // Modal
// .modal-wrapper {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   animation: fadeIn 0.3s ease-in-out 0s both;
// }
