.card__wrapper {
  position: relative;
  // min-height: 440px !important;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 4px;
  .card__cardImage__saveHeart {
    background: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    width: 35px;
    height: 35px;
    border-radius: 31px;
    position: absolute;
    margin: 16px 16px 0 0;
    top: 0;
    right: 0;
    svg {
      width: 19px;
      height: 19px;
      fill: #505050;
    }
    :hover {
      fill: #e41513;
    }
  }
  .card__cardImage__saveHeart--filled {
    svg {
      fill: #e41513;
    }
  }
  .card {
    background: #ffffff;
    border: 1px solid #efeeee;
    border-radius: 5px;
    padding-top: 1.3rem;

    &:hover {
      .card__cardImage__image {
        transform: scale(1.05);
      }
    }
    
    .cardContainer {
      padding: 1.3rem 1.3rem 0.75rem 1.3rem;
      @media (max-width: 768px) {
        padding: 1.3rem 0.75rem 0.75rem 0.75rem;
      }
    }

    .card__cardImage {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      overflow: hidden;
      // height: 250px;
      position: relative;
      // padding-top: 1.3rem;
    }
    .card__cardHeading {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #505050;
      // padding-top: 1.3rem;
      // margin: 24px 0px 16px 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      // line-clamp: 5;
      -webkit-box-orient: vertical;
      min-height: 54px;
      word-break: break-word;
    }
    .card__cardPrice {
      font-weight: 700;
      font-size: 21px;
      line-height: 20px;
      color: #8b0000;
      padding-top: 0.75rem;
      // border-bottom: 1px solid #efeeee;
      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 18px;
      }
      // @media screen and (min-width: 768px) and (max-width: 1500px) {
      //   font-size: 16px;
      //   line-height: 20px;
      // }
      .card__cardPrice--lineThrough {
        font-size: 14px;
        line-height: 20px;
        margin-left: 5px;
        text-decoration-line: line-through;
        color: #a2a1a1;
        // @media screen and (min-width: 768px) and (max-width: 1500px) {
        //   font-size: 14px;
        //   line-height: 20px;
        // }
        @media (max-width: 768px) {
          font-size: 16px;
          // line-height: 42px;
        }
      }
    }
    .card__cardParagraph {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.6rem 0 0 0;
      min-height: 31px;
      @media (max-width: 768px) {
        padding: 0;
      }
      .card__cardParagraph--discount {
        font-weight: 500;
        font-size: 16px;
        // line-height: 20px;
        color: #505050;
        // @media screen and (min-width: 768px) and (max-width: 1500px) {
        //   font-size: 16px;
        //   line-height: 20px;
        // }
        // @media (max-width: 768px) {
        //   // font-size: 14px;
        //   // line-height: 16px;
        // }
      }
    }
    .card__cardBottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 0.75rem;
      border-top: 1px solid #efeeee;
      margin-top: 0.75rem;
    }
    .card__cardBottom--image {
      display: flex;
    }
    .card__cardBottom--text {
      margin-left: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #505050;
      // @media screen and (min-width: 768px) and (max-width: 1500px) {
      //   font-size: 16px;
      //   line-height: 20px;
      // }
      // @media (max-width: 768px) {
      //   // font-size: 12px;
      //   // line-height: 10px;
      // }
    }
  }
  .ProductImageSection__image__stock {
    padding: 2px 10px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
    transform: rotate(-45deg);
    font-size: 15px;
    color: #ffffff;
    transform: rotate(-45deg);
    position: absolute;
    right: -45px;
    bottom: 25px;
    z-index: 1;

    @media (max-width: 500px) {
      width: 100px;
      padding: 2px 5px;
      right: -28px;
      bottom: 15px;
    }
  }
  .free-shipping {
    margin-top: 50px;
    position: absolute;
    background: #cef7e6;
    color: #505050;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 4px 10px;
    z-index: 2;
    border-radius: 0px 5px 5px 0px;
  }
  .sales-badge {
    margin-top: 20px;
    position: absolute;
    background: #ffdd05;
    color: black;
    font-size: 0.9rem;
    font-weight: 500;
    padding: 4px 10px;
    z-index: 2;
    border-radius: 0px 5px 5px 0px;
  }
  .ProductImageSection__image__zoom {
    margin: auto 0;
  }
  .ProductImageSection__image__instock {
    background: #68c000;
  }
  .ProductImageSection__image__outofstock {
    background: #eb1313;
  }
  .ProductImageSection__image__order-item {
    background: #e96100;
    @media (max-width: 500px) {
      font-size: 10px;
      bottom: 16px;
    }
  }
}
