#footer {
  background: #fefaf9;
  width: 100%;
  margin: 0 auto;
  padding-top: 5rem;
  position: relative;
  overflow: hidden;

  @media (max-width: 600px) {
    padding-top: 3rem;
  }

  .jiroy-logo {
    path,
    polygon {
      fill: #8c0001;
    }
  }

  h4 {
    &.footer-title {
      height: 30px;
      display: flex;
      align-items: center;
      color: black;
      margin: 0;
    }
  }

  ul.links {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 500;
    width: 100%;
  }

  ul {
    li {
      padding: 0.4rem 0;
    }

    &.countries li {
      a {
        display: flex;
        align-items: center;
      }
      // display: flex;

      svg {
        height: 20px;
        margin-right: 1rem;
      }
    }
  }

  .container {
    max-width: unset;

    > div {
      // CONTENT
      &:nth-child(1) {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1160px) {
          flex-direction: column;
        }

        .footer-left-wrap {
          display: flex;

          @media (min-width: 1160px) {
            width: 250px;
            margin-bottom: 0;
            margin-right: auto;
          }
        }

        .footer-brand {
          position: relative;
          width: 100%;

          .jiroy-logo {
            height: 50px;

            @media (max-width: 380px) {
              height: 40px;
            }

            path {
              fill: #8c0001;
            }
          }

          > div {
            p {
              color: #000;
              font-weight: 500;
              padding: 0;
              margin-top: 1rem;
              margin-bottom: 0;

              a {
                color: #8b0000;
              }
            }
          }
        }

        .footer-accordions {
          display: flex;

          @media (max-width: 900px) {
            flex-direction: column;
          }

          > div {
            // margin-right: 3rem;
            // padding-right: 3rem;
            // border-right: 1px solid black;

            // @media (max-width: 1360px) {
            //   margin-right: 2rem;
            //   padding-right: 2rem;
            // }

            @media (max-width: 900px) {
              width: auto;
              margin: 0;
              padding: 0;
              border: 0;
            }

            &:nth-child(1) {
              min-width: 150px;
              // @media (max-width: 1270px) {
              //   min-width: 60px !important;

              //   ul {
              //     min-width: 60px !important;
              //   }
              // }

              ul {
                min-width: 150px;
              }
            }

            &:nth-child(2) {
              @media (max-width: 840px) {
                width: 120px;
                width: auto;
                margin: 0;
                padding: 0;
                border: 0;
              }

              ul {
                max-width: unset;
                min-width: unset;
                min-width: 150px;

                li {
                  min-width: unset;
                }
              }
            }

            &:nth-child(4) {
              @media (min-width: 901px) {
                width: 160px;

                .links {
                  min-width: 160px;
                }
              }
              border: 0;
              padding-right: 0;
              margin-right: 0;
            }
          }
        }
      }

      // COPY
      &:nth-child(2) {
        margin: 3rem 0 3rem 0;
        display: flex;
        justify-content: space-between;

        @media (max-width: 1260px) {
          margin-top: 2rem;
        }

        > div {
          display: flex;
          align-items: center;

          @media (max-width: 800px) {
            margin-bottom: 1rem;
          }
        }

        ul {
          display: flex;
          margin: 0;
          padding: 0;

          li {
            margin-right: 2rem;

            &:last-child {
              margin: 0;
            }
          }
        }

        p {
          margin: 0;
          color: black;
        }
      }
    }
  }

  h4 {
    font-size: 1.4rem;
    color: black;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  // a {
  //   color: rgba(white, 0.6);
  //   transition: 0.3s ease-in-out;

  //   &:hover {
  //     color: black;
  //   }
  // }

  .products {
    width: 100%;
  }

  .MuiAccordion-root {
    background: transparent;
    box-shadow: none;

    .MuiButtonBase-root {
      height: 30px;
    }

    .MuiSvgIcon-root {
      color: black;
      display: none;
      @media (max-width: 900px) {
        display: block;
      }
    }

    #panel0d-content .MuiAccordionDetails-root {
      @media (min-width: 901px) {
        margin-right: 3rem;
        padding-right: 3rem;
        border-right: 2px solid #560d09;
      }
    }

    .MuiAccordionDetails-root {
      padding: 0;
      // padding-bottom: 1rem;
      display: flex;

      // &:nth-child(1) {
      //   border-right: 1px solid red;
      // }

      .action-button {
        position: absolute;
        background: #222;
        color: white;
        top: 0.7rem;
        left: 7.5rem;

        &:hover {
          background: #333;
        }
      }

      > div {
        width: 100%;
      }
    }

    &.Mui-expanded {
      margin: 0;
    }

    .MuiAccordionSummary-root {
      padding: 0;
      pointer-events: none;

      @media (max-width: 1152px) {
        pointer-events: all;
      }

      &.Mui-expanded {
        min-height: unset;
        min-height: 48px;

        .MuiButtonBase-root {
          display: none;

          @media (max-width: 1152px) {
            display: block;
          }
        }
      }
    }

    .MuiAccordionSummary-content {
      color: black;
      font-size: 1.3rem;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 12px 0 !important;

      &.Mui-expanded {
        margin: 12px 0 !important;
      }
    }

    &:not(:last-child) {
      border-bottom: 0;
    }

    &:before {
      display: none;
    }

    &.expanded {
      margin: auto;
    }
  }

  .nav-list {
    margin: 0;
    padding: 0;
    list-style: none;

    &.social {
      margin-top: 2rem;
      display: flex;

      @media (max-width: 1160px) {
        margin-bottom: 2rem;
      }

      li {
        height: 40px;
        display: flex;
        align-items: center;
        margin-right: 1.5rem;

        &:last-child {
          margin: 0;
        }

        a {
          height: 44px;
          width: 44px;
          background: black;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          svg {
            height: 18px;
            fill: white;
            line-height: 1;
            transition: 0.3s ease-in-out;
          }

          &:hover {
            background: #008484;
          }
        }
      }
    }
  }
}
