.main-header,
.shopping-header {
  position: relative;
  background: linear-gradient(0deg, #ffffff, #f6f6f6);
  z-index: 500;
  color: white;
  margin-top: 94px;
  @media (max-width: 1401px) {
    margin-top: 60px;
  }
  @media (max-width: 991px) {
    margin-top: 105px;
  }
  .search-title {
    margin-top: 50px;
    @media (max-width: 1401px) {
      margin-top: 40px;
    }
    @media (max-width: 991px) {
      margin-top: 50px;
    }
    @media (max-width: 800px) {
      margin-top: 30px;
    }
  }
  .shopping-title {
    margin-bottom: 10px;
    color: rgb(80, 80, 80);
    h1 {
      color: #505050;
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.2;
      @media (max-width: 800px) {
        font-size: 1.5rem;
      }
    }
    p {
      line-height: 1.5;
      margin-top: 15px;
      margin-bottom: 10px;
      font-size: 1.1rem;
    }
  }
  .container {
    padding-top: 3rem;
    // padding-bottom: 1.5rem;

    // @media (max-width: 991px) {
    //   padding-top: calc(3.7rem + 38px);
    // }
  }

  h1 {
    color: black;
    font-size: 2rem;
    padding: 0;
    margin: 0;

    @media (max-width: 600px) {
      font-size: 1.6rem;
    }
  }
}
