.analytics {
    background: #f6f6f6;

    header {
        padding: 44px 2rem 0rem 2rem;
        min-height: calc(55vh - 44px);
        // background: linear-gradient(147deg, #c62128, #a00000, #c62128, #a00000);
        background-size: 400% 400%;
        -webkit-animation: Gradient 10s ease infinite;
        animation: Gradient 10s ease infinite;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        overflow: hidden;
        background: #8b0000;

        .graph {
            z-index: 2;

            svg {
                position: absolute;
                right: -50px;
                bottom: 90px;
                width: 980px;
                min-height: 600px;
                transform: scale(1.5);

                @media (max-width: 1400px) {
                    width: 930px;
                    bottom: 47px;
                }

                @media (max-width: 1100px) {
                    width: 740px;
                    transform: scale(1.2);
                }

                @media (max-width: 800px) {
                    bottom: -30px;
                }

                @media (max-width: 600px) {
                    bottom: -160px;
                }

                path {
                    stroke: rgba(white, 0.2);
                    stroke-width: 3px;
                }

                ellipse {
                    fill: white;
                    animation: fadeIn 0.3s ease-in-out 0s both;

                    &.circle-1 {
                        animation-delay: 0.4s;
                    }

                    &.circle-2 {
                        animation-delay: 0.6s;
                    }

                    &.circle-3 {
                        animation-delay: 0.8s;
                    }

                    &.circle-4 {
                        animation-delay: 1s;
                    }

                    &.circle-5 {
                        animation-delay: 1.2s;
                    }

                    &.circle-6 {
                        animation-delay: 1.4s;
                    }

                    &.circle-7 {
                        animation-delay: 1.6s;
                    }

                    &.circle-8 {
                        animation-delay: 1.8s;
                    }

                    &.circle-9 {
                        animation-delay: 2s;
                    }

                    &.circle-10 {
                        animation-delay: 2.2s;
                    }

                    &.circle-11 {
                        animation-delay: 2.4s;
                    }
                }

                #linje {
                    animation: graphAnimation 3000ms linear 1 normal forwards;
                    stroke-dashoffset: 1062.7;
                    stroke-dasharray: 1062.7;
                    fill: none;
                }

                @keyframes graphAnimation {
                    0% {
                        stroke-dashoffset: 1062.7;
                    }

                    100% {
                        stroke-dashoffset: 0;
                    }
                }
            }
        }

        .grid {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
        }

        h1 {
            color: white;
            margin: 0 0 2rem 0;
        }

        input {
            color: black;

            &::placeholder {
                color: black;
            }
        }

        >div {
            &:nth-child(3) {
                max-width: 670px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                z-index: 3;

                h2 {
                    color: rgba(white, 0.7);
                    line-height: 1.5;
                }

            }
        }

    }

    .analytics-numbers {
        display: flex;
        align-items: center;
        min-height: calc(30vh - 6rem);
        background: #f6f6f6;

        .value-box {
            max-width: 650px;
            border-radius: 1rem;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .value-box-title {
                flex-basis: 100%;
                margin-top: 0;
                margin-bottom: 1.5rem;
                text-align: center;

                @media (max-width: 600px) {
                    margin-bottom: 0.5rem;
                }
            }

            span {
                width: 1px;
                background: rgba(black, 0.1);
            }

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 1.6rem;
                font-weight: 500;

                @media (max-width: 800px) {
                    font-size: 1.3rem;
                }

                @media (max-width: 600px) {
                    font-size: 1.2rem;
                }

                b {
                    color: #e41513;
                    font-size: 3.2rem;

                    @media (max-width: 800px) {
                        font-size: 2.6rem;
                    }

                    @media (max-width: 600px) {
                        font-size: 2.4rem;
                    }
                }
            }
        }
    }

    .analytics-info {
        .container {
            max-width: 600px;

            p {
                color: black;
            }
        }
    }

}