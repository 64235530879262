a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}
#CybotCookiebotDialog {
  border: 18px solid #FFF !important;
}

// a#CybotCookiebotDialogPoweredbyCybot,
// div#CybotCookiebotDialogPoweredByText {
//   display: none !important;
// }

// #CybotCookiebotDialog {
//   width: 80% !important;
//   max-width: 600px !important;
//   font-family: futura-pt,sans-serif !important;
//   border-radius: 0 !important;
//   @media (max-width: 600px) {
//     width: 90% !important;
//   }

//   button{
//     border-radius: 3rem !important;
//     padding: 0.75rem 2rem !important;
//     font-family: futura-pt,sans-serif !important;
//     font-size: 1.1rem !important;
//     font-weight: 500 !important;
//   }

//   #CybotCookiebotDialogHeader {
//     display: none !important;
//   }

//   .CybotCookiebotDialogBodyLevelButtonDescription {
//     font-size: 13px !important;
//   }

//   #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
//     border-radius: 3rem !important;
//     border-width: 2px !important;
//     text-align: center !important;
//   }

//   #CybotCookiebotDialogTabContent .CybotCookiebotDialogBodyLevelButtonSlider {
//     background-color: #e1e1e1 !important;
//   }

//   #CybotCookiebotDialogBodyButtonDecline {
//     margin-right: 1rem !important;
//     border-color: rgba(black, 0.1) !important;

//     &:hover{
//       border-color: black;
//     }

//     &:hover{
//       border-color: black;
//     }

//     @media (max-width: 600px) {
//       width: auto !important;
//       margin-right: 0 !important;
//     }
//   }

//   #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
//     border-color: rgba(black, 0.1);
//   }

//   #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
//     margin-left: auto !important;
//   }
// }

// #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
//   border-bottom: 0px solid #d8d8d8 !important;
//   color: #000 !important;
// }

// #CybotCookiebotDialog .CybotCookiebotScrollContainer {
//   border-bottom: 0 !important;
// }

// #CybotCookiebotDialogBodyLevelButtonsSelectPane {
//   border-top: 1px solid #d8d8d8 !important;

//   .CybotCookiebotDialogBodyLevelButtonDescription{
//       font-weight: 500 !important;
//   }

// }

// #CybotCookiebotDialog.active+#CybotCookiebotDialogBodyUnderlay {
//   opacity: 0.5 !important;
// }

// #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.active {
//   color: #008484 !important;
//   border-bottom: 3px solid #008484 !important;
// }

// #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
//   color: #008484 !important;
// }

// #CybotCookiebotDialog .CybotCookiebotScrollContainer{
//   padding: 0 !important;
// }

// #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
// #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
// #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
//   background: #008484 !important;
//   border-color: #008484 !important;
//   color: white !important;

//   &:hover{
//     background: darken(#008484, 10%) !important;
//     border-color: darken(#008484, 10%) !important;
//   }
// }

// #CybotCookiebotDialogTabContent input:checked+.CybotCookiebotDialogBodyLevelButtonSlider {
//   background-color: #008484 !important;
// }

// #CybotCookiebotDialog #CybotCookiebotDialogFooter{
//   padding: 1.5em 1.5em 1.5em;
// }

// #CybotCookiebotDialog[dir="rtl"] #CybotCookiebotDialogTabContent input+.CybotCookiebotDialogBodyLevelButtonSlider:before,
// #CybotCookiebotDialogTabContent input:checked+.CybotCookiebotDialogBodyLevelButtonSlider:before {
//   background-color: #008484 !important;
// }

// #CybotCookiebotDialogBodyLevelButtonsSelectPane label:not([for="CybotCookiebotDialogBodyLevelButtonNecessary"]) .CybotCookiebotDialogBodyLevelButtonDescription:hover {
//   color: #008484 !important;
// }

// #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton:hover,
// #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab:hover,
// #CybotCookiebotDialogDetailBodyContentCookieContainerTypes .CybotCookiebotDialogDetailBodyContentCookieProvider:not(.CybotCookiebotDialogDetailBodyContentCookieInfoCount):hover {
//   color: #008484 !important;
// }

// .CybotCookiebotDialogBodyBottomWrapper {
//   @media (max-width: 600px) {
//     display: none !important;
//   }
// }

// #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
//   @media (max-width: 600px) {
//     flex-direction: column !important;
//   }

// }

// #CybotCookiebotDialogBodyContent {
//   @media (max-width: 600px) {
//     padding-bottom: 0 !important;
//   }
// }

// .CybotCookiebotFader {
//   display: none !important;
// }

// #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
// #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection{
//   border-color: rgba(black, 0.1) !important;
// }