@import "../global/fire-flies.scss";

.no-longer-active-page {
  display: flex;
  height: 100vh;
  place-content: center;
  place-items: center;
  background: black;
}

.no-longer-active-page__content {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 16px;
}

.no-longer-active-page__content__upper-section {
  font-size: 1.5rem;
  color: white;
  text-align: center;
}