// html, body {
//   height: 100%;
// }

// body {
//   background: black;
//   background-size: cover;
// }

.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;
}

.firefly::before,
.firefly::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: -10vw;
}

.firefly::before {
  background: black;
  opacity: 0.4;
  animation: drift ease alternate infinite;
}

.firefly::after {
  background: white;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw yellow;
  animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
  animation-name: move1;
}

.firefly:nth-child(1)::before {
  animation-duration: 14s;
}

.firefly:nth-child(1)::after {
  animation-duration: 14s, 10238ms;
  animation-delay: 0ms, 7893ms;
}

@keyframes move1 {
  0% {
    transform: translateX(-29vw) translateY(-46vh) scale(0.81);
  }

  0.4385964912% {
    transform: translateX(3vw) translateY(41vh) scale(0.67);
  }

  0.8771929825% {
    transform: translateX(-48vw) translateY(-39vh) scale(0.44);
  }

  1.3157894737% {
    transform: translateX(45vw) translateY(50vh) scale(0.45);
  }

  1.7543859649% {
    transform: translateX(6vw) translateY(-18vh) scale(0.97);
  }

  2.1929824561% {
    transform: translateX(33vw) translateY(43vh) scale(0.45);
  }

  2.6315789474% {
    transform: translateX(44vw) translateY(18vh) scale(0.84);
  }

  3.0701754386% {
    transform: translateX(29vw) translateY(6vh) scale(0.71);
  }

  3.5087719298% {
    transform: translateX(-20vw) translateY(-30vh) scale(0.57);
  }

  3.9473684211% {
    transform: translateX(-5vw) translateY(7vh) scale(0.91);
  }

  4.3859649123% {
    transform: translateX(20vw) translateY(-42vh) scale(0.68);
  }

  4.8245614035% {
    transform: translateX(49vw) translateY(15vh) scale(0.38);
  }

  5.2631578947% {
    transform: translateX(-9vw) translateY(11vh) scale(0.86);
  }

  5.701754386% {
    transform: translateX(49vw) translateY(-5vh) scale(0.63);
  }

  6.1403508772% {
    transform: translateX(45vw) translateY(-22vh) scale(0.7);
  }

  6.5789473684% {
    transform: translateX(-8vw) translateY(37vh) scale(0.47);
  }

  7.0175438596% {
    transform: translateX(-46vw) translateY(-3vh) scale(0.47);
  }

  7.4561403509% {
    transform: translateX(35vw) translateY(-20vh) scale(0.95);
  }

  7.8947368421% {
    transform: translateX(9vw) translateY(-26vh) scale(0.56);
  }

  8.3333333333% {
    transform: translateX(-2vw) translateY(-12vh) scale(0.32);
  }

  8.7719298246% {
    transform: translateX(-33vw) translateY(31vh) scale(0.83);
  }

  9.2105263158% {
    transform: translateX(26vw) translateY(34vh) scale(0.52);
  }

  9.649122807% {
    transform: translateX(-5vw) translateY(46vh) scale(0.88);
  }

  10.0877192982% {
    transform: translateX(-11vw) translateY(-19vh) scale(0.44);
  }

  10.5263157895% {
    transform: translateX(35vw) translateY(23vh) scale(0.94);
  }

  10.9649122807% {
    transform: translateX(-28vw) translateY(15vh) scale(0.38);
  }

  11.4035087719% {
    transform: translateX(-23vw) translateY(-33vh) scale(0.35);
  }

  11.8421052632% {
    transform: translateX(18vw) translateY(50vh) scale(0.7);
  }

  12.2807017544% {
    transform: translateX(33vw) translateY(-12vh) scale(0.83);
  }

  12.7192982456% {
    transform: translateX(41vw) translateY(14vh) scale(0.65);
  }

  13.1578947368% {
    transform: translateX(2vw) translateY(0vh) scale(0.46);
  }

  13.5964912281% {
    transform: translateX(36vw) translateY(20vh) scale(0.76);
  }

  14.0350877193% {
    transform: translateX(21vw) translateY(-30vh) scale(0.73);
  }

  14.4736842105% {
    transform: translateX(-1vw) translateY(-33vh) scale(0.48);
  }

  14.9122807018% {
    transform: translateX(-43vw) translateY(-2vh) scale(0.93);
  }

  15.350877193% {
    transform: translateX(-35vw) translateY(32vh) scale(0.76);
  }

  15.7894736842% {
    transform: translateX(-39vw) translateY(-27vh) scale(0.79);
  }

  16.2280701754% {
    transform: translateX(-41vw) translateY(-11vh) scale(0.53);
  }

  16.6666666667% {
    transform: translateX(-2vw) translateY(-42vh) scale(0.38);
  }

  17.1052631579% {
    transform: translateX(11vw) translateY(30vh) scale(0.38);
  }

  17.5438596491% {
    transform: translateX(-14vw) translateY(-22vh) scale(0.51);
  }

  17.9824561404% {
    transform: translateX(25vw) translateY(1vh) scale(0.38);
  }

  18.4210526316% {
    transform: translateX(12vw) translateY(-25vh) scale(0.28);
  }

  18.8596491228% {
    transform: translateX(-16vw) translateY(18vh) scale(0.3);
  }

  19.298245614% {
    transform: translateX(-31vw) translateY(-5vh) scale(0.33);
  }

  19.7368421053% {
    transform: translateX(34vw) translateY(-23vh) scale(0.44);
  }

  20.1754385965% {
    transform: translateX(36vw) translateY(-15vh) scale(0.45);
  }

  20.6140350877% {
    transform: translateX(-21vw) translateY(49vh) scale(0.66);
  }

  21.0526315789% {
    transform: translateX(33vw) translateY(-6vh) scale(0.9);
  }

  21.4912280702% {
    transform: translateX(8vw) translateY(-32vh) scale(0.65);
  }

  21.9298245614% {
    transform: translateX(-18vw) translateY(18vh) scale(0.66);
  }

  22.3684210526% {
    transform: translateX(7vw) translateY(-40vh) scale(0.62);
  }

  22.8070175439% {
    transform: translateX(35vw) translateY(38vh) scale(0.85);
  }

  23.2456140351% {
    transform: translateX(3vw) translateY(-38vh) scale(0.91);
  }

  23.6842105263% {
    transform: translateX(-18vw) translateY(43vh) scale(1);
  }

  24.1228070175% {
    transform: translateX(35vw) translateY(-31vh) scale(0.84);
  }

  24.5614035088% {
    transform: translateX(40vw) translateY(25vh) scale(0.5);
  }

  25% {
    transform: translateX(-13vw) translateY(-20vh) scale(0.82);
  }

  25.4385964912% {
    transform: translateX(-14vw) translateY(18vh) scale(0.82);
  }

  25.8771929825% {
    transform: translateX(21vw) translateY(12vh) scale(0.58);
  }

  26.3157894737% {
    transform: translateX(16vw) translateY(-29vh) scale(0.28);
  }

  26.7543859649% {
    transform: translateX(-1vw) translateY(-15vh) scale(0.3);
  }

  27.1929824561% {
    transform: translateX(-40vw) translateY(-31vh) scale(0.73);
  }

  27.6315789474% {
    transform: translateX(-11vw) translateY(-21vh) scale(0.34);
  }

  28.0701754386% {
    transform: translateX(-22vw) translateY(2vh) scale(0.4);
  }

  28.5087719298% {
    transform: translateX(-19vw) translateY(-5vh) scale(0.44);
  }

  28.9473684211% {
    transform: translateX(-34vw) translateY(7vh) scale(0.74);
  }

  29.3859649123% {
    transform: translateX(34vw) translateY(14vh) scale(0.55);
  }

  29.8245614035% {
    transform: translateX(26vw) translateY(50vh) scale(0.75);
  }

  30.2631578947% {
    transform: translateX(-35vw) translateY(26vh) scale(0.37);
  }

  30.701754386% {
    transform: translateX(49vw) translateY(-49vh) scale(0.38);
  }

  31.1403508772% {
    transform: translateX(6vw) translateY(30vh) scale(0.59);
  }

  31.5789473684% {
    transform: translateX(20vw) translateY(28vh) scale(0.53);
  }

  32.0175438596% {
    transform: translateX(-2vw) translateY(-28vh) scale(0.49);
  }

  32.4561403509% {
    transform: translateX(-42vw) translateY(2vh) scale(0.83);
  }

  32.8947368421% {
    transform: translateX(-15vw) translateY(41vh) scale(0.46);
  }

  33.3333333333% {
    transform: translateX(1vw) translateY(-3vh) scale(0.86);
  }

  33.7719298246% {
    transform: translateX(20vw) translateY(24vh) scale(0.92);
  }

  34.2105263158% {
    transform: translateX(-47vw) translateY(14vh) scale(0.28);
  }

  34.649122807% {
    transform: translateX(37vw) translateY(45vh) scale(0.75);
  }

  35.0877192982% {
    transform: translateX(-43vw) translateY(-16vh) scale(0.81);
  }

  35.5263157895% {
    transform: translateX(16vw) translateY(48vh) scale(0.94);
  }

  35.9649122807% {
    transform: translateX(-44vw) translateY(19vh) scale(0.35);
  }

  36.4035087719% {
    transform: translateX(19vw) translateY(-36vh) scale(0.89);
  }

  36.8421052632% {
    transform: translateX(32vw) translateY(-32vh) scale(0.44);
  }

  37.2807017544% {
    transform: translateX(41vw) translateY(33vh) scale(0.37);
  }

  37.7192982456% {
    transform: translateX(-1vw) translateY(35vh) scale(0.72);
  }

  38.1578947368% {
    transform: translateX(2vw) translateY(-44vh) scale(0.79);
  }

  38.5964912281% {
    transform: translateX(15vw) translateY(-25vh) scale(0.93);
  }

  39.0350877193% {
    transform: translateX(33vw) translateY(23vh) scale(0.82);
  }

  39.4736842105% {
    transform: translateX(26vw) translateY(19vh) scale(0.63);
  }

  39.9122807018% {
    transform: translateX(-29vw) translateY(-26vh) scale(0.3);
  }

  40.350877193% {
    transform: translateX(27vw) translateY(-43vh) scale(0.53);
  }

  40.7894736842% {
    transform: translateX(5vw) translateY(29vh) scale(0.98);
  }

  41.2280701754% {
    transform: translateX(-6vw) translateY(-48vh) scale(0.66);
  }

  41.6666666667% {
    transform: translateX(49vw) translateY(-18vh) scale(0.72);
  }

  42.1052631579% {
    transform: translateX(45vw) translateY(9vh) scale(0.58);
  }

  42.5438596491% {
    transform: translateX(12vw) translateY(39vh) scale(0.93);
  }

  42.9824561404% {
    transform: translateX(43vw) translateY(4vh) scale(1);
  }

  43.4210526316% {
    transform: translateX(10vw) translateY(49vh) scale(0.6);
  }

  43.8596491228% {
    transform: translateX(-14vw) translateY(3vh) scale(0.46);
  }

  44.298245614% {
    transform: translateX(-28vw) translateY(-32vh) scale(0.71);
  }

  44.7368421053% {
    transform: translateX(-34vw) translateY(-43vh) scale(0.81);
  }

  45.1754385965% {
    transform: translateX(49vw) translateY(-49vh) scale(0.5);
  }

  45.6140350877% {
    transform: translateX(13vw) translateY(39vh) scale(0.97);
  }

  46.0526315789% {
    transform: translateX(11vw) translateY(16vh) scale(0.43);
  }

  46.4912280702% {
    transform: translateX(-17vw) translateY(42vh) scale(0.58);
  }

  46.9298245614% {
    transform: translateX(-43vw) translateY(-18vh) scale(0.69);
  }

  47.3684210526% {
    transform: translateX(5vw) translateY(20vh) scale(0.7);
  }

  47.8070175439% {
    transform: translateX(1vw) translateY(49vh) scale(0.92);
  }

  48.2456140351% {
    transform: translateX(-33vw) translateY(-27vh) scale(0.76);
  }

  48.6842105263% {
    transform: translateX(-41vw) translateY(2vh) scale(0.5);
  }

  49.1228070175% {
    transform: translateX(-7vw) translateY(24vh) scale(0.6);
  }

  49.5614035088% {
    transform: translateX(-41vw) translateY(-46vh) scale(0.4);
  }

  50% {
    transform: translateX(16vw) translateY(-41vh) scale(0.58);
  }

  50.4385964912% {
    transform: translateX(-48vw) translateY(-25vh) scale(0.38);
  }

  50.8771929825% {
    transform: translateX(12vw) translateY(3vh) scale(0.9);
  }

  51.3157894737% {
    transform: translateX(45vw) translateY(22vh) scale(0.38);
  }

  51.7543859649% {
    transform: translateX(-45vw) translateY(8vh) scale(0.77);
  }

  52.1929824561% {
    transform: translateX(12vw) translateY(31vh) scale(0.85);
  }

  52.6315789474% {
    transform: translateX(44vw) translateY(26vh) scale(0.76);
  }

  53.0701754386% {
    transform: translateX(-1vw) translateY(11vh) scale(0.54);
  }

  53.5087719298% {
    transform: translateX(33vw) translateY(-22vh) scale(0.85);
  }

  53.9473684211% {
    transform: translateX(34vw) translateY(19vh) scale(0.48);
  }

  54.3859649123% {
    transform: translateX(20vw) translateY(19vh) scale(0.34);
  }

  54.8245614035% {
    transform: translateX(-6vw) translateY(-26vh) scale(0.34);
  }

  55.2631578947% {
    transform: translateX(29vw) translateY(23vh) scale(0.89);
  }

  55.701754386% {
    transform: translateX(-14vw) translateY(12vh) scale(0.64);
  }

  56.1403508772% {
    transform: translateX(-43vw) translateY(6vh) scale(0.9);
  }

  56.5789473684% {
    transform: translateX(41vw) translateY(9vh) scale(0.65);
  }

  57.0175438596% {
    transform: translateX(28vw) translateY(10vh) scale(0.43);
  }

  57.4561403509% {
    transform: translateX(-15vw) translateY(11vh) scale(0.81);
  }

  57.8947368421% {
    transform: translateX(49vw) translateY(39vh) scale(0.34);
  }

  58.3333333333% {
    transform: translateX(16vw) translateY(42vh) scale(0.76);
  }

  58.7719298246% {
    transform: translateX(-22vw) translateY(22vh) scale(0.52);
  }

  59.2105263158% {
    transform: translateX(49vw) translateY(45vh) scale(0.52);
  }

  59.649122807% {
    transform: translateX(-30vw) translateY(-6vh) scale(0.77);
  }

  60.0877192982% {
    transform: translateX(-24vw) translateY(-27vh) scale(0.61);
  }

  60.5263157895% {
    transform: translateX(8vw) translateY(44vh) scale(0.49);
  }

  60.9649122807% {
    transform: translateX(-4vw) translateY(-26vh) scale(0.78);
  }

  61.4035087719% {
    transform: translateX(29vw) translateY(33vh) scale(0.46);
  }

  61.8421052632% {
    transform: translateX(-38vw) translateY(22vh) scale(0.71);
  }

  62.2807017544% {
    transform: translateX(-6vw) translateY(1vh) scale(0.44);
  }

  62.7192982456% {
    transform: translateX(-16vw) translateY(-40vh) scale(0.93);
  }

  63.1578947368% {
    transform: translateX(-6vw) translateY(-2vh) scale(0.68);
  }

  63.5964912281% {
    transform: translateX(30vw) translateY(49vh) scale(0.71);
  }

  64.0350877193% {
    transform: translateX(3vw) translateY(-25vh) scale(0.97);
  }

  64.4736842105% {
    transform: translateX(-46vw) translateY(-33vh) scale(0.51);
  }

  64.9122807018% {
    transform: translateX(23vw) translateY(44vh) scale(0.55);
  }

  65.350877193% {
    transform: translateX(-11vw) translateY(-24vh) scale(0.58);
  }

  65.7894736842% {
    transform: translateX(-39vw) translateY(-41vh) scale(0.89);
  }

  66.2280701754% {
    transform: translateX(-17vw) translateY(46vh) scale(0.84);
  }

  66.6666666667% {
    transform: translateX(-36vw) translateY(-24vh) scale(0.84);
  }

  67.1052631579% {
    transform: translateX(15vw) translateY(26vh) scale(0.52);
  }

  67.5438596491% {
    transform: translateX(15vw) translateY(-26vh) scale(0.38);
  }

  67.9824561404% {
    transform: translateX(40vw) translateY(3vh) scale(0.66);
  }

  68.4210526316% {
    transform: translateX(24vw) translateY(37vh) scale(0.81);
  }

  68.8596491228% {
    transform: translateX(15vw) translateY(24vh) scale(0.38);
  }

  69.298245614% {
    transform: translateX(-11vw) translateY(6vh) scale(0.28);
  }

  69.7368421053% {
    transform: translateX(-38vw) translateY(-29vh) scale(0.49);
  }

  70.1754385965% {
    transform: translateX(15vw) translateY(-34vh) scale(0.39);
  }

  70.6140350877% {
    transform: translateX(31vw) translateY(-39vh) scale(0.57);
  }

  71.0526315789% {
    transform: translateX(-43vw) translateY(20vh) scale(0.91);
  }

  71.4912280702% {
    transform: translateX(-46vw) translateY(-19vh) scale(0.4);
  }

  71.9298245614% {
    transform: translateX(-46vw) translateY(-2vh) scale(0.9);
  }

  72.3684210526% {
    transform: translateX(3vw) translateY(36vh) scale(0.67);
  }

  72.8070175439% {
    transform: translateX(-43vw) translateY(-24vh) scale(0.36);
  }

  73.2456140351% {
    transform: translateX(26vw) translateY(35vh) scale(0.8);
  }

  73.6842105263% {
    transform: translateX(-44vw) translateY(1vh) scale(0.89);
  }

  74.1228070175% {
    transform: translateX(-30vw) translateY(-47vh) scale(0.39);
  }

  74.5614035088% {
    transform: translateX(25vw) translateY(-32vh) scale(0.73);
  }

  75% {
    transform: translateX(-28vw) translateY(-23vh) scale(0.88);
  }

  75.4385964912% {
    transform: translateX(-23vw) translateY(-33vh) scale(0.84);
  }

  75.8771929825% {
    transform: translateX(14vw) translateY(32vh) scale(1);
  }

  76.3157894737% {
    transform: translateX(-11vw) translateY(-40vh) scale(0.47);
  }

  76.7543859649% {
    transform: translateX(-29vw) translateY(-27vh) scale(0.97);
  }

  77.1929824561% {
    transform: translateX(9vw) translateY(-14vh) scale(0.73);
  }

  77.6315789474% {
    transform: translateX(-24vw) translateY(-8vh) scale(0.78);
  }

  78.0701754386% {
    transform: translateX(-26vw) translateY(-15vh) scale(0.85);
  }

  78.5087719298% {
    transform: translateX(-9vw) translateY(41vh) scale(0.7);
  }

  78.9473684211% {
    transform: translateX(-29vw) translateY(33vh) scale(0.31);
  }

  79.3859649123% {
    transform: translateX(49vw) translateY(13vh) scale(0.86);
  }

  79.8245614035% {
    transform: translateX(-49vw) translateY(-32vh) scale(0.84);
  }

  80.2631578947% {
    transform: translateX(44vw) translateY(-39vh) scale(0.85);
  }

  80.701754386% {
    transform: translateX(-5vw) translateY(-10vh) scale(0.28);
  }

  81.1403508772% {
    transform: translateX(4vw) translateY(49vh) scale(0.42);
  }

  81.5789473684% {
    transform: translateX(26vw) translateY(-45vh) scale(0.98);
  }

  82.0175438596% {
    transform: translateX(-14vw) translateY(-14vh) scale(0.9);
  }

  82.4561403509% {
    transform: translateX(44vw) translateY(46vh) scale(0.46);
  }

  82.8947368421% {
    transform: translateX(3vw) translateY(23vh) scale(0.35);
  }

  83.3333333333% {
    transform: translateX(32vw) translateY(22vh) scale(0.98);
  }

  83.7719298246% {
    transform: translateX(-11vw) translateY(-18vh) scale(0.84);
  }

  84.2105263158% {
    transform: translateX(23vw) translateY(36vh) scale(0.95);
  }

  84.649122807% {
    transform: translateX(20vw) translateY(2vh) scale(0.61);
  }

  85.0877192982% {
    transform: translateX(-17vw) translateY(16vh) scale(0.55);
  }

  85.5263157895% {
    transform: translateX(15vw) translateY(38vh) scale(0.99);
  }

  85.9649122807% {
    transform: translateX(-38vw) translateY(-5vh) scale(0.9);
  }

  86.4035087719% {
    transform: translateX(-22vw) translateY(38vh) scale(0.73);
  }

  86.8421052632% {
    transform: translateX(27vw) translateY(-26vh) scale(0.72);
  }

  87.2807017544% {
    transform: translateX(-43vw) translateY(-14vh) scale(0.38);
  }

  87.7192982456% {
    transform: translateX(20vw) translateY(-31vh) scale(0.44);
  }

  88.1578947368% {
    transform: translateX(24vw) translateY(-32vh) scale(0.92);
  }

  88.5964912281% {
    transform: translateX(-38vw) translateY(22vh) scale(0.93);
  }

  89.0350877193% {
    transform: translateX(26vw) translateY(-15vh) scale(0.64);
  }

  89.4736842105% {
    transform: translateX(-40vw) translateY(-46vh) scale(0.83);
  }

  89.9122807018% {
    transform: translateX(-1vw) translateY(-38vh) scale(0.34);
  }

  90.350877193% {
    transform: translateX(-1vw) translateY(11vh) scale(0.7);
  }

  90.7894736842% {
    transform: translateX(-15vw) translateY(25vh) scale(0.93);
  }

  91.2280701754% {
    transform: translateX(7vw) translateY(-43vh) scale(0.94);
  }

  91.6666666667% {
    transform: translateX(43vw) translateY(-28vh) scale(0.49);
  }

  92.1052631579% {
    transform: translateX(30vw) translateY(5vh) scale(0.95);
  }

  92.5438596491% {
    transform: translateX(8vw) translateY(0vh) scale(0.68);
  }

  92.9824561404% {
    transform: translateX(4vw) translateY(-49vh) scale(0.5);
  }

  93.4210526316% {
    transform: translateX(27vw) translateY(-28vh) scale(0.34);
  }

  93.8596491228% {
    transform: translateX(-37vw) translateY(-43vh) scale(0.64);
  }

  94.298245614% {
    transform: translateX(14vw) translateY(-28vh) scale(0.38);
  }

  94.7368421053% {
    transform: translateX(35vw) translateY(-31vh) scale(0.81);
  }

  95.1754385965% {
    transform: translateX(34vw) translateY(-18vh) scale(0.86);
  }

  95.6140350877% {
    transform: translateX(6vw) translateY(-47vh) scale(0.76);
  }

  96.0526315789% {
    transform: translateX(24vw) translateY(-38vh) scale(0.38);
  }

  96.4912280702% {
    transform: translateX(40vw) translateY(-32vh) scale(0.59);
  }

  96.9298245614% {
    transform: translateX(-19vw) translateY(-10vh) scale(0.44);
  }

  97.3684210526% {
    transform: translateX(2vw) translateY(33vh) scale(0.42);
  }

  97.8070175439% {
    transform: translateX(38vw) translateY(38vh) scale(0.77);
  }

  98.2456140351% {
    transform: translateX(23vw) translateY(-28vh) scale(0.5);
  }

  98.6842105263% {
    transform: translateX(4vw) translateY(-41vh) scale(0.6);
  }

  99.1228070175% {
    transform: translateX(3vw) translateY(-11vh) scale(0.74);
  }

  99.5614035088% {
    transform: translateX(19vw) translateY(30vh) scale(0.43);
  }

  100% {
    transform: translateX(-28vw) translateY(32vh) scale(0.58);
  }
}

.firefly:nth-child(2) {
  animation-name: move2;
}

.firefly:nth-child(2)::before {
  animation-duration: 15s;
}

.firefly:nth-child(2)::after {
  animation-duration: 15s, 10274ms;
  animation-delay: 0ms, 8435ms;
}

@keyframes move2 {
  0% {
    transform: translateX(-42vw) translateY(40vh) scale(0.97);
  }

  0.4048582996% {
    transform: translateX(-2vw) translateY(23vh) scale(0.67);
  }

  0.8097165992% {
    transform: translateX(-24vw) translateY(-8vh) scale(0.4);
  }

  1.2145748988% {
    transform: translateX(42vw) translateY(18vh) scale(0.3);
  }

  1.6194331984% {
    transform: translateX(-30vw) translateY(11vh) scale(0.96);
  }

  2.024291498% {
    transform: translateX(-18vw) translateY(2vh) scale(0.36);
  }

  2.4291497976% {
    transform: translateX(5vw) translateY(-7vh) scale(0.34);
  }

  2.8340080972% {
    transform: translateX(34vw) translateY(-1vh) scale(0.42);
  }

  3.2388663968% {
    transform: translateX(0vw) translateY(-30vh) scale(0.88);
  }

  3.6437246964% {
    transform: translateX(-5vw) translateY(-19vh) scale(0.53);
  }

  4.048582996% {
    transform: translateX(-30vw) translateY(-19vh) scale(0.39);
  }

  4.4534412955% {
    transform: translateX(-27vw) translateY(10vh) scale(0.53);
  }

  4.8582995951% {
    transform: translateX(-13vw) translateY(50vh) scale(0.77);
  }

  5.2631578947% {
    transform: translateX(46vw) translateY(25vh) scale(0.38);
  }

  5.6680161943% {
    transform: translateX(-6vw) translateY(46vh) scale(0.29);
  }

  6.0728744939% {
    transform: translateX(-7vw) translateY(-5vh) scale(0.73);
  }

  6.4777327935% {
    transform: translateX(-15vw) translateY(-25vh) scale(0.82);
  }

  6.8825910931% {
    transform: translateX(1vw) translateY(-14vh) scale(0.84);
  }

  7.2874493927% {
    transform: translateX(12vw) translateY(5vh) scale(0.59);
  }

  7.6923076923% {
    transform: translateX(-33vw) translateY(50vh) scale(0.92);
  }

  8.0971659919% {
    transform: translateX(2vw) translateY(50vh) scale(0.91);
  }

  8.5020242915% {
    transform: translateX(49vw) translateY(16vh) scale(0.26);
  }

  8.9068825911% {
    transform: translateX(-11vw) translateY(4vh) scale(0.27);
  }

  9.3117408907% {
    transform: translateX(7vw) translateY(-39vh) scale(0.34);
  }

  9.7165991903% {
    transform: translateX(45vw) translateY(21vh) scale(0.98);
  }

  10.1214574899% {
    transform: translateX(39vw) translateY(14vh) scale(0.6);
  }

  10.5263157895% {
    transform: translateX(-27vw) translateY(-27vh) scale(0.89);
  }

  10.9311740891% {
    transform: translateX(0vw) translateY(-39vh) scale(0.53);
  }

  11.3360323887% {
    transform: translateX(-19vw) translateY(-45vh) scale(0.78);
  }

  11.7408906883% {
    transform: translateX(-36vw) translateY(4vh) scale(0.91);
  }

  12.1457489879% {
    transform: translateX(-17vw) translateY(2vh) scale(0.84);
  }

  12.5506072874% {
    transform: translateX(-38vw) translateY(-46vh) scale(0.95);
  }

  12.955465587% {
    transform: translateX(-18vw) translateY(37vh) scale(0.27);
  }

  13.3603238866% {
    transform: translateX(-36vw) translateY(15vh) scale(0.41);
  }

  13.7651821862% {
    transform: translateX(-28vw) translateY(-49vh) scale(0.79);
  }

  14.1700404858% {
    transform: translateX(20vw) translateY(-43vh) scale(0.69);
  }

  14.5748987854% {
    transform: translateX(26vw) translateY(-36vh) scale(0.37);
  }

  14.979757085% {
    transform: translateX(-46vw) translateY(13vh) scale(0.53);
  }

  15.3846153846% {
    transform: translateX(15vw) translateY(-35vh) scale(0.79);
  }

  15.7894736842% {
    transform: translateX(28vw) translateY(26vh) scale(0.55);
  }

  16.1943319838% {
    transform: translateX(37vw) translateY(-32vh) scale(0.63);
  }

  16.5991902834% {
    transform: translateX(-28vw) translateY(-42vh) scale(0.26);
  }

  17.004048583% {
    transform: translateX(38vw) translateY(1vh) scale(0.93);
  }

  17.4089068826% {
    transform: translateX(-34vw) translateY(-39vh) scale(0.84);
  }

  17.8137651822% {
    transform: translateX(-49vw) translateY(-39vh) scale(0.9);
  }

  18.2186234818% {
    transform: translateX(21vw) translateY(33vh) scale(0.72);
  }

  18.6234817814% {
    transform: translateX(30vw) translateY(4vh) scale(0.55);
  }

  19.028340081% {
    transform: translateX(4vw) translateY(4vh) scale(0.38);
  }

  19.4331983806% {
    transform: translateX(6vw) translateY(43vh) scale(0.3);
  }

  19.8380566802% {
    transform: translateX(28vw) translateY(-25vh) scale(0.32);
  }

  20.2429149798% {
    transform: translateX(18vw) translateY(-7vh) scale(0.93);
  }

  20.6477732794% {
    transform: translateX(42vw) translateY(-49vh) scale(0.84);
  }

  21.0526315789% {
    transform: translateX(28vw) translateY(50vh) scale(0.35);
  }

  21.4574898785% {
    transform: translateX(23vw) translateY(-48vh) scale(0.68);
  }

  21.8623481781% {
    transform: translateX(-35vw) translateY(29vh) scale(0.65);
  }

  22.2672064777% {
    transform: translateX(-36vw) translateY(-19vh) scale(0.79);
  }

  22.6720647773% {
    transform: translateX(-17vw) translateY(0vh) scale(0.44);
  }

  23.0769230769% {
    transform: translateX(37vw) translateY(37vh) scale(0.99);
  }

  23.4817813765% {
    transform: translateX(22vw) translateY(45vh) scale(0.32);
  }

  23.8866396761% {
    transform: translateX(10vw) translateY(23vh) scale(0.66);
  }

  24.2914979757% {
    transform: translateX(22vw) translateY(13vh) scale(0.51);
  }

  24.6963562753% {
    transform: translateX(33vw) translateY(3vh) scale(0.78);
  }

  25.1012145749% {
    transform: translateX(-16vw) translateY(39vh) scale(0.45);
  }

  25.5060728745% {
    transform: translateX(-34vw) translateY(-10vh) scale(0.28);
  }

  25.9109311741% {
    transform: translateX(-4vw) translateY(36vh) scale(0.97);
  }

  26.3157894737% {
    transform: translateX(-37vw) translateY(50vh) scale(0.71);
  }

  26.7206477733% {
    transform: translateX(-10vw) translateY(49vh) scale(0.7);
  }

  27.1255060729% {
    transform: translateX(3vw) translateY(-27vh) scale(0.96);
  }

  27.5303643725% {
    transform: translateX(6vw) translateY(-36vh) scale(0.92);
  }

  27.9352226721% {
    transform: translateX(-22vw) translateY(25vh) scale(0.76);
  }

  28.3400809717% {
    transform: translateX(27vw) translateY(-35vh) scale(0.71);
  }

  28.7449392713% {
    transform: translateX(-17vw) translateY(39vh) scale(0.98);
  }

  29.1497975709% {
    transform: translateX(14vw) translateY(-12vh) scale(0.36);
  }

  29.5546558704% {
    transform: translateX(3vw) translateY(-43vh) scale(0.68);
  }

  29.95951417% {
    transform: translateX(50vw) translateY(7vh) scale(0.7);
  }

  30.3643724696% {
    transform: translateX(36vw) translateY(9vh) scale(0.27);
  }

  30.7692307692% {
    transform: translateX(32vw) translateY(15vh) scale(0.32);
  }

  31.1740890688% {
    transform: translateX(13vw) translateY(-3vh) scale(0.44);
  }

  31.5789473684% {
    transform: translateX(8vw) translateY(29vh) scale(0.94);
  }

  31.983805668% {
    transform: translateX(6vw) translateY(-2vh) scale(0.44);
  }

  32.3886639676% {
    transform: translateX(6vw) translateY(7vh) scale(0.27);
  }

  32.7935222672% {
    transform: translateX(-31vw) translateY(-46vh) scale(0.75);
  }

  33.1983805668% {
    transform: translateX(46vw) translateY(20vh) scale(0.97);
  }

  33.6032388664% {
    transform: translateX(-23vw) translateY(27vh) scale(0.49);
  }

  34.008097166% {
    transform: translateX(-38vw) translateY(-40vh) scale(0.59);
  }

  34.4129554656% {
    transform: translateX(-6vw) translateY(-45vh) scale(0.73);
  }

  34.8178137652% {
    transform: translateX(21vw) translateY(-48vh) scale(0.7);
  }

  35.2226720648% {
    transform: translateX(-32vw) translateY(42vh) scale(0.98);
  }

  35.6275303644% {
    transform: translateX(-37vw) translateY(-41vh) scale(0.32);
  }

  36.032388664% {
    transform: translateX(33vw) translateY(33vh) scale(0.64);
  }

  36.4372469636% {
    transform: translateX(23vw) translateY(29vh) scale(0.46);
  }

  36.8421052632% {
    transform: translateX(19vw) translateY(-14vh) scale(0.64);
  }

  37.2469635628% {
    transform: translateX(36vw) translateY(31vh) scale(0.58);
  }

  37.6518218623% {
    transform: translateX(-6vw) translateY(-19vh) scale(0.44);
  }

  38.0566801619% {
    transform: translateX(-49vw) translateY(34vh) scale(0.69);
  }

  38.4615384615% {
    transform: translateX(16vw) translateY(-26vh) scale(0.46);
  }

  38.8663967611% {
    transform: translateX(29vw) translateY(-7vh) scale(0.76);
  }

  39.2712550607% {
    transform: translateX(32vw) translateY(-36vh) scale(0.97);
  }

  39.6761133603% {
    transform: translateX(-15vw) translateY(-19vh) scale(0.34);
  }

  40.0809716599% {
    transform: translateX(-46vw) translateY(16vh) scale(0.8);
  }

  40.4858299595% {
    transform: translateX(-45vw) translateY(33vh) scale(0.42);
  }

  40.8906882591% {
    transform: translateX(-29vw) translateY(-20vh) scale(0.26);
  }

  41.2955465587% {
    transform: translateX(-33vw) translateY(-19vh) scale(0.72);
  }

  41.7004048583% {
    transform: translateX(-35vw) translateY(4vh) scale(0.65);
  }

  42.1052631579% {
    transform: translateX(23vw) translateY(8vh) scale(0.94);
  }

  42.5101214575% {
    transform: translateX(-45vw) translateY(-19vh) scale(0.57);
  }

  42.9149797571% {
    transform: translateX(-6vw) translateY(-7vh) scale(0.51);
  }

  43.3198380567% {
    transform: translateX(-37vw) translateY(-36vh) scale(0.67);
  }

  43.7246963563% {
    transform: translateX(19vw) translateY(-6vh) scale(0.87);
  }

  44.1295546559% {
    transform: translateX(29vw) translateY(7vh) scale(0.4);
  }

  44.5344129555% {
    transform: translateX(31vw) translateY(-41vh) scale(0.36);
  }

  44.9392712551% {
    transform: translateX(5vw) translateY(45vh) scale(0.4);
  }

  45.3441295547% {
    transform: translateX(10vw) translateY(-24vh) scale(0.32);
  }

  45.7489878543% {
    transform: translateX(23vw) translateY(-43vh) scale(0.33);
  }

  46.1538461538% {
    transform: translateX(-9vw) translateY(-7vh) scale(0.74);
  }

  46.5587044534% {
    transform: translateX(-31vw) translateY(-25vh) scale(0.35);
  }

  46.963562753% {
    transform: translateX(49vw) translateY(-40vh) scale(0.74);
  }

  47.3684210526% {
    transform: translateX(4vw) translateY(22vh) scale(0.63);
  }

  47.7732793522% {
    transform: translateX(-19vw) translateY(-43vh) scale(0.78);
  }

  48.1781376518% {
    transform: translateX(-24vw) translateY(-30vh) scale(0.51);
  }

  48.5829959514% {
    transform: translateX(-36vw) translateY(35vh) scale(0.67);
  }

  48.987854251% {
    transform: translateX(2vw) translateY(-7vh) scale(0.26);
  }

  49.3927125506% {
    transform: translateX(-23vw) translateY(11vh) scale(0.92);
  }

  49.7975708502% {
    transform: translateX(-48vw) translateY(50vh) scale(0.71);
  }

  50.2024291498% {
    transform: translateX(23vw) translateY(9vh) scale(0.51);
  }

  50.6072874494% {
    transform: translateX(9vw) translateY(-23vh) scale(0.35);
  }

  51.012145749% {
    transform: translateX(31vw) translateY(49vh) scale(0.74);
  }

  51.4170040486% {
    transform: translateX(-34vw) translateY(-13vh) scale(0.51);
  }

  51.8218623482% {
    transform: translateX(-41vw) translateY(-46vh) scale(0.53);
  }

  52.2267206478% {
    transform: translateX(11vw) translateY(19vh) scale(0.5);
  }

  52.6315789474% {
    transform: translateX(27vw) translateY(2vh) scale(0.6);
  }

  53.036437247% {
    transform: translateX(3vw) translateY(-17vh) scale(0.76);
  }

  53.4412955466% {
    transform: translateX(25vw) translateY(47vh) scale(0.52);
  }

  53.8461538462% {
    transform: translateX(41vw) translateY(-47vh) scale(0.88);
  }

  54.2510121457% {
    transform: translateX(34vw) translateY(-23vh) scale(0.55);
  }

  54.6558704453% {
    transform: translateX(48vw) translateY(46vh) scale(0.61);
  }

  55.0607287449% {
    transform: translateX(41vw) translateY(-30vh) scale(0.71);
  }

  55.4655870445% {
    transform: translateX(-38vw) translateY(1vh) scale(0.81);
  }

  55.8704453441% {
    transform: translateX(-37vw) translateY(-3vh) scale(0.74);
  }

  56.2753036437% {
    transform: translateX(-1vw) translateY(-7vh) scale(0.77);
  }

  56.6801619433% {
    transform: translateX(-41vw) translateY(-47vh) scale(0.94);
  }

  57.0850202429% {
    transform: translateX(48vw) translateY(-35vh) scale(0.9);
  }

  57.4898785425% {
    transform: translateX(-35vw) translateY(30vh) scale(0.62);
  }

  57.8947368421% {
    transform: translateX(-23vw) translateY(37vh) scale(0.55);
  }

  58.2995951417% {
    transform: translateX(-1vw) translateY(21vh) scale(0.6);
  }

  58.7044534413% {
    transform: translateX(-9vw) translateY(-35vh) scale(0.37);
  }

  59.1093117409% {
    transform: translateX(18vw) translateY(18vh) scale(0.64);
  }

  59.5141700405% {
    transform: translateX(0vw) translateY(-23vh) scale(0.3);
  }

  59.9190283401% {
    transform: translateX(-45vw) translateY(-47vh) scale(0.91);
  }

  60.3238866397% {
    transform: translateX(-5vw) translateY(31vh) scale(0.88);
  }

  60.7287449393% {
    transform: translateX(-38vw) translateY(-25vh) scale(0.76);
  }

  61.1336032389% {
    transform: translateX(22vw) translateY(34vh) scale(0.34);
  }

  61.5384615385% {
    transform: translateX(11vw) translateY(22vh) scale(0.83);
  }

  61.9433198381% {
    transform: translateX(43vw) translateY(25vh) scale(0.71);
  }

  62.3481781377% {
    transform: translateX(-38vw) translateY(-17vh) scale(0.6);
  }

  62.7530364372% {
    transform: translateX(-42vw) translateY(-25vh) scale(0.52);
  }

  63.1578947368% {
    transform: translateX(-12vw) translateY(14vh) scale(0.69);
  }

  63.5627530364% {
    transform: translateX(-11vw) translateY(48vh) scale(0.97);
  }

  63.967611336% {
    transform: translateX(27vw) translateY(-15vh) scale(0.97);
  }

  64.3724696356% {
    transform: translateX(29vw) translateY(-28vh) scale(0.61);
  }

  64.7773279352% {
    transform: translateX(6vw) translateY(2vh) scale(0.6);
  }

  65.1821862348% {
    transform: translateX(-46vw) translateY(-28vh) scale(0.87);
  }

  65.5870445344% {
    transform: translateX(-22vw) translateY(48vh) scale(0.64);
  }

  65.991902834% {
    transform: translateX(23vw) translateY(-8vh) scale(0.88);
  }

  66.3967611336% {
    transform: translateX(-22vw) translateY(-46vh) scale(0.42);
  }

  66.8016194332% {
    transform: translateX(16vw) translateY(4vh) scale(0.45);
  }

  67.2064777328% {
    transform: translateX(-8vw) translateY(-11vh) scale(0.61);
  }

  67.6113360324% {
    transform: translateX(-18vw) translateY(-11vh) scale(0.33);
  }

  68.016194332% {
    transform: translateX(12vw) translateY(12vh) scale(0.61);
  }

  68.4210526316% {
    transform: translateX(-28vw) translateY(19vh) scale(0.66);
  }

  68.8259109312% {
    transform: translateX(22vw) translateY(-14vh) scale(0.49);
  }

  69.2307692308% {
    transform: translateX(7vw) translateY(-47vh) scale(0.81);
  }

  69.6356275304% {
    transform: translateX(26vw) translateY(-46vh) scale(0.35);
  }

  70.04048583% {
    transform: translateX(-18vw) translateY(42vh) scale(0.72);
  }

  70.4453441296% {
    transform: translateX(-48vw) translateY(34vh) scale(0.35);
  }

  70.8502024291% {
    transform: translateX(37vw) translateY(-38vh) scale(0.69);
  }

  71.2550607287% {
    transform: translateX(16vw) translateY(5vh) scale(0.84);
  }

  71.6599190283% {
    transform: translateX(-27vw) translateY(30vh) scale(0.73);
  }

  72.0647773279% {
    transform: translateX(47vw) translateY(20vh) scale(0.48);
  }

  72.4696356275% {
    transform: translateX(49vw) translateY(-48vh) scale(0.52);
  }

  72.8744939271% {
    transform: translateX(35vw) translateY(35vh) scale(0.91);
  }

  73.2793522267% {
    transform: translateX(19vw) translateY(31vh) scale(0.48);
  }

  73.6842105263% {
    transform: translateX(-12vw) translateY(4vh) scale(0.38);
  }

  74.0890688259% {
    transform: translateX(-1vw) translateY(-39vh) scale(0.57);
  }

  74.4939271255% {
    transform: translateX(6vw) translateY(17vh) scale(0.53);
  }

  74.8987854251% {
    transform: translateX(38vw) translateY(13vh) scale(0.78);
  }

  75.3036437247% {
    transform: translateX(19vw) translateY(16vh) scale(0.48);
  }

  75.7085020243% {
    transform: translateX(-10vw) translateY(-34vh) scale(0.51);
  }

  76.1133603239% {
    transform: translateX(-45vw) translateY(-40vh) scale(0.43);
  }

  76.5182186235% {
    transform: translateX(44vw) translateY(15vh) scale(0.88);
  }

  76.9230769231% {
    transform: translateX(-34vw) translateY(43vh) scale(0.97);
  }

  77.3279352227% {
    transform: translateX(50vw) translateY(17vh) scale(0.27);
  }

  77.7327935223% {
    transform: translateX(-20vw) translateY(7vh) scale(0.98);
  }

  78.1376518219% {
    transform: translateX(-36vw) translateY(-10vh) scale(0.32);
  }

  78.5425101215% {
    transform: translateX(-42vw) translateY(47vh) scale(0.82);
  }

  78.9473684211% {
    transform: translateX(-6vw) translateY(-11vh) scale(0.48);
  }

  79.3522267206% {
    transform: translateX(19vw) translateY(25vh) scale(0.41);
  }

  79.7570850202% {
    transform: translateX(46vw) translateY(33vh) scale(0.68);
  }

  80.1619433198% {
    transform: translateX(47vw) translateY(44vh) scale(0.62);
  }

  80.5668016194% {
    transform: translateX(37vw) translateY(12vh) scale(0.83);
  }

  80.971659919% {
    transform: translateX(44vw) translateY(-36vh) scale(0.49);
  }

  81.3765182186% {
    transform: translateX(-24vw) translateY(5vh) scale(0.35);
  }

  81.7813765182% {
    transform: translateX(30vw) translateY(32vh) scale(0.62);
  }

  82.1862348178% {
    transform: translateX(-32vw) translateY(35vh) scale(0.92);
  }

  82.5910931174% {
    transform: translateX(10vw) translateY(-12vh) scale(0.36);
  }

  82.995951417% {
    transform: translateX(35vw) translateY(-33vh) scale(0.44);
  }

  83.4008097166% {
    transform: translateX(45vw) translateY(39vh) scale(0.56);
  }

  83.8056680162% {
    transform: translateX(-18vw) translateY(43vh) scale(0.75);
  }

  84.2105263158% {
    transform: translateX(31vw) translateY(25vh) scale(0.39);
  }

  84.6153846154% {
    transform: translateX(-6vw) translateY(32vh) scale(0.49);
  }

  85.020242915% {
    transform: translateX(33vw) translateY(-35vh) scale(0.94);
  }

  85.4251012146% {
    transform: translateX(-36vw) translateY(-39vh) scale(0.36);
  }

  85.8299595142% {
    transform: translateX(6vw) translateY(42vh) scale(0.48);
  }

  86.2348178138% {
    transform: translateX(4vw) translateY(38vh) scale(0.71);
  }

  86.6396761134% {
    transform: translateX(-2vw) translateY(-46vh) scale(0.69);
  }

  87.044534413% {
    transform: translateX(-16vw) translateY(-15vh) scale(0.7);
  }

  87.4493927126% {
    transform: translateX(33vw) translateY(-46vh) scale(0.35);
  }

  87.8542510121% {
    transform: translateX(-41vw) translateY(-17vh) scale(0.8);
  }

  88.2591093117% {
    transform: translateX(-23vw) translateY(-1vh) scale(0.38);
  }

  88.6639676113% {
    transform: translateX(25vw) translateY(-46vh) scale(0.35);
  }

  89.0688259109% {
    transform: translateX(-5vw) translateY(-27vh) scale(0.84);
  }

  89.4736842105% {
    transform: translateX(-19vw) translateY(30vh) scale(0.77);
  }

  89.8785425101% {
    transform: translateX(2vw) translateY(-7vh) scale(0.54);
  }

  90.2834008097% {
    transform: translateX(-22vw) translateY(-42vh) scale(0.7);
  }

  90.6882591093% {
    transform: translateX(-35vw) translateY(-21vh) scale(1);
  }

  91.0931174089% {
    transform: translateX(-27vw) translateY(-21vh) scale(0.35);
  }

  91.4979757085% {
    transform: translateX(37vw) translateY(50vh) scale(0.89);
  }

  91.9028340081% {
    transform: translateX(30vw) translateY(20vh) scale(0.59);
  }

  92.3076923077% {
    transform: translateX(-3vw) translateY(4vh) scale(0.53);
  }

  92.7125506073% {
    transform: translateX(-35vw) translateY(29vh) scale(0.43);
  }

  93.1174089069% {
    transform: translateX(15vw) translateY(-2vh) scale(0.33);
  }

  93.5222672065% {
    transform: translateX(29vw) translateY(-39vh) scale(0.5);
  }

  93.9271255061% {
    transform: translateX(-49vw) translateY(19vh) scale(0.96);
  }

  94.3319838057% {
    transform: translateX(10vw) translateY(38vh) scale(0.57);
  }

  94.7368421053% {
    transform: translateX(1vw) translateY(-25vh) scale(1);
  }

  95.1417004049% {
    transform: translateX(40vw) translateY(28vh) scale(0.92);
  }

  95.5465587045% {
    transform: translateX(-33vw) translateY(-31vh) scale(0.69);
  }

  95.951417004% {
    transform: translateX(19vw) translateY(38vh) scale(0.48);
  }

  96.3562753036% {
    transform: translateX(2vw) translateY(5vh) scale(0.57);
  }

  96.7611336032% {
    transform: translateX(-24vw) translateY(15vh) scale(0.27);
  }

  97.1659919028% {
    transform: translateX(-6vw) translateY(-4vh) scale(0.31);
  }

  97.5708502024% {
    transform: translateX(45vw) translateY(18vh) scale(0.31);
  }

  97.975708502% {
    transform: translateX(36vw) translateY(14vh) scale(0.86);
  }

  98.3805668016% {
    transform: translateX(-9vw) translateY(45vh) scale(0.67);
  }

  98.7854251012% {
    transform: translateX(2vw) translateY(-1vh) scale(0.73);
  }

  99.1902834008% {
    transform: translateX(-27vw) translateY(29vh) scale(0.29);
  }

  99.5951417004% {
    transform: translateX(1vw) translateY(47vh) scale(0.57);
  }

  100% {
    transform: translateX(26vw) translateY(-11vh) scale(0.64);
  }
}

.firefly:nth-child(3) {
  animation-name: move3;
}

.firefly:nth-child(3)::before {
  animation-duration: 14s;
}

.firefly:nth-child(3)::after {
  animation-duration: 14s, 5340ms;
  animation-delay: 0ms, 8183ms;
}

@keyframes move3 {
  0% {
    transform: translateX(-20vw) translateY(46vh) scale(0.77);
  }

  0.4484304933% {
    transform: translateX(4vw) translateY(48vh) scale(0.48);
  }

  0.8968609865% {
    transform: translateX(22vw) translateY(-14vh) scale(0.64);
  }

  1.3452914798% {
    transform: translateX(-14vw) translateY(-3vh) scale(1);
  }

  1.7937219731% {
    transform: translateX(25vw) translateY(33vh) scale(0.42);
  }

  2.2421524664% {
    transform: translateX(17vw) translateY(-22vh) scale(0.27);
  }

  2.6905829596% {
    transform: translateX(0vw) translateY(-29vh) scale(0.76);
  }

  3.1390134529% {
    transform: translateX(-34vw) translateY(35vh) scale(0.51);
  }

  3.5874439462% {
    transform: translateX(-47vw) translateY(-8vh) scale(0.5);
  }

  4.0358744395% {
    transform: translateX(-32vw) translateY(20vh) scale(0.42);
  }

  4.4843049327% {
    transform: translateX(19vw) translateY(-48vh) scale(0.87);
  }

  4.932735426% {
    transform: translateX(12vw) translateY(-32vh) scale(0.53);
  }

  5.3811659193% {
    transform: translateX(33vw) translateY(39vh) scale(0.7);
  }

  5.8295964126% {
    transform: translateX(47vw) translateY(11vh) scale(0.32);
  }

  6.2780269058% {
    transform: translateX(-11vw) translateY(-46vh) scale(0.91);
  }

  6.7264573991% {
    transform: translateX(28vw) translateY(27vh) scale(0.27);
  }

  7.1748878924% {
    transform: translateX(39vw) translateY(43vh) scale(0.86);
  }

  7.6233183857% {
    transform: translateX(26vw) translateY(32vh) scale(0.54);
  }

  8.0717488789% {
    transform: translateX(-27vw) translateY(49vh) scale(0.36);
  }

  8.5201793722% {
    transform: translateX(31vw) translateY(34vh) scale(0.92);
  }

  8.9686098655% {
    transform: translateX(-4vw) translateY(18vh) scale(0.85);
  }

  9.4170403587% {
    transform: translateX(-4vw) translateY(0vh) scale(0.45);
  }

  9.865470852% {
    transform: translateX(-37vw) translateY(-20vh) scale(0.39);
  }

  10.3139013453% {
    transform: translateX(37vw) translateY(-30vh) scale(0.72);
  }

  10.7623318386% {
    transform: translateX(-41vw) translateY(-47vh) scale(0.92);
  }

  11.2107623318% {
    transform: translateX(21vw) translateY(27vh) scale(0.53);
  }

  11.6591928251% {
    transform: translateX(-18vw) translateY(-19vh) scale(0.85);
  }

  12.1076233184% {
    transform: translateX(17vw) translateY(14vh) scale(1);
  }

  12.5560538117% {
    transform: translateX(37vw) translateY(22vh) scale(0.81);
  }

  13.0044843049% {
    transform: translateX(-25vw) translateY(-24vh) scale(0.39);
  }

  13.4529147982% {
    transform: translateX(25vw) translateY(-46vh) scale(0.66);
  }

  13.9013452915% {
    transform: translateX(-44vw) translateY(-8vh) scale(0.32);
  }

  14.3497757848% {
    transform: translateX(-32vw) translateY(-8vh) scale(0.45);
  }

  14.798206278% {
    transform: translateX(-42vw) translateY(-46vh) scale(0.78);
  }

  15.2466367713% {
    transform: translateX(-44vw) translateY(20vh) scale(0.29);
  }

  15.6950672646% {
    transform: translateX(-47vw) translateY(45vh) scale(0.38);
  }

  16.1434977578% {
    transform: translateX(32vw) translateY(4vh) scale(0.66);
  }

  16.5919282511% {
    transform: translateX(11vw) translateY(-21vh) scale(0.69);
  }

  17.0403587444% {
    transform: translateX(8vw) translateY(-15vh) scale(0.9);
  }

  17.4887892377% {
    transform: translateX(-13vw) translateY(30vh) scale(0.27);
  }

  17.9372197309% {
    transform: translateX(49vw) translateY(-34vh) scale(0.57);
  }

  18.3856502242% {
    transform: translateX(39vw) translateY(-40vh) scale(0.48);
  }

  18.8340807175% {
    transform: translateX(-23vw) translateY(-19vh) scale(0.77);
  }

  19.2825112108% {
    transform: translateX(-16vw) translateY(26vh) scale(0.87);
  }

  19.730941704% {
    transform: translateX(-43vw) translateY(11vh) scale(0.47);
  }

  20.1793721973% {
    transform: translateX(-20vw) translateY(-22vh) scale(0.83);
  }

  20.6278026906% {
    transform: translateX(-18vw) translateY(-32vh) scale(0.54);
  }

  21.0762331839% {
    transform: translateX(-7vw) translateY(-44vh) scale(0.81);
  }

  21.5246636771% {
    transform: translateX(-2vw) translateY(35vh) scale(0.44);
  }

  21.9730941704% {
    transform: translateX(12vw) translateY(-44vh) scale(0.76);
  }

  22.4215246637% {
    transform: translateX(-24vw) translateY(28vh) scale(1);
  }

  22.869955157% {
    transform: translateX(22vw) translateY(39vh) scale(0.8);
  }

  23.3183856502% {
    transform: translateX(-27vw) translateY(32vh) scale(0.38);
  }

  23.7668161435% {
    transform: translateX(0vw) translateY(-30vh) scale(0.87);
  }

  24.2152466368% {
    transform: translateX(-32vw) translateY(13vh) scale(0.7);
  }

  24.66367713% {
    transform: translateX(-5vw) translateY(-48vh) scale(0.65);
  }

  25.1121076233% {
    transform: translateX(7vw) translateY(-22vh) scale(0.77);
  }

  25.5605381166% {
    transform: translateX(-12vw) translateY(-21vh) scale(0.87);
  }

  26.0089686099% {
    transform: translateX(-2vw) translateY(28vh) scale(0.48);
  }

  26.4573991031% {
    transform: translateX(15vw) translateY(47vh) scale(0.26);
  }

  26.9058295964% {
    transform: translateX(-20vw) translateY(12vh) scale(0.74);
  }

  27.3542600897% {
    transform: translateX(4vw) translateY(-25vh) scale(0.43);
  }

  27.802690583% {
    transform: translateX(-45vw) translateY(13vh) scale(0.61);
  }

  28.2511210762% {
    transform: translateX(6vw) translateY(22vh) scale(0.67);
  }

  28.6995515695% {
    transform: translateX(3vw) translateY(37vh) scale(0.64);
  }

  29.1479820628% {
    transform: translateX(-4vw) translateY(31vh) scale(0.82);
  }

  29.5964125561% {
    transform: translateX(-24vw) translateY(25vh) scale(0.56);
  }

  30.0448430493% {
    transform: translateX(-17vw) translateY(-1vh) scale(0.87);
  }

  30.4932735426% {
    transform: translateX(-3vw) translateY(2vh) scale(0.99);
  }

  30.9417040359% {
    transform: translateX(48vw) translateY(16vh) scale(0.97);
  }

  31.3901345291% {
    transform: translateX(-34vw) translateY(6vh) scale(0.5);
  }

  31.8385650224% {
    transform: translateX(34vw) translateY(20vh) scale(0.99);
  }

  32.2869955157% {
    transform: translateX(-9vw) translateY(46vh) scale(0.47);
  }

  32.735426009% {
    transform: translateX(-46vw) translateY(-25vh) scale(0.49);
  }

  33.1838565022% {
    transform: translateX(-15vw) translateY(-5vh) scale(0.3);
  }

  33.6322869955% {
    transform: translateX(-16vw) translateY(-15vh) scale(0.59);
  }

  34.0807174888% {
    transform: translateX(20vw) translateY(-19vh) scale(0.38);
  }

  34.5291479821% {
    transform: translateX(29vw) translateY(7vh) scale(0.82);
  }

  34.9775784753% {
    transform: translateX(35vw) translateY(12vh) scale(0.66);
  }

  35.4260089686% {
    transform: translateX(3vw) translateY(-1vh) scale(0.48);
  }

  35.8744394619% {
    transform: translateX(-19vw) translateY(50vh) scale(0.92);
  }

  36.3228699552% {
    transform: translateX(-13vw) translateY(-46vh) scale(0.6);
  }

  36.7713004484% {
    transform: translateX(1vw) translateY(-17vh) scale(0.43);
  }

  37.2197309417% {
    transform: translateX(-14vw) translateY(-18vh) scale(0.72);
  }

  37.668161435% {
    transform: translateX(-9vw) translateY(46vh) scale(0.5);
  }

  38.1165919283% {
    transform: translateX(-6vw) translateY(-39vh) scale(0.82);
  }

  38.5650224215% {
    transform: translateX(-21vw) translateY(36vh) scale(0.35);
  }

  39.0134529148% {
    transform: translateX(-41vw) translateY(-35vh) scale(0.57);
  }

  39.4618834081% {
    transform: translateX(-20vw) translateY(-15vh) scale(0.87);
  }

  39.9103139013% {
    transform: translateX(14vw) translateY(30vh) scale(0.89);
  }

  40.3587443946% {
    transform: translateX(16vw) translateY(-42vh) scale(0.96);
  }

  40.8071748879% {
    transform: translateX(10vw) translateY(3vh) scale(0.8);
  }

  41.2556053812% {
    transform: translateX(8vw) translateY(8vh) scale(0.92);
  }

  41.7040358744% {
    transform: translateX(-35vw) translateY(-24vh) scale(0.54);
  }

  42.1524663677% {
    transform: translateX(-39vw) translateY(46vh) scale(0.68);
  }

  42.600896861% {
    transform: translateX(45vw) translateY(8vh) scale(0.79);
  }

  43.0493273543% {
    transform: translateX(-37vw) translateY(-49vh) scale(0.47);
  }

  43.4977578475% {
    transform: translateX(-42vw) translateY(-11vh) scale(0.56);
  }

  43.9461883408% {
    transform: translateX(14vw) translateY(-32vh) scale(0.59);
  }

  44.3946188341% {
    transform: translateX(40vw) translateY(37vh) scale(0.51);
  }

  44.8430493274% {
    transform: translateX(-33vw) translateY(14vh) scale(0.39);
  }

  45.2914798206% {
    transform: translateX(14vw) translateY(-33vh) scale(0.69);
  }

  45.7399103139% {
    transform: translateX(16vw) translateY(35vh) scale(0.27);
  }

  46.1883408072% {
    transform: translateX(-44vw) translateY(-4vh) scale(0.97);
  }

  46.6367713004% {
    transform: translateX(20vw) translateY(-3vh) scale(0.65);
  }

  47.0852017937% {
    transform: translateX(-42vw) translateY(-28vh) scale(1);
  }

  47.533632287% {
    transform: translateX(-41vw) translateY(-47vh) scale(0.96);
  }

  47.9820627803% {
    transform: translateX(-10vw) translateY(35vh) scale(0.88);
  }

  48.4304932735% {
    transform: translateX(34vw) translateY(37vh) scale(1);
  }

  48.8789237668% {
    transform: translateX(48vw) translateY(-13vh) scale(0.59);
  }

  49.3273542601% {
    transform: translateX(-45vw) translateY(-35vh) scale(0.73);
  }

  49.7757847534% {
    transform: translateX(50vw) translateY(5vh) scale(0.39);
  }

  50.2242152466% {
    transform: translateX(18vw) translateY(27vh) scale(0.52);
  }

  50.6726457399% {
    transform: translateX(-39vw) translateY(25vh) scale(0.49);
  }

  51.1210762332% {
    transform: translateX(-34vw) translateY(3vh) scale(0.69);
  }

  51.5695067265% {
    transform: translateX(-36vw) translateY(-20vh) scale(0.76);
  }

  52.0179372197% {
    transform: translateX(13vw) translateY(-24vh) scale(0.28);
  }

  52.466367713% {
    transform: translateX(-41vw) translateY(-39vh) scale(0.82);
  }

  52.9147982063% {
    transform: translateX(-44vw) translateY(-17vh) scale(0.77);
  }

  53.3632286996% {
    transform: translateX(50vw) translateY(21vh) scale(0.43);
  }

  53.8116591928% {
    transform: translateX(34vw) translateY(-38vh) scale(0.65);
  }

  54.2600896861% {
    transform: translateX(-20vw) translateY(0vh) scale(0.83);
  }

  54.7085201794% {
    transform: translateX(45vw) translateY(37vh) scale(0.4);
  }

  55.1569506726% {
    transform: translateX(10vw) translateY(-31vh) scale(0.63);
  }

  55.6053811659% {
    transform: translateX(48vw) translateY(32vh) scale(0.72);
  }

  56.0538116592% {
    transform: translateX(-11vw) translateY(-45vh) scale(0.38);
  }

  56.5022421525% {
    transform: translateX(42vw) translateY(-26vh) scale(0.58);
  }

  56.9506726457% {
    transform: translateX(-1vw) translateY(-8vh) scale(0.51);
  }

  57.399103139% {
    transform: translateX(27vw) translateY(0vh) scale(0.35);
  }

  57.8475336323% {
    transform: translateX(-37vw) translateY(30vh) scale(0.72);
  }

  58.2959641256% {
    transform: translateX(-45vw) translateY(2vh) scale(0.54);
  }

  58.7443946188% {
    transform: translateX(44vw) translateY(6vh) scale(0.96);
  }

  59.1928251121% {
    transform: translateX(46vw) translateY(-32vh) scale(0.88);
  }

  59.6412556054% {
    transform: translateX(-27vw) translateY(-37vh) scale(0.67);
  }

  60.0896860987% {
    transform: translateX(-21vw) translateY(8vh) scale(0.49);
  }

  60.5381165919% {
    transform: translateX(-41vw) translateY(15vh) scale(0.97);
  }

  60.9865470852% {
    transform: translateX(-48vw) translateY(45vh) scale(0.95);
  }

  61.4349775785% {
    transform: translateX(25vw) translateY(-46vh) scale(0.96);
  }

  61.8834080717% {
    transform: translateX(37vw) translateY(-24vh) scale(0.5);
  }

  62.331838565% {
    transform: translateX(-24vw) translateY(4vh) scale(0.37);
  }

  62.7802690583% {
    transform: translateX(1vw) translateY(39vh) scale(0.72);
  }

  63.2286995516% {
    transform: translateX(-33vw) translateY(47vh) scale(0.63);
  }

  63.6771300448% {
    transform: translateX(32vw) translateY(-39vh) scale(0.88);
  }

  64.1255605381% {
    transform: translateX(7vw) translateY(-48vh) scale(0.69);
  }

  64.5739910314% {
    transform: translateX(-11vw) translateY(-35vh) scale(0.94);
  }

  65.0224215247% {
    transform: translateX(49vw) translateY(4vh) scale(0.89);
  }

  65.4708520179% {
    transform: translateX(30vw) translateY(-22vh) scale(0.39);
  }

  65.9192825112% {
    transform: translateX(-20vw) translateY(8vh) scale(0.66);
  }

  66.3677130045% {
    transform: translateX(42vw) translateY(-26vh) scale(0.81);
  }

  66.8161434978% {
    transform: translateX(22vw) translateY(33vh) scale(0.39);
  }

  67.264573991% {
    transform: translateX(-49vw) translateY(23vh) scale(0.27);
  }

  67.7130044843% {
    transform: translateX(15vw) translateY(44vh) scale(0.35);
  }

  68.1614349776% {
    transform: translateX(-24vw) translateY(18vh) scale(0.74);
  }

  68.6098654709% {
    transform: translateX(15vw) translateY(19vh) scale(0.35);
  }

  69.0582959641% {
    transform: translateX(14vw) translateY(-37vh) scale(0.63);
  }

  69.5067264574% {
    transform: translateX(-18vw) translateY(23vh) scale(0.89);
  }

  69.9551569507% {
    transform: translateX(-20vw) translateY(-9vh) scale(0.92);
  }

  70.4035874439% {
    transform: translateX(-14vw) translateY(25vh) scale(0.55);
  }

  70.8520179372% {
    transform: translateX(-6vw) translateY(-32vh) scale(0.52);
  }

  71.3004484305% {
    transform: translateX(-39vw) translateY(32vh) scale(0.29);
  }

  71.7488789238% {
    transform: translateX(-49vw) translateY(41vh) scale(0.87);
  }

  72.197309417% {
    transform: translateX(49vw) translateY(41vh) scale(0.89);
  }

  72.6457399103% {
    transform: translateX(14vw) translateY(1vh) scale(0.59);
  }

  73.0941704036% {
    transform: translateX(2vw) translateY(10vh) scale(0.7);
  }

  73.5426008969% {
    transform: translateX(-22vw) translateY(-28vh) scale(0.59);
  }

  73.9910313901% {
    transform: translateX(24vw) translateY(-25vh) scale(0.31);
  }

  74.4394618834% {
    transform: translateX(1vw) translateY(31vh) scale(0.99);
  }

  74.8878923767% {
    transform: translateX(-9vw) translateY(36vh) scale(0.75);
  }

  75.33632287% {
    transform: translateX(-48vw) translateY(-7vh) scale(0.54);
  }

  75.7847533632% {
    transform: translateX(45vw) translateY(35vh) scale(0.79);
  }

  76.2331838565% {
    transform: translateX(-43vw) translateY(5vh) scale(0.62);
  }

  76.6816143498% {
    transform: translateX(2vw) translateY(-30vh) scale(0.84);
  }

  77.130044843% {
    transform: translateX(23vw) translateY(-15vh) scale(0.44);
  }

  77.5784753363% {
    transform: translateX(-5vw) translateY(14vh) scale(0.32);
  }

  78.0269058296% {
    transform: translateX(48vw) translateY(-14vh) scale(0.54);
  }

  78.4753363229% {
    transform: translateX(5vw) translateY(5vh) scale(0.83);
  }

  78.9237668161% {
    transform: translateX(28vw) translateY(21vh) scale(0.9);
  }

  79.3721973094% {
    transform: translateX(-6vw) translateY(3vh) scale(0.47);
  }

  79.8206278027% {
    transform: translateX(-41vw) translateY(17vh) scale(0.9);
  }

  80.269058296% {
    transform: translateX(-17vw) translateY(14vh) scale(0.77);
  }

  80.7174887892% {
    transform: translateX(1vw) translateY(19vh) scale(0.38);
  }

  81.1659192825% {
    transform: translateX(44vw) translateY(-48vh) scale(0.7);
  }

  81.6143497758% {
    transform: translateX(8vw) translateY(-29vh) scale(0.81);
  }

  82.0627802691% {
    transform: translateX(-46vw) translateY(-42vh) scale(0.26);
  }

  82.5112107623% {
    transform: translateX(-47vw) translateY(40vh) scale(0.75);
  }

  82.9596412556% {
    transform: translateX(-29vw) translateY(13vh) scale(0.97);
  }

  83.4080717489% {
    transform: translateX(3vw) translateY(7vh) scale(0.58);
  }

  83.8565022422% {
    transform: translateX(3vw) translateY(-37vh) scale(0.97);
  }

  84.3049327354% {
    transform: translateX(28vw) translateY(41vh) scale(0.78);
  }

  84.7533632287% {
    transform: translateX(-47vw) translateY(-15vh) scale(0.52);
  }

  85.201793722% {
    transform: translateX(-15vw) translateY(-10vh) scale(0.89);
  }

  85.6502242152% {
    transform: translateX(-35vw) translateY(-11vh) scale(0.52);
  }

  86.0986547085% {
    transform: translateX(-24vw) translateY(10vh) scale(0.99);
  }

  86.5470852018% {
    transform: translateX(-4vw) translateY(-40vh) scale(0.35);
  }

  86.9955156951% {
    transform: translateX(-7vw) translateY(35vh) scale(0.59);
  }

  87.4439461883% {
    transform: translateX(42vw) translateY(18vh) scale(0.55);
  }

  87.8923766816% {
    transform: translateX(-38vw) translateY(-24vh) scale(0.91);
  }

  88.3408071749% {
    transform: translateX(-3vw) translateY(23vh) scale(0.43);
  }

  88.7892376682% {
    transform: translateX(37vw) translateY(12vh) scale(0.81);
  }

  89.2376681614% {
    transform: translateX(24vw) translateY(4vh) scale(0.51);
  }

  89.6860986547% {
    transform: translateX(2vw) translateY(-18vh) scale(0.5);
  }

  90.134529148% {
    transform: translateX(49vw) translateY(36vh) scale(0.39);
  }

  90.5829596413% {
    transform: translateX(-32vw) translateY(17vh) scale(0.89);
  }

  91.0313901345% {
    transform: translateX(-17vw) translateY(-38vh) scale(0.64);
  }

  91.4798206278% {
    transform: translateX(38vw) translateY(47vh) scale(0.73);
  }

  91.9282511211% {
    transform: translateX(-23vw) translateY(35vh) scale(0.26);
  }

  92.3766816143% {
    transform: translateX(-22vw) translateY(-13vh) scale(0.99);
  }

  92.8251121076% {
    transform: translateX(-16vw) translateY(-6vh) scale(0.48);
  }

  93.2735426009% {
    transform: translateX(46vw) translateY(28vh) scale(0.86);
  }

  93.7219730942% {
    transform: translateX(-11vw) translateY(45vh) scale(0.79);
  }

  94.1704035874% {
    transform: translateX(21vw) translateY(40vh) scale(0.78);
  }

  94.6188340807% {
    transform: translateX(1vw) translateY(22vh) scale(0.67);
  }

  95.067264574% {
    transform: translateX(12vw) translateY(13vh) scale(0.79);
  }

  95.5156950673% {
    transform: translateX(-1vw) translateY(-1vh) scale(0.26);
  }

  95.9641255605% {
    transform: translateX(41vw) translateY(17vh) scale(0.9);
  }

  96.4125560538% {
    transform: translateX(-4vw) translateY(43vh) scale(0.77);
  }

  96.8609865471% {
    transform: translateX(25vw) translateY(49vh) scale(0.65);
  }

  97.3094170404% {
    transform: translateX(35vw) translateY(-14vh) scale(0.54);
  }

  97.7578475336% {
    transform: translateX(43vw) translateY(-31vh) scale(0.57);
  }

  98.2062780269% {
    transform: translateX(-48vw) translateY(-27vh) scale(0.29);
  }

  98.6547085202% {
    transform: translateX(46vw) translateY(23vh) scale(0.77);
  }

  99.1031390135% {
    transform: translateX(-23vw) translateY(41vh) scale(0.88);
  }

  99.5515695067% {
    transform: translateX(43vw) translateY(6vh) scale(0.41);
  }

  100% {
    transform: translateX(-28vw) translateY(1vh) scale(0.35);
  }
}

.firefly:nth-child(4) {
  animation-name: move4;
}

.firefly:nth-child(4)::before {
  animation-duration: 16s;
}

.firefly:nth-child(4)::after {
  animation-duration: 16s, 7751ms;
  animation-delay: 0ms, 5739ms;
}

@keyframes move4 {
  0% {
    transform: translateX(0vw) translateY(22vh) scale(0.9);
  }

  0.5813953488% {
    transform: translateX(29vw) translateY(-22vh) scale(0.29);
  }

  1.1627906977% {
    transform: translateX(17vw) translateY(17vh) scale(0.28);
  }

  1.7441860465% {
    transform: translateX(-46vw) translateY(49vh) scale(0.35);
  }

  2.3255813953% {
    transform: translateX(-46vw) translateY(24vh) scale(0.73);
  }

  2.9069767442% {
    transform: translateX(-27vw) translateY(-28vh) scale(0.41);
  }

  3.488372093% {
    transform: translateX(-19vw) translateY(7vh) scale(0.9);
  }

  4.0697674419% {
    transform: translateX(-13vw) translateY(3vh) scale(0.36);
  }

  4.6511627907% {
    transform: translateX(-19vw) translateY(-13vh) scale(0.67);
  }

  5.2325581395% {
    transform: translateX(-45vw) translateY(14vh) scale(0.64);
  }

  5.8139534884% {
    transform: translateX(-40vw) translateY(31vh) scale(0.46);
  }

  6.3953488372% {
    transform: translateX(36vw) translateY(-34vh) scale(0.99);
  }

  6.976744186% {
    transform: translateX(9vw) translateY(-22vh) scale(0.74);
  }

  7.5581395349% {
    transform: translateX(19vw) translateY(31vh) scale(0.95);
  }

  8.1395348837% {
    transform: translateX(-4vw) translateY(-32vh) scale(0.57);
  }

  8.7209302326% {
    transform: translateX(16vw) translateY(-21vh) scale(0.89);
  }

  9.3023255814% {
    transform: translateX(18vw) translateY(20vh) scale(0.62);
  }

  9.8837209302% {
    transform: translateX(-37vw) translateY(35vh) scale(0.32);
  }

  10.4651162791% {
    transform: translateX(6vw) translateY(-39vh) scale(0.89);
  }

  11.0465116279% {
    transform: translateX(15vw) translateY(-2vh) scale(0.65);
  }

  11.6279069767% {
    transform: translateX(46vw) translateY(-46vh) scale(0.47);
  }

  12.2093023256% {
    transform: translateX(27vw) translateY(-10vh) scale(0.64);
  }

  12.7906976744% {
    transform: translateX(34vw) translateY(33vh) scale(0.72);
  }

  13.3720930233% {
    transform: translateX(11vw) translateY(5vh) scale(0.59);
  }

  13.9534883721% {
    transform: translateX(41vw) translateY(37vh) scale(0.61);
  }

  14.5348837209% {
    transform: translateX(-28vw) translateY(25vh) scale(0.61);
  }

  15.1162790698% {
    transform: translateX(44vw) translateY(4vh) scale(0.79);
  }

  15.6976744186% {
    transform: translateX(-29vw) translateY(11vh) scale(0.8);
  }

  16.2790697674% {
    transform: translateX(-18vw) translateY(-23vh) scale(0.67);
  }

  16.8604651163% {
    transform: translateX(-22vw) translateY(-23vh) scale(0.74);
  }

  17.4418604651% {
    transform: translateX(15vw) translateY(-47vh) scale(0.3);
  }

  18.023255814% {
    transform: translateX(-9vw) translateY(24vh) scale(0.83);
  }

  18.6046511628% {
    transform: translateX(-43vw) translateY(-9vh) scale(0.64);
  }

  19.1860465116% {
    transform: translateX(-7vw) translateY(-3vh) scale(0.37);
  }

  19.7674418605% {
    transform: translateX(34vw) translateY(-27vh) scale(0.55);
  }

  20.3488372093% {
    transform: translateX(-15vw) translateY(43vh) scale(0.84);
  }

  20.9302325581% {
    transform: translateX(33vw) translateY(33vh) scale(0.96);
  }

  21.511627907% {
    transform: translateX(9vw) translateY(9vh) scale(0.92);
  }

  22.0930232558% {
    transform: translateX(-2vw) translateY(41vh) scale(0.41);
  }

  22.6744186047% {
    transform: translateX(-2vw) translateY(-45vh) scale(0.26);
  }

  23.2558139535% {
    transform: translateX(-11vw) translateY(20vh) scale(0.32);
  }

  23.8372093023% {
    transform: translateX(-45vw) translateY(35vh) scale(0.61);
  }

  24.4186046512% {
    transform: translateX(45vw) translateY(42vh) scale(0.29);
  }

  25% {
    transform: translateX(-8vw) translateY(41vh) scale(0.46);
  }

  25.5813953488% {
    transform: translateX(15vw) translateY(-36vh) scale(0.49);
  }

  26.1627906977% {
    transform: translateX(24vw) translateY(-3vh) scale(0.6);
  }

  26.7441860465% {
    transform: translateX(-20vw) translateY(48vh) scale(0.51);
  }

  27.3255813953% {
    transform: translateX(11vw) translateY(49vh) scale(0.58);
  }

  27.9069767442% {
    transform: translateX(23vw) translateY(46vh) scale(0.74);
  }

  28.488372093% {
    transform: translateX(22vw) translateY(31vh) scale(0.58);
  }

  29.0697674419% {
    transform: translateX(-26vw) translateY(32vh) scale(0.31);
  }

  29.6511627907% {
    transform: translateX(-32vw) translateY(-6vh) scale(0.6);
  }

  30.2325581395% {
    transform: translateX(5vw) translateY(-45vh) scale(0.77);
  }

  30.8139534884% {
    transform: translateX(29vw) translateY(38vh) scale(0.78);
  }

  31.3953488372% {
    transform: translateX(-39vw) translateY(39vh) scale(0.7);
  }

  31.976744186% {
    transform: translateX(37vw) translateY(-10vh) scale(0.93);
  }

  32.5581395349% {
    transform: translateX(-38vw) translateY(36vh) scale(0.34);
  }

  33.1395348837% {
    transform: translateX(12vw) translateY(-41vh) scale(0.94);
  }

  33.7209302326% {
    transform: translateX(-30vw) translateY(29vh) scale(0.71);
  }

  34.3023255814% {
    transform: translateX(12vw) translateY(34vh) scale(0.47);
  }

  34.8837209302% {
    transform: translateX(49vw) translateY(-31vh) scale(0.63);
  }

  35.4651162791% {
    transform: translateX(3vw) translateY(-12vh) scale(0.56);
  }

  36.0465116279% {
    transform: translateX(-33vw) translateY(11vh) scale(0.98);
  }

  36.6279069767% {
    transform: translateX(-11vw) translateY(2vh) scale(0.68);
  }

  37.2093023256% {
    transform: translateX(-47vw) translateY(26vh) scale(0.75);
  }

  37.7906976744% {
    transform: translateX(19vw) translateY(-14vh) scale(0.72);
  }

  38.3720930233% {
    transform: translateX(42vw) translateY(-29vh) scale(0.65);
  }

  38.9534883721% {
    transform: translateX(-29vw) translateY(35vh) scale(0.35);
  }

  39.5348837209% {
    transform: translateX(-3vw) translateY(-49vh) scale(0.45);
  }

  40.1162790698% {
    transform: translateX(-44vw) translateY(-25vh) scale(0.61);
  }

  40.6976744186% {
    transform: translateX(-23vw) translateY(-34vh) scale(0.92);
  }

  41.2790697674% {
    transform: translateX(-36vw) translateY(-1vh) scale(0.91);
  }

  41.8604651163% {
    transform: translateX(31vw) translateY(-6vh) scale(0.28);
  }

  42.4418604651% {
    transform: translateX(-24vw) translateY(-2vh) scale(0.92);
  }

  43.023255814% {
    transform: translateX(47vw) translateY(43vh) scale(0.88);
  }

  43.6046511628% {
    transform: translateX(42vw) translateY(47vh) scale(0.62);
  }

  44.1860465116% {
    transform: translateX(-28vw) translateY(-36vh) scale(0.7);
  }

  44.7674418605% {
    transform: translateX(-14vw) translateY(-24vh) scale(0.87);
  }

  45.3488372093% {
    transform: translateX(-47vw) translateY(-26vh) scale(0.85);
  }

  45.9302325581% {
    transform: translateX(37vw) translateY(41vh) scale(0.29);
  }

  46.511627907% {
    transform: translateX(-47vw) translateY(48vh) scale(0.82);
  }

  47.0930232558% {
    transform: translateX(45vw) translateY(-15vh) scale(0.67);
  }

  47.6744186047% {
    transform: translateX(-34vw) translateY(-49vh) scale(0.84);
  }

  48.2558139535% {
    transform: translateX(49vw) translateY(30vh) scale(0.88);
  }

  48.8372093023% {
    transform: translateX(47vw) translateY(-29vh) scale(0.3);
  }

  49.4186046512% {
    transform: translateX(-11vw) translateY(-32vh) scale(0.89);
  }

  50% {
    transform: translateX(-45vw) translateY(-37vh) scale(0.83);
  }

  50.5813953488% {
    transform: translateX(6vw) translateY(-48vh) scale(0.56);
  }

  51.1627906977% {
    transform: translateX(-3vw) translateY(-16vh) scale(0.47);
  }

  51.7441860465% {
    transform: translateX(43vw) translateY(-17vh) scale(0.35);
  }

  52.3255813953% {
    transform: translateX(-20vw) translateY(10vh) scale(0.44);
  }

  52.9069767442% {
    transform: translateX(-34vw) translateY(-48vh) scale(0.54);
  }

  53.488372093% {
    transform: translateX(16vw) translateY(23vh) scale(0.93);
  }

  54.0697674419% {
    transform: translateX(30vw) translateY(19vh) scale(0.4);
  }

  54.6511627907% {
    transform: translateX(23vw) translateY(-27vh) scale(0.7);
  }

  55.2325581395% {
    transform: translateX(-6vw) translateY(-4vh) scale(0.49);
  }

  55.8139534884% {
    transform: translateX(29vw) translateY(-33vh) scale(0.28);
  }

  56.3953488372% {
    transform: translateX(25vw) translateY(40vh) scale(0.95);
  }

  56.976744186% {
    transform: translateX(-35vw) translateY(-48vh) scale(0.91);
  }

  57.5581395349% {
    transform: translateX(-15vw) translateY(23vh) scale(0.63);
  }

  58.1395348837% {
    transform: translateX(-35vw) translateY(42vh) scale(0.76);
  }

  58.7209302326% {
    transform: translateX(-29vw) translateY(-6vh) scale(0.3);
  }

  59.3023255814% {
    transform: translateX(7vw) translateY(49vh) scale(0.34);
  }

  59.8837209302% {
    transform: translateX(-32vw) translateY(-21vh) scale(0.89);
  }

  60.4651162791% {
    transform: translateX(3vw) translateY(41vh) scale(0.8);
  }

  61.0465116279% {
    transform: translateX(-47vw) translateY(-43vh) scale(0.29);
  }

  61.6279069767% {
    transform: translateX(31vw) translateY(25vh) scale(0.79);
  }

  62.2093023256% {
    transform: translateX(-24vw) translateY(22vh) scale(0.45);
  }

  62.7906976744% {
    transform: translateX(33vw) translateY(38vh) scale(0.76);
  }

  63.3720930233% {
    transform: translateX(0vw) translateY(-12vh) scale(0.94);
  }

  63.9534883721% {
    transform: translateX(23vw) translateY(-38vh) scale(0.3);
  }

  64.5348837209% {
    transform: translateX(-34vw) translateY(-15vh) scale(0.88);
  }

  65.1162790698% {
    transform: translateX(-2vw) translateY(-19vh) scale(0.55);
  }

  65.6976744186% {
    transform: translateX(-41vw) translateY(-47vh) scale(0.75);
  }

  66.2790697674% {
    transform: translateX(-31vw) translateY(-42vh) scale(0.88);
  }

  66.8604651163% {
    transform: translateX(-7vw) translateY(-6vh) scale(0.42);
  }

  67.4418604651% {
    transform: translateX(32vw) translateY(-37vh) scale(0.86);
  }

  68.023255814% {
    transform: translateX(-11vw) translateY(-40vh) scale(0.9);
  }

  68.6046511628% {
    transform: translateX(-47vw) translateY(2vh) scale(0.33);
  }

  69.1860465116% {
    transform: translateX(-22vw) translateY(11vh) scale(0.27);
  }

  69.7674418605% {
    transform: translateX(-3vw) translateY(-8vh) scale(0.77);
  }

  70.3488372093% {
    transform: translateX(-11vw) translateY(-11vh) scale(0.54);
  }

  70.9302325581% {
    transform: translateX(22vw) translateY(-11vh) scale(0.26);
  }

  71.511627907% {
    transform: translateX(34vw) translateY(-45vh) scale(0.97);
  }

  72.0930232558% {
    transform: translateX(7vw) translateY(20vh) scale(0.31);
  }

  72.6744186047% {
    transform: translateX(-17vw) translateY(-34vh) scale(0.29);
  }

  73.2558139535% {
    transform: translateX(2vw) translateY(22vh) scale(0.44);
  }

  73.8372093023% {
    transform: translateX(5vw) translateY(-14vh) scale(0.27);
  }

  74.4186046512% {
    transform: translateX(-1vw) translateY(42vh) scale(0.3);
  }

  75% {
    transform: translateX(41vw) translateY(24vh) scale(0.52);
  }

  75.5813953488% {
    transform: translateX(9vw) translateY(37vh) scale(0.39);
  }

  76.1627906977% {
    transform: translateX(5vw) translateY(-31vh) scale(0.7);
  }

  76.7441860465% {
    transform: translateX(-26vw) translateY(-31vh) scale(0.66);
  }

  77.3255813953% {
    transform: translateX(13vw) translateY(-25vh) scale(0.77);
  }

  77.9069767442% {
    transform: translateX(8vw) translateY(-19vh) scale(0.7);
  }

  78.488372093% {
    transform: translateX(-18vw) translateY(-28vh) scale(0.9);
  }

  79.0697674419% {
    transform: translateX(20vw) translateY(14vh) scale(0.28);
  }

  79.6511627907% {
    transform: translateX(-29vw) translateY(-9vh) scale(0.92);
  }

  80.2325581395% {
    transform: translateX(-17vw) translateY(-35vh) scale(0.56);
  }

  80.8139534884% {
    transform: translateX(49vw) translateY(44vh) scale(0.65);
  }

  81.3953488372% {
    transform: translateX(-41vw) translateY(-49vh) scale(0.91);
  }

  81.976744186% {
    transform: translateX(9vw) translateY(12vh) scale(0.91);
  }

  82.5581395349% {
    transform: translateX(-9vw) translateY(-2vh) scale(0.87);
  }

  83.1395348837% {
    transform: translateX(1vw) translateY(17vh) scale(0.73);
  }

  83.7209302326% {
    transform: translateX(28vw) translateY(0vh) scale(0.91);
  }

  84.3023255814% {
    transform: translateX(46vw) translateY(43vh) scale(0.8);
  }

  84.8837209302% {
    transform: translateX(41vw) translateY(17vh) scale(0.61);
  }

  85.4651162791% {
    transform: translateX(29vw) translateY(24vh) scale(0.77);
  }

  86.0465116279% {
    transform: translateX(12vw) translateY(49vh) scale(0.52);
  }

  86.6279069767% {
    transform: translateX(29vw) translateY(-43vh) scale(0.39);
  }

  87.2093023256% {
    transform: translateX(40vw) translateY(15vh) scale(0.47);
  }

  87.7906976744% {
    transform: translateX(-40vw) translateY(-39vh) scale(0.81);
  }

  88.3720930233% {
    transform: translateX(-46vw) translateY(3vh) scale(0.54);
  }

  88.9534883721% {
    transform: translateX(1vw) translateY(-27vh) scale(0.42);
  }

  89.5348837209% {
    transform: translateX(30vw) translateY(29vh) scale(0.33);
  }

  90.1162790698% {
    transform: translateX(30vw) translateY(42vh) scale(0.69);
  }

  90.6976744186% {
    transform: translateX(34vw) translateY(8vh) scale(0.38);
  }

  91.2790697674% {
    transform: translateX(-29vw) translateY(-49vh) scale(0.46);
  }

  91.8604651163% {
    transform: translateX(-8vw) translateY(18vh) scale(1);
  }

  92.4418604651% {
    transform: translateX(2vw) translateY(-37vh) scale(0.64);
  }

  93.023255814% {
    transform: translateX(9vw) translateY(-12vh) scale(0.31);
  }

  93.6046511628% {
    transform: translateX(-4vw) translateY(29vh) scale(0.86);
  }

  94.1860465116% {
    transform: translateX(29vw) translateY(5vh) scale(0.75);
  }

  94.7674418605% {
    transform: translateX(25vw) translateY(-37vh) scale(0.31);
  }

  95.3488372093% {
    transform: translateX(-15vw) translateY(-18vh) scale(0.91);
  }

  95.9302325581% {
    transform: translateX(-29vw) translateY(-20vh) scale(0.81);
  }

  96.511627907% {
    transform: translateX(-48vw) translateY(36vh) scale(0.85);
  }

  97.0930232558% {
    transform: translateX(-21vw) translateY(19vh) scale(0.43);
  }

  97.6744186047% {
    transform: translateX(-47vw) translateY(-22vh) scale(0.59);
  }

  98.2558139535% {
    transform: translateX(4vw) translateY(49vh) scale(0.83);
  }

  98.8372093023% {
    transform: translateX(8vw) translateY(-23vh) scale(0.73);
  }

  99.4186046512% {
    transform: translateX(-24vw) translateY(33vh) scale(0.66);
  }

  100% {
    transform: translateX(-40vw) translateY(21vh) scale(0.69);
  }
}

.firefly:nth-child(5) {
  animation-name: move5;
}

.firefly:nth-child(5)::before {
  animation-duration: 17s;
}

.firefly:nth-child(5)::after {
  animation-duration: 17s, 10095ms;
  animation-delay: 0ms, 4656ms;
}

@keyframes move5 {
  0% {
    transform: translateX(31vw) translateY(6vh) scale(0.68);
  }

  0.3773584906% {
    transform: translateX(-37vw) translateY(9vh) scale(0.72);
  }

  0.7547169811% {
    transform: translateX(-30vw) translateY(37vh) scale(0.96);
  }

  1.1320754717% {
    transform: translateX(42vw) translateY(1vh) scale(0.32);
  }

  1.5094339623% {
    transform: translateX(-8vw) translateY(-26vh) scale(0.31);
  }

  1.8867924528% {
    transform: translateX(33vw) translateY(16vh) scale(0.43);
  }

  2.2641509434% {
    transform: translateX(3vw) translateY(-48vh) scale(0.26);
  }

  2.641509434% {
    transform: translateX(-44vw) translateY(20vh) scale(0.9);
  }

  3.0188679245% {
    transform: translateX(36vw) translateY(-25vh) scale(0.76);
  }

  3.3962264151% {
    transform: translateX(-17vw) translateY(11vh) scale(0.6);
  }

  3.7735849057% {
    transform: translateX(21vw) translateY(-40vh) scale(0.78);
  }

  4.1509433962% {
    transform: translateX(30vw) translateY(40vh) scale(0.77);
  }

  4.5283018868% {
    transform: translateX(37vw) translateY(26vh) scale(0.66);
  }

  4.9056603774% {
    transform: translateX(17vw) translateY(22vh) scale(0.48);
  }

  5.2830188679% {
    transform: translateX(-15vw) translateY(-23vh) scale(0.58);
  }

  5.6603773585% {
    transform: translateX(44vw) translateY(-28vh) scale(0.36);
  }

  6.0377358491% {
    transform: translateX(-30vw) translateY(-27vh) scale(0.87);
  }

  6.4150943396% {
    transform: translateX(-26vw) translateY(7vh) scale(0.88);
  }

  6.7924528302% {
    transform: translateX(-38vw) translateY(-13vh) scale(0.32);
  }

  7.1698113208% {
    transform: translateX(41vw) translateY(27vh) scale(0.8);
  }

  7.5471698113% {
    transform: translateX(7vw) translateY(4vh) scale(0.75);
  }

  7.9245283019% {
    transform: translateX(19vw) translateY(32vh) scale(0.59);
  }

  8.3018867925% {
    transform: translateX(6vw) translateY(-7vh) scale(0.43);
  }

  8.679245283% {
    transform: translateX(39vw) translateY(24vh) scale(1);
  }

  9.0566037736% {
    transform: translateX(-4vw) translateY(-31vh) scale(0.51);
  }

  9.4339622642% {
    transform: translateX(-22vw) translateY(-21vh) scale(0.48);
  }

  9.8113207547% {
    transform: translateX(-49vw) translateY(34vh) scale(0.47);
  }

  10.1886792453% {
    transform: translateX(-15vw) translateY(-28vh) scale(0.41);
  }

  10.5660377358% {
    transform: translateX(45vw) translateY(-46vh) scale(0.75);
  }

  10.9433962264% {
    transform: translateX(44vw) translateY(-6vh) scale(0.32);
  }

  11.320754717% {
    transform: translateX(-22vw) translateY(49vh) scale(0.9);
  }

  11.6981132075% {
    transform: translateX(38vw) translateY(1vh) scale(0.94);
  }

  12.0754716981% {
    transform: translateX(-8vw) translateY(34vh) scale(0.68);
  }

  12.4528301887% {
    transform: translateX(-3vw) translateY(31vh) scale(1);
  }

  12.8301886792% {
    transform: translateX(-37vw) translateY(-7vh) scale(0.91);
  }

  13.2075471698% {
    transform: translateX(-48vw) translateY(50vh) scale(0.31);
  }

  13.5849056604% {
    transform: translateX(47vw) translateY(-7vh) scale(0.77);
  }

  13.9622641509% {
    transform: translateX(22vw) translateY(-49vh) scale(0.52);
  }

  14.3396226415% {
    transform: translateX(-40vw) translateY(-21vh) scale(0.86);
  }

  14.7169811321% {
    transform: translateX(8vw) translateY(16vh) scale(0.84);
  }

  15.0943396226% {
    transform: translateX(-31vw) translateY(5vh) scale(0.43);
  }

  15.4716981132% {
    transform: translateX(29vw) translateY(17vh) scale(0.58);
  }

  15.8490566038% {
    transform: translateX(-45vw) translateY(-12vh) scale(0.86);
  }

  16.2264150943% {
    transform: translateX(45vw) translateY(1vh) scale(0.95);
  }

  16.6037735849% {
    transform: translateX(35vw) translateY(46vh) scale(0.72);
  }

  16.9811320755% {
    transform: translateX(49vw) translateY(32vh) scale(0.67);
  }

  17.358490566% {
    transform: translateX(41vw) translateY(45vh) scale(0.98);
  }

  17.7358490566% {
    transform: translateX(38vw) translateY(-45vh) scale(0.67);
  }

  18.1132075472% {
    transform: translateX(-9vw) translateY(15vh) scale(0.68);
  }

  18.4905660377% {
    transform: translateX(-48vw) translateY(50vh) scale(0.69);
  }

  18.8679245283% {
    transform: translateX(-40vw) translateY(45vh) scale(0.51);
  }

  19.2452830189% {
    transform: translateX(37vw) translateY(14vh) scale(0.26);
  }

  19.6226415094% {
    transform: translateX(-36vw) translateY(11vh) scale(0.98);
  }

  20% {
    transform: translateX(-31vw) translateY(3vh) scale(0.96);
  }

  20.3773584906% {
    transform: translateX(14vw) translateY(19vh) scale(0.6);
  }

  20.7547169811% {
    transform: translateX(39vw) translateY(-37vh) scale(0.75);
  }

  21.1320754717% {
    transform: translateX(4vw) translateY(22vh) scale(0.84);
  }

  21.5094339623% {
    transform: translateX(20vw) translateY(-25vh) scale(0.35);
  }

  21.8867924528% {
    transform: translateX(2vw) translateY(37vh) scale(0.81);
  }

  22.2641509434% {
    transform: translateX(-22vw) translateY(24vh) scale(0.56);
  }

  22.641509434% {
    transform: translateX(40vw) translateY(47vh) scale(0.49);
  }

  23.0188679245% {
    transform: translateX(6vw) translateY(20vh) scale(0.4);
  }

  23.3962264151% {
    transform: translateX(-2vw) translateY(16vh) scale(0.37);
  }

  23.7735849057% {
    transform: translateX(-33vw) translateY(9vh) scale(0.44);
  }

  24.1509433962% {
    transform: translateX(-27vw) translateY(47vh) scale(0.32);
  }

  24.5283018868% {
    transform: translateX(17vw) translateY(-27vh) scale(0.71);
  }

  24.9056603774% {
    transform: translateX(13vw) translateY(-4vh) scale(0.95);
  }

  25.2830188679% {
    transform: translateX(-40vw) translateY(48vh) scale(0.86);
  }

  25.6603773585% {
    transform: translateX(35vw) translateY(17vh) scale(0.28);
  }

  26.0377358491% {
    transform: translateX(-20vw) translateY(47vh) scale(0.9);
  }

  26.4150943396% {
    transform: translateX(-10vw) translateY(-21vh) scale(0.44);
  }

  26.7924528302% {
    transform: translateX(29vw) translateY(27vh) scale(0.37);
  }

  27.1698113208% {
    transform: translateX(10vw) translateY(-16vh) scale(0.29);
  }

  27.5471698113% {
    transform: translateX(46vw) translateY(49vh) scale(0.85);
  }

  27.9245283019% {
    transform: translateX(0vw) translateY(-16vh) scale(0.52);
  }

  28.3018867925% {
    transform: translateX(3vw) translateY(-42vh) scale(0.57);
  }

  28.679245283% {
    transform: translateX(-16vw) translateY(-28vh) scale(0.47);
  }

  29.0566037736% {
    transform: translateX(1vw) translateY(-4vh) scale(0.96);
  }

  29.4339622642% {
    transform: translateX(-19vw) translateY(-46vh) scale(0.97);
  }

  29.8113207547% {
    transform: translateX(-36vw) translateY(7vh) scale(0.57);
  }

  30.1886792453% {
    transform: translateX(-1vw) translateY(-30vh) scale(0.51);
  }

  30.5660377358% {
    transform: translateX(-32vw) translateY(-22vh) scale(0.37);
  }

  30.9433962264% {
    transform: translateX(20vw) translateY(-29vh) scale(0.4);
  }

  31.320754717% {
    transform: translateX(24vw) translateY(24vh) scale(0.72);
  }

  31.6981132075% {
    transform: translateX(13vw) translateY(6vh) scale(0.94);
  }

  32.0754716981% {
    transform: translateX(36vw) translateY(-10vh) scale(0.85);
  }

  32.4528301887% {
    transform: translateX(-16vw) translateY(43vh) scale(1);
  }

  32.8301886792% {
    transform: translateX(20vw) translateY(-32vh) scale(0.48);
  }

  33.2075471698% {
    transform: translateX(-24vw) translateY(10vh) scale(0.48);
  }

  33.5849056604% {
    transform: translateX(25vw) translateY(-13vh) scale(0.41);
  }

  33.9622641509% {
    transform: translateX(-11vw) translateY(-31vh) scale(0.63);
  }

  34.3396226415% {
    transform: translateX(26vw) translateY(-26vh) scale(0.26);
  }

  34.7169811321% {
    transform: translateX(-47vw) translateY(-37vh) scale(0.3);
  }

  35.0943396226% {
    transform: translateX(-14vw) translateY(-3vh) scale(0.34);
  }

  35.4716981132% {
    transform: translateX(-10vw) translateY(4vh) scale(0.64);
  }

  35.8490566038% {
    transform: translateX(16vw) translateY(-42vh) scale(0.31);
  }

  36.2264150943% {
    transform: translateX(-37vw) translateY(-41vh) scale(0.49);
  }

  36.6037735849% {
    transform: translateX(42vw) translateY(-21vh) scale(0.86);
  }

  36.9811320755% {
    transform: translateX(39vw) translateY(-37vh) scale(0.79);
  }

  37.358490566% {
    transform: translateX(-10vw) translateY(-21vh) scale(0.88);
  }

  37.7358490566% {
    transform: translateX(-33vw) translateY(23vh) scale(0.81);
  }

  38.1132075472% {
    transform: translateX(-34vw) translateY(22vh) scale(0.91);
  }

  38.4905660377% {
    transform: translateX(7vw) translateY(45vh) scale(0.55);
  }

  38.8679245283% {
    transform: translateX(42vw) translateY(-7vh) scale(0.3);
  }

  39.2452830189% {
    transform: translateX(-4vw) translateY(-8vh) scale(0.92);
  }

  39.6226415094% {
    transform: translateX(-18vw) translateY(34vh) scale(0.74);
  }

  40% {
    transform: translateX(-28vw) translateY(18vh) scale(0.26);
  }

  40.3773584906% {
    transform: translateX(-48vw) translateY(4vh) scale(0.4);
  }

  40.7547169811% {
    transform: translateX(-20vw) translateY(-35vh) scale(0.65);
  }

  41.1320754717% {
    transform: translateX(-46vw) translateY(-18vh) scale(0.67);
  }

  41.5094339623% {
    transform: translateX(-31vw) translateY(-47vh) scale(0.32);
  }

  41.8867924528% {
    transform: translateX(43vw) translateY(47vh) scale(0.64);
  }

  42.2641509434% {
    transform: translateX(25vw) translateY(40vh) scale(0.46);
  }

  42.641509434% {
    transform: translateX(-4vw) translateY(18vh) scale(0.87);
  }

  43.0188679245% {
    transform: translateX(42vw) translateY(46vh) scale(0.39);
  }

  43.3962264151% {
    transform: translateX(10vw) translateY(45vh) scale(0.46);
  }

  43.7735849057% {
    transform: translateX(-15vw) translateY(16vh) scale(0.98);
  }

  44.1509433962% {
    transform: translateX(24vw) translateY(-28vh) scale(0.29);
  }

  44.5283018868% {
    transform: translateX(18vw) translateY(-21vh) scale(0.78);
  }

  44.9056603774% {
    transform: translateX(-8vw) translateY(-28vh) scale(0.94);
  }

  45.2830188679% {
    transform: translateX(20vw) translateY(-39vh) scale(0.89);
  }

  45.6603773585% {
    transform: translateX(9vw) translateY(8vh) scale(0.87);
  }

  46.0377358491% {
    transform: translateX(-7vw) translateY(19vh) scale(0.96);
  }

  46.4150943396% {
    transform: translateX(-8vw) translateY(-26vh) scale(0.88);
  }

  46.7924528302% {
    transform: translateX(-31vw) translateY(7vh) scale(0.43);
  }

  47.1698113208% {
    transform: translateX(-21vw) translateY(-45vh) scale(0.32);
  }

  47.5471698113% {
    transform: translateX(-33vw) translateY(45vh) scale(0.97);
  }

  47.9245283019% {
    transform: translateX(-46vw) translateY(-47vh) scale(0.33);
  }

  48.3018867925% {
    transform: translateX(-43vw) translateY(31vh) scale(0.49);
  }

  48.679245283% {
    transform: translateX(-22vw) translateY(-7vh) scale(0.62);
  }

  49.0566037736% {
    transform: translateX(-14vw) translateY(3vh) scale(0.82);
  }

  49.4339622642% {
    transform: translateX(41vw) translateY(-30vh) scale(0.96);
  }

  49.8113207547% {
    transform: translateX(-17vw) translateY(20vh) scale(0.28);
  }

  50.1886792453% {
    transform: translateX(-38vw) translateY(6vh) scale(0.34);
  }

  50.5660377358% {
    transform: translateX(16vw) translateY(9vh) scale(0.86);
  }

  50.9433962264% {
    transform: translateX(47vw) translateY(-24vh) scale(0.63);
  }

  51.320754717% {
    transform: translateX(12vw) translateY(-34vh) scale(0.63);
  }

  51.6981132075% {
    transform: translateX(11vw) translateY(32vh) scale(0.75);
  }

  52.0754716981% {
    transform: translateX(20vw) translateY(-6vh) scale(0.62);
  }

  52.4528301887% {
    transform: translateX(38vw) translateY(46vh) scale(0.68);
  }

  52.8301886792% {
    transform: translateX(-2vw) translateY(-12vh) scale(0.38);
  }

  53.2075471698% {
    transform: translateX(23vw) translateY(-35vh) scale(0.8);
  }

  53.5849056604% {
    transform: translateX(1vw) translateY(0vh) scale(0.7);
  }

  53.9622641509% {
    transform: translateX(-6vw) translateY(0vh) scale(0.66);
  }

  54.3396226415% {
    transform: translateX(19vw) translateY(-30vh) scale(0.75);
  }

  54.7169811321% {
    transform: translateX(11vw) translateY(23vh) scale(0.54);
  }

  55.0943396226% {
    transform: translateX(-29vw) translateY(44vh) scale(0.32);
  }

  55.4716981132% {
    transform: translateX(48vw) translateY(0vh) scale(0.53);
  }

  55.8490566038% {
    transform: translateX(45vw) translateY(-44vh) scale(0.73);
  }

  56.2264150943% {
    transform: translateX(-17vw) translateY(-3vh) scale(0.46);
  }

  56.6037735849% {
    transform: translateX(31vw) translateY(-8vh) scale(0.7);
  }

  56.9811320755% {
    transform: translateX(-15vw) translateY(-12vh) scale(0.88);
  }

  57.358490566% {
    transform: translateX(-32vw) translateY(45vh) scale(0.52);
  }

  57.7358490566% {
    transform: translateX(40vw) translateY(-11vh) scale(0.47);
  }

  58.1132075472% {
    transform: translateX(45vw) translateY(-42vh) scale(0.69);
  }

  58.4905660377% {
    transform: translateX(18vw) translateY(47vh) scale(0.26);
  }

  58.8679245283% {
    transform: translateX(-41vw) translateY(-44vh) scale(0.32);
  }

  59.2452830189% {
    transform: translateX(0vw) translateY(34vh) scale(0.68);
  }

  59.6226415094% {
    transform: translateX(-40vw) translateY(-31vh) scale(0.69);
  }

  60% {
    transform: translateX(-15vw) translateY(-46vh) scale(0.76);
  }

  60.3773584906% {
    transform: translateX(44vw) translateY(-2vh) scale(0.35);
  }

  60.7547169811% {
    transform: translateX(44vw) translateY(38vh) scale(0.64);
  }

  61.1320754717% {
    transform: translateX(-14vw) translateY(6vh) scale(0.29);
  }

  61.5094339623% {
    transform: translateX(-24vw) translateY(5vh) scale(0.34);
  }

  61.8867924528% {
    transform: translateX(24vw) translateY(-23vh) scale(0.91);
  }

  62.2641509434% {
    transform: translateX(9vw) translateY(37vh) scale(0.52);
  }

  62.641509434% {
    transform: translateX(-17vw) translateY(-1vh) scale(0.43);
  }

  63.0188679245% {
    transform: translateX(12vw) translateY(42vh) scale(0.38);
  }

  63.3962264151% {
    transform: translateX(-26vw) translateY(0vh) scale(0.83);
  }

  63.7735849057% {
    transform: translateX(43vw) translateY(21vh) scale(0.56);
  }

  64.1509433962% {
    transform: translateX(-27vw) translateY(-17vh) scale(0.79);
  }

  64.5283018868% {
    transform: translateX(-41vw) translateY(-10vh) scale(0.78);
  }

  64.9056603774% {
    transform: translateX(-2vw) translateY(-5vh) scale(0.91);
  }

  65.2830188679% {
    transform: translateX(48vw) translateY(44vh) scale(0.65);
  }

  65.6603773585% {
    transform: translateX(-40vw) translateY(-48vh) scale(0.34);
  }

  66.0377358491% {
    transform: translateX(-29vw) translateY(24vh) scale(0.96);
  }

  66.4150943396% {
    transform: translateX(8vw) translateY(-7vh) scale(0.87);
  }

  66.7924528302% {
    transform: translateX(37vw) translateY(-44vh) scale(0.26);
  }

  67.1698113208% {
    transform: translateX(-30vw) translateY(-44vh) scale(0.52);
  }

  67.5471698113% {
    transform: translateX(35vw) translateY(26vh) scale(0.44);
  }

  67.9245283019% {
    transform: translateX(46vw) translateY(33vh) scale(0.31);
  }

  68.3018867925% {
    transform: translateX(22vw) translateY(7vh) scale(0.34);
  }

  68.679245283% {
    transform: translateX(37vw) translateY(-15vh) scale(0.64);
  }

  69.0566037736% {
    transform: translateX(-43vw) translateY(-36vh) scale(0.46);
  }

  69.4339622642% {
    transform: translateX(37vw) translateY(16vh) scale(0.87);
  }

  69.8113207547% {
    transform: translateX(21vw) translateY(-27vh) scale(0.55);
  }

  70.1886792453% {
    transform: translateX(10vw) translateY(-16vh) scale(0.43);
  }

  70.5660377358% {
    transform: translateX(-14vw) translateY(-39vh) scale(0.88);
  }

  70.9433962264% {
    transform: translateX(23vw) translateY(-31vh) scale(0.66);
  }

  71.320754717% {
    transform: translateX(29vw) translateY(34vh) scale(0.77);
  }

  71.6981132075% {
    transform: translateX(-34vw) translateY(12vh) scale(0.47);
  }

  72.0754716981% {
    transform: translateX(13vw) translateY(-49vh) scale(0.75);
  }

  72.4528301887% {
    transform: translateX(-30vw) translateY(-37vh) scale(0.7);
  }

  72.8301886792% {
    transform: translateX(22vw) translateY(-15vh) scale(0.31);
  }

  73.2075471698% {
    transform: translateX(-18vw) translateY(-33vh) scale(0.63);
  }

  73.5849056604% {
    transform: translateX(-35vw) translateY(-30vh) scale(0.75);
  }

  73.9622641509% {
    transform: translateX(40vw) translateY(-43vh) scale(0.99);
  }

  74.3396226415% {
    transform: translateX(17vw) translateY(33vh) scale(0.72);
  }

  74.7169811321% {
    transform: translateX(36vw) translateY(30vh) scale(0.68);
  }

  75.0943396226% {
    transform: translateX(45vw) translateY(-12vh) scale(0.73);
  }

  75.4716981132% {
    transform: translateX(50vw) translateY(-3vh) scale(0.5);
  }

  75.8490566038% {
    transform: translateX(43vw) translateY(43vh) scale(0.45);
  }

  76.2264150943% {
    transform: translateX(13vw) translateY(33vh) scale(0.45);
  }

  76.6037735849% {
    transform: translateX(-26vw) translateY(19vh) scale(0.52);
  }

  76.9811320755% {
    transform: translateX(26vw) translateY(-14vh) scale(0.32);
  }

  77.358490566% {
    transform: translateX(10vw) translateY(-8vh) scale(0.9);
  }

  77.7358490566% {
    transform: translateX(30vw) translateY(-20vh) scale(0.51);
  }

  78.1132075472% {
    transform: translateX(30vw) translateY(-2vh) scale(0.94);
  }

  78.4905660377% {
    transform: translateX(19vw) translateY(13vh) scale(0.69);
  }

  78.8679245283% {
    transform: translateX(-22vw) translateY(-17vh) scale(0.85);
  }

  79.2452830189% {
    transform: translateX(-40vw) translateY(-28vh) scale(0.66);
  }

  79.6226415094% {
    transform: translateX(-34vw) translateY(-2vh) scale(0.94);
  }

  80% {
    transform: translateX(24vw) translateY(-27vh) scale(0.62);
  }

  80.3773584906% {
    transform: translateX(21vw) translateY(-49vh) scale(0.51);
  }

  80.7547169811% {
    transform: translateX(42vw) translateY(-25vh) scale(0.38);
  }

  81.1320754717% {
    transform: translateX(15vw) translateY(-7vh) scale(0.74);
  }

  81.5094339623% {
    transform: translateX(-3vw) translateY(-25vh) scale(0.71);
  }

  81.8867924528% {
    transform: translateX(-42vw) translateY(-11vh) scale(0.43);
  }

  82.2641509434% {
    transform: translateX(24vw) translateY(9vh) scale(0.57);
  }

  82.641509434% {
    transform: translateX(-42vw) translateY(-35vh) scale(0.84);
  }

  83.0188679245% {
    transform: translateX(-39vw) translateY(26vh) scale(0.97);
  }

  83.3962264151% {
    transform: translateX(-43vw) translateY(-13vh) scale(0.29);
  }

  83.7735849057% {
    transform: translateX(41vw) translateY(23vh) scale(0.6);
  }

  84.1509433962% {
    transform: translateX(-10vw) translateY(29vh) scale(0.89);
  }

  84.5283018868% {
    transform: translateX(47vw) translateY(-3vh) scale(0.92);
  }

  84.9056603774% {
    transform: translateX(-4vw) translateY(-48vh) scale(0.96);
  }

  85.2830188679% {
    transform: translateX(-2vw) translateY(41vh) scale(0.26);
  }

  85.6603773585% {
    transform: translateX(-13vw) translateY(-19vh) scale(0.3);
  }

  86.0377358491% {
    transform: translateX(3vw) translateY(-19vh) scale(0.36);
  }

  86.4150943396% {
    transform: translateX(31vw) translateY(-11vh) scale(0.94);
  }

  86.7924528302% {
    transform: translateX(40vw) translateY(-10vh) scale(0.45);
  }

  87.1698113208% {
    transform: translateX(-25vw) translateY(16vh) scale(0.92);
  }

  87.5471698113% {
    transform: translateX(-35vw) translateY(37vh) scale(0.5);
  }

  87.9245283019% {
    transform: translateX(-3vw) translateY(4vh) scale(0.32);
  }

  88.3018867925% {
    transform: translateX(-7vw) translateY(-32vh) scale(0.76);
  }

  88.679245283% {
    transform: translateX(-11vw) translateY(-11vh) scale(0.34);
  }

  89.0566037736% {
    transform: translateX(31vw) translateY(15vh) scale(0.44);
  }

  89.4339622642% {
    transform: translateX(45vw) translateY(-21vh) scale(0.98);
  }

  89.8113207547% {
    transform: translateX(9vw) translateY(27vh) scale(0.61);
  }

  90.1886792453% {
    transform: translateX(-3vw) translateY(7vh) scale(0.65);
  }

  90.5660377358% {
    transform: translateX(42vw) translateY(12vh) scale(0.74);
  }

  90.9433962264% {
    transform: translateX(42vw) translateY(39vh) scale(0.94);
  }

  91.320754717% {
    transform: translateX(-9vw) translateY(-25vh) scale(0.89);
  }

  91.6981132075% {
    transform: translateX(-38vw) translateY(0vh) scale(0.74);
  }

  92.0754716981% {
    transform: translateX(-17vw) translateY(23vh) scale(0.99);
  }

  92.4528301887% {
    transform: translateX(32vw) translateY(18vh) scale(0.92);
  }

  92.8301886792% {
    transform: translateX(17vw) translateY(-17vh) scale(0.5);
  }

  93.2075471698% {
    transform: translateX(-7vw) translateY(-47vh) scale(0.98);
  }

  93.5849056604% {
    transform: translateX(-39vw) translateY(15vh) scale(0.28);
  }

  93.9622641509% {
    transform: translateX(-17vw) translateY(43vh) scale(0.74);
  }

  94.3396226415% {
    transform: translateX(6vw) translateY(32vh) scale(0.67);
  }

  94.7169811321% {
    transform: translateX(7vw) translateY(-18vh) scale(0.86);
  }

  95.0943396226% {
    transform: translateX(-14vw) translateY(-5vh) scale(0.34);
  }

  95.4716981132% {
    transform: translateX(-46vw) translateY(-35vh) scale(0.46);
  }

  95.8490566038% {
    transform: translateX(-7vw) translateY(-40vh) scale(0.65);
  }

  96.2264150943% {
    transform: translateX(22vw) translateY(-19vh) scale(0.29);
  }

  96.6037735849% {
    transform: translateX(2vw) translateY(-24vh) scale(0.48);
  }

  96.9811320755% {
    transform: translateX(34vw) translateY(-32vh) scale(0.72);
  }

  97.358490566% {
    transform: translateX(48vw) translateY(-38vh) scale(0.85);
  }

  97.7358490566% {
    transform: translateX(-2vw) translateY(-17vh) scale(0.35);
  }

  98.1132075472% {
    transform: translateX(-36vw) translateY(-48vh) scale(0.54);
  }

  98.4905660377% {
    transform: translateX(-12vw) translateY(6vh) scale(0.32);
  }

  98.8679245283% {
    transform: translateX(-14vw) translateY(8vh) scale(0.44);
  }

  99.2452830189% {
    transform: translateX(-10vw) translateY(-27vh) scale(0.4);
  }

  99.6226415094% {
    transform: translateX(-15vw) translateY(15vh) scale(0.62);
  }

  100% {
    transform: translateX(45vw) translateY(36vh) scale(0.27);
  }
}

.firefly:nth-child(6) {
  animation-name: move6;
}

.firefly:nth-child(6)::before {
  animation-duration: 16s;
}

.firefly:nth-child(6)::after {
  animation-duration: 16s, 7905ms;
  animation-delay: 0ms, 4129ms;
}

@keyframes move6 {
  0% {
    transform: translateX(16vw) translateY(-37vh) scale(0.54);
  }

  0.3584229391% {
    transform: translateX(-9vw) translateY(-44vh) scale(0.42);
  }

  0.7168458781% {
    transform: translateX(36vw) translateY(-2vh) scale(0.41);
  }

  1.0752688172% {
    transform: translateX(-3vw) translateY(-15vh) scale(0.82);
  }

  1.4336917563% {
    transform: translateX(16vw) translateY(45vh) scale(1);
  }

  1.7921146953% {
    transform: translateX(37vw) translateY(-23vh) scale(0.9);
  }

  2.1505376344% {
    transform: translateX(-13vw) translateY(5vh) scale(0.76);
  }

  2.5089605735% {
    transform: translateX(8vw) translateY(-7vh) scale(0.83);
  }

  2.8673835125% {
    transform: translateX(18vw) translateY(-12vh) scale(0.42);
  }

  3.2258064516% {
    transform: translateX(-26vw) translateY(-5vh) scale(0.83);
  }

  3.5842293907% {
    transform: translateX(-8vw) translateY(-36vh) scale(0.42);
  }

  3.9426523297% {
    transform: translateX(-42vw) translateY(28vh) scale(0.51);
  }

  4.3010752688% {
    transform: translateX(44vw) translateY(-17vh) scale(0.67);
  }

  4.6594982079% {
    transform: translateX(-1vw) translateY(43vh) scale(0.3);
  }

  5.017921147% {
    transform: translateX(-11vw) translateY(-14vh) scale(0.35);
  }

  5.376344086% {
    transform: translateX(24vw) translateY(39vh) scale(0.77);
  }

  5.7347670251% {
    transform: translateX(-46vw) translateY(-1vh) scale(0.26);
  }

  6.0931899642% {
    transform: translateX(-8vw) translateY(-48vh) scale(0.8);
  }

  6.4516129032% {
    transform: translateX(17vw) translateY(42vh) scale(0.89);
  }

  6.8100358423% {
    transform: translateX(12vw) translateY(-44vh) scale(0.95);
  }

  7.1684587814% {
    transform: translateX(-14vw) translateY(-28vh) scale(0.84);
  }

  7.5268817204% {
    transform: translateX(-38vw) translateY(4vh) scale(0.93);
  }

  7.8853046595% {
    transform: translateX(14vw) translateY(9vh) scale(0.91);
  }

  8.2437275986% {
    transform: translateX(-24vw) translateY(-13vh) scale(0.68);
  }

  8.6021505376% {
    transform: translateX(-45vw) translateY(-12vh) scale(0.77);
  }

  8.9605734767% {
    transform: translateX(-19vw) translateY(0vh) scale(0.7);
  }

  9.3189964158% {
    transform: translateX(8vw) translateY(16vh) scale(0.87);
  }

  9.6774193548% {
    transform: translateX(-39vw) translateY(-39vh) scale(0.83);
  }

  10.0358422939% {
    transform: translateX(40vw) translateY(4vh) scale(0.93);
  }

  10.394265233% {
    transform: translateX(-22vw) translateY(-44vh) scale(0.56);
  }

  10.752688172% {
    transform: translateX(-35vw) translateY(35vh) scale(0.6);
  }

  11.1111111111% {
    transform: translateX(4vw) translateY(-17vh) scale(0.91);
  }

  11.4695340502% {
    transform: translateX(-8vw) translateY(7vh) scale(0.65);
  }

  11.8279569892% {
    transform: translateX(-6vw) translateY(0vh) scale(0.26);
  }

  12.1863799283% {
    transform: translateX(-15vw) translateY(23vh) scale(1);
  }

  12.5448028674% {
    transform: translateX(11vw) translateY(-28vh) scale(0.55);
  }

  12.9032258065% {
    transform: translateX(-10vw) translateY(46vh) scale(0.4);
  }

  13.2616487455% {
    transform: translateX(38vw) translateY(-5vh) scale(0.4);
  }

  13.6200716846% {
    transform: translateX(-24vw) translateY(-16vh) scale(0.5);
  }

  13.9784946237% {
    transform: translateX(39vw) translateY(21vh) scale(0.57);
  }

  14.3369175627% {
    transform: translateX(-15vw) translateY(11vh) scale(0.42);
  }

  14.6953405018% {
    transform: translateX(-32vw) translateY(-21vh) scale(0.77);
  }

  15.0537634409% {
    transform: translateX(7vw) translateY(-16vh) scale(0.67);
  }

  15.4121863799% {
    transform: translateX(45vw) translateY(44vh) scale(0.99);
  }

  15.770609319% {
    transform: translateX(-16vw) translateY(-31vh) scale(0.87);
  }

  16.1290322581% {
    transform: translateX(-10vw) translateY(-31vh) scale(0.72);
  }

  16.4874551971% {
    transform: translateX(-16vw) translateY(30vh) scale(0.99);
  }

  16.8458781362% {
    transform: translateX(-27vw) translateY(6vh) scale(0.44);
  }

  17.2043010753% {
    transform: translateX(-36vw) translateY(23vh) scale(0.26);
  }

  17.5627240143% {
    transform: translateX(20vw) translateY(-37vh) scale(0.76);
  }

  17.9211469534% {
    transform: translateX(34vw) translateY(6vh) scale(0.49);
  }

  18.2795698925% {
    transform: translateX(44vw) translateY(-27vh) scale(0.74);
  }

  18.6379928315% {
    transform: translateX(8vw) translateY(-44vh) scale(0.27);
  }

  18.9964157706% {
    transform: translateX(46vw) translateY(-43vh) scale(0.31);
  }

  19.3548387097% {
    transform: translateX(46vw) translateY(6vh) scale(0.3);
  }

  19.7132616487% {
    transform: translateX(9vw) translateY(47vh) scale(0.69);
  }

  20.0716845878% {
    transform: translateX(8vw) translateY(-44vh) scale(0.32);
  }

  20.4301075269% {
    transform: translateX(-30vw) translateY(-38vh) scale(0.4);
  }

  20.7885304659% {
    transform: translateX(25vw) translateY(-21vh) scale(0.9);
  }

  21.146953405% {
    transform: translateX(-13vw) translateY(-37vh) scale(0.5);
  }

  21.5053763441% {
    transform: translateX(47vw) translateY(44vh) scale(0.42);
  }

  21.8637992832% {
    transform: translateX(5vw) translateY(37vh) scale(0.58);
  }

  22.2222222222% {
    transform: translateX(-18vw) translateY(41vh) scale(0.45);
  }

  22.5806451613% {
    transform: translateX(-49vw) translateY(-6vh) scale(0.47);
  }

  22.9390681004% {
    transform: translateX(31vw) translateY(24vh) scale(0.66);
  }

  23.2974910394% {
    transform: translateX(49vw) translateY(-34vh) scale(0.49);
  }

  23.6559139785% {
    transform: translateX(-19vw) translateY(34vh) scale(0.95);
  }

  24.0143369176% {
    transform: translateX(-49vw) translateY(50vh) scale(0.62);
  }

  24.3727598566% {
    transform: translateX(36vw) translateY(-20vh) scale(0.78);
  }

  24.7311827957% {
    transform: translateX(29vw) translateY(-29vh) scale(0.42);
  }

  25.0896057348% {
    transform: translateX(30vw) translateY(-11vh) scale(0.57);
  }

  25.4480286738% {
    transform: translateX(-13vw) translateY(50vh) scale(0.79);
  }

  25.8064516129% {
    transform: translateX(0vw) translateY(37vh) scale(0.35);
  }

  26.164874552% {
    transform: translateX(-14vw) translateY(13vh) scale(0.93);
  }

  26.523297491% {
    transform: translateX(40vw) translateY(44vh) scale(0.74);
  }

  26.8817204301% {
    transform: translateX(-2vw) translateY(33vh) scale(0.26);
  }

  27.2401433692% {
    transform: translateX(35vw) translateY(-32vh) scale(0.81);
  }

  27.5985663082% {
    transform: translateX(-42vw) translateY(-39vh) scale(0.41);
  }

  27.9569892473% {
    transform: translateX(-43vw) translateY(-47vh) scale(0.3);
  }

  28.3154121864% {
    transform: translateX(-43vw) translateY(39vh) scale(0.94);
  }

  28.6738351254% {
    transform: translateX(-22vw) translateY(-11vh) scale(0.94);
  }

  29.0322580645% {
    transform: translateX(15vw) translateY(34vh) scale(0.78);
  }

  29.3906810036% {
    transform: translateX(-27vw) translateY(3vh) scale(0.38);
  }

  29.7491039427% {
    transform: translateX(1vw) translateY(21vh) scale(0.27);
  }

  30.1075268817% {
    transform: translateX(-25vw) translateY(-22vh) scale(0.35);
  }

  30.4659498208% {
    transform: translateX(-8vw) translateY(7vh) scale(0.93);
  }

  30.8243727599% {
    transform: translateX(31vw) translateY(10vh) scale(0.57);
  }

  31.1827956989% {
    transform: translateX(-38vw) translateY(13vh) scale(0.62);
  }

  31.541218638% {
    transform: translateX(34vw) translateY(-45vh) scale(0.7);
  }

  31.8996415771% {
    transform: translateX(-16vw) translateY(-10vh) scale(0.93);
  }

  32.2580645161% {
    transform: translateX(-2vw) translateY(32vh) scale(0.65);
  }

  32.6164874552% {
    transform: translateX(27vw) translateY(-21vh) scale(0.41);
  }

  32.9749103943% {
    transform: translateX(31vw) translateY(28vh) scale(0.83);
  }

  33.3333333333% {
    transform: translateX(-47vw) translateY(-20vh) scale(0.3);
  }

  33.6917562724% {
    transform: translateX(0vw) translateY(-1vh) scale(0.64);
  }

  34.0501792115% {
    transform: translateX(34vw) translateY(-31vh) scale(0.93);
  }

  34.4086021505% {
    transform: translateX(43vw) translateY(30vh) scale(0.78);
  }

  34.7670250896% {
    transform: translateX(-17vw) translateY(24vh) scale(0.33);
  }

  35.1254480287% {
    transform: translateX(31vw) translateY(-26vh) scale(0.28);
  }

  35.4838709677% {
    transform: translateX(-46vw) translateY(-18vh) scale(0.99);
  }

  35.8422939068% {
    transform: translateX(43vw) translateY(15vh) scale(0.31);
  }

  36.2007168459% {
    transform: translateX(-40vw) translateY(20vh) scale(0.98);
  }

  36.5591397849% {
    transform: translateX(-5vw) translateY(47vh) scale(0.59);
  }

  36.917562724% {
    transform: translateX(-3vw) translateY(44vh) scale(0.79);
  }

  37.2759856631% {
    transform: translateX(-32vw) translateY(24vh) scale(0.58);
  }

  37.6344086022% {
    transform: translateX(-20vw) translateY(-26vh) scale(1);
  }

  37.9928315412% {
    transform: translateX(36vw) translateY(-32vh) scale(0.62);
  }

  38.3512544803% {
    transform: translateX(12vw) translateY(27vh) scale(0.28);
  }

  38.7096774194% {
    transform: translateX(30vw) translateY(-17vh) scale(0.54);
  }

  39.0681003584% {
    transform: translateX(-42vw) translateY(-2vh) scale(0.44);
  }

  39.4265232975% {
    transform: translateX(19vw) translateY(-22vh) scale(0.51);
  }

  39.7849462366% {
    transform: translateX(29vw) translateY(5vh) scale(0.27);
  }

  40.1433691756% {
    transform: translateX(17vw) translateY(-7vh) scale(0.79);
  }

  40.5017921147% {
    transform: translateX(-7vw) translateY(-24vh) scale(0.53);
  }

  40.8602150538% {
    transform: translateX(-32vw) translateY(-42vh) scale(0.53);
  }

  41.2186379928% {
    transform: translateX(-1vw) translateY(48vh) scale(0.65);
  }

  41.5770609319% {
    transform: translateX(-28vw) translateY(-34vh) scale(0.76);
  }

  41.935483871% {
    transform: translateX(-19vw) translateY(14vh) scale(0.77);
  }

  42.29390681% {
    transform: translateX(-43vw) translateY(48vh) scale(0.95);
  }

  42.6523297491% {
    transform: translateX(-42vw) translateY(-38vh) scale(0.76);
  }

  43.0107526882% {
    transform: translateX(8vw) translateY(28vh) scale(0.66);
  }

  43.3691756272% {
    transform: translateX(-46vw) translateY(32vh) scale(0.26);
  }

  43.7275985663% {
    transform: translateX(-24vw) translateY(2vh) scale(0.75);
  }

  44.0860215054% {
    transform: translateX(-32vw) translateY(-13vh) scale(0.5);
  }

  44.4444444444% {
    transform: translateX(-6vw) translateY(8vh) scale(0.75);
  }

  44.8028673835% {
    transform: translateX(43vw) translateY(-16vh) scale(0.73);
  }

  45.1612903226% {
    transform: translateX(1vw) translateY(-10vh) scale(0.34);
  }

  45.5197132616% {
    transform: translateX(23vw) translateY(31vh) scale(0.59);
  }

  45.8781362007% {
    transform: translateX(36vw) translateY(36vh) scale(0.79);
  }

  46.2365591398% {
    transform: translateX(-30vw) translateY(-49vh) scale(0.73);
  }

  46.5949820789% {
    transform: translateX(-35vw) translateY(45vh) scale(0.4);
  }

  46.9534050179% {
    transform: translateX(44vw) translateY(27vh) scale(0.43);
  }

  47.311827957% {
    transform: translateX(47vw) translateY(-46vh) scale(0.6);
  }

  47.6702508961% {
    transform: translateX(1vw) translateY(-40vh) scale(0.92);
  }

  48.0286738351% {
    transform: translateX(-9vw) translateY(-10vh) scale(0.84);
  }

  48.3870967742% {
    transform: translateX(7vw) translateY(-7vh) scale(0.67);
  }

  48.7455197133% {
    transform: translateX(-6vw) translateY(1vh) scale(0.84);
  }

  49.1039426523% {
    transform: translateX(-24vw) translateY(29vh) scale(0.67);
  }

  49.4623655914% {
    transform: translateX(-18vw) translateY(8vh) scale(0.59);
  }

  49.8207885305% {
    transform: translateX(39vw) translateY(-49vh) scale(0.5);
  }

  50.1792114695% {
    transform: translateX(-3vw) translateY(-22vh) scale(0.77);
  }

  50.5376344086% {
    transform: translateX(-33vw) translateY(27vh) scale(0.82);
  }

  50.8960573477% {
    transform: translateX(-40vw) translateY(-38vh) scale(0.55);
  }

  51.2544802867% {
    transform: translateX(-31vw) translateY(28vh) scale(0.49);
  }

  51.6129032258% {
    transform: translateX(45vw) translateY(-19vh) scale(0.41);
  }

  51.9713261649% {
    transform: translateX(-47vw) translateY(47vh) scale(0.82);
  }

  52.3297491039% {
    transform: translateX(-36vw) translateY(-21vh) scale(0.76);
  }

  52.688172043% {
    transform: translateX(31vw) translateY(14vh) scale(0.63);
  }

  53.0465949821% {
    transform: translateX(-18vw) translateY(-49vh) scale(0.47);
  }

  53.4050179211% {
    transform: translateX(-1vw) translateY(47vh) scale(0.33);
  }

  53.7634408602% {
    transform: translateX(10vw) translateY(-14vh) scale(0.76);
  }

  54.1218637993% {
    transform: translateX(-1vw) translateY(-7vh) scale(0.29);
  }

  54.4802867384% {
    transform: translateX(-28vw) translateY(12vh) scale(0.85);
  }

  54.8387096774% {
    transform: translateX(-45vw) translateY(-32vh) scale(0.89);
  }

  55.1971326165% {
    transform: translateX(-2vw) translateY(0vh) scale(0.36);
  }

  55.5555555556% {
    transform: translateX(-48vw) translateY(13vh) scale(0.45);
  }

  55.9139784946% {
    transform: translateX(17vw) translateY(-16vh) scale(0.42);
  }

  56.2724014337% {
    transform: translateX(40vw) translateY(39vh) scale(0.35);
  }

  56.6308243728% {
    transform: translateX(39vw) translateY(25vh) scale(0.69);
  }

  56.9892473118% {
    transform: translateX(34vw) translateY(-36vh) scale(1);
  }

  57.3476702509% {
    transform: translateX(20vw) translateY(12vh) scale(0.63);
  }

  57.70609319% {
    transform: translateX(24vw) translateY(19vh) scale(0.6);
  }

  58.064516129% {
    transform: translateX(-39vw) translateY(17vh) scale(0.5);
  }

  58.4229390681% {
    transform: translateX(-44vw) translateY(-23vh) scale(0.39);
  }

  58.7813620072% {
    transform: translateX(-18vw) translateY(38vh) scale(0.59);
  }

  59.1397849462% {
    transform: translateX(-9vw) translateY(-4vh) scale(0.54);
  }

  59.4982078853% {
    transform: translateX(7vw) translateY(-11vh) scale(0.9);
  }

  59.8566308244% {
    transform: translateX(7vw) translateY(-21vh) scale(0.34);
  }

  60.2150537634% {
    transform: translateX(11vw) translateY(28vh) scale(0.78);
  }

  60.5734767025% {
    transform: translateX(-18vw) translateY(-12vh) scale(0.88);
  }

  60.9318996416% {
    transform: translateX(-20vw) translateY(33vh) scale(0.42);
  }

  61.2903225806% {
    transform: translateX(35vw) translateY(30vh) scale(0.34);
  }

  61.6487455197% {
    transform: translateX(-10vw) translateY(-25vh) scale(0.72);
  }

  62.0071684588% {
    transform: translateX(13vw) translateY(0vh) scale(0.81);
  }

  62.3655913978% {
    transform: translateX(-44vw) translateY(44vh) scale(0.38);
  }

  62.7240143369% {
    transform: translateX(-33vw) translateY(40vh) scale(0.89);
  }

  63.082437276% {
    transform: translateX(-4vw) translateY(-19vh) scale(0.64);
  }

  63.4408602151% {
    transform: translateX(24vw) translateY(-41vh) scale(0.56);
  }

  63.7992831541% {
    transform: translateX(5vw) translateY(-6vh) scale(0.62);
  }

  64.1577060932% {
    transform: translateX(-15vw) translateY(12vh) scale(0.32);
  }

  64.5161290323% {
    transform: translateX(-9vw) translateY(-22vh) scale(0.43);
  }

  64.8745519713% {
    transform: translateX(0vw) translateY(14vh) scale(0.48);
  }

  65.2329749104% {
    transform: translateX(-5vw) translateY(-35vh) scale(0.87);
  }

  65.5913978495% {
    transform: translateX(49vw) translateY(3vh) scale(0.85);
  }

  65.9498207885% {
    transform: translateX(4vw) translateY(15vh) scale(0.92);
  }

  66.3082437276% {
    transform: translateX(3vw) translateY(16vh) scale(0.3);
  }

  66.6666666667% {
    transform: translateX(-41vw) translateY(6vh) scale(0.52);
  }

  67.0250896057% {
    transform: translateX(49vw) translateY(18vh) scale(0.3);
  }

  67.3835125448% {
    transform: translateX(-17vw) translateY(-43vh) scale(0.83);
  }

  67.7419354839% {
    transform: translateX(37vw) translateY(-44vh) scale(0.91);
  }

  68.1003584229% {
    transform: translateX(28vw) translateY(30vh) scale(0.86);
  }

  68.458781362% {
    transform: translateX(30vw) translateY(-24vh) scale(0.61);
  }

  68.8172043011% {
    transform: translateX(34vw) translateY(11vh) scale(0.55);
  }

  69.1756272401% {
    transform: translateX(3vw) translateY(6vh) scale(0.29);
  }

  69.5340501792% {
    transform: translateX(4vw) translateY(-3vh) scale(0.8);
  }

  69.8924731183% {
    transform: translateX(-32vw) translateY(42vh) scale(0.56);
  }

  70.2508960573% {
    transform: translateX(3vw) translateY(-47vh) scale(0.27);
  }

  70.6093189964% {
    transform: translateX(-42vw) translateY(-44vh) scale(0.96);
  }

  70.9677419355% {
    transform: translateX(26vw) translateY(-22vh) scale(0.61);
  }

  71.3261648746% {
    transform: translateX(50vw) translateY(37vh) scale(1);
  }

  71.6845878136% {
    transform: translateX(48vw) translateY(-36vh) scale(0.47);
  }

  72.0430107527% {
    transform: translateX(-19vw) translateY(26vh) scale(0.81);
  }

  72.4014336918% {
    transform: translateX(-40vw) translateY(-39vh) scale(0.76);
  }

  72.7598566308% {
    transform: translateX(6vw) translateY(16vh) scale(0.88);
  }

  73.1182795699% {
    transform: translateX(5vw) translateY(48vh) scale(0.79);
  }

  73.476702509% {
    transform: translateX(12vw) translateY(27vh) scale(0.41);
  }

  73.835125448% {
    transform: translateX(13vw) translateY(-11vh) scale(0.45);
  }

  74.1935483871% {
    transform: translateX(46vw) translateY(-41vh) scale(0.51);
  }

  74.5519713262% {
    transform: translateX(19vw) translateY(-16vh) scale(0.71);
  }

  74.9103942652% {
    transform: translateX(24vw) translateY(-21vh) scale(0.85);
  }

  75.2688172043% {
    transform: translateX(40vw) translateY(23vh) scale(0.46);
  }

  75.6272401434% {
    transform: translateX(-1vw) translateY(-42vh) scale(0.29);
  }

  75.9856630824% {
    transform: translateX(20vw) translateY(0vh) scale(0.71);
  }

  76.3440860215% {
    transform: translateX(33vw) translateY(-39vh) scale(0.67);
  }

  76.7025089606% {
    transform: translateX(2vw) translateY(-22vh) scale(0.98);
  }

  77.0609318996% {
    transform: translateX(44vw) translateY(-48vh) scale(0.69);
  }

  77.4193548387% {
    transform: translateX(-30vw) translateY(31vh) scale(0.94);
  }

  77.7777777778% {
    transform: translateX(14vw) translateY(-23vh) scale(0.58);
  }

  78.1362007168% {
    transform: translateX(8vw) translateY(-27vh) scale(0.43);
  }

  78.4946236559% {
    transform: translateX(-16vw) translateY(-4vh) scale(0.99);
  }

  78.853046595% {
    transform: translateX(15vw) translateY(-43vh) scale(0.61);
  }

  79.2114695341% {
    transform: translateX(32vw) translateY(38vh) scale(0.41);
  }

  79.5698924731% {
    transform: translateX(46vw) translateY(-34vh) scale(0.73);
  }

  79.9283154122% {
    transform: translateX(-13vw) translateY(0vh) scale(0.78);
  }

  80.2867383513% {
    transform: translateX(35vw) translateY(-20vh) scale(0.7);
  }

  80.6451612903% {
    transform: translateX(11vw) translateY(47vh) scale(0.42);
  }

  81.0035842294% {
    transform: translateX(9vw) translateY(46vh) scale(0.78);
  }

  81.3620071685% {
    transform: translateX(-16vw) translateY(45vh) scale(0.54);
  }

  81.7204301075% {
    transform: translateX(3vw) translateY(2vh) scale(0.97);
  }

  82.0788530466% {
    transform: translateX(17vw) translateY(9vh) scale(0.89);
  }

  82.4372759857% {
    transform: translateX(-31vw) translateY(43vh) scale(0.28);
  }

  82.7956989247% {
    transform: translateX(43vw) translateY(18vh) scale(0.55);
  }

  83.1541218638% {
    transform: translateX(9vw) translateY(-28vh) scale(0.62);
  }

  83.5125448029% {
    transform: translateX(-40vw) translateY(-47vh) scale(0.44);
  }

  83.8709677419% {
    transform: translateX(-44vw) translateY(-25vh) scale(0.65);
  }

  84.229390681% {
    transform: translateX(20vw) translateY(35vh) scale(0.88);
  }

  84.5878136201% {
    transform: translateX(36vw) translateY(-13vh) scale(0.91);
  }

  84.9462365591% {
    transform: translateX(-4vw) translateY(47vh) scale(0.3);
  }

  85.3046594982% {
    transform: translateX(1vw) translateY(-25vh) scale(0.7);
  }

  85.6630824373% {
    transform: translateX(0vw) translateY(15vh) scale(0.97);
  }

  86.0215053763% {
    transform: translateX(30vw) translateY(-22vh) scale(0.71);
  }

  86.3799283154% {
    transform: translateX(35vw) translateY(-19vh) scale(0.37);
  }

  86.7383512545% {
    transform: translateX(25vw) translateY(-7vh) scale(0.62);
  }

  87.0967741935% {
    transform: translateX(37vw) translateY(-21vh) scale(0.64);
  }

  87.4551971326% {
    transform: translateX(26vw) translateY(26vh) scale(0.87);
  }

  87.8136200717% {
    transform: translateX(10vw) translateY(2vh) scale(0.55);
  }

  88.1720430108% {
    transform: translateX(-34vw) translateY(-49vh) scale(0.39);
  }

  88.5304659498% {
    transform: translateX(-44vw) translateY(-17vh) scale(0.8);
  }

  88.8888888889% {
    transform: translateX(7vw) translateY(36vh) scale(0.82);
  }

  89.247311828% {
    transform: translateX(-31vw) translateY(-18vh) scale(0.9);
  }

  89.605734767% {
    transform: translateX(46vw) translateY(46vh) scale(1);
  }

  89.9641577061% {
    transform: translateX(-17vw) translateY(36vh) scale(0.38);
  }

  90.3225806452% {
    transform: translateX(14vw) translateY(37vh) scale(0.73);
  }

  90.6810035842% {
    transform: translateX(-9vw) translateY(-3vh) scale(0.3);
  }

  91.0394265233% {
    transform: translateX(-49vw) translateY(17vh) scale(0.55);
  }

  91.3978494624% {
    transform: translateX(-25vw) translateY(-30vh) scale(0.99);
  }

  91.7562724014% {
    transform: translateX(35vw) translateY(-48vh) scale(0.99);
  }

  92.1146953405% {
    transform: translateX(5vw) translateY(8vh) scale(0.83);
  }

  92.4731182796% {
    transform: translateX(-29vw) translateY(-15vh) scale(0.68);
  }

  92.8315412186% {
    transform: translateX(34vw) translateY(-1vh) scale(0.92);
  }

  93.1899641577% {
    transform: translateX(21vw) translateY(-22vh) scale(0.66);
  }

  93.5483870968% {
    transform: translateX(-49vw) translateY(12vh) scale(0.29);
  }

  93.9068100358% {
    transform: translateX(-27vw) translateY(0vh) scale(0.73);
  }

  94.2652329749% {
    transform: translateX(-5vw) translateY(38vh) scale(0.3);
  }

  94.623655914% {
    transform: translateX(48vw) translateY(-43vh) scale(0.98);
  }

  94.982078853% {
    transform: translateX(9vw) translateY(21vh) scale(0.88);
  }

  95.3405017921% {
    transform: translateX(-7vw) translateY(20vh) scale(0.48);
  }

  95.6989247312% {
    transform: translateX(47vw) translateY(-38vh) scale(0.54);
  }

  96.0573476703% {
    transform: translateX(-31vw) translateY(37vh) scale(0.85);
  }

  96.4157706093% {
    transform: translateX(-1vw) translateY(13vh) scale(0.8);
  }

  96.7741935484% {
    transform: translateX(14vw) translateY(2vh) scale(0.5);
  }

  97.1326164875% {
    transform: translateX(33vw) translateY(9vh) scale(0.41);
  }

  97.4910394265% {
    transform: translateX(-17vw) translateY(27vh) scale(0.28);
  }

  97.8494623656% {
    transform: translateX(35vw) translateY(28vh) scale(0.96);
  }

  98.2078853047% {
    transform: translateX(12vw) translateY(30vh) scale(0.34);
  }

  98.5663082437% {
    transform: translateX(-34vw) translateY(2vh) scale(0.81);
  }

  98.9247311828% {
    transform: translateX(-9vw) translateY(39vh) scale(0.91);
  }

  99.2831541219% {
    transform: translateX(-40vw) translateY(-40vh) scale(0.61);
  }

  99.6415770609% {
    transform: translateX(43vw) translateY(31vh) scale(0.39);
  }

  100% {
    transform: translateX(-27vw) translateY(33vh) scale(0.46);
  }
}

.firefly:nth-child(7) {
  animation-name: move7;
}

.firefly:nth-child(7)::before {
  animation-duration: 13s;
}

.firefly:nth-child(7)::after {
  animation-duration: 13s, 6557ms;
  animation-delay: 0ms, 6914ms;
}

@keyframes move7 {
  0% {
    transform: translateX(-49vw) translateY(33vh) scale(0.33);
  }

  0.3984063745% {
    transform: translateX(-27vw) translateY(-46vh) scale(0.45);
  }

  0.796812749% {
    transform: translateX(-43vw) translateY(36vh) scale(0.99);
  }

  1.1952191235% {
    transform: translateX(13vw) translateY(7vh) scale(0.99);
  }

  1.593625498% {
    transform: translateX(47vw) translateY(-33vh) scale(0.6);
  }

  1.9920318725% {
    transform: translateX(-30vw) translateY(-32vh) scale(0.29);
  }

  2.390438247% {
    transform: translateX(17vw) translateY(-44vh) scale(0.76);
  }

  2.7888446215% {
    transform: translateX(-49vw) translateY(26vh) scale(0.85);
  }

  3.187250996% {
    transform: translateX(4vw) translateY(4vh) scale(0.84);
  }

  3.5856573705% {
    transform: translateX(34vw) translateY(-34vh) scale(0.59);
  }

  3.984063745% {
    transform: translateX(17vw) translateY(5vh) scale(0.59);
  }

  4.3824701195% {
    transform: translateX(49vw) translateY(25vh) scale(0.79);
  }

  4.780876494% {
    transform: translateX(4vw) translateY(-26vh) scale(0.45);
  }

  5.1792828685% {
    transform: translateX(10vw) translateY(-20vh) scale(0.29);
  }

  5.577689243% {
    transform: translateX(48vw) translateY(30vh) scale(0.79);
  }

  5.9760956175% {
    transform: translateX(36vw) translateY(-15vh) scale(0.82);
  }

  6.374501992% {
    transform: translateX(-22vw) translateY(40vh) scale(0.31);
  }

  6.7729083665% {
    transform: translateX(37vw) translateY(-45vh) scale(0.98);
  }

  7.171314741% {
    transform: translateX(-4vw) translateY(23vh) scale(0.26);
  }

  7.5697211155% {
    transform: translateX(33vw) translateY(-25vh) scale(0.4);
  }

  7.96812749% {
    transform: translateX(-47vw) translateY(-8vh) scale(0.51);
  }

  8.3665338645% {
    transform: translateX(-1vw) translateY(6vh) scale(0.92);
  }

  8.764940239% {
    transform: translateX(-42vw) translateY(-24vh) scale(0.63);
  }

  9.1633466135% {
    transform: translateX(4vw) translateY(11vh) scale(0.32);
  }

  9.561752988% {
    transform: translateX(-13vw) translateY(-47vh) scale(0.87);
  }

  9.9601593625% {
    transform: translateX(9vw) translateY(-42vh) scale(0.42);
  }

  10.3585657371% {
    transform: translateX(-18vw) translateY(-6vh) scale(0.78);
  }

  10.7569721116% {
    transform: translateX(-14vw) translateY(37vh) scale(0.5);
  }

  11.1553784861% {
    transform: translateX(30vw) translateY(-33vh) scale(0.72);
  }

  11.5537848606% {
    transform: translateX(46vw) translateY(36vh) scale(0.67);
  }

  11.9521912351% {
    transform: translateX(45vw) translateY(34vh) scale(0.91);
  }

  12.3505976096% {
    transform: translateX(9vw) translateY(41vh) scale(0.49);
  }

  12.7490039841% {
    transform: translateX(7vw) translateY(-17vh) scale(0.29);
  }

  13.1474103586% {
    transform: translateX(48vw) translateY(-6vh) scale(0.65);
  }

  13.5458167331% {
    transform: translateX(-4vw) translateY(28vh) scale(0.32);
  }

  13.9442231076% {
    transform: translateX(-41vw) translateY(-33vh) scale(0.47);
  }

  14.3426294821% {
    transform: translateX(-43vw) translateY(13vh) scale(0.49);
  }

  14.7410358566% {
    transform: translateX(39vw) translateY(19vh) scale(0.37);
  }

  15.1394422311% {
    transform: translateX(-10vw) translateY(-32vh) scale(0.83);
  }

  15.5378486056% {
    transform: translateX(-48vw) translateY(43vh) scale(0.71);
  }

  15.9362549801% {
    transform: translateX(35vw) translateY(-41vh) scale(0.9);
  }

  16.3346613546% {
    transform: translateX(24vw) translateY(-15vh) scale(0.36);
  }

  16.7330677291% {
    transform: translateX(8vw) translateY(21vh) scale(0.81);
  }

  17.1314741036% {
    transform: translateX(-37vw) translateY(-32vh) scale(0.87);
  }

  17.5298804781% {
    transform: translateX(12vw) translateY(-12vh) scale(0.63);
  }

  17.9282868526% {
    transform: translateX(11vw) translateY(-12vh) scale(0.74);
  }

  18.3266932271% {
    transform: translateX(-23vw) translateY(37vh) scale(0.6);
  }

  18.7250996016% {
    transform: translateX(-8vw) translateY(-5vh) scale(0.34);
  }

  19.1235059761% {
    transform: translateX(-5vw) translateY(31vh) scale(0.51);
  }

  19.5219123506% {
    transform: translateX(4vw) translateY(31vh) scale(0.9);
  }

  19.9203187251% {
    transform: translateX(12vw) translateY(-32vh) scale(0.52);
  }

  20.3187250996% {
    transform: translateX(-20vw) translateY(21vh) scale(0.98);
  }

  20.7171314741% {
    transform: translateX(-17vw) translateY(-13vh) scale(0.77);
  }

  21.1155378486% {
    transform: translateX(-8vw) translateY(-27vh) scale(0.35);
  }

  21.5139442231% {
    transform: translateX(29vw) translateY(8vh) scale(0.6);
  }

  21.9123505976% {
    transform: translateX(44vw) translateY(-36vh) scale(0.96);
  }

  22.3107569721% {
    transform: translateX(12vw) translateY(24vh) scale(0.38);
  }

  22.7091633466% {
    transform: translateX(46vw) translateY(7vh) scale(0.41);
  }

  23.1075697211% {
    transform: translateX(23vw) translateY(-39vh) scale(0.68);
  }

  23.5059760956% {
    transform: translateX(33vw) translateY(39vh) scale(0.63);
  }

  23.9043824701% {
    transform: translateX(5vw) translateY(-13vh) scale(0.28);
  }

  24.3027888446% {
    transform: translateX(45vw) translateY(-30vh) scale(0.38);
  }

  24.7011952191% {
    transform: translateX(8vw) translateY(-40vh) scale(0.51);
  }

  25.0996015936% {
    transform: translateX(29vw) translateY(23vh) scale(0.69);
  }

  25.4980079681% {
    transform: translateX(-33vw) translateY(49vh) scale(0.32);
  }

  25.8964143426% {
    transform: translateX(-47vw) translateY(41vh) scale(0.69);
  }

  26.2948207171% {
    transform: translateX(20vw) translateY(25vh) scale(0.66);
  }

  26.6932270916% {
    transform: translateX(28vw) translateY(1vh) scale(0.52);
  }

  27.0916334661% {
    transform: translateX(37vw) translateY(4vh) scale(0.95);
  }

  27.4900398406% {
    transform: translateX(43vw) translateY(14vh) scale(0.53);
  }

  27.8884462151% {
    transform: translateX(-2vw) translateY(0vh) scale(0.91);
  }

  28.2868525896% {
    transform: translateX(4vw) translateY(-18vh) scale(0.53);
  }

  28.6852589641% {
    transform: translateX(26vw) translateY(1vh) scale(0.71);
  }

  29.0836653386% {
    transform: translateX(-29vw) translateY(12vh) scale(0.36);
  }

  29.4820717131% {
    transform: translateX(-30vw) translateY(38vh) scale(0.75);
  }

  29.8804780876% {
    transform: translateX(40vw) translateY(49vh) scale(0.29);
  }

  30.2788844622% {
    transform: translateX(43vw) translateY(-45vh) scale(0.73);
  }

  30.6772908367% {
    transform: translateX(4vw) translateY(31vh) scale(0.47);
  }

  31.0756972112% {
    transform: translateX(7vw) translateY(-2vh) scale(0.7);
  }

  31.4741035857% {
    transform: translateX(-45vw) translateY(-3vh) scale(0.53);
  }

  31.8725099602% {
    transform: translateX(-7vw) translateY(9vh) scale(0.56);
  }

  32.2709163347% {
    transform: translateX(6vw) translateY(0vh) scale(0.35);
  }

  32.6693227092% {
    transform: translateX(29vw) translateY(16vh) scale(0.7);
  }

  33.0677290837% {
    transform: translateX(-33vw) translateY(-13vh) scale(0.74);
  }

  33.4661354582% {
    transform: translateX(-28vw) translateY(43vh) scale(0.85);
  }

  33.8645418327% {
    transform: translateX(-18vw) translateY(-14vh) scale(0.91);
  }

  34.2629482072% {
    transform: translateX(-41vw) translateY(42vh) scale(0.53);
  }

  34.6613545817% {
    transform: translateX(-24vw) translateY(20vh) scale(0.52);
  }

  35.0597609562% {
    transform: translateX(10vw) translateY(-14vh) scale(0.39);
  }

  35.4581673307% {
    transform: translateX(3vw) translateY(47vh) scale(0.95);
  }

  35.8565737052% {
    transform: translateX(20vw) translateY(47vh) scale(0.3);
  }

  36.2549800797% {
    transform: translateX(18vw) translateY(-7vh) scale(0.41);
  }

  36.6533864542% {
    transform: translateX(19vw) translateY(34vh) scale(0.83);
  }

  37.0517928287% {
    transform: translateX(-36vw) translateY(31vh) scale(0.82);
  }

  37.4501992032% {
    transform: translateX(-7vw) translateY(41vh) scale(0.79);
  }

  37.8486055777% {
    transform: translateX(18vw) translateY(28vh) scale(0.34);
  }

  38.2470119522% {
    transform: translateX(34vw) translateY(24vh) scale(0.96);
  }

  38.6454183267% {
    transform: translateX(-18vw) translateY(21vh) scale(0.93);
  }

  39.0438247012% {
    transform: translateX(37vw) translateY(7vh) scale(0.96);
  }

  39.4422310757% {
    transform: translateX(47vw) translateY(3vh) scale(0.6);
  }

  39.8406374502% {
    transform: translateX(38vw) translateY(28vh) scale(0.93);
  }

  40.2390438247% {
    transform: translateX(-42vw) translateY(-26vh) scale(0.94);
  }

  40.6374501992% {
    transform: translateX(-11vw) translateY(-34vh) scale(0.36);
  }

  41.0358565737% {
    transform: translateX(28vw) translateY(-22vh) scale(0.9);
  }

  41.4342629482% {
    transform: translateX(-6vw) translateY(2vh) scale(0.74);
  }

  41.8326693227% {
    transform: translateX(50vw) translateY(-49vh) scale(0.35);
  }

  42.2310756972% {
    transform: translateX(-44vw) translateY(-34vh) scale(0.26);
  }

  42.6294820717% {
    transform: translateX(-7vw) translateY(-30vh) scale(0.7);
  }

  43.0278884462% {
    transform: translateX(-47vw) translateY(-19vh) scale(0.45);
  }

  43.4262948207% {
    transform: translateX(-7vw) translateY(-12vh) scale(0.55);
  }

  43.8247011952% {
    transform: translateX(14vw) translateY(10vh) scale(0.93);
  }

  44.2231075697% {
    transform: translateX(28vw) translateY(-11vh) scale(0.92);
  }

  44.6215139442% {
    transform: translateX(-22vw) translateY(41vh) scale(0.26);
  }

  45.0199203187% {
    transform: translateX(8vw) translateY(-20vh) scale(0.89);
  }

  45.4183266932% {
    transform: translateX(-48vw) translateY(-37vh) scale(0.88);
  }

  45.8167330677% {
    transform: translateX(23vw) translateY(20vh) scale(0.76);
  }

  46.2151394422% {
    transform: translateX(4vw) translateY(-43vh) scale(0.37);
  }

  46.6135458167% {
    transform: translateX(29vw) translateY(-45vh) scale(0.68);
  }

  47.0119521912% {
    transform: translateX(-9vw) translateY(33vh) scale(0.86);
  }

  47.4103585657% {
    transform: translateX(8vw) translateY(7vh) scale(0.36);
  }

  47.8087649402% {
    transform: translateX(-23vw) translateY(-21vh) scale(0.3);
  }

  48.2071713147% {
    transform: translateX(-19vw) translateY(33vh) scale(0.57);
  }

  48.6055776892% {
    transform: translateX(50vw) translateY(-32vh) scale(0.4);
  }

  49.0039840637% {
    transform: translateX(-47vw) translateY(-1vh) scale(0.38);
  }

  49.4023904382% {
    transform: translateX(19vw) translateY(9vh) scale(0.68);
  }

  49.8007968127% {
    transform: translateX(31vw) translateY(-2vh) scale(0.71);
  }

  50.1992031873% {
    transform: translateX(-11vw) translateY(-7vh) scale(0.9);
  }

  50.5976095618% {
    transform: translateX(1vw) translateY(-37vh) scale(0.7);
  }

  50.9960159363% {
    transform: translateX(-8vw) translateY(47vh) scale(0.83);
  }

  51.3944223108% {
    transform: translateX(-36vw) translateY(47vh) scale(0.35);
  }

  51.7928286853% {
    transform: translateX(-17vw) translateY(-8vh) scale(0.55);
  }

  52.1912350598% {
    transform: translateX(-13vw) translateY(27vh) scale(0.65);
  }

  52.5896414343% {
    transform: translateX(-22vw) translateY(12vh) scale(0.86);
  }

  52.9880478088% {
    transform: translateX(-39vw) translateY(7vh) scale(0.84);
  }

  53.3864541833% {
    transform: translateX(-48vw) translateY(-24vh) scale(0.29);
  }

  53.7848605578% {
    transform: translateX(7vw) translateY(-8vh) scale(0.57);
  }

  54.1832669323% {
    transform: translateX(-8vw) translateY(-35vh) scale(0.44);
  }

  54.5816733068% {
    transform: translateX(-1vw) translateY(8vh) scale(0.57);
  }

  54.9800796813% {
    transform: translateX(48vw) translateY(-41vh) scale(0.99);
  }

  55.3784860558% {
    transform: translateX(2vw) translateY(-2vh) scale(0.93);
  }

  55.7768924303% {
    transform: translateX(2vw) translateY(6vh) scale(0.77);
  }

  56.1752988048% {
    transform: translateX(12vw) translateY(-39vh) scale(0.5);
  }

  56.5737051793% {
    transform: translateX(-28vw) translateY(-39vh) scale(0.67);
  }

  56.9721115538% {
    transform: translateX(-14vw) translateY(-44vh) scale(0.5);
  }

  57.3705179283% {
    transform: translateX(37vw) translateY(12vh) scale(0.48);
  }

  57.7689243028% {
    transform: translateX(30vw) translateY(-1vh) scale(0.67);
  }

  58.1673306773% {
    transform: translateX(-19vw) translateY(17vh) scale(0.65);
  }

  58.5657370518% {
    transform: translateX(-14vw) translateY(15vh) scale(0.62);
  }

  58.9641434263% {
    transform: translateX(34vw) translateY(-27vh) scale(0.94);
  }

  59.3625498008% {
    transform: translateX(-49vw) translateY(-44vh) scale(0.27);
  }

  59.7609561753% {
    transform: translateX(34vw) translateY(-11vh) scale(0.39);
  }

  60.1593625498% {
    transform: translateX(-39vw) translateY(-4vh) scale(0.85);
  }

  60.5577689243% {
    transform: translateX(20vw) translateY(27vh) scale(1);
  }

  60.9561752988% {
    transform: translateX(32vw) translateY(6vh) scale(0.82);
  }

  61.3545816733% {
    transform: translateX(-47vw) translateY(47vh) scale(0.56);
  }

  61.7529880478% {
    transform: translateX(-6vw) translateY(4vh) scale(0.39);
  }

  62.1513944223% {
    transform: translateX(-17vw) translateY(50vh) scale(0.32);
  }

  62.5498007968% {
    transform: translateX(3vw) translateY(40vh) scale(0.99);
  }

  62.9482071713% {
    transform: translateX(-11vw) translateY(4vh) scale(0.89);
  }

  63.3466135458% {
    transform: translateX(-41vw) translateY(20vh) scale(0.77);
  }

  63.7450199203% {
    transform: translateX(31vw) translateY(28vh) scale(0.76);
  }

  64.1434262948% {
    transform: translateX(29vw) translateY(24vh) scale(0.28);
  }

  64.5418326693% {
    transform: translateX(-18vw) translateY(-8vh) scale(0.99);
  }

  64.9402390438% {
    transform: translateX(-21vw) translateY(32vh) scale(0.61);
  }

  65.3386454183% {
    transform: translateX(43vw) translateY(-28vh) scale(0.32);
  }

  65.7370517928% {
    transform: translateX(-3vw) translateY(39vh) scale(0.66);
  }

  66.1354581673% {
    transform: translateX(-18vw) translateY(43vh) scale(0.44);
  }

  66.5338645418% {
    transform: translateX(-34vw) translateY(-27vh) scale(0.71);
  }

  66.9322709163% {
    transform: translateX(22vw) translateY(-3vh) scale(0.82);
  }

  67.3306772908% {
    transform: translateX(-26vw) translateY(19vh) scale(0.36);
  }

  67.7290836653% {
    transform: translateX(-31vw) translateY(-45vh) scale(0.76);
  }

  68.1274900398% {
    transform: translateX(-40vw) translateY(-23vh) scale(0.69);
  }

  68.5258964143% {
    transform: translateX(-35vw) translateY(30vh) scale(0.9);
  }

  68.9243027888% {
    transform: translateX(-17vw) translateY(3vh) scale(0.53);
  }

  69.3227091633% {
    transform: translateX(-38vw) translateY(-4vh) scale(0.82);
  }

  69.7211155378% {
    transform: translateX(-14vw) translateY(2vh) scale(0.41);
  }

  70.1195219124% {
    transform: translateX(0vw) translateY(17vh) scale(0.48);
  }

  70.5179282869% {
    transform: translateX(-13vw) translateY(-15vh) scale(0.71);
  }

  70.9163346614% {
    transform: translateX(-42vw) translateY(-14vh) scale(0.69);
  }

  71.3147410359% {
    transform: translateX(-32vw) translateY(40vh) scale(0.39);
  }

  71.7131474104% {
    transform: translateX(-20vw) translateY(-23vh) scale(0.47);
  }

  72.1115537849% {
    transform: translateX(-8vw) translateY(-17vh) scale(0.99);
  }

  72.5099601594% {
    transform: translateX(25vw) translateY(1vh) scale(0.63);
  }

  72.9083665339% {
    transform: translateX(-22vw) translateY(-24vh) scale(0.32);
  }

  73.3067729084% {
    transform: translateX(-2vw) translateY(9vh) scale(0.7);
  }

  73.7051792829% {
    transform: translateX(-36vw) translateY(40vh) scale(0.26);
  }

  74.1035856574% {
    transform: translateX(11vw) translateY(47vh) scale(0.81);
  }

  74.5019920319% {
    transform: translateX(36vw) translateY(29vh) scale(0.96);
  }

  74.9003984064% {
    transform: translateX(43vw) translateY(7vh) scale(0.67);
  }

  75.2988047809% {
    transform: translateX(23vw) translateY(24vh) scale(0.61);
  }

  75.6972111554% {
    transform: translateX(4vw) translateY(11vh) scale(0.37);
  }

  76.0956175299% {
    transform: translateX(-28vw) translateY(27vh) scale(0.97);
  }

  76.4940239044% {
    transform: translateX(0vw) translateY(-26vh) scale(0.9);
  }

  76.8924302789% {
    transform: translateX(46vw) translateY(18vh) scale(0.53);
  }

  77.2908366534% {
    transform: translateX(47vw) translateY(32vh) scale(0.6);
  }

  77.6892430279% {
    transform: translateX(12vw) translateY(-48vh) scale(0.63);
  }

  78.0876494024% {
    transform: translateX(32vw) translateY(-28vh) scale(0.83);
  }

  78.4860557769% {
    transform: translateX(-26vw) translateY(30vh) scale(0.68);
  }

  78.8844621514% {
    transform: translateX(37vw) translateY(-38vh) scale(0.65);
  }

  79.2828685259% {
    transform: translateX(42vw) translateY(9vh) scale(0.28);
  }

  79.6812749004% {
    transform: translateX(-12vw) translateY(-25vh) scale(0.85);
  }

  80.0796812749% {
    transform: translateX(12vw) translateY(-8vh) scale(0.85);
  }

  80.4780876494% {
    transform: translateX(27vw) translateY(-30vh) scale(0.73);
  }

  80.8764940239% {
    transform: translateX(-2vw) translateY(-8vh) scale(0.37);
  }

  81.2749003984% {
    transform: translateX(39vw) translateY(-47vh) scale(0.52);
  }

  81.6733067729% {
    transform: translateX(18vw) translateY(13vh) scale(0.43);
  }

  82.0717131474% {
    transform: translateX(44vw) translateY(29vh) scale(0.88);
  }

  82.4701195219% {
    transform: translateX(38vw) translateY(39vh) scale(0.34);
  }

  82.8685258964% {
    transform: translateX(6vw) translateY(6vh) scale(0.84);
  }

  83.2669322709% {
    transform: translateX(-47vw) translateY(-13vh) scale(0.27);
  }

  83.6653386454% {
    transform: translateX(21vw) translateY(-25vh) scale(0.49);
  }

  84.0637450199% {
    transform: translateX(-43vw) translateY(39vh) scale(0.37);
  }

  84.4621513944% {
    transform: translateX(-34vw) translateY(-46vh) scale(0.29);
  }

  84.8605577689% {
    transform: translateX(50vw) translateY(-15vh) scale(0.27);
  }

  85.2589641434% {
    transform: translateX(-7vw) translateY(-36vh) scale(0.27);
  }

  85.6573705179% {
    transform: translateX(-49vw) translateY(-25vh) scale(0.28);
  }

  86.0557768924% {
    transform: translateX(-36vw) translateY(9vh) scale(0.59);
  }

  86.4541832669% {
    transform: translateX(37vw) translateY(-20vh) scale(0.57);
  }

  86.8525896414% {
    transform: translateX(16vw) translateY(-22vh) scale(0.56);
  }

  87.2509960159% {
    transform: translateX(7vw) translateY(-6vh) scale(0.65);
  }

  87.6494023904% {
    transform: translateX(-1vw) translateY(44vh) scale(1);
  }

  88.0478087649% {
    transform: translateX(-18vw) translateY(28vh) scale(0.9);
  }

  88.4462151394% {
    transform: translateX(-26vw) translateY(18vh) scale(0.92);
  }

  88.8446215139% {
    transform: translateX(-25vw) translateY(-47vh) scale(0.58);
  }

  89.2430278884% {
    transform: translateX(-47vw) translateY(7vh) scale(0.72);
  }

  89.6414342629% {
    transform: translateX(-20vw) translateY(-11vh) scale(0.62);
  }

  90.0398406375% {
    transform: translateX(-3vw) translateY(9vh) scale(0.97);
  }

  90.438247012% {
    transform: translateX(-32vw) translateY(21vh) scale(0.54);
  }

  90.8366533865% {
    transform: translateX(-30vw) translateY(-33vh) scale(0.56);
  }

  91.235059761% {
    transform: translateX(-7vw) translateY(42vh) scale(0.62);
  }

  91.6334661355% {
    transform: translateX(-1vw) translateY(-41vh) scale(0.41);
  }

  92.03187251% {
    transform: translateX(-35vw) translateY(31vh) scale(0.79);
  }

  92.4302788845% {
    transform: translateX(37vw) translateY(-9vh) scale(0.52);
  }

  92.828685259% {
    transform: translateX(35vw) translateY(46vh) scale(0.46);
  }

  93.2270916335% {
    transform: translateX(22vw) translateY(43vh) scale(0.8);
  }

  93.625498008% {
    transform: translateX(-43vw) translateY(-24vh) scale(0.5);
  }

  94.0239043825% {
    transform: translateX(3vw) translateY(-43vh) scale(0.98);
  }

  94.422310757% {
    transform: translateX(32vw) translateY(-44vh) scale(0.78);
  }

  94.8207171315% {
    transform: translateX(-4vw) translateY(-38vh) scale(0.54);
  }

  95.219123506% {
    transform: translateX(-22vw) translateY(-41vh) scale(0.96);
  }

  95.6175298805% {
    transform: translateX(20vw) translateY(-38vh) scale(0.69);
  }

  96.015936255% {
    transform: translateX(-2vw) translateY(1vh) scale(0.52);
  }

  96.4143426295% {
    transform: translateX(2vw) translateY(11vh) scale(0.99);
  }

  96.812749004% {
    transform: translateX(-47vw) translateY(41vh) scale(1);
  }

  97.2111553785% {
    transform: translateX(-25vw) translateY(-6vh) scale(0.43);
  }

  97.609561753% {
    transform: translateX(-34vw) translateY(25vh) scale(0.9);
  }

  98.0079681275% {
    transform: translateX(35vw) translateY(49vh) scale(0.98);
  }

  98.406374502% {
    transform: translateX(-28vw) translateY(49vh) scale(1);
  }

  98.8047808765% {
    transform: translateX(1vw) translateY(-32vh) scale(0.69);
  }

  99.203187251% {
    transform: translateX(-31vw) translateY(-36vh) scale(0.44);
  }

  99.6015936255% {
    transform: translateX(44vw) translateY(12vh) scale(0.46);
  }

  100% {
    transform: translateX(45vw) translateY(46vh) scale(0.85);
  }
}

.firefly:nth-child(8) {
  animation-name: move8;
}

.firefly:nth-child(8)::before {
  animation-duration: 14s;
}

.firefly:nth-child(8)::after {
  animation-duration: 14s, 10357ms;
  animation-delay: 0ms, 3914ms;
}

@keyframes move8 {
  0% {
    transform: translateX(32vw) translateY(35vh) scale(0.38);
  }

  0.36900369% {
    transform: translateX(-29vw) translateY(-49vh) scale(0.93);
  }

  0.7380073801% {
    transform: translateX(-20vw) translateY(32vh) scale(0.72);
  }

  1.1070110701% {
    transform: translateX(25vw) translateY(5vh) scale(0.97);
  }

  1.4760147601% {
    transform: translateX(-39vw) translateY(44vh) scale(1);
  }

  1.8450184502% {
    transform: translateX(17vw) translateY(-32vh) scale(0.46);
  }

  2.2140221402% {
    transform: translateX(-12vw) translateY(-15vh) scale(0.42);
  }

  2.5830258303% {
    transform: translateX(-13vw) translateY(33vh) scale(0.38);
  }

  2.9520295203% {
    transform: translateX(5vw) translateY(-6vh) scale(0.74);
  }

  3.3210332103% {
    transform: translateX(-20vw) translateY(48vh) scale(0.95);
  }

  3.6900369004% {
    transform: translateX(38vw) translateY(-30vh) scale(0.9);
  }

  4.0590405904% {
    transform: translateX(20vw) translateY(-22vh) scale(0.63);
  }

  4.4280442804% {
    transform: translateX(-37vw) translateY(40vh) scale(0.56);
  }

  4.7970479705% {
    transform: translateX(-26vw) translateY(-43vh) scale(0.55);
  }

  5.1660516605% {
    transform: translateX(-6vw) translateY(48vh) scale(0.64);
  }

  5.5350553506% {
    transform: translateX(27vw) translateY(42vh) scale(0.66);
  }

  5.9040590406% {
    transform: translateX(3vw) translateY(-43vh) scale(0.42);
  }

  6.2730627306% {
    transform: translateX(-38vw) translateY(6vh) scale(0.76);
  }

  6.6420664207% {
    transform: translateX(-46vw) translateY(-4vh) scale(0.64);
  }

  7.0110701107% {
    transform: translateX(-18vw) translateY(-24vh) scale(0.31);
  }

  7.3800738007% {
    transform: translateX(-36vw) translateY(-48vh) scale(0.63);
  }

  7.7490774908% {
    transform: translateX(45vw) translateY(-35vh) scale(0.71);
  }

  8.1180811808% {
    transform: translateX(-21vw) translateY(-7vh) scale(0.7);
  }

  8.4870848708% {
    transform: translateX(-46vw) translateY(-27vh) scale(0.4);
  }

  8.8560885609% {
    transform: translateX(14vw) translateY(-45vh) scale(0.88);
  }

  9.2250922509% {
    transform: translateX(-10vw) translateY(-41vh) scale(0.8);
  }

  9.594095941% {
    transform: translateX(-38vw) translateY(-23vh) scale(0.9);
  }

  9.963099631% {
    transform: translateX(29vw) translateY(-20vh) scale(0.67);
  }

  10.332103321% {
    transform: translateX(46vw) translateY(-35vh) scale(0.83);
  }

  10.7011070111% {
    transform: translateX(-14vw) translateY(-39vh) scale(0.6);
  }

  11.0701107011% {
    transform: translateX(17vw) translateY(-49vh) scale(0.73);
  }

  11.4391143911% {
    transform: translateX(39vw) translateY(22vh) scale(0.7);
  }

  11.8081180812% {
    transform: translateX(-5vw) translateY(14vh) scale(0.45);
  }

  12.1771217712% {
    transform: translateX(-37vw) translateY(-43vh) scale(0.29);
  }

  12.5461254613% {
    transform: translateX(-48vw) translateY(11vh) scale(0.34);
  }

  12.9151291513% {
    transform: translateX(8vw) translateY(11vh) scale(0.87);
  }

  13.2841328413% {
    transform: translateX(-44vw) translateY(7vh) scale(0.29);
  }

  13.6531365314% {
    transform: translateX(9vw) translateY(44vh) scale(0.48);
  }

  14.0221402214% {
    transform: translateX(46vw) translateY(46vh) scale(0.61);
  }

  14.3911439114% {
    transform: translateX(-2vw) translateY(-5vh) scale(0.88);
  }

  14.7601476015% {
    transform: translateX(-1vw) translateY(-13vh) scale(0.38);
  }

  15.1291512915% {
    transform: translateX(27vw) translateY(-44vh) scale(0.78);
  }

  15.4981549815% {
    transform: translateX(23vw) translateY(34vh) scale(0.91);
  }

  15.8671586716% {
    transform: translateX(12vw) translateY(35vh) scale(0.65);
  }

  16.2361623616% {
    transform: translateX(44vw) translateY(33vh) scale(0.85);
  }

  16.6051660517% {
    transform: translateX(5vw) translateY(-1vh) scale(0.61);
  }

  16.9741697417% {
    transform: translateX(-7vw) translateY(38vh) scale(0.4);
  }

  17.3431734317% {
    transform: translateX(-4vw) translateY(-11vh) scale(0.4);
  }

  17.7121771218% {
    transform: translateX(8vw) translateY(-47vh) scale(0.7);
  }

  18.0811808118% {
    transform: translateX(-14vw) translateY(38vh) scale(0.99);
  }

  18.4501845018% {
    transform: translateX(11vw) translateY(-7vh) scale(0.75);
  }

  18.8191881919% {
    transform: translateX(-13vw) translateY(-9vh) scale(0.86);
  }

  19.1881918819% {
    transform: translateX(-37vw) translateY(19vh) scale(0.53);
  }

  19.557195572% {
    transform: translateX(19vw) translateY(10vh) scale(0.88);
  }

  19.926199262% {
    transform: translateX(24vw) translateY(-49vh) scale(0.49);
  }

  20.295202952% {
    transform: translateX(-43vw) translateY(-40vh) scale(0.94);
  }

  20.6642066421% {
    transform: translateX(-25vw) translateY(14vh) scale(0.46);
  }

  21.0332103321% {
    transform: translateX(43vw) translateY(-21vh) scale(0.68);
  }

  21.4022140221% {
    transform: translateX(-25vw) translateY(11vh) scale(0.51);
  }

  21.7712177122% {
    transform: translateX(7vw) translateY(-22vh) scale(0.41);
  }

  22.1402214022% {
    transform: translateX(35vw) translateY(-21vh) scale(0.28);
  }

  22.5092250923% {
    transform: translateX(-23vw) translateY(24vh) scale(0.47);
  }

  22.8782287823% {
    transform: translateX(-23vw) translateY(-21vh) scale(0.4);
  }

  23.2472324723% {
    transform: translateX(-23vw) translateY(7vh) scale(0.56);
  }

  23.6162361624% {
    transform: translateX(8vw) translateY(-49vh) scale(0.58);
  }

  23.9852398524% {
    transform: translateX(-32vw) translateY(-29vh) scale(0.66);
  }

  24.3542435424% {
    transform: translateX(13vw) translateY(-25vh) scale(0.99);
  }

  24.7232472325% {
    transform: translateX(38vw) translateY(-23vh) scale(0.52);
  }

  25.0922509225% {
    transform: translateX(-29vw) translateY(-46vh) scale(0.59);
  }

  25.4612546125% {
    transform: translateX(9vw) translateY(-34vh) scale(0.96);
  }

  25.8302583026% {
    transform: translateX(18vw) translateY(-33vh) scale(0.26);
  }

  26.1992619926% {
    transform: translateX(-13vw) translateY(2vh) scale(0.61);
  }

  26.5682656827% {
    transform: translateX(-13vw) translateY(31vh) scale(0.93);
  }

  26.9372693727% {
    transform: translateX(42vw) translateY(14vh) scale(0.95);
  }

  27.3062730627% {
    transform: translateX(-27vw) translateY(32vh) scale(0.61);
  }

  27.6752767528% {
    transform: translateX(30vw) translateY(40vh) scale(0.82);
  }

  28.0442804428% {
    transform: translateX(33vw) translateY(20vh) scale(0.48);
  }

  28.4132841328% {
    transform: translateX(43vw) translateY(-20vh) scale(0.88);
  }

  28.7822878229% {
    transform: translateX(28vw) translateY(41vh) scale(0.38);
  }

  29.1512915129% {
    transform: translateX(11vw) translateY(12vh) scale(0.81);
  }

  29.520295203% {
    transform: translateX(13vw) translateY(-40vh) scale(0.94);
  }

  29.889298893% {
    transform: translateX(29vw) translateY(-30vh) scale(0.54);
  }

  30.258302583% {
    transform: translateX(36vw) translateY(35vh) scale(0.75);
  }

  30.6273062731% {
    transform: translateX(2vw) translateY(46vh) scale(0.68);
  }

  30.9963099631% {
    transform: translateX(-26vw) translateY(18vh) scale(0.34);
  }

  31.3653136531% {
    transform: translateX(36vw) translateY(-24vh) scale(0.32);
  }

  31.7343173432% {
    transform: translateX(34vw) translateY(33vh) scale(0.45);
  }

  32.1033210332% {
    transform: translateX(10vw) translateY(-48vh) scale(0.43);
  }

  32.4723247232% {
    transform: translateX(11vw) translateY(37vh) scale(0.93);
  }

  32.8413284133% {
    transform: translateX(-41vw) translateY(-35vh) scale(0.91);
  }

  33.2103321033% {
    transform: translateX(-41vw) translateY(-5vh) scale(0.56);
  }

  33.5793357934% {
    transform: translateX(17vw) translateY(1vh) scale(0.66);
  }

  33.9483394834% {
    transform: translateX(-45vw) translateY(-34vh) scale(0.3);
  }

  34.3173431734% {
    transform: translateX(-10vw) translateY(-26vh) scale(0.74);
  }

  34.6863468635% {
    transform: translateX(-14vw) translateY(18vh) scale(0.54);
  }

  35.0553505535% {
    transform: translateX(28vw) translateY(-7vh) scale(0.66);
  }

  35.4243542435% {
    transform: translateX(-36vw) translateY(-41vh) scale(0.79);
  }

  35.7933579336% {
    transform: translateX(29vw) translateY(35vh) scale(0.99);
  }

  36.1623616236% {
    transform: translateX(-29vw) translateY(24vh) scale(0.72);
  }

  36.5313653137% {
    transform: translateX(-32vw) translateY(-8vh) scale(0.46);
  }

  36.9003690037% {
    transform: translateX(-30vw) translateY(26vh) scale(0.48);
  }

  37.2693726937% {
    transform: translateX(23vw) translateY(31vh) scale(0.66);
  }

  37.6383763838% {
    transform: translateX(-7vw) translateY(-36vh) scale(0.52);
  }

  38.0073800738% {
    transform: translateX(-12vw) translateY(-34vh) scale(0.63);
  }

  38.3763837638% {
    transform: translateX(1vw) translateY(-9vh) scale(0.34);
  }

  38.7453874539% {
    transform: translateX(24vw) translateY(-44vh) scale(0.57);
  }

  39.1143911439% {
    transform: translateX(-47vw) translateY(-24vh) scale(0.61);
  }

  39.4833948339% {
    transform: translateX(50vw) translateY(21vh) scale(0.56);
  }

  39.852398524% {
    transform: translateX(30vw) translateY(23vh) scale(0.44);
  }

  40.221402214% {
    transform: translateX(48vw) translateY(48vh) scale(0.56);
  }

  40.5904059041% {
    transform: translateX(-8vw) translateY(-44vh) scale(0.41);
  }

  40.9594095941% {
    transform: translateX(-37vw) translateY(42vh) scale(0.98);
  }

  41.3284132841% {
    transform: translateX(-16vw) translateY(7vh) scale(0.82);
  }

  41.6974169742% {
    transform: translateX(47vw) translateY(-9vh) scale(0.9);
  }

  42.0664206642% {
    transform: translateX(47vw) translateY(13vh) scale(0.88);
  }

  42.4354243542% {
    transform: translateX(-37vw) translateY(15vh) scale(0.82);
  }

  42.8044280443% {
    transform: translateX(-14vw) translateY(40vh) scale(0.5);
  }

  43.1734317343% {
    transform: translateX(-27vw) translateY(11vh) scale(0.51);
  }

  43.5424354244% {
    transform: translateX(-27vw) translateY(4vh) scale(0.69);
  }

  43.9114391144% {
    transform: translateX(-19vw) translateY(-42vh) scale(1);
  }

  44.2804428044% {
    transform: translateX(-26vw) translateY(48vh) scale(0.92);
  }

  44.6494464945% {
    transform: translateX(-33vw) translateY(4vh) scale(0.57);
  }

  45.0184501845% {
    transform: translateX(-16vw) translateY(11vh) scale(0.71);
  }

  45.3874538745% {
    transform: translateX(-2vw) translateY(-35vh) scale(0.28);
  }

  45.7564575646% {
    transform: translateX(-18vw) translateY(37vh) scale(0.41);
  }

  46.1254612546% {
    transform: translateX(6vw) translateY(-48vh) scale(0.81);
  }

  46.4944649446% {
    transform: translateX(-49vw) translateY(34vh) scale(0.4);
  }

  46.8634686347% {
    transform: translateX(35vw) translateY(28vh) scale(0.76);
  }

  47.2324723247% {
    transform: translateX(10vw) translateY(43vh) scale(0.88);
  }

  47.6014760148% {
    transform: translateX(2vw) translateY(-38vh) scale(0.41);
  }

  47.9704797048% {
    transform: translateX(-33vw) translateY(-12vh) scale(0.92);
  }

  48.3394833948% {
    transform: translateX(-10vw) translateY(-43vh) scale(0.83);
  }

  48.7084870849% {
    transform: translateX(-39vw) translateY(-34vh) scale(0.61);
  }

  49.0774907749% {
    transform: translateX(-10vw) translateY(-13vh) scale(0.83);
  }

  49.4464944649% {
    transform: translateX(26vw) translateY(41vh) scale(0.88);
  }

  49.815498155% {
    transform: translateX(42vw) translateY(43vh) scale(0.98);
  }

  50.184501845% {
    transform: translateX(27vw) translateY(-13vh) scale(0.95);
  }

  50.5535055351% {
    transform: translateX(44vw) translateY(-46vh) scale(0.89);
  }

  50.9225092251% {
    transform: translateX(1vw) translateY(26vh) scale(0.75);
  }

  51.2915129151% {
    transform: translateX(-2vw) translateY(-8vh) scale(0.71);
  }

  51.6605166052% {
    transform: translateX(-44vw) translateY(-30vh) scale(0.29);
  }

  52.0295202952% {
    transform: translateX(-2vw) translateY(0vh) scale(0.79);
  }

  52.3985239852% {
    transform: translateX(-21vw) translateY(-26vh) scale(0.84);
  }

  52.7675276753% {
    transform: translateX(-12vw) translateY(-24vh) scale(0.8);
  }

  53.1365313653% {
    transform: translateX(-45vw) translateY(-42vh) scale(0.93);
  }

  53.5055350554% {
    transform: translateX(19vw) translateY(33vh) scale(0.32);
  }

  53.8745387454% {
    transform: translateX(-9vw) translateY(9vh) scale(0.42);
  }

  54.2435424354% {
    transform: translateX(-15vw) translateY(-20vh) scale(0.6);
  }

  54.6125461255% {
    transform: translateX(-23vw) translateY(49vh) scale(0.83);
  }

  54.9815498155% {
    transform: translateX(-38vw) translateY(2vh) scale(0.34);
  }

  55.3505535055% {
    transform: translateX(-15vw) translateY(-14vh) scale(0.44);
  }

  55.7195571956% {
    transform: translateX(16vw) translateY(18vh) scale(0.7);
  }

  56.0885608856% {
    transform: translateX(12vw) translateY(9vh) scale(0.27);
  }

  56.4575645756% {
    transform: translateX(-10vw) translateY(42vh) scale(0.78);
  }

  56.8265682657% {
    transform: translateX(-22vw) translateY(29vh) scale(0.89);
  }

  57.1955719557% {
    transform: translateX(-10vw) translateY(-35vh) scale(0.53);
  }

  57.5645756458% {
    transform: translateX(-6vw) translateY(-1vh) scale(0.73);
  }

  57.9335793358% {
    transform: translateX(17vw) translateY(-13vh) scale(0.71);
  }

  58.3025830258% {
    transform: translateX(-20vw) translateY(40vh) scale(0.39);
  }

  58.6715867159% {
    transform: translateX(14vw) translateY(-7vh) scale(0.84);
  }

  59.0405904059% {
    transform: translateX(-16vw) translateY(6vh) scale(0.54);
  }

  59.4095940959% {
    transform: translateX(-30vw) translateY(-12vh) scale(0.63);
  }

  59.778597786% {
    transform: translateX(-4vw) translateY(-48vh) scale(0.94);
  }

  60.147601476% {
    transform: translateX(7vw) translateY(11vh) scale(0.27);
  }

  60.5166051661% {
    transform: translateX(-13vw) translateY(11vh) scale(0.66);
  }

  60.8856088561% {
    transform: translateX(15vw) translateY(22vh) scale(0.71);
  }

  61.2546125461% {
    transform: translateX(12vw) translateY(50vh) scale(0.42);
  }

  61.6236162362% {
    transform: translateX(-10vw) translateY(3vh) scale(0.53);
  }

  61.9926199262% {
    transform: translateX(2vw) translateY(1vh) scale(0.54);
  }

  62.3616236162% {
    transform: translateX(47vw) translateY(-8vh) scale(0.65);
  }

  62.7306273063% {
    transform: translateX(39vw) translateY(44vh) scale(0.41);
  }

  63.0996309963% {
    transform: translateX(32vw) translateY(-23vh) scale(0.33);
  }

  63.4686346863% {
    transform: translateX(-45vw) translateY(12vh) scale(0.74);
  }

  63.8376383764% {
    transform: translateX(-36vw) translateY(-29vh) scale(0.89);
  }

  64.2066420664% {
    transform: translateX(29vw) translateY(0vh) scale(0.9);
  }

  64.5756457565% {
    transform: translateX(-17vw) translateY(2vh) scale(0.9);
  }

  64.9446494465% {
    transform: translateX(-26vw) translateY(0vh) scale(0.98);
  }

  65.3136531365% {
    transform: translateX(-6vw) translateY(26vh) scale(0.45);
  }

  65.6826568266% {
    transform: translateX(12vw) translateY(-49vh) scale(0.57);
  }

  66.0516605166% {
    transform: translateX(23vw) translateY(11vh) scale(0.49);
  }

  66.4206642066% {
    transform: translateX(-35vw) translateY(-13vh) scale(0.68);
  }

  66.7896678967% {
    transform: translateX(11vw) translateY(-49vh) scale(0.95);
  }

  67.1586715867% {
    transform: translateX(5vw) translateY(-29vh) scale(0.61);
  }

  67.5276752768% {
    transform: translateX(-38vw) translateY(-16vh) scale(0.52);
  }

  67.8966789668% {
    transform: translateX(-37vw) translateY(36vh) scale(0.96);
  }

  68.2656826568% {
    transform: translateX(0vw) translateY(-9vh) scale(0.91);
  }

  68.6346863469% {
    transform: translateX(2vw) translateY(21vh) scale(0.42);
  }

  69.0036900369% {
    transform: translateX(16vw) translateY(3vh) scale(0.92);
  }

  69.3726937269% {
    transform: translateX(-29vw) translateY(-1vh) scale(0.81);
  }

  69.741697417% {
    transform: translateX(-8vw) translateY(0vh) scale(0.62);
  }

  70.110701107% {
    transform: translateX(44vw) translateY(-13vh) scale(0.35);
  }

  70.479704797% {
    transform: translateX(-27vw) translateY(18vh) scale(0.94);
  }

  70.8487084871% {
    transform: translateX(46vw) translateY(-38vh) scale(0.47);
  }

  71.2177121771% {
    transform: translateX(-1vw) translateY(21vh) scale(0.86);
  }

  71.5867158672% {
    transform: translateX(-42vw) translateY(23vh) scale(0.71);
  }

  71.9557195572% {
    transform: translateX(-41vw) translateY(-12vh) scale(0.38);
  }

  72.3247232472% {
    transform: translateX(-26vw) translateY(-12vh) scale(0.56);
  }

  72.6937269373% {
    transform: translateX(15vw) translateY(15vh) scale(0.67);
  }

  73.0627306273% {
    transform: translateX(29vw) translateY(-16vh) scale(0.55);
  }

  73.4317343173% {
    transform: translateX(-49vw) translateY(43vh) scale(0.78);
  }

  73.8007380074% {
    transform: translateX(-31vw) translateY(-4vh) scale(0.43);
  }

  74.1697416974% {
    transform: translateX(6vw) translateY(-21vh) scale(0.91);
  }

  74.5387453875% {
    transform: translateX(-46vw) translateY(-39vh) scale(0.71);
  }

  74.9077490775% {
    transform: translateX(30vw) translateY(45vh) scale(0.66);
  }

  75.2767527675% {
    transform: translateX(-35vw) translateY(-25vh) scale(0.72);
  }

  75.6457564576% {
    transform: translateX(-40vw) translateY(-22vh) scale(0.83);
  }

  76.0147601476% {
    transform: translateX(18vw) translateY(44vh) scale(0.94);
  }

  76.3837638376% {
    transform: translateX(0vw) translateY(16vh) scale(0.93);
  }

  76.7527675277% {
    transform: translateX(-4vw) translateY(39vh) scale(0.33);
  }

  77.1217712177% {
    transform: translateX(-37vw) translateY(-10vh) scale(0.97);
  }

  77.4907749077% {
    transform: translateX(-6vw) translateY(-14vh) scale(0.94);
  }

  77.8597785978% {
    transform: translateX(-32vw) translateY(46vh) scale(0.5);
  }

  78.2287822878% {
    transform: translateX(22vw) translateY(48vh) scale(0.88);
  }

  78.5977859779% {
    transform: translateX(48vw) translateY(-17vh) scale(0.62);
  }

  78.9667896679% {
    transform: translateX(-45vw) translateY(18vh) scale(0.38);
  }

  79.3357933579% {
    transform: translateX(-19vw) translateY(-7vh) scale(0.6);
  }

  79.704797048% {
    transform: translateX(8vw) translateY(-19vh) scale(0.68);
  }

  80.073800738% {
    transform: translateX(26vw) translateY(-8vh) scale(0.62);
  }

  80.442804428% {
    transform: translateX(-24vw) translateY(2vh) scale(0.75);
  }

  80.8118081181% {
    transform: translateX(6vw) translateY(-21vh) scale(0.41);
  }

  81.1808118081% {
    transform: translateX(0vw) translateY(25vh) scale(0.36);
  }

  81.5498154982% {
    transform: translateX(46vw) translateY(32vh) scale(0.73);
  }

  81.9188191882% {
    transform: translateX(23vw) translateY(19vh) scale(0.29);
  }

  82.2878228782% {
    transform: translateX(36vw) translateY(-36vh) scale(0.58);
  }

  82.6568265683% {
    transform: translateX(-2vw) translateY(-11vh) scale(0.86);
  }

  83.0258302583% {
    transform: translateX(-3vw) translateY(11vh) scale(0.55);
  }

  83.3948339483% {
    transform: translateX(-39vw) translateY(42vh) scale(0.33);
  }

  83.7638376384% {
    transform: translateX(30vw) translateY(32vh) scale(0.36);
  }

  84.1328413284% {
    transform: translateX(33vw) translateY(-33vh) scale(0.86);
  }

  84.5018450185% {
    transform: translateX(14vw) translateY(-9vh) scale(0.43);
  }

  84.8708487085% {
    transform: translateX(35vw) translateY(-10vh) scale(0.84);
  }

  85.2398523985% {
    transform: translateX(22vw) translateY(7vh) scale(0.32);
  }

  85.6088560886% {
    transform: translateX(-26vw) translateY(24vh) scale(0.36);
  }

  85.9778597786% {
    transform: translateX(-47vw) translateY(-48vh) scale(0.95);
  }

  86.3468634686% {
    transform: translateX(45vw) translateY(-25vh) scale(0.72);
  }

  86.7158671587% {
    transform: translateX(-2vw) translateY(-22vh) scale(1);
  }

  87.0848708487% {
    transform: translateX(18vw) translateY(-9vh) scale(0.9);
  }

  87.4538745387% {
    transform: translateX(45vw) translateY(-29vh) scale(0.31);
  }

  87.8228782288% {
    transform: translateX(17vw) translateY(29vh) scale(0.82);
  }

  88.1918819188% {
    transform: translateX(-6vw) translateY(23vh) scale(0.87);
  }

  88.5608856089% {
    transform: translateX(-20vw) translateY(-48vh) scale(0.71);
  }

  88.9298892989% {
    transform: translateX(-21vw) translateY(26vh) scale(0.26);
  }

  89.2988929889% {
    transform: translateX(30vw) translateY(-1vh) scale(0.93);
  }

  89.667896679% {
    transform: translateX(-20vw) translateY(-9vh) scale(0.59);
  }

  90.036900369% {
    transform: translateX(-29vw) translateY(38vh) scale(0.83);
  }

  90.405904059% {
    transform: translateX(1vw) translateY(-46vh) scale(0.82);
  }

  90.7749077491% {
    transform: translateX(-34vw) translateY(-17vh) scale(0.69);
  }

  91.1439114391% {
    transform: translateX(29vw) translateY(19vh) scale(0.46);
  }

  91.5129151292% {
    transform: translateX(-36vw) translateY(-3vh) scale(0.95);
  }

  91.8819188192% {
    transform: translateX(-30vw) translateY(14vh) scale(0.29);
  }

  92.2509225092% {
    transform: translateX(42vw) translateY(16vh) scale(0.39);
  }

  92.6199261993% {
    transform: translateX(-31vw) translateY(-32vh) scale(0.5);
  }

  92.9889298893% {
    transform: translateX(-42vw) translateY(-23vh) scale(0.86);
  }

  93.3579335793% {
    transform: translateX(44vw) translateY(-24vh) scale(0.26);
  }

  93.7269372694% {
    transform: translateX(-47vw) translateY(-8vh) scale(0.52);
  }

  94.0959409594% {
    transform: translateX(4vw) translateY(-15vh) scale(0.34);
  }

  94.4649446494% {
    transform: translateX(24vw) translateY(49vh) scale(0.87);
  }

  94.8339483395% {
    transform: translateX(-16vw) translateY(28vh) scale(0.92);
  }

  95.2029520295% {
    transform: translateX(26vw) translateY(8vh) scale(0.92);
  }

  95.5719557196% {
    transform: translateX(4vw) translateY(-39vh) scale(0.79);
  }

  95.9409594096% {
    transform: translateX(22vw) translateY(-5vh) scale(0.81);
  }

  96.3099630996% {
    transform: translateX(-7vw) translateY(-48vh) scale(1);
  }

  96.6789667897% {
    transform: translateX(46vw) translateY(2vh) scale(0.45);
  }

  97.0479704797% {
    transform: translateX(-7vw) translateY(28vh) scale(0.49);
  }

  97.4169741697% {
    transform: translateX(21vw) translateY(-42vh) scale(0.9);
  }

  97.7859778598% {
    transform: translateX(-3vw) translateY(-15vh) scale(0.48);
  }

  98.1549815498% {
    transform: translateX(11vw) translateY(43vh) scale(0.56);
  }

  98.5239852399% {
    transform: translateX(-27vw) translateY(-21vh) scale(0.86);
  }

  98.8929889299% {
    transform: translateX(4vw) translateY(37vh) scale(0.86);
  }

  99.2619926199% {
    transform: translateX(40vw) translateY(38vh) scale(0.3);
  }

  99.63099631% {
    transform: translateX(-45vw) translateY(15vh) scale(0.89);
  }

  100% {
    transform: translateX(34vw) translateY(27vh) scale(0.48);
  }
}

.firefly:nth-child(9) {
  animation-name: move9;
}

.firefly:nth-child(9)::before {
  animation-duration: 10s;
}

.firefly:nth-child(9)::after {
  animation-duration: 10s, 7327ms;
  animation-delay: 0ms, 6978ms;
}

@keyframes move9 {
  0% {
    transform: translateX(1vw) translateY(-8vh) scale(0.51);
  }

  0.4784688995% {
    transform: translateX(36vw) translateY(26vh) scale(0.79);
  }

  0.956937799% {
    transform: translateX(41vw) translateY(34vh) scale(0.27);
  }

  1.4354066986% {
    transform: translateX(-23vw) translateY(-47vh) scale(0.8);
  }

  1.9138755981% {
    transform: translateX(-6vw) translateY(5vh) scale(0.39);
  }

  2.3923444976% {
    transform: translateX(32vw) translateY(5vh) scale(0.69);
  }

  2.8708133971% {
    transform: translateX(-8vw) translateY(-43vh) scale(0.47);
  }

  3.3492822967% {
    transform: translateX(-44vw) translateY(43vh) scale(0.26);
  }

  3.8277511962% {
    transform: translateX(-9vw) translateY(26vh) scale(0.96);
  }

  4.3062200957% {
    transform: translateX(37vw) translateY(24vh) scale(0.27);
  }

  4.7846889952% {
    transform: translateX(-21vw) translateY(29vh) scale(0.92);
  }

  5.2631578947% {
    transform: translateX(9vw) translateY(37vh) scale(0.49);
  }

  5.7416267943% {
    transform: translateX(-35vw) translateY(33vh) scale(0.66);
  }

  6.2200956938% {
    transform: translateX(-6vw) translateY(-42vh) scale(0.4);
  }

  6.6985645933% {
    transform: translateX(29vw) translateY(-42vh) scale(0.28);
  }

  7.1770334928% {
    transform: translateX(-21vw) translateY(16vh) scale(0.79);
  }

  7.6555023923% {
    transform: translateX(16vw) translateY(4vh) scale(0.43);
  }

  8.1339712919% {
    transform: translateX(43vw) translateY(-23vh) scale(0.49);
  }

  8.6124401914% {
    transform: translateX(38vw) translateY(9vh) scale(0.96);
  }

  9.0909090909% {
    transform: translateX(-7vw) translateY(-33vh) scale(0.67);
  }

  9.5693779904% {
    transform: translateX(-5vw) translateY(23vh) scale(0.67);
  }

  10.04784689% {
    transform: translateX(-48vw) translateY(-7vh) scale(0.91);
  }

  10.5263157895% {
    transform: translateX(12vw) translateY(-13vh) scale(0.92);
  }

  11.004784689% {
    transform: translateX(2vw) translateY(50vh) scale(0.69);
  }

  11.4832535885% {
    transform: translateX(-9vw) translateY(10vh) scale(0.29);
  }

  11.961722488% {
    transform: translateX(3vw) translateY(-35vh) scale(0.37);
  }

  12.4401913876% {
    transform: translateX(40vw) translateY(18vh) scale(0.32);
  }

  12.9186602871% {
    transform: translateX(-38vw) translateY(-10vh) scale(0.48);
  }

  13.3971291866% {
    transform: translateX(24vw) translateY(14vh) scale(0.49);
  }

  13.8755980861% {
    transform: translateX(29vw) translateY(0vh) scale(0.62);
  }

  14.3540669856% {
    transform: translateX(-31vw) translateY(-42vh) scale(0.63);
  }

  14.8325358852% {
    transform: translateX(4vw) translateY(-42vh) scale(0.89);
  }

  15.3110047847% {
    transform: translateX(10vw) translateY(17vh) scale(0.74);
  }

  15.7894736842% {
    transform: translateX(-9vw) translateY(13vh) scale(0.52);
  }

  16.2679425837% {
    transform: translateX(20vw) translateY(-18vh) scale(0.52);
  }

  16.7464114833% {
    transform: translateX(-2vw) translateY(7vh) scale(0.76);
  }

  17.2248803828% {
    transform: translateX(7vw) translateY(-40vh) scale(0.26);
  }

  17.7033492823% {
    transform: translateX(35vw) translateY(-34vh) scale(0.64);
  }

  18.1818181818% {
    transform: translateX(0vw) translateY(-23vh) scale(0.8);
  }

  18.6602870813% {
    transform: translateX(25vw) translateY(32vh) scale(0.73);
  }

  19.1387559809% {
    transform: translateX(14vw) translateY(-20vh) scale(1);
  }

  19.6172248804% {
    transform: translateX(28vw) translateY(-29vh) scale(0.38);
  }

  20.0956937799% {
    transform: translateX(6vw) translateY(-12vh) scale(0.37);
  }

  20.5741626794% {
    transform: translateX(-19vw) translateY(-45vh) scale(0.37);
  }

  21.0526315789% {
    transform: translateX(-30vw) translateY(12vh) scale(0.69);
  }

  21.5311004785% {
    transform: translateX(-38vw) translateY(-47vh) scale(0.65);
  }

  22.009569378% {
    transform: translateX(-43vw) translateY(-40vh) scale(0.68);
  }

  22.4880382775% {
    transform: translateX(39vw) translateY(4vh) scale(0.8);
  }

  22.966507177% {
    transform: translateX(-12vw) translateY(-14vh) scale(0.89);
  }

  23.4449760766% {
    transform: translateX(-18vw) translateY(13vh) scale(0.29);
  }

  23.9234449761% {
    transform: translateX(14vw) translateY(41vh) scale(0.34);
  }

  24.4019138756% {
    transform: translateX(-3vw) translateY(20vh) scale(0.81);
  }

  24.8803827751% {
    transform: translateX(42vw) translateY(-27vh) scale(0.29);
  }

  25.3588516746% {
    transform: translateX(-26vw) translateY(12vh) scale(0.65);
  }

  25.8373205742% {
    transform: translateX(-37vw) translateY(17vh) scale(0.32);
  }

  26.3157894737% {
    transform: translateX(17vw) translateY(-23vh) scale(0.99);
  }

  26.7942583732% {
    transform: translateX(-46vw) translateY(-11vh) scale(0.41);
  }

  27.2727272727% {
    transform: translateX(6vw) translateY(-31vh) scale(0.43);
  }

  27.7511961722% {
    transform: translateX(33vw) translateY(7vh) scale(0.97);
  }

  28.2296650718% {
    transform: translateX(26vw) translateY(18vh) scale(0.46);
  }

  28.7081339713% {
    transform: translateX(24vw) translateY(-12vh) scale(0.56);
  }

  29.1866028708% {
    transform: translateX(18vw) translateY(45vh) scale(0.79);
  }

  29.6650717703% {
    transform: translateX(34vw) translateY(-17vh) scale(0.74);
  }

  30.1435406699% {
    transform: translateX(33vw) translateY(-35vh) scale(0.71);
  }

  30.6220095694% {
    transform: translateX(-3vw) translateY(-26vh) scale(0.85);
  }

  31.1004784689% {
    transform: translateX(-15vw) translateY(43vh) scale(0.94);
  }

  31.5789473684% {
    transform: translateX(4vw) translateY(23vh) scale(0.82);
  }

  32.0574162679% {
    transform: translateX(32vw) translateY(-17vh) scale(0.81);
  }

  32.5358851675% {
    transform: translateX(-1vw) translateY(45vh) scale(0.43);
  }

  33.014354067% {
    transform: translateX(12vw) translateY(-5vh) scale(0.89);
  }

  33.4928229665% {
    transform: translateX(-29vw) translateY(17vh) scale(0.7);
  }

  33.971291866% {
    transform: translateX(-40vw) translateY(4vh) scale(0.36);
  }

  34.4497607656% {
    transform: translateX(-11vw) translateY(43vh) scale(0.91);
  }

  34.9282296651% {
    transform: translateX(47vw) translateY(-22vh) scale(0.81);
  }

  35.4066985646% {
    transform: translateX(-28vw) translateY(43vh) scale(0.71);
  }

  35.8851674641% {
    transform: translateX(-22vw) translateY(-18vh) scale(0.56);
  }

  36.3636363636% {
    transform: translateX(-45vw) translateY(38vh) scale(0.47);
  }

  36.8421052632% {
    transform: translateX(45vw) translateY(42vh) scale(0.76);
  }

  37.3205741627% {
    transform: translateX(30vw) translateY(-8vh) scale(0.93);
  }

  37.7990430622% {
    transform: translateX(-13vw) translateY(-11vh) scale(0.37);
  }

  38.2775119617% {
    transform: translateX(22vw) translateY(-9vh) scale(0.48);
  }

  38.7559808612% {
    transform: translateX(-18vw) translateY(12vh) scale(0.9);
  }

  39.2344497608% {
    transform: translateX(-27vw) translateY(-33vh) scale(0.88);
  }

  39.7129186603% {
    transform: translateX(7vw) translateY(-18vh) scale(0.41);
  }

  40.1913875598% {
    transform: translateX(-18vw) translateY(-21vh) scale(0.73);
  }

  40.6698564593% {
    transform: translateX(-22vw) translateY(38vh) scale(0.42);
  }

  41.1483253589% {
    transform: translateX(-49vw) translateY(-38vh) scale(0.89);
  }

  41.6267942584% {
    transform: translateX(-35vw) translateY(12vh) scale(0.64);
  }

  42.1052631579% {
    transform: translateX(-2vw) translateY(31vh) scale(0.51);
  }

  42.5837320574% {
    transform: translateX(1vw) translateY(49vh) scale(0.98);
  }

  43.0622009569% {
    transform: translateX(8vw) translateY(-43vh) scale(0.52);
  }

  43.5406698565% {
    transform: translateX(-22vw) translateY(0vh) scale(0.82);
  }

  44.019138756% {
    transform: translateX(-19vw) translateY(-29vh) scale(0.76);
  }

  44.4976076555% {
    transform: translateX(37vw) translateY(26vh) scale(0.73);
  }

  44.976076555% {
    transform: translateX(12vw) translateY(-39vh) scale(0.92);
  }

  45.4545454545% {
    transform: translateX(31vw) translateY(-49vh) scale(0.89);
  }

  45.9330143541% {
    transform: translateX(-35vw) translateY(-24vh) scale(0.46);
  }

  46.4114832536% {
    transform: translateX(9vw) translateY(36vh) scale(0.87);
  }

  46.8899521531% {
    transform: translateX(10vw) translateY(27vh) scale(0.53);
  }

  47.3684210526% {
    transform: translateX(-30vw) translateY(28vh) scale(0.57);
  }

  47.8468899522% {
    transform: translateX(26vw) translateY(-14vh) scale(0.57);
  }

  48.3253588517% {
    transform: translateX(-32vw) translateY(18vh) scale(0.67);
  }

  48.8038277512% {
    transform: translateX(39vw) translateY(-3vh) scale(0.78);
  }

  49.2822966507% {
    transform: translateX(-11vw) translateY(-49vh) scale(0.96);
  }

  49.7607655502% {
    transform: translateX(30vw) translateY(1vh) scale(0.99);
  }

  50.2392344498% {
    transform: translateX(42vw) translateY(-10vh) scale(0.48);
  }

  50.7177033493% {
    transform: translateX(2vw) translateY(-42vh) scale(0.7);
  }

  51.1961722488% {
    transform: translateX(-48vw) translateY(-22vh) scale(0.26);
  }

  51.6746411483% {
    transform: translateX(24vw) translateY(-42vh) scale(0.88);
  }

  52.1531100478% {
    transform: translateX(-19vw) translateY(-28vh) scale(0.83);
  }

  52.6315789474% {
    transform: translateX(-35vw) translateY(20vh) scale(0.85);
  }

  53.1100478469% {
    transform: translateX(-36vw) translateY(-45vh) scale(0.32);
  }

  53.5885167464% {
    transform: translateX(0vw) translateY(10vh) scale(0.97);
  }

  54.0669856459% {
    transform: translateX(-34vw) translateY(-1vh) scale(0.89);
  }

  54.5454545455% {
    transform: translateX(3vw) translateY(12vh) scale(0.38);
  }

  55.023923445% {
    transform: translateX(-24vw) translateY(27vh) scale(0.78);
  }

  55.5023923445% {
    transform: translateX(-4vw) translateY(34vh) scale(0.4);
  }

  55.980861244% {
    transform: translateX(44vw) translateY(-4vh) scale(0.87);
  }

  56.4593301435% {
    transform: translateX(-10vw) translateY(11vh) scale(0.45);
  }

  56.9377990431% {
    transform: translateX(21vw) translateY(32vh) scale(0.63);
  }

  57.4162679426% {
    transform: translateX(-12vw) translateY(-33vh) scale(0.95);
  }

  57.8947368421% {
    transform: translateX(32vw) translateY(33vh) scale(0.77);
  }

  58.3732057416% {
    transform: translateX(-5vw) translateY(31vh) scale(0.42);
  }

  58.8516746411% {
    transform: translateX(3vw) translateY(-31vh) scale(0.62);
  }

  59.3301435407% {
    transform: translateX(-38vw) translateY(-1vh) scale(0.96);
  }

  59.8086124402% {
    transform: translateX(-35vw) translateY(9vh) scale(1);
  }

  60.2870813397% {
    transform: translateX(48vw) translateY(20vh) scale(0.79);
  }

  60.7655502392% {
    transform: translateX(11vw) translateY(9vh) scale(0.28);
  }

  61.2440191388% {
    transform: translateX(19vw) translateY(14vh) scale(0.27);
  }

  61.7224880383% {
    transform: translateX(33vw) translateY(34vh) scale(0.49);
  }

  62.2009569378% {
    transform: translateX(-1vw) translateY(34vh) scale(0.95);
  }

  62.6794258373% {
    transform: translateX(47vw) translateY(-46vh) scale(0.45);
  }

  63.1578947368% {
    transform: translateX(-21vw) translateY(21vh) scale(0.73);
  }

  63.6363636364% {
    transform: translateX(40vw) translateY(-13vh) scale(1);
  }

  64.1148325359% {
    transform: translateX(-19vw) translateY(45vh) scale(0.74);
  }

  64.5933014354% {
    transform: translateX(32vw) translateY(33vh) scale(0.53);
  }

  65.0717703349% {
    transform: translateX(15vw) translateY(-20vh) scale(0.75);
  }

  65.5502392344% {
    transform: translateX(-6vw) translateY(-4vh) scale(0.41);
  }

  66.028708134% {
    transform: translateX(9vw) translateY(-16vh) scale(0.82);
  }

  66.5071770335% {
    transform: translateX(-10vw) translateY(33vh) scale(0.84);
  }

  66.985645933% {
    transform: translateX(14vw) translateY(-14vh) scale(0.86);
  }

  67.4641148325% {
    transform: translateX(-28vw) translateY(14vh) scale(0.53);
  }

  67.9425837321% {
    transform: translateX(36vw) translateY(-36vh) scale(0.62);
  }

  68.4210526316% {
    transform: translateX(-44vw) translateY(-31vh) scale(0.92);
  }

  68.8995215311% {
    transform: translateX(-10vw) translateY(32vh) scale(0.8);
  }

  69.3779904306% {
    transform: translateX(-24vw) translateY(13vh) scale(0.72);
  }

  69.8564593301% {
    transform: translateX(50vw) translateY(-7vh) scale(0.8);
  }

  70.3349282297% {
    transform: translateX(-14vw) translateY(-36vh) scale(0.57);
  }

  70.8133971292% {
    transform: translateX(-29vw) translateY(-27vh) scale(0.81);
  }

  71.2918660287% {
    transform: translateX(-20vw) translateY(27vh) scale(0.42);
  }

  71.7703349282% {
    transform: translateX(-12vw) translateY(-12vh) scale(0.35);
  }

  72.2488038278% {
    transform: translateX(12vw) translateY(-16vh) scale(0.44);
  }

  72.7272727273% {
    transform: translateX(-31vw) translateY(30vh) scale(0.64);
  }

  73.2057416268% {
    transform: translateX(-36vw) translateY(5vh) scale(0.87);
  }

  73.6842105263% {
    transform: translateX(-31vw) translateY(15vh) scale(0.31);
  }

  74.1626794258% {
    transform: translateX(48vw) translateY(-29vh) scale(0.27);
  }

  74.6411483254% {
    transform: translateX(-25vw) translateY(-33vh) scale(0.54);
  }

  75.1196172249% {
    transform: translateX(46vw) translateY(5vh) scale(0.58);
  }

  75.5980861244% {
    transform: translateX(14vw) translateY(-30vh) scale(0.42);
  }

  76.0765550239% {
    transform: translateX(-18vw) translateY(40vh) scale(0.37);
  }

  76.5550239234% {
    transform: translateX(45vw) translateY(-15vh) scale(0.27);
  }

  77.033492823% {
    transform: translateX(23vw) translateY(-38vh) scale(0.81);
  }

  77.5119617225% {
    transform: translateX(-9vw) translateY(-38vh) scale(0.93);
  }

  77.990430622% {
    transform: translateX(41vw) translateY(-3vh) scale(0.86);
  }

  78.4688995215% {
    transform: translateX(-35vw) translateY(25vh) scale(0.59);
  }

  78.9473684211% {
    transform: translateX(0vw) translateY(-6vh) scale(0.39);
  }

  79.4258373206% {
    transform: translateX(-49vw) translateY(28vh) scale(0.88);
  }

  79.9043062201% {
    transform: translateX(36vw) translateY(-23vh) scale(0.32);
  }

  80.3827751196% {
    transform: translateX(21vw) translateY(14vh) scale(0.93);
  }

  80.8612440191% {
    transform: translateX(-12vw) translateY(-3vh) scale(0.93);
  }

  81.3397129187% {
    transform: translateX(-11vw) translateY(20vh) scale(0.34);
  }

  81.8181818182% {
    transform: translateX(-44vw) translateY(3vh) scale(0.39);
  }

  82.2966507177% {
    transform: translateX(43vw) translateY(-12vh) scale(0.35);
  }

  82.7751196172% {
    transform: translateX(-25vw) translateY(29vh) scale(0.3);
  }

  83.2535885167% {
    transform: translateX(-42vw) translateY(47vh) scale(0.51);
  }

  83.7320574163% {
    transform: translateX(-27vw) translateY(24vh) scale(0.55);
  }

  84.2105263158% {
    transform: translateX(-42vw) translateY(19vh) scale(0.91);
  }

  84.6889952153% {
    transform: translateX(40vw) translateY(-20vh) scale(0.62);
  }

  85.1674641148% {
    transform: translateX(-11vw) translateY(-42vh) scale(0.3);
  }

  85.6459330144% {
    transform: translateX(10vw) translateY(34vh) scale(0.56);
  }

  86.1244019139% {
    transform: translateX(-18vw) translateY(38vh) scale(0.99);
  }

  86.6028708134% {
    transform: translateX(-22vw) translateY(-8vh) scale(0.84);
  }

  87.0813397129% {
    transform: translateX(-25vw) translateY(-4vh) scale(0.9);
  }

  87.5598086124% {
    transform: translateX(-48vw) translateY(7vh) scale(0.9);
  }

  88.038277512% {
    transform: translateX(-14vw) translateY(-23vh) scale(0.47);
  }

  88.5167464115% {
    transform: translateX(17vw) translateY(36vh) scale(0.93);
  }

  88.995215311% {
    transform: translateX(15vw) translateY(-17vh) scale(0.85);
  }

  89.4736842105% {
    transform: translateX(12vw) translateY(18vh) scale(0.76);
  }

  89.95215311% {
    transform: translateX(-5vw) translateY(19vh) scale(0.33);
  }

  90.4306220096% {
    transform: translateX(43vw) translateY(11vh) scale(0.28);
  }

  90.9090909091% {
    transform: translateX(31vw) translateY(-36vh) scale(0.6);
  }

  91.3875598086% {
    transform: translateX(-21vw) translateY(42vh) scale(0.28);
  }

  91.8660287081% {
    transform: translateX(-35vw) translateY(17vh) scale(0.45);
  }

  92.3444976077% {
    transform: translateX(49vw) translateY(-43vh) scale(0.48);
  }

  92.8229665072% {
    transform: translateX(-24vw) translateY(28vh) scale(0.57);
  }

  93.3014354067% {
    transform: translateX(-44vw) translateY(-40vh) scale(0.68);
  }

  93.7799043062% {
    transform: translateX(-1vw) translateY(34vh) scale(0.44);
  }

  94.2583732057% {
    transform: translateX(22vw) translateY(-41vh) scale(0.45);
  }

  94.7368421053% {
    transform: translateX(18vw) translateY(23vh) scale(0.72);
  }

  95.2153110048% {
    transform: translateX(-12vw) translateY(25vh) scale(0.84);
  }

  95.6937799043% {
    transform: translateX(-24vw) translateY(14vh) scale(0.52);
  }

  96.1722488038% {
    transform: translateX(38vw) translateY(-31vh) scale(0.84);
  }

  96.6507177033% {
    transform: translateX(22vw) translateY(12vh) scale(0.5);
  }

  97.1291866029% {
    transform: translateX(-28vw) translateY(-29vh) scale(0.52);
  }

  97.6076555024% {
    transform: translateX(33vw) translateY(-18vh) scale(0.33);
  }

  98.0861244019% {
    transform: translateX(-6vw) translateY(-14vh) scale(0.81);
  }

  98.5645933014% {
    transform: translateX(6vw) translateY(-49vh) scale(0.27);
  }

  99.043062201% {
    transform: translateX(37vw) translateY(8vh) scale(0.28);
  }

  99.5215311005% {
    transform: translateX(44vw) translateY(-45vh) scale(0.32);
  }

  100% {
    transform: translateX(-14vw) translateY(-29vh) scale(0.87);
  }
}

.firefly:nth-child(10) {
  animation-name: move10;
}

.firefly:nth-child(10)::before {
  animation-duration: 10s;
}

.firefly:nth-child(10)::after {
  animation-duration: 10s, 5459ms;
  animation-delay: 0ms, 2104ms;
}

@keyframes move10 {
  0% {
    transform: translateX(-2vw) translateY(-29vh) scale(0.87);
  }

  0.3759398496% {
    transform: translateX(-25vw) translateY(38vh) scale(0.51);
  }

  0.7518796992% {
    transform: translateX(-28vw) translateY(39vh) scale(0.28);
  }

  1.1278195489% {
    transform: translateX(-5vw) translateY(-15vh) scale(0.29);
  }

  1.5037593985% {
    transform: translateX(-34vw) translateY(-26vh) scale(0.98);
  }

  1.8796992481% {
    transform: translateX(43vw) translateY(4vh) scale(0.98);
  }

  2.2556390977% {
    transform: translateX(46vw) translateY(14vh) scale(0.9);
  }

  2.6315789474% {
    transform: translateX(7vw) translateY(24vh) scale(0.61);
  }

  3.007518797% {
    transform: translateX(-27vw) translateY(27vh) scale(0.77);
  }

  3.3834586466% {
    transform: translateX(29vw) translateY(27vh) scale(0.41);
  }

  3.7593984962% {
    transform: translateX(-40vw) translateY(-47vh) scale(0.55);
  }

  4.1353383459% {
    transform: translateX(-9vw) translateY(47vh) scale(0.92);
  }

  4.5112781955% {
    transform: translateX(-4vw) translateY(38vh) scale(0.58);
  }

  4.8872180451% {
    transform: translateX(0vw) translateY(-17vh) scale(0.96);
  }

  5.2631578947% {
    transform: translateX(-16vw) translateY(25vh) scale(0.62);
  }

  5.6390977444% {
    transform: translateX(-1vw) translateY(49vh) scale(0.86);
  }

  6.015037594% {
    transform: translateX(-10vw) translateY(4vh) scale(0.79);
  }

  6.3909774436% {
    transform: translateX(-19vw) translateY(49vh) scale(0.61);
  }

  6.7669172932% {
    transform: translateX(38vw) translateY(-25vh) scale(0.83);
  }

  7.1428571429% {
    transform: translateX(22vw) translateY(10vh) scale(0.31);
  }

  7.5187969925% {
    transform: translateX(31vw) translateY(49vh) scale(0.3);
  }

  7.8947368421% {
    transform: translateX(-40vw) translateY(22vh) scale(0.68);
  }

  8.2706766917% {
    transform: translateX(8vw) translateY(-24vh) scale(0.29);
  }

  8.6466165414% {
    transform: translateX(38vw) translateY(-12vh) scale(0.89);
  }

  9.022556391% {
    transform: translateX(-28vw) translateY(-2vh) scale(0.59);
  }

  9.3984962406% {
    transform: translateX(12vw) translateY(-37vh) scale(0.46);
  }

  9.7744360902% {
    transform: translateX(-40vw) translateY(16vh) scale(0.63);
  }

  10.1503759398% {
    transform: translateX(-39vw) translateY(-42vh) scale(0.83);
  }

  10.5263157895% {
    transform: translateX(-12vw) translateY(-29vh) scale(0.32);
  }

  10.9022556391% {
    transform: translateX(-46vw) translateY(5vh) scale(0.48);
  }

  11.2781954887% {
    transform: translateX(23vw) translateY(-38vh) scale(0.75);
  }

  11.6541353383% {
    transform: translateX(47vw) translateY(-2vh) scale(0.39);
  }

  12.030075188% {
    transform: translateX(-37vw) translateY(45vh) scale(0.75);
  }

  12.4060150376% {
    transform: translateX(-35vw) translateY(45vh) scale(0.76);
  }

  12.7819548872% {
    transform: translateX(19vw) translateY(34vh) scale(0.38);
  }

  13.1578947368% {
    transform: translateX(-4vw) translateY(-46vh) scale(0.44);
  }

  13.5338345865% {
    transform: translateX(-14vw) translateY(-34vh) scale(0.56);
  }

  13.9097744361% {
    transform: translateX(-1vw) translateY(46vh) scale(0.48);
  }

  14.2857142857% {
    transform: translateX(-42vw) translateY(-48vh) scale(0.62);
  }

  14.6616541353% {
    transform: translateX(-46vw) translateY(42vh) scale(0.86);
  }

  15.037593985% {
    transform: translateX(-20vw) translateY(27vh) scale(0.79);
  }

  15.4135338346% {
    transform: translateX(22vw) translateY(-9vh) scale(0.44);
  }

  15.7894736842% {
    transform: translateX(3vw) translateY(41vh) scale(0.29);
  }

  16.1654135338% {
    transform: translateX(-38vw) translateY(-25vh) scale(0.5);
  }

  16.5413533835% {
    transform: translateX(-1vw) translateY(-39vh) scale(0.42);
  }

  16.9172932331% {
    transform: translateX(34vw) translateY(8vh) scale(0.37);
  }

  17.2932330827% {
    transform: translateX(17vw) translateY(25vh) scale(0.49);
  }

  17.6691729323% {
    transform: translateX(12vw) translateY(-43vh) scale(0.73);
  }

  18.045112782% {
    transform: translateX(-41vw) translateY(-46vh) scale(0.34);
  }

  18.4210526316% {
    transform: translateX(-1vw) translateY(-32vh) scale(0.59);
  }

  18.7969924812% {
    transform: translateX(30vw) translateY(11vh) scale(0.48);
  }

  19.1729323308% {
    transform: translateX(-25vw) translateY(-13vh) scale(0.6);
  }

  19.5488721805% {
    transform: translateX(-48vw) translateY(7vh) scale(0.99);
  }

  19.9248120301% {
    transform: translateX(-32vw) translateY(31vh) scale(0.26);
  }

  20.3007518797% {
    transform: translateX(48vw) translateY(11vh) scale(0.81);
  }

  20.6766917293% {
    transform: translateX(-42vw) translateY(40vh) scale(0.7);
  }

  21.0526315789% {
    transform: translateX(-40vw) translateY(11vh) scale(0.9);
  }

  21.4285714286% {
    transform: translateX(42vw) translateY(-15vh) scale(0.46);
  }

  21.8045112782% {
    transform: translateX(-18vw) translateY(11vh) scale(0.44);
  }

  22.1804511278% {
    transform: translateX(39vw) translateY(-19vh) scale(0.57);
  }

  22.5563909774% {
    transform: translateX(-21vw) translateY(-40vh) scale(0.27);
  }

  22.9323308271% {
    transform: translateX(48vw) translateY(8vh) scale(0.41);
  }

  23.3082706767% {
    transform: translateX(-43vw) translateY(-11vh) scale(0.55);
  }

  23.6842105263% {
    transform: translateX(-35vw) translateY(8vh) scale(0.51);
  }

  24.0601503759% {
    transform: translateX(-30vw) translateY(-18vh) scale(0.7);
  }

  24.4360902256% {
    transform: translateX(-47vw) translateY(38vh) scale(0.96);
  }

  24.8120300752% {
    transform: translateX(-12vw) translateY(25vh) scale(0.38);
  }

  25.1879699248% {
    transform: translateX(0vw) translateY(35vh) scale(0.27);
  }

  25.5639097744% {
    transform: translateX(-10vw) translateY(22vh) scale(0.46);
  }

  25.9398496241% {
    transform: translateX(-33vw) translateY(47vh) scale(0.94);
  }

  26.3157894737% {
    transform: translateX(9vw) translateY(-45vh) scale(0.48);
  }

  26.6917293233% {
    transform: translateX(12vw) translateY(-32vh) scale(0.64);
  }

  27.0676691729% {
    transform: translateX(-26vw) translateY(-45vh) scale(0.7);
  }

  27.4436090226% {
    transform: translateX(-26vw) translateY(37vh) scale(0.32);
  }

  27.8195488722% {
    transform: translateX(19vw) translateY(-13vh) scale(0.62);
  }

  28.1954887218% {
    transform: translateX(50vw) translateY(-11vh) scale(0.97);
  }

  28.5714285714% {
    transform: translateX(-16vw) translateY(-47vh) scale(0.62);
  }

  28.9473684211% {
    transform: translateX(36vw) translateY(46vh) scale(0.97);
  }

  29.3233082707% {
    transform: translateX(-33vw) translateY(-45vh) scale(0.83);
  }

  29.6992481203% {
    transform: translateX(-46vw) translateY(-36vh) scale(0.98);
  }

  30.0751879699% {
    transform: translateX(18vw) translateY(20vh) scale(0.64);
  }

  30.4511278195% {
    transform: translateX(44vw) translateY(1vh) scale(0.54);
  }

  30.8270676692% {
    transform: translateX(-35vw) translateY(46vh) scale(0.95);
  }

  31.2030075188% {
    transform: translateX(36vw) translateY(30vh) scale(0.97);
  }

  31.5789473684% {
    transform: translateX(48vw) translateY(7vh) scale(0.44);
  }

  31.954887218% {
    transform: translateX(-33vw) translateY(-7vh) scale(0.33);
  }

  32.3308270677% {
    transform: translateX(-6vw) translateY(23vh) scale(0.82);
  }

  32.7067669173% {
    transform: translateX(46vw) translateY(44vh) scale(0.75);
  }

  33.0827067669% {
    transform: translateX(22vw) translateY(19vh) scale(0.3);
  }

  33.4586466165% {
    transform: translateX(-30vw) translateY(-32vh) scale(0.55);
  }

  33.8345864662% {
    transform: translateX(29vw) translateY(-25vh) scale(0.74);
  }

  34.2105263158% {
    transform: translateX(-22vw) translateY(13vh) scale(0.64);
  }

  34.5864661654% {
    transform: translateX(-5vw) translateY(8vh) scale(0.93);
  }

  34.962406015% {
    transform: translateX(-6vw) translateY(1vh) scale(0.77);
  }

  35.3383458647% {
    transform: translateX(-39vw) translateY(22vh) scale(0.91);
  }

  35.7142857143% {
    transform: translateX(20vw) translateY(-29vh) scale(0.86);
  }

  36.0902255639% {
    transform: translateX(-36vw) translateY(-2vh) scale(0.69);
  }

  36.4661654135% {
    transform: translateX(-49vw) translateY(6vh) scale(0.7);
  }

  36.8421052632% {
    transform: translateX(-20vw) translateY(-15vh) scale(0.6);
  }

  37.2180451128% {
    transform: translateX(-9vw) translateY(8vh) scale(0.59);
  }

  37.5939849624% {
    transform: translateX(6vw) translateY(47vh) scale(0.43);
  }

  37.969924812% {
    transform: translateX(-17vw) translateY(-19vh) scale(0.84);
  }

  38.3458646617% {
    transform: translateX(-27vw) translateY(3vh) scale(0.31);
  }

  38.7218045113% {
    transform: translateX(-30vw) translateY(-38vh) scale(0.8);
  }

  39.0977443609% {
    transform: translateX(-6vw) translateY(45vh) scale(0.39);
  }

  39.4736842105% {
    transform: translateX(-29vw) translateY(-35vh) scale(0.86);
  }

  39.8496240602% {
    transform: translateX(-17vw) translateY(36vh) scale(0.99);
  }

  40.2255639098% {
    transform: translateX(-17vw) translateY(39vh) scale(0.65);
  }

  40.6015037594% {
    transform: translateX(28vw) translateY(-33vh) scale(0.84);
  }

  40.977443609% {
    transform: translateX(-13vw) translateY(-38vh) scale(1);
  }

  41.3533834586% {
    transform: translateX(3vw) translateY(-32vh) scale(0.34);
  }

  41.7293233083% {
    transform: translateX(-5vw) translateY(-48vh) scale(0.85);
  }

  42.1052631579% {
    transform: translateX(-15vw) translateY(18vh) scale(0.62);
  }

  42.4812030075% {
    transform: translateX(42vw) translateY(13vh) scale(0.62);
  }

  42.8571428571% {
    transform: translateX(36vw) translateY(1vh) scale(0.99);
  }

  43.2330827068% {
    transform: translateX(28vw) translateY(34vh) scale(0.84);
  }

  43.6090225564% {
    transform: translateX(-46vw) translateY(19vh) scale(0.5);
  }

  43.984962406% {
    transform: translateX(-8vw) translateY(-42vh) scale(0.87);
  }

  44.3609022556% {
    transform: translateX(-40vw) translateY(24vh) scale(0.45);
  }

  44.7368421053% {
    transform: translateX(-48vw) translateY(-16vh) scale(0.71);
  }

  45.1127819549% {
    transform: translateX(-38vw) translateY(17vh) scale(0.79);
  }

  45.4887218045% {
    transform: translateX(-5vw) translateY(46vh) scale(0.9);
  }

  45.8646616541% {
    transform: translateX(-30vw) translateY(4vh) scale(0.3);
  }

  46.2406015038% {
    transform: translateX(9vw) translateY(-44vh) scale(0.62);
  }

  46.6165413534% {
    transform: translateX(45vw) translateY(-21vh) scale(0.3);
  }

  46.992481203% {
    transform: translateX(0vw) translateY(-38vh) scale(0.43);
  }

  47.3684210526% {
    transform: translateX(9vw) translateY(7vh) scale(0.33);
  }

  47.7443609023% {
    transform: translateX(28vw) translateY(4vh) scale(0.66);
  }

  48.1203007519% {
    transform: translateX(8vw) translateY(48vh) scale(0.35);
  }

  48.4962406015% {
    transform: translateX(-32vw) translateY(-26vh) scale(0.49);
  }

  48.8721804511% {
    transform: translateX(-20vw) translateY(-44vh) scale(0.45);
  }

  49.2481203008% {
    transform: translateX(-17vw) translateY(8vh) scale(0.84);
  }

  49.6240601504% {
    transform: translateX(28vw) translateY(33vh) scale(0.57);
  }

  50% {
    transform: translateX(-33vw) translateY(33vh) scale(0.78);
  }

  50.3759398496% {
    transform: translateX(-48vw) translateY(-25vh) scale(0.51);
  }

  50.7518796992% {
    transform: translateX(-6vw) translateY(-7vh) scale(0.43);
  }

  51.1278195489% {
    transform: translateX(47vw) translateY(16vh) scale(0.68);
  }

  51.5037593985% {
    transform: translateX(19vw) translateY(-10vh) scale(0.95);
  }

  51.8796992481% {
    transform: translateX(-24vw) translateY(-28vh) scale(0.37);
  }

  52.2556390977% {
    transform: translateX(28vw) translateY(46vh) scale(0.7);
  }

  52.6315789474% {
    transform: translateX(43vw) translateY(-12vh) scale(0.83);
  }

  53.007518797% {
    transform: translateX(-48vw) translateY(-35vh) scale(0.95);
  }

  53.3834586466% {
    transform: translateX(7vw) translateY(-38vh) scale(0.31);
  }

  53.7593984962% {
    transform: translateX(-38vw) translateY(-23vh) scale(0.81);
  }

  54.1353383459% {
    transform: translateX(50vw) translateY(35vh) scale(0.37);
  }

  54.5112781955% {
    transform: translateX(-14vw) translateY(-30vh) scale(0.75);
  }

  54.8872180451% {
    transform: translateX(7vw) translateY(37vh) scale(0.61);
  }

  55.2631578947% {
    transform: translateX(-31vw) translateY(-24vh) scale(0.85);
  }

  55.6390977444% {
    transform: translateX(20vw) translateY(-4vh) scale(0.68);
  }

  56.015037594% {
    transform: translateX(29vw) translateY(5vh) scale(0.3);
  }

  56.3909774436% {
    transform: translateX(-12vw) translateY(-19vh) scale(0.59);
  }

  56.7669172932% {
    transform: translateX(7vw) translateY(-41vh) scale(0.64);
  }

  57.1428571429% {
    transform: translateX(-39vw) translateY(-14vh) scale(0.79);
  }

  57.5187969925% {
    transform: translateX(27vw) translateY(6vh) scale(0.97);
  }

  57.8947368421% {
    transform: translateX(-22vw) translateY(-19vh) scale(0.9);
  }

  58.2706766917% {
    transform: translateX(10vw) translateY(-9vh) scale(0.64);
  }

  58.6466165414% {
    transform: translateX(-14vw) translateY(-26vh) scale(0.36);
  }

  59.022556391% {
    transform: translateX(-5vw) translateY(-12vh) scale(0.93);
  }

  59.3984962406% {
    transform: translateX(48vw) translateY(36vh) scale(0.44);
  }

  59.7744360902% {
    transform: translateX(-13vw) translateY(48vh) scale(0.68);
  }

  60.1503759398% {
    transform: translateX(48vw) translateY(-10vh) scale(0.55);
  }

  60.5263157895% {
    transform: translateX(-6vw) translateY(21vh) scale(0.42);
  }

  60.9022556391% {
    transform: translateX(-27vw) translateY(-25vh) scale(0.78);
  }

  61.2781954887% {
    transform: translateX(16vw) translateY(40vh) scale(0.93);
  }

  61.6541353383% {
    transform: translateX(-5vw) translateY(6vh) scale(0.35);
  }

  62.030075188% {
    transform: translateX(16vw) translateY(38vh) scale(0.61);
  }

  62.4060150376% {
    transform: translateX(21vw) translateY(-1vh) scale(0.85);
  }

  62.7819548872% {
    transform: translateX(10vw) translateY(44vh) scale(0.69);
  }

  63.1578947368% {
    transform: translateX(14vw) translateY(-30vh) scale(0.4);
  }

  63.5338345865% {
    transform: translateX(-26vw) translateY(-9vh) scale(0.41);
  }

  63.9097744361% {
    transform: translateX(-46vw) translateY(32vh) scale(0.44);
  }

  64.2857142857% {
    transform: translateX(-46vw) translateY(-22vh) scale(0.74);
  }

  64.6616541353% {
    transform: translateX(20vw) translateY(-18vh) scale(0.45);
  }

  65.037593985% {
    transform: translateX(37vw) translateY(-39vh) scale(0.76);
  }

  65.4135338346% {
    transform: translateX(-15vw) translateY(43vh) scale(0.66);
  }

  65.7894736842% {
    transform: translateX(29vw) translateY(-39vh) scale(0.47);
  }

  66.1654135338% {
    transform: translateX(24vw) translateY(-5vh) scale(0.39);
  }

  66.5413533835% {
    transform: translateX(48vw) translateY(-11vh) scale(0.68);
  }

  66.9172932331% {
    transform: translateX(-15vw) translateY(17vh) scale(0.75);
  }

  67.2932330827% {
    transform: translateX(21vw) translateY(39vh) scale(0.99);
  }

  67.6691729323% {
    transform: translateX(20vw) translateY(49vh) scale(0.68);
  }

  68.045112782% {
    transform: translateX(-5vw) translateY(-45vh) scale(0.39);
  }

  68.4210526316% {
    transform: translateX(-43vw) translateY(-23vh) scale(0.56);
  }

  68.7969924812% {
    transform: translateX(-1vw) translateY(5vh) scale(0.35);
  }

  69.1729323308% {
    transform: translateX(-8vw) translateY(10vh) scale(0.87);
  }

  69.5488721805% {
    transform: translateX(-36vw) translateY(-5vh) scale(0.43);
  }

  69.9248120301% {
    transform: translateX(-32vw) translateY(6vh) scale(0.29);
  }

  70.3007518797% {
    transform: translateX(2vw) translateY(36vh) scale(0.92);
  }

  70.6766917293% {
    transform: translateX(17vw) translateY(11vh) scale(0.89);
  }

  71.0526315789% {
    transform: translateX(6vw) translateY(4vh) scale(0.79);
  }

  71.4285714286% {
    transform: translateX(-6vw) translateY(38vh) scale(0.6);
  }

  71.8045112782% {
    transform: translateX(-9vw) translateY(46vh) scale(0.48);
  }

  72.1804511278% {
    transform: translateX(25vw) translateY(-27vh) scale(0.97);
  }

  72.5563909774% {
    transform: translateX(-44vw) translateY(19vh) scale(0.8);
  }

  72.9323308271% {
    transform: translateX(-34vw) translateY(0vh) scale(0.8);
  }

  73.3082706767% {
    transform: translateX(34vw) translateY(28vh) scale(0.5);
  }

  73.6842105263% {
    transform: translateX(25vw) translateY(50vh) scale(0.81);
  }

  74.0601503759% {
    transform: translateX(-49vw) translateY(-10vh) scale(0.28);
  }

  74.4360902256% {
    transform: translateX(20vw) translateY(1vh) scale(0.97);
  }

  74.8120300752% {
    transform: translateX(-7vw) translateY(45vh) scale(0.94);
  }

  75.1879699248% {
    transform: translateX(48vw) translateY(-43vh) scale(0.56);
  }

  75.5639097744% {
    transform: translateX(49vw) translateY(36vh) scale(0.72);
  }

  75.9398496241% {
    transform: translateX(-41vw) translateY(-24vh) scale(0.34);
  }

  76.3157894737% {
    transform: translateX(-25vw) translateY(12vh) scale(0.54);
  }

  76.6917293233% {
    transform: translateX(-7vw) translateY(-18vh) scale(0.67);
  }

  77.0676691729% {
    transform: translateX(-37vw) translateY(-47vh) scale(0.58);
  }

  77.4436090226% {
    transform: translateX(8vw) translateY(33vh) scale(0.75);
  }

  77.8195488722% {
    transform: translateX(32vw) translateY(-27vh) scale(0.42);
  }

  78.1954887218% {
    transform: translateX(-45vw) translateY(38vh) scale(0.78);
  }

  78.5714285714% {
    transform: translateX(8vw) translateY(21vh) scale(0.47);
  }

  78.9473684211% {
    transform: translateX(50vw) translateY(2vh) scale(0.99);
  }

  79.3233082707% {
    transform: translateX(47vw) translateY(-31vh) scale(0.66);
  }

  79.6992481203% {
    transform: translateX(13vw) translateY(31vh) scale(0.78);
  }

  80.0751879699% {
    transform: translateX(19vw) translateY(46vh) scale(0.96);
  }

  80.4511278195% {
    transform: translateX(35vw) translateY(46vh) scale(0.66);
  }

  80.8270676692% {
    transform: translateX(14vw) translateY(7vh) scale(0.31);
  }

  81.2030075188% {
    transform: translateX(-33vw) translateY(-44vh) scale(0.36);
  }

  81.5789473684% {
    transform: translateX(-3vw) translateY(-33vh) scale(0.48);
  }

  81.954887218% {
    transform: translateX(41vw) translateY(31vh) scale(0.56);
  }

  82.3308270677% {
    transform: translateX(-15vw) translateY(32vh) scale(0.46);
  }

  82.7067669173% {
    transform: translateX(-1vw) translateY(-27vh) scale(0.99);
  }

  83.0827067669% {
    transform: translateX(6vw) translateY(16vh) scale(0.27);
  }

  83.4586466165% {
    transform: translateX(-3vw) translateY(-8vh) scale(0.72);
  }

  83.8345864662% {
    transform: translateX(-21vw) translateY(22vh) scale(0.83);
  }

  84.2105263158% {
    transform: translateX(9vw) translateY(-35vh) scale(0.3);
  }

  84.5864661654% {
    transform: translateX(-22vw) translateY(-24vh) scale(0.6);
  }

  84.962406015% {
    transform: translateX(33vw) translateY(-31vh) scale(0.45);
  }

  85.3383458647% {
    transform: translateX(-6vw) translateY(-7vh) scale(0.42);
  }

  85.7142857143% {
    transform: translateX(-43vw) translateY(-14vh) scale(0.36);
  }

  86.0902255639% {
    transform: translateX(-21vw) translateY(-14vh) scale(0.99);
  }

  86.4661654135% {
    transform: translateX(28vw) translateY(-45vh) scale(0.59);
  }

  86.8421052632% {
    transform: translateX(25vw) translateY(32vh) scale(0.53);
  }

  87.2180451128% {
    transform: translateX(3vw) translateY(-21vh) scale(0.48);
  }

  87.5939849624% {
    transform: translateX(12vw) translateY(-5vh) scale(0.52);
  }

  87.969924812% {
    transform: translateX(-9vw) translateY(-29vh) scale(0.88);
  }

  88.3458646617% {
    transform: translateX(33vw) translateY(-43vh) scale(0.91);
  }

  88.7218045113% {
    transform: translateX(40vw) translateY(-19vh) scale(0.32);
  }

  89.0977443609% {
    transform: translateX(1vw) translateY(-17vh) scale(0.95);
  }

  89.4736842105% {
    transform: translateX(23vw) translateY(-24vh) scale(0.9);
  }

  89.8496240602% {
    transform: translateX(27vw) translateY(9vh) scale(0.39);
  }

  90.2255639098% {
    transform: translateX(4vw) translateY(24vh) scale(0.27);
  }

  90.6015037594% {
    transform: translateX(-38vw) translateY(16vh) scale(0.96);
  }

  90.977443609% {
    transform: translateX(25vw) translateY(5vh) scale(0.45);
  }

  91.3533834586% {
    transform: translateX(-43vw) translateY(40vh) scale(0.71);
  }

  91.7293233083% {
    transform: translateX(15vw) translateY(-5vh) scale(0.52);
  }

  92.1052631579% {
    transform: translateX(-17vw) translateY(-28vh) scale(0.37);
  }

  92.4812030075% {
    transform: translateX(-38vw) translateY(-22vh) scale(0.41);
  }

  92.8571428571% {
    transform: translateX(39vw) translateY(-43vh) scale(0.35);
  }

  93.2330827068% {
    transform: translateX(12vw) translateY(45vh) scale(0.48);
  }

  93.6090225564% {
    transform: translateX(-13vw) translateY(6vh) scale(0.91);
  }

  93.984962406% {
    transform: translateX(-10vw) translateY(-8vh) scale(0.44);
  }

  94.3609022556% {
    transform: translateX(38vw) translateY(-19vh) scale(0.84);
  }

  94.7368421053% {
    transform: translateX(0vw) translateY(43vh) scale(0.39);
  }

  95.1127819549% {
    transform: translateX(15vw) translateY(-5vh) scale(0.5);
  }

  95.4887218045% {
    transform: translateX(-29vw) translateY(-13vh) scale(0.95);
  }

  95.8646616541% {
    transform: translateX(-6vw) translateY(-23vh) scale(0.79);
  }

  96.2406015038% {
    transform: translateX(50vw) translateY(2vh) scale(0.73);
  }

  96.6165413534% {
    transform: translateX(-27vw) translateY(-33vh) scale(0.78);
  }

  96.992481203% {
    transform: translateX(-18vw) translateY(45vh) scale(0.35);
  }

  97.3684210526% {
    transform: translateX(34vw) translateY(-7vh) scale(0.81);
  }

  97.7443609023% {
    transform: translateX(48vw) translateY(-42vh) scale(0.26);
  }

  98.1203007519% {
    transform: translateX(-48vw) translateY(9vh) scale(0.33);
  }

  98.4962406015% {
    transform: translateX(-30vw) translateY(12vh) scale(0.93);
  }

  98.8721804511% {
    transform: translateX(18vw) translateY(32vh) scale(0.38);
  }

  99.2481203008% {
    transform: translateX(38vw) translateY(47vh) scale(0.86);
  }

  99.6240601504% {
    transform: translateX(17vw) translateY(17vh) scale(0.44);
  }

  100% {
    transform: translateX(-16vw) translateY(36vh) scale(0.34);
  }
}

.firefly:nth-child(11) {
  animation-name: move11;
}

.firefly:nth-child(11)::before {
  animation-duration: 16s;
}

.firefly:nth-child(11)::after {
  animation-duration: 16s, 7109ms;
  animation-delay: 0ms, 4442ms;
}

@keyframes move11 {
  0% {
    transform: translateX(1vw) translateY(-38vh) scale(0.93);
  }

  0.4048582996% {
    transform: translateX(44vw) translateY(-42vh) scale(0.7);
  }

  0.8097165992% {
    transform: translateX(-40vw) translateY(16vh) scale(0.88);
  }

  1.2145748988% {
    transform: translateX(5vw) translateY(-17vh) scale(0.59);
  }

  1.6194331984% {
    transform: translateX(19vw) translateY(-19vh) scale(0.36);
  }

  2.024291498% {
    transform: translateX(28vw) translateY(6vh) scale(0.4);
  }

  2.4291497976% {
    transform: translateX(-1vw) translateY(-19vh) scale(0.32);
  }

  2.8340080972% {
    transform: translateX(7vw) translateY(21vh) scale(0.49);
  }

  3.2388663968% {
    transform: translateX(23vw) translateY(1vh) scale(0.52);
  }

  3.6437246964% {
    transform: translateX(7vw) translateY(46vh) scale(0.3);
  }

  4.048582996% {
    transform: translateX(19vw) translateY(-48vh) scale(0.86);
  }

  4.4534412955% {
    transform: translateX(41vw) translateY(5vh) scale(0.32);
  }

  4.8582995951% {
    transform: translateX(-35vw) translateY(10vh) scale(0.29);
  }

  5.2631578947% {
    transform: translateX(38vw) translateY(33vh) scale(0.91);
  }

  5.6680161943% {
    transform: translateX(49vw) translateY(15vh) scale(0.53);
  }

  6.0728744939% {
    transform: translateX(32vw) translateY(7vh) scale(0.41);
  }

  6.4777327935% {
    transform: translateX(3vw) translateY(26vh) scale(0.84);
  }

  6.8825910931% {
    transform: translateX(12vw) translateY(27vh) scale(0.35);
  }

  7.2874493927% {
    transform: translateX(9vw) translateY(12vh) scale(0.7);
  }

  7.6923076923% {
    transform: translateX(28vw) translateY(17vh) scale(0.88);
  }

  8.0971659919% {
    transform: translateX(45vw) translateY(-33vh) scale(0.89);
  }

  8.5020242915% {
    transform: translateX(29vw) translateY(1vh) scale(0.73);
  }

  8.9068825911% {
    transform: translateX(30vw) translateY(-6vh) scale(0.53);
  }

  9.3117408907% {
    transform: translateX(15vw) translateY(-24vh) scale(0.93);
  }

  9.7165991903% {
    transform: translateX(-17vw) translateY(-16vh) scale(0.54);
  }

  10.1214574899% {
    transform: translateX(-49vw) translateY(21vh) scale(0.33);
  }

  10.5263157895% {
    transform: translateX(-39vw) translateY(-28vh) scale(0.55);
  }

  10.9311740891% {
    transform: translateX(11vw) translateY(-26vh) scale(0.29);
  }

  11.3360323887% {
    transform: translateX(-25vw) translateY(37vh) scale(0.82);
  }

  11.7408906883% {
    transform: translateX(-19vw) translateY(-7vh) scale(0.67);
  }

  12.1457489879% {
    transform: translateX(38vw) translateY(12vh) scale(0.49);
  }

  12.5506072874% {
    transform: translateX(-34vw) translateY(-41vh) scale(0.72);
  }

  12.955465587% {
    transform: translateX(-22vw) translateY(36vh) scale(0.62);
  }

  13.3603238866% {
    transform: translateX(-10vw) translateY(13vh) scale(0.8);
  }

  13.7651821862% {
    transform: translateX(16vw) translateY(-9vh) scale(0.41);
  }

  14.1700404858% {
    transform: translateX(-46vw) translateY(-33vh) scale(0.52);
  }

  14.5748987854% {
    transform: translateX(-19vw) translateY(30vh) scale(0.44);
  }

  14.979757085% {
    transform: translateX(38vw) translateY(23vh) scale(0.54);
  }

  15.3846153846% {
    transform: translateX(35vw) translateY(-4vh) scale(0.77);
  }

  15.7894736842% {
    transform: translateX(-12vw) translateY(-42vh) scale(0.8);
  }

  16.1943319838% {
    transform: translateX(29vw) translateY(45vh) scale(0.99);
  }

  16.5991902834% {
    transform: translateX(-20vw) translateY(32vh) scale(0.39);
  }

  17.004048583% {
    transform: translateX(4vw) translateY(39vh) scale(0.29);
  }

  17.4089068826% {
    transform: translateX(29vw) translateY(-39vh) scale(0.57);
  }

  17.8137651822% {
    transform: translateX(-42vw) translateY(-17vh) scale(0.85);
  }

  18.2186234818% {
    transform: translateX(-26vw) translateY(21vh) scale(0.51);
  }

  18.6234817814% {
    transform: translateX(-20vw) translateY(16vh) scale(0.39);
  }

  19.028340081% {
    transform: translateX(3vw) translateY(5vh) scale(0.39);
  }

  19.4331983806% {
    transform: translateX(34vw) translateY(-20vh) scale(0.26);
  }

  19.8380566802% {
    transform: translateX(-27vw) translateY(31vh) scale(0.93);
  }

  20.2429149798% {
    transform: translateX(-32vw) translateY(-5vh) scale(0.73);
  }

  20.6477732794% {
    transform: translateX(-38vw) translateY(-5vh) scale(0.6);
  }

  21.0526315789% {
    transform: translateX(11vw) translateY(-15vh) scale(0.38);
  }

  21.4574898785% {
    transform: translateX(0vw) translateY(-17vh) scale(0.89);
  }

  21.8623481781% {
    transform: translateX(-8vw) translateY(-3vh) scale(0.94);
  }

  22.2672064777% {
    transform: translateX(-42vw) translateY(33vh) scale(0.75);
  }

  22.6720647773% {
    transform: translateX(-8vw) translateY(19vh) scale(0.96);
  }

  23.0769230769% {
    transform: translateX(-9vw) translateY(40vh) scale(0.55);
  }

  23.4817813765% {
    transform: translateX(-42vw) translateY(-8vh) scale(0.72);
  }

  23.8866396761% {
    transform: translateX(37vw) translateY(44vh) scale(0.61);
  }

  24.2914979757% {
    transform: translateX(4vw) translateY(34vh) scale(0.88);
  }

  24.6963562753% {
    transform: translateX(19vw) translateY(14vh) scale(0.9);
  }

  25.1012145749% {
    transform: translateX(17vw) translateY(12vh) scale(0.96);
  }

  25.5060728745% {
    transform: translateX(-4vw) translateY(-22vh) scale(0.46);
  }

  25.9109311741% {
    transform: translateX(49vw) translateY(-14vh) scale(0.7);
  }

  26.3157894737% {
    transform: translateX(-1vw) translateY(-24vh) scale(0.85);
  }

  26.7206477733% {
    transform: translateX(-39vw) translateY(-48vh) scale(0.92);
  }

  27.1255060729% {
    transform: translateX(8vw) translateY(34vh) scale(0.74);
  }

  27.5303643725% {
    transform: translateX(-29vw) translateY(-4vh) scale(0.5);
  }

  27.9352226721% {
    transform: translateX(-17vw) translateY(5vh) scale(0.84);
  }

  28.3400809717% {
    transform: translateX(-47vw) translateY(-5vh) scale(0.49);
  }

  28.7449392713% {
    transform: translateX(27vw) translateY(-24vh) scale(0.92);
  }

  29.1497975709% {
    transform: translateX(-24vw) translateY(0vh) scale(0.39);
  }

  29.5546558704% {
    transform: translateX(18vw) translateY(-37vh) scale(0.43);
  }

  29.95951417% {
    transform: translateX(17vw) translateY(15vh) scale(0.28);
  }

  30.3643724696% {
    transform: translateX(19vw) translateY(12vh) scale(0.94);
  }

  30.7692307692% {
    transform: translateX(12vw) translateY(7vh) scale(0.54);
  }

  31.1740890688% {
    transform: translateX(-18vw) translateY(15vh) scale(0.32);
  }

  31.5789473684% {
    transform: translateX(-34vw) translateY(26vh) scale(0.9);
  }

  31.983805668% {
    transform: translateX(19vw) translateY(49vh) scale(0.9);
  }

  32.3886639676% {
    transform: translateX(-23vw) translateY(-18vh) scale(1);
  }

  32.7935222672% {
    transform: translateX(-15vw) translateY(-25vh) scale(0.46);
  }

  33.1983805668% {
    transform: translateX(32vw) translateY(-47vh) scale(0.69);
  }

  33.6032388664% {
    transform: translateX(49vw) translateY(-36vh) scale(0.55);
  }

  34.008097166% {
    transform: translateX(16vw) translateY(-26vh) scale(0.42);
  }

  34.4129554656% {
    transform: translateX(41vw) translateY(22vh) scale(0.56);
  }

  34.8178137652% {
    transform: translateX(-28vw) translateY(21vh) scale(0.48);
  }

  35.2226720648% {
    transform: translateX(43vw) translateY(-12vh) scale(0.26);
  }

  35.6275303644% {
    transform: translateX(-13vw) translateY(-31vh) scale(0.99);
  }

  36.032388664% {
    transform: translateX(21vw) translateY(27vh) scale(0.84);
  }

  36.4372469636% {
    transform: translateX(2vw) translateY(-35vh) scale(0.42);
  }

  36.8421052632% {
    transform: translateX(19vw) translateY(42vh) scale(0.29);
  }

  37.2469635628% {
    transform: translateX(-7vw) translateY(18vh) scale(0.41);
  }

  37.6518218623% {
    transform: translateX(-8vw) translateY(30vh) scale(0.26);
  }

  38.0566801619% {
    transform: translateX(-31vw) translateY(7vh) scale(0.53);
  }

  38.4615384615% {
    transform: translateX(-22vw) translateY(38vh) scale(0.75);
  }

  38.8663967611% {
    transform: translateX(-31vw) translateY(21vh) scale(0.85);
  }

  39.2712550607% {
    transform: translateX(10vw) translateY(-10vh) scale(0.93);
  }

  39.6761133603% {
    transform: translateX(11vw) translateY(30vh) scale(0.64);
  }

  40.0809716599% {
    transform: translateX(22vw) translateY(45vh) scale(0.46);
  }

  40.4858299595% {
    transform: translateX(-36vw) translateY(2vh) scale(0.27);
  }

  40.8906882591% {
    transform: translateX(-39vw) translateY(-36vh) scale(0.82);
  }

  41.2955465587% {
    transform: translateX(18vw) translateY(-35vh) scale(0.96);
  }

  41.7004048583% {
    transform: translateX(17vw) translateY(15vh) scale(0.82);
  }

  42.1052631579% {
    transform: translateX(-34vw) translateY(31vh) scale(0.32);
  }

  42.5101214575% {
    transform: translateX(5vw) translateY(38vh) scale(0.99);
  }

  42.9149797571% {
    transform: translateX(17vw) translateY(17vh) scale(0.62);
  }

  43.3198380567% {
    transform: translateX(38vw) translateY(-4vh) scale(0.77);
  }

  43.7246963563% {
    transform: translateX(-10vw) translateY(47vh) scale(0.7);
  }

  44.1295546559% {
    transform: translateX(2vw) translateY(-26vh) scale(0.82);
  }

  44.5344129555% {
    transform: translateX(-18vw) translateY(-14vh) scale(0.51);
  }

  44.9392712551% {
    transform: translateX(-5vw) translateY(-35vh) scale(0.96);
  }

  45.3441295547% {
    transform: translateX(12vw) translateY(9vh) scale(0.73);
  }

  45.7489878543% {
    transform: translateX(-9vw) translateY(16vh) scale(0.31);
  }

  46.1538461538% {
    transform: translateX(2vw) translateY(-24vh) scale(0.79);
  }

  46.5587044534% {
    transform: translateX(-28vw) translateY(26vh) scale(0.56);
  }

  46.963562753% {
    transform: translateX(-17vw) translateY(20vh) scale(0.28);
  }

  47.3684210526% {
    transform: translateX(30vw) translateY(-12vh) scale(0.33);
  }

  47.7732793522% {
    transform: translateX(22vw) translateY(-47vh) scale(0.8);
  }

  48.1781376518% {
    transform: translateX(8vw) translateY(-45vh) scale(0.92);
  }

  48.5829959514% {
    transform: translateX(1vw) translateY(44vh) scale(0.73);
  }

  48.987854251% {
    transform: translateX(-21vw) translateY(20vh) scale(0.62);
  }

  49.3927125506% {
    transform: translateX(-16vw) translateY(-23vh) scale(0.78);
  }

  49.7975708502% {
    transform: translateX(-41vw) translateY(1vh) scale(0.47);
  }

  50.2024291498% {
    transform: translateX(39vw) translateY(39vh) scale(0.56);
  }

  50.6072874494% {
    transform: translateX(-34vw) translateY(0vh) scale(0.6);
  }

  51.012145749% {
    transform: translateX(15vw) translateY(-3vh) scale(0.78);
  }

  51.4170040486% {
    transform: translateX(-6vw) translateY(-1vh) scale(0.91);
  }

  51.8218623482% {
    transform: translateX(16vw) translateY(6vh) scale(0.54);
  }

  52.2267206478% {
    transform: translateX(-24vw) translateY(27vh) scale(0.82);
  }

  52.6315789474% {
    transform: translateX(41vw) translateY(-36vh) scale(0.67);
  }

  53.036437247% {
    transform: translateX(15vw) translateY(6vh) scale(0.71);
  }

  53.4412955466% {
    transform: translateX(-28vw) translateY(-34vh) scale(0.66);
  }

  53.8461538462% {
    transform: translateX(-5vw) translateY(44vh) scale(0.91);
  }

  54.2510121457% {
    transform: translateX(-1vw) translateY(29vh) scale(0.6);
  }

  54.6558704453% {
    transform: translateX(-16vw) translateY(-38vh) scale(0.43);
  }

  55.0607287449% {
    transform: translateX(5vw) translateY(30vh) scale(0.28);
  }

  55.4655870445% {
    transform: translateX(14vw) translateY(12vh) scale(0.27);
  }

  55.8704453441% {
    transform: translateX(26vw) translateY(4vh) scale(0.5);
  }

  56.2753036437% {
    transform: translateX(-19vw) translateY(13vh) scale(0.41);
  }

  56.6801619433% {
    transform: translateX(7vw) translateY(-28vh) scale(0.61);
  }

  57.0850202429% {
    transform: translateX(7vw) translateY(-5vh) scale(0.91);
  }

  57.4898785425% {
    transform: translateX(24vw) translateY(-43vh) scale(0.35);
  }

  57.8947368421% {
    transform: translateX(-7vw) translateY(17vh) scale(0.39);
  }

  58.2995951417% {
    transform: translateX(12vw) translateY(-45vh) scale(0.99);
  }

  58.7044534413% {
    transform: translateX(-2vw) translateY(42vh) scale(0.34);
  }

  59.1093117409% {
    transform: translateX(6vw) translateY(-38vh) scale(0.75);
  }

  59.5141700405% {
    transform: translateX(-43vw) translateY(-1vh) scale(0.49);
  }

  59.9190283401% {
    transform: translateX(-4vw) translateY(49vh) scale(0.73);
  }

  60.3238866397% {
    transform: translateX(-43vw) translateY(-17vh) scale(0.45);
  }

  60.7287449393% {
    transform: translateX(-9vw) translateY(13vh) scale(0.61);
  }

  61.1336032389% {
    transform: translateX(13vw) translateY(44vh) scale(0.28);
  }

  61.5384615385% {
    transform: translateX(10vw) translateY(32vh) scale(0.29);
  }

  61.9433198381% {
    transform: translateX(19vw) translateY(-44vh) scale(0.3);
  }

  62.3481781377% {
    transform: translateX(36vw) translateY(-46vh) scale(0.74);
  }

  62.7530364372% {
    transform: translateX(5vw) translateY(12vh) scale(0.6);
  }

  63.1578947368% {
    transform: translateX(-3vw) translateY(-38vh) scale(1);
  }

  63.5627530364% {
    transform: translateX(5vw) translateY(19vh) scale(0.65);
  }

  63.967611336% {
    transform: translateX(6vw) translateY(-6vh) scale(0.67);
  }

  64.3724696356% {
    transform: translateX(-9vw) translateY(-39vh) scale(0.76);
  }

  64.7773279352% {
    transform: translateX(42vw) translateY(31vh) scale(0.85);
  }

  65.1821862348% {
    transform: translateX(34vw) translateY(44vh) scale(0.53);
  }

  65.5870445344% {
    transform: translateX(6vw) translateY(-4vh) scale(0.62);
  }

  65.991902834% {
    transform: translateX(2vw) translateY(2vh) scale(0.71);
  }

  66.3967611336% {
    transform: translateX(42vw) translateY(-22vh) scale(0.29);
  }

  66.8016194332% {
    transform: translateX(-11vw) translateY(-49vh) scale(0.66);
  }

  67.2064777328% {
    transform: translateX(-33vw) translateY(-14vh) scale(0.58);
  }

  67.6113360324% {
    transform: translateX(23vw) translateY(-38vh) scale(0.77);
  }

  68.016194332% {
    transform: translateX(-37vw) translateY(17vh) scale(0.4);
  }

  68.4210526316% {
    transform: translateX(-13vw) translateY(-49vh) scale(0.3);
  }

  68.8259109312% {
    transform: translateX(38vw) translateY(25vh) scale(0.51);
  }

  69.2307692308% {
    transform: translateX(-39vw) translateY(32vh) scale(0.74);
  }

  69.6356275304% {
    transform: translateX(-21vw) translateY(32vh) scale(0.34);
  }

  70.04048583% {
    transform: translateX(-18vw) translateY(-8vh) scale(0.71);
  }

  70.4453441296% {
    transform: translateX(-37vw) translateY(34vh) scale(0.95);
  }

  70.8502024291% {
    transform: translateX(-42vw) translateY(-31vh) scale(0.26);
  }

  71.2550607287% {
    transform: translateX(20vw) translateY(-39vh) scale(0.9);
  }

  71.6599190283% {
    transform: translateX(7vw) translateY(-44vh) scale(0.75);
  }

  72.0647773279% {
    transform: translateX(40vw) translateY(-5vh) scale(0.44);
  }

  72.4696356275% {
    transform: translateX(-12vw) translateY(-16vh) scale(0.54);
  }

  72.8744939271% {
    transform: translateX(-18vw) translateY(1vh) scale(0.79);
  }

  73.2793522267% {
    transform: translateX(-20vw) translateY(0vh) scale(0.63);
  }

  73.6842105263% {
    transform: translateX(-25vw) translateY(44vh) scale(0.39);
  }

  74.0890688259% {
    transform: translateX(-33vw) translateY(3vh) scale(0.39);
  }

  74.4939271255% {
    transform: translateX(24vw) translateY(16vh) scale(0.94);
  }

  74.8987854251% {
    transform: translateX(-29vw) translateY(2vh) scale(0.63);
  }

  75.3036437247% {
    transform: translateX(44vw) translateY(-45vh) scale(0.85);
  }

  75.7085020243% {
    transform: translateX(8vw) translateY(-17vh) scale(0.26);
  }

  76.1133603239% {
    transform: translateX(47vw) translateY(3vh) scale(0.74);
  }

  76.5182186235% {
    transform: translateX(16vw) translateY(-9vh) scale(0.91);
  }

  76.9230769231% {
    transform: translateX(-11vw) translateY(20vh) scale(0.74);
  }

  77.3279352227% {
    transform: translateX(-17vw) translateY(9vh) scale(0.6);
  }

  77.7327935223% {
    transform: translateX(14vw) translateY(-24vh) scale(0.81);
  }

  78.1376518219% {
    transform: translateX(-25vw) translateY(-41vh) scale(0.63);
  }

  78.5425101215% {
    transform: translateX(16vw) translateY(17vh) scale(0.76);
  }

  78.9473684211% {
    transform: translateX(40vw) translateY(-8vh) scale(0.97);
  }

  79.3522267206% {
    transform: translateX(43vw) translateY(-21vh) scale(0.92);
  }

  79.7570850202% {
    transform: translateX(-5vw) translateY(-20vh) scale(0.35);
  }

  80.1619433198% {
    transform: translateX(47vw) translateY(-29vh) scale(0.44);
  }

  80.5668016194% {
    transform: translateX(-1vw) translateY(-36vh) scale(0.99);
  }

  80.971659919% {
    transform: translateX(40vw) translateY(35vh) scale(0.56);
  }

  81.3765182186% {
    transform: translateX(-34vw) translateY(29vh) scale(0.9);
  }

  81.7813765182% {
    transform: translateX(29vw) translateY(41vh) scale(0.6);
  }

  82.1862348178% {
    transform: translateX(-43vw) translateY(39vh) scale(0.39);
  }

  82.5910931174% {
    transform: translateX(-28vw) translateY(-5vh) scale(0.75);
  }

  82.995951417% {
    transform: translateX(-12vw) translateY(12vh) scale(0.98);
  }

  83.4008097166% {
    transform: translateX(34vw) translateY(30vh) scale(0.49);
  }

  83.8056680162% {
    transform: translateX(50vw) translateY(-47vh) scale(0.58);
  }

  84.2105263158% {
    transform: translateX(37vw) translateY(32vh) scale(0.34);
  }

  84.6153846154% {
    transform: translateX(-41vw) translateY(32vh) scale(0.45);
  }

  85.020242915% {
    transform: translateX(-28vw) translateY(0vh) scale(0.8);
  }

  85.4251012146% {
    transform: translateX(16vw) translateY(15vh) scale(0.53);
  }

  85.8299595142% {
    transform: translateX(-12vw) translateY(36vh) scale(0.89);
  }

  86.2348178138% {
    transform: translateX(-25vw) translateY(-44vh) scale(0.34);
  }

  86.6396761134% {
    transform: translateX(42vw) translateY(-48vh) scale(0.41);
  }

  87.044534413% {
    transform: translateX(33vw) translateY(6vh) scale(0.77);
  }

  87.4493927126% {
    transform: translateX(-5vw) translateY(10vh) scale(0.9);
  }

  87.8542510121% {
    transform: translateX(16vw) translateY(0vh) scale(0.61);
  }

  88.2591093117% {
    transform: translateX(42vw) translateY(17vh) scale(0.84);
  }

  88.6639676113% {
    transform: translateX(9vw) translateY(-7vh) scale(0.84);
  }

  89.0688259109% {
    transform: translateX(18vw) translateY(21vh) scale(0.46);
  }

  89.4736842105% {
    transform: translateX(-21vw) translateY(-2vh) scale(0.51);
  }

  89.8785425101% {
    transform: translateX(-48vw) translateY(24vh) scale(0.93);
  }

  90.2834008097% {
    transform: translateX(14vw) translateY(-3vh) scale(0.9);
  }

  90.6882591093% {
    transform: translateX(23vw) translateY(47vh) scale(0.57);
  }

  91.0931174089% {
    transform: translateX(23vw) translateY(28vh) scale(0.3);
  }

  91.4979757085% {
    transform: translateX(-37vw) translateY(50vh) scale(0.79);
  }

  91.9028340081% {
    transform: translateX(-49vw) translateY(-18vh) scale(0.59);
  }

  92.3076923077% {
    transform: translateX(10vw) translateY(2vh) scale(0.67);
  }

  92.7125506073% {
    transform: translateX(23vw) translateY(-15vh) scale(0.98);
  }

  93.1174089069% {
    transform: translateX(31vw) translateY(13vh) scale(0.65);
  }

  93.5222672065% {
    transform: translateX(37vw) translateY(48vh) scale(0.8);
  }

  93.9271255061% {
    transform: translateX(-23vw) translateY(37vh) scale(0.44);
  }

  94.3319838057% {
    transform: translateX(15vw) translateY(-45vh) scale(0.37);
  }

  94.7368421053% {
    transform: translateX(-17vw) translateY(7vh) scale(0.86);
  }

  95.1417004049% {
    transform: translateX(-15vw) translateY(17vh) scale(0.79);
  }

  95.5465587045% {
    transform: translateX(-4vw) translateY(-36vh) scale(0.62);
  }

  95.951417004% {
    transform: translateX(-20vw) translateY(-20vh) scale(0.94);
  }

  96.3562753036% {
    transform: translateX(37vw) translateY(-17vh) scale(0.59);
  }

  96.7611336032% {
    transform: translateX(-13vw) translateY(-27vh) scale(0.88);
  }

  97.1659919028% {
    transform: translateX(-48vw) translateY(-47vh) scale(0.64);
  }

  97.5708502024% {
    transform: translateX(21vw) translateY(-34vh) scale(0.76);
  }

  97.975708502% {
    transform: translateX(-26vw) translateY(-21vh) scale(0.83);
  }

  98.3805668016% {
    transform: translateX(29vw) translateY(38vh) scale(0.32);
  }

  98.7854251012% {
    transform: translateX(31vw) translateY(39vh) scale(0.98);
  }

  99.1902834008% {
    transform: translateX(2vw) translateY(4vh) scale(0.32);
  }

  99.5951417004% {
    transform: translateX(27vw) translateY(-11vh) scale(0.4);
  }

  100% {
    transform: translateX(-4vw) translateY(43vh) scale(0.4);
  }
}

.firefly:nth-child(12) {
  animation-name: move12;
}

.firefly:nth-child(12)::before {
  animation-duration: 18s;
}

.firefly:nth-child(12)::after {
  animation-duration: 18s, 9704ms;
  animation-delay: 0ms, 916ms;
}

@keyframes move12 {
  0% {
    transform: translateX(15vw) translateY(30vh) scale(0.66);
  }

  0.5714285714% {
    transform: translateX(24vw) translateY(32vh) scale(0.65);
  }

  1.1428571429% {
    transform: translateX(37vw) translateY(-2vh) scale(0.82);
  }

  1.7142857143% {
    transform: translateX(-8vw) translateY(36vh) scale(0.97);
  }

  2.2857142857% {
    transform: translateX(50vw) translateY(-47vh) scale(0.56);
  }

  2.8571428571% {
    transform: translateX(-6vw) translateY(-43vh) scale(0.95);
  }

  3.4285714286% {
    transform: translateX(49vw) translateY(39vh) scale(0.83);
  }

  4% {
    transform: translateX(-15vw) translateY(-42vh) scale(0.57);
  }

  4.5714285714% {
    transform: translateX(-17vw) translateY(-14vh) scale(0.43);
  }

  5.1428571429% {
    transform: translateX(26vw) translateY(14vh) scale(0.49);
  }

  5.7142857143% {
    transform: translateX(-40vw) translateY(-33vh) scale(0.57);
  }

  6.2857142857% {
    transform: translateX(42vw) translateY(36vh) scale(0.43);
  }

  6.8571428571% {
    transform: translateX(20vw) translateY(46vh) scale(0.53);
  }

  7.4285714286% {
    transform: translateX(12vw) translateY(-44vh) scale(0.51);
  }

  8% {
    transform: translateX(-35vw) translateY(-10vh) scale(0.79);
  }

  8.5714285714% {
    transform: translateX(-21vw) translateY(14vh) scale(0.72);
  }

  9.1428571429% {
    transform: translateX(-49vw) translateY(31vh) scale(0.92);
  }

  9.7142857143% {
    transform: translateX(-30vw) translateY(-18vh) scale(0.86);
  }

  10.2857142857% {
    transform: translateX(35vw) translateY(-15vh) scale(0.37);
  }

  10.8571428571% {
    transform: translateX(1vw) translateY(49vh) scale(0.85);
  }

  11.4285714286% {
    transform: translateX(-20vw) translateY(9vh) scale(0.44);
  }

  12% {
    transform: translateX(23vw) translateY(0vh) scale(0.49);
  }

  12.5714285714% {
    transform: translateX(-29vw) translateY(27vh) scale(0.84);
  }

  13.1428571429% {
    transform: translateX(44vw) translateY(-15vh) scale(0.34);
  }

  13.7142857143% {
    transform: translateX(4vw) translateY(-22vh) scale(0.47);
  }

  14.2857142857% {
    transform: translateX(-26vw) translateY(-10vh) scale(0.46);
  }

  14.8571428571% {
    transform: translateX(-30vw) translateY(32vh) scale(0.87);
  }

  15.4285714286% {
    transform: translateX(-29vw) translateY(10vh) scale(0.44);
  }

  16% {
    transform: translateX(43vw) translateY(-24vh) scale(0.56);
  }

  16.5714285714% {
    transform: translateX(-29vw) translateY(44vh) scale(0.44);
  }

  17.1428571429% {
    transform: translateX(10vw) translateY(17vh) scale(0.6);
  }

  17.7142857143% {
    transform: translateX(0vw) translateY(-45vh) scale(0.81);
  }

  18.2857142857% {
    transform: translateX(49vw) translateY(-4vh) scale(0.48);
  }

  18.8571428571% {
    transform: translateX(45vw) translateY(-7vh) scale(0.85);
  }

  19.4285714286% {
    transform: translateX(-43vw) translateY(-48vh) scale(0.97);
  }

  20% {
    transform: translateX(18vw) translateY(6vh) scale(0.5);
  }

  20.5714285714% {
    transform: translateX(-23vw) translateY(-36vh) scale(0.83);
  }

  21.1428571429% {
    transform: translateX(-22vw) translateY(-46vh) scale(0.96);
  }

  21.7142857143% {
    transform: translateX(39vw) translateY(24vh) scale(0.48);
  }

  22.2857142857% {
    transform: translateX(8vw) translateY(4vh) scale(0.33);
  }

  22.8571428571% {
    transform: translateX(-16vw) translateY(-27vh) scale(0.84);
  }

  23.4285714286% {
    transform: translateX(-10vw) translateY(46vh) scale(0.81);
  }

  24% {
    transform: translateX(2vw) translateY(26vh) scale(0.53);
  }

  24.5714285714% {
    transform: translateX(-37vw) translateY(6vh) scale(0.96);
  }

  25.1428571429% {
    transform: translateX(46vw) translateY(7vh) scale(0.54);
  }

  25.7142857143% {
    transform: translateX(48vw) translateY(16vh) scale(0.26);
  }

  26.2857142857% {
    transform: translateX(-44vw) translateY(-29vh) scale(0.58);
  }

  26.8571428571% {
    transform: translateX(-15vw) translateY(-34vh) scale(0.49);
  }

  27.4285714286% {
    transform: translateX(-4vw) translateY(42vh) scale(0.44);
  }

  28% {
    transform: translateX(-29vw) translateY(39vh) scale(0.44);
  }

  28.5714285714% {
    transform: translateX(20vw) translateY(46vh) scale(0.88);
  }

  29.1428571429% {
    transform: translateX(-10vw) translateY(-25vh) scale(0.87);
  }

  29.7142857143% {
    transform: translateX(19vw) translateY(-47vh) scale(0.83);
  }

  30.2857142857% {
    transform: translateX(-37vw) translateY(-38vh) scale(0.66);
  }

  30.8571428571% {
    transform: translateX(-35vw) translateY(40vh) scale(0.34);
  }

  31.4285714286% {
    transform: translateX(44vw) translateY(18vh) scale(0.83);
  }

  32% {
    transform: translateX(6vw) translateY(-26vh) scale(1);
  }

  32.5714285714% {
    transform: translateX(-12vw) translateY(40vh) scale(0.7);
  }

  33.1428571429% {
    transform: translateX(-39vw) translateY(-13vh) scale(0.26);
  }

  33.7142857143% {
    transform: translateX(-12vw) translateY(16vh) scale(0.79);
  }

  34.2857142857% {
    transform: translateX(-37vw) translateY(-23vh) scale(0.66);
  }

  34.8571428571% {
    transform: translateX(31vw) translateY(-16vh) scale(0.74);
  }

  35.4285714286% {
    transform: translateX(-13vw) translateY(-47vh) scale(0.34);
  }

  36% {
    transform: translateX(-4vw) translateY(21vh) scale(0.35);
  }

  36.5714285714% {
    transform: translateX(-26vw) translateY(30vh) scale(0.72);
  }

  37.1428571429% {
    transform: translateX(38vw) translateY(-13vh) scale(0.77);
  }

  37.7142857143% {
    transform: translateX(-32vw) translateY(-41vh) scale(0.33);
  }

  38.2857142857% {
    transform: translateX(36vw) translateY(-17vh) scale(0.28);
  }

  38.8571428571% {
    transform: translateX(-7vw) translateY(30vh) scale(0.75);
  }

  39.4285714286% {
    transform: translateX(-5vw) translateY(-48vh) scale(0.28);
  }

  40% {
    transform: translateX(-31vw) translateY(9vh) scale(0.72);
  }

  40.5714285714% {
    transform: translateX(-22vw) translateY(-29vh) scale(0.45);
  }

  41.1428571429% {
    transform: translateX(12vw) translateY(46vh) scale(0.66);
  }

  41.7142857143% {
    transform: translateX(-34vw) translateY(-9vh) scale(0.9);
  }

  42.2857142857% {
    transform: translateX(42vw) translateY(-16vh) scale(0.47);
  }

  42.8571428571% {
    transform: translateX(-34vw) translateY(-34vh) scale(0.34);
  }

  43.4285714286% {
    transform: translateX(26vw) translateY(25vh) scale(0.7);
  }

  44% {
    transform: translateX(12vw) translateY(38vh) scale(0.97);
  }

  44.5714285714% {
    transform: translateX(-4vw) translateY(-3vh) scale(0.39);
  }

  45.1428571429% {
    transform: translateX(21vw) translateY(29vh) scale(0.55);
  }

  45.7142857143% {
    transform: translateX(34vw) translateY(-39vh) scale(0.37);
  }

  46.2857142857% {
    transform: translateX(-49vw) translateY(-33vh) scale(0.42);
  }

  46.8571428571% {
    transform: translateX(34vw) translateY(-12vh) scale(0.57);
  }

  47.4285714286% {
    transform: translateX(5vw) translateY(24vh) scale(0.53);
  }

  48% {
    transform: translateX(19vw) translateY(-15vh) scale(0.53);
  }

  48.5714285714% {
    transform: translateX(-34vw) translateY(-23vh) scale(0.4);
  }

  49.1428571429% {
    transform: translateX(-26vw) translateY(6vh) scale(0.71);
  }

  49.7142857143% {
    transform: translateX(-6vw) translateY(-44vh) scale(0.95);
  }

  50.2857142857% {
    transform: translateX(24vw) translateY(1vh) scale(0.47);
  }

  50.8571428571% {
    transform: translateX(23vw) translateY(-28vh) scale(0.54);
  }

  51.4285714286% {
    transform: translateX(-14vw) translateY(26vh) scale(0.37);
  }

  52% {
    transform: translateX(-47vw) translateY(-16vh) scale(0.48);
  }

  52.5714285714% {
    transform: translateX(-12vw) translateY(24vh) scale(0.27);
  }

  53.1428571429% {
    transform: translateX(22vw) translateY(-18vh) scale(0.69);
  }

  53.7142857143% {
    transform: translateX(9vw) translateY(1vh) scale(0.32);
  }

  54.2857142857% {
    transform: translateX(-36vw) translateY(5vh) scale(0.47);
  }

  54.8571428571% {
    transform: translateX(4vw) translateY(5vh) scale(0.31);
  }

  55.4285714286% {
    transform: translateX(-19vw) translateY(9vh) scale(0.49);
  }

  56% {
    transform: translateX(-39vw) translateY(-10vh) scale(0.9);
  }

  56.5714285714% {
    transform: translateX(-23vw) translateY(40vh) scale(0.73);
  }

  57.1428571429% {
    transform: translateX(24vw) translateY(38vh) scale(0.87);
  }

  57.7142857143% {
    transform: translateX(29vw) translateY(25vh) scale(0.7);
  }

  58.2857142857% {
    transform: translateX(-11vw) translateY(-27vh) scale(0.29);
  }

  58.8571428571% {
    transform: translateX(-42vw) translateY(40vh) scale(0.52);
  }

  59.4285714286% {
    transform: translateX(-43vw) translateY(-49vh) scale(0.56);
  }

  60% {
    transform: translateX(18vw) translateY(29vh) scale(1);
  }

  60.5714285714% {
    transform: translateX(15vw) translateY(-22vh) scale(0.71);
  }

  61.1428571429% {
    transform: translateX(38vw) translateY(7vh) scale(0.46);
  }

  61.7142857143% {
    transform: translateX(23vw) translateY(9vh) scale(0.48);
  }

  62.2857142857% {
    transform: translateX(-33vw) translateY(24vh) scale(0.69);
  }

  62.8571428571% {
    transform: translateX(39vw) translateY(-48vh) scale(0.85);
  }

  63.4285714286% {
    transform: translateX(-47vw) translateY(18vh) scale(0.69);
  }

  64% {
    transform: translateX(48vw) translateY(11vh) scale(0.64);
  }

  64.5714285714% {
    transform: translateX(14vw) translateY(50vh) scale(0.3);
  }

  65.1428571429% {
    transform: translateX(15vw) translateY(23vh) scale(0.51);
  }

  65.7142857143% {
    transform: translateX(-39vw) translateY(45vh) scale(0.41);
  }

  66.2857142857% {
    transform: translateX(-1vw) translateY(47vh) scale(0.83);
  }

  66.8571428571% {
    transform: translateX(35vw) translateY(3vh) scale(0.43);
  }

  67.4285714286% {
    transform: translateX(-6vw) translateY(4vh) scale(0.43);
  }

  68% {
    transform: translateX(27vw) translateY(23vh) scale(0.89);
  }

  68.5714285714% {
    transform: translateX(44vw) translateY(14vh) scale(0.84);
  }

  69.1428571429% {
    transform: translateX(27vw) translateY(28vh) scale(0.46);
  }

  69.7142857143% {
    transform: translateX(30vw) translateY(-49vh) scale(0.52);
  }

  70.2857142857% {
    transform: translateX(20vw) translateY(23vh) scale(0.49);
  }

  70.8571428571% {
    transform: translateX(44vw) translateY(9vh) scale(0.83);
  }

  71.4285714286% {
    transform: translateX(-42vw) translateY(-4vh) scale(0.8);
  }

  72% {
    transform: translateX(47vw) translateY(-47vh) scale(0.38);
  }

  72.5714285714% {
    transform: translateX(43vw) translateY(-48vh) scale(0.63);
  }

  73.1428571429% {
    transform: translateX(21vw) translateY(-23vh) scale(0.84);
  }

  73.7142857143% {
    transform: translateX(24vw) translateY(44vh) scale(0.94);
  }

  74.2857142857% {
    transform: translateX(-22vw) translateY(-2vh) scale(0.75);
  }

  74.8571428571% {
    transform: translateX(16vw) translateY(4vh) scale(0.97);
  }

  75.4285714286% {
    transform: translateX(6vw) translateY(37vh) scale(0.55);
  }

  76% {
    transform: translateX(-10vw) translateY(-44vh) scale(0.56);
  }

  76.5714285714% {
    transform: translateX(10vw) translateY(41vh) scale(0.87);
  }

  77.1428571429% {
    transform: translateX(-32vw) translateY(-41vh) scale(0.61);
  }

  77.7142857143% {
    transform: translateX(24vw) translateY(-49vh) scale(0.81);
  }

  78.2857142857% {
    transform: translateX(2vw) translateY(-33vh) scale(0.59);
  }

  78.8571428571% {
    transform: translateX(-16vw) translateY(14vh) scale(0.64);
  }

  79.4285714286% {
    transform: translateX(27vw) translateY(-46vh) scale(0.78);
  }

  80% {
    transform: translateX(14vw) translateY(-27vh) scale(0.69);
  }

  80.5714285714% {
    transform: translateX(43vw) translateY(-21vh) scale(0.93);
  }

  81.1428571429% {
    transform: translateX(-9vw) translateY(-44vh) scale(0.59);
  }

  81.7142857143% {
    transform: translateX(19vw) translateY(-36vh) scale(0.3);
  }

  82.2857142857% {
    transform: translateX(20vw) translateY(20vh) scale(0.82);
  }

  82.8571428571% {
    transform: translateX(3vw) translateY(49vh) scale(0.9);
  }

  83.4285714286% {
    transform: translateX(13vw) translateY(32vh) scale(0.79);
  }

  84% {
    transform: translateX(-35vw) translateY(-28vh) scale(0.64);
  }

  84.5714285714% {
    transform: translateX(-27vw) translateY(40vh) scale(0.49);
  }

  85.1428571429% {
    transform: translateX(13vw) translateY(15vh) scale(0.4);
  }

  85.7142857143% {
    transform: translateX(8vw) translateY(9vh) scale(0.42);
  }

  86.2857142857% {
    transform: translateX(-5vw) translateY(-24vh) scale(0.54);
  }

  86.8571428571% {
    transform: translateX(48vw) translateY(47vh) scale(0.76);
  }

  87.4285714286% {
    transform: translateX(-44vw) translateY(28vh) scale(0.67);
  }

  88% {
    transform: translateX(-25vw) translateY(-19vh) scale(0.57);
  }

  88.5714285714% {
    transform: translateX(-11vw) translateY(39vh) scale(0.49);
  }

  89.1428571429% {
    transform: translateX(1vw) translateY(-16vh) scale(0.8);
  }

  89.7142857143% {
    transform: translateX(22vw) translateY(-14vh) scale(0.6);
  }

  90.2857142857% {
    transform: translateX(35vw) translateY(-43vh) scale(0.87);
  }

  90.8571428571% {
    transform: translateX(-32vw) translateY(-43vh) scale(0.65);
  }

  91.4285714286% {
    transform: translateX(2vw) translateY(31vh) scale(0.85);
  }

  92% {
    transform: translateX(44vw) translateY(47vh) scale(0.93);
  }

  92.5714285714% {
    transform: translateX(1vw) translateY(-9vh) scale(0.28);
  }

  93.1428571429% {
    transform: translateX(3vw) translateY(29vh) scale(0.51);
  }

  93.7142857143% {
    transform: translateX(33vw) translateY(-37vh) scale(0.76);
  }

  94.2857142857% {
    transform: translateX(8vw) translateY(5vh) scale(0.41);
  }

  94.8571428571% {
    transform: translateX(-3vw) translateY(-10vh) scale(0.46);
  }

  95.4285714286% {
    transform: translateX(26vw) translateY(8vh) scale(0.96);
  }

  96% {
    transform: translateX(-39vw) translateY(-15vh) scale(0.35);
  }

  96.5714285714% {
    transform: translateX(-31vw) translateY(40vh) scale(0.33);
  }

  97.1428571429% {
    transform: translateX(39vw) translateY(-49vh) scale(0.3);
  }

  97.7142857143% {
    transform: translateX(-11vw) translateY(43vh) scale(0.33);
  }

  98.2857142857% {
    transform: translateX(-37vw) translateY(8vh) scale(0.42);
  }

  98.8571428571% {
    transform: translateX(-34vw) translateY(-4vh) scale(0.99);
  }

  99.4285714286% {
    transform: translateX(-48vw) translateY(22vh) scale(0.56);
  }

  100% {
    transform: translateX(36vw) translateY(17vh) scale(0.92);
  }
}

.firefly:nth-child(13) {
  animation-name: move13;
}

.firefly:nth-child(13)::before {
  animation-duration: 15s;
}

.firefly:nth-child(13)::after {
  animation-duration: 15s, 8304ms;
  animation-delay: 0ms, 4899ms;
}

@keyframes move13 {
  0% {
    transform: translateX(-36vw) translateY(-49vh) scale(0.29);
  }

  0.3584229391% {
    transform: translateX(-4vw) translateY(38vh) scale(0.85);
  }

  0.7168458781% {
    transform: translateX(-7vw) translateY(7vh) scale(0.68);
  }

  1.0752688172% {
    transform: translateX(-45vw) translateY(21vh) scale(0.74);
  }

  1.4336917563% {
    transform: translateX(44vw) translateY(27vh) scale(0.93);
  }

  1.7921146953% {
    transform: translateX(10vw) translateY(-31vh) scale(0.6);
  }

  2.1505376344% {
    transform: translateX(-3vw) translateY(-27vh) scale(0.39);
  }

  2.5089605735% {
    transform: translateX(-29vw) translateY(-34vh) scale(0.26);
  }

  2.8673835125% {
    transform: translateX(39vw) translateY(28vh) scale(0.63);
  }

  3.2258064516% {
    transform: translateX(-33vw) translateY(37vh) scale(0.7);
  }

  3.5842293907% {
    transform: translateX(20vw) translateY(36vh) scale(0.36);
  }

  3.9426523297% {
    transform: translateX(24vw) translateY(16vh) scale(0.46);
  }

  4.3010752688% {
    transform: translateX(16vw) translateY(-30vh) scale(0.46);
  }

  4.6594982079% {
    transform: translateX(35vw) translateY(-10vh) scale(0.47);
  }

  5.017921147% {
    transform: translateX(15vw) translateY(-30vh) scale(0.56);
  }

  5.376344086% {
    transform: translateX(-7vw) translateY(-18vh) scale(0.88);
  }

  5.7347670251% {
    transform: translateX(37vw) translateY(20vh) scale(0.47);
  }

  6.0931899642% {
    transform: translateX(-39vw) translateY(37vh) scale(0.61);
  }

  6.4516129032% {
    transform: translateX(31vw) translateY(-16vh) scale(0.56);
  }

  6.8100358423% {
    transform: translateX(5vw) translateY(-49vh) scale(0.61);
  }

  7.1684587814% {
    transform: translateX(46vw) translateY(-28vh) scale(0.51);
  }

  7.5268817204% {
    transform: translateX(33vw) translateY(27vh) scale(0.55);
  }

  7.8853046595% {
    transform: translateX(47vw) translateY(25vh) scale(0.61);
  }

  8.2437275986% {
    transform: translateX(42vw) translateY(-35vh) scale(0.66);
  }

  8.6021505376% {
    transform: translateX(33vw) translateY(-12vh) scale(0.47);
  }

  8.9605734767% {
    transform: translateX(6vw) translateY(-47vh) scale(0.62);
  }

  9.3189964158% {
    transform: translateX(18vw) translateY(6vh) scale(0.52);
  }

  9.6774193548% {
    transform: translateX(-20vw) translateY(9vh) scale(0.48);
  }

  10.0358422939% {
    transform: translateX(-27vw) translateY(7vh) scale(0.52);
  }

  10.394265233% {
    transform: translateX(-32vw) translateY(-21vh) scale(0.46);
  }

  10.752688172% {
    transform: translateX(30vw) translateY(-2vh) scale(0.52);
  }

  11.1111111111% {
    transform: translateX(-2vw) translateY(28vh) scale(0.6);
  }

  11.4695340502% {
    transform: translateX(36vw) translateY(-46vh) scale(0.59);
  }

  11.8279569892% {
    transform: translateX(15vw) translateY(-45vh) scale(0.56);
  }

  12.1863799283% {
    transform: translateX(-36vw) translateY(-38vh) scale(0.4);
  }

  12.5448028674% {
    transform: translateX(27vw) translateY(-21vh) scale(1);
  }

  12.9032258065% {
    transform: translateX(0vw) translateY(-24vh) scale(0.44);
  }

  13.2616487455% {
    transform: translateX(27vw) translateY(-49vh) scale(0.79);
  }

  13.6200716846% {
    transform: translateX(-6vw) translateY(9vh) scale(0.63);
  }

  13.9784946237% {
    transform: translateX(20vw) translateY(9vh) scale(0.4);
  }

  14.3369175627% {
    transform: translateX(1vw) translateY(-1vh) scale(0.77);
  }

  14.6953405018% {
    transform: translateX(-28vw) translateY(19vh) scale(0.45);
  }

  15.0537634409% {
    transform: translateX(-30vw) translateY(7vh) scale(0.59);
  }

  15.4121863799% {
    transform: translateX(4vw) translateY(30vh) scale(0.95);
  }

  15.770609319% {
    transform: translateX(11vw) translateY(-25vh) scale(0.51);
  }

  16.1290322581% {
    transform: translateX(-11vw) translateY(-42vh) scale(1);
  }

  16.4874551971% {
    transform: translateX(29vw) translateY(-26vh) scale(0.62);
  }

  16.8458781362% {
    transform: translateX(6vw) translateY(17vh) scale(0.66);
  }

  17.2043010753% {
    transform: translateX(-43vw) translateY(27vh) scale(0.29);
  }

  17.5627240143% {
    transform: translateX(-29vw) translateY(49vh) scale(0.57);
  }

  17.9211469534% {
    transform: translateX(-18vw) translateY(47vh) scale(0.48);
  }

  18.2795698925% {
    transform: translateX(36vw) translateY(26vh) scale(0.58);
  }

  18.6379928315% {
    transform: translateX(-11vw) translateY(-49vh) scale(0.73);
  }

  18.9964157706% {
    transform: translateX(-26vw) translateY(-46vh) scale(0.37);
  }

  19.3548387097% {
    transform: translateX(25vw) translateY(-6vh) scale(0.33);
  }

  19.7132616487% {
    transform: translateX(-20vw) translateY(-38vh) scale(0.72);
  }

  20.0716845878% {
    transform: translateX(13vw) translateY(-44vh) scale(0.89);
  }

  20.4301075269% {
    transform: translateX(45vw) translateY(-43vh) scale(0.26);
  }

  20.7885304659% {
    transform: translateX(-22vw) translateY(-20vh) scale(0.4);
  }

  21.146953405% {
    transform: translateX(39vw) translateY(16vh) scale(0.39);
  }

  21.5053763441% {
    transform: translateX(47vw) translateY(-3vh) scale(0.33);
  }

  21.8637992832% {
    transform: translateX(-20vw) translateY(-1vh) scale(0.84);
  }

  22.2222222222% {
    transform: translateX(-41vw) translateY(-5vh) scale(0.34);
  }

  22.5806451613% {
    transform: translateX(38vw) translateY(43vh) scale(0.94);
  }

  22.9390681004% {
    transform: translateX(-49vw) translateY(-32vh) scale(0.65);
  }

  23.2974910394% {
    transform: translateX(9vw) translateY(3vh) scale(0.53);
  }

  23.6559139785% {
    transform: translateX(-4vw) translateY(36vh) scale(0.47);
  }

  24.0143369176% {
    transform: translateX(31vw) translateY(39vh) scale(0.83);
  }

  24.3727598566% {
    transform: translateX(19vw) translateY(46vh) scale(0.3);
  }

  24.7311827957% {
    transform: translateX(42vw) translateY(19vh) scale(0.92);
  }

  25.0896057348% {
    transform: translateX(36vw) translateY(33vh) scale(0.87);
  }

  25.4480286738% {
    transform: translateX(-49vw) translateY(16vh) scale(0.44);
  }

  25.8064516129% {
    transform: translateX(-20vw) translateY(44vh) scale(0.45);
  }

  26.164874552% {
    transform: translateX(35vw) translateY(5vh) scale(0.31);
  }

  26.523297491% {
    transform: translateX(-18vw) translateY(-11vh) scale(0.55);
  }

  26.8817204301% {
    transform: translateX(-34vw) translateY(18vh) scale(0.37);
  }

  27.2401433692% {
    transform: translateX(7vw) translateY(-49vh) scale(0.96);
  }

  27.5985663082% {
    transform: translateX(29vw) translateY(36vh) scale(0.29);
  }

  27.9569892473% {
    transform: translateX(46vw) translateY(-28vh) scale(0.78);
  }

  28.3154121864% {
    transform: translateX(-20vw) translateY(45vh) scale(0.58);
  }

  28.6738351254% {
    transform: translateX(33vw) translateY(-37vh) scale(0.89);
  }

  29.0322580645% {
    transform: translateX(40vw) translateY(-16vh) scale(0.74);
  }

  29.3906810036% {
    transform: translateX(-15vw) translateY(36vh) scale(0.27);
  }

  29.7491039427% {
    transform: translateX(31vw) translateY(38vh) scale(1);
  }

  30.1075268817% {
    transform: translateX(-42vw) translateY(12vh) scale(0.46);
  }

  30.4659498208% {
    transform: translateX(-11vw) translateY(-23vh) scale(0.45);
  }

  30.8243727599% {
    transform: translateX(38vw) translateY(-42vh) scale(0.61);
  }

  31.1827956989% {
    transform: translateX(-25vw) translateY(-18vh) scale(0.83);
  }

  31.541218638% {
    transform: translateX(41vw) translateY(-29vh) scale(0.31);
  }

  31.8996415771% {
    transform: translateX(-48vw) translateY(22vh) scale(0.75);
  }

  32.2580645161% {
    transform: translateX(15vw) translateY(38vh) scale(0.31);
  }

  32.6164874552% {
    transform: translateX(-34vw) translateY(-47vh) scale(0.57);
  }

  32.9749103943% {
    transform: translateX(-23vw) translateY(33vh) scale(0.51);
  }

  33.3333333333% {
    transform: translateX(4vw) translateY(10vh) scale(0.98);
  }

  33.6917562724% {
    transform: translateX(-3vw) translateY(-22vh) scale(1);
  }

  34.0501792115% {
    transform: translateX(13vw) translateY(-22vh) scale(0.9);
  }

  34.4086021505% {
    transform: translateX(-47vw) translateY(-26vh) scale(0.72);
  }

  34.7670250896% {
    transform: translateX(26vw) translateY(7vh) scale(0.29);
  }

  35.1254480287% {
    transform: translateX(38vw) translateY(9vh) scale(0.47);
  }

  35.4838709677% {
    transform: translateX(-49vw) translateY(-31vh) scale(0.71);
  }

  35.8422939068% {
    transform: translateX(17vw) translateY(6vh) scale(1);
  }

  36.2007168459% {
    transform: translateX(44vw) translateY(36vh) scale(0.52);
  }

  36.5591397849% {
    transform: translateX(37vw) translateY(1vh) scale(0.29);
  }

  36.917562724% {
    transform: translateX(42vw) translateY(-19vh) scale(0.54);
  }

  37.2759856631% {
    transform: translateX(9vw) translateY(36vh) scale(0.53);
  }

  37.6344086022% {
    transform: translateX(-3vw) translateY(41vh) scale(0.61);
  }

  37.9928315412% {
    transform: translateX(-36vw) translateY(-34vh) scale(0.32);
  }

  38.3512544803% {
    transform: translateX(1vw) translateY(21vh) scale(0.67);
  }

  38.7096774194% {
    transform: translateX(-23vw) translateY(32vh) scale(0.58);
  }

  39.0681003584% {
    transform: translateX(0vw) translateY(20vh) scale(0.88);
  }

  39.4265232975% {
    transform: translateX(40vw) translateY(-10vh) scale(0.63);
  }

  39.7849462366% {
    transform: translateX(28vw) translateY(36vh) scale(0.89);
  }

  40.1433691756% {
    transform: translateX(19vw) translateY(38vh) scale(0.39);
  }

  40.5017921147% {
    transform: translateX(-13vw) translateY(42vh) scale(0.71);
  }

  40.8602150538% {
    transform: translateX(31vw) translateY(-40vh) scale(0.89);
  }

  41.2186379928% {
    transform: translateX(-15vw) translateY(46vh) scale(0.37);
  }

  41.5770609319% {
    transform: translateX(-4vw) translateY(-35vh) scale(0.83);
  }

  41.935483871% {
    transform: translateX(12vw) translateY(-30vh) scale(0.77);
  }

  42.29390681% {
    transform: translateX(-32vw) translateY(32vh) scale(0.86);
  }

  42.6523297491% {
    transform: translateX(-42vw) translateY(-41vh) scale(0.45);
  }

  43.0107526882% {
    transform: translateX(36vw) translateY(26vh) scale(0.61);
  }

  43.3691756272% {
    transform: translateX(10vw) translateY(-33vh) scale(0.61);
  }

  43.7275985663% {
    transform: translateX(49vw) translateY(-29vh) scale(0.55);
  }

  44.0860215054% {
    transform: translateX(28vw) translateY(-18vh) scale(0.54);
  }

  44.4444444444% {
    transform: translateX(-1vw) translateY(47vh) scale(0.78);
  }

  44.8028673835% {
    transform: translateX(-6vw) translateY(-37vh) scale(0.42);
  }

  45.1612903226% {
    transform: translateX(-22vw) translateY(-21vh) scale(0.93);
  }

  45.5197132616% {
    transform: translateX(-34vw) translateY(-11vh) scale(0.92);
  }

  45.8781362007% {
    transform: translateX(21vw) translateY(44vh) scale(0.46);
  }

  46.2365591398% {
    transform: translateX(24vw) translateY(-6vh) scale(0.79);
  }

  46.5949820789% {
    transform: translateX(16vw) translateY(-28vh) scale(0.52);
  }

  46.9534050179% {
    transform: translateX(33vw) translateY(37vh) scale(0.91);
  }

  47.311827957% {
    transform: translateX(4vw) translateY(17vh) scale(0.55);
  }

  47.6702508961% {
    transform: translateX(-16vw) translateY(31vh) scale(0.97);
  }

  48.0286738351% {
    transform: translateX(-19vw) translateY(-2vh) scale(0.94);
  }

  48.3870967742% {
    transform: translateX(-49vw) translateY(22vh) scale(0.72);
  }

  48.7455197133% {
    transform: translateX(-25vw) translateY(11vh) scale(0.98);
  }

  49.1039426523% {
    transform: translateX(-14vw) translateY(38vh) scale(0.96);
  }

  49.4623655914% {
    transform: translateX(-8vw) translateY(4vh) scale(0.35);
  }

  49.8207885305% {
    transform: translateX(-28vw) translateY(-11vh) scale(0.27);
  }

  50.1792114695% {
    transform: translateX(-9vw) translateY(38vh) scale(0.85);
  }

  50.5376344086% {
    transform: translateX(-9vw) translateY(16vh) scale(0.3);
  }

  50.8960573477% {
    transform: translateX(-15vw) translateY(-18vh) scale(0.43);
  }

  51.2544802867% {
    transform: translateX(-32vw) translateY(19vh) scale(0.32);
  }

  51.6129032258% {
    transform: translateX(-8vw) translateY(-23vh) scale(0.36);
  }

  51.9713261649% {
    transform: translateX(-10vw) translateY(-13vh) scale(0.32);
  }

  52.3297491039% {
    transform: translateX(22vw) translateY(-36vh) scale(0.94);
  }

  52.688172043% {
    transform: translateX(16vw) translateY(-23vh) scale(0.9);
  }

  53.0465949821% {
    transform: translateX(33vw) translateY(46vh) scale(0.81);
  }

  53.4050179211% {
    transform: translateX(0vw) translateY(29vh) scale(0.84);
  }

  53.7634408602% {
    transform: translateX(-10vw) translateY(25vh) scale(0.89);
  }

  54.1218637993% {
    transform: translateX(-39vw) translateY(-6vh) scale(0.43);
  }

  54.4802867384% {
    transform: translateX(-45vw) translateY(-46vh) scale(0.52);
  }

  54.8387096774% {
    transform: translateX(-27vw) translateY(24vh) scale(0.9);
  }

  55.1971326165% {
    transform: translateX(24vw) translateY(-28vh) scale(0.31);
  }

  55.5555555556% {
    transform: translateX(-26vw) translateY(24vh) scale(0.82);
  }

  55.9139784946% {
    transform: translateX(14vw) translateY(-47vh) scale(0.42);
  }

  56.2724014337% {
    transform: translateX(-29vw) translateY(-38vh) scale(0.7);
  }

  56.6308243728% {
    transform: translateX(-1vw) translateY(41vh) scale(0.26);
  }

  56.9892473118% {
    transform: translateX(26vw) translateY(-17vh) scale(0.6);
  }

  57.3476702509% {
    transform: translateX(36vw) translateY(-26vh) scale(0.56);
  }

  57.70609319% {
    transform: translateX(36vw) translateY(50vh) scale(0.7);
  }

  58.064516129% {
    transform: translateX(-32vw) translateY(-5vh) scale(0.36);
  }

  58.4229390681% {
    transform: translateX(-12vw) translateY(-9vh) scale(0.9);
  }

  58.7813620072% {
    transform: translateX(21vw) translateY(-27vh) scale(0.42);
  }

  59.1397849462% {
    transform: translateX(8vw) translateY(35vh) scale(0.43);
  }

  59.4982078853% {
    transform: translateX(-16vw) translateY(44vh) scale(0.83);
  }

  59.8566308244% {
    transform: translateX(-47vw) translateY(26vh) scale(0.95);
  }

  60.2150537634% {
    transform: translateX(24vw) translateY(10vh) scale(0.27);
  }

  60.5734767025% {
    transform: translateX(-28vw) translateY(26vh) scale(0.52);
  }

  60.9318996416% {
    transform: translateX(-37vw) translateY(41vh) scale(0.63);
  }

  61.2903225806% {
    transform: translateX(-47vw) translateY(5vh) scale(0.3);
  }

  61.6487455197% {
    transform: translateX(2vw) translateY(36vh) scale(0.58);
  }

  62.0071684588% {
    transform: translateX(-8vw) translateY(-1vh) scale(0.68);
  }

  62.3655913978% {
    transform: translateX(-3vw) translateY(-17vh) scale(0.96);
  }

  62.7240143369% {
    transform: translateX(21vw) translateY(-5vh) scale(0.98);
  }

  63.082437276% {
    transform: translateX(19vw) translateY(-46vh) scale(0.35);
  }

  63.4408602151% {
    transform: translateX(11vw) translateY(-46vh) scale(0.45);
  }

  63.7992831541% {
    transform: translateX(-41vw) translateY(11vh) scale(0.48);
  }

  64.1577060932% {
    transform: translateX(37vw) translateY(-29vh) scale(0.84);
  }

  64.5161290323% {
    transform: translateX(7vw) translateY(-23vh) scale(0.99);
  }

  64.8745519713% {
    transform: translateX(-14vw) translateY(-31vh) scale(0.36);
  }

  65.2329749104% {
    transform: translateX(-48vw) translateY(-25vh) scale(0.85);
  }

  65.5913978495% {
    transform: translateX(24vw) translateY(15vh) scale(0.56);
  }

  65.9498207885% {
    transform: translateX(-46vw) translateY(48vh) scale(0.33);
  }

  66.3082437276% {
    transform: translateX(17vw) translateY(0vh) scale(1);
  }

  66.6666666667% {
    transform: translateX(50vw) translateY(-16vh) scale(0.46);
  }

  67.0250896057% {
    transform: translateX(-6vw) translateY(-21vh) scale(0.89);
  }

  67.3835125448% {
    transform: translateX(-24vw) translateY(35vh) scale(0.34);
  }

  67.7419354839% {
    transform: translateX(-25vw) translateY(-21vh) scale(0.65);
  }

  68.1003584229% {
    transform: translateX(40vw) translateY(-26vh) scale(0.26);
  }

  68.458781362% {
    transform: translateX(-44vw) translateY(19vh) scale(0.49);
  }

  68.8172043011% {
    transform: translateX(-25vw) translateY(1vh) scale(0.42);
  }

  69.1756272401% {
    transform: translateX(-40vw) translateY(45vh) scale(0.97);
  }

  69.5340501792% {
    transform: translateX(-46vw) translateY(21vh) scale(0.31);
  }

  69.8924731183% {
    transform: translateX(6vw) translateY(13vh) scale(0.66);
  }

  70.2508960573% {
    transform: translateX(-10vw) translateY(-18vh) scale(0.36);
  }

  70.6093189964% {
    transform: translateX(28vw) translateY(-16vh) scale(0.36);
  }

  70.9677419355% {
    transform: translateX(47vw) translateY(24vh) scale(0.57);
  }

  71.3261648746% {
    transform: translateX(21vw) translateY(13vh) scale(0.56);
  }

  71.6845878136% {
    transform: translateX(-15vw) translateY(16vh) scale(0.45);
  }

  72.0430107527% {
    transform: translateX(-34vw) translateY(-30vh) scale(0.47);
  }

  72.4014336918% {
    transform: translateX(-34vw) translateY(-14vh) scale(0.39);
  }

  72.7598566308% {
    transform: translateX(33vw) translateY(-11vh) scale(0.32);
  }

  73.1182795699% {
    transform: translateX(-12vw) translateY(39vh) scale(0.26);
  }

  73.476702509% {
    transform: translateX(-46vw) translateY(42vh) scale(0.44);
  }

  73.835125448% {
    transform: translateX(19vw) translateY(-38vh) scale(0.5);
  }

  74.1935483871% {
    transform: translateX(-5vw) translateY(-14vh) scale(0.83);
  }

  74.5519713262% {
    transform: translateX(27vw) translateY(8vh) scale(0.35);
  }

  74.9103942652% {
    transform: translateX(-9vw) translateY(-44vh) scale(0.68);
  }

  75.2688172043% {
    transform: translateX(-10vw) translateY(27vh) scale(0.79);
  }

  75.6272401434% {
    transform: translateX(20vw) translateY(-21vh) scale(0.86);
  }

  75.9856630824% {
    transform: translateX(45vw) translateY(-46vh) scale(0.49);
  }

  76.3440860215% {
    transform: translateX(23vw) translateY(28vh) scale(0.76);
  }

  76.7025089606% {
    transform: translateX(35vw) translateY(-7vh) scale(0.44);
  }

  77.0609318996% {
    transform: translateX(12vw) translateY(-41vh) scale(0.42);
  }

  77.4193548387% {
    transform: translateX(30vw) translateY(-49vh) scale(0.46);
  }

  77.7777777778% {
    transform: translateX(-26vw) translateY(-25vh) scale(0.29);
  }

  78.1362007168% {
    transform: translateX(-3vw) translateY(-25vh) scale(0.34);
  }

  78.4946236559% {
    transform: translateX(-34vw) translateY(-17vh) scale(1);
  }

  78.853046595% {
    transform: translateX(-40vw) translateY(26vh) scale(0.73);
  }

  79.2114695341% {
    transform: translateX(27vw) translateY(-35vh) scale(0.3);
  }

  79.5698924731% {
    transform: translateX(-33vw) translateY(-21vh) scale(0.99);
  }

  79.9283154122% {
    transform: translateX(-7vw) translateY(26vh) scale(0.91);
  }

  80.2867383513% {
    transform: translateX(-1vw) translateY(-27vh) scale(0.44);
  }

  80.6451612903% {
    transform: translateX(13vw) translateY(27vh) scale(0.39);
  }

  81.0035842294% {
    transform: translateX(5vw) translateY(31vh) scale(0.5);
  }

  81.3620071685% {
    transform: translateX(-38vw) translateY(47vh) scale(0.57);
  }

  81.7204301075% {
    transform: translateX(-30vw) translateY(29vh) scale(0.52);
  }

  82.0788530466% {
    transform: translateX(42vw) translateY(22vh) scale(0.8);
  }

  82.4372759857% {
    transform: translateX(4vw) translateY(32vh) scale(0.7);
  }

  82.7956989247% {
    transform: translateX(-4vw) translateY(18vh) scale(0.91);
  }

  83.1541218638% {
    transform: translateX(39vw) translateY(-9vh) scale(0.81);
  }

  83.5125448029% {
    transform: translateX(-3vw) translateY(-25vh) scale(0.3);
  }

  83.8709677419% {
    transform: translateX(-48vw) translateY(42vh) scale(0.46);
  }

  84.229390681% {
    transform: translateX(-41vw) translateY(-34vh) scale(0.69);
  }

  84.5878136201% {
    transform: translateX(44vw) translateY(8vh) scale(0.94);
  }

  84.9462365591% {
    transform: translateX(-6vw) translateY(-41vh) scale(0.78);
  }

  85.3046594982% {
    transform: translateX(-10vw) translateY(9vh) scale(0.65);
  }

  85.6630824373% {
    transform: translateX(12vw) translateY(21vh) scale(0.98);
  }

  86.0215053763% {
    transform: translateX(5vw) translateY(-42vh) scale(0.95);
  }

  86.3799283154% {
    transform: translateX(-4vw) translateY(-10vh) scale(0.98);
  }

  86.7383512545% {
    transform: translateX(4vw) translateY(33vh) scale(0.58);
  }

  87.0967741935% {
    transform: translateX(48vw) translateY(-41vh) scale(0.34);
  }

  87.4551971326% {
    transform: translateX(-29vw) translateY(-8vh) scale(0.99);
  }

  87.8136200717% {
    transform: translateX(-23vw) translateY(-14vh) scale(0.27);
  }

  88.1720430108% {
    transform: translateX(12vw) translateY(3vh) scale(0.64);
  }

  88.5304659498% {
    transform: translateX(4vw) translateY(46vh) scale(0.75);
  }

  88.8888888889% {
    transform: translateX(-20vw) translateY(-48vh) scale(0.56);
  }

  89.247311828% {
    transform: translateX(-12vw) translateY(-44vh) scale(0.61);
  }

  89.605734767% {
    transform: translateX(3vw) translateY(31vh) scale(0.95);
  }

  89.9641577061% {
    transform: translateX(-3vw) translateY(-13vh) scale(0.35);
  }

  90.3225806452% {
    transform: translateX(-8vw) translateY(12vh) scale(0.85);
  }

  90.6810035842% {
    transform: translateX(-42vw) translateY(7vh) scale(0.53);
  }

  91.0394265233% {
    transform: translateX(39vw) translateY(40vh) scale(0.54);
  }

  91.3978494624% {
    transform: translateX(-7vw) translateY(7vh) scale(0.8);
  }

  91.7562724014% {
    transform: translateX(16vw) translateY(16vh) scale(0.32);
  }

  92.1146953405% {
    transform: translateX(37vw) translateY(-15vh) scale(0.48);
  }

  92.4731182796% {
    transform: translateX(-5vw) translateY(16vh) scale(0.94);
  }

  92.8315412186% {
    transform: translateX(31vw) translateY(-34vh) scale(0.32);
  }

  93.1899641577% {
    transform: translateX(-2vw) translateY(-30vh) scale(0.26);
  }

  93.5483870968% {
    transform: translateX(48vw) translateY(29vh) scale(0.58);
  }

  93.9068100358% {
    transform: translateX(9vw) translateY(-19vh) scale(0.65);
  }

  94.2652329749% {
    transform: translateX(40vw) translateY(49vh) scale(0.57);
  }

  94.623655914% {
    transform: translateX(11vw) translateY(50vh) scale(0.97);
  }

  94.982078853% {
    transform: translateX(-29vw) translateY(27vh) scale(0.6);
  }

  95.3405017921% {
    transform: translateX(28vw) translateY(-23vh) scale(0.73);
  }

  95.6989247312% {
    transform: translateX(30vw) translateY(-44vh) scale(0.45);
  }

  96.0573476703% {
    transform: translateX(24vw) translateY(33vh) scale(0.69);
  }

  96.4157706093% {
    transform: translateX(44vw) translateY(30vh) scale(0.35);
  }

  96.7741935484% {
    transform: translateX(-10vw) translateY(43vh) scale(0.7);
  }

  97.1326164875% {
    transform: translateX(-49vw) translateY(42vh) scale(0.85);
  }

  97.4910394265% {
    transform: translateX(-3vw) translateY(-20vh) scale(0.88);
  }

  97.8494623656% {
    transform: translateX(31vw) translateY(34vh) scale(0.69);
  }

  98.2078853047% {
    transform: translateX(-42vw) translateY(7vh) scale(0.62);
  }

  98.5663082437% {
    transform: translateX(37vw) translateY(-44vh) scale(0.69);
  }

  98.9247311828% {
    transform: translateX(49vw) translateY(39vh) scale(0.98);
  }

  99.2831541219% {
    transform: translateX(34vw) translateY(21vh) scale(0.98);
  }

  99.6415770609% {
    transform: translateX(-23vw) translateY(-35vh) scale(0.4);
  }

  100% {
    transform: translateX(47vw) translateY(18vh) scale(0.38);
  }
}

.firefly:nth-child(14) {
  animation-name: move14;
}

.firefly:nth-child(14)::before {
  animation-duration: 9s;
}

.firefly:nth-child(14)::after {
  animation-duration: 9s, 9224ms;
  animation-delay: 0ms, 6159ms;
}

@keyframes move14 {
  0% {
    transform: translateX(15vw) translateY(-39vh) scale(0.5);
  }

  0.5102040816% {
    transform: translateX(-28vw) translateY(7vh) scale(0.87);
  }

  1.0204081633% {
    transform: translateX(-40vw) translateY(24vh) scale(0.51);
  }

  1.5306122449% {
    transform: translateX(-17vw) translateY(3vh) scale(0.55);
  }

  2.0408163265% {
    transform: translateX(43vw) translateY(43vh) scale(0.98);
  }

  2.5510204082% {
    transform: translateX(-18vw) translateY(19vh) scale(0.92);
  }

  3.0612244898% {
    transform: translateX(-44vw) translateY(4vh) scale(0.35);
  }

  3.5714285714% {
    transform: translateX(-47vw) translateY(-6vh) scale(0.87);
  }

  4.0816326531% {
    transform: translateX(-48vw) translateY(-12vh) scale(0.49);
  }

  4.5918367347% {
    transform: translateX(22vw) translateY(37vh) scale(0.7);
  }

  5.1020408163% {
    transform: translateX(-44vw) translateY(13vh) scale(0.41);
  }

  5.612244898% {
    transform: translateX(9vw) translateY(-45vh) scale(0.73);
  }

  6.1224489796% {
    transform: translateX(-26vw) translateY(-24vh) scale(0.97);
  }

  6.6326530612% {
    transform: translateX(23vw) translateY(13vh) scale(0.64);
  }

  7.1428571429% {
    transform: translateX(-9vw) translateY(-3vh) scale(0.81);
  }

  7.6530612245% {
    transform: translateX(-25vw) translateY(-26vh) scale(0.78);
  }

  8.1632653061% {
    transform: translateX(39vw) translateY(24vh) scale(0.84);
  }

  8.6734693878% {
    transform: translateX(37vw) translateY(18vh) scale(0.75);
  }

  9.1836734694% {
    transform: translateX(-3vw) translateY(38vh) scale(0.42);
  }

  9.693877551% {
    transform: translateX(-14vw) translateY(37vh) scale(0.66);
  }

  10.2040816327% {
    transform: translateX(-26vw) translateY(36vh) scale(0.64);
  }

  10.7142857143% {
    transform: translateX(-49vw) translateY(-24vh) scale(0.43);
  }

  11.2244897959% {
    transform: translateX(49vw) translateY(-9vh) scale(0.52);
  }

  11.7346938776% {
    transform: translateX(-23vw) translateY(-2vh) scale(0.86);
  }

  12.2448979592% {
    transform: translateX(-1vw) translateY(2vh) scale(0.43);
  }

  12.7551020408% {
    transform: translateX(-40vw) translateY(-26vh) scale(0.99);
  }

  13.2653061224% {
    transform: translateX(-4vw) translateY(-4vh) scale(0.61);
  }

  13.7755102041% {
    transform: translateX(-21vw) translateY(50vh) scale(0.38);
  }

  14.2857142857% {
    transform: translateX(-9vw) translateY(-20vh) scale(0.78);
  }

  14.7959183673% {
    transform: translateX(-34vw) translateY(-22vh) scale(0.8);
  }

  15.306122449% {
    transform: translateX(-1vw) translateY(-3vh) scale(0.93);
  }

  15.8163265306% {
    transform: translateX(-44vw) translateY(-28vh) scale(0.73);
  }

  16.3265306122% {
    transform: translateX(41vw) translateY(33vh) scale(0.3);
  }

  16.8367346939% {
    transform: translateX(19vw) translateY(28vh) scale(0.95);
  }

  17.3469387755% {
    transform: translateX(-40vw) translateY(22vh) scale(0.41);
  }

  17.8571428571% {
    transform: translateX(-4vw) translateY(50vh) scale(0.82);
  }

  18.3673469388% {
    transform: translateX(47vw) translateY(28vh) scale(0.44);
  }

  18.8775510204% {
    transform: translateX(38vw) translateY(41vh) scale(0.36);
  }

  19.387755102% {
    transform: translateX(29vw) translateY(-42vh) scale(0.5);
  }

  19.8979591837% {
    transform: translateX(14vw) translateY(18vh) scale(0.39);
  }

  20.4081632653% {
    transform: translateX(2vw) translateY(30vh) scale(0.31);
  }

  20.9183673469% {
    transform: translateX(-20vw) translateY(36vh) scale(0.38);
  }

  21.4285714286% {
    transform: translateX(28vw) translateY(50vh) scale(0.4);
  }

  21.9387755102% {
    transform: translateX(-19vw) translateY(14vh) scale(0.59);
  }

  22.4489795918% {
    transform: translateX(45vw) translateY(1vh) scale(0.61);
  }

  22.9591836735% {
    transform: translateX(-29vw) translateY(-45vh) scale(0.64);
  }

  23.4693877551% {
    transform: translateX(-6vw) translateY(-45vh) scale(0.51);
  }

  23.9795918367% {
    transform: translateX(-39vw) translateY(-7vh) scale(0.73);
  }

  24.4897959184% {
    transform: translateX(-5vw) translateY(-45vh) scale(0.32);
  }

  25% {
    transform: translateX(16vw) translateY(-44vh) scale(0.67);
  }

  25.5102040816% {
    transform: translateX(30vw) translateY(38vh) scale(0.28);
  }

  26.0204081633% {
    transform: translateX(-33vw) translateY(-3vh) scale(0.82);
  }

  26.5306122449% {
    transform: translateX(-20vw) translateY(44vh) scale(0.57);
  }

  27.0408163265% {
    transform: translateX(-15vw) translateY(-37vh) scale(0.71);
  }

  27.5510204082% {
    transform: translateX(35vw) translateY(-39vh) scale(0.53);
  }

  28.0612244898% {
    transform: translateX(-1vw) translateY(-2vh) scale(0.28);
  }

  28.5714285714% {
    transform: translateX(-26vw) translateY(-31vh) scale(0.85);
  }

  29.0816326531% {
    transform: translateX(34vw) translateY(-40vh) scale(0.63);
  }

  29.5918367347% {
    transform: translateX(16vw) translateY(-22vh) scale(0.77);
  }

  30.1020408163% {
    transform: translateX(35vw) translateY(50vh) scale(0.63);
  }

  30.612244898% {
    transform: translateX(-47vw) translateY(21vh) scale(0.36);
  }

  31.1224489796% {
    transform: translateX(50vw) translateY(-42vh) scale(0.95);
  }

  31.6326530612% {
    transform: translateX(5vw) translateY(-32vh) scale(0.33);
  }

  32.1428571429% {
    transform: translateX(-8vw) translateY(18vh) scale(0.46);
  }

  32.6530612245% {
    transform: translateX(17vw) translateY(-22vh) scale(0.41);
  }

  33.1632653061% {
    transform: translateX(28vw) translateY(38vh) scale(0.38);
  }

  33.6734693878% {
    transform: translateX(21vw) translateY(-16vh) scale(0.69);
  }

  34.1836734694% {
    transform: translateX(-29vw) translateY(29vh) scale(0.89);
  }

  34.693877551% {
    transform: translateX(34vw) translateY(38vh) scale(0.74);
  }

  35.2040816327% {
    transform: translateX(1vw) translateY(-3vh) scale(0.82);
  }

  35.7142857143% {
    transform: translateX(-16vw) translateY(8vh) scale(0.62);
  }

  36.2244897959% {
    transform: translateX(-7vw) translateY(5vh) scale(0.47);
  }

  36.7346938776% {
    transform: translateX(48vw) translateY(11vh) scale(0.57);
  }

  37.2448979592% {
    transform: translateX(-49vw) translateY(32vh) scale(0.87);
  }

  37.7551020408% {
    transform: translateX(41vw) translateY(42vh) scale(0.47);
  }

  38.2653061224% {
    transform: translateX(-48vw) translateY(-46vh) scale(0.74);
  }

  38.7755102041% {
    transform: translateX(-45vw) translateY(-19vh) scale(0.42);
  }

  39.2857142857% {
    transform: translateX(-33vw) translateY(50vh) scale(0.88);
  }

  39.7959183673% {
    transform: translateX(2vw) translateY(46vh) scale(0.52);
  }

  40.306122449% {
    transform: translateX(24vw) translateY(6vh) scale(0.59);
  }

  40.8163265306% {
    transform: translateX(-19vw) translateY(38vh) scale(0.32);
  }

  41.3265306122% {
    transform: translateX(37vw) translateY(41vh) scale(0.39);
  }

  41.8367346939% {
    transform: translateX(1vw) translateY(-6vh) scale(0.83);
  }

  42.3469387755% {
    transform: translateX(-27vw) translateY(-43vh) scale(0.3);
  }

  42.8571428571% {
    transform: translateX(-4vw) translateY(18vh) scale(0.27);
  }

  43.3673469388% {
    transform: translateX(21vw) translateY(-37vh) scale(0.87);
  }

  43.8775510204% {
    transform: translateX(34vw) translateY(-31vh) scale(0.99);
  }

  44.387755102% {
    transform: translateX(26vw) translateY(14vh) scale(0.89);
  }

  44.8979591837% {
    transform: translateX(-19vw) translateY(19vh) scale(0.38);
  }

  45.4081632653% {
    transform: translateX(-3vw) translateY(44vh) scale(0.82);
  }

  45.9183673469% {
    transform: translateX(-8vw) translateY(2vh) scale(0.93);
  }

  46.4285714286% {
    transform: translateX(4vw) translateY(-9vh) scale(0.8);
  }

  46.9387755102% {
    transform: translateX(-24vw) translateY(22vh) scale(0.94);
  }

  47.4489795918% {
    transform: translateX(-39vw) translateY(14vh) scale(0.65);
  }

  47.9591836735% {
    transform: translateX(8vw) translateY(41vh) scale(0.29);
  }

  48.4693877551% {
    transform: translateX(-22vw) translateY(-41vh) scale(0.44);
  }

  48.9795918367% {
    transform: translateX(26vw) translateY(1vh) scale(0.85);
  }

  49.4897959184% {
    transform: translateX(-31vw) translateY(19vh) scale(0.44);
  }

  50% {
    transform: translateX(-25vw) translateY(9vh) scale(0.69);
  }

  50.5102040816% {
    transform: translateX(-36vw) translateY(39vh) scale(0.7);
  }

  51.0204081633% {
    transform: translateX(43vw) translateY(-15vh) scale(0.72);
  }

  51.5306122449% {
    transform: translateX(-9vw) translateY(5vh) scale(0.79);
  }

  52.0408163265% {
    transform: translateX(-10vw) translateY(-18vh) scale(0.48);
  }

  52.5510204082% {
    transform: translateX(42vw) translateY(-27vh) scale(0.69);
  }

  53.0612244898% {
    transform: translateX(-44vw) translateY(29vh) scale(0.41);
  }

  53.5714285714% {
    transform: translateX(-9vw) translateY(-31vh) scale(0.51);
  }

  54.0816326531% {
    transform: translateX(37vw) translateY(38vh) scale(0.53);
  }

  54.5918367347% {
    transform: translateX(40vw) translateY(-41vh) scale(0.82);
  }

  55.1020408163% {
    transform: translateX(15vw) translateY(-49vh) scale(0.64);
  }

  55.612244898% {
    transform: translateX(39vw) translateY(-41vh) scale(0.45);
  }

  56.1224489796% {
    transform: translateX(-47vw) translateY(23vh) scale(0.29);
  }

  56.6326530612% {
    transform: translateX(-47vw) translateY(-6vh) scale(0.58);
  }

  57.1428571429% {
    transform: translateX(-16vw) translateY(-12vh) scale(0.61);
  }

  57.6530612245% {
    transform: translateX(-25vw) translateY(48vh) scale(0.7);
  }

  58.1632653061% {
    transform: translateX(29vw) translateY(41vh) scale(0.88);
  }

  58.6734693878% {
    transform: translateX(-16vw) translateY(-16vh) scale(0.26);
  }

  59.1836734694% {
    transform: translateX(-36vw) translateY(-32vh) scale(0.87);
  }

  59.693877551% {
    transform: translateX(50vw) translateY(18vh) scale(0.77);
  }

  60.2040816327% {
    transform: translateX(41vw) translateY(43vh) scale(0.28);
  }

  60.7142857143% {
    transform: translateX(30vw) translateY(41vh) scale(0.34);
  }

  61.2244897959% {
    transform: translateX(44vw) translateY(-2vh) scale(0.76);
  }

  61.7346938776% {
    transform: translateX(33vw) translateY(39vh) scale(0.74);
  }

  62.2448979592% {
    transform: translateX(11vw) translateY(-4vh) scale(0.65);
  }

  62.7551020408% {
    transform: translateX(-39vw) translateY(3vh) scale(0.34);
  }

  63.2653061224% {
    transform: translateX(-17vw) translateY(30vh) scale(0.55);
  }

  63.7755102041% {
    transform: translateX(32vw) translateY(19vh) scale(0.71);
  }

  64.2857142857% {
    transform: translateX(-49vw) translateY(-35vh) scale(0.89);
  }

  64.7959183673% {
    transform: translateX(-38vw) translateY(39vh) scale(0.86);
  }

  65.306122449% {
    transform: translateX(6vw) translateY(0vh) scale(0.4);
  }

  65.8163265306% {
    transform: translateX(40vw) translateY(-41vh) scale(0.96);
  }

  66.3265306122% {
    transform: translateX(-32vw) translateY(46vh) scale(0.92);
  }

  66.8367346939% {
    transform: translateX(-30vw) translateY(30vh) scale(0.77);
  }

  67.3469387755% {
    transform: translateX(3vw) translateY(-12vh) scale(0.55);
  }

  67.8571428571% {
    transform: translateX(43vw) translateY(10vh) scale(1);
  }

  68.3673469388% {
    transform: translateX(47vw) translateY(36vh) scale(0.95);
  }

  68.8775510204% {
    transform: translateX(-36vw) translateY(28vh) scale(0.31);
  }

  69.387755102% {
    transform: translateX(-27vw) translateY(-12vh) scale(0.39);
  }

  69.8979591837% {
    transform: translateX(49vw) translateY(26vh) scale(0.96);
  }

  70.4081632653% {
    transform: translateX(-25vw) translateY(-7vh) scale(0.96);
  }

  70.9183673469% {
    transform: translateX(13vw) translateY(-15vh) scale(0.5);
  }

  71.4285714286% {
    transform: translateX(-34vw) translateY(-49vh) scale(0.29);
  }

  71.9387755102% {
    transform: translateX(22vw) translateY(-39vh) scale(0.32);
  }

  72.4489795918% {
    transform: translateX(37vw) translateY(-41vh) scale(0.41);
  }

  72.9591836735% {
    transform: translateX(-13vw) translateY(-19vh) scale(0.45);
  }

  73.4693877551% {
    transform: translateX(-49vw) translateY(0vh) scale(0.46);
  }

  73.9795918367% {
    transform: translateX(-8vw) translateY(-49vh) scale(0.88);
  }

  74.4897959184% {
    transform: translateX(37vw) translateY(17vh) scale(0.61);
  }

  75% {
    transform: translateX(-16vw) translateY(-33vh) scale(0.94);
  }

  75.5102040816% {
    transform: translateX(27vw) translateY(-40vh) scale(0.94);
  }

  76.0204081633% {
    transform: translateX(39vw) translateY(4vh) scale(0.91);
  }

  76.5306122449% {
    transform: translateX(-4vw) translateY(-31vh) scale(0.98);
  }

  77.0408163265% {
    transform: translateX(-11vw) translateY(49vh) scale(0.46);
  }

  77.5510204082% {
    transform: translateX(42vw) translateY(13vh) scale(0.92);
  }

  78.0612244898% {
    transform: translateX(-48vw) translateY(7vh) scale(1);
  }

  78.5714285714% {
    transform: translateX(-9vw) translateY(-31vh) scale(0.74);
  }

  79.0816326531% {
    transform: translateX(-46vw) translateY(-23vh) scale(0.53);
  }

  79.5918367347% {
    transform: translateX(-2vw) translateY(18vh) scale(0.29);
  }

  80.1020408163% {
    transform: translateX(32vw) translateY(-48vh) scale(0.47);
  }

  80.612244898% {
    transform: translateX(-3vw) translateY(-34vh) scale(0.47);
  }

  81.1224489796% {
    transform: translateX(-6vw) translateY(-30vh) scale(0.54);
  }

  81.6326530612% {
    transform: translateX(-31vw) translateY(45vh) scale(0.98);
  }

  82.1428571429% {
    transform: translateX(-31vw) translateY(-2vh) scale(0.48);
  }

  82.6530612245% {
    transform: translateX(11vw) translateY(-31vh) scale(0.79);
  }

  83.1632653061% {
    transform: translateX(-36vw) translateY(4vh) scale(0.88);
  }

  83.6734693878% {
    transform: translateX(25vw) translateY(-45vh) scale(0.8);
  }

  84.1836734694% {
    transform: translateX(23vw) translateY(-20vh) scale(0.99);
  }

  84.693877551% {
    transform: translateX(39vw) translateY(-23vh) scale(0.82);
  }

  85.2040816327% {
    transform: translateX(-19vw) translateY(9vh) scale(0.34);
  }

  85.7142857143% {
    transform: translateX(-18vw) translateY(47vh) scale(0.98);
  }

  86.2244897959% {
    transform: translateX(11vw) translateY(-18vh) scale(0.66);
  }

  86.7346938776% {
    transform: translateX(-47vw) translateY(-16vh) scale(0.91);
  }

  87.2448979592% {
    transform: translateX(-5vw) translateY(3vh) scale(0.53);
  }

  87.7551020408% {
    transform: translateX(24vw) translateY(-46vh) scale(0.78);
  }

  88.2653061224% {
    transform: translateX(46vw) translateY(24vh) scale(0.87);
  }

  88.7755102041% {
    transform: translateX(-44vw) translateY(37vh) scale(0.27);
  }

  89.2857142857% {
    transform: translateX(-29vw) translateY(-13vh) scale(0.72);
  }

  89.7959183673% {
    transform: translateX(26vw) translateY(-43vh) scale(0.34);
  }

  90.306122449% {
    transform: translateX(-42vw) translateY(41vh) scale(0.35);
  }

  90.8163265306% {
    transform: translateX(16vw) translateY(-30vh) scale(0.7);
  }

  91.3265306122% {
    transform: translateX(-31vw) translateY(31vh) scale(0.83);
  }

  91.8367346939% {
    transform: translateX(19vw) translateY(18vh) scale(0.9);
  }

  92.3469387755% {
    transform: translateX(31vw) translateY(30vh) scale(0.94);
  }

  92.8571428571% {
    transform: translateX(4vw) translateY(-21vh) scale(0.77);
  }

  93.3673469388% {
    transform: translateX(-37vw) translateY(-10vh) scale(0.27);
  }

  93.8775510204% {
    transform: translateX(27vw) translateY(-24vh) scale(0.52);
  }

  94.387755102% {
    transform: translateX(29vw) translateY(49vh) scale(0.94);
  }

  94.8979591837% {
    transform: translateX(-42vw) translateY(5vh) scale(0.78);
  }

  95.4081632653% {
    transform: translateX(-19vw) translateY(44vh) scale(0.67);
  }

  95.9183673469% {
    transform: translateX(-11vw) translateY(-35vh) scale(0.72);
  }

  96.4285714286% {
    transform: translateX(36vw) translateY(-48vh) scale(0.78);
  }

  96.9387755102% {
    transform: translateX(15vw) translateY(-19vh) scale(0.5);
  }

  97.4489795918% {
    transform: translateX(40vw) translateY(-45vh) scale(0.31);
  }

  97.9591836735% {
    transform: translateX(27vw) translateY(4vh) scale(0.84);
  }

  98.4693877551% {
    transform: translateX(-5vw) translateY(-31vh) scale(0.34);
  }

  98.9795918367% {
    transform: translateX(-9vw) translateY(24vh) scale(0.78);
  }

  99.4897959184% {
    transform: translateX(22vw) translateY(-43vh) scale(0.36);
  }

  100% {
    transform: translateX(40vw) translateY(45vh) scale(0.78);
  }
}

.firefly:nth-child(15) {
  animation-name: move15;
}

.firefly:nth-child(15)::before {
  animation-duration: 13s;
}

.firefly:nth-child(15)::after {
  animation-duration: 13s, 5901ms;
  animation-delay: 0ms, 5432ms;
}

@keyframes move15 {
  0% {
    transform: translateX(39vw) translateY(2vh) scale(0.27);
  }

  0.4524886878% {
    transform: translateX(34vw) translateY(-37vh) scale(0.34);
  }

  0.9049773756% {
    transform: translateX(7vw) translateY(4vh) scale(0.68);
  }

  1.3574660633% {
    transform: translateX(7vw) translateY(-27vh) scale(0.49);
  }

  1.8099547511% {
    transform: translateX(30vw) translateY(23vh) scale(0.45);
  }

  2.2624434389% {
    transform: translateX(40vw) translateY(-29vh) scale(0.68);
  }

  2.7149321267% {
    transform: translateX(-31vw) translateY(-47vh) scale(0.88);
  }

  3.1674208145% {
    transform: translateX(-43vw) translateY(34vh) scale(0.26);
  }

  3.6199095023% {
    transform: translateX(34vw) translateY(-45vh) scale(0.77);
  }

  4.07239819% {
    transform: translateX(16vw) translateY(11vh) scale(0.78);
  }

  4.5248868778% {
    transform: translateX(42vw) translateY(-24vh) scale(0.53);
  }

  4.9773755656% {
    transform: translateX(-46vw) translateY(16vh) scale(0.34);
  }

  5.4298642534% {
    transform: translateX(13vw) translateY(43vh) scale(0.36);
  }

  5.8823529412% {
    transform: translateX(-32vw) translateY(38vh) scale(0.64);
  }

  6.334841629% {
    transform: translateX(-1vw) translateY(42vh) scale(0.52);
  }

  6.7873303167% {
    transform: translateX(-25vw) translateY(-14vh) scale(0.63);
  }

  7.2398190045% {
    transform: translateX(0vw) translateY(36vh) scale(0.51);
  }

  7.6923076923% {
    transform: translateX(2vw) translateY(-11vh) scale(0.32);
  }

  8.1447963801% {
    transform: translateX(-33vw) translateY(-43vh) scale(0.97);
  }

  8.5972850679% {
    transform: translateX(36vw) translateY(16vh) scale(0.51);
  }

  9.0497737557% {
    transform: translateX(16vw) translateY(15vh) scale(0.42);
  }

  9.5022624434% {
    transform: translateX(21vw) translateY(-10vh) scale(0.77);
  }

  9.9547511312% {
    transform: translateX(-4vw) translateY(4vh) scale(0.93);
  }

  10.407239819% {
    transform: translateX(-38vw) translateY(20vh) scale(0.3);
  }

  10.8597285068% {
    transform: translateX(-48vw) translateY(-46vh) scale(0.72);
  }

  11.3122171946% {
    transform: translateX(-19vw) translateY(35vh) scale(0.63);
  }

  11.7647058824% {
    transform: translateX(17vw) translateY(-39vh) scale(0.28);
  }

  12.2171945701% {
    transform: translateX(-4vw) translateY(42vh) scale(0.49);
  }

  12.6696832579% {
    transform: translateX(22vw) translateY(37vh) scale(0.71);
  }

  13.1221719457% {
    transform: translateX(31vw) translateY(25vh) scale(0.85);
  }

  13.5746606335% {
    transform: translateX(29vw) translateY(-33vh) scale(0.4);
  }

  14.0271493213% {
    transform: translateX(-24vw) translateY(-41vh) scale(0.49);
  }

  14.479638009% {
    transform: translateX(41vw) translateY(8vh) scale(0.76);
  }

  14.9321266968% {
    transform: translateX(-5vw) translateY(2vh) scale(0.67);
  }

  15.3846153846% {
    transform: translateX(-14vw) translateY(49vh) scale(0.95);
  }

  15.8371040724% {
    transform: translateX(39vw) translateY(5vh) scale(0.69);
  }

  16.2895927602% {
    transform: translateX(49vw) translateY(-5vh) scale(0.5);
  }

  16.742081448% {
    transform: translateX(15vw) translateY(-35vh) scale(0.95);
  }

  17.1945701357% {
    transform: translateX(44vw) translateY(19vh) scale(0.49);
  }

  17.6470588235% {
    transform: translateX(-24vw) translateY(-48vh) scale(1);
  }

  18.0995475113% {
    transform: translateX(-21vw) translateY(22vh) scale(0.39);
  }

  18.5520361991% {
    transform: translateX(14vw) translateY(46vh) scale(0.4);
  }

  19.0045248869% {
    transform: translateX(31vw) translateY(1vh) scale(0.99);
  }

  19.4570135747% {
    transform: translateX(-33vw) translateY(35vh) scale(0.57);
  }

  19.9095022624% {
    transform: translateX(7vw) translateY(47vh) scale(0.46);
  }

  20.3619909502% {
    transform: translateX(-10vw) translateY(2vh) scale(0.39);
  }

  20.814479638% {
    transform: translateX(-2vw) translateY(37vh) scale(0.4);
  }

  21.2669683258% {
    transform: translateX(-8vw) translateY(-40vh) scale(0.39);
  }

  21.7194570136% {
    transform: translateX(16vw) translateY(-20vh) scale(0.94);
  }

  22.1719457014% {
    transform: translateX(-8vw) translateY(-37vh) scale(0.39);
  }

  22.6244343891% {
    transform: translateX(1vw) translateY(-8vh) scale(0.26);
  }

  23.0769230769% {
    transform: translateX(14vw) translateY(16vh) scale(0.93);
  }

  23.5294117647% {
    transform: translateX(-32vw) translateY(-5vh) scale(0.51);
  }

  23.9819004525% {
    transform: translateX(-30vw) translateY(41vh) scale(0.81);
  }

  24.4343891403% {
    transform: translateX(0vw) translateY(3vh) scale(0.74);
  }

  24.8868778281% {
    transform: translateX(31vw) translateY(47vh) scale(0.93);
  }

  25.3393665158% {
    transform: translateX(-35vw) translateY(11vh) scale(0.92);
  }

  25.7918552036% {
    transform: translateX(24vw) translateY(50vh) scale(0.8);
  }

  26.2443438914% {
    transform: translateX(-1vw) translateY(42vh) scale(0.88);
  }

  26.6968325792% {
    transform: translateX(-32vw) translateY(14vh) scale(0.94);
  }

  27.149321267% {
    transform: translateX(-38vw) translateY(34vh) scale(0.59);
  }

  27.6018099548% {
    transform: translateX(23vw) translateY(-46vh) scale(0.32);
  }

  28.0542986425% {
    transform: translateX(-30vw) translateY(1vh) scale(0.42);
  }

  28.5067873303% {
    transform: translateX(28vw) translateY(5vh) scale(0.44);
  }

  28.9592760181% {
    transform: translateX(-15vw) translateY(26vh) scale(0.9);
  }

  29.4117647059% {
    transform: translateX(2vw) translateY(-14vh) scale(0.3);
  }

  29.8642533937% {
    transform: translateX(35vw) translateY(25vh) scale(0.49);
  }

  30.3167420814% {
    transform: translateX(-13vw) translateY(-30vh) scale(0.44);
  }

  30.7692307692% {
    transform: translateX(-12vw) translateY(-47vh) scale(0.75);
  }

  31.221719457% {
    transform: translateX(45vw) translateY(42vh) scale(0.4);
  }

  31.6742081448% {
    transform: translateX(35vw) translateY(29vh) scale(0.8);
  }

  32.1266968326% {
    transform: translateX(-15vw) translateY(-3vh) scale(0.66);
  }

  32.5791855204% {
    transform: translateX(-28vw) translateY(14vh) scale(0.35);
  }

  33.0316742081% {
    transform: translateX(47vw) translateY(-23vh) scale(0.92);
  }

  33.4841628959% {
    transform: translateX(-45vw) translateY(-46vh) scale(0.26);
  }

  33.9366515837% {
    transform: translateX(19vw) translateY(-27vh) scale(0.76);
  }

  34.3891402715% {
    transform: translateX(11vw) translateY(-42vh) scale(0.7);
  }

  34.8416289593% {
    transform: translateX(-7vw) translateY(-4vh) scale(0.86);
  }

  35.2941176471% {
    transform: translateX(38vw) translateY(32vh) scale(0.38);
  }

  35.7466063348% {
    transform: translateX(-44vw) translateY(10vh) scale(0.76);
  }

  36.1990950226% {
    transform: translateX(6vw) translateY(38vh) scale(0.73);
  }

  36.6515837104% {
    transform: translateX(-8vw) translateY(7vh) scale(0.57);
  }

  37.1040723982% {
    transform: translateX(31vw) translateY(10vh) scale(0.37);
  }

  37.556561086% {
    transform: translateX(-10vw) translateY(3vh) scale(0.62);
  }

  38.0090497738% {
    transform: translateX(-36vw) translateY(8vh) scale(0.87);
  }

  38.4615384615% {
    transform: translateX(31vw) translateY(-43vh) scale(0.9);
  }

  38.9140271493% {
    transform: translateX(42vw) translateY(-45vh) scale(0.64);
  }

  39.3665158371% {
    transform: translateX(-12vw) translateY(-38vh) scale(0.85);
  }

  39.8190045249% {
    transform: translateX(-13vw) translateY(-29vh) scale(0.87);
  }

  40.2714932127% {
    transform: translateX(-23vw) translateY(22vh) scale(0.38);
  }

  40.7239819005% {
    transform: translateX(23vw) translateY(40vh) scale(0.78);
  }

  41.1764705882% {
    transform: translateX(-5vw) translateY(27vh) scale(0.92);
  }

  41.628959276% {
    transform: translateX(-35vw) translateY(11vh) scale(0.45);
  }

  42.0814479638% {
    transform: translateX(11vw) translateY(-43vh) scale(0.45);
  }

  42.5339366516% {
    transform: translateX(-10vw) translateY(-26vh) scale(0.61);
  }

  42.9864253394% {
    transform: translateX(24vw) translateY(-10vh) scale(0.54);
  }

  43.4389140271% {
    transform: translateX(2vw) translateY(9vh) scale(0.9);
  }

  43.8914027149% {
    transform: translateX(-18vw) translateY(1vh) scale(0.98);
  }

  44.3438914027% {
    transform: translateX(-11vw) translateY(-20vh) scale(0.69);
  }

  44.7963800905% {
    transform: translateX(-47vw) translateY(22vh) scale(0.64);
  }

  45.2488687783% {
    transform: translateX(-28vw) translateY(-1vh) scale(0.52);
  }

  45.7013574661% {
    transform: translateX(5vw) translateY(50vh) scale(0.89);
  }

  46.1538461538% {
    transform: translateX(-29vw) translateY(-35vh) scale(0.3);
  }

  46.6063348416% {
    transform: translateX(22vw) translateY(-20vh) scale(0.64);
  }

  47.0588235294% {
    transform: translateX(-29vw) translateY(44vh) scale(0.32);
  }

  47.5113122172% {
    transform: translateX(-27vw) translateY(24vh) scale(0.44);
  }

  47.963800905% {
    transform: translateX(42vw) translateY(-16vh) scale(0.69);
  }

  48.4162895928% {
    transform: translateX(-2vw) translateY(-2vh) scale(0.35);
  }

  48.8687782805% {
    transform: translateX(28vw) translateY(-18vh) scale(0.78);
  }

  49.3212669683% {
    transform: translateX(-3vw) translateY(40vh) scale(0.97);
  }

  49.7737556561% {
    transform: translateX(-19vw) translateY(-42vh) scale(0.35);
  }

  50.2262443439% {
    transform: translateX(10vw) translateY(43vh) scale(0.57);
  }

  50.6787330317% {
    transform: translateX(2vw) translateY(43vh) scale(0.87);
  }

  51.1312217195% {
    transform: translateX(-42vw) translateY(50vh) scale(0.58);
  }

  51.5837104072% {
    transform: translateX(-47vw) translateY(-35vh) scale(0.77);
  }

  52.036199095% {
    transform: translateX(40vw) translateY(-41vh) scale(0.48);
  }

  52.4886877828% {
    transform: translateX(24vw) translateY(44vh) scale(0.55);
  }

  52.9411764706% {
    transform: translateX(25vw) translateY(33vh) scale(0.35);
  }

  53.3936651584% {
    transform: translateX(-17vw) translateY(-48vh) scale(0.43);
  }

  53.8461538462% {
    transform: translateX(46vw) translateY(2vh) scale(0.7);
  }

  54.2986425339% {
    transform: translateX(39vw) translateY(-36vh) scale(0.57);
  }

  54.7511312217% {
    transform: translateX(44vw) translateY(9vh) scale(0.36);
  }

  55.2036199095% {
    transform: translateX(-40vw) translateY(-22vh) scale(0.71);
  }

  55.6561085973% {
    transform: translateX(12vw) translateY(20vh) scale(0.34);
  }

  56.1085972851% {
    transform: translateX(-20vw) translateY(2vh) scale(0.33);
  }

  56.5610859729% {
    transform: translateX(-4vw) translateY(-33vh) scale(0.76);
  }

  57.0135746606% {
    transform: translateX(-32vw) translateY(-43vh) scale(0.69);
  }

  57.4660633484% {
    transform: translateX(41vw) translateY(35vh) scale(0.97);
  }

  57.9185520362% {
    transform: translateX(-9vw) translateY(-5vh) scale(0.27);
  }

  58.371040724% {
    transform: translateX(17vw) translateY(-5vh) scale(0.31);
  }

  58.8235294118% {
    transform: translateX(-39vw) translateY(-26vh) scale(0.77);
  }

  59.2760180995% {
    transform: translateX(-45vw) translateY(-24vh) scale(0.47);
  }

  59.7285067873% {
    transform: translateX(-36vw) translateY(19vh) scale(0.46);
  }

  60.1809954751% {
    transform: translateX(28vw) translateY(-4vh) scale(0.4);
  }

  60.6334841629% {
    transform: translateX(36vw) translateY(44vh) scale(0.58);
  }

  61.0859728507% {
    transform: translateX(-24vw) translateY(-1vh) scale(0.61);
  }

  61.5384615385% {
    transform: translateX(29vw) translateY(-23vh) scale(0.78);
  }

  61.9909502262% {
    transform: translateX(14vw) translateY(-47vh) scale(0.97);
  }

  62.443438914% {
    transform: translateX(-4vw) translateY(34vh) scale(0.69);
  }

  62.8959276018% {
    transform: translateX(17vw) translateY(-18vh) scale(0.85);
  }

  63.3484162896% {
    transform: translateX(-34vw) translateY(33vh) scale(0.39);
  }

  63.8009049774% {
    transform: translateX(47vw) translateY(49vh) scale(0.82);
  }

  64.2533936652% {
    transform: translateX(35vw) translateY(18vh) scale(0.54);
  }

  64.7058823529% {
    transform: translateX(-9vw) translateY(13vh) scale(0.86);
  }

  65.1583710407% {
    transform: translateX(27vw) translateY(34vh) scale(0.35);
  }

  65.6108597285% {
    transform: translateX(-21vw) translateY(-36vh) scale(0.35);
  }

  66.0633484163% {
    transform: translateX(41vw) translateY(-33vh) scale(0.63);
  }

  66.5158371041% {
    transform: translateX(-1vw) translateY(-41vh) scale(0.6);
  }

  66.9683257919% {
    transform: translateX(-27vw) translateY(-31vh) scale(0.94);
  }

  67.4208144796% {
    transform: translateX(-41vw) translateY(12vh) scale(0.91);
  }

  67.8733031674% {
    transform: translateX(-32vw) translateY(6vh) scale(0.54);
  }

  68.3257918552% {
    transform: translateX(23vw) translateY(9vh) scale(0.63);
  }

  68.778280543% {
    transform: translateX(46vw) translateY(-25vh) scale(0.41);
  }

  69.2307692308% {
    transform: translateX(47vw) translateY(44vh) scale(0.92);
  }

  69.6832579186% {
    transform: translateX(46vw) translateY(-1vh) scale(0.66);
  }

  70.1357466063% {
    transform: translateX(-45vw) translateY(-48vh) scale(0.53);
  }

  70.5882352941% {
    transform: translateX(-33vw) translateY(-25vh) scale(0.89);
  }

  71.0407239819% {
    transform: translateX(-34vw) translateY(38vh) scale(0.86);
  }

  71.4932126697% {
    transform: translateX(44vw) translateY(45vh) scale(0.38);
  }

  71.9457013575% {
    transform: translateX(37vw) translateY(38vh) scale(0.26);
  }

  72.3981900452% {
    transform: translateX(-22vw) translateY(32vh) scale(0.9);
  }

  72.850678733% {
    transform: translateX(23vw) translateY(-41vh) scale(0.74);
  }

  73.3031674208% {
    transform: translateX(-12vw) translateY(-31vh) scale(0.7);
  }

  73.7556561086% {
    transform: translateX(37vw) translateY(-11vh) scale(0.34);
  }

  74.2081447964% {
    transform: translateX(-45vw) translateY(-42vh) scale(0.27);
  }

  74.6606334842% {
    transform: translateX(29vw) translateY(-17vh) scale(0.45);
  }

  75.1131221719% {
    transform: translateX(12vw) translateY(7vh) scale(0.28);
  }

  75.5656108597% {
    transform: translateX(12vw) translateY(22vh) scale(0.56);
  }

  76.0180995475% {
    transform: translateX(-40vw) translateY(-32vh) scale(0.28);
  }

  76.4705882353% {
    transform: translateX(29vw) translateY(29vh) scale(0.7);
  }

  76.9230769231% {
    transform: translateX(12vw) translateY(49vh) scale(0.79);
  }

  77.3755656109% {
    transform: translateX(18vw) translateY(-30vh) scale(0.77);
  }

  77.8280542986% {
    transform: translateX(-22vw) translateY(7vh) scale(0.37);
  }

  78.2805429864% {
    transform: translateX(39vw) translateY(22vh) scale(0.55);
  }

  78.7330316742% {
    transform: translateX(-36vw) translateY(-5vh) scale(0.85);
  }

  79.185520362% {
    transform: translateX(-7vw) translateY(19vh) scale(0.97);
  }

  79.6380090498% {
    transform: translateX(5vw) translateY(-22vh) scale(0.93);
  }

  80.0904977376% {
    transform: translateX(13vw) translateY(26vh) scale(0.91);
  }

  80.5429864253% {
    transform: translateX(-28vw) translateY(-26vh) scale(0.55);
  }

  80.9954751131% {
    transform: translateX(5vw) translateY(41vh) scale(0.8);
  }

  81.4479638009% {
    transform: translateX(2vw) translateY(26vh) scale(0.9);
  }

  81.9004524887% {
    transform: translateX(-4vw) translateY(23vh) scale(0.85);
  }

  82.3529411765% {
    transform: translateX(-33vw) translateY(47vh) scale(0.99);
  }

  82.8054298643% {
    transform: translateX(-49vw) translateY(34vh) scale(0.64);
  }

  83.257918552% {
    transform: translateX(-43vw) translateY(-1vh) scale(0.99);
  }

  83.7104072398% {
    transform: translateX(29vw) translateY(38vh) scale(0.41);
  }

  84.1628959276% {
    transform: translateX(-17vw) translateY(-6vh) scale(0.8);
  }

  84.6153846154% {
    transform: translateX(-12vw) translateY(45vh) scale(0.62);
  }

  85.0678733032% {
    transform: translateX(30vw) translateY(0vh) scale(0.75);
  }

  85.520361991% {
    transform: translateX(50vw) translateY(-29vh) scale(0.67);
  }

  85.9728506787% {
    transform: translateX(37vw) translateY(37vh) scale(0.46);
  }

  86.4253393665% {
    transform: translateX(-6vw) translateY(-44vh) scale(0.43);
  }

  86.8778280543% {
    transform: translateX(16vw) translateY(-17vh) scale(0.44);
  }

  87.3303167421% {
    transform: translateX(35vw) translateY(5vh) scale(0.64);
  }

  87.7828054299% {
    transform: translateX(-34vw) translateY(-16vh) scale(0.53);
  }

  88.2352941176% {
    transform: translateX(-36vw) translateY(19vh) scale(0.76);
  }

  88.6877828054% {
    transform: translateX(13vw) translateY(25vh) scale(0.53);
  }

  89.1402714932% {
    transform: translateX(-9vw) translateY(-12vh) scale(0.87);
  }

  89.592760181% {
    transform: translateX(10vw) translateY(23vh) scale(0.84);
  }

  90.0452488688% {
    transform: translateX(-42vw) translateY(10vh) scale(0.37);
  }

  90.4977375566% {
    transform: translateX(29vw) translateY(-5vh) scale(0.72);
  }

  90.9502262443% {
    transform: translateX(-34vw) translateY(-47vh) scale(0.41);
  }

  91.4027149321% {
    transform: translateX(36vw) translateY(-21vh) scale(0.75);
  }

  91.8552036199% {
    transform: translateX(44vw) translateY(-9vh) scale(0.46);
  }

  92.3076923077% {
    transform: translateX(16vw) translateY(39vh) scale(0.26);
  }

  92.7601809955% {
    transform: translateX(-6vw) translateY(26vh) scale(0.28);
  }

  93.2126696833% {
    transform: translateX(27vw) translateY(9vh) scale(0.68);
  }

  93.665158371% {
    transform: translateX(-37vw) translateY(31vh) scale(0.76);
  }

  94.1176470588% {
    transform: translateX(4vw) translateY(36vh) scale(0.82);
  }

  94.5701357466% {
    transform: translateX(-8vw) translateY(7vh) scale(0.36);
  }

  95.0226244344% {
    transform: translateX(18vw) translateY(42vh) scale(0.51);
  }

  95.4751131222% {
    transform: translateX(-16vw) translateY(-16vh) scale(0.99);
  }

  95.92760181% {
    transform: translateX(-9vw) translateY(-26vh) scale(0.34);
  }

  96.3800904977% {
    transform: translateX(-31vw) translateY(-49vh) scale(0.82);
  }

  96.8325791855% {
    transform: translateX(-35vw) translateY(-40vh) scale(0.31);
  }

  97.2850678733% {
    transform: translateX(35vw) translateY(-15vh) scale(1);
  }

  97.7375565611% {
    transform: translateX(-8vw) translateY(-34vh) scale(0.62);
  }

  98.1900452489% {
    transform: translateX(-37vw) translateY(-44vh) scale(0.34);
  }

  98.6425339367% {
    transform: translateX(-25vw) translateY(-38vh) scale(0.53);
  }

  99.0950226244% {
    transform: translateX(-29vw) translateY(16vh) scale(0.93);
  }

  99.5475113122% {
    transform: translateX(24vw) translateY(-42vh) scale(0.6);
  }

  100% {
    transform: translateX(36vw) translateY(16vh) scale(0.85);
  }
}

.firefly:nth-child(16) {
  animation-name: move16;
}

.firefly:nth-child(16)::before {
  animation-duration: 14s;
}

.firefly:nth-child(16)::after {
  animation-duration: 14s, 10423ms;
  animation-delay: 0ms, 7728ms;
}

@keyframes move16 {
  0% {
    transform: translateX(-45vw) translateY(-18vh) scale(0.55);
  }

  0.3703703704% {
    transform: translateX(-8vw) translateY(33vh) scale(0.36);
  }

  0.7407407407% {
    transform: translateX(0vw) translateY(-38vh) scale(0.73);
  }

  1.1111111111% {
    transform: translateX(15vw) translateY(37vh) scale(0.29);
  }

  1.4814814815% {
    transform: translateX(23vw) translateY(-46vh) scale(0.7);
  }

  1.8518518519% {
    transform: translateX(-24vw) translateY(-15vh) scale(0.26);
  }

  2.2222222222% {
    transform: translateX(47vw) translateY(21vh) scale(0.8);
  }

  2.5925925926% {
    transform: translateX(-40vw) translateY(35vh) scale(0.92);
  }

  2.962962963% {
    transform: translateX(-17vw) translateY(-28vh) scale(0.78);
  }

  3.3333333333% {
    transform: translateX(-24vw) translateY(-6vh) scale(0.5);
  }

  3.7037037037% {
    transform: translateX(-10vw) translateY(-43vh) scale(0.65);
  }

  4.0740740741% {
    transform: translateX(36vw) translateY(28vh) scale(0.77);
  }

  4.4444444444% {
    transform: translateX(36vw) translateY(31vh) scale(0.35);
  }

  4.8148148148% {
    transform: translateX(-14vw) translateY(47vh) scale(0.62);
  }

  5.1851851852% {
    transform: translateX(40vw) translateY(-3vh) scale(0.78);
  }

  5.5555555556% {
    transform: translateX(42vw) translateY(21vh) scale(0.54);
  }

  5.9259259259% {
    transform: translateX(-13vw) translateY(-36vh) scale(0.76);
  }

  6.2962962963% {
    transform: translateX(-34vw) translateY(-9vh) scale(0.62);
  }

  6.6666666667% {
    transform: translateX(48vw) translateY(27vh) scale(0.57);
  }

  7.037037037% {
    transform: translateX(21vw) translateY(48vh) scale(0.71);
  }

  7.4074074074% {
    transform: translateX(14vw) translateY(-14vh) scale(0.8);
  }

  7.7777777778% {
    transform: translateX(45vw) translateY(32vh) scale(0.97);
  }

  8.1481481481% {
    transform: translateX(-33vw) translateY(-29vh) scale(0.45);
  }

  8.5185185185% {
    transform: translateX(42vw) translateY(23vh) scale(0.55);
  }

  8.8888888889% {
    transform: translateX(-29vw) translateY(-28vh) scale(0.77);
  }

  9.2592592593% {
    transform: translateX(44vw) translateY(49vh) scale(0.99);
  }

  9.6296296296% {
    transform: translateX(18vw) translateY(-24vh) scale(0.73);
  }

  10% {
    transform: translateX(3vw) translateY(-47vh) scale(0.82);
  }

  10.3703703704% {
    transform: translateX(-48vw) translateY(-16vh) scale(0.68);
  }

  10.7407407407% {
    transform: translateX(-35vw) translateY(32vh) scale(0.32);
  }

  11.1111111111% {
    transform: translateX(41vw) translateY(13vh) scale(0.6);
  }

  11.4814814815% {
    transform: translateX(31vw) translateY(-44vh) scale(0.51);
  }

  11.8518518519% {
    transform: translateX(27vw) translateY(-23vh) scale(0.46);
  }

  12.2222222222% {
    transform: translateX(38vw) translateY(25vh) scale(0.83);
  }

  12.5925925926% {
    transform: translateX(-2vw) translateY(2vh) scale(0.5);
  }

  12.962962963% {
    transform: translateX(10vw) translateY(14vh) scale(0.63);
  }

  13.3333333333% {
    transform: translateX(34vw) translateY(-3vh) scale(0.96);
  }

  13.7037037037% {
    transform: translateX(45vw) translateY(-13vh) scale(0.96);
  }

  14.0740740741% {
    transform: translateX(7vw) translateY(43vh) scale(0.53);
  }

  14.4444444444% {
    transform: translateX(45vw) translateY(9vh) scale(0.56);
  }

  14.8148148148% {
    transform: translateX(-23vw) translateY(-10vh) scale(0.93);
  }

  15.1851851852% {
    transform: translateX(-33vw) translateY(-13vh) scale(0.58);
  }

  15.5555555556% {
    transform: translateX(-15vw) translateY(27vh) scale(0.27);
  }

  15.9259259259% {
    transform: translateX(40vw) translateY(-16vh) scale(0.77);
  }

  16.2962962963% {
    transform: translateX(48vw) translateY(44vh) scale(0.37);
  }

  16.6666666667% {
    transform: translateX(-5vw) translateY(-35vh) scale(0.3);
  }

  17.037037037% {
    transform: translateX(-16vw) translateY(-40vh) scale(0.63);
  }

  17.4074074074% {
    transform: translateX(-21vw) translateY(46vh) scale(0.87);
  }

  17.7777777778% {
    transform: translateX(41vw) translateY(16vh) scale(0.37);
  }

  18.1481481481% {
    transform: translateX(-38vw) translateY(12vh) scale(0.65);
  }

  18.5185185185% {
    transform: translateX(32vw) translateY(-40vh) scale(0.62);
  }

  18.8888888889% {
    transform: translateX(37vw) translateY(-9vh) scale(0.26);
  }

  19.2592592593% {
    transform: translateX(5vw) translateY(-27vh) scale(0.33);
  }

  19.6296296296% {
    transform: translateX(-3vw) translateY(44vh) scale(0.46);
  }

  20% {
    transform: translateX(12vw) translateY(-25vh) scale(0.71);
  }

  20.3703703704% {
    transform: translateX(-10vw) translateY(13vh) scale(0.27);
  }

  20.7407407407% {
    transform: translateX(-17vw) translateY(-24vh) scale(0.26);
  }

  21.1111111111% {
    transform: translateX(-49vw) translateY(-45vh) scale(0.35);
  }

  21.4814814815% {
    transform: translateX(47vw) translateY(2vh) scale(0.53);
  }

  21.8518518519% {
    transform: translateX(-26vw) translateY(27vh) scale(0.46);
  }

  22.2222222222% {
    transform: translateX(46vw) translateY(-42vh) scale(0.62);
  }

  22.5925925926% {
    transform: translateX(37vw) translateY(2vh) scale(0.75);
  }

  22.962962963% {
    transform: translateX(25vw) translateY(-9vh) scale(0.64);
  }

  23.3333333333% {
    transform: translateX(24vw) translateY(31vh) scale(0.61);
  }

  23.7037037037% {
    transform: translateX(25vw) translateY(30vh) scale(0.44);
  }

  24.0740740741% {
    transform: translateX(17vw) translateY(25vh) scale(0.39);
  }

  24.4444444444% {
    transform: translateX(46vw) translateY(-48vh) scale(0.72);
  }

  24.8148148148% {
    transform: translateX(-25vw) translateY(-33vh) scale(0.42);
  }

  25.1851851852% {
    transform: translateX(50vw) translateY(49vh) scale(0.26);
  }

  25.5555555556% {
    transform: translateX(-45vw) translateY(-3vh) scale(0.29);
  }

  25.9259259259% {
    transform: translateX(-2vw) translateY(-44vh) scale(0.83);
  }

  26.2962962963% {
    transform: translateX(-15vw) translateY(40vh) scale(0.55);
  }

  26.6666666667% {
    transform: translateX(-6vw) translateY(-14vh) scale(0.39);
  }

  27.037037037% {
    transform: translateX(25vw) translateY(-39vh) scale(0.94);
  }

  27.4074074074% {
    transform: translateX(-5vw) translateY(-29vh) scale(0.94);
  }

  27.7777777778% {
    transform: translateX(-29vw) translateY(-3vh) scale(0.5);
  }

  28.1481481481% {
    transform: translateX(15vw) translateY(-35vh) scale(0.66);
  }

  28.5185185185% {
    transform: translateX(-5vw) translateY(4vh) scale(0.65);
  }

  28.8888888889% {
    transform: translateX(9vw) translateY(-5vh) scale(0.71);
  }

  29.2592592593% {
    transform: translateX(-19vw) translateY(44vh) scale(0.99);
  }

  29.6296296296% {
    transform: translateX(-19vw) translateY(17vh) scale(0.62);
  }

  30% {
    transform: translateX(40vw) translateY(42vh) scale(0.29);
  }

  30.3703703704% {
    transform: translateX(-15vw) translateY(19vh) scale(0.76);
  }

  30.7407407407% {
    transform: translateX(40vw) translateY(41vh) scale(0.96);
  }

  31.1111111111% {
    transform: translateX(-8vw) translateY(-30vh) scale(0.93);
  }

  31.4814814815% {
    transform: translateX(-42vw) translateY(-36vh) scale(0.85);
  }

  31.8518518519% {
    transform: translateX(45vw) translateY(-43vh) scale(0.47);
  }

  32.2222222222% {
    transform: translateX(-11vw) translateY(-3vh) scale(0.89);
  }

  32.5925925926% {
    transform: translateX(-42vw) translateY(26vh) scale(0.75);
  }

  32.962962963% {
    transform: translateX(9vw) translateY(32vh) scale(0.59);
  }

  33.3333333333% {
    transform: translateX(-7vw) translateY(-29vh) scale(0.84);
  }

  33.7037037037% {
    transform: translateX(0vw) translateY(16vh) scale(0.93);
  }

  34.0740740741% {
    transform: translateX(39vw) translateY(37vh) scale(1);
  }

  34.4444444444% {
    transform: translateX(32vw) translateY(7vh) scale(0.97);
  }

  34.8148148148% {
    transform: translateX(28vw) translateY(50vh) scale(0.93);
  }

  35.1851851852% {
    transform: translateX(-46vw) translateY(-15vh) scale(0.87);
  }

  35.5555555556% {
    transform: translateX(7vw) translateY(-1vh) scale(0.35);
  }

  35.9259259259% {
    transform: translateX(46vw) translateY(-14vh) scale(0.31);
  }

  36.2962962963% {
    transform: translateX(16vw) translateY(24vh) scale(0.51);
  }

  36.6666666667% {
    transform: translateX(-33vw) translateY(26vh) scale(0.86);
  }

  37.037037037% {
    transform: translateX(0vw) translateY(-1vh) scale(0.55);
  }

  37.4074074074% {
    transform: translateX(-7vw) translateY(35vh) scale(0.7);
  }

  37.7777777778% {
    transform: translateX(-29vw) translateY(43vh) scale(0.55);
  }

  38.1481481481% {
    transform: translateX(26vw) translateY(35vh) scale(0.4);
  }

  38.5185185185% {
    transform: translateX(46vw) translateY(12vh) scale(0.28);
  }

  38.8888888889% {
    transform: translateX(28vw) translateY(-16vh) scale(0.8);
  }

  39.2592592593% {
    transform: translateX(4vw) translateY(-45vh) scale(0.83);
  }

  39.6296296296% {
    transform: translateX(-45vw) translateY(-34vh) scale(0.37);
  }

  40% {
    transform: translateX(-24vw) translateY(-16vh) scale(0.92);
  }

  40.3703703704% {
    transform: translateX(-42vw) translateY(-15vh) scale(0.41);
  }

  40.7407407407% {
    transform: translateX(8vw) translateY(-19vh) scale(0.76);
  }

  41.1111111111% {
    transform: translateX(35vw) translateY(-37vh) scale(0.96);
  }

  41.4814814815% {
    transform: translateX(43vw) translateY(47vh) scale(0.79);
  }

  41.8518518519% {
    transform: translateX(-20vw) translateY(6vh) scale(0.93);
  }

  42.2222222222% {
    transform: translateX(-39vw) translateY(-46vh) scale(0.99);
  }

  42.5925925926% {
    transform: translateX(27vw) translateY(-27vh) scale(0.7);
  }

  42.962962963% {
    transform: translateX(20vw) translateY(-31vh) scale(0.45);
  }

  43.3333333333% {
    transform: translateX(-29vw) translateY(-48vh) scale(0.93);
  }

  43.7037037037% {
    transform: translateX(14vw) translateY(14vh) scale(0.96);
  }

  44.0740740741% {
    transform: translateX(22vw) translateY(-27vh) scale(0.63);
  }

  44.4444444444% {
    transform: translateX(6vw) translateY(13vh) scale(0.73);
  }

  44.8148148148% {
    transform: translateX(50vw) translateY(2vh) scale(0.49);
  }

  45.1851851852% {
    transform: translateX(-10vw) translateY(48vh) scale(0.28);
  }

  45.5555555556% {
    transform: translateX(-8vw) translateY(-36vh) scale(0.67);
  }

  45.9259259259% {
    transform: translateX(-43vw) translateY(41vh) scale(0.85);
  }

  46.2962962963% {
    transform: translateX(-20vw) translateY(-46vh) scale(0.85);
  }

  46.6666666667% {
    transform: translateX(-18vw) translateY(48vh) scale(0.93);
  }

  47.037037037% {
    transform: translateX(2vw) translateY(-23vh) scale(0.37);
  }

  47.4074074074% {
    transform: translateX(-9vw) translateY(-40vh) scale(0.79);
  }

  47.7777777778% {
    transform: translateX(29vw) translateY(27vh) scale(0.89);
  }

  48.1481481481% {
    transform: translateX(-11vw) translateY(0vh) scale(0.39);
  }

  48.5185185185% {
    transform: translateX(28vw) translateY(10vh) scale(0.74);
  }

  48.8888888889% {
    transform: translateX(-35vw) translateY(40vh) scale(0.73);
  }

  49.2592592593% {
    transform: translateX(-16vw) translateY(-43vh) scale(0.67);
  }

  49.6296296296% {
    transform: translateX(-27vw) translateY(36vh) scale(0.45);
  }

  50% {
    transform: translateX(-34vw) translateY(32vh) scale(0.94);
  }

  50.3703703704% {
    transform: translateX(-34vw) translateY(-32vh) scale(0.33);
  }

  50.7407407407% {
    transform: translateX(17vw) translateY(0vh) scale(0.39);
  }

  51.1111111111% {
    transform: translateX(-14vw) translateY(-27vh) scale(0.86);
  }

  51.4814814815% {
    transform: translateX(40vw) translateY(3vh) scale(0.26);
  }

  51.8518518519% {
    transform: translateX(-14vw) translateY(25vh) scale(0.75);
  }

  52.2222222222% {
    transform: translateX(-47vw) translateY(23vh) scale(0.92);
  }

  52.5925925926% {
    transform: translateX(-21vw) translateY(22vh) scale(0.27);
  }

  52.962962963% {
    transform: translateX(50vw) translateY(-44vh) scale(0.35);
  }

  53.3333333333% {
    transform: translateX(13vw) translateY(13vh) scale(0.57);
  }

  53.7037037037% {
    transform: translateX(-3vw) translateY(-33vh) scale(0.69);
  }

  54.0740740741% {
    transform: translateX(-38vw) translateY(-21vh) scale(0.34);
  }

  54.4444444444% {
    transform: translateX(49vw) translateY(-47vh) scale(0.58);
  }

  54.8148148148% {
    transform: translateX(27vw) translateY(31vh) scale(0.38);
  }

  55.1851851852% {
    transform: translateX(18vw) translateY(29vh) scale(0.62);
  }

  55.5555555556% {
    transform: translateX(-28vw) translateY(-38vh) scale(0.8);
  }

  55.9259259259% {
    transform: translateX(21vw) translateY(-22vh) scale(0.7);
  }

  56.2962962963% {
    transform: translateX(18vw) translateY(16vh) scale(0.49);
  }

  56.6666666667% {
    transform: translateX(35vw) translateY(-22vh) scale(0.96);
  }

  57.037037037% {
    transform: translateX(29vw) translateY(13vh) scale(0.39);
  }

  57.4074074074% {
    transform: translateX(30vw) translateY(14vh) scale(0.36);
  }

  57.7777777778% {
    transform: translateX(41vw) translateY(-21vh) scale(0.58);
  }

  58.1481481481% {
    transform: translateX(-31vw) translateY(6vh) scale(0.58);
  }

  58.5185185185% {
    transform: translateX(31vw) translateY(-41vh) scale(0.71);
  }

  58.8888888889% {
    transform: translateX(34vw) translateY(-28vh) scale(0.69);
  }

  59.2592592593% {
    transform: translateX(-1vw) translateY(-9vh) scale(0.57);
  }

  59.6296296296% {
    transform: translateX(40vw) translateY(35vh) scale(0.99);
  }

  60% {
    transform: translateX(9vw) translateY(-3vh) scale(0.69);
  }

  60.3703703704% {
    transform: translateX(-18vw) translateY(30vh) scale(0.76);
  }

  60.7407407407% {
    transform: translateX(11vw) translateY(36vh) scale(0.39);
  }

  61.1111111111% {
    transform: translateX(49vw) translateY(26vh) scale(0.49);
  }

  61.4814814815% {
    transform: translateX(16vw) translateY(-1vh) scale(0.26);
  }

  61.8518518519% {
    transform: translateX(26vw) translateY(30vh) scale(0.53);
  }

  62.2222222222% {
    transform: translateX(-21vw) translateY(41vh) scale(0.36);
  }

  62.5925925926% {
    transform: translateX(-19vw) translateY(-41vh) scale(0.41);
  }

  62.962962963% {
    transform: translateX(-43vw) translateY(-32vh) scale(0.55);
  }

  63.3333333333% {
    transform: translateX(-45vw) translateY(12vh) scale(0.33);
  }

  63.7037037037% {
    transform: translateX(-47vw) translateY(-20vh) scale(0.7);
  }

  64.0740740741% {
    transform: translateX(3vw) translateY(-30vh) scale(0.75);
  }

  64.4444444444% {
    transform: translateX(48vw) translateY(-24vh) scale(0.86);
  }

  64.8148148148% {
    transform: translateX(48vw) translateY(27vh) scale(0.68);
  }

  65.1851851852% {
    transform: translateX(25vw) translateY(19vh) scale(0.27);
  }

  65.5555555556% {
    transform: translateX(1vw) translateY(-31vh) scale(0.37);
  }

  65.9259259259% {
    transform: translateX(-10vw) translateY(-12vh) scale(0.98);
  }

  66.2962962963% {
    transform: translateX(23vw) translateY(-12vh) scale(0.95);
  }

  66.6666666667% {
    transform: translateX(-2vw) translateY(19vh) scale(0.48);
  }

  67.037037037% {
    transform: translateX(21vw) translateY(-7vh) scale(0.47);
  }

  67.4074074074% {
    transform: translateX(-4vw) translateY(25vh) scale(0.91);
  }

  67.7777777778% {
    transform: translateX(22vw) translateY(-23vh) scale(0.53);
  }

  68.1481481481% {
    transform: translateX(-44vw) translateY(38vh) scale(0.92);
  }

  68.5185185185% {
    transform: translateX(38vw) translateY(11vh) scale(0.31);
  }

  68.8888888889% {
    transform: translateX(47vw) translateY(35vh) scale(0.67);
  }

  69.2592592593% {
    transform: translateX(25vw) translateY(-37vh) scale(0.92);
  }

  69.6296296296% {
    transform: translateX(-45vw) translateY(8vh) scale(0.53);
  }

  70% {
    transform: translateX(36vw) translateY(-46vh) scale(0.55);
  }

  70.3703703704% {
    transform: translateX(39vw) translateY(44vh) scale(0.41);
  }

  70.7407407407% {
    transform: translateX(16vw) translateY(9vh) scale(0.65);
  }

  71.1111111111% {
    transform: translateX(25vw) translateY(-13vh) scale(0.38);
  }

  71.4814814815% {
    transform: translateX(10vw) translateY(4vh) scale(0.99);
  }

  71.8518518519% {
    transform: translateX(26vw) translateY(36vh) scale(0.53);
  }

  72.2222222222% {
    transform: translateX(36vw) translateY(49vh) scale(0.48);
  }

  72.5925925926% {
    transform: translateX(-27vw) translateY(2vh) scale(0.39);
  }

  72.962962963% {
    transform: translateX(-7vw) translateY(-12vh) scale(0.56);
  }

  73.3333333333% {
    transform: translateX(-18vw) translateY(-21vh) scale(0.59);
  }

  73.7037037037% {
    transform: translateX(-48vw) translateY(-31vh) scale(0.45);
  }

  74.0740740741% {
    transform: translateX(22vw) translateY(18vh) scale(0.27);
  }

  74.4444444444% {
    transform: translateX(36vw) translateY(26vh) scale(0.91);
  }

  74.8148148148% {
    transform: translateX(17vw) translateY(-25vh) scale(0.74);
  }

  75.1851851852% {
    transform: translateX(45vw) translateY(5vh) scale(0.69);
  }

  75.5555555556% {
    transform: translateX(-30vw) translateY(1vh) scale(0.77);
  }

  75.9259259259% {
    transform: translateX(-46vw) translateY(47vh) scale(0.89);
  }

  76.2962962963% {
    transform: translateX(19vw) translateY(-38vh) scale(0.38);
  }

  76.6666666667% {
    transform: translateX(24vw) translateY(39vh) scale(0.64);
  }

  77.037037037% {
    transform: translateX(19vw) translateY(40vh) scale(0.5);
  }

  77.4074074074% {
    transform: translateX(-39vw) translateY(-34vh) scale(0.91);
  }

  77.7777777778% {
    transform: translateX(-37vw) translateY(22vh) scale(0.44);
  }

  78.1481481481% {
    transform: translateX(-14vw) translateY(-31vh) scale(0.64);
  }

  78.5185185185% {
    transform: translateX(17vw) translateY(37vh) scale(0.47);
  }

  78.8888888889% {
    transform: translateX(-7vw) translateY(-32vh) scale(0.91);
  }

  79.2592592593% {
    transform: translateX(-46vw) translateY(48vh) scale(0.52);
  }

  79.6296296296% {
    transform: translateX(5vw) translateY(-18vh) scale(0.6);
  }

  80% {
    transform: translateX(-13vw) translateY(-27vh) scale(0.98);
  }

  80.3703703704% {
    transform: translateX(30vw) translateY(38vh) scale(0.96);
  }

  80.7407407407% {
    transform: translateX(27vw) translateY(-47vh) scale(0.38);
  }

  81.1111111111% {
    transform: translateX(15vw) translateY(13vh) scale(0.9);
  }

  81.4814814815% {
    transform: translateX(42vw) translateY(3vh) scale(0.72);
  }

  81.8518518519% {
    transform: translateX(-4vw) translateY(8vh) scale(0.46);
  }

  82.2222222222% {
    transform: translateX(27vw) translateY(-28vh) scale(0.77);
  }

  82.5925925926% {
    transform: translateX(-48vw) translateY(12vh) scale(0.33);
  }

  82.962962963% {
    transform: translateX(32vw) translateY(49vh) scale(0.6);
  }

  83.3333333333% {
    transform: translateX(-30vw) translateY(-48vh) scale(0.96);
  }

  83.7037037037% {
    transform: translateX(18vw) translateY(-20vh) scale(0.55);
  }

  84.0740740741% {
    transform: translateX(-31vw) translateY(10vh) scale(0.83);
  }

  84.4444444444% {
    transform: translateX(-11vw) translateY(30vh) scale(0.89);
  }

  84.8148148148% {
    transform: translateX(-43vw) translateY(45vh) scale(0.64);
  }

  85.1851851852% {
    transform: translateX(-47vw) translateY(-40vh) scale(0.53);
  }

  85.5555555556% {
    transform: translateX(-42vw) translateY(-48vh) scale(0.41);
  }

  85.9259259259% {
    transform: translateX(-12vw) translateY(-31vh) scale(0.5);
  }

  86.2962962963% {
    transform: translateX(-31vw) translateY(50vh) scale(0.4);
  }

  86.6666666667% {
    transform: translateX(-34vw) translateY(9vh) scale(0.37);
  }

  87.037037037% {
    transform: translateX(13vw) translateY(-7vh) scale(0.63);
  }

  87.4074074074% {
    transform: translateX(-16vw) translateY(-46vh) scale(0.63);
  }

  87.7777777778% {
    transform: translateX(27vw) translateY(-25vh) scale(0.5);
  }

  88.1481481481% {
    transform: translateX(16vw) translateY(-19vh) scale(0.89);
  }

  88.5185185185% {
    transform: translateX(-17vw) translateY(-9vh) scale(0.63);
  }

  88.8888888889% {
    transform: translateX(32vw) translateY(14vh) scale(0.43);
  }

  89.2592592593% {
    transform: translateX(-17vw) translateY(-17vh) scale(0.65);
  }

  89.6296296296% {
    transform: translateX(-32vw) translateY(-30vh) scale(0.67);
  }

  90% {
    transform: translateX(-31vw) translateY(-29vh) scale(0.62);
  }

  90.3703703704% {
    transform: translateX(44vw) translateY(-1vh) scale(0.35);
  }

  90.7407407407% {
    transform: translateX(-7vw) translateY(17vh) scale(0.85);
  }

  91.1111111111% {
    transform: translateX(3vw) translateY(-34vh) scale(0.68);
  }

  91.4814814815% {
    transform: translateX(-8vw) translateY(-37vh) scale(0.93);
  }

  91.8518518519% {
    transform: translateX(40vw) translateY(21vh) scale(1);
  }

  92.2222222222% {
    transform: translateX(-12vw) translateY(39vh) scale(0.7);
  }

  92.5925925926% {
    transform: translateX(27vw) translateY(15vh) scale(0.43);
  }

  92.962962963% {
    transform: translateX(9vw) translateY(-44vh) scale(0.56);
  }

  93.3333333333% {
    transform: translateX(-38vw) translateY(-10vh) scale(0.31);
  }

  93.7037037037% {
    transform: translateX(42vw) translateY(-42vh) scale(0.88);
  }

  94.0740740741% {
    transform: translateX(31vw) translateY(0vh) scale(0.59);
  }

  94.4444444444% {
    transform: translateX(33vw) translateY(3vh) scale(0.88);
  }

  94.8148148148% {
    transform: translateX(41vw) translateY(18vh) scale(0.71);
  }

  95.1851851852% {
    transform: translateX(31vw) translateY(30vh) scale(0.91);
  }

  95.5555555556% {
    transform: translateX(-35vw) translateY(19vh) scale(0.74);
  }

  95.9259259259% {
    transform: translateX(42vw) translateY(-39vh) scale(0.65);
  }

  96.2962962963% {
    transform: translateX(41vw) translateY(44vh) scale(0.48);
  }

  96.6666666667% {
    transform: translateX(32vw) translateY(-20vh) scale(0.54);
  }

  97.037037037% {
    transform: translateX(33vw) translateY(17vh) scale(0.54);
  }

  97.4074074074% {
    transform: translateX(-32vw) translateY(37vh) scale(0.29);
  }

  97.7777777778% {
    transform: translateX(-2vw) translateY(4vh) scale(0.8);
  }

  98.1481481481% {
    transform: translateX(-25vw) translateY(0vh) scale(0.27);
  }

  98.5185185185% {
    transform: translateX(9vw) translateY(-32vh) scale(0.71);
  }

  98.8888888889% {
    transform: translateX(6vw) translateY(35vh) scale(0.79);
  }

  99.2592592593% {
    transform: translateX(-11vw) translateY(37vh) scale(0.62);
  }

  99.6296296296% {
    transform: translateX(-21vw) translateY(-42vh) scale(0.77);
  }

  100% {
    transform: translateX(-49vw) translateY(26vh) scale(0.65);
  }
}

.firefly:nth-child(17) {
  animation-name: move17;
}

.firefly:nth-child(17)::before {
  animation-duration: 13s;
}

.firefly:nth-child(17)::after {
  animation-duration: 13s, 5532ms;
  animation-delay: 0ms, 5011ms;
}

@keyframes move17 {
  0% {
    transform: translateX(0vw) translateY(-25vh) scale(0.71);
  }

  0.3636363636% {
    transform: translateX(-16vw) translateY(-43vh) scale(0.88);
  }

  0.7272727273% {
    transform: translateX(32vw) translateY(17vh) scale(0.57);
  }

  1.0909090909% {
    transform: translateX(6vw) translateY(-39vh) scale(0.74);
  }

  1.4545454545% {
    transform: translateX(-4vw) translateY(-38vh) scale(0.49);
  }

  1.8181818182% {
    transform: translateX(-31vw) translateY(-11vh) scale(0.92);
  }

  2.1818181818% {
    transform: translateX(-15vw) translateY(-32vh) scale(0.65);
  }

  2.5454545455% {
    transform: translateX(-15vw) translateY(17vh) scale(0.9);
  }

  2.9090909091% {
    transform: translateX(-21vw) translateY(30vh) scale(0.78);
  }

  3.2727272727% {
    transform: translateX(-5vw) translateY(-37vh) scale(0.8);
  }

  3.6363636364% {
    transform: translateX(4vw) translateY(23vh) scale(0.81);
  }

  4% {
    transform: translateX(-6vw) translateY(-47vh) scale(0.96);
  }

  4.3636363636% {
    transform: translateX(3vw) translateY(-7vh) scale(0.77);
  }

  4.7272727273% {
    transform: translateX(-12vw) translateY(20vh) scale(0.36);
  }

  5.0909090909% {
    transform: translateX(-33vw) translateY(-3vh) scale(0.63);
  }

  5.4545454545% {
    transform: translateX(-30vw) translateY(13vh) scale(0.7);
  }

  5.8181818182% {
    transform: translateX(2vw) translateY(25vh) scale(0.29);
  }

  6.1818181818% {
    transform: translateX(-38vw) translateY(31vh) scale(0.26);
  }

  6.5454545455% {
    transform: translateX(1vw) translateY(13vh) scale(0.55);
  }

  6.9090909091% {
    transform: translateX(-32vw) translateY(-4vh) scale(0.97);
  }

  7.2727272727% {
    transform: translateX(-14vw) translateY(-21vh) scale(0.56);
  }

  7.6363636364% {
    transform: translateX(49vw) translateY(42vh) scale(0.37);
  }

  8% {
    transform: translateX(-25vw) translateY(-25vh) scale(0.32);
  }

  8.3636363636% {
    transform: translateX(10vw) translateY(43vh) scale(0.46);
  }

  8.7272727273% {
    transform: translateX(9vw) translateY(-33vh) scale(0.66);
  }

  9.0909090909% {
    transform: translateX(-47vw) translateY(3vh) scale(0.92);
  }

  9.4545454545% {
    transform: translateX(16vw) translateY(-28vh) scale(0.93);
  }

  9.8181818182% {
    transform: translateX(-3vw) translateY(-24vh) scale(0.79);
  }

  10.1818181818% {
    transform: translateX(-44vw) translateY(9vh) scale(0.32);
  }

  10.5454545455% {
    transform: translateX(5vw) translateY(21vh) scale(0.36);
  }

  10.9090909091% {
    transform: translateX(7vw) translateY(-5vh) scale(0.65);
  }

  11.2727272727% {
    transform: translateX(3vw) translateY(11vh) scale(0.55);
  }

  11.6363636364% {
    transform: translateX(-17vw) translateY(47vh) scale(0.45);
  }

  12% {
    transform: translateX(-5vw) translateY(33vh) scale(0.31);
  }

  12.3636363636% {
    transform: translateX(17vw) translateY(18vh) scale(0.61);
  }

  12.7272727273% {
    transform: translateX(-44vw) translateY(-30vh) scale(0.45);
  }

  13.0909090909% {
    transform: translateX(-3vw) translateY(13vh) scale(0.88);
  }

  13.4545454545% {
    transform: translateX(37vw) translateY(-48vh) scale(0.68);
  }

  13.8181818182% {
    transform: translateX(-48vw) translateY(-47vh) scale(0.96);
  }

  14.1818181818% {
    transform: translateX(-28vw) translateY(39vh) scale(0.91);
  }

  14.5454545455% {
    transform: translateX(-15vw) translateY(-23vh) scale(0.62);
  }

  14.9090909091% {
    transform: translateX(-37vw) translateY(-43vh) scale(0.4);
  }

  15.2727272727% {
    transform: translateX(34vw) translateY(49vh) scale(0.71);
  }

  15.6363636364% {
    transform: translateX(6vw) translateY(-6vh) scale(0.77);
  }

  16% {
    transform: translateX(-35vw) translateY(-9vh) scale(0.89);
  }

  16.3636363636% {
    transform: translateX(-33vw) translateY(6vh) scale(0.39);
  }

  16.7272727273% {
    transform: translateX(-24vw) translateY(-45vh) scale(0.27);
  }

  17.0909090909% {
    transform: translateX(26vw) translateY(-21vh) scale(0.26);
  }

  17.4545454545% {
    transform: translateX(-42vw) translateY(14vh) scale(0.8);
  }

  17.8181818182% {
    transform: translateX(3vw) translateY(-41vh) scale(0.69);
  }

  18.1818181818% {
    transform: translateX(8vw) translateY(-43vh) scale(0.93);
  }

  18.5454545455% {
    transform: translateX(20vw) translateY(14vh) scale(0.49);
  }

  18.9090909091% {
    transform: translateX(-44vw) translateY(32vh) scale(0.33);
  }

  19.2727272727% {
    transform: translateX(-11vw) translateY(34vh) scale(0.52);
  }

  19.6363636364% {
    transform: translateX(-49vw) translateY(-24vh) scale(0.65);
  }

  20% {
    transform: translateX(39vw) translateY(-28vh) scale(0.76);
  }

  20.3636363636% {
    transform: translateX(-11vw) translateY(-42vh) scale(0.96);
  }

  20.7272727273% {
    transform: translateX(-37vw) translateY(0vh) scale(0.38);
  }

  21.0909090909% {
    transform: translateX(-48vw) translateY(-29vh) scale(0.88);
  }

  21.4545454545% {
    transform: translateX(-39vw) translateY(19vh) scale(0.63);
  }

  21.8181818182% {
    transform: translateX(-33vw) translateY(-4vh) scale(0.94);
  }

  22.1818181818% {
    transform: translateX(49vw) translateY(45vh) scale(0.38);
  }

  22.5454545455% {
    transform: translateX(-38vw) translateY(22vh) scale(0.66);
  }

  22.9090909091% {
    transform: translateX(26vw) translateY(-29vh) scale(0.89);
  }

  23.2727272727% {
    transform: translateX(-39vw) translateY(-33vh) scale(0.68);
  }

  23.6363636364% {
    transform: translateX(24vw) translateY(-9vh) scale(0.94);
  }

  24% {
    transform: translateX(27vw) translateY(-26vh) scale(0.83);
  }

  24.3636363636% {
    transform: translateX(39vw) translateY(31vh) scale(0.67);
  }

  24.7272727273% {
    transform: translateX(40vw) translateY(-33vh) scale(0.54);
  }

  25.0909090909% {
    transform: translateX(5vw) translateY(-16vh) scale(0.56);
  }

  25.4545454545% {
    transform: translateX(-26vw) translateY(-6vh) scale(0.68);
  }

  25.8181818182% {
    transform: translateX(-41vw) translateY(-4vh) scale(0.26);
  }

  26.1818181818% {
    transform: translateX(30vw) translateY(19vh) scale(0.83);
  }

  26.5454545455% {
    transform: translateX(-18vw) translateY(45vh) scale(0.76);
  }

  26.9090909091% {
    transform: translateX(-21vw) translateY(14vh) scale(0.82);
  }

  27.2727272727% {
    transform: translateX(-10vw) translateY(11vh) scale(0.85);
  }

  27.6363636364% {
    transform: translateX(36vw) translateY(6vh) scale(0.89);
  }

  28% {
    transform: translateX(-49vw) translateY(-3vh) scale(0.48);
  }

  28.3636363636% {
    transform: translateX(47vw) translateY(-43vh) scale(0.71);
  }

  28.7272727273% {
    transform: translateX(-48vw) translateY(-30vh) scale(0.87);
  }

  29.0909090909% {
    transform: translateX(35vw) translateY(-38vh) scale(0.33);
  }

  29.4545454545% {
    transform: translateX(-38vw) translateY(-27vh) scale(0.54);
  }

  29.8181818182% {
    transform: translateX(-11vw) translateY(-23vh) scale(0.65);
  }

  30.1818181818% {
    transform: translateX(-45vw) translateY(-38vh) scale(0.84);
  }

  30.5454545455% {
    transform: translateX(-9vw) translateY(45vh) scale(0.34);
  }

  30.9090909091% {
    transform: translateX(16vw) translateY(32vh) scale(0.46);
  }

  31.2727272727% {
    transform: translateX(45vw) translateY(-38vh) scale(0.66);
  }

  31.6363636364% {
    transform: translateX(33vw) translateY(38vh) scale(0.98);
  }

  32% {
    transform: translateX(-15vw) translateY(17vh) scale(0.78);
  }

  32.3636363636% {
    transform: translateX(26vw) translateY(32vh) scale(0.45);
  }

  32.7272727273% {
    transform: translateX(-28vw) translateY(-49vh) scale(0.33);
  }

  33.0909090909% {
    transform: translateX(-16vw) translateY(-16vh) scale(0.63);
  }

  33.4545454545% {
    transform: translateX(-19vw) translateY(-18vh) scale(0.35);
  }

  33.8181818182% {
    transform: translateX(-42vw) translateY(7vh) scale(0.35);
  }

  34.1818181818% {
    transform: translateX(-45vw) translateY(-29vh) scale(0.65);
  }

  34.5454545455% {
    transform: translateX(12vw) translateY(-14vh) scale(0.56);
  }

  34.9090909091% {
    transform: translateX(25vw) translateY(46vh) scale(0.29);
  }

  35.2727272727% {
    transform: translateX(-14vw) translateY(-1vh) scale(0.58);
  }

  35.6363636364% {
    transform: translateX(0vw) translateY(29vh) scale(0.28);
  }

  36% {
    transform: translateX(-41vw) translateY(-13vh) scale(0.61);
  }

  36.3636363636% {
    transform: translateX(-14vw) translateY(22vh) scale(0.37);
  }

  36.7272727273% {
    transform: translateX(23vw) translateY(34vh) scale(0.93);
  }

  37.0909090909% {
    transform: translateX(-47vw) translateY(-7vh) scale(0.41);
  }

  37.4545454545% {
    transform: translateX(-26vw) translateY(37vh) scale(0.31);
  }

  37.8181818182% {
    transform: translateX(24vw) translateY(-33vh) scale(0.3);
  }

  38.1818181818% {
    transform: translateX(-2vw) translateY(-12vh) scale(0.63);
  }

  38.5454545455% {
    transform: translateX(32vw) translateY(11vh) scale(0.49);
  }

  38.9090909091% {
    transform: translateX(-29vw) translateY(-19vh) scale(0.3);
  }

  39.2727272727% {
    transform: translateX(2vw) translateY(-2vh) scale(0.64);
  }

  39.6363636364% {
    transform: translateX(-41vw) translateY(-25vh) scale(0.9);
  }

  40% {
    transform: translateX(50vw) translateY(-48vh) scale(0.58);
  }

  40.3636363636% {
    transform: translateX(22vw) translateY(14vh) scale(0.88);
  }

  40.7272727273% {
    transform: translateX(-40vw) translateY(31vh) scale(0.47);
  }

  41.0909090909% {
    transform: translateX(49vw) translateY(-38vh) scale(0.83);
  }

  41.4545454545% {
    transform: translateX(4vw) translateY(36vh) scale(0.49);
  }

  41.8181818182% {
    transform: translateX(-45vw) translateY(-10vh) scale(0.68);
  }

  42.1818181818% {
    transform: translateX(9vw) translateY(-34vh) scale(0.38);
  }

  42.5454545455% {
    transform: translateX(37vw) translateY(34vh) scale(0.75);
  }

  42.9090909091% {
    transform: translateX(47vw) translateY(-30vh) scale(0.5);
  }

  43.2727272727% {
    transform: translateX(46vw) translateY(-45vh) scale(0.6);
  }

  43.6363636364% {
    transform: translateX(-6vw) translateY(3vh) scale(0.97);
  }

  44% {
    transform: translateX(-43vw) translateY(41vh) scale(1);
  }

  44.3636363636% {
    transform: translateX(42vw) translateY(-23vh) scale(0.95);
  }

  44.7272727273% {
    transform: translateX(25vw) translateY(-31vh) scale(0.69);
  }

  45.0909090909% {
    transform: translateX(-49vw) translateY(39vh) scale(0.32);
  }

  45.4545454545% {
    transform: translateX(-40vw) translateY(41vh) scale(0.97);
  }

  45.8181818182% {
    transform: translateX(48vw) translateY(24vh) scale(0.89);
  }

  46.1818181818% {
    transform: translateX(47vw) translateY(-19vh) scale(0.4);
  }

  46.5454545455% {
    transform: translateX(-15vw) translateY(-43vh) scale(0.51);
  }

  46.9090909091% {
    transform: translateX(-26vw) translateY(-37vh) scale(0.44);
  }

  47.2727272727% {
    transform: translateX(29vw) translateY(-12vh) scale(0.93);
  }

  47.6363636364% {
    transform: translateX(-28vw) translateY(0vh) scale(0.5);
  }

  48% {
    transform: translateX(-23vw) translateY(19vh) scale(0.3);
  }

  48.3636363636% {
    transform: translateX(-23vw) translateY(47vh) scale(0.45);
  }

  48.7272727273% {
    transform: translateX(44vw) translateY(16vh) scale(0.27);
  }

  49.0909090909% {
    transform: translateX(-4vw) translateY(47vh) scale(0.57);
  }

  49.4545454545% {
    transform: translateX(37vw) translateY(-17vh) scale(0.53);
  }

  49.8181818182% {
    transform: translateX(4vw) translateY(-26vh) scale(0.72);
  }

  50.1818181818% {
    transform: translateX(23vw) translateY(33vh) scale(0.81);
  }

  50.5454545455% {
    transform: translateX(-18vw) translateY(-18vh) scale(0.99);
  }

  50.9090909091% {
    transform: translateX(8vw) translateY(6vh) scale(0.78);
  }

  51.2727272727% {
    transform: translateX(-37vw) translateY(-23vh) scale(0.38);
  }

  51.6363636364% {
    transform: translateX(43vw) translateY(-25vh) scale(0.44);
  }

  52% {
    transform: translateX(-13vw) translateY(-21vh) scale(0.37);
  }

  52.3636363636% {
    transform: translateX(7vw) translateY(-47vh) scale(0.97);
  }

  52.7272727273% {
    transform: translateX(-8vw) translateY(-13vh) scale(0.81);
  }

  53.0909090909% {
    transform: translateX(-20vw) translateY(-2vh) scale(0.34);
  }

  53.4545454545% {
    transform: translateX(6vw) translateY(-17vh) scale(0.37);
  }

  53.8181818182% {
    transform: translateX(9vw) translateY(20vh) scale(0.28);
  }

  54.1818181818% {
    transform: translateX(25vw) translateY(-45vh) scale(0.81);
  }

  54.5454545455% {
    transform: translateX(20vw) translateY(-32vh) scale(0.54);
  }

  54.9090909091% {
    transform: translateX(15vw) translateY(-42vh) scale(0.48);
  }

  55.2727272727% {
    transform: translateX(-39vw) translateY(-27vh) scale(0.9);
  }

  55.6363636364% {
    transform: translateX(-46vw) translateY(-16vh) scale(0.38);
  }

  56% {
    transform: translateX(-12vw) translateY(41vh) scale(0.34);
  }

  56.3636363636% {
    transform: translateX(43vw) translateY(31vh) scale(0.91);
  }

  56.7272727273% {
    transform: translateX(35vw) translateY(16vh) scale(0.63);
  }

  57.0909090909% {
    transform: translateX(8vw) translateY(29vh) scale(0.78);
  }

  57.4545454545% {
    transform: translateX(-6vw) translateY(13vh) scale(0.94);
  }

  57.8181818182% {
    transform: translateX(15vw) translateY(-5vh) scale(0.43);
  }

  58.1818181818% {
    transform: translateX(-44vw) translateY(42vh) scale(0.4);
  }

  58.5454545455% {
    transform: translateX(-39vw) translateY(45vh) scale(0.5);
  }

  58.9090909091% {
    transform: translateX(31vw) translateY(22vh) scale(0.97);
  }

  59.2727272727% {
    transform: translateX(-2vw) translateY(-9vh) scale(0.43);
  }

  59.6363636364% {
    transform: translateX(39vw) translateY(2vh) scale(0.69);
  }

  60% {
    transform: translateX(-13vw) translateY(46vh) scale(0.46);
  }

  60.3636363636% {
    transform: translateX(50vw) translateY(-11vh) scale(0.86);
  }

  60.7272727273% {
    transform: translateX(-38vw) translateY(-43vh) scale(0.27);
  }

  61.0909090909% {
    transform: translateX(-35vw) translateY(-26vh) scale(0.98);
  }

  61.4545454545% {
    transform: translateX(27vw) translateY(4vh) scale(0.54);
  }

  61.8181818182% {
    transform: translateX(-30vw) translateY(2vh) scale(0.67);
  }

  62.1818181818% {
    transform: translateX(29vw) translateY(-22vh) scale(0.9);
  }

  62.5454545455% {
    transform: translateX(-2vw) translateY(-23vh) scale(0.52);
  }

  62.9090909091% {
    transform: translateX(-47vw) translateY(26vh) scale(0.53);
  }

  63.2727272727% {
    transform: translateX(-18vw) translateY(18vh) scale(0.55);
  }

  63.6363636364% {
    transform: translateX(18vw) translateY(-6vh) scale(0.97);
  }

  64% {
    transform: translateX(2vw) translateY(7vh) scale(0.29);
  }

  64.3636363636% {
    transform: translateX(31vw) translateY(-44vh) scale(0.48);
  }

  64.7272727273% {
    transform: translateX(-13vw) translateY(-3vh) scale(0.29);
  }

  65.0909090909% {
    transform: translateX(-13vw) translateY(26vh) scale(0.46);
  }

  65.4545454545% {
    transform: translateX(-15vw) translateY(-23vh) scale(0.49);
  }

  65.8181818182% {
    transform: translateX(45vw) translateY(-41vh) scale(0.4);
  }

  66.1818181818% {
    transform: translateX(-21vw) translateY(2vh) scale(0.95);
  }

  66.5454545455% {
    transform: translateX(50vw) translateY(43vh) scale(0.35);
  }

  66.9090909091% {
    transform: translateX(-30vw) translateY(31vh) scale(0.54);
  }

  67.2727272727% {
    transform: translateX(-48vw) translateY(12vh) scale(0.83);
  }

  67.6363636364% {
    transform: translateX(-42vw) translateY(-32vh) scale(0.29);
  }

  68% {
    transform: translateX(-1vw) translateY(-14vh) scale(0.59);
  }

  68.3636363636% {
    transform: translateX(-13vw) translateY(-34vh) scale(0.94);
  }

  68.7272727273% {
    transform: translateX(-33vw) translateY(-45vh) scale(0.69);
  }

  69.0909090909% {
    transform: translateX(43vw) translateY(13vh) scale(0.4);
  }

  69.4545454545% {
    transform: translateX(40vw) translateY(-6vh) scale(0.7);
  }

  69.8181818182% {
    transform: translateX(14vw) translateY(-15vh) scale(0.41);
  }

  70.1818181818% {
    transform: translateX(-20vw) translateY(45vh) scale(0.65);
  }

  70.5454545455% {
    transform: translateX(24vw) translateY(-6vh) scale(0.59);
  }

  70.9090909091% {
    transform: translateX(42vw) translateY(-24vh) scale(0.59);
  }

  71.2727272727% {
    transform: translateX(11vw) translateY(41vh) scale(0.55);
  }

  71.6363636364% {
    transform: translateX(-49vw) translateY(15vh) scale(0.61);
  }

  72% {
    transform: translateX(-26vw) translateY(45vh) scale(0.59);
  }

  72.3636363636% {
    transform: translateX(14vw) translateY(-35vh) scale(0.99);
  }

  72.7272727273% {
    transform: translateX(16vw) translateY(31vh) scale(0.71);
  }

  73.0909090909% {
    transform: translateX(32vw) translateY(-40vh) scale(1);
  }

  73.4545454545% {
    transform: translateX(-30vw) translateY(33vh) scale(0.63);
  }

  73.8181818182% {
    transform: translateX(-3vw) translateY(-21vh) scale(0.88);
  }

  74.1818181818% {
    transform: translateX(27vw) translateY(45vh) scale(1);
  }

  74.5454545455% {
    transform: translateX(-25vw) translateY(-19vh) scale(0.98);
  }

  74.9090909091% {
    transform: translateX(-11vw) translateY(-46vh) scale(0.34);
  }

  75.2727272727% {
    transform: translateX(36vw) translateY(-17vh) scale(0.28);
  }

  75.6363636364% {
    transform: translateX(-47vw) translateY(43vh) scale(0.48);
  }

  76% {
    transform: translateX(28vw) translateY(-25vh) scale(0.39);
  }

  76.3636363636% {
    transform: translateX(-34vw) translateY(43vh) scale(0.63);
  }

  76.7272727273% {
    transform: translateX(27vw) translateY(28vh) scale(0.74);
  }

  77.0909090909% {
    transform: translateX(4vw) translateY(-24vh) scale(0.91);
  }

  77.4545454545% {
    transform: translateX(10vw) translateY(30vh) scale(0.71);
  }

  77.8181818182% {
    transform: translateX(12vw) translateY(-31vh) scale(0.37);
  }

  78.1818181818% {
    transform: translateX(-15vw) translateY(-21vh) scale(0.33);
  }

  78.5454545455% {
    transform: translateX(-36vw) translateY(9vh) scale(0.71);
  }

  78.9090909091% {
    transform: translateX(-25vw) translateY(4vh) scale(0.65);
  }

  79.2727272727% {
    transform: translateX(47vw) translateY(-33vh) scale(0.59);
  }

  79.6363636364% {
    transform: translateX(-2vw) translateY(-8vh) scale(0.64);
  }

  80% {
    transform: translateX(48vw) translateY(41vh) scale(0.93);
  }

  80.3636363636% {
    transform: translateX(5vw) translateY(26vh) scale(0.39);
  }

  80.7272727273% {
    transform: translateX(-31vw) translateY(24vh) scale(0.85);
  }

  81.0909090909% {
    transform: translateX(47vw) translateY(37vh) scale(0.66);
  }

  81.4545454545% {
    transform: translateX(-5vw) translateY(-23vh) scale(0.37);
  }

  81.8181818182% {
    transform: translateX(1vw) translateY(-36vh) scale(0.35);
  }

  82.1818181818% {
    transform: translateX(1vw) translateY(-18vh) scale(0.57);
  }

  82.5454545455% {
    transform: translateX(38vw) translateY(-5vh) scale(0.42);
  }

  82.9090909091% {
    transform: translateX(10vw) translateY(-46vh) scale(0.43);
  }

  83.2727272727% {
    transform: translateX(-30vw) translateY(-14vh) scale(0.95);
  }

  83.6363636364% {
    transform: translateX(18vw) translateY(34vh) scale(0.77);
  }

  84% {
    transform: translateX(-46vw) translateY(-6vh) scale(0.36);
  }

  84.3636363636% {
    transform: translateX(-37vw) translateY(-16vh) scale(0.57);
  }

  84.7272727273% {
    transform: translateX(-48vw) translateY(39vh) scale(0.9);
  }

  85.0909090909% {
    transform: translateX(14vw) translateY(50vh) scale(0.83);
  }

  85.4545454545% {
    transform: translateX(42vw) translateY(-23vh) scale(0.32);
  }

  85.8181818182% {
    transform: translateX(-49vw) translateY(-47vh) scale(0.61);
  }

  86.1818181818% {
    transform: translateX(-2vw) translateY(-33vh) scale(0.43);
  }

  86.5454545455% {
    transform: translateX(-17vw) translateY(3vh) scale(0.67);
  }

  86.9090909091% {
    transform: translateX(-13vw) translateY(15vh) scale(0.29);
  }

  87.2727272727% {
    transform: translateX(27vw) translateY(-22vh) scale(0.36);
  }

  87.6363636364% {
    transform: translateX(-24vw) translateY(41vh) scale(0.4);
  }

  88% {
    transform: translateX(47vw) translateY(5vh) scale(0.45);
  }

  88.3636363636% {
    transform: translateX(15vw) translateY(-26vh) scale(0.29);
  }

  88.7272727273% {
    transform: translateX(-34vw) translateY(22vh) scale(0.82);
  }

  89.0909090909% {
    transform: translateX(33vw) translateY(-32vh) scale(0.81);
  }

  89.4545454545% {
    transform: translateX(38vw) translateY(4vh) scale(0.68);
  }

  89.8181818182% {
    transform: translateX(-33vw) translateY(-17vh) scale(0.9);
  }

  90.1818181818% {
    transform: translateX(46vw) translateY(-48vh) scale(0.41);
  }

  90.5454545455% {
    transform: translateX(-3vw) translateY(7vh) scale(0.47);
  }

  90.9090909091% {
    transform: translateX(-23vw) translateY(-38vh) scale(0.31);
  }

  91.2727272727% {
    transform: translateX(-29vw) translateY(-48vh) scale(0.5);
  }

  91.6363636364% {
    transform: translateX(42vw) translateY(37vh) scale(0.86);
  }

  92% {
    transform: translateX(-28vw) translateY(10vh) scale(0.99);
  }

  92.3636363636% {
    transform: translateX(18vw) translateY(-21vh) scale(0.58);
  }

  92.7272727273% {
    transform: translateX(-32vw) translateY(-11vh) scale(0.98);
  }

  93.0909090909% {
    transform: translateX(22vw) translateY(-10vh) scale(0.8);
  }

  93.4545454545% {
    transform: translateX(19vw) translateY(-17vh) scale(0.94);
  }

  93.8181818182% {
    transform: translateX(-42vw) translateY(8vh) scale(0.7);
  }

  94.1818181818% {
    transform: translateX(-24vw) translateY(-37vh) scale(0.93);
  }

  94.5454545455% {
    transform: translateX(-3vw) translateY(-10vh) scale(0.71);
  }

  94.9090909091% {
    transform: translateX(30vw) translateY(19vh) scale(0.81);
  }

  95.2727272727% {
    transform: translateX(15vw) translateY(47vh) scale(0.63);
  }

  95.6363636364% {
    transform: translateX(33vw) translateY(29vh) scale(0.98);
  }

  96% {
    transform: translateX(-19vw) translateY(-6vh) scale(1);
  }

  96.3636363636% {
    transform: translateX(-19vw) translateY(-19vh) scale(0.27);
  }

  96.7272727273% {
    transform: translateX(-16vw) translateY(14vh) scale(0.93);
  }

  97.0909090909% {
    transform: translateX(-49vw) translateY(-15vh) scale(0.56);
  }

  97.4545454545% {
    transform: translateX(26vw) translateY(-30vh) scale(0.81);
  }

  97.8181818182% {
    transform: translateX(2vw) translateY(-43vh) scale(0.72);
  }

  98.1818181818% {
    transform: translateX(35vw) translateY(-36vh) scale(0.61);
  }

  98.5454545455% {
    transform: translateX(1vw) translateY(-29vh) scale(0.6);
  }

  98.9090909091% {
    transform: translateX(-31vw) translateY(-45vh) scale(0.89);
  }

  99.2727272727% {
    transform: translateX(0vw) translateY(-2vh) scale(0.97);
  }

  99.6363636364% {
    transform: translateX(-15vw) translateY(-1vh) scale(0.7);
  }

  100% {
    transform: translateX(32vw) translateY(43vh) scale(0.97);
  }
}

.firefly:nth-child(18) {
  animation-name: move18;
}

.firefly:nth-child(18)::before {
  animation-duration: 10s;
}

.firefly:nth-child(18)::after {
  animation-duration: 10s, 9165ms;
  animation-delay: 0ms, 950ms;
}

@keyframes move18 {
  0% {
    transform: translateX(3vw) translateY(-15vh) scale(0.52);
  }

  0.5617977528% {
    transform: translateX(5vw) translateY(30vh) scale(0.56);
  }

  1.1235955056% {
    transform: translateX(25vw) translateY(33vh) scale(0.35);
  }

  1.6853932584% {
    transform: translateX(9vw) translateY(-28vh) scale(0.65);
  }

  2.2471910112% {
    transform: translateX(14vw) translateY(42vh) scale(0.49);
  }

  2.808988764% {
    transform: translateX(38vw) translateY(43vh) scale(0.82);
  }

  3.3707865169% {
    transform: translateX(33vw) translateY(-15vh) scale(0.87);
  }

  3.9325842697% {
    transform: translateX(-18vw) translateY(-8vh) scale(0.66);
  }

  4.4943820225% {
    transform: translateX(34vw) translateY(8vh) scale(0.82);
  }

  5.0561797753% {
    transform: translateX(12vw) translateY(-18vh) scale(0.32);
  }

  5.6179775281% {
    transform: translateX(3vw) translateY(-47vh) scale(0.89);
  }

  6.1797752809% {
    transform: translateX(27vw) translateY(-5vh) scale(0.53);
  }

  6.7415730337% {
    transform: translateX(-41vw) translateY(18vh) scale(0.85);
  }

  7.3033707865% {
    transform: translateX(-20vw) translateY(-2vh) scale(0.3);
  }

  7.8651685393% {
    transform: translateX(15vw) translateY(-23vh) scale(0.48);
  }

  8.4269662921% {
    transform: translateX(-48vw) translateY(34vh) scale(0.79);
  }

  8.9887640449% {
    transform: translateX(36vw) translateY(27vh) scale(0.85);
  }

  9.5505617978% {
    transform: translateX(28vw) translateY(41vh) scale(0.86);
  }

  10.1123595506% {
    transform: translateX(-1vw) translateY(-39vh) scale(0.81);
  }

  10.6741573034% {
    transform: translateX(7vw) translateY(36vh) scale(0.85);
  }

  11.2359550562% {
    transform: translateX(-3vw) translateY(41vh) scale(0.91);
  }

  11.797752809% {
    transform: translateX(-27vw) translateY(-36vh) scale(0.8);
  }

  12.3595505618% {
    transform: translateX(42vw) translateY(-12vh) scale(0.64);
  }

  12.9213483146% {
    transform: translateX(-3vw) translateY(13vh) scale(0.48);
  }

  13.4831460674% {
    transform: translateX(-12vw) translateY(10vh) scale(0.36);
  }

  14.0449438202% {
    transform: translateX(0vw) translateY(32vh) scale(0.61);
  }

  14.606741573% {
    transform: translateX(-27vw) translateY(-40vh) scale(0.47);
  }

  15.1685393258% {
    transform: translateX(-2vw) translateY(-40vh) scale(0.84);
  }

  15.7303370787% {
    transform: translateX(-23vw) translateY(-3vh) scale(0.5);
  }

  16.2921348315% {
    transform: translateX(43vw) translateY(-30vh) scale(0.35);
  }

  16.8539325843% {
    transform: translateX(-14vw) translateY(30vh) scale(0.7);
  }

  17.4157303371% {
    transform: translateX(7vw) translateY(4vh) scale(0.81);
  }

  17.9775280899% {
    transform: translateX(8vw) translateY(39vh) scale(0.91);
  }

  18.5393258427% {
    transform: translateX(-44vw) translateY(-15vh) scale(0.92);
  }

  19.1011235955% {
    transform: translateX(41vw) translateY(12vh) scale(0.29);
  }

  19.6629213483% {
    transform: translateX(36vw) translateY(-27vh) scale(0.49);
  }

  20.2247191011% {
    transform: translateX(11vw) translateY(50vh) scale(0.45);
  }

  20.7865168539% {
    transform: translateX(42vw) translateY(6vh) scale(0.77);
  }

  21.3483146067% {
    transform: translateX(27vw) translateY(27vh) scale(0.86);
  }

  21.9101123596% {
    transform: translateX(49vw) translateY(-35vh) scale(0.86);
  }

  22.4719101124% {
    transform: translateX(-49vw) translateY(0vh) scale(0.51);
  }

  23.0337078652% {
    transform: translateX(30vw) translateY(42vh) scale(0.27);
  }

  23.595505618% {
    transform: translateX(3vw) translateY(0vh) scale(0.42);
  }

  24.1573033708% {
    transform: translateX(-18vw) translateY(-38vh) scale(0.48);
  }

  24.7191011236% {
    transform: translateX(30vw) translateY(5vh) scale(0.3);
  }

  25.2808988764% {
    transform: translateX(-37vw) translateY(-11vh) scale(0.48);
  }

  25.8426966292% {
    transform: translateX(-10vw) translateY(2vh) scale(0.82);
  }

  26.404494382% {
    transform: translateX(35vw) translateY(48vh) scale(0.88);
  }

  26.9662921348% {
    transform: translateX(-30vw) translateY(-29vh) scale(0.61);
  }

  27.5280898876% {
    transform: translateX(27vw) translateY(-33vh) scale(0.94);
  }

  28.0898876404% {
    transform: translateX(-3vw) translateY(41vh) scale(0.63);
  }

  28.6516853933% {
    transform: translateX(41vw) translateY(40vh) scale(0.44);
  }

  29.2134831461% {
    transform: translateX(19vw) translateY(-36vh) scale(0.66);
  }

  29.7752808989% {
    transform: translateX(0vw) translateY(17vh) scale(0.89);
  }

  30.3370786517% {
    transform: translateX(-14vw) translateY(9vh) scale(0.7);
  }

  30.8988764045% {
    transform: translateX(48vw) translateY(-49vh) scale(0.34);
  }

  31.4606741573% {
    transform: translateX(-2vw) translateY(-21vh) scale(0.38);
  }

  32.0224719101% {
    transform: translateX(-39vw) translateY(41vh) scale(0.29);
  }

  32.5842696629% {
    transform: translateX(36vw) translateY(-13vh) scale(0.76);
  }

  33.1460674157% {
    transform: translateX(-33vw) translateY(-11vh) scale(0.89);
  }

  33.7078651685% {
    transform: translateX(-17vw) translateY(-44vh) scale(0.55);
  }

  34.2696629213% {
    transform: translateX(-21vw) translateY(16vh) scale(0.65);
  }

  34.8314606742% {
    transform: translateX(-48vw) translateY(-18vh) scale(0.37);
  }

  35.393258427% {
    transform: translateX(25vw) translateY(5vh) scale(0.76);
  }

  35.9550561798% {
    transform: translateX(50vw) translateY(50vh) scale(0.7);
  }

  36.5168539326% {
    transform: translateX(-21vw) translateY(24vh) scale(0.26);
  }

  37.0786516854% {
    transform: translateX(9vw) translateY(11vh) scale(0.79);
  }

  37.6404494382% {
    transform: translateX(14vw) translateY(-23vh) scale(0.3);
  }

  38.202247191% {
    transform: translateX(-29vw) translateY(-10vh) scale(0.82);
  }

  38.7640449438% {
    transform: translateX(28vw) translateY(25vh) scale(0.41);
  }

  39.3258426966% {
    transform: translateX(-28vw) translateY(-6vh) scale(0.7);
  }

  39.8876404494% {
    transform: translateX(16vw) translateY(-7vh) scale(0.76);
  }

  40.4494382022% {
    transform: translateX(-28vw) translateY(-3vh) scale(0.72);
  }

  41.0112359551% {
    transform: translateX(33vw) translateY(-21vh) scale(0.99);
  }

  41.5730337079% {
    transform: translateX(-36vw) translateY(-5vh) scale(0.8);
  }

  42.1348314607% {
    transform: translateX(-38vw) translateY(-34vh) scale(0.58);
  }

  42.6966292135% {
    transform: translateX(-29vw) translateY(-2vh) scale(0.68);
  }

  43.2584269663% {
    transform: translateX(48vw) translateY(-5vh) scale(0.49);
  }

  43.8202247191% {
    transform: translateX(40vw) translateY(15vh) scale(0.93);
  }

  44.3820224719% {
    transform: translateX(1vw) translateY(-36vh) scale(0.8);
  }

  44.9438202247% {
    transform: translateX(47vw) translateY(-47vh) scale(0.68);
  }

  45.5056179775% {
    transform: translateX(-32vw) translateY(-38vh) scale(0.95);
  }

  46.0674157303% {
    transform: translateX(-22vw) translateY(12vh) scale(0.49);
  }

  46.6292134831% {
    transform: translateX(2vw) translateY(23vh) scale(0.48);
  }

  47.191011236% {
    transform: translateX(39vw) translateY(4vh) scale(0.41);
  }

  47.7528089888% {
    transform: translateX(12vw) translateY(18vh) scale(0.52);
  }

  48.3146067416% {
    transform: translateX(-44vw) translateY(-49vh) scale(0.74);
  }

  48.8764044944% {
    transform: translateX(2vw) translateY(5vh) scale(0.68);
  }

  49.4382022472% {
    transform: translateX(-41vw) translateY(-11vh) scale(0.6);
  }

  50% {
    transform: translateX(49vw) translateY(28vh) scale(0.39);
  }

  50.5617977528% {
    transform: translateX(29vw) translateY(26vh) scale(0.45);
  }

  51.1235955056% {
    transform: translateX(-42vw) translateY(-23vh) scale(0.73);
  }

  51.6853932584% {
    transform: translateX(18vw) translateY(24vh) scale(0.27);
  }

  52.2471910112% {
    transform: translateX(-12vw) translateY(27vh) scale(0.28);
  }

  52.808988764% {
    transform: translateX(24vw) translateY(-48vh) scale(0.48);
  }

  53.3707865169% {
    transform: translateX(38vw) translateY(-9vh) scale(0.36);
  }

  53.9325842697% {
    transform: translateX(-5vw) translateY(18vh) scale(0.81);
  }

  54.4943820225% {
    transform: translateX(-13vw) translateY(27vh) scale(0.26);
  }

  55.0561797753% {
    transform: translateX(40vw) translateY(-29vh) scale(0.92);
  }

  55.6179775281% {
    transform: translateX(35vw) translateY(-11vh) scale(0.94);
  }

  56.1797752809% {
    transform: translateX(-7vw) translateY(31vh) scale(0.79);
  }

  56.7415730337% {
    transform: translateX(20vw) translateY(-3vh) scale(0.33);
  }

  57.3033707865% {
    transform: translateX(-1vw) translateY(-33vh) scale(0.36);
  }

  57.8651685393% {
    transform: translateX(34vw) translateY(-12vh) scale(0.34);
  }

  58.4269662921% {
    transform: translateX(-32vw) translateY(-37vh) scale(0.5);
  }

  58.9887640449% {
    transform: translateX(11vw) translateY(-7vh) scale(0.9);
  }

  59.5505617978% {
    transform: translateX(-49vw) translateY(-24vh) scale(0.84);
  }

  60.1123595506% {
    transform: translateX(-39vw) translateY(-38vh) scale(0.35);
  }

  60.6741573034% {
    transform: translateX(-30vw) translateY(-4vh) scale(0.36);
  }

  61.2359550562% {
    transform: translateX(45vw) translateY(25vh) scale(0.42);
  }

  61.797752809% {
    transform: translateX(45vw) translateY(10vh) scale(0.7);
  }

  62.3595505618% {
    transform: translateX(21vw) translateY(-26vh) scale(0.75);
  }

  62.9213483146% {
    transform: translateX(9vw) translateY(41vh) scale(0.73);
  }

  63.4831460674% {
    transform: translateX(45vw) translateY(-48vh) scale(0.62);
  }

  64.0449438202% {
    transform: translateX(19vw) translateY(2vh) scale(0.92);
  }

  64.606741573% {
    transform: translateX(47vw) translateY(-5vh) scale(0.28);
  }

  65.1685393258% {
    transform: translateX(25vw) translateY(30vh) scale(0.76);
  }

  65.7303370787% {
    transform: translateX(38vw) translateY(49vh) scale(0.51);
  }

  66.2921348315% {
    transform: translateX(20vw) translateY(-35vh) scale(0.77);
  }

  66.8539325843% {
    transform: translateX(-46vw) translateY(7vh) scale(0.4);
  }

  67.4157303371% {
    transform: translateX(-2vw) translateY(-23vh) scale(0.51);
  }

  67.9775280899% {
    transform: translateX(7vw) translateY(30vh) scale(0.91);
  }

  68.5393258427% {
    transform: translateX(-45vw) translateY(1vh) scale(0.88);
  }

  69.1011235955% {
    transform: translateX(18vw) translateY(-18vh) scale(0.64);
  }

  69.6629213483% {
    transform: translateX(-42vw) translateY(24vh) scale(0.56);
  }

  70.2247191011% {
    transform: translateX(-47vw) translateY(24vh) scale(1);
  }

  70.7865168539% {
    transform: translateX(21vw) translateY(-2vh) scale(0.61);
  }

  71.3483146067% {
    transform: translateX(-26vw) translateY(-41vh) scale(0.38);
  }

  71.9101123596% {
    transform: translateX(-29vw) translateY(24vh) scale(0.87);
  }

  72.4719101124% {
    transform: translateX(21vw) translateY(-6vh) scale(0.93);
  }

  73.0337078652% {
    transform: translateX(45vw) translateY(37vh) scale(0.49);
  }

  73.595505618% {
    transform: translateX(12vw) translateY(33vh) scale(0.26);
  }

  74.1573033708% {
    transform: translateX(21vw) translateY(31vh) scale(0.62);
  }

  74.7191011236% {
    transform: translateX(1vw) translateY(36vh) scale(0.57);
  }

  75.2808988764% {
    transform: translateX(-6vw) translateY(39vh) scale(0.84);
  }

  75.8426966292% {
    transform: translateX(-8vw) translateY(-46vh) scale(0.53);
  }

  76.404494382% {
    transform: translateX(-18vw) translateY(8vh) scale(0.97);
  }

  76.9662921348% {
    transform: translateX(30vw) translateY(12vh) scale(0.46);
  }

  77.5280898876% {
    transform: translateX(-10vw) translateY(46vh) scale(0.98);
  }

  78.0898876404% {
    transform: translateX(1vw) translateY(8vh) scale(0.31);
  }

  78.6516853933% {
    transform: translateX(-46vw) translateY(4vh) scale(0.26);
  }

  79.2134831461% {
    transform: translateX(-19vw) translateY(-10vh) scale(0.62);
  }

  79.7752808989% {
    transform: translateX(-39vw) translateY(43vh) scale(0.95);
  }

  80.3370786517% {
    transform: translateX(-9vw) translateY(-16vh) scale(1);
  }

  80.8988764045% {
    transform: translateX(-24vw) translateY(33vh) scale(0.78);
  }

  81.4606741573% {
    transform: translateX(26vw) translateY(-48vh) scale(0.91);
  }

  82.0224719101% {
    transform: translateX(7vw) translateY(15vh) scale(0.94);
  }

  82.5842696629% {
    transform: translateX(26vw) translateY(-7vh) scale(0.88);
  }

  83.1460674157% {
    transform: translateX(1vw) translateY(-2vh) scale(0.63);
  }

  83.7078651685% {
    transform: translateX(-13vw) translateY(37vh) scale(0.5);
  }

  84.2696629213% {
    transform: translateX(-25vw) translateY(-40vh) scale(0.92);
  }

  84.8314606742% {
    transform: translateX(-33vw) translateY(-46vh) scale(0.83);
  }

  85.393258427% {
    transform: translateX(39vw) translateY(-8vh) scale(0.52);
  }

  85.9550561798% {
    transform: translateX(-5vw) translateY(-45vh) scale(0.76);
  }

  86.5168539326% {
    transform: translateX(0vw) translateY(21vh) scale(0.89);
  }

  87.0786516854% {
    transform: translateX(-45vw) translateY(-3vh) scale(0.46);
  }

  87.6404494382% {
    transform: translateX(45vw) translateY(-28vh) scale(0.93);
  }

  88.202247191% {
    transform: translateX(13vw) translateY(49vh) scale(0.53);
  }

  88.7640449438% {
    transform: translateX(16vw) translateY(-31vh) scale(0.91);
  }

  89.3258426966% {
    transform: translateX(19vw) translateY(45vh) scale(0.5);
  }

  89.8876404494% {
    transform: translateX(-33vw) translateY(50vh) scale(0.74);
  }

  90.4494382022% {
    transform: translateX(-16vw) translateY(29vh) scale(0.79);
  }

  91.0112359551% {
    transform: translateX(-31vw) translateY(1vh) scale(0.92);
  }

  91.5730337079% {
    transform: translateX(-10vw) translateY(36vh) scale(0.65);
  }

  92.1348314607% {
    transform: translateX(-14vw) translateY(16vh) scale(0.39);
  }

  92.6966292135% {
    transform: translateX(25vw) translateY(-23vh) scale(0.45);
  }

  93.2584269663% {
    transform: translateX(20vw) translateY(15vh) scale(0.71);
  }

  93.8202247191% {
    transform: translateX(23vw) translateY(7vh) scale(0.27);
  }

  94.3820224719% {
    transform: translateX(48vw) translateY(-12vh) scale(0.82);
  }

  94.9438202247% {
    transform: translateX(-33vw) translateY(48vh) scale(0.64);
  }

  95.5056179775% {
    transform: translateX(33vw) translateY(-49vh) scale(0.26);
  }

  96.0674157303% {
    transform: translateX(46vw) translateY(-15vh) scale(0.53);
  }

  96.6292134831% {
    transform: translateX(3vw) translateY(-19vh) scale(0.45);
  }

  97.191011236% {
    transform: translateX(-6vw) translateY(22vh) scale(0.49);
  }

  97.7528089888% {
    transform: translateX(-27vw) translateY(-20vh) scale(0.3);
  }

  98.3146067416% {
    transform: translateX(-2vw) translateY(-20vh) scale(0.42);
  }

  98.8764044944% {
    transform: translateX(-40vw) translateY(32vh) scale(0.79);
  }

  99.4382022472% {
    transform: translateX(28vw) translateY(-20vh) scale(0.93);
  }

  100% {
    transform: translateX(-11vw) translateY(16vh) scale(0.97);
  }
}

.firefly:nth-child(19) {
  animation-name: move19;
}

.firefly:nth-child(19)::before {
  animation-duration: 17s;
}

.firefly:nth-child(19)::after {
  animation-duration: 17s, 6953ms;
  animation-delay: 0ms, 4635ms;
}

@keyframes move19 {
  0% {
    transform: translateX(39vw) translateY(6vh) scale(0.56);
  }

  0.4237288136% {
    transform: translateX(-41vw) translateY(-34vh) scale(1);
  }

  0.8474576271% {
    transform: translateX(-29vw) translateY(-32vh) scale(0.35);
  }

  1.2711864407% {
    transform: translateX(-48vw) translateY(7vh) scale(0.6);
  }

  1.6949152542% {
    transform: translateX(-46vw) translateY(6vh) scale(0.73);
  }

  2.1186440678% {
    transform: translateX(-46vw) translateY(-40vh) scale(0.51);
  }

  2.5423728814% {
    transform: translateX(48vw) translateY(31vh) scale(0.26);
  }

  2.9661016949% {
    transform: translateX(7vw) translateY(-25vh) scale(0.89);
  }

  3.3898305085% {
    transform: translateX(25vw) translateY(7vh) scale(0.72);
  }

  3.813559322% {
    transform: translateX(15vw) translateY(25vh) scale(0.91);
  }

  4.2372881356% {
    transform: translateX(-7vw) translateY(18vh) scale(0.89);
  }

  4.6610169492% {
    transform: translateX(4vw) translateY(-44vh) scale(0.74);
  }

  5.0847457627% {
    transform: translateX(26vw) translateY(-38vh) scale(0.36);
  }

  5.5084745763% {
    transform: translateX(0vw) translateY(-41vh) scale(0.41);
  }

  5.9322033898% {
    transform: translateX(-42vw) translateY(1vh) scale(0.58);
  }

  6.3559322034% {
    transform: translateX(-33vw) translateY(33vh) scale(0.95);
  }

  6.7796610169% {
    transform: translateX(-21vw) translateY(-40vh) scale(0.48);
  }

  7.2033898305% {
    transform: translateX(-37vw) translateY(25vh) scale(0.69);
  }

  7.6271186441% {
    transform: translateX(7vw) translateY(29vh) scale(0.65);
  }

  8.0508474576% {
    transform: translateX(-2vw) translateY(-38vh) scale(0.96);
  }

  8.4745762712% {
    transform: translateX(-15vw) translateY(-46vh) scale(0.89);
  }

  8.8983050847% {
    transform: translateX(-14vw) translateY(42vh) scale(0.4);
  }

  9.3220338983% {
    transform: translateX(-46vw) translateY(45vh) scale(0.56);
  }

  9.7457627119% {
    transform: translateX(-19vw) translateY(-49vh) scale(0.95);
  }

  10.1694915254% {
    transform: translateX(35vw) translateY(47vh) scale(0.36);
  }

  10.593220339% {
    transform: translateX(-37vw) translateY(50vh) scale(0.49);
  }

  11.0169491525% {
    transform: translateX(16vw) translateY(-12vh) scale(0.48);
  }

  11.4406779661% {
    transform: translateX(-2vw) translateY(-3vh) scale(0.31);
  }

  11.8644067797% {
    transform: translateX(43vw) translateY(-18vh) scale(0.66);
  }

  12.2881355932% {
    transform: translateX(21vw) translateY(-3vh) scale(0.99);
  }

  12.7118644068% {
    transform: translateX(17vw) translateY(-43vh) scale(0.59);
  }

  13.1355932203% {
    transform: translateX(-48vw) translateY(-41vh) scale(0.49);
  }

  13.5593220339% {
    transform: translateX(49vw) translateY(-41vh) scale(0.97);
  }

  13.9830508475% {
    transform: translateX(-2vw) translateY(26vh) scale(0.79);
  }

  14.406779661% {
    transform: translateX(35vw) translateY(-33vh) scale(0.98);
  }

  14.8305084746% {
    transform: translateX(47vw) translateY(34vh) scale(0.34);
  }

  15.2542372881% {
    transform: translateX(-43vw) translateY(-38vh) scale(0.3);
  }

  15.6779661017% {
    transform: translateX(-22vw) translateY(43vh) scale(0.28);
  }

  16.1016949153% {
    transform: translateX(41vw) translateY(14vh) scale(0.72);
  }

  16.5254237288% {
    transform: translateX(24vw) translateY(-35vh) scale(0.64);
  }

  16.9491525424% {
    transform: translateX(-45vw) translateY(27vh) scale(0.81);
  }

  17.3728813559% {
    transform: translateX(28vw) translateY(-6vh) scale(0.36);
  }

  17.7966101695% {
    transform: translateX(-46vw) translateY(12vh) scale(0.61);
  }

  18.2203389831% {
    transform: translateX(9vw) translateY(42vh) scale(0.75);
  }

  18.6440677966% {
    transform: translateX(-6vw) translateY(38vh) scale(0.28);
  }

  19.0677966102% {
    transform: translateX(22vw) translateY(-1vh) scale(0.83);
  }

  19.4915254237% {
    transform: translateX(30vw) translateY(-36vh) scale(0.57);
  }

  19.9152542373% {
    transform: translateX(26vw) translateY(7vh) scale(0.58);
  }

  20.3389830508% {
    transform: translateX(43vw) translateY(-32vh) scale(0.83);
  }

  20.7627118644% {
    transform: translateX(6vw) translateY(34vh) scale(0.86);
  }

  21.186440678% {
    transform: translateX(33vw) translateY(44vh) scale(0.52);
  }

  21.6101694915% {
    transform: translateX(17vw) translateY(50vh) scale(0.46);
  }

  22.0338983051% {
    transform: translateX(-17vw) translateY(-41vh) scale(0.66);
  }

  22.4576271186% {
    transform: translateX(24vw) translateY(-6vh) scale(0.95);
  }

  22.8813559322% {
    transform: translateX(13vw) translateY(8vh) scale(0.81);
  }

  23.3050847458% {
    transform: translateX(-48vw) translateY(1vh) scale(0.31);
  }

  23.7288135593% {
    transform: translateX(-48vw) translateY(-16vh) scale(0.94);
  }

  24.1525423729% {
    transform: translateX(-38vw) translateY(19vh) scale(0.58);
  }

  24.5762711864% {
    transform: translateX(-16vw) translateY(31vh) scale(0.46);
  }

  25% {
    transform: translateX(-1vw) translateY(-8vh) scale(0.45);
  }

  25.4237288136% {
    transform: translateX(32vw) translateY(17vh) scale(0.38);
  }

  25.8474576271% {
    transform: translateX(-1vw) translateY(-4vh) scale(0.49);
  }

  26.2711864407% {
    transform: translateX(25vw) translateY(-12vh) scale(0.46);
  }

  26.6949152542% {
    transform: translateX(-43vw) translateY(-41vh) scale(0.43);
  }

  27.1186440678% {
    transform: translateX(-38vw) translateY(30vh) scale(0.61);
  }

  27.5423728814% {
    transform: translateX(27vw) translateY(-44vh) scale(0.74);
  }

  27.9661016949% {
    transform: translateX(-22vw) translateY(-6vh) scale(0.37);
  }

  28.3898305085% {
    transform: translateX(30vw) translateY(-40vh) scale(0.44);
  }

  28.813559322% {
    transform: translateX(15vw) translateY(-13vh) scale(0.81);
  }

  29.2372881356% {
    transform: translateX(21vw) translateY(12vh) scale(0.72);
  }

  29.6610169492% {
    transform: translateX(-48vw) translateY(-9vh) scale(0.4);
  }

  30.0847457627% {
    transform: translateX(6vw) translateY(-31vh) scale(0.32);
  }

  30.5084745763% {
    transform: translateX(-9vw) translateY(25vh) scale(0.55);
  }

  30.9322033898% {
    transform: translateX(-8vw) translateY(24vh) scale(0.85);
  }

  31.3559322034% {
    transform: translateX(-20vw) translateY(-8vh) scale(0.87);
  }

  31.7796610169% {
    transform: translateX(-7vw) translateY(48vh) scale(0.48);
  }

  32.2033898305% {
    transform: translateX(-7vw) translateY(4vh) scale(0.56);
  }

  32.6271186441% {
    transform: translateX(28vw) translateY(-43vh) scale(0.31);
  }

  33.0508474576% {
    transform: translateX(5vw) translateY(-19vh) scale(0.38);
  }

  33.4745762712% {
    transform: translateX(-39vw) translateY(41vh) scale(0.6);
  }

  33.8983050847% {
    transform: translateX(-31vw) translateY(49vh) scale(0.84);
  }

  34.3220338983% {
    transform: translateX(-38vw) translateY(40vh) scale(0.83);
  }

  34.7457627119% {
    transform: translateX(40vw) translateY(20vh) scale(0.34);
  }

  35.1694915254% {
    transform: translateX(28vw) translateY(0vh) scale(0.39);
  }

  35.593220339% {
    transform: translateX(20vw) translateY(-34vh) scale(0.27);
  }

  36.0169491525% {
    transform: translateX(-18vw) translateY(28vh) scale(0.85);
  }

  36.4406779661% {
    transform: translateX(49vw) translateY(-3vh) scale(0.61);
  }

  36.8644067797% {
    transform: translateX(34vw) translateY(-36vh) scale(0.62);
  }

  37.2881355932% {
    transform: translateX(5vw) translateY(-34vh) scale(0.6);
  }

  37.7118644068% {
    transform: translateX(25vw) translateY(23vh) scale(0.39);
  }

  38.1355932203% {
    transform: translateX(-27vw) translateY(-6vh) scale(0.87);
  }

  38.5593220339% {
    transform: translateX(4vw) translateY(16vh) scale(0.97);
  }

  38.9830508475% {
    transform: translateX(39vw) translateY(-2vh) scale(0.89);
  }

  39.406779661% {
    transform: translateX(-9vw) translateY(1vh) scale(0.32);
  }

  39.8305084746% {
    transform: translateX(-24vw) translateY(7vh) scale(0.76);
  }

  40.2542372881% {
    transform: translateX(50vw) translateY(-41vh) scale(0.92);
  }

  40.6779661017% {
    transform: translateX(34vw) translateY(-35vh) scale(0.98);
  }

  41.1016949153% {
    transform: translateX(50vw) translateY(22vh) scale(0.68);
  }

  41.5254237288% {
    transform: translateX(8vw) translateY(-41vh) scale(0.5);
  }

  41.9491525424% {
    transform: translateX(-6vw) translateY(45vh) scale(0.42);
  }

  42.3728813559% {
    transform: translateX(28vw) translateY(41vh) scale(0.58);
  }

  42.7966101695% {
    transform: translateX(-14vw) translateY(15vh) scale(0.39);
  }

  43.2203389831% {
    transform: translateX(10vw) translateY(3vh) scale(0.69);
  }

  43.6440677966% {
    transform: translateX(-5vw) translateY(25vh) scale(0.7);
  }

  44.0677966102% {
    transform: translateX(47vw) translateY(41vh) scale(0.34);
  }

  44.4915254237% {
    transform: translateX(50vw) translateY(33vh) scale(0.54);
  }

  44.9152542373% {
    transform: translateX(-41vw) translateY(23vh) scale(0.94);
  }

  45.3389830508% {
    transform: translateX(-20vw) translateY(23vh) scale(0.64);
  }

  45.7627118644% {
    transform: translateX(-10vw) translateY(42vh) scale(0.83);
  }

  46.186440678% {
    transform: translateX(18vw) translateY(33vh) scale(0.75);
  }

  46.6101694915% {
    transform: translateX(39vw) translateY(-8vh) scale(0.26);
  }

  47.0338983051% {
    transform: translateX(28vw) translateY(44vh) scale(0.95);
  }

  47.4576271186% {
    transform: translateX(-9vw) translateY(37vh) scale(0.4);
  }

  47.8813559322% {
    transform: translateX(36vw) translateY(-17vh) scale(0.59);
  }

  48.3050847458% {
    transform: translateX(-27vw) translateY(-18vh) scale(0.45);
  }

  48.7288135593% {
    transform: translateX(-43vw) translateY(4vh) scale(0.48);
  }

  49.1525423729% {
    transform: translateX(4vw) translateY(0vh) scale(0.79);
  }

  49.5762711864% {
    transform: translateX(42vw) translateY(3vh) scale(0.97);
  }

  50% {
    transform: translateX(-31vw) translateY(-49vh) scale(0.71);
  }

  50.4237288136% {
    transform: translateX(-20vw) translateY(-21vh) scale(0.52);
  }

  50.8474576271% {
    transform: translateX(36vw) translateY(50vh) scale(1);
  }

  51.2711864407% {
    transform: translateX(-23vw) translateY(13vh) scale(0.66);
  }

  51.6949152542% {
    transform: translateX(-10vw) translateY(-2vh) scale(0.7);
  }

  52.1186440678% {
    transform: translateX(-24vw) translateY(10vh) scale(0.54);
  }

  52.5423728814% {
    transform: translateX(20vw) translateY(-19vh) scale(0.75);
  }

  52.9661016949% {
    transform: translateX(22vw) translateY(-47vh) scale(0.74);
  }

  53.3898305085% {
    transform: translateX(-20vw) translateY(45vh) scale(0.66);
  }

  53.813559322% {
    transform: translateX(33vw) translateY(22vh) scale(0.48);
  }

  54.2372881356% {
    transform: translateX(14vw) translateY(22vh) scale(0.51);
  }

  54.6610169492% {
    transform: translateX(14vw) translateY(10vh) scale(0.95);
  }

  55.0847457627% {
    transform: translateX(-25vw) translateY(42vh) scale(0.53);
  }

  55.5084745763% {
    transform: translateX(-21vw) translateY(-18vh) scale(0.49);
  }

  55.9322033898% {
    transform: translateX(-2vw) translateY(1vh) scale(0.29);
  }

  56.3559322034% {
    transform: translateX(34vw) translateY(11vh) scale(0.36);
  }

  56.7796610169% {
    transform: translateX(-14vw) translateY(20vh) scale(0.66);
  }

  57.2033898305% {
    transform: translateX(10vw) translateY(-41vh) scale(0.43);
  }

  57.6271186441% {
    transform: translateX(0vw) translateY(-28vh) scale(0.73);
  }

  58.0508474576% {
    transform: translateX(-3vw) translateY(-15vh) scale(0.4);
  }

  58.4745762712% {
    transform: translateX(-29vw) translateY(-16vh) scale(0.4);
  }

  58.8983050847% {
    transform: translateX(17vw) translateY(50vh) scale(0.61);
  }

  59.3220338983% {
    transform: translateX(-37vw) translateY(11vh) scale(0.92);
  }

  59.7457627119% {
    transform: translateX(-24vw) translateY(18vh) scale(0.56);
  }

  60.1694915254% {
    transform: translateX(43vw) translateY(-5vh) scale(0.64);
  }

  60.593220339% {
    transform: translateX(-16vw) translateY(22vh) scale(0.93);
  }

  61.0169491525% {
    transform: translateX(46vw) translateY(41vh) scale(0.42);
  }

  61.4406779661% {
    transform: translateX(-49vw) translateY(-22vh) scale(0.69);
  }

  61.8644067797% {
    transform: translateX(-46vw) translateY(-22vh) scale(0.91);
  }

  62.2881355932% {
    transform: translateX(40vw) translateY(-36vh) scale(1);
  }

  62.7118644068% {
    transform: translateX(5vw) translateY(-17vh) scale(0.98);
  }

  63.1355932203% {
    transform: translateX(22vw) translateY(21vh) scale(0.37);
  }

  63.5593220339% {
    transform: translateX(28vw) translateY(17vh) scale(0.49);
  }

  63.9830508475% {
    transform: translateX(6vw) translateY(24vh) scale(0.96);
  }

  64.406779661% {
    transform: translateX(-40vw) translateY(-33vh) scale(0.97);
  }

  64.8305084746% {
    transform: translateX(5vw) translateY(-25vh) scale(1);
  }

  65.2542372881% {
    transform: translateX(31vw) translateY(1vh) scale(0.51);
  }

  65.6779661017% {
    transform: translateX(-46vw) translateY(38vh) scale(0.71);
  }

  66.1016949153% {
    transform: translateX(-12vw) translateY(4vh) scale(0.52);
  }

  66.5254237288% {
    transform: translateX(-33vw) translateY(33vh) scale(0.78);
  }

  66.9491525424% {
    transform: translateX(-30vw) translateY(-5vh) scale(0.76);
  }

  67.3728813559% {
    transform: translateX(19vw) translateY(-23vh) scale(0.42);
  }

  67.7966101695% {
    transform: translateX(19vw) translateY(-24vh) scale(0.86);
  }

  68.2203389831% {
    transform: translateX(-26vw) translateY(-33vh) scale(0.95);
  }

  68.6440677966% {
    transform: translateX(14vw) translateY(29vh) scale(0.27);
  }

  69.0677966102% {
    transform: translateX(-44vw) translateY(45vh) scale(0.52);
  }

  69.4915254237% {
    transform: translateX(7vw) translateY(49vh) scale(0.65);
  }

  69.9152542373% {
    transform: translateX(-20vw) translateY(28vh) scale(0.55);
  }

  70.3389830508% {
    transform: translateX(23vw) translateY(-41vh) scale(0.91);
  }

  70.7627118644% {
    transform: translateX(-23vw) translateY(12vh) scale(0.77);
  }

  71.186440678% {
    transform: translateX(3vw) translateY(8vh) scale(0.46);
  }

  71.6101694915% {
    transform: translateX(7vw) translateY(-6vh) scale(0.91);
  }

  72.0338983051% {
    transform: translateX(14vw) translateY(-4vh) scale(0.51);
  }

  72.4576271186% {
    transform: translateX(36vw) translateY(48vh) scale(0.52);
  }

  72.8813559322% {
    transform: translateX(8vw) translateY(-44vh) scale(0.87);
  }

  73.3050847458% {
    transform: translateX(14vw) translateY(26vh) scale(0.32);
  }

  73.7288135593% {
    transform: translateX(-26vw) translateY(37vh) scale(0.27);
  }

  74.1525423729% {
    transform: translateX(25vw) translateY(-27vh) scale(0.64);
  }

  74.5762711864% {
    transform: translateX(-23vw) translateY(-32vh) scale(0.68);
  }

  75% {
    transform: translateX(11vw) translateY(-48vh) scale(0.75);
  }

  75.4237288136% {
    transform: translateX(-49vw) translateY(0vh) scale(0.82);
  }

  75.8474576271% {
    transform: translateX(-2vw) translateY(-20vh) scale(0.37);
  }

  76.2711864407% {
    transform: translateX(-27vw) translateY(32vh) scale(0.29);
  }

  76.6949152542% {
    transform: translateX(39vw) translateY(27vh) scale(0.78);
  }

  77.1186440678% {
    transform: translateX(15vw) translateY(38vh) scale(0.34);
  }

  77.5423728814% {
    transform: translateX(-17vw) translateY(-7vh) scale(0.6);
  }

  77.9661016949% {
    transform: translateX(-29vw) translateY(39vh) scale(0.39);
  }

  78.3898305085% {
    transform: translateX(2vw) translateY(-8vh) scale(0.61);
  }

  78.813559322% {
    transform: translateX(41vw) translateY(18vh) scale(0.98);
  }

  79.2372881356% {
    transform: translateX(40vw) translateY(-26vh) scale(0.52);
  }

  79.6610169492% {
    transform: translateX(-31vw) translateY(11vh) scale(0.33);
  }

  80.0847457627% {
    transform: translateX(-8vw) translateY(-1vh) scale(0.66);
  }

  80.5084745763% {
    transform: translateX(19vw) translateY(-8vh) scale(0.4);
  }

  80.9322033898% {
    transform: translateX(6vw) translateY(5vh) scale(0.97);
  }

  81.3559322034% {
    transform: translateX(24vw) translateY(4vh) scale(0.67);
  }

  81.7796610169% {
    transform: translateX(13vw) translateY(-1vh) scale(0.43);
  }

  82.2033898305% {
    transform: translateX(-32vw) translateY(-32vh) scale(0.41);
  }

  82.6271186441% {
    transform: translateX(12vw) translateY(24vh) scale(0.36);
  }

  83.0508474576% {
    transform: translateX(-38vw) translateY(-21vh) scale(0.37);
  }

  83.4745762712% {
    transform: translateX(25vw) translateY(24vh) scale(0.78);
  }

  83.8983050847% {
    transform: translateX(33vw) translateY(35vh) scale(0.68);
  }

  84.3220338983% {
    transform: translateX(-38vw) translateY(4vh) scale(0.79);
  }

  84.7457627119% {
    transform: translateX(30vw) translateY(-31vh) scale(0.94);
  }

  85.1694915254% {
    transform: translateX(8vw) translateY(21vh) scale(0.54);
  }

  85.593220339% {
    transform: translateX(-14vw) translateY(-31vh) scale(0.89);
  }

  86.0169491525% {
    transform: translateX(-4vw) translateY(38vh) scale(0.86);
  }

  86.4406779661% {
    transform: translateX(-49vw) translateY(24vh) scale(0.93);
  }

  86.8644067797% {
    transform: translateX(21vw) translateY(21vh) scale(0.46);
  }

  87.2881355932% {
    transform: translateX(-41vw) translateY(-37vh) scale(0.45);
  }

  87.7118644068% {
    transform: translateX(-30vw) translateY(-3vh) scale(0.62);
  }

  88.1355932203% {
    transform: translateX(19vw) translateY(5vh) scale(0.33);
  }

  88.5593220339% {
    transform: translateX(22vw) translateY(47vh) scale(0.69);
  }

  88.9830508475% {
    transform: translateX(-8vw) translateY(-47vh) scale(0.86);
  }

  89.406779661% {
    transform: translateX(6vw) translateY(-15vh) scale(0.57);
  }

  89.8305084746% {
    transform: translateX(36vw) translateY(-2vh) scale(0.59);
  }

  90.2542372881% {
    transform: translateX(28vw) translateY(18vh) scale(0.34);
  }

  90.6779661017% {
    transform: translateX(-45vw) translateY(-16vh) scale(0.45);
  }

  91.1016949153% {
    transform: translateX(-25vw) translateY(-21vh) scale(0.83);
  }

  91.5254237288% {
    transform: translateX(-42vw) translateY(-21vh) scale(0.54);
  }

  91.9491525424% {
    transform: translateX(-9vw) translateY(10vh) scale(0.42);
  }

  92.3728813559% {
    transform: translateX(3vw) translateY(50vh) scale(0.49);
  }

  92.7966101695% {
    transform: translateX(46vw) translateY(-26vh) scale(0.96);
  }

  93.2203389831% {
    transform: translateX(-19vw) translateY(3vh) scale(0.91);
  }

  93.6440677966% {
    transform: translateX(-38vw) translateY(-2vh) scale(0.8);
  }

  94.0677966102% {
    transform: translateX(28vw) translateY(43vh) scale(0.75);
  }

  94.4915254237% {
    transform: translateX(10vw) translateY(-21vh) scale(0.42);
  }

  94.9152542373% {
    transform: translateX(-40vw) translateY(22vh) scale(0.28);
  }

  95.3389830508% {
    transform: translateX(-29vw) translateY(-8vh) scale(0.64);
  }

  95.7627118644% {
    transform: translateX(-13vw) translateY(38vh) scale(0.27);
  }

  96.186440678% {
    transform: translateX(-41vw) translateY(32vh) scale(0.92);
  }

  96.6101694915% {
    transform: translateX(13vw) translateY(-36vh) scale(0.77);
  }

  97.0338983051% {
    transform: translateX(-48vw) translateY(-41vh) scale(0.9);
  }

  97.4576271186% {
    transform: translateX(-18vw) translateY(-46vh) scale(0.81);
  }

  97.8813559322% {
    transform: translateX(6vw) translateY(-40vh) scale(0.6);
  }

  98.3050847458% {
    transform: translateX(-37vw) translateY(-11vh) scale(0.47);
  }

  98.7288135593% {
    transform: translateX(8vw) translateY(42vh) scale(0.65);
  }

  99.1525423729% {
    transform: translateX(30vw) translateY(49vh) scale(0.43);
  }

  99.5762711864% {
    transform: translateX(47vw) translateY(-1vh) scale(0.89);
  }

  100% {
    transform: translateX(-29vw) translateY(9vh) scale(0.37);
  }
}

.firefly:nth-child(20) {
  animation-name: move20;
}

.firefly:nth-child(20)::before {
  animation-duration: 13s;
}

.firefly:nth-child(20)::after {
  animation-duration: 13s, 8953ms;
  animation-delay: 0ms, 2193ms;
}

@keyframes move20 {
  0% {
    transform: translateX(-8vw) translateY(-18vh) scale(0.5);
  }

  0.5649717514% {
    transform: translateX(39vw) translateY(50vh) scale(0.47);
  }

  1.1299435028% {
    transform: translateX(0vw) translateY(6vh) scale(0.98);
  }

  1.6949152542% {
    transform: translateX(-43vw) translateY(-3vh) scale(0.63);
  }

  2.2598870056% {
    transform: translateX(41vw) translateY(35vh) scale(0.58);
  }

  2.8248587571% {
    transform: translateX(30vw) translateY(21vh) scale(0.52);
  }

  3.3898305085% {
    transform: translateX(-2vw) translateY(29vh) scale(0.67);
  }

  3.9548022599% {
    transform: translateX(-40vw) translateY(-14vh) scale(0.59);
  }

  4.5197740113% {
    transform: translateX(49vw) translateY(40vh) scale(0.47);
  }

  5.0847457627% {
    transform: translateX(-12vw) translateY(-27vh) scale(0.55);
  }

  5.6497175141% {
    transform: translateX(-45vw) translateY(-12vh) scale(0.83);
  }

  6.2146892655% {
    transform: translateX(9vw) translateY(-9vh) scale(0.73);
  }

  6.7796610169% {
    transform: translateX(33vw) translateY(17vh) scale(0.77);
  }

  7.3446327684% {
    transform: translateX(44vw) translateY(-10vh) scale(0.33);
  }

  7.9096045198% {
    transform: translateX(-27vw) translateY(-36vh) scale(0.35);
  }

  8.4745762712% {
    transform: translateX(30vw) translateY(-38vh) scale(0.76);
  }

  9.0395480226% {
    transform: translateX(33vw) translateY(-39vh) scale(0.28);
  }

  9.604519774% {
    transform: translateX(37vw) translateY(41vh) scale(0.98);
  }

  10.1694915254% {
    transform: translateX(-38vw) translateY(35vh) scale(0.55);
  }

  10.7344632768% {
    transform: translateX(-18vw) translateY(-30vh) scale(0.8);
  }

  11.2994350282% {
    transform: translateX(7vw) translateY(-4vh) scale(0.49);
  }

  11.8644067797% {
    transform: translateX(-48vw) translateY(7vh) scale(0.74);
  }

  12.4293785311% {
    transform: translateX(4vw) translateY(-47vh) scale(0.97);
  }

  12.9943502825% {
    transform: translateX(-37vw) translateY(-24vh) scale(0.95);
  }

  13.5593220339% {
    transform: translateX(-22vw) translateY(33vh) scale(0.76);
  }

  14.1242937853% {
    transform: translateX(-45vw) translateY(-1vh) scale(0.82);
  }

  14.6892655367% {
    transform: translateX(8vw) translateY(0vh) scale(0.78);
  }

  15.2542372881% {
    transform: translateX(42vw) translateY(33vh) scale(0.39);
  }

  15.8192090395% {
    transform: translateX(40vw) translateY(-45vh) scale(0.49);
  }

  16.384180791% {
    transform: translateX(-41vw) translateY(7vh) scale(0.7);
  }

  16.9491525424% {
    transform: translateX(-47vw) translateY(-19vh) scale(0.75);
  }

  17.5141242938% {
    transform: translateX(-4vw) translateY(-13vh) scale(0.62);
  }

  18.0790960452% {
    transform: translateX(-21vw) translateY(-39vh) scale(0.98);
  }

  18.6440677966% {
    transform: translateX(49vw) translateY(-33vh) scale(0.29);
  }

  19.209039548% {
    transform: translateX(5vw) translateY(19vh) scale(0.43);
  }

  19.7740112994% {
    transform: translateX(41vw) translateY(-49vh) scale(0.52);
  }

  20.3389830508% {
    transform: translateX(-43vw) translateY(-2vh) scale(0.71);
  }

  20.9039548023% {
    transform: translateX(11vw) translateY(20vh) scale(0.53);
  }

  21.4689265537% {
    transform: translateX(26vw) translateY(1vh) scale(0.37);
  }

  22.0338983051% {
    transform: translateX(17vw) translateY(15vh) scale(0.75);
  }

  22.5988700565% {
    transform: translateX(-18vw) translateY(-16vh) scale(0.89);
  }

  23.1638418079% {
    transform: translateX(31vw) translateY(10vh) scale(0.31);
  }

  23.7288135593% {
    transform: translateX(35vw) translateY(-14vh) scale(0.73);
  }

  24.2937853107% {
    transform: translateX(12vw) translateY(26vh) scale(0.69);
  }

  24.8587570621% {
    transform: translateX(-40vw) translateY(48vh) scale(0.47);
  }

  25.4237288136% {
    transform: translateX(15vw) translateY(25vh) scale(0.56);
  }

  25.988700565% {
    transform: translateX(-28vw) translateY(-3vh) scale(0.99);
  }

  26.5536723164% {
    transform: translateX(22vw) translateY(-24vh) scale(0.59);
  }

  27.1186440678% {
    transform: translateX(-39vw) translateY(45vh) scale(0.78);
  }

  27.6836158192% {
    transform: translateX(32vw) translateY(0vh) scale(0.82);
  }

  28.2485875706% {
    transform: translateX(-10vw) translateY(-43vh) scale(0.92);
  }

  28.813559322% {
    transform: translateX(35vw) translateY(-21vh) scale(0.95);
  }

  29.3785310734% {
    transform: translateX(5vw) translateY(-24vh) scale(0.75);
  }

  29.9435028249% {
    transform: translateX(24vw) translateY(-19vh) scale(0.5);
  }

  30.5084745763% {
    transform: translateX(-9vw) translateY(-18vh) scale(0.49);
  }

  31.0734463277% {
    transform: translateX(39vw) translateY(47vh) scale(0.4);
  }

  31.6384180791% {
    transform: translateX(27vw) translateY(-48vh) scale(0.82);
  }

  32.2033898305% {
    transform: translateX(-39vw) translateY(-9vh) scale(0.85);
  }

  32.7683615819% {
    transform: translateX(-2vw) translateY(-15vh) scale(0.5);
  }

  33.3333333333% {
    transform: translateX(23vw) translateY(44vh) scale(0.65);
  }

  33.8983050847% {
    transform: translateX(26vw) translateY(3vh) scale(0.95);
  }

  34.4632768362% {
    transform: translateX(24vw) translateY(21vh) scale(0.34);
  }

  35.0282485876% {
    transform: translateX(-16vw) translateY(-46vh) scale(0.94);
  }

  35.593220339% {
    transform: translateX(2vw) translateY(-18vh) scale(0.33);
  }

  36.1581920904% {
    transform: translateX(17vw) translateY(-29vh) scale(0.28);
  }

  36.7231638418% {
    transform: translateX(-15vw) translateY(-37vh) scale(0.41);
  }

  37.2881355932% {
    transform: translateX(-21vw) translateY(1vh) scale(0.76);
  }

  37.8531073446% {
    transform: translateX(-48vw) translateY(41vh) scale(0.96);
  }

  38.418079096% {
    transform: translateX(36vw) translateY(36vh) scale(0.51);
  }

  38.9830508475% {
    transform: translateX(-22vw) translateY(-49vh) scale(0.3);
  }

  39.5480225989% {
    transform: translateX(40vw) translateY(-17vh) scale(0.58);
  }

  40.1129943503% {
    transform: translateX(17vw) translateY(-27vh) scale(0.68);
  }

  40.6779661017% {
    transform: translateX(22vw) translateY(21vh) scale(0.92);
  }

  41.2429378531% {
    transform: translateX(5vw) translateY(-3vh) scale(0.34);
  }

  41.8079096045% {
    transform: translateX(-11vw) translateY(15vh) scale(0.99);
  }

  42.3728813559% {
    transform: translateX(-44vw) translateY(2vh) scale(0.56);
  }

  42.9378531073% {
    transform: translateX(19vw) translateY(43vh) scale(0.76);
  }

  43.5028248588% {
    transform: translateX(39vw) translateY(-44vh) scale(0.31);
  }

  44.0677966102% {
    transform: translateX(37vw) translateY(-26vh) scale(0.93);
  }

  44.6327683616% {
    transform: translateX(31vw) translateY(35vh) scale(0.6);
  }

  45.197740113% {
    transform: translateX(-42vw) translateY(-1vh) scale(0.89);
  }

  45.7627118644% {
    transform: translateX(48vw) translateY(-44vh) scale(0.66);
  }

  46.3276836158% {
    transform: translateX(36vw) translateY(11vh) scale(0.92);
  }

  46.8926553672% {
    transform: translateX(50vw) translateY(36vh) scale(0.78);
  }

  47.4576271186% {
    transform: translateX(13vw) translateY(19vh) scale(0.94);
  }

  48.0225988701% {
    transform: translateX(30vw) translateY(25vh) scale(0.65);
  }

  48.5875706215% {
    transform: translateX(-15vw) translateY(27vh) scale(0.8);
  }

  49.1525423729% {
    transform: translateX(29vw) translateY(-15vh) scale(0.85);
  }

  49.7175141243% {
    transform: translateX(48vw) translateY(-42vh) scale(0.91);
  }

  50.2824858757% {
    transform: translateX(44vw) translateY(5vh) scale(0.74);
  }

  50.8474576271% {
    transform: translateX(-44vw) translateY(-2vh) scale(0.84);
  }

  51.4124293785% {
    transform: translateX(-4vw) translateY(-47vh) scale(0.32);
  }

  51.9774011299% {
    transform: translateX(-33vw) translateY(37vh) scale(0.72);
  }

  52.5423728814% {
    transform: translateX(2vw) translateY(-4vh) scale(0.41);
  }

  53.1073446328% {
    transform: translateX(-22vw) translateY(13vh) scale(0.62);
  }

  53.6723163842% {
    transform: translateX(21vw) translateY(-19vh) scale(0.5);
  }

  54.2372881356% {
    transform: translateX(17vw) translateY(-20vh) scale(0.41);
  }

  54.802259887% {
    transform: translateX(4vw) translateY(16vh) scale(0.8);
  }

  55.3672316384% {
    transform: translateX(22vw) translateY(-40vh) scale(0.41);
  }

  55.9322033898% {
    transform: translateX(32vw) translateY(-41vh) scale(0.53);
  }

  56.4971751412% {
    transform: translateX(-41vw) translateY(14vh) scale(0.33);
  }

  57.0621468927% {
    transform: translateX(21vw) translateY(-16vh) scale(0.52);
  }

  57.6271186441% {
    transform: translateX(24vw) translateY(-10vh) scale(0.96);
  }

  58.1920903955% {
    transform: translateX(0vw) translateY(50vh) scale(0.33);
  }

  58.7570621469% {
    transform: translateX(36vw) translateY(32vh) scale(0.64);
  }

  59.3220338983% {
    transform: translateX(-39vw) translateY(12vh) scale(0.33);
  }

  59.8870056497% {
    transform: translateX(18vw) translateY(45vh) scale(0.83);
  }

  60.4519774011% {
    transform: translateX(27vw) translateY(-8vh) scale(0.96);
  }

  61.0169491525% {
    transform: translateX(4vw) translateY(39vh) scale(0.65);
  }

  61.581920904% {
    transform: translateX(5vw) translateY(-28vh) scale(0.95);
  }

  62.1468926554% {
    transform: translateX(-11vw) translateY(34vh) scale(0.86);
  }

  62.7118644068% {
    transform: translateX(-14vw) translateY(13vh) scale(0.99);
  }

  63.2768361582% {
    transform: translateX(-3vw) translateY(-17vh) scale(0.26);
  }

  63.8418079096% {
    transform: translateX(-30vw) translateY(32vh) scale(0.27);
  }

  64.406779661% {
    transform: translateX(-34vw) translateY(-41vh) scale(0.97);
  }

  64.9717514124% {
    transform: translateX(34vw) translateY(13vh) scale(0.48);
  }

  65.5367231638% {
    transform: translateX(-12vw) translateY(6vh) scale(0.27);
  }

  66.1016949153% {
    transform: translateX(38vw) translateY(3vh) scale(0.44);
  }

  66.6666666667% {
    transform: translateX(44vw) translateY(41vh) scale(0.4);
  }

  67.2316384181% {
    transform: translateX(-5vw) translateY(29vh) scale(0.49);
  }

  67.7966101695% {
    transform: translateX(-47vw) translateY(1vh) scale(0.67);
  }

  68.3615819209% {
    transform: translateX(-37vw) translateY(-14vh) scale(0.74);
  }

  68.9265536723% {
    transform: translateX(-31vw) translateY(-1vh) scale(0.99);
  }

  69.4915254237% {
    transform: translateX(-12vw) translateY(50vh) scale(0.89);
  }

  70.0564971751% {
    transform: translateX(-22vw) translateY(26vh) scale(0.79);
  }

  70.6214689266% {
    transform: translateX(-22vw) translateY(-6vh) scale(0.56);
  }

  71.186440678% {
    transform: translateX(40vw) translateY(27vh) scale(0.4);
  }

  71.7514124294% {
    transform: translateX(-16vw) translateY(-8vh) scale(0.67);
  }

  72.3163841808% {
    transform: translateX(13vw) translateY(-21vh) scale(0.74);
  }

  72.8813559322% {
    transform: translateX(-41vw) translateY(37vh) scale(0.87);
  }

  73.4463276836% {
    transform: translateX(10vw) translateY(33vh) scale(0.94);
  }

  74.011299435% {
    transform: translateX(-24vw) translateY(-14vh) scale(0.57);
  }

  74.5762711864% {
    transform: translateX(-15vw) translateY(-38vh) scale(0.53);
  }

  75.1412429379% {
    transform: translateX(-43vw) translateY(25vh) scale(0.41);
  }

  75.7062146893% {
    transform: translateX(-41vw) translateY(10vh) scale(0.42);
  }

  76.2711864407% {
    transform: translateX(-36vw) translateY(9vh) scale(0.53);
  }

  76.8361581921% {
    transform: translateX(14vw) translateY(-35vh) scale(0.56);
  }

  77.4011299435% {
    transform: translateX(-13vw) translateY(14vh) scale(0.28);
  }

  77.9661016949% {
    transform: translateX(4vw) translateY(5vh) scale(0.45);
  }

  78.5310734463% {
    transform: translateX(23vw) translateY(32vh) scale(0.32);
  }

  79.0960451977% {
    transform: translateX(43vw) translateY(35vh) scale(0.31);
  }

  79.6610169492% {
    transform: translateX(-20vw) translateY(-20vh) scale(0.95);
  }

  80.2259887006% {
    transform: translateX(-46vw) translateY(24vh) scale(0.32);
  }

  80.790960452% {
    transform: translateX(-45vw) translateY(23vh) scale(0.99);
  }

  81.3559322034% {
    transform: translateX(-5vw) translateY(-45vh) scale(0.78);
  }

  81.9209039548% {
    transform: translateX(22vw) translateY(-4vh) scale(0.64);
  }

  82.4858757062% {
    transform: translateX(45vw) translateY(33vh) scale(1);
  }

  83.0508474576% {
    transform: translateX(-46vw) translateY(-23vh) scale(0.8);
  }

  83.615819209% {
    transform: translateX(20vw) translateY(-37vh) scale(0.65);
  }

  84.1807909605% {
    transform: translateX(38vw) translateY(39vh) scale(1);
  }

  84.7457627119% {
    transform: translateX(39vw) translateY(1vh) scale(0.42);
  }

  85.3107344633% {
    transform: translateX(-30vw) translateY(12vh) scale(0.57);
  }

  85.8757062147% {
    transform: translateX(-29vw) translateY(-28vh) scale(0.9);
  }

  86.4406779661% {
    transform: translateX(21vw) translateY(-15vh) scale(0.7);
  }

  87.0056497175% {
    transform: translateX(-43vw) translateY(10vh) scale(0.97);
  }

  87.5706214689% {
    transform: translateX(24vw) translateY(14vh) scale(0.41);
  }

  88.1355932203% {
    transform: translateX(1vw) translateY(-36vh) scale(0.42);
  }

  88.7005649718% {
    transform: translateX(48vw) translateY(-40vh) scale(0.55);
  }

  89.2655367232% {
    transform: translateX(44vw) translateY(-1vh) scale(0.46);
  }

  89.8305084746% {
    transform: translateX(16vw) translateY(0vh) scale(0.68);
  }

  90.395480226% {
    transform: translateX(16vw) translateY(49vh) scale(0.9);
  }

  90.9604519774% {
    transform: translateX(9vw) translateY(-7vh) scale(0.85);
  }

  91.5254237288% {
    transform: translateX(-36vw) translateY(0vh) scale(0.35);
  }

  92.0903954802% {
    transform: translateX(12vw) translateY(-8vh) scale(0.52);
  }

  92.6553672316% {
    transform: translateX(-17vw) translateY(22vh) scale(0.87);
  }

  93.2203389831% {
    transform: translateX(25vw) translateY(-13vh) scale(0.8);
  }

  93.7853107345% {
    transform: translateX(-7vw) translateY(16vh) scale(0.54);
  }

  94.3502824859% {
    transform: translateX(26vw) translateY(-41vh) scale(0.53);
  }

  94.9152542373% {
    transform: translateX(38vw) translateY(-11vh) scale(0.91);
  }

  95.4802259887% {
    transform: translateX(37vw) translateY(-12vh) scale(0.55);
  }

  96.0451977401% {
    transform: translateX(50vw) translateY(34vh) scale(0.78);
  }

  96.6101694915% {
    transform: translateX(39vw) translateY(16vh) scale(0.96);
  }

  97.1751412429% {
    transform: translateX(-37vw) translateY(50vh) scale(0.84);
  }

  97.7401129944% {
    transform: translateX(44vw) translateY(-48vh) scale(0.63);
  }

  98.3050847458% {
    transform: translateX(-1vw) translateY(-11vh) scale(0.63);
  }

  98.8700564972% {
    transform: translateX(-20vw) translateY(-41vh) scale(0.35);
  }

  99.4350282486% {
    transform: translateX(0vw) translateY(32vh) scale(0.41);
  }

  100% {
    transform: translateX(-21vw) translateY(-26vh) scale(0.57);
  }
}

.firefly:nth-child(21) {
  animation-name: move21;
}

.firefly:nth-child(21)::before {
  animation-duration: 15s;
}

.firefly:nth-child(21)::after {
  animation-duration: 15s, 5509ms;
  animation-delay: 0ms, 2206ms;
}

@keyframes move21 {
  0% {
    transform: translateX(49vw) translateY(44vh) scale(0.73);
  }

  0.4926108374% {
    transform: translateX(-18vw) translateY(-49vh) scale(0.31);
  }

  0.9852216749% {
    transform: translateX(21vw) translateY(13vh) scale(0.53);
  }

  1.4778325123% {
    transform: translateX(40vw) translateY(-26vh) scale(0.79);
  }

  1.9704433498% {
    transform: translateX(23vw) translateY(-29vh) scale(0.59);
  }

  2.4630541872% {
    transform: translateX(-48vw) translateY(-7vh) scale(0.8);
  }

  2.9556650246% {
    transform: translateX(27vw) translateY(-45vh) scale(0.39);
  }

  3.4482758621% {
    transform: translateX(-24vw) translateY(-10vh) scale(0.72);
  }

  3.9408866995% {
    transform: translateX(-45vw) translateY(24vh) scale(0.62);
  }

  4.4334975369% {
    transform: translateX(6vw) translateY(-21vh) scale(0.35);
  }

  4.9261083744% {
    transform: translateX(-39vw) translateY(6vh) scale(0.64);
  }

  5.4187192118% {
    transform: translateX(28vw) translateY(45vh) scale(0.61);
  }

  5.9113300493% {
    transform: translateX(-15vw) translateY(29vh) scale(0.82);
  }

  6.4039408867% {
    transform: translateX(24vw) translateY(8vh) scale(0.81);
  }

  6.8965517241% {
    transform: translateX(-41vw) translateY(-46vh) scale(0.71);
  }

  7.3891625616% {
    transform: translateX(26vw) translateY(17vh) scale(0.55);
  }

  7.881773399% {
    transform: translateX(37vw) translateY(-4vh) scale(0.43);
  }

  8.3743842365% {
    transform: translateX(-27vw) translateY(-14vh) scale(0.29);
  }

  8.8669950739% {
    transform: translateX(-24vw) translateY(-40vh) scale(0.8);
  }

  9.3596059113% {
    transform: translateX(-40vw) translateY(0vh) scale(0.85);
  }

  9.8522167488% {
    transform: translateX(19vw) translateY(-19vh) scale(1);
  }

  10.3448275862% {
    transform: translateX(-14vw) translateY(-45vh) scale(0.91);
  }

  10.8374384236% {
    transform: translateX(17vw) translateY(-35vh) scale(0.95);
  }

  11.3300492611% {
    transform: translateX(-27vw) translateY(5vh) scale(0.95);
  }

  11.8226600985% {
    transform: translateX(-31vw) translateY(-26vh) scale(0.98);
  }

  12.315270936% {
    transform: translateX(-48vw) translateY(-8vh) scale(0.41);
  }

  12.8078817734% {
    transform: translateX(-25vw) translateY(-48vh) scale(0.4);
  }

  13.3004926108% {
    transform: translateX(39vw) translateY(-27vh) scale(0.83);
  }

  13.7931034483% {
    transform: translateX(-7vw) translateY(22vh) scale(0.64);
  }

  14.2857142857% {
    transform: translateX(10vw) translateY(13vh) scale(0.38);
  }

  14.7783251232% {
    transform: translateX(-2vw) translateY(-41vh) scale(0.87);
  }

  15.2709359606% {
    transform: translateX(-47vw) translateY(7vh) scale(0.94);
  }

  15.763546798% {
    transform: translateX(11vw) translateY(-27vh) scale(0.9);
  }

  16.2561576355% {
    transform: translateX(-11vw) translateY(-35vh) scale(0.76);
  }

  16.7487684729% {
    transform: translateX(-16vw) translateY(50vh) scale(0.78);
  }

  17.2413793103% {
    transform: translateX(6vw) translateY(2vh) scale(0.86);
  }

  17.7339901478% {
    transform: translateX(25vw) translateY(2vh) scale(0.83);
  }

  18.2266009852% {
    transform: translateX(21vw) translateY(-30vh) scale(0.54);
  }

  18.7192118227% {
    transform: translateX(-41vw) translateY(-32vh) scale(0.78);
  }

  19.2118226601% {
    transform: translateX(5vw) translateY(-46vh) scale(0.7);
  }

  19.7044334975% {
    transform: translateX(-12vw) translateY(-28vh) scale(0.32);
  }

  20.197044335% {
    transform: translateX(-26vw) translateY(-10vh) scale(0.47);
  }

  20.6896551724% {
    transform: translateX(-16vw) translateY(32vh) scale(0.68);
  }

  21.1822660099% {
    transform: translateX(-26vw) translateY(-28vh) scale(0.98);
  }

  21.6748768473% {
    transform: translateX(7vw) translateY(8vh) scale(0.67);
  }

  22.1674876847% {
    transform: translateX(48vw) translateY(-19vh) scale(0.51);
  }

  22.6600985222% {
    transform: translateX(42vw) translateY(-29vh) scale(0.57);
  }

  23.1527093596% {
    transform: translateX(5vw) translateY(-15vh) scale(0.86);
  }

  23.645320197% {
    transform: translateX(-47vw) translateY(-43vh) scale(0.28);
  }

  24.1379310345% {
    transform: translateX(22vw) translateY(-18vh) scale(0.29);
  }

  24.6305418719% {
    transform: translateX(14vw) translateY(31vh) scale(0.96);
  }

  25.1231527094% {
    transform: translateX(-9vw) translateY(21vh) scale(0.48);
  }

  25.6157635468% {
    transform: translateX(-42vw) translateY(-49vh) scale(0.61);
  }

  26.1083743842% {
    transform: translateX(-34vw) translateY(-23vh) scale(0.78);
  }

  26.6009852217% {
    transform: translateX(-6vw) translateY(-25vh) scale(0.72);
  }

  27.0935960591% {
    transform: translateX(34vw) translateY(-49vh) scale(0.59);
  }

  27.5862068966% {
    transform: translateX(22vw) translateY(14vh) scale(0.92);
  }

  28.078817734% {
    transform: translateX(-23vw) translateY(-45vh) scale(0.66);
  }

  28.5714285714% {
    transform: translateX(1vw) translateY(-42vh) scale(0.51);
  }

  29.0640394089% {
    transform: translateX(-17vw) translateY(-47vh) scale(0.46);
  }

  29.5566502463% {
    transform: translateX(-5vw) translateY(-35vh) scale(0.44);
  }

  30.0492610837% {
    transform: translateX(-8vw) translateY(21vh) scale(0.33);
  }

  30.5418719212% {
    transform: translateX(38vw) translateY(-33vh) scale(0.39);
  }

  31.0344827586% {
    transform: translateX(-45vw) translateY(6vh) scale(0.4);
  }

  31.5270935961% {
    transform: translateX(-38vw) translateY(-41vh) scale(0.27);
  }

  32.0197044335% {
    transform: translateX(-21vw) translateY(21vh) scale(0.39);
  }

  32.5123152709% {
    transform: translateX(-8vw) translateY(36vh) scale(0.63);
  }

  33.0049261084% {
    transform: translateX(20vw) translateY(4vh) scale(0.35);
  }

  33.4975369458% {
    transform: translateX(39vw) translateY(-26vh) scale(0.49);
  }

  33.9901477833% {
    transform: translateX(2vw) translateY(-39vh) scale(0.7);
  }

  34.4827586207% {
    transform: translateX(23vw) translateY(-13vh) scale(0.68);
  }

  34.9753694581% {
    transform: translateX(-22vw) translateY(32vh) scale(0.82);
  }

  35.4679802956% {
    transform: translateX(30vw) translateY(16vh) scale(0.58);
  }

  35.960591133% {
    transform: translateX(21vw) translateY(47vh) scale(0.46);
  }

  36.4532019704% {
    transform: translateX(17vw) translateY(-16vh) scale(0.98);
  }

  36.9458128079% {
    transform: translateX(39vw) translateY(-41vh) scale(0.28);
  }

  37.4384236453% {
    transform: translateX(-41vw) translateY(-18vh) scale(0.79);
  }

  37.9310344828% {
    transform: translateX(-11vw) translateY(26vh) scale(0.62);
  }

  38.4236453202% {
    transform: translateX(-46vw) translateY(13vh) scale(0.74);
  }

  38.9162561576% {
    transform: translateX(-18vw) translateY(6vh) scale(0.84);
  }

  39.4088669951% {
    transform: translateX(13vw) translateY(41vh) scale(0.63);
  }

  39.9014778325% {
    transform: translateX(-14vw) translateY(10vh) scale(1);
  }

  40.39408867% {
    transform: translateX(-17vw) translateY(-15vh) scale(0.63);
  }

  40.8866995074% {
    transform: translateX(34vw) translateY(-2vh) scale(0.71);
  }

  41.3793103448% {
    transform: translateX(-10vw) translateY(22vh) scale(0.71);
  }

  41.8719211823% {
    transform: translateX(26vw) translateY(-42vh) scale(0.64);
  }

  42.3645320197% {
    transform: translateX(-31vw) translateY(34vh) scale(0.43);
  }

  42.8571428571% {
    transform: translateX(-6vw) translateY(29vh) scale(0.65);
  }

  43.3497536946% {
    transform: translateX(-26vw) translateY(-33vh) scale(0.98);
  }

  43.842364532% {
    transform: translateX(0vw) translateY(13vh) scale(0.27);
  }

  44.3349753695% {
    transform: translateX(-19vw) translateY(-19vh) scale(0.97);
  }

  44.8275862069% {
    transform: translateX(-11vw) translateY(8vh) scale(0.85);
  }

  45.3201970443% {
    transform: translateX(-28vw) translateY(23vh) scale(0.74);
  }

  45.8128078818% {
    transform: translateX(-32vw) translateY(43vh) scale(0.46);
  }

  46.3054187192% {
    transform: translateX(44vw) translateY(16vh) scale(0.65);
  }

  46.7980295567% {
    transform: translateX(-6vw) translateY(50vh) scale(0.79);
  }

  47.2906403941% {
    transform: translateX(-3vw) translateY(36vh) scale(0.36);
  }

  47.7832512315% {
    transform: translateX(-39vw) translateY(10vh) scale(0.39);
  }

  48.275862069% {
    transform: translateX(37vw) translateY(42vh) scale(0.63);
  }

  48.7684729064% {
    transform: translateX(-4vw) translateY(29vh) scale(0.98);
  }

  49.2610837438% {
    transform: translateX(-31vw) translateY(11vh) scale(0.54);
  }

  49.7536945813% {
    transform: translateX(-39vw) translateY(-28vh) scale(0.64);
  }

  50.2463054187% {
    transform: translateX(21vw) translateY(5vh) scale(0.79);
  }

  50.7389162562% {
    transform: translateX(-29vw) translateY(34vh) scale(0.98);
  }

  51.2315270936% {
    transform: translateX(-11vw) translateY(-40vh) scale(0.83);
  }

  51.724137931% {
    transform: translateX(5vw) translateY(-25vh) scale(0.86);
  }

  52.2167487685% {
    transform: translateX(-35vw) translateY(-3vh) scale(0.68);
  }

  52.7093596059% {
    transform: translateX(33vw) translateY(-11vh) scale(0.56);
  }

  53.2019704433% {
    transform: translateX(-43vw) translateY(18vh) scale(0.68);
  }

  53.6945812808% {
    transform: translateX(-22vw) translateY(-11vh) scale(0.3);
  }

  54.1871921182% {
    transform: translateX(-21vw) translateY(-32vh) scale(0.33);
  }

  54.6798029557% {
    transform: translateX(50vw) translateY(-44vh) scale(0.32);
  }

  55.1724137931% {
    transform: translateX(31vw) translateY(-32vh) scale(0.38);
  }

  55.6650246305% {
    transform: translateX(2vw) translateY(-3vh) scale(0.59);
  }

  56.157635468% {
    transform: translateX(-16vw) translateY(-24vh) scale(0.49);
  }

  56.6502463054% {
    transform: translateX(-45vw) translateY(-41vh) scale(0.93);
  }

  57.1428571429% {
    transform: translateX(-46vw) translateY(-28vh) scale(0.43);
  }

  57.6354679803% {
    transform: translateX(7vw) translateY(31vh) scale(0.56);
  }

  58.1280788177% {
    transform: translateX(32vw) translateY(15vh) scale(0.94);
  }

  58.6206896552% {
    transform: translateX(-10vw) translateY(24vh) scale(0.5);
  }

  59.1133004926% {
    transform: translateX(-13vw) translateY(19vh) scale(0.4);
  }

  59.60591133% {
    transform: translateX(-40vw) translateY(38vh) scale(0.31);
  }

  60.0985221675% {
    transform: translateX(8vw) translateY(-23vh) scale(0.92);
  }

  60.5911330049% {
    transform: translateX(19vw) translateY(40vh) scale(0.32);
  }

  61.0837438424% {
    transform: translateX(-32vw) translateY(-36vh) scale(0.31);
  }

  61.5763546798% {
    transform: translateX(49vw) translateY(9vh) scale(0.93);
  }

  62.0689655172% {
    transform: translateX(21vw) translateY(11vh) scale(0.82);
  }

  62.5615763547% {
    transform: translateX(-9vw) translateY(-10vh) scale(0.58);
  }

  63.0541871921% {
    transform: translateX(-37vw) translateY(10vh) scale(0.37);
  }

  63.5467980296% {
    transform: translateX(-11vw) translateY(-37vh) scale(0.33);
  }

  64.039408867% {
    transform: translateX(-12vw) translateY(-15vh) scale(0.43);
  }

  64.5320197044% {
    transform: translateX(-8vw) translateY(13vh) scale(0.86);
  }

  65.0246305419% {
    transform: translateX(-43vw) translateY(29vh) scale(0.71);
  }

  65.5172413793% {
    transform: translateX(8vw) translateY(5vh) scale(0.39);
  }

  66.0098522167% {
    transform: translateX(-5vw) translateY(6vh) scale(0.95);
  }

  66.5024630542% {
    transform: translateX(8vw) translateY(-47vh) scale(0.73);
  }

  66.9950738916% {
    transform: translateX(33vw) translateY(6vh) scale(0.84);
  }

  67.4876847291% {
    transform: translateX(5vw) translateY(13vh) scale(0.84);
  }

  67.9802955665% {
    transform: translateX(-12vw) translateY(15vh) scale(1);
  }

  68.4729064039% {
    transform: translateX(50vw) translateY(-35vh) scale(0.4);
  }

  68.9655172414% {
    transform: translateX(-5vw) translateY(-42vh) scale(0.33);
  }

  69.4581280788% {
    transform: translateX(-26vw) translateY(-15vh) scale(1);
  }

  69.9507389163% {
    transform: translateX(-40vw) translateY(-46vh) scale(0.62);
  }

  70.4433497537% {
    transform: translateX(19vw) translateY(-46vh) scale(0.46);
  }

  70.9359605911% {
    transform: translateX(-27vw) translateY(17vh) scale(0.53);
  }

  71.4285714286% {
    transform: translateX(-8vw) translateY(49vh) scale(0.69);
  }

  71.921182266% {
    transform: translateX(-11vw) translateY(29vh) scale(0.43);
  }

  72.4137931034% {
    transform: translateX(40vw) translateY(-15vh) scale(0.45);
  }

  72.9064039409% {
    transform: translateX(-25vw) translateY(21vh) scale(0.67);
  }

  73.3990147783% {
    transform: translateX(-40vw) translateY(-38vh) scale(0.88);
  }

  73.8916256158% {
    transform: translateX(-6vw) translateY(-38vh) scale(0.39);
  }

  74.3842364532% {
    transform: translateX(-14vw) translateY(-3vh) scale(0.85);
  }

  74.8768472906% {
    transform: translateX(-29vw) translateY(-1vh) scale(0.57);
  }

  75.3694581281% {
    transform: translateX(48vw) translateY(2vh) scale(0.74);
  }

  75.8620689655% {
    transform: translateX(-28vw) translateY(-29vh) scale(0.53);
  }

  76.354679803% {
    transform: translateX(-20vw) translateY(-20vh) scale(0.74);
  }

  76.8472906404% {
    transform: translateX(10vw) translateY(-10vh) scale(0.73);
  }

  77.3399014778% {
    transform: translateX(26vw) translateY(-26vh) scale(0.77);
  }

  77.8325123153% {
    transform: translateX(-23vw) translateY(48vh) scale(0.82);
  }

  78.3251231527% {
    transform: translateX(-8vw) translateY(33vh) scale(0.76);
  }

  78.8177339901% {
    transform: translateX(-2vw) translateY(-27vh) scale(0.95);
  }

  79.3103448276% {
    transform: translateX(-1vw) translateY(41vh) scale(0.86);
  }

  79.802955665% {
    transform: translateX(-45vw) translateY(48vh) scale(0.65);
  }

  80.2955665025% {
    transform: translateX(-4vw) translateY(9vh) scale(0.65);
  }

  80.7881773399% {
    transform: translateX(-33vw) translateY(-1vh) scale(0.85);
  }

  81.2807881773% {
    transform: translateX(-23vw) translateY(-16vh) scale(0.7);
  }

  81.7733990148% {
    transform: translateX(17vw) translateY(45vh) scale(0.32);
  }

  82.2660098522% {
    transform: translateX(24vw) translateY(6vh) scale(0.96);
  }

  82.7586206897% {
    transform: translateX(30vw) translateY(-31vh) scale(0.34);
  }

  83.2512315271% {
    transform: translateX(19vw) translateY(-38vh) scale(0.27);
  }

  83.7438423645% {
    transform: translateX(39vw) translateY(-36vh) scale(0.53);
  }

  84.236453202% {
    transform: translateX(14vw) translateY(15vh) scale(0.96);
  }

  84.7290640394% {
    transform: translateX(0vw) translateY(11vh) scale(0.97);
  }

  85.2216748768% {
    transform: translateX(25vw) translateY(-21vh) scale(0.51);
  }

  85.7142857143% {
    transform: translateX(21vw) translateY(28vh) scale(0.82);
  }

  86.2068965517% {
    transform: translateX(32vw) translateY(-1vh) scale(0.26);
  }

  86.6995073892% {
    transform: translateX(22vw) translateY(42vh) scale(0.35);
  }

  87.1921182266% {
    transform: translateX(-30vw) translateY(-47vh) scale(0.87);
  }

  87.684729064% {
    transform: translateX(34vw) translateY(48vh) scale(0.7);
  }

  88.1773399015% {
    transform: translateX(-40vw) translateY(7vh) scale(0.73);
  }

  88.6699507389% {
    transform: translateX(-27vw) translateY(-41vh) scale(0.59);
  }

  89.1625615764% {
    transform: translateX(37vw) translateY(-37vh) scale(0.28);
  }

  89.6551724138% {
    transform: translateX(-39vw) translateY(16vh) scale(0.96);
  }

  90.1477832512% {
    transform: translateX(48vw) translateY(-12vh) scale(0.4);
  }

  90.6403940887% {
    transform: translateX(34vw) translateY(-16vh) scale(0.93);
  }

  91.1330049261% {
    transform: translateX(23vw) translateY(13vh) scale(0.97);
  }

  91.6256157635% {
    transform: translateX(16vw) translateY(16vh) scale(0.83);
  }

  92.118226601% {
    transform: translateX(-31vw) translateY(22vh) scale(0.66);
  }

  92.6108374384% {
    transform: translateX(-41vw) translateY(-10vh) scale(0.41);
  }

  93.1034482759% {
    transform: translateX(-41vw) translateY(27vh) scale(0.39);
  }

  93.5960591133% {
    transform: translateX(44vw) translateY(-26vh) scale(0.5);
  }

  94.0886699507% {
    transform: translateX(-48vw) translateY(-2vh) scale(0.51);
  }

  94.5812807882% {
    transform: translateX(36vw) translateY(21vh) scale(0.43);
  }

  95.0738916256% {
    transform: translateX(-9vw) translateY(-43vh) scale(0.75);
  }

  95.5665024631% {
    transform: translateX(1vw) translateY(-43vh) scale(0.58);
  }

  96.0591133005% {
    transform: translateX(50vw) translateY(-47vh) scale(0.84);
  }

  96.5517241379% {
    transform: translateX(-10vw) translateY(-25vh) scale(0.4);
  }

  97.0443349754% {
    transform: translateX(-37vw) translateY(-32vh) scale(0.43);
  }

  97.5369458128% {
    transform: translateX(-11vw) translateY(-48vh) scale(0.66);
  }

  98.0295566502% {
    transform: translateX(-41vw) translateY(16vh) scale(0.28);
  }

  98.5221674877% {
    transform: translateX(-8vw) translateY(20vh) scale(0.31);
  }

  99.0147783251% {
    transform: translateX(31vw) translateY(11vh) scale(0.44);
  }

  99.5073891626% {
    transform: translateX(-8vw) translateY(33vh) scale(0.94);
  }

  100% {
    transform: translateX(10vw) translateY(-26vh) scale(0.35);
  }
}

.firefly:nth-child(22) {
  animation-name: move22;
}

.firefly:nth-child(22)::before {
  animation-duration: 18s;
}

.firefly:nth-child(22)::after {
  animation-duration: 18s, 6287ms;
  animation-delay: 0ms, 2097ms;
}

@keyframes move22 {
  0% {
    transform: translateX(15vw) translateY(-17vh) scale(0.89);
  }

  0.4854368932% {
    transform: translateX(-30vw) translateY(30vh) scale(0.98);
  }

  0.9708737864% {
    transform: translateX(28vw) translateY(-18vh) scale(0.73);
  }

  1.4563106796% {
    transform: translateX(-7vw) translateY(31vh) scale(0.45);
  }

  1.9417475728% {
    transform: translateX(-4vw) translateY(-28vh) scale(0.97);
  }

  2.427184466% {
    transform: translateX(40vw) translateY(5vh) scale(0.89);
  }

  2.9126213592% {
    transform: translateX(-13vw) translateY(-2vh) scale(0.32);
  }

  3.3980582524% {
    transform: translateX(-12vw) translateY(49vh) scale(0.85);
  }

  3.8834951456% {
    transform: translateX(-3vw) translateY(16vh) scale(0.57);
  }

  4.3689320388% {
    transform: translateX(2vw) translateY(18vh) scale(0.29);
  }

  4.854368932% {
    transform: translateX(-45vw) translateY(-28vh) scale(0.52);
  }

  5.3398058252% {
    transform: translateX(-2vw) translateY(-24vh) scale(1);
  }

  5.8252427184% {
    transform: translateX(41vw) translateY(-12vh) scale(0.92);
  }

  6.3106796117% {
    transform: translateX(-19vw) translateY(6vh) scale(0.56);
  }

  6.7961165049% {
    transform: translateX(18vw) translateY(26vh) scale(0.29);
  }

  7.2815533981% {
    transform: translateX(12vw) translateY(49vh) scale(0.45);
  }

  7.7669902913% {
    transform: translateX(34vw) translateY(-43vh) scale(0.33);
  }

  8.2524271845% {
    transform: translateX(2vw) translateY(38vh) scale(0.62);
  }

  8.7378640777% {
    transform: translateX(9vw) translateY(-16vh) scale(0.31);
  }

  9.2233009709% {
    transform: translateX(-6vw) translateY(7vh) scale(0.75);
  }

  9.7087378641% {
    transform: translateX(24vw) translateY(34vh) scale(1);
  }

  10.1941747573% {
    transform: translateX(21vw) translateY(34vh) scale(0.29);
  }

  10.6796116505% {
    transform: translateX(-7vw) translateY(3vh) scale(0.84);
  }

  11.1650485437% {
    transform: translateX(-14vw) translateY(14vh) scale(0.73);
  }

  11.6504854369% {
    transform: translateX(1vw) translateY(0vh) scale(1);
  }

  12.1359223301% {
    transform: translateX(21vw) translateY(24vh) scale(0.98);
  }

  12.6213592233% {
    transform: translateX(17vw) translateY(10vh) scale(0.51);
  }

  13.1067961165% {
    transform: translateX(-18vw) translateY(-10vh) scale(0.89);
  }

  13.5922330097% {
    transform: translateX(-47vw) translateY(32vh) scale(0.84);
  }

  14.0776699029% {
    transform: translateX(9vw) translateY(-43vh) scale(0.34);
  }

  14.5631067961% {
    transform: translateX(31vw) translateY(-11vh) scale(0.59);
  }

  15.0485436893% {
    transform: translateX(-17vw) translateY(-34vh) scale(1);
  }

  15.5339805825% {
    transform: translateX(27vw) translateY(-5vh) scale(0.65);
  }

  16.0194174757% {
    transform: translateX(32vw) translateY(-28vh) scale(0.47);
  }

  16.5048543689% {
    transform: translateX(-1vw) translateY(-37vh) scale(0.75);
  }

  16.9902912621% {
    transform: translateX(45vw) translateY(30vh) scale(0.74);
  }

  17.4757281553% {
    transform: translateX(2vw) translateY(42vh) scale(0.57);
  }

  17.9611650485% {
    transform: translateX(15vw) translateY(45vh) scale(0.46);
  }

  18.4466019417% {
    transform: translateX(-4vw) translateY(-9vh) scale(0.39);
  }

  18.932038835% {
    transform: translateX(37vw) translateY(10vh) scale(0.75);
  }

  19.4174757282% {
    transform: translateX(-33vw) translateY(31vh) scale(0.51);
  }

  19.9029126214% {
    transform: translateX(12vw) translateY(-1vh) scale(0.63);
  }

  20.3883495146% {
    transform: translateX(21vw) translateY(22vh) scale(0.5);
  }

  20.8737864078% {
    transform: translateX(-25vw) translateY(-17vh) scale(0.78);
  }

  21.359223301% {
    transform: translateX(9vw) translateY(-29vh) scale(0.95);
  }

  21.8446601942% {
    transform: translateX(-47vw) translateY(8vh) scale(0.93);
  }

  22.3300970874% {
    transform: translateX(23vw) translateY(-4vh) scale(0.75);
  }

  22.8155339806% {
    transform: translateX(42vw) translateY(-40vh) scale(0.83);
  }

  23.3009708738% {
    transform: translateX(30vw) translateY(-17vh) scale(0.33);
  }

  23.786407767% {
    transform: translateX(35vw) translateY(-43vh) scale(0.37);
  }

  24.2718446602% {
    transform: translateX(-42vw) translateY(32vh) scale(0.45);
  }

  24.7572815534% {
    transform: translateX(24vw) translateY(41vh) scale(0.79);
  }

  25.2427184466% {
    transform: translateX(23vw) translateY(33vh) scale(0.47);
  }

  25.7281553398% {
    transform: translateX(6vw) translateY(47vh) scale(0.91);
  }

  26.213592233% {
    transform: translateX(40vw) translateY(20vh) scale(0.69);
  }

  26.6990291262% {
    transform: translateX(-7vw) translateY(50vh) scale(0.63);
  }

  27.1844660194% {
    transform: translateX(-32vw) translateY(25vh) scale(0.91);
  }

  27.6699029126% {
    transform: translateX(-8vw) translateY(26vh) scale(0.45);
  }

  28.1553398058% {
    transform: translateX(-7vw) translateY(25vh) scale(0.39);
  }

  28.640776699% {
    transform: translateX(-13vw) translateY(-29vh) scale(0.82);
  }

  29.1262135922% {
    transform: translateX(-35vw) translateY(38vh) scale(0.49);
  }

  29.6116504854% {
    transform: translateX(-40vw) translateY(-25vh) scale(0.37);
  }

  30.0970873786% {
    transform: translateX(21vw) translateY(2vh) scale(0.59);
  }

  30.5825242718% {
    transform: translateX(50vw) translateY(-27vh) scale(0.79);
  }

  31.067961165% {
    transform: translateX(27vw) translateY(0vh) scale(0.39);
  }

  31.5533980583% {
    transform: translateX(-11vw) translateY(9vh) scale(0.57);
  }

  32.0388349515% {
    transform: translateX(37vw) translateY(29vh) scale(0.35);
  }

  32.5242718447% {
    transform: translateX(38vw) translateY(-8vh) scale(0.51);
  }

  33.0097087379% {
    transform: translateX(0vw) translateY(6vh) scale(0.76);
  }

  33.4951456311% {
    transform: translateX(-49vw) translateY(-6vh) scale(0.75);
  }

  33.9805825243% {
    transform: translateX(9vw) translateY(36vh) scale(0.45);
  }

  34.4660194175% {
    transform: translateX(-36vw) translateY(13vh) scale(0.27);
  }

  34.9514563107% {
    transform: translateX(16vw) translateY(-15vh) scale(0.84);
  }

  35.4368932039% {
    transform: translateX(-21vw) translateY(-45vh) scale(0.35);
  }

  35.9223300971% {
    transform: translateX(23vw) translateY(12vh) scale(0.57);
  }

  36.4077669903% {
    transform: translateX(-9vw) translateY(48vh) scale(0.95);
  }

  36.8932038835% {
    transform: translateX(-10vw) translateY(-8vh) scale(0.83);
  }

  37.3786407767% {
    transform: translateX(-49vw) translateY(-42vh) scale(0.29);
  }

  37.8640776699% {
    transform: translateX(-31vw) translateY(9vh) scale(0.42);
  }

  38.3495145631% {
    transform: translateX(43vw) translateY(27vh) scale(0.52);
  }

  38.8349514563% {
    transform: translateX(35vw) translateY(-23vh) scale(0.41);
  }

  39.3203883495% {
    transform: translateX(4vw) translateY(-29vh) scale(0.57);
  }

  39.8058252427% {
    transform: translateX(16vw) translateY(-20vh) scale(0.55);
  }

  40.2912621359% {
    transform: translateX(16vw) translateY(26vh) scale(0.95);
  }

  40.7766990291% {
    transform: translateX(-3vw) translateY(-10vh) scale(0.95);
  }

  41.2621359223% {
    transform: translateX(-23vw) translateY(16vh) scale(0.99);
  }

  41.7475728155% {
    transform: translateX(45vw) translateY(-23vh) scale(0.35);
  }

  42.2330097087% {
    transform: translateX(23vw) translateY(-35vh) scale(0.38);
  }

  42.7184466019% {
    transform: translateX(-20vw) translateY(-35vh) scale(0.83);
  }

  43.2038834951% {
    transform: translateX(36vw) translateY(8vh) scale(0.88);
  }

  43.6893203883% {
    transform: translateX(-10vw) translateY(47vh) scale(0.41);
  }

  44.1747572816% {
    transform: translateX(17vw) translateY(-23vh) scale(0.88);
  }

  44.6601941748% {
    transform: translateX(32vw) translateY(-7vh) scale(0.28);
  }

  45.145631068% {
    transform: translateX(33vw) translateY(21vh) scale(0.55);
  }

  45.6310679612% {
    transform: translateX(35vw) translateY(23vh) scale(0.7);
  }

  46.1165048544% {
    transform: translateX(4vw) translateY(-3vh) scale(0.73);
  }

  46.6019417476% {
    transform: translateX(43vw) translateY(-24vh) scale(0.61);
  }

  47.0873786408% {
    transform: translateX(6vw) translateY(-8vh) scale(0.62);
  }

  47.572815534% {
    transform: translateX(-9vw) translateY(45vh) scale(0.32);
  }

  48.0582524272% {
    transform: translateX(34vw) translateY(32vh) scale(0.53);
  }

  48.5436893204% {
    transform: translateX(47vw) translateY(15vh) scale(0.7);
  }

  49.0291262136% {
    transform: translateX(-3vw) translateY(10vh) scale(1);
  }

  49.5145631068% {
    transform: translateX(-47vw) translateY(45vh) scale(0.97);
  }

  50% {
    transform: translateX(-38vw) translateY(28vh) scale(0.64);
  }

  50.4854368932% {
    transform: translateX(-39vw) translateY(-22vh) scale(0.62);
  }

  50.9708737864% {
    transform: translateX(-22vw) translateY(-47vh) scale(0.62);
  }

  51.4563106796% {
    transform: translateX(24vw) translateY(-38vh) scale(0.55);
  }

  51.9417475728% {
    transform: translateX(8vw) translateY(31vh) scale(0.5);
  }

  52.427184466% {
    transform: translateX(41vw) translateY(-15vh) scale(0.69);
  }

  52.9126213592% {
    transform: translateX(31vw) translateY(-47vh) scale(1);
  }

  53.3980582524% {
    transform: translateX(-34vw) translateY(-26vh) scale(0.61);
  }

  53.8834951456% {
    transform: translateX(-7vw) translateY(-1vh) scale(0.68);
  }

  54.3689320388% {
    transform: translateX(-4vw) translateY(-32vh) scale(0.34);
  }

  54.854368932% {
    transform: translateX(46vw) translateY(0vh) scale(0.63);
  }

  55.3398058252% {
    transform: translateX(15vw) translateY(1vh) scale(0.26);
  }

  55.8252427184% {
    transform: translateX(44vw) translateY(5vh) scale(0.38);
  }

  56.3106796117% {
    transform: translateX(20vw) translateY(16vh) scale(0.72);
  }

  56.7961165049% {
    transform: translateX(31vw) translateY(-12vh) scale(0.71);
  }

  57.2815533981% {
    transform: translateX(49vw) translateY(0vh) scale(0.74);
  }

  57.7669902913% {
    transform: translateX(6vw) translateY(50vh) scale(0.5);
  }

  58.2524271845% {
    transform: translateX(30vw) translateY(-14vh) scale(0.76);
  }

  58.7378640777% {
    transform: translateX(49vw) translateY(-7vh) scale(0.84);
  }

  59.2233009709% {
    transform: translateX(-20vw) translateY(35vh) scale(0.65);
  }

  59.7087378641% {
    transform: translateX(-48vw) translateY(37vh) scale(0.69);
  }

  60.1941747573% {
    transform: translateX(-8vw) translateY(-16vh) scale(0.91);
  }

  60.6796116505% {
    transform: translateX(-11vw) translateY(19vh) scale(0.9);
  }

  61.1650485437% {
    transform: translateX(-11vw) translateY(8vh) scale(0.33);
  }

  61.6504854369% {
    transform: translateX(2vw) translateY(42vh) scale(0.91);
  }

  62.1359223301% {
    transform: translateX(38vw) translateY(48vh) scale(0.71);
  }

  62.6213592233% {
    transform: translateX(-46vw) translateY(40vh) scale(0.47);
  }

  63.1067961165% {
    transform: translateX(-36vw) translateY(26vh) scale(0.82);
  }

  63.5922330097% {
    transform: translateX(25vw) translateY(-21vh) scale(0.57);
  }

  64.0776699029% {
    transform: translateX(32vw) translateY(-5vh) scale(0.88);
  }

  64.5631067961% {
    transform: translateX(1vw) translateY(50vh) scale(0.7);
  }

  65.0485436893% {
    transform: translateX(-25vw) translateY(29vh) scale(0.37);
  }

  65.5339805825% {
    transform: translateX(30vw) translateY(-33vh) scale(0.31);
  }

  66.0194174757% {
    transform: translateX(13vw) translateY(-28vh) scale(0.54);
  }

  66.5048543689% {
    transform: translateX(9vw) translateY(23vh) scale(0.33);
  }

  66.9902912621% {
    transform: translateX(5vw) translateY(-20vh) scale(0.72);
  }

  67.4757281553% {
    transform: translateX(-16vw) translateY(-27vh) scale(0.75);
  }

  67.9611650485% {
    transform: translateX(-43vw) translateY(-27vh) scale(0.37);
  }

  68.4466019417% {
    transform: translateX(22vw) translateY(4vh) scale(0.42);
  }

  68.932038835% {
    transform: translateX(48vw) translateY(21vh) scale(0.66);
  }

  69.4174757282% {
    transform: translateX(-15vw) translateY(-32vh) scale(0.55);
  }

  69.9029126214% {
    transform: translateX(-11vw) translateY(-32vh) scale(0.94);
  }

  70.3883495146% {
    transform: translateX(28vw) translateY(27vh) scale(0.35);
  }

  70.8737864078% {
    transform: translateX(28vw) translateY(36vh) scale(0.94);
  }

  71.359223301% {
    transform: translateX(16vw) translateY(-34vh) scale(0.48);
  }

  71.8446601942% {
    transform: translateX(-31vw) translateY(45vh) scale(0.67);
  }

  72.3300970874% {
    transform: translateX(50vw) translateY(25vh) scale(0.81);
  }

  72.8155339806% {
    transform: translateX(-48vw) translateY(-29vh) scale(0.59);
  }

  73.3009708738% {
    transform: translateX(-28vw) translateY(-35vh) scale(0.95);
  }

  73.786407767% {
    transform: translateX(3vw) translateY(-39vh) scale(0.9);
  }

  74.2718446602% {
    transform: translateX(27vw) translateY(-32vh) scale(0.43);
  }

  74.7572815534% {
    transform: translateX(-23vw) translateY(23vh) scale(0.94);
  }

  75.2427184466% {
    transform: translateX(28vw) translateY(20vh) scale(0.85);
  }

  75.7281553398% {
    transform: translateX(15vw) translateY(-16vh) scale(0.26);
  }

  76.213592233% {
    transform: translateX(2vw) translateY(0vh) scale(0.26);
  }

  76.6990291262% {
    transform: translateX(14vw) translateY(5vh) scale(0.84);
  }

  77.1844660194% {
    transform: translateX(-43vw) translateY(2vh) scale(0.63);
  }

  77.6699029126% {
    transform: translateX(-44vw) translateY(-15vh) scale(0.42);
  }

  78.1553398058% {
    transform: translateX(42vw) translateY(-44vh) scale(0.92);
  }

  78.640776699% {
    transform: translateX(30vw) translateY(3vh) scale(0.97);
  }

  79.1262135922% {
    transform: translateX(-20vw) translateY(-46vh) scale(0.28);
  }

  79.6116504854% {
    transform: translateX(-35vw) translateY(31vh) scale(0.48);
  }

  80.0970873786% {
    transform: translateX(7vw) translateY(-35vh) scale(0.56);
  }

  80.5825242718% {
    transform: translateX(16vw) translateY(50vh) scale(0.74);
  }

  81.067961165% {
    transform: translateX(-18vw) translateY(-18vh) scale(0.8);
  }

  81.5533980583% {
    transform: translateX(15vw) translateY(-33vh) scale(0.34);
  }

  82.0388349515% {
    transform: translateX(6vw) translateY(19vh) scale(0.33);
  }

  82.5242718447% {
    transform: translateX(-29vw) translateY(-10vh) scale(0.75);
  }

  83.0097087379% {
    transform: translateX(-36vw) translateY(38vh) scale(0.56);
  }

  83.4951456311% {
    transform: translateX(-41vw) translateY(9vh) scale(0.97);
  }

  83.9805825243% {
    transform: translateX(-44vw) translateY(5vh) scale(0.82);
  }

  84.4660194175% {
    transform: translateX(42vw) translateY(4vh) scale(0.66);
  }

  84.9514563107% {
    transform: translateX(7vw) translateY(19vh) scale(0.42);
  }

  85.4368932039% {
    transform: translateX(31vw) translateY(-23vh) scale(0.8);
  }

  85.9223300971% {
    transform: translateX(-36vw) translateY(15vh) scale(0.7);
  }

  86.4077669903% {
    transform: translateX(-3vw) translateY(-33vh) scale(0.85);
  }

  86.8932038835% {
    transform: translateX(-23vw) translateY(16vh) scale(0.97);
  }

  87.3786407767% {
    transform: translateX(-32vw) translateY(-29vh) scale(0.93);
  }

  87.8640776699% {
    transform: translateX(-25vw) translateY(-19vh) scale(0.47);
  }

  88.3495145631% {
    transform: translateX(-5vw) translateY(-27vh) scale(0.52);
  }

  88.8349514563% {
    transform: translateX(-46vw) translateY(36vh) scale(0.5);
  }

  89.3203883495% {
    transform: translateX(-48vw) translateY(-43vh) scale(0.99);
  }

  89.8058252427% {
    transform: translateX(11vw) translateY(35vh) scale(0.97);
  }

  90.2912621359% {
    transform: translateX(34vw) translateY(-38vh) scale(0.54);
  }

  90.7766990291% {
    transform: translateX(1vw) translateY(-22vh) scale(0.91);
  }

  91.2621359223% {
    transform: translateX(-20vw) translateY(16vh) scale(0.26);
  }

  91.7475728155% {
    transform: translateX(50vw) translateY(-11vh) scale(0.7);
  }

  92.2330097087% {
    transform: translateX(-44vw) translateY(-12vh) scale(0.9);
  }

  92.7184466019% {
    transform: translateX(-41vw) translateY(-18vh) scale(0.38);
  }

  93.2038834951% {
    transform: translateX(35vw) translateY(-32vh) scale(0.29);
  }

  93.6893203883% {
    transform: translateX(22vw) translateY(1vh) scale(0.36);
  }

  94.1747572816% {
    transform: translateX(4vw) translateY(29vh) scale(0.46);
  }

  94.6601941748% {
    transform: translateX(23vw) translateY(22vh) scale(0.32);
  }

  95.145631068% {
    transform: translateX(48vw) translateY(42vh) scale(0.54);
  }

  95.6310679612% {
    transform: translateX(2vw) translateY(-49vh) scale(0.44);
  }

  96.1165048544% {
    transform: translateX(47vw) translateY(-17vh) scale(0.27);
  }

  96.6019417476% {
    transform: translateX(-25vw) translateY(-6vh) scale(0.83);
  }

  97.0873786408% {
    transform: translateX(0vw) translateY(-41vh) scale(0.53);
  }

  97.572815534% {
    transform: translateX(17vw) translateY(17vh) scale(0.76);
  }

  98.0582524272% {
    transform: translateX(50vw) translateY(7vh) scale(0.56);
  }

  98.5436893204% {
    transform: translateX(16vw) translateY(0vh) scale(0.79);
  }

  99.0291262136% {
    transform: translateX(48vw) translateY(-45vh) scale(0.81);
  }

  99.5145631068% {
    transform: translateX(47vw) translateY(37vh) scale(0.7);
  }

  100% {
    transform: translateX(10vw) translateY(42vh) scale(0.91);
  }
}

.firefly:nth-child(23) {
  animation-name: move23;
}

.firefly:nth-child(23)::before {
  animation-duration: 9s;
}

.firefly:nth-child(23)::after {
  animation-duration: 9s, 9143ms;
  animation-delay: 0ms, 5528ms;
}

@keyframes move23 {
  0% {
    transform: translateX(33vw) translateY(22vh) scale(0.34);
  }

  0.5847953216% {
    transform: translateX(26vw) translateY(18vh) scale(0.69);
  }

  1.1695906433% {
    transform: translateX(-8vw) translateY(45vh) scale(0.39);
  }

  1.7543859649% {
    transform: translateX(13vw) translateY(15vh) scale(0.75);
  }

  2.3391812865% {
    transform: translateX(-13vw) translateY(26vh) scale(0.97);
  }

  2.9239766082% {
    transform: translateX(1vw) translateY(-30vh) scale(0.98);
  }

  3.5087719298% {
    transform: translateX(39vw) translateY(34vh) scale(0.55);
  }

  4.0935672515% {
    transform: translateX(-3vw) translateY(5vh) scale(0.94);
  }

  4.6783625731% {
    transform: translateX(-15vw) translateY(-26vh) scale(0.81);
  }

  5.2631578947% {
    transform: translateX(16vw) translateY(42vh) scale(0.47);
  }

  5.8479532164% {
    transform: translateX(50vw) translateY(44vh) scale(0.28);
  }

  6.432748538% {
    transform: translateX(-41vw) translateY(-9vh) scale(0.69);
  }

  7.0175438596% {
    transform: translateX(36vw) translateY(45vh) scale(0.39);
  }

  7.6023391813% {
    transform: translateX(22vw) translateY(35vh) scale(0.54);
  }

  8.1871345029% {
    transform: translateX(39vw) translateY(48vh) scale(0.5);
  }

  8.7719298246% {
    transform: translateX(-30vw) translateY(-34vh) scale(0.71);
  }

  9.3567251462% {
    transform: translateX(-6vw) translateY(48vh) scale(0.49);
  }

  9.9415204678% {
    transform: translateX(14vw) translateY(-1vh) scale(0.94);
  }

  10.5263157895% {
    transform: translateX(4vw) translateY(-45vh) scale(0.64);
  }

  11.1111111111% {
    transform: translateX(41vw) translateY(49vh) scale(0.91);
  }

  11.6959064327% {
    transform: translateX(49vw) translateY(-10vh) scale(0.7);
  }

  12.2807017544% {
    transform: translateX(6vw) translateY(-47vh) scale(0.64);
  }

  12.865497076% {
    transform: translateX(-41vw) translateY(22vh) scale(0.72);
  }

  13.4502923977% {
    transform: translateX(4vw) translateY(19vh) scale(0.9);
  }

  14.0350877193% {
    transform: translateX(-42vw) translateY(12vh) scale(0.77);
  }

  14.6198830409% {
    transform: translateX(-29vw) translateY(49vh) scale(0.75);
  }

  15.2046783626% {
    transform: translateX(0vw) translateY(47vh) scale(0.49);
  }

  15.7894736842% {
    transform: translateX(-13vw) translateY(34vh) scale(0.56);
  }

  16.3742690058% {
    transform: translateX(-49vw) translateY(-7vh) scale(0.83);
  }

  16.9590643275% {
    transform: translateX(-33vw) translateY(21vh) scale(0.86);
  }

  17.5438596491% {
    transform: translateX(44vw) translateY(-20vh) scale(0.69);
  }

  18.1286549708% {
    transform: translateX(-11vw) translateY(9vh) scale(0.32);
  }

  18.7134502924% {
    transform: translateX(31vw) translateY(28vh) scale(0.48);
  }

  19.298245614% {
    transform: translateX(12vw) translateY(13vh) scale(0.41);
  }

  19.8830409357% {
    transform: translateX(38vw) translateY(-10vh) scale(0.59);
  }

  20.4678362573% {
    transform: translateX(-13vw) translateY(-4vh) scale(0.38);
  }

  21.0526315789% {
    transform: translateX(15vw) translateY(-12vh) scale(0.63);
  }

  21.6374269006% {
    transform: translateX(-10vw) translateY(-17vh) scale(0.4);
  }

  22.2222222222% {
    transform: translateX(8vw) translateY(42vh) scale(0.93);
  }

  22.8070175439% {
    transform: translateX(4vw) translateY(-23vh) scale(0.95);
  }

  23.3918128655% {
    transform: translateX(-7vw) translateY(50vh) scale(0.55);
  }

  23.9766081871% {
    transform: translateX(12vw) translateY(-42vh) scale(0.97);
  }

  24.5614035088% {
    transform: translateX(-22vw) translateY(40vh) scale(0.98);
  }

  25.1461988304% {
    transform: translateX(10vw) translateY(4vh) scale(0.96);
  }

  25.730994152% {
    transform: translateX(36vw) translateY(-3vh) scale(0.5);
  }

  26.3157894737% {
    transform: translateX(46vw) translateY(34vh) scale(0.88);
  }

  26.9005847953% {
    transform: translateX(37vw) translateY(-30vh) scale(0.9);
  }

  27.485380117% {
    transform: translateX(17vw) translateY(-40vh) scale(0.29);
  }

  28.0701754386% {
    transform: translateX(-45vw) translateY(1vh) scale(0.55);
  }

  28.6549707602% {
    transform: translateX(2vw) translateY(18vh) scale(0.93);
  }

  29.2397660819% {
    transform: translateX(-23vw) translateY(21vh) scale(0.94);
  }

  29.8245614035% {
    transform: translateX(38vw) translateY(16vh) scale(0.45);
  }

  30.4093567251% {
    transform: translateX(23vw) translateY(-14vh) scale(0.34);
  }

  30.9941520468% {
    transform: translateX(45vw) translateY(-13vh) scale(0.77);
  }

  31.5789473684% {
    transform: translateX(25vw) translateY(18vh) scale(0.93);
  }

  32.1637426901% {
    transform: translateX(-29vw) translateY(-17vh) scale(0.92);
  }

  32.7485380117% {
    transform: translateX(38vw) translateY(-18vh) scale(0.93);
  }

  33.3333333333% {
    transform: translateX(10vw) translateY(21vh) scale(0.44);
  }

  33.918128655% {
    transform: translateX(13vw) translateY(6vh) scale(0.58);
  }

  34.5029239766% {
    transform: translateX(-28vw) translateY(8vh) scale(0.6);
  }

  35.0877192982% {
    transform: translateX(6vw) translateY(7vh) scale(0.75);
  }

  35.6725146199% {
    transform: translateX(46vw) translateY(-12vh) scale(0.5);
  }

  36.2573099415% {
    transform: translateX(-49vw) translateY(-38vh) scale(0.62);
  }

  36.8421052632% {
    transform: translateX(36vw) translateY(-35vh) scale(0.32);
  }

  37.4269005848% {
    transform: translateX(-15vw) translateY(-7vh) scale(0.29);
  }

  38.0116959064% {
    transform: translateX(45vw) translateY(-28vh) scale(0.74);
  }

  38.5964912281% {
    transform: translateX(-2vw) translateY(39vh) scale(0.75);
  }

  39.1812865497% {
    transform: translateX(-1vw) translateY(-36vh) scale(0.92);
  }

  39.7660818713% {
    transform: translateX(30vw) translateY(16vh) scale(0.49);
  }

  40.350877193% {
    transform: translateX(-12vw) translateY(-25vh) scale(0.75);
  }

  40.9356725146% {
    transform: translateX(-22vw) translateY(2vh) scale(0.82);
  }

  41.5204678363% {
    transform: translateX(-21vw) translateY(-6vh) scale(0.44);
  }

  42.1052631579% {
    transform: translateX(50vw) translateY(35vh) scale(0.72);
  }

  42.6900584795% {
    transform: translateX(32vw) translateY(-29vh) scale(0.77);
  }

  43.2748538012% {
    transform: translateX(-38vw) translateY(-14vh) scale(0.81);
  }

  43.8596491228% {
    transform: translateX(-28vw) translateY(48vh) scale(0.86);
  }

  44.4444444444% {
    transform: translateX(15vw) translateY(27vh) scale(0.6);
  }

  45.0292397661% {
    transform: translateX(-44vw) translateY(-10vh) scale(0.77);
  }

  45.6140350877% {
    transform: translateX(-33vw) translateY(32vh) scale(0.58);
  }

  46.1988304094% {
    transform: translateX(-35vw) translateY(10vh) scale(0.44);
  }

  46.783625731% {
    transform: translateX(-5vw) translateY(18vh) scale(0.67);
  }

  47.3684210526% {
    transform: translateX(-21vw) translateY(-49vh) scale(0.8);
  }

  47.9532163743% {
    transform: translateX(-2vw) translateY(-31vh) scale(0.29);
  }

  48.5380116959% {
    transform: translateX(41vw) translateY(-18vh) scale(0.49);
  }

  49.1228070175% {
    transform: translateX(5vw) translateY(45vh) scale(0.97);
  }

  49.7076023392% {
    transform: translateX(41vw) translateY(22vh) scale(0.47);
  }

  50.2923976608% {
    transform: translateX(-35vw) translateY(1vh) scale(0.57);
  }

  50.8771929825% {
    transform: translateX(-28vw) translateY(-43vh) scale(0.63);
  }

  51.4619883041% {
    transform: translateX(20vw) translateY(23vh) scale(0.36);
  }

  52.0467836257% {
    transform: translateX(46vw) translateY(21vh) scale(0.89);
  }

  52.6315789474% {
    transform: translateX(-22vw) translateY(14vh) scale(0.58);
  }

  53.216374269% {
    transform: translateX(43vw) translateY(-37vh) scale(0.32);
  }

  53.8011695906% {
    transform: translateX(-6vw) translateY(12vh) scale(0.96);
  }

  54.3859649123% {
    transform: translateX(11vw) translateY(-28vh) scale(0.47);
  }

  54.9707602339% {
    transform: translateX(-4vw) translateY(-11vh) scale(0.73);
  }

  55.5555555556% {
    transform: translateX(-20vw) translateY(38vh) scale(0.55);
  }

  56.1403508772% {
    transform: translateX(-41vw) translateY(-13vh) scale(0.38);
  }

  56.7251461988% {
    transform: translateX(30vw) translateY(-34vh) scale(0.84);
  }

  57.3099415205% {
    transform: translateX(-3vw) translateY(-41vh) scale(0.9);
  }

  57.8947368421% {
    transform: translateX(22vw) translateY(-31vh) scale(0.7);
  }

  58.4795321637% {
    transform: translateX(-3vw) translateY(-21vh) scale(0.85);
  }

  59.0643274854% {
    transform: translateX(15vw) translateY(-8vh) scale(0.97);
  }

  59.649122807% {
    transform: translateX(-49vw) translateY(-35vh) scale(0.41);
  }

  60.2339181287% {
    transform: translateX(-16vw) translateY(-41vh) scale(0.8);
  }

  60.8187134503% {
    transform: translateX(-24vw) translateY(26vh) scale(0.93);
  }

  61.4035087719% {
    transform: translateX(-36vw) translateY(-33vh) scale(1);
  }

  61.9883040936% {
    transform: translateX(14vw) translateY(-19vh) scale(0.35);
  }

  62.5730994152% {
    transform: translateX(4vw) translateY(-20vh) scale(0.99);
  }

  63.1578947368% {
    transform: translateX(-23vw) translateY(21vh) scale(0.88);
  }

  63.7426900585% {
    transform: translateX(50vw) translateY(-43vh) scale(0.6);
  }

  64.3274853801% {
    transform: translateX(32vw) translateY(18vh) scale(0.85);
  }

  64.9122807018% {
    transform: translateX(-21vw) translateY(-10vh) scale(0.59);
  }

  65.4970760234% {
    transform: translateX(-16vw) translateY(32vh) scale(0.32);
  }

  66.081871345% {
    transform: translateX(-30vw) translateY(21vh) scale(0.96);
  }

  66.6666666667% {
    transform: translateX(36vw) translateY(-26vh) scale(0.34);
  }

  67.2514619883% {
    transform: translateX(13vw) translateY(-45vh) scale(0.85);
  }

  67.8362573099% {
    transform: translateX(-13vw) translateY(-48vh) scale(0.55);
  }

  68.4210526316% {
    transform: translateX(-4vw) translateY(-21vh) scale(0.55);
  }

  69.0058479532% {
    transform: translateX(25vw) translateY(-33vh) scale(0.44);
  }

  69.5906432749% {
    transform: translateX(-17vw) translateY(7vh) scale(0.68);
  }

  70.1754385965% {
    transform: translateX(45vw) translateY(-27vh) scale(0.45);
  }

  70.7602339181% {
    transform: translateX(42vw) translateY(-43vh) scale(0.66);
  }

  71.3450292398% {
    transform: translateX(21vw) translateY(-3vh) scale(0.43);
  }

  71.9298245614% {
    transform: translateX(-27vw) translateY(-1vh) scale(0.47);
  }

  72.514619883% {
    transform: translateX(45vw) translateY(19vh) scale(0.94);
  }

  73.0994152047% {
    transform: translateX(14vw) translateY(26vh) scale(0.81);
  }

  73.6842105263% {
    transform: translateX(21vw) translateY(1vh) scale(0.55);
  }

  74.269005848% {
    transform: translateX(-47vw) translateY(9vh) scale(0.48);
  }

  74.8538011696% {
    transform: translateX(19vw) translateY(1vh) scale(0.45);
  }

  75.4385964912% {
    transform: translateX(-21vw) translateY(-10vh) scale(0.28);
  }

  76.0233918129% {
    transform: translateX(-49vw) translateY(26vh) scale(0.8);
  }

  76.6081871345% {
    transform: translateX(18vw) translateY(19vh) scale(0.54);
  }

  77.1929824561% {
    transform: translateX(-7vw) translateY(-48vh) scale(0.31);
  }

  77.7777777778% {
    transform: translateX(-3vw) translateY(22vh) scale(0.81);
  }

  78.3625730994% {
    transform: translateX(1vw) translateY(-22vh) scale(0.89);
  }

  78.9473684211% {
    transform: translateX(-30vw) translateY(11vh) scale(0.9);
  }

  79.5321637427% {
    transform: translateX(26vw) translateY(-36vh) scale(0.99);
  }

  80.1169590643% {
    transform: translateX(30vw) translateY(-43vh) scale(0.83);
  }

  80.701754386% {
    transform: translateX(-14vw) translateY(-40vh) scale(0.38);
  }

  81.2865497076% {
    transform: translateX(-28vw) translateY(5vh) scale(0.84);
  }

  81.8713450292% {
    transform: translateX(12vw) translateY(-36vh) scale(0.88);
  }

  82.4561403509% {
    transform: translateX(45vw) translateY(15vh) scale(0.76);
  }

  83.0409356725% {
    transform: translateX(26vw) translateY(7vh) scale(0.84);
  }

  83.6257309942% {
    transform: translateX(13vw) translateY(-27vh) scale(0.45);
  }

  84.2105263158% {
    transform: translateX(24vw) translateY(-14vh) scale(0.58);
  }

  84.7953216374% {
    transform: translateX(-2vw) translateY(1vh) scale(0.87);
  }

  85.3801169591% {
    transform: translateX(-46vw) translateY(8vh) scale(0.3);
  }

  85.9649122807% {
    transform: translateX(-35vw) translateY(-22vh) scale(0.84);
  }

  86.5497076023% {
    transform: translateX(39vw) translateY(-29vh) scale(0.67);
  }

  87.134502924% {
    transform: translateX(16vw) translateY(14vh) scale(0.93);
  }

  87.7192982456% {
    transform: translateX(36vw) translateY(13vh) scale(0.3);
  }

  88.3040935673% {
    transform: translateX(33vw) translateY(-27vh) scale(0.63);
  }

  88.8888888889% {
    transform: translateX(-38vw) translateY(3vh) scale(0.57);
  }

  89.4736842105% {
    transform: translateX(-29vw) translateY(-46vh) scale(0.32);
  }

  90.0584795322% {
    transform: translateX(7vw) translateY(6vh) scale(1);
  }

  90.6432748538% {
    transform: translateX(-36vw) translateY(-49vh) scale(0.89);
  }

  91.2280701754% {
    transform: translateX(50vw) translateY(6vh) scale(0.8);
  }

  91.8128654971% {
    transform: translateX(3vw) translateY(-35vh) scale(0.95);
  }

  92.3976608187% {
    transform: translateX(9vw) translateY(43vh) scale(0.56);
  }

  92.9824561404% {
    transform: translateX(-7vw) translateY(-28vh) scale(0.83);
  }

  93.567251462% {
    transform: translateX(-25vw) translateY(-5vh) scale(0.62);
  }

  94.1520467836% {
    transform: translateX(42vw) translateY(22vh) scale(0.28);
  }

  94.7368421053% {
    transform: translateX(14vw) translateY(20vh) scale(0.86);
  }

  95.3216374269% {
    transform: translateX(-49vw) translateY(5vh) scale(0.79);
  }

  95.9064327485% {
    transform: translateX(-15vw) translateY(-19vh) scale(0.82);
  }

  96.4912280702% {
    transform: translateX(9vw) translateY(-30vh) scale(0.95);
  }

  97.0760233918% {
    transform: translateX(-29vw) translateY(-46vh) scale(0.61);
  }

  97.6608187135% {
    transform: translateX(1vw) translateY(-16vh) scale(0.65);
  }

  98.2456140351% {
    transform: translateX(-38vw) translateY(-35vh) scale(0.86);
  }

  98.8304093567% {
    transform: translateX(27vw) translateY(-22vh) scale(0.37);
  }

  99.4152046784% {
    transform: translateX(21vw) translateY(-23vh) scale(0.85);
  }

  100% {
    transform: translateX(-12vw) translateY(42vh) scale(0.62);
  }
}

.firefly:nth-child(24) {
  animation-name: move24;
}

.firefly:nth-child(24)::before {
  animation-duration: 14s;
}

.firefly:nth-child(24)::after {
  animation-duration: 14s, 10381ms;
  animation-delay: 0ms, 4558ms;
}

@keyframes move24 {
  0% {
    transform: translateX(45vw) translateY(16vh) scale(0.74);
  }

  0.3703703704% {
    transform: translateX(-45vw) translateY(-32vh) scale(0.97);
  }

  0.7407407407% {
    transform: translateX(42vw) translateY(-33vh) scale(0.55);
  }

  1.1111111111% {
    transform: translateX(5vw) translateY(17vh) scale(0.99);
  }

  1.4814814815% {
    transform: translateX(3vw) translateY(50vh) scale(0.69);
  }

  1.8518518519% {
    transform: translateX(-44vw) translateY(-3vh) scale(0.4);
  }

  2.2222222222% {
    transform: translateX(10vw) translateY(-23vh) scale(0.5);
  }

  2.5925925926% {
    transform: translateX(10vw) translateY(-39vh) scale(0.29);
  }

  2.962962963% {
    transform: translateX(-39vw) translateY(-30vh) scale(0.99);
  }

  3.3333333333% {
    transform: translateX(23vw) translateY(-40vh) scale(0.38);
  }

  3.7037037037% {
    transform: translateX(17vw) translateY(-8vh) scale(0.5);
  }

  4.0740740741% {
    transform: translateX(-29vw) translateY(-13vh) scale(0.97);
  }

  4.4444444444% {
    transform: translateX(10vw) translateY(33vh) scale(0.66);
  }

  4.8148148148% {
    transform: translateX(-45vw) translateY(-13vh) scale(0.71);
  }

  5.1851851852% {
    transform: translateX(27vw) translateY(-15vh) scale(0.58);
  }

  5.5555555556% {
    transform: translateX(15vw) translateY(49vh) scale(0.61);
  }

  5.9259259259% {
    transform: translateX(16vw) translateY(5vh) scale(0.41);
  }

  6.2962962963% {
    transform: translateX(-38vw) translateY(-34vh) scale(0.91);
  }

  6.6666666667% {
    transform: translateX(-8vw) translateY(33vh) scale(0.78);
  }

  7.037037037% {
    transform: translateX(26vw) translateY(-9vh) scale(0.39);
  }

  7.4074074074% {
    transform: translateX(-15vw) translateY(26vh) scale(0.32);
  }

  7.7777777778% {
    transform: translateX(-5vw) translateY(24vh) scale(0.96);
  }

  8.1481481481% {
    transform: translateX(29vw) translateY(24vh) scale(0.86);
  }

  8.5185185185% {
    transform: translateX(-27vw) translateY(-27vh) scale(0.71);
  }

  8.8888888889% {
    transform: translateX(49vw) translateY(49vh) scale(0.95);
  }

  9.2592592593% {
    transform: translateX(2vw) translateY(15vh) scale(0.39);
  }

  9.6296296296% {
    transform: translateX(27vw) translateY(43vh) scale(0.55);
  }

  10% {
    transform: translateX(50vw) translateY(-46vh) scale(0.6);
  }

  10.3703703704% {
    transform: translateX(47vw) translateY(-19vh) scale(0.4);
  }

  10.7407407407% {
    transform: translateX(3vw) translateY(7vh) scale(0.57);
  }

  11.1111111111% {
    transform: translateX(45vw) translateY(13vh) scale(0.73);
  }

  11.4814814815% {
    transform: translateX(-5vw) translateY(-4vh) scale(0.95);
  }

  11.8518518519% {
    transform: translateX(20vw) translateY(8vh) scale(0.39);
  }

  12.2222222222% {
    transform: translateX(30vw) translateY(29vh) scale(0.87);
  }

  12.5925925926% {
    transform: translateX(3vw) translateY(18vh) scale(0.93);
  }

  12.962962963% {
    transform: translateX(31vw) translateY(14vh) scale(0.68);
  }

  13.3333333333% {
    transform: translateX(-19vw) translateY(28vh) scale(0.77);
  }

  13.7037037037% {
    transform: translateX(10vw) translateY(-4vh) scale(0.62);
  }

  14.0740740741% {
    transform: translateX(-6vw) translateY(26vh) scale(0.8);
  }

  14.4444444444% {
    transform: translateX(13vw) translateY(-13vh) scale(0.65);
  }

  14.8148148148% {
    transform: translateX(-42vw) translateY(35vh) scale(0.44);
  }

  15.1851851852% {
    transform: translateX(22vw) translateY(-27vh) scale(0.42);
  }

  15.5555555556% {
    transform: translateX(13vw) translateY(23vh) scale(0.44);
  }

  15.9259259259% {
    transform: translateX(42vw) translateY(-9vh) scale(0.97);
  }

  16.2962962963% {
    transform: translateX(5vw) translateY(35vh) scale(0.5);
  }

  16.6666666667% {
    transform: translateX(-24vw) translateY(-23vh) scale(1);
  }

  17.037037037% {
    transform: translateX(40vw) translateY(11vh) scale(0.63);
  }

  17.4074074074% {
    transform: translateX(28vw) translateY(-8vh) scale(0.32);
  }

  17.7777777778% {
    transform: translateX(20vw) translateY(-43vh) scale(0.61);
  }

  18.1481481481% {
    transform: translateX(22vw) translateY(38vh) scale(0.73);
  }

  18.5185185185% {
    transform: translateX(48vw) translateY(17vh) scale(0.79);
  }

  18.8888888889% {
    transform: translateX(-46vw) translateY(40vh) scale(0.57);
  }

  19.2592592593% {
    transform: translateX(-13vw) translateY(45vh) scale(0.66);
  }

  19.6296296296% {
    transform: translateX(26vw) translateY(-32vh) scale(0.56);
  }

  20% {
    transform: translateX(13vw) translateY(42vh) scale(0.74);
  }

  20.3703703704% {
    transform: translateX(25vw) translateY(-10vh) scale(0.72);
  }

  20.7407407407% {
    transform: translateX(7vw) translateY(6vh) scale(0.69);
  }

  21.1111111111% {
    transform: translateX(-34vw) translateY(22vh) scale(0.74);
  }

  21.4814814815% {
    transform: translateX(31vw) translateY(24vh) scale(0.43);
  }

  21.8518518519% {
    transform: translateX(20vw) translateY(20vh) scale(0.98);
  }

  22.2222222222% {
    transform: translateX(41vw) translateY(32vh) scale(0.76);
  }

  22.5925925926% {
    transform: translateX(36vw) translateY(-34vh) scale(0.66);
  }

  22.962962963% {
    transform: translateX(39vw) translateY(-14vh) scale(0.88);
  }

  23.3333333333% {
    transform: translateX(-7vw) translateY(-20vh) scale(0.86);
  }

  23.7037037037% {
    transform: translateX(-22vw) translateY(49vh) scale(0.56);
  }

  24.0740740741% {
    transform: translateX(-25vw) translateY(50vh) scale(0.46);
  }

  24.4444444444% {
    transform: translateX(12vw) translateY(-22vh) scale(0.61);
  }

  24.8148148148% {
    transform: translateX(-45vw) translateY(-41vh) scale(0.31);
  }

  25.1851851852% {
    transform: translateX(31vw) translateY(1vh) scale(0.33);
  }

  25.5555555556% {
    transform: translateX(22vw) translateY(42vh) scale(0.26);
  }

  25.9259259259% {
    transform: translateX(32vw) translateY(-48vh) scale(0.9);
  }

  26.2962962963% {
    transform: translateX(46vw) translateY(-2vh) scale(0.89);
  }

  26.6666666667% {
    transform: translateX(-27vw) translateY(-47vh) scale(0.97);
  }

  27.037037037% {
    transform: translateX(4vw) translateY(0vh) scale(0.52);
  }

  27.4074074074% {
    transform: translateX(17vw) translateY(-25vh) scale(0.66);
  }

  27.7777777778% {
    transform: translateX(44vw) translateY(-1vh) scale(0.57);
  }

  28.1481481481% {
    transform: translateX(-31vw) translateY(36vh) scale(0.49);
  }

  28.5185185185% {
    transform: translateX(27vw) translateY(19vh) scale(0.74);
  }

  28.8888888889% {
    transform: translateX(18vw) translateY(-4vh) scale(0.95);
  }

  29.2592592593% {
    transform: translateX(8vw) translateY(49vh) scale(0.55);
  }

  29.6296296296% {
    transform: translateX(16vw) translateY(-12vh) scale(0.6);
  }

  30% {
    transform: translateX(27vw) translateY(7vh) scale(0.32);
  }

  30.3703703704% {
    transform: translateX(-9vw) translateY(20vh) scale(0.71);
  }

  30.7407407407% {
    transform: translateX(41vw) translateY(33vh) scale(0.59);
  }

  31.1111111111% {
    transform: translateX(28vw) translateY(22vh) scale(0.38);
  }

  31.4814814815% {
    transform: translateX(-38vw) translateY(5vh) scale(0.54);
  }

  31.8518518519% {
    transform: translateX(29vw) translateY(-19vh) scale(0.51);
  }

  32.2222222222% {
    transform: translateX(-24vw) translateY(-41vh) scale(0.43);
  }

  32.5925925926% {
    transform: translateX(36vw) translateY(48vh) scale(0.41);
  }

  32.962962963% {
    transform: translateX(44vw) translateY(2vh) scale(0.54);
  }

  33.3333333333% {
    transform: translateX(-20vw) translateY(-13vh) scale(0.6);
  }

  33.7037037037% {
    transform: translateX(30vw) translateY(1vh) scale(0.92);
  }

  34.0740740741% {
    transform: translateX(21vw) translateY(-38vh) scale(0.36);
  }

  34.4444444444% {
    transform: translateX(35vw) translateY(-12vh) scale(0.75);
  }

  34.8148148148% {
    transform: translateX(-12vw) translateY(42vh) scale(0.93);
  }

  35.1851851852% {
    transform: translateX(43vw) translateY(-10vh) scale(0.64);
  }

  35.5555555556% {
    transform: translateX(-47vw) translateY(-33vh) scale(0.59);
  }

  35.9259259259% {
    transform: translateX(15vw) translateY(-16vh) scale(0.66);
  }

  36.2962962963% {
    transform: translateX(-37vw) translateY(17vh) scale(0.42);
  }

  36.6666666667% {
    transform: translateX(-24vw) translateY(-11vh) scale(0.64);
  }

  37.037037037% {
    transform: translateX(7vw) translateY(10vh) scale(0.94);
  }

  37.4074074074% {
    transform: translateX(24vw) translateY(50vh) scale(0.68);
  }

  37.7777777778% {
    transform: translateX(8vw) translateY(-28vh) scale(0.35);
  }

  38.1481481481% {
    transform: translateX(45vw) translateY(29vh) scale(0.87);
  }

  38.5185185185% {
    transform: translateX(39vw) translateY(-35vh) scale(0.42);
  }

  38.8888888889% {
    transform: translateX(-37vw) translateY(17vh) scale(0.4);
  }

  39.2592592593% {
    transform: translateX(-9vw) translateY(-6vh) scale(0.37);
  }

  39.6296296296% {
    transform: translateX(-24vw) translateY(49vh) scale(0.44);
  }

  40% {
    transform: translateX(30vw) translateY(-21vh) scale(0.37);
  }

  40.3703703704% {
    transform: translateX(-19vw) translateY(11vh) scale(0.83);
  }

  40.7407407407% {
    transform: translateX(-38vw) translateY(-3vh) scale(0.41);
  }

  41.1111111111% {
    transform: translateX(-4vw) translateY(-5vh) scale(0.82);
  }

  41.4814814815% {
    transform: translateX(-10vw) translateY(-45vh) scale(0.66);
  }

  41.8518518519% {
    transform: translateX(40vw) translateY(36vh) scale(0.28);
  }

  42.2222222222% {
    transform: translateX(-28vw) translateY(25vh) scale(0.96);
  }

  42.5925925926% {
    transform: translateX(-32vw) translateY(35vh) scale(0.85);
  }

  42.962962963% {
    transform: translateX(5vw) translateY(-38vh) scale(0.82);
  }

  43.3333333333% {
    transform: translateX(14vw) translateY(50vh) scale(0.35);
  }

  43.7037037037% {
    transform: translateX(36vw) translateY(41vh) scale(0.76);
  }

  44.0740740741% {
    transform: translateX(-40vw) translateY(27vh) scale(0.73);
  }

  44.4444444444% {
    transform: translateX(15vw) translateY(19vh) scale(0.68);
  }

  44.8148148148% {
    transform: translateX(-49vw) translateY(-12vh) scale(1);
  }

  45.1851851852% {
    transform: translateX(-18vw) translateY(-33vh) scale(0.85);
  }

  45.5555555556% {
    transform: translateX(-18vw) translateY(26vh) scale(0.56);
  }

  45.9259259259% {
    transform: translateX(-7vw) translateY(38vh) scale(0.54);
  }

  46.2962962963% {
    transform: translateX(5vw) translateY(-32vh) scale(0.56);
  }

  46.6666666667% {
    transform: translateX(37vw) translateY(33vh) scale(0.62);
  }

  47.037037037% {
    transform: translateX(38vw) translateY(-48vh) scale(0.36);
  }

  47.4074074074% {
    transform: translateX(-16vw) translateY(-17vh) scale(0.36);
  }

  47.7777777778% {
    transform: translateX(2vw) translateY(-29vh) scale(0.87);
  }

  48.1481481481% {
    transform: translateX(41vw) translateY(37vh) scale(0.57);
  }

  48.5185185185% {
    transform: translateX(-28vw) translateY(-4vh) scale(0.58);
  }

  48.8888888889% {
    transform: translateX(32vw) translateY(-46vh) scale(0.9);
  }

  49.2592592593% {
    transform: translateX(-8vw) translateY(12vh) scale(0.36);
  }

  49.6296296296% {
    transform: translateX(-7vw) translateY(29vh) scale(0.33);
  }

  50% {
    transform: translateX(-30vw) translateY(36vh) scale(0.28);
  }

  50.3703703704% {
    transform: translateX(-41vw) translateY(25vh) scale(0.64);
  }

  50.7407407407% {
    transform: translateX(-12vw) translateY(-32vh) scale(0.54);
  }

  51.1111111111% {
    transform: translateX(-16vw) translateY(-46vh) scale(0.87);
  }

  51.4814814815% {
    transform: translateX(-12vw) translateY(41vh) scale(0.74);
  }

  51.8518518519% {
    transform: translateX(11vw) translateY(26vh) scale(0.4);
  }

  52.2222222222% {
    transform: translateX(32vw) translateY(48vh) scale(0.52);
  }

  52.5925925926% {
    transform: translateX(30vw) translateY(-34vh) scale(0.74);
  }

  52.962962963% {
    transform: translateX(49vw) translateY(5vh) scale(0.98);
  }

  53.3333333333% {
    transform: translateX(0vw) translateY(24vh) scale(0.39);
  }

  53.7037037037% {
    transform: translateX(15vw) translateY(42vh) scale(0.63);
  }

  54.0740740741% {
    transform: translateX(-1vw) translateY(46vh) scale(0.65);
  }

  54.4444444444% {
    transform: translateX(42vw) translateY(41vh) scale(0.85);
  }

  54.8148148148% {
    transform: translateX(12vw) translateY(15vh) scale(0.62);
  }

  55.1851851852% {
    transform: translateX(12vw) translateY(-21vh) scale(0.44);
  }

  55.5555555556% {
    transform: translateX(-3vw) translateY(1vh) scale(0.53);
  }

  55.9259259259% {
    transform: translateX(7vw) translateY(-8vh) scale(0.9);
  }

  56.2962962963% {
    transform: translateX(-33vw) translateY(28vh) scale(0.27);
  }

  56.6666666667% {
    transform: translateX(8vw) translateY(27vh) scale(0.69);
  }

  57.037037037% {
    transform: translateX(6vw) translateY(11vh) scale(0.53);
  }

  57.4074074074% {
    transform: translateX(-47vw) translateY(-3vh) scale(0.91);
  }

  57.7777777778% {
    transform: translateX(18vw) translateY(30vh) scale(0.43);
  }

  58.1481481481% {
    transform: translateX(-2vw) translateY(35vh) scale(0.42);
  }

  58.5185185185% {
    transform: translateX(-37vw) translateY(10vh) scale(0.51);
  }

  58.8888888889% {
    transform: translateX(-39vw) translateY(3vh) scale(0.55);
  }

  59.2592592593% {
    transform: translateX(-9vw) translateY(-45vh) scale(0.97);
  }

  59.6296296296% {
    transform: translateX(-15vw) translateY(15vh) scale(0.82);
  }

  60% {
    transform: translateX(41vw) translateY(-49vh) scale(0.68);
  }

  60.3703703704% {
    transform: translateX(37vw) translateY(-44vh) scale(0.59);
  }

  60.7407407407% {
    transform: translateX(34vw) translateY(48vh) scale(0.46);
  }

  61.1111111111% {
    transform: translateX(42vw) translateY(-34vh) scale(0.37);
  }

  61.4814814815% {
    transform: translateX(-3vw) translateY(-36vh) scale(0.55);
  }

  61.8518518519% {
    transform: translateX(-32vw) translateY(-9vh) scale(0.78);
  }

  62.2222222222% {
    transform: translateX(31vw) translateY(40vh) scale(0.43);
  }

  62.5925925926% {
    transform: translateX(-25vw) translateY(-1vh) scale(0.59);
  }

  62.962962963% {
    transform: translateX(18vw) translateY(11vh) scale(0.58);
  }

  63.3333333333% {
    transform: translateX(4vw) translateY(26vh) scale(0.26);
  }

  63.7037037037% {
    transform: translateX(38vw) translateY(-1vh) scale(0.76);
  }

  64.0740740741% {
    transform: translateX(-20vw) translateY(-4vh) scale(0.79);
  }

  64.4444444444% {
    transform: translateX(41vw) translateY(-40vh) scale(0.92);
  }

  64.8148148148% {
    transform: translateX(-30vw) translateY(38vh) scale(0.72);
  }

  65.1851851852% {
    transform: translateX(19vw) translateY(25vh) scale(0.52);
  }

  65.5555555556% {
    transform: translateX(44vw) translateY(-9vh) scale(0.56);
  }

  65.9259259259% {
    transform: translateX(5vw) translateY(30vh) scale(0.85);
  }

  66.2962962963% {
    transform: translateX(-31vw) translateY(-39vh) scale(0.91);
  }

  66.6666666667% {
    transform: translateX(-27vw) translateY(47vh) scale(0.37);
  }

  67.037037037% {
    transform: translateX(-40vw) translateY(1vh) scale(0.66);
  }

  67.4074074074% {
    transform: translateX(-43vw) translateY(8vh) scale(0.53);
  }

  67.7777777778% {
    transform: translateX(-24vw) translateY(-36vh) scale(0.83);
  }

  68.1481481481% {
    transform: translateX(40vw) translateY(28vh) scale(0.43);
  }

  68.5185185185% {
    transform: translateX(-9vw) translateY(2vh) scale(0.63);
  }

  68.8888888889% {
    transform: translateX(17vw) translateY(-2vh) scale(0.37);
  }

  69.2592592593% {
    transform: translateX(50vw) translateY(48vh) scale(0.32);
  }

  69.6296296296% {
    transform: translateX(-18vw) translateY(-47vh) scale(0.68);
  }

  70% {
    transform: translateX(42vw) translateY(-32vh) scale(0.52);
  }

  70.3703703704% {
    transform: translateX(41vw) translateY(-33vh) scale(0.46);
  }

  70.7407407407% {
    transform: translateX(31vw) translateY(31vh) scale(0.75);
  }

  71.1111111111% {
    transform: translateX(12vw) translateY(-17vh) scale(0.92);
  }

  71.4814814815% {
    transform: translateX(46vw) translateY(15vh) scale(0.49);
  }

  71.8518518519% {
    transform: translateX(-9vw) translateY(-44vh) scale(0.66);
  }

  72.2222222222% {
    transform: translateX(18vw) translateY(-24vh) scale(0.32);
  }

  72.5925925926% {
    transform: translateX(-17vw) translateY(-30vh) scale(0.76);
  }

  72.962962963% {
    transform: translateX(-13vw) translateY(14vh) scale(0.79);
  }

  73.3333333333% {
    transform: translateX(-46vw) translateY(8vh) scale(0.96);
  }

  73.7037037037% {
    transform: translateX(-1vw) translateY(42vh) scale(0.71);
  }

  74.0740740741% {
    transform: translateX(-40vw) translateY(-8vh) scale(0.65);
  }

  74.4444444444% {
    transform: translateX(28vw) translateY(-12vh) scale(0.58);
  }

  74.8148148148% {
    transform: translateX(-1vw) translateY(45vh) scale(0.78);
  }

  75.1851851852% {
    transform: translateX(40vw) translateY(48vh) scale(0.58);
  }

  75.5555555556% {
    transform: translateX(37vw) translateY(13vh) scale(0.38);
  }

  75.9259259259% {
    transform: translateX(-48vw) translateY(19vh) scale(0.72);
  }

  76.2962962963% {
    transform: translateX(3vw) translateY(44vh) scale(0.8);
  }

  76.6666666667% {
    transform: translateX(-34vw) translateY(-9vh) scale(0.57);
  }

  77.037037037% {
    transform: translateX(-12vw) translateY(38vh) scale(0.39);
  }

  77.4074074074% {
    transform: translateX(40vw) translateY(47vh) scale(0.72);
  }

  77.7777777778% {
    transform: translateX(37vw) translateY(46vh) scale(0.62);
  }

  78.1481481481% {
    transform: translateX(-12vw) translateY(-32vh) scale(0.38);
  }

  78.5185185185% {
    transform: translateX(15vw) translateY(-6vh) scale(0.94);
  }

  78.8888888889% {
    transform: translateX(9vw) translateY(0vh) scale(0.52);
  }

  79.2592592593% {
    transform: translateX(12vw) translateY(34vh) scale(0.32);
  }

  79.6296296296% {
    transform: translateX(-11vw) translateY(15vh) scale(0.31);
  }

  80% {
    transform: translateX(22vw) translateY(-12vh) scale(0.81);
  }

  80.3703703704% {
    transform: translateX(1vw) translateY(-37vh) scale(0.64);
  }

  80.7407407407% {
    transform: translateX(-22vw) translateY(36vh) scale(0.98);
  }

  81.1111111111% {
    transform: translateX(18vw) translateY(23vh) scale(0.92);
  }

  81.4814814815% {
    transform: translateX(11vw) translateY(-32vh) scale(0.4);
  }

  81.8518518519% {
    transform: translateX(-14vw) translateY(-33vh) scale(0.55);
  }

  82.2222222222% {
    transform: translateX(-48vw) translateY(29vh) scale(0.52);
  }

  82.5925925926% {
    transform: translateX(44vw) translateY(-17vh) scale(0.62);
  }

  82.962962963% {
    transform: translateX(0vw) translateY(4vh) scale(0.69);
  }

  83.3333333333% {
    transform: translateX(26vw) translateY(36vh) scale(0.7);
  }

  83.7037037037% {
    transform: translateX(-1vw) translateY(21vh) scale(0.81);
  }

  84.0740740741% {
    transform: translateX(-10vw) translateY(13vh) scale(0.43);
  }

  84.4444444444% {
    transform: translateX(-33vw) translateY(20vh) scale(0.62);
  }

  84.8148148148% {
    transform: translateX(12vw) translateY(-4vh) scale(0.4);
  }

  85.1851851852% {
    transform: translateX(-35vw) translateY(-20vh) scale(0.53);
  }

  85.5555555556% {
    transform: translateX(8vw) translateY(-35vh) scale(0.55);
  }

  85.9259259259% {
    transform: translateX(-26vw) translateY(45vh) scale(0.95);
  }

  86.2962962963% {
    transform: translateX(48vw) translateY(-39vh) scale(0.4);
  }

  86.6666666667% {
    transform: translateX(15vw) translateY(-43vh) scale(0.41);
  }

  87.037037037% {
    transform: translateX(24vw) translateY(-5vh) scale(0.51);
  }

  87.4074074074% {
    transform: translateX(-9vw) translateY(35vh) scale(0.88);
  }

  87.7777777778% {
    transform: translateX(8vw) translateY(-28vh) scale(0.47);
  }

  88.1481481481% {
    transform: translateX(1vw) translateY(27vh) scale(0.78);
  }

  88.5185185185% {
    transform: translateX(-19vw) translateY(2vh) scale(0.62);
  }

  88.8888888889% {
    transform: translateX(-5vw) translateY(-8vh) scale(0.37);
  }

  89.2592592593% {
    transform: translateX(19vw) translateY(21vh) scale(0.72);
  }

  89.6296296296% {
    transform: translateX(21vw) translateY(34vh) scale(0.59);
  }

  90% {
    transform: translateX(29vw) translateY(16vh) scale(0.39);
  }

  90.3703703704% {
    transform: translateX(-35vw) translateY(35vh) scale(0.96);
  }

  90.7407407407% {
    transform: translateX(16vw) translateY(-41vh) scale(0.58);
  }

  91.1111111111% {
    transform: translateX(-46vw) translateY(-23vh) scale(0.32);
  }

  91.4814814815% {
    transform: translateX(9vw) translateY(-31vh) scale(1);
  }

  91.8518518519% {
    transform: translateX(-12vw) translateY(49vh) scale(1);
  }

  92.2222222222% {
    transform: translateX(-4vw) translateY(-17vh) scale(0.9);
  }

  92.5925925926% {
    transform: translateX(-28vw) translateY(-30vh) scale(0.45);
  }

  92.962962963% {
    transform: translateX(22vw) translateY(38vh) scale(0.43);
  }

  93.3333333333% {
    transform: translateX(25vw) translateY(-41vh) scale(0.75);
  }

  93.7037037037% {
    transform: translateX(10vw) translateY(31vh) scale(0.93);
  }

  94.0740740741% {
    transform: translateX(12vw) translateY(-45vh) scale(0.59);
  }

  94.4444444444% {
    transform: translateX(19vw) translateY(15vh) scale(0.65);
  }

  94.8148148148% {
    transform: translateX(43vw) translateY(12vh) scale(0.72);
  }

  95.1851851852% {
    transform: translateX(-6vw) translateY(-20vh) scale(0.58);
  }

  95.5555555556% {
    transform: translateX(-31vw) translateY(-24vh) scale(0.68);
  }

  95.9259259259% {
    transform: translateX(12vw) translateY(19vh) scale(0.9);
  }

  96.2962962963% {
    transform: translateX(-40vw) translateY(-20vh) scale(0.78);
  }

  96.6666666667% {
    transform: translateX(-30vw) translateY(38vh) scale(0.37);
  }

  97.037037037% {
    transform: translateX(17vw) translateY(-29vh) scale(0.87);
  }

  97.4074074074% {
    transform: translateX(-17vw) translateY(50vh) scale(0.45);
  }

  97.7777777778% {
    transform: translateX(-32vw) translateY(-24vh) scale(0.7);
  }

  98.1481481481% {
    transform: translateX(-25vw) translateY(16vh) scale(0.86);
  }

  98.5185185185% {
    transform: translateX(-32vw) translateY(47vh) scale(0.5);
  }

  98.8888888889% {
    transform: translateX(-37vw) translateY(31vh) scale(0.31);
  }

  99.2592592593% {
    transform: translateX(-13vw) translateY(-29vh) scale(0.44);
  }

  99.6296296296% {
    transform: translateX(26vw) translateY(-11vh) scale(0.5);
  }

  100% {
    transform: translateX(10vw) translateY(11vh) scale(0.78);
  }
}

.firefly:nth-child(25) {
  animation-name: move25;
}

.firefly:nth-child(25)::before {
  animation-duration: 14s;
}

.firefly:nth-child(25)::after {
  animation-duration: 14s, 7810ms;
  animation-delay: 0ms, 4261ms;
}

@keyframes move25 {
  0% {
    transform: translateX(-21vw) translateY(-8vh) scale(0.96);
  }

  0.3891050584% {
    transform: translateX(-15vw) translateY(-5vh) scale(0.57);
  }

  0.7782101167% {
    transform: translateX(21vw) translateY(26vh) scale(0.48);
  }

  1.1673151751% {
    transform: translateX(-8vw) translateY(-10vh) scale(1);
  }

  1.5564202335% {
    transform: translateX(-4vw) translateY(6vh) scale(0.3);
  }

  1.9455252918% {
    transform: translateX(-40vw) translateY(12vh) scale(0.76);
  }

  2.3346303502% {
    transform: translateX(19vw) translateY(26vh) scale(0.56);
  }

  2.7237354086% {
    transform: translateX(12vw) translateY(13vh) scale(0.42);
  }

  3.1128404669% {
    transform: translateX(32vw) translateY(-6vh) scale(0.54);
  }

  3.5019455253% {
    transform: translateX(22vw) translateY(-4vh) scale(0.26);
  }

  3.8910505837% {
    transform: translateX(-21vw) translateY(-33vh) scale(0.33);
  }

  4.280155642% {
    transform: translateX(13vw) translateY(7vh) scale(0.71);
  }

  4.6692607004% {
    transform: translateX(28vw) translateY(-40vh) scale(0.6);
  }

  5.0583657588% {
    transform: translateX(-5vw) translateY(-2vh) scale(0.97);
  }

  5.4474708171% {
    transform: translateX(-23vw) translateY(2vh) scale(0.46);
  }

  5.8365758755% {
    transform: translateX(-10vw) translateY(-21vh) scale(0.85);
  }

  6.2256809339% {
    transform: translateX(-48vw) translateY(-4vh) scale(0.84);
  }

  6.6147859922% {
    transform: translateX(-10vw) translateY(-39vh) scale(0.64);
  }

  7.0038910506% {
    transform: translateX(-13vw) translateY(17vh) scale(0.86);
  }

  7.3929961089% {
    transform: translateX(-39vw) translateY(22vh) scale(0.81);
  }

  7.7821011673% {
    transform: translateX(-30vw) translateY(22vh) scale(0.37);
  }

  8.1712062257% {
    transform: translateX(18vw) translateY(19vh) scale(0.82);
  }

  8.560311284% {
    transform: translateX(49vw) translateY(31vh) scale(0.3);
  }

  8.9494163424% {
    transform: translateX(-43vw) translateY(-37vh) scale(0.93);
  }

  9.3385214008% {
    transform: translateX(-25vw) translateY(-26vh) scale(0.74);
  }

  9.7276264591% {
    transform: translateX(2vw) translateY(-44vh) scale(1);
  }

  10.1167315175% {
    transform: translateX(27vw) translateY(9vh) scale(0.87);
  }

  10.5058365759% {
    transform: translateX(-1vw) translateY(0vh) scale(0.43);
  }

  10.8949416342% {
    transform: translateX(41vw) translateY(-23vh) scale(0.88);
  }

  11.2840466926% {
    transform: translateX(-3vw) translateY(-16vh) scale(0.42);
  }

  11.673151751% {
    transform: translateX(-9vw) translateY(-26vh) scale(0.7);
  }

  12.0622568093% {
    transform: translateX(40vw) translateY(-13vh) scale(0.83);
  }

  12.4513618677% {
    transform: translateX(-18vw) translateY(27vh) scale(0.66);
  }

  12.8404669261% {
    transform: translateX(-45vw) translateY(45vh) scale(0.87);
  }

  13.2295719844% {
    transform: translateX(-3vw) translateY(7vh) scale(0.29);
  }

  13.6186770428% {
    transform: translateX(33vw) translateY(41vh) scale(0.91);
  }

  14.0077821012% {
    transform: translateX(14vw) translateY(-40vh) scale(0.31);
  }

  14.3968871595% {
    transform: translateX(13vw) translateY(-7vh) scale(0.58);
  }

  14.7859922179% {
    transform: translateX(46vw) translateY(-20vh) scale(0.76);
  }

  15.1750972763% {
    transform: translateX(-28vw) translateY(4vh) scale(0.54);
  }

  15.5642023346% {
    transform: translateX(-30vw) translateY(6vh) scale(0.99);
  }

  15.953307393% {
    transform: translateX(32vw) translateY(41vh) scale(0.81);
  }

  16.3424124514% {
    transform: translateX(25vw) translateY(-30vh) scale(0.98);
  }

  16.7315175097% {
    transform: translateX(-31vw) translateY(40vh) scale(0.68);
  }

  17.1206225681% {
    transform: translateX(-32vw) translateY(45vh) scale(0.46);
  }

  17.5097276265% {
    transform: translateX(-25vw) translateY(11vh) scale(0.9);
  }

  17.8988326848% {
    transform: translateX(-38vw) translateY(-45vh) scale(0.89);
  }

  18.2879377432% {
    transform: translateX(22vw) translateY(46vh) scale(0.72);
  }

  18.6770428016% {
    transform: translateX(19vw) translateY(12vh) scale(0.4);
  }

  19.0661478599% {
    transform: translateX(21vw) translateY(2vh) scale(0.66);
  }

  19.4552529183% {
    transform: translateX(-10vw) translateY(-39vh) scale(0.65);
  }

  19.8443579767% {
    transform: translateX(-37vw) translateY(-43vh) scale(0.76);
  }

  20.233463035% {
    transform: translateX(14vw) translateY(-13vh) scale(0.87);
  }

  20.6225680934% {
    transform: translateX(-8vw) translateY(-31vh) scale(0.69);
  }

  21.0116731518% {
    transform: translateX(44vw) translateY(23vh) scale(0.67);
  }

  21.4007782101% {
    transform: translateX(37vw) translateY(44vh) scale(0.37);
  }

  21.7898832685% {
    transform: translateX(2vw) translateY(-41vh) scale(0.45);
  }

  22.1789883268% {
    transform: translateX(12vw) translateY(35vh) scale(0.52);
  }

  22.5680933852% {
    transform: translateX(-32vw) translateY(6vh) scale(0.34);
  }

  22.9571984436% {
    transform: translateX(10vw) translateY(34vh) scale(0.79);
  }

  23.3463035019% {
    transform: translateX(26vw) translateY(-34vh) scale(0.68);
  }

  23.7354085603% {
    transform: translateX(-1vw) translateY(-46vh) scale(0.55);
  }

  24.1245136187% {
    transform: translateX(32vw) translateY(-29vh) scale(0.59);
  }

  24.513618677% {
    transform: translateX(-38vw) translateY(50vh) scale(0.63);
  }

  24.9027237354% {
    transform: translateX(23vw) translateY(34vh) scale(0.33);
  }

  25.2918287938% {
    transform: translateX(-20vw) translateY(30vh) scale(0.74);
  }

  25.6809338521% {
    transform: translateX(-28vw) translateY(-8vh) scale(0.53);
  }

  26.0700389105% {
    transform: translateX(16vw) translateY(46vh) scale(0.84);
  }

  26.4591439689% {
    transform: translateX(-30vw) translateY(-35vh) scale(0.36);
  }

  26.8482490272% {
    transform: translateX(-4vw) translateY(26vh) scale(0.43);
  }

  27.2373540856% {
    transform: translateX(-1vw) translateY(-42vh) scale(0.89);
  }

  27.626459144% {
    transform: translateX(9vw) translateY(23vh) scale(0.63);
  }

  28.0155642023% {
    transform: translateX(-49vw) translateY(9vh) scale(0.28);
  }

  28.4046692607% {
    transform: translateX(23vw) translateY(-36vh) scale(0.84);
  }

  28.7937743191% {
    transform: translateX(26vw) translateY(-3vh) scale(0.48);
  }

  29.1828793774% {
    transform: translateX(-46vw) translateY(-45vh) scale(0.5);
  }

  29.5719844358% {
    transform: translateX(16vw) translateY(23vh) scale(0.56);
  }

  29.9610894942% {
    transform: translateX(27vw) translateY(42vh) scale(0.51);
  }

  30.3501945525% {
    transform: translateX(1vw) translateY(37vh) scale(0.99);
  }

  30.7392996109% {
    transform: translateX(-11vw) translateY(-40vh) scale(0.32);
  }

  31.1284046693% {
    transform: translateX(20vw) translateY(44vh) scale(0.94);
  }

  31.5175097276% {
    transform: translateX(30vw) translateY(32vh) scale(0.73);
  }

  31.906614786% {
    transform: translateX(24vw) translateY(-42vh) scale(0.46);
  }

  32.2957198444% {
    transform: translateX(-5vw) translateY(16vh) scale(0.74);
  }

  32.6848249027% {
    transform: translateX(9vw) translateY(10vh) scale(0.38);
  }

  33.0739299611% {
    transform: translateX(-1vw) translateY(-40vh) scale(0.45);
  }

  33.4630350195% {
    transform: translateX(34vw) translateY(-18vh) scale(0.93);
  }

  33.8521400778% {
    transform: translateX(-13vw) translateY(-11vh) scale(0.53);
  }

  34.2412451362% {
    transform: translateX(11vw) translateY(38vh) scale(0.46);
  }

  34.6303501946% {
    transform: translateX(14vw) translateY(1vh) scale(0.51);
  }

  35.0194552529% {
    transform: translateX(-2vw) translateY(-7vh) scale(0.99);
  }

  35.4085603113% {
    transform: translateX(44vw) translateY(-7vh) scale(1);
  }

  35.7976653696% {
    transform: translateX(4vw) translateY(-17vh) scale(0.35);
  }

  36.186770428% {
    transform: translateX(2vw) translateY(-21vh) scale(0.84);
  }

  36.5758754864% {
    transform: translateX(26vw) translateY(7vh) scale(0.66);
  }

  36.9649805447% {
    transform: translateX(-21vw) translateY(38vh) scale(0.98);
  }

  37.3540856031% {
    transform: translateX(-2vw) translateY(-5vh) scale(0.93);
  }

  37.7431906615% {
    transform: translateX(4vw) translateY(5vh) scale(0.92);
  }

  38.1322957198% {
    transform: translateX(45vw) translateY(38vh) scale(0.58);
  }

  38.5214007782% {
    transform: translateX(26vw) translateY(1vh) scale(0.83);
  }

  38.9105058366% {
    transform: translateX(2vw) translateY(-32vh) scale(0.59);
  }

  39.2996108949% {
    transform: translateX(-9vw) translateY(43vh) scale(0.99);
  }

  39.6887159533% {
    transform: translateX(40vw) translateY(-14vh) scale(0.28);
  }

  40.0778210117% {
    transform: translateX(-3vw) translateY(13vh) scale(0.64);
  }

  40.46692607% {
    transform: translateX(42vw) translateY(33vh) scale(0.31);
  }

  40.8560311284% {
    transform: translateX(-46vw) translateY(20vh) scale(0.82);
  }

  41.2451361868% {
    transform: translateX(-44vw) translateY(17vh) scale(0.88);
  }

  41.6342412451% {
    transform: translateX(15vw) translateY(-10vh) scale(0.65);
  }

  42.0233463035% {
    transform: translateX(9vw) translateY(-12vh) scale(0.55);
  }

  42.4124513619% {
    transform: translateX(-14vw) translateY(16vh) scale(0.66);
  }

  42.8015564202% {
    transform: translateX(39vw) translateY(33vh) scale(0.56);
  }

  43.1906614786% {
    transform: translateX(10vw) translateY(38vh) scale(0.82);
  }

  43.579766537% {
    transform: translateX(8vw) translateY(38vh) scale(0.33);
  }

  43.9688715953% {
    transform: translateX(5vw) translateY(-33vh) scale(0.74);
  }

  44.3579766537% {
    transform: translateX(3vw) translateY(5vh) scale(0.97);
  }

  44.7470817121% {
    transform: translateX(29vw) translateY(43vh) scale(0.66);
  }

  45.1361867704% {
    transform: translateX(-27vw) translateY(-11vh) scale(0.7);
  }

  45.5252918288% {
    transform: translateX(49vw) translateY(-18vh) scale(0.77);
  }

  45.9143968872% {
    transform: translateX(-30vw) translateY(18vh) scale(0.63);
  }

  46.3035019455% {
    transform: translateX(-29vw) translateY(4vh) scale(0.55);
  }

  46.6926070039% {
    transform: translateX(42vw) translateY(-10vh) scale(0.75);
  }

  47.0817120623% {
    transform: translateX(-26vw) translateY(-27vh) scale(0.38);
  }

  47.4708171206% {
    transform: translateX(21vw) translateY(-20vh) scale(0.66);
  }

  47.859922179% {
    transform: translateX(39vw) translateY(39vh) scale(0.83);
  }

  48.2490272374% {
    transform: translateX(-38vw) translateY(12vh) scale(0.89);
  }

  48.6381322957% {
    transform: translateX(18vw) translateY(17vh) scale(0.51);
  }

  49.0272373541% {
    transform: translateX(24vw) translateY(-44vh) scale(0.84);
  }

  49.4163424125% {
    transform: translateX(22vw) translateY(-38vh) scale(0.52);
  }

  49.8054474708% {
    transform: translateX(-39vw) translateY(-33vh) scale(0.66);
  }

  50.1945525292% {
    transform: translateX(-6vw) translateY(-4vh) scale(0.26);
  }

  50.5836575875% {
    transform: translateX(-28vw) translateY(36vh) scale(0.75);
  }

  50.9727626459% {
    transform: translateX(-46vw) translateY(-13vh) scale(0.75);
  }

  51.3618677043% {
    transform: translateX(-31vw) translateY(12vh) scale(0.53);
  }

  51.7509727626% {
    transform: translateX(50vw) translateY(0vh) scale(1);
  }

  52.140077821% {
    transform: translateX(-3vw) translateY(19vh) scale(0.39);
  }

  52.5291828794% {
    transform: translateX(43vw) translateY(-31vh) scale(0.44);
  }

  52.9182879377% {
    transform: translateX(-40vw) translateY(31vh) scale(0.48);
  }

  53.3073929961% {
    transform: translateX(-1vw) translateY(-7vh) scale(0.3);
  }

  53.6964980545% {
    transform: translateX(-35vw) translateY(44vh) scale(0.86);
  }

  54.0856031128% {
    transform: translateX(-32vw) translateY(-31vh) scale(0.42);
  }

  54.4747081712% {
    transform: translateX(-25vw) translateY(49vh) scale(0.61);
  }

  54.8638132296% {
    transform: translateX(10vw) translateY(6vh) scale(0.37);
  }

  55.2529182879% {
    transform: translateX(-46vw) translateY(-41vh) scale(0.57);
  }

  55.6420233463% {
    transform: translateX(-15vw) translateY(-14vh) scale(0.4);
  }

  56.0311284047% {
    transform: translateX(-44vw) translateY(20vh) scale(0.35);
  }

  56.420233463% {
    transform: translateX(-46vw) translateY(40vh) scale(0.59);
  }

  56.8093385214% {
    transform: translateX(-42vw) translateY(50vh) scale(0.28);
  }

  57.1984435798% {
    transform: translateX(32vw) translateY(45vh) scale(0.92);
  }

  57.5875486381% {
    transform: translateX(27vw) translateY(-43vh) scale(0.53);
  }

  57.9766536965% {
    transform: translateX(-37vw) translateY(-26vh) scale(0.73);
  }

  58.3657587549% {
    transform: translateX(-14vw) translateY(32vh) scale(0.51);
  }

  58.7548638132% {
    transform: translateX(-46vw) translateY(-7vh) scale(0.81);
  }

  59.1439688716% {
    transform: translateX(25vw) translateY(11vh) scale(0.36);
  }

  59.53307393% {
    transform: translateX(-3vw) translateY(-23vh) scale(0.9);
  }

  59.9221789883% {
    transform: translateX(-40vw) translateY(45vh) scale(0.32);
  }

  60.3112840467% {
    transform: translateX(13vw) translateY(27vh) scale(0.9);
  }

  60.7003891051% {
    transform: translateX(-26vw) translateY(24vh) scale(0.38);
  }

  61.0894941634% {
    transform: translateX(36vw) translateY(8vh) scale(0.57);
  }

  61.4785992218% {
    transform: translateX(-16vw) translateY(-28vh) scale(0.71);
  }

  61.8677042802% {
    transform: translateX(1vw) translateY(43vh) scale(0.58);
  }

  62.2568093385% {
    transform: translateX(30vw) translateY(-25vh) scale(0.87);
  }

  62.6459143969% {
    transform: translateX(-23vw) translateY(43vh) scale(0.65);
  }

  63.0350194553% {
    transform: translateX(-12vw) translateY(-5vh) scale(0.83);
  }

  63.4241245136% {
    transform: translateX(-45vw) translateY(-16vh) scale(0.55);
  }

  63.813229572% {
    transform: translateX(26vw) translateY(-8vh) scale(0.99);
  }

  64.2023346304% {
    transform: translateX(38vw) translateY(-31vh) scale(0.81);
  }

  64.5914396887% {
    transform: translateX(20vw) translateY(-31vh) scale(0.95);
  }

  64.9805447471% {
    transform: translateX(22vw) translateY(4vh) scale(0.65);
  }

  65.3696498054% {
    transform: translateX(-23vw) translateY(-33vh) scale(0.67);
  }

  65.7587548638% {
    transform: translateX(28vw) translateY(45vh) scale(0.49);
  }

  66.1478599222% {
    transform: translateX(5vw) translateY(-45vh) scale(0.62);
  }

  66.5369649805% {
    transform: translateX(25vw) translateY(4vh) scale(0.56);
  }

  66.9260700389% {
    transform: translateX(-32vw) translateY(44vh) scale(0.79);
  }

  67.3151750973% {
    transform: translateX(25vw) translateY(-47vh) scale(0.93);
  }

  67.7042801556% {
    transform: translateX(20vw) translateY(-24vh) scale(0.48);
  }

  68.093385214% {
    transform: translateX(43vw) translateY(-9vh) scale(0.75);
  }

  68.4824902724% {
    transform: translateX(40vw) translateY(10vh) scale(0.32);
  }

  68.8715953307% {
    transform: translateX(-29vw) translateY(-19vh) scale(0.59);
  }

  69.2607003891% {
    transform: translateX(48vw) translateY(-27vh) scale(0.76);
  }

  69.6498054475% {
    transform: translateX(-8vw) translateY(42vh) scale(0.47);
  }

  70.0389105058% {
    transform: translateX(20vw) translateY(40vh) scale(0.69);
  }

  70.4280155642% {
    transform: translateX(13vw) translateY(-6vh) scale(0.66);
  }

  70.8171206226% {
    transform: translateX(12vw) translateY(-19vh) scale(0.99);
  }

  71.2062256809% {
    transform: translateX(-40vw) translateY(-7vh) scale(0.99);
  }

  71.5953307393% {
    transform: translateX(-47vw) translateY(41vh) scale(0.8);
  }

  71.9844357977% {
    transform: translateX(-18vw) translateY(-5vh) scale(0.72);
  }

  72.373540856% {
    transform: translateX(31vw) translateY(-39vh) scale(0.27);
  }

  72.7626459144% {
    transform: translateX(25vw) translateY(-12vh) scale(0.89);
  }

  73.1517509728% {
    transform: translateX(3vw) translateY(9vh) scale(0.54);
  }

  73.5408560311% {
    transform: translateX(-12vw) translateY(6vh) scale(0.43);
  }

  73.9299610895% {
    transform: translateX(9vw) translateY(-14vh) scale(0.65);
  }

  74.3190661479% {
    transform: translateX(6vw) translateY(-49vh) scale(0.82);
  }

  74.7081712062% {
    transform: translateX(-31vw) translateY(42vh) scale(0.44);
  }

  75.0972762646% {
    transform: translateX(-36vw) translateY(-6vh) scale(0.94);
  }

  75.486381323% {
    transform: translateX(38vw) translateY(44vh) scale(0.95);
  }

  75.8754863813% {
    transform: translateX(33vw) translateY(-1vh) scale(0.37);
  }

  76.2645914397% {
    transform: translateX(19vw) translateY(42vh) scale(0.81);
  }

  76.6536964981% {
    transform: translateX(-22vw) translateY(27vh) scale(0.39);
  }

  77.0428015564% {
    transform: translateX(-14vw) translateY(22vh) scale(0.42);
  }

  77.4319066148% {
    transform: translateX(42vw) translateY(50vh) scale(0.73);
  }

  77.8210116732% {
    transform: translateX(7vw) translateY(-18vh) scale(0.34);
  }

  78.2101167315% {
    transform: translateX(37vw) translateY(-17vh) scale(0.64);
  }

  78.5992217899% {
    transform: translateX(26vw) translateY(41vh) scale(0.74);
  }

  78.9883268482% {
    transform: translateX(24vw) translateY(-49vh) scale(0.7);
  }

  79.3774319066% {
    transform: translateX(29vw) translateY(18vh) scale(0.3);
  }

  79.766536965% {
    transform: translateX(-24vw) translateY(24vh) scale(0.64);
  }

  80.1556420233% {
    transform: translateX(49vw) translateY(-10vh) scale(0.44);
  }

  80.5447470817% {
    transform: translateX(17vw) translateY(-20vh) scale(0.7);
  }

  80.9338521401% {
    transform: translateX(30vw) translateY(16vh) scale(0.26);
  }

  81.3229571984% {
    transform: translateX(-6vw) translateY(50vh) scale(0.77);
  }

  81.7120622568% {
    transform: translateX(-11vw) translateY(19vh) scale(0.57);
  }

  82.1011673152% {
    transform: translateX(38vw) translateY(-37vh) scale(0.67);
  }

  82.4902723735% {
    transform: translateX(11vw) translateY(40vh) scale(0.51);
  }

  82.8793774319% {
    transform: translateX(21vw) translateY(-25vh) scale(0.79);
  }

  83.2684824903% {
    transform: translateX(5vw) translateY(23vh) scale(0.54);
  }

  83.6575875486% {
    transform: translateX(40vw) translateY(-26vh) scale(0.6);
  }

  84.046692607% {
    transform: translateX(41vw) translateY(-26vh) scale(0.73);
  }

  84.4357976654% {
    transform: translateX(-37vw) translateY(17vh) scale(0.97);
  }

  84.8249027237% {
    transform: translateX(-27vw) translateY(-27vh) scale(0.96);
  }

  85.2140077821% {
    transform: translateX(-21vw) translateY(-46vh) scale(0.89);
  }

  85.6031128405% {
    transform: translateX(5vw) translateY(-39vh) scale(0.56);
  }

  85.9922178988% {
    transform: translateX(-39vw) translateY(42vh) scale(0.92);
  }

  86.3813229572% {
    transform: translateX(19vw) translateY(-43vh) scale(0.75);
  }

  86.7704280156% {
    transform: translateX(18vw) translateY(23vh) scale(0.59);
  }

  87.1595330739% {
    transform: translateX(33vw) translateY(41vh) scale(0.99);
  }

  87.5486381323% {
    transform: translateX(-32vw) translateY(5vh) scale(0.67);
  }

  87.9377431907% {
    transform: translateX(-14vw) translateY(-47vh) scale(0.49);
  }

  88.326848249% {
    transform: translateX(-18vw) translateY(-11vh) scale(0.75);
  }

  88.7159533074% {
    transform: translateX(-47vw) translateY(14vh) scale(0.71);
  }

  89.1050583658% {
    transform: translateX(27vw) translateY(18vh) scale(0.54);
  }

  89.4941634241% {
    transform: translateX(12vw) translateY(-43vh) scale(0.4);
  }

  89.8832684825% {
    transform: translateX(37vw) translateY(10vh) scale(0.27);
  }

  90.2723735409% {
    transform: translateX(16vw) translateY(-27vh) scale(0.75);
  }

  90.6614785992% {
    transform: translateX(1vw) translateY(41vh) scale(0.46);
  }

  91.0505836576% {
    transform: translateX(24vw) translateY(34vh) scale(0.33);
  }

  91.439688716% {
    transform: translateX(20vw) translateY(21vh) scale(0.53);
  }

  91.8287937743% {
    transform: translateX(42vw) translateY(33vh) scale(0.35);
  }

  92.2178988327% {
    transform: translateX(-48vw) translateY(13vh) scale(0.49);
  }

  92.6070038911% {
    transform: translateX(-3vw) translateY(25vh) scale(0.43);
  }

  92.9961089494% {
    transform: translateX(-14vw) translateY(-22vh) scale(0.64);
  }

  93.3852140078% {
    transform: translateX(41vw) translateY(5vh) scale(0.53);
  }

  93.7743190661% {
    transform: translateX(-12vw) translateY(-46vh) scale(0.69);
  }

  94.1634241245% {
    transform: translateX(0vw) translateY(-8vh) scale(0.48);
  }

  94.5525291829% {
    transform: translateX(12vw) translateY(-16vh) scale(0.52);
  }

  94.9416342412% {
    transform: translateX(6vw) translateY(18vh) scale(0.35);
  }

  95.3307392996% {
    transform: translateX(9vw) translateY(-31vh) scale(0.35);
  }

  95.719844358% {
    transform: translateX(-27vw) translateY(-16vh) scale(0.31);
  }

  96.1089494163% {
    transform: translateX(45vw) translateY(-17vh) scale(0.97);
  }

  96.4980544747% {
    transform: translateX(14vw) translateY(32vh) scale(0.78);
  }

  96.8871595331% {
    transform: translateX(29vw) translateY(41vh) scale(0.5);
  }

  97.2762645914% {
    transform: translateX(-36vw) translateY(24vh) scale(0.93);
  }

  97.6653696498% {
    transform: translateX(48vw) translateY(34vh) scale(0.59);
  }

  98.0544747082% {
    transform: translateX(19vw) translateY(-49vh) scale(0.97);
  }

  98.4435797665% {
    transform: translateX(19vw) translateY(-20vh) scale(0.88);
  }

  98.8326848249% {
    transform: translateX(49vw) translateY(38vh) scale(1);
  }

  99.2217898833% {
    transform: translateX(25vw) translateY(12vh) scale(0.86);
  }

  99.6108949416% {
    transform: translateX(-31vw) translateY(-30vh) scale(0.51);
  }

  100% {
    transform: translateX(-31vw) translateY(-14vh) scale(0.65);
  }
}

.firefly:nth-child(26) {
  animation-name: move26;
}

.firefly:nth-child(26)::before {
  animation-duration: 14s;
}

.firefly:nth-child(26)::after {
  animation-duration: 14s, 6213ms;
  animation-delay: 0ms, 3463ms;
}

@keyframes move26 {
  0% {
    transform: translateX(-49vw) translateY(-8vh) scale(0.58);
  }

  0.4201680672% {
    transform: translateX(21vw) translateY(-3vh) scale(0.87);
  }

  0.8403361345% {
    transform: translateX(-36vw) translateY(33vh) scale(0.56);
  }

  1.2605042017% {
    transform: translateX(-49vw) translateY(-35vh) scale(0.42);
  }

  1.6806722689% {
    transform: translateX(-29vw) translateY(-29vh) scale(0.47);
  }

  2.1008403361% {
    transform: translateX(1vw) translateY(-43vh) scale(0.34);
  }

  2.5210084034% {
    transform: translateX(-20vw) translateY(49vh) scale(0.76);
  }

  2.9411764706% {
    transform: translateX(6vw) translateY(13vh) scale(0.96);
  }

  3.3613445378% {
    transform: translateX(-32vw) translateY(36vh) scale(0.29);
  }

  3.781512605% {
    transform: translateX(-14vw) translateY(24vh) scale(0.7);
  }

  4.2016806723% {
    transform: translateX(18vw) translateY(12vh) scale(0.89);
  }

  4.6218487395% {
    transform: translateX(20vw) translateY(40vh) scale(0.5);
  }

  5.0420168067% {
    transform: translateX(42vw) translateY(-22vh) scale(0.4);
  }

  5.4621848739% {
    transform: translateX(19vw) translateY(25vh) scale(0.95);
  }

  5.8823529412% {
    transform: translateX(48vw) translateY(42vh) scale(0.29);
  }

  6.3025210084% {
    transform: translateX(5vw) translateY(38vh) scale(0.26);
  }

  6.7226890756% {
    transform: translateX(-27vw) translateY(7vh) scale(0.94);
  }

  7.1428571429% {
    transform: translateX(-46vw) translateY(31vh) scale(0.71);
  }

  7.5630252101% {
    transform: translateX(-11vw) translateY(27vh) scale(0.52);
  }

  7.9831932773% {
    transform: translateX(20vw) translateY(28vh) scale(0.62);
  }

  8.4033613445% {
    transform: translateX(17vw) translateY(-29vh) scale(0.62);
  }

  8.8235294118% {
    transform: translateX(-45vw) translateY(-17vh) scale(0.44);
  }

  9.243697479% {
    transform: translateX(32vw) translateY(22vh) scale(0.5);
  }

  9.6638655462% {
    transform: translateX(20vw) translateY(10vh) scale(0.5);
  }

  10.0840336134% {
    transform: translateX(7vw) translateY(10vh) scale(0.87);
  }

  10.5042016807% {
    transform: translateX(-8vw) translateY(-44vh) scale(0.47);
  }

  10.9243697479% {
    transform: translateX(49vw) translateY(19vh) scale(0.37);
  }

  11.3445378151% {
    transform: translateX(2vw) translateY(-29vh) scale(0.54);
  }

  11.7647058824% {
    transform: translateX(-26vw) translateY(13vh) scale(0.48);
  }

  12.1848739496% {
    transform: translateX(-18vw) translateY(-28vh) scale(0.68);
  }

  12.6050420168% {
    transform: translateX(9vw) translateY(22vh) scale(0.6);
  }

  13.025210084% {
    transform: translateX(21vw) translateY(49vh) scale(0.58);
  }

  13.4453781513% {
    transform: translateX(-39vw) translateY(-46vh) scale(0.87);
  }

  13.8655462185% {
    transform: translateX(-29vw) translateY(-34vh) scale(0.79);
  }

  14.2857142857% {
    transform: translateX(-9vw) translateY(49vh) scale(0.26);
  }

  14.7058823529% {
    transform: translateX(48vw) translateY(11vh) scale(0.86);
  }

  15.1260504202% {
    transform: translateX(19vw) translateY(38vh) scale(0.53);
  }

  15.5462184874% {
    transform: translateX(26vw) translateY(16vh) scale(0.87);
  }

  15.9663865546% {
    transform: translateX(30vw) translateY(37vh) scale(0.38);
  }

  16.3865546218% {
    transform: translateX(36vw) translateY(50vh) scale(0.37);
  }

  16.8067226891% {
    transform: translateX(18vw) translateY(21vh) scale(0.84);
  }

  17.2268907563% {
    transform: translateX(-18vw) translateY(7vh) scale(0.45);
  }

  17.6470588235% {
    transform: translateX(-41vw) translateY(-5vh) scale(0.97);
  }

  18.0672268908% {
    transform: translateX(14vw) translateY(10vh) scale(0.99);
  }

  18.487394958% {
    transform: translateX(29vw) translateY(-20vh) scale(0.72);
  }

  18.9075630252% {
    transform: translateX(21vw) translateY(19vh) scale(1);
  }

  19.3277310924% {
    transform: translateX(-3vw) translateY(-43vh) scale(0.39);
  }

  19.7478991597% {
    transform: translateX(4vw) translateY(8vh) scale(0.59);
  }

  20.1680672269% {
    transform: translateX(-27vw) translateY(-20vh) scale(0.42);
  }

  20.5882352941% {
    transform: translateX(24vw) translateY(-19vh) scale(0.66);
  }

  21.0084033613% {
    transform: translateX(-12vw) translateY(-28vh) scale(0.81);
  }

  21.4285714286% {
    transform: translateX(30vw) translateY(3vh) scale(0.91);
  }

  21.8487394958% {
    transform: translateX(3vw) translateY(30vh) scale(0.37);
  }

  22.268907563% {
    transform: translateX(-31vw) translateY(40vh) scale(0.73);
  }

  22.6890756303% {
    transform: translateX(31vw) translateY(7vh) scale(0.67);
  }

  23.1092436975% {
    transform: translateX(-44vw) translateY(-48vh) scale(0.69);
  }

  23.5294117647% {
    transform: translateX(21vw) translateY(19vh) scale(0.93);
  }

  23.9495798319% {
    transform: translateX(-32vw) translateY(35vh) scale(0.7);
  }

  24.3697478992% {
    transform: translateX(4vw) translateY(-14vh) scale(0.3);
  }

  24.7899159664% {
    transform: translateX(-35vw) translateY(-8vh) scale(0.59);
  }

  25.2100840336% {
    transform: translateX(-8vw) translateY(-3vh) scale(0.26);
  }

  25.6302521008% {
    transform: translateX(13vw) translateY(-36vh) scale(0.94);
  }

  26.0504201681% {
    transform: translateX(-36vw) translateY(43vh) scale(0.64);
  }

  26.4705882353% {
    transform: translateX(44vw) translateY(-8vh) scale(0.72);
  }

  26.8907563025% {
    transform: translateX(16vw) translateY(5vh) scale(0.61);
  }

  27.3109243697% {
    transform: translateX(-28vw) translateY(22vh) scale(0.67);
  }

  27.731092437% {
    transform: translateX(-31vw) translateY(-38vh) scale(0.68);
  }

  28.1512605042% {
    transform: translateX(-48vw) translateY(-30vh) scale(0.68);
  }

  28.5714285714% {
    transform: translateX(-28vw) translateY(12vh) scale(0.64);
  }

  28.9915966387% {
    transform: translateX(22vw) translateY(-4vh) scale(0.69);
  }

  29.4117647059% {
    transform: translateX(-30vw) translateY(16vh) scale(0.86);
  }

  29.8319327731% {
    transform: translateX(-11vw) translateY(-30vh) scale(0.47);
  }

  30.2521008403% {
    transform: translateX(-11vw) translateY(-47vh) scale(0.7);
  }

  30.6722689076% {
    transform: translateX(5vw) translateY(-14vh) scale(0.29);
  }

  31.0924369748% {
    transform: translateX(-10vw) translateY(22vh) scale(0.43);
  }

  31.512605042% {
    transform: translateX(-4vw) translateY(-47vh) scale(0.33);
  }

  31.9327731092% {
    transform: translateX(14vw) translateY(39vh) scale(0.69);
  }

  32.3529411765% {
    transform: translateX(0vw) translateY(34vh) scale(0.62);
  }

  32.7731092437% {
    transform: translateX(9vw) translateY(24vh) scale(0.26);
  }

  33.1932773109% {
    transform: translateX(40vw) translateY(-8vh) scale(0.62);
  }

  33.6134453782% {
    transform: translateX(-42vw) translateY(-29vh) scale(0.57);
  }

  34.0336134454% {
    transform: translateX(31vw) translateY(26vh) scale(0.29);
  }

  34.4537815126% {
    transform: translateX(40vw) translateY(-34vh) scale(0.48);
  }

  34.8739495798% {
    transform: translateX(-18vw) translateY(2vh) scale(0.68);
  }

  35.2941176471% {
    transform: translateX(3vw) translateY(-7vh) scale(0.65);
  }

  35.7142857143% {
    transform: translateX(-19vw) translateY(12vh) scale(0.44);
  }

  36.1344537815% {
    transform: translateX(33vw) translateY(3vh) scale(0.65);
  }

  36.5546218487% {
    transform: translateX(7vw) translateY(37vh) scale(0.87);
  }

  36.974789916% {
    transform: translateX(-16vw) translateY(-16vh) scale(0.5);
  }

  37.3949579832% {
    transform: translateX(-18vw) translateY(-11vh) scale(0.68);
  }

  37.8151260504% {
    transform: translateX(5vw) translateY(11vh) scale(0.37);
  }

  38.2352941176% {
    transform: translateX(-4vw) translateY(-18vh) scale(0.8);
  }

  38.6554621849% {
    transform: translateX(-37vw) translateY(-33vh) scale(0.26);
  }

  39.0756302521% {
    transform: translateX(-23vw) translateY(-14vh) scale(0.84);
  }

  39.4957983193% {
    transform: translateX(15vw) translateY(48vh) scale(0.54);
  }

  39.9159663866% {
    transform: translateX(-8vw) translateY(17vh) scale(0.81);
  }

  40.3361344538% {
    transform: translateX(37vw) translateY(-12vh) scale(0.57);
  }

  40.756302521% {
    transform: translateX(-7vw) translateY(-8vh) scale(0.73);
  }

  41.1764705882% {
    transform: translateX(-30vw) translateY(20vh) scale(0.56);
  }

  41.5966386555% {
    transform: translateX(35vw) translateY(28vh) scale(0.98);
  }

  42.0168067227% {
    transform: translateX(-20vw) translateY(13vh) scale(0.83);
  }

  42.4369747899% {
    transform: translateX(-4vw) translateY(-2vh) scale(0.89);
  }

  42.8571428571% {
    transform: translateX(-26vw) translateY(-47vh) scale(0.89);
  }

  43.2773109244% {
    transform: translateX(-12vw) translateY(0vh) scale(0.5);
  }

  43.6974789916% {
    transform: translateX(49vw) translateY(-15vh) scale(0.61);
  }

  44.1176470588% {
    transform: translateX(5vw) translateY(19vh) scale(0.53);
  }

  44.5378151261% {
    transform: translateX(38vw) translateY(15vh) scale(0.66);
  }

  44.9579831933% {
    transform: translateX(-15vw) translateY(21vh) scale(0.8);
  }

  45.3781512605% {
    transform: translateX(24vw) translateY(-4vh) scale(0.95);
  }

  45.7983193277% {
    transform: translateX(-20vw) translateY(-30vh) scale(0.34);
  }

  46.218487395% {
    transform: translateX(-46vw) translateY(-11vh) scale(0.65);
  }

  46.6386554622% {
    transform: translateX(-18vw) translateY(-10vh) scale(0.31);
  }

  47.0588235294% {
    transform: translateX(3vw) translateY(40vh) scale(0.46);
  }

  47.4789915966% {
    transform: translateX(10vw) translateY(35vh) scale(0.93);
  }

  47.8991596639% {
    transform: translateX(-20vw) translateY(-10vh) scale(0.77);
  }

  48.3193277311% {
    transform: translateX(30vw) translateY(20vh) scale(0.26);
  }

  48.7394957983% {
    transform: translateX(6vw) translateY(-40vh) scale(0.88);
  }

  49.1596638655% {
    transform: translateX(-28vw) translateY(-36vh) scale(0.37);
  }

  49.5798319328% {
    transform: translateX(44vw) translateY(-14vh) scale(0.95);
  }

  50% {
    transform: translateX(19vw) translateY(-4vh) scale(0.67);
  }

  50.4201680672% {
    transform: translateX(42vw) translateY(16vh) scale(0.96);
  }

  50.8403361345% {
    transform: translateX(38vw) translateY(-40vh) scale(0.91);
  }

  51.2605042017% {
    transform: translateX(-9vw) translateY(-36vh) scale(0.5);
  }

  51.6806722689% {
    transform: translateX(13vw) translateY(9vh) scale(0.66);
  }

  52.1008403361% {
    transform: translateX(-35vw) translateY(31vh) scale(0.83);
  }

  52.5210084034% {
    transform: translateX(17vw) translateY(-10vh) scale(0.35);
  }

  52.9411764706% {
    transform: translateX(-9vw) translateY(31vh) scale(0.43);
  }

  53.3613445378% {
    transform: translateX(-15vw) translateY(20vh) scale(0.84);
  }

  53.781512605% {
    transform: translateX(30vw) translateY(4vh) scale(0.69);
  }

  54.2016806723% {
    transform: translateX(37vw) translateY(-14vh) scale(0.69);
  }

  54.6218487395% {
    transform: translateX(-24vw) translateY(-21vh) scale(0.7);
  }

  55.0420168067% {
    transform: translateX(5vw) translateY(-46vh) scale(0.9);
  }

  55.4621848739% {
    transform: translateX(-44vw) translateY(-6vh) scale(0.9);
  }

  55.8823529412% {
    transform: translateX(-20vw) translateY(-12vh) scale(0.81);
  }

  56.3025210084% {
    transform: translateX(28vw) translateY(27vh) scale(0.6);
  }

  56.7226890756% {
    transform: translateX(-26vw) translateY(6vh) scale(0.69);
  }

  57.1428571429% {
    transform: translateX(35vw) translateY(9vh) scale(0.74);
  }

  57.5630252101% {
    transform: translateX(-30vw) translateY(3vh) scale(0.8);
  }

  57.9831932773% {
    transform: translateX(8vw) translateY(44vh) scale(0.82);
  }

  58.4033613445% {
    transform: translateX(31vw) translateY(-12vh) scale(0.81);
  }

  58.8235294118% {
    transform: translateX(-5vw) translateY(14vh) scale(0.91);
  }

  59.243697479% {
    transform: translateX(17vw) translateY(-9vh) scale(0.72);
  }

  59.6638655462% {
    transform: translateX(5vw) translateY(21vh) scale(0.41);
  }

  60.0840336134% {
    transform: translateX(-9vw) translateY(-1vh) scale(0.3);
  }

  60.5042016807% {
    transform: translateX(-48vw) translateY(46vh) scale(0.3);
  }

  60.9243697479% {
    transform: translateX(2vw) translateY(23vh) scale(0.27);
  }

  61.3445378151% {
    transform: translateX(-5vw) translateY(1vh) scale(0.83);
  }

  61.7647058824% {
    transform: translateX(-19vw) translateY(-36vh) scale(0.32);
  }

  62.1848739496% {
    transform: translateX(-22vw) translateY(5vh) scale(0.3);
  }

  62.6050420168% {
    transform: translateX(-42vw) translateY(0vh) scale(0.62);
  }

  63.025210084% {
    transform: translateX(-21vw) translateY(12vh) scale(0.77);
  }

  63.4453781513% {
    transform: translateX(-7vw) translateY(-49vh) scale(0.86);
  }

  63.8655462185% {
    transform: translateX(5vw) translateY(30vh) scale(0.89);
  }

  64.2857142857% {
    transform: translateX(-30vw) translateY(-38vh) scale(0.71);
  }

  64.7058823529% {
    transform: translateX(36vw) translateY(22vh) scale(0.54);
  }

  65.1260504202% {
    transform: translateX(19vw) translateY(12vh) scale(0.62);
  }

  65.5462184874% {
    transform: translateX(44vw) translateY(0vh) scale(0.57);
  }

  65.9663865546% {
    transform: translateX(17vw) translateY(-13vh) scale(0.4);
  }

  66.3865546218% {
    transform: translateX(48vw) translateY(-23vh) scale(0.5);
  }

  66.8067226891% {
    transform: translateX(-40vw) translateY(-21vh) scale(0.89);
  }

  67.2268907563% {
    transform: translateX(36vw) translateY(-16vh) scale(0.48);
  }

  67.6470588235% {
    transform: translateX(7vw) translateY(45vh) scale(0.81);
  }

  68.0672268908% {
    transform: translateX(-23vw) translateY(41vh) scale(1);
  }

  68.487394958% {
    transform: translateX(12vw) translateY(-3vh) scale(0.44);
  }

  68.9075630252% {
    transform: translateX(-38vw) translateY(-34vh) scale(0.61);
  }

  69.3277310924% {
    transform: translateX(-17vw) translateY(-28vh) scale(0.73);
  }

  69.7478991597% {
    transform: translateX(-39vw) translateY(6vh) scale(0.39);
  }

  70.1680672269% {
    transform: translateX(36vw) translateY(-14vh) scale(0.5);
  }

  70.5882352941% {
    transform: translateX(-14vw) translateY(-12vh) scale(0.58);
  }

  71.0084033613% {
    transform: translateX(27vw) translateY(27vh) scale(0.43);
  }

  71.4285714286% {
    transform: translateX(25vw) translateY(21vh) scale(0.3);
  }

  71.8487394958% {
    transform: translateX(22vw) translateY(16vh) scale(0.94);
  }

  72.268907563% {
    transform: translateX(45vw) translateY(18vh) scale(0.51);
  }

  72.6890756303% {
    transform: translateX(-28vw) translateY(-5vh) scale(0.65);
  }

  73.1092436975% {
    transform: translateX(-10vw) translateY(-5vh) scale(0.57);
  }

  73.5294117647% {
    transform: translateX(-2vw) translateY(41vh) scale(0.67);
  }

  73.9495798319% {
    transform: translateX(-15vw) translateY(-48vh) scale(0.68);
  }

  74.3697478992% {
    transform: translateX(12vw) translateY(36vh) scale(0.94);
  }

  74.7899159664% {
    transform: translateX(-48vw) translateY(10vh) scale(0.61);
  }

  75.2100840336% {
    transform: translateX(39vw) translateY(27vh) scale(0.94);
  }

  75.6302521008% {
    transform: translateX(-29vw) translateY(-8vh) scale(0.92);
  }

  76.0504201681% {
    transform: translateX(47vw) translateY(9vh) scale(0.31);
  }

  76.4705882353% {
    transform: translateX(-9vw) translateY(-31vh) scale(0.4);
  }

  76.8907563025% {
    transform: translateX(-44vw) translateY(-48vh) scale(0.49);
  }

  77.3109243697% {
    transform: translateX(-6vw) translateY(-43vh) scale(0.56);
  }

  77.731092437% {
    transform: translateX(-39vw) translateY(-18vh) scale(0.93);
  }

  78.1512605042% {
    transform: translateX(15vw) translateY(31vh) scale(0.96);
  }

  78.5714285714% {
    transform: translateX(-11vw) translateY(10vh) scale(0.39);
  }

  78.9915966387% {
    transform: translateX(33vw) translateY(-44vh) scale(0.78);
  }

  79.4117647059% {
    transform: translateX(-46vw) translateY(45vh) scale(0.92);
  }

  79.8319327731% {
    transform: translateX(50vw) translateY(-26vh) scale(0.29);
  }

  80.2521008403% {
    transform: translateX(-38vw) translateY(-31vh) scale(0.7);
  }

  80.6722689076% {
    transform: translateX(-8vw) translateY(14vh) scale(0.56);
  }

  81.0924369748% {
    transform: translateX(10vw) translateY(-48vh) scale(0.32);
  }

  81.512605042% {
    transform: translateX(-16vw) translateY(-33vh) scale(0.49);
  }

  81.9327731092% {
    transform: translateX(3vw) translateY(-21vh) scale(0.32);
  }

  82.3529411765% {
    transform: translateX(29vw) translateY(-6vh) scale(0.94);
  }

  82.7731092437% {
    transform: translateX(38vw) translateY(-49vh) scale(0.49);
  }

  83.1932773109% {
    transform: translateX(40vw) translateY(43vh) scale(0.9);
  }

  83.6134453782% {
    transform: translateX(29vw) translateY(-30vh) scale(0.73);
  }

  84.0336134454% {
    transform: translateX(-3vw) translateY(-2vh) scale(0.83);
  }

  84.4537815126% {
    transform: translateX(45vw) translateY(5vh) scale(0.82);
  }

  84.8739495798% {
    transform: translateX(23vw) translateY(8vh) scale(0.53);
  }

  85.2941176471% {
    transform: translateX(12vw) translateY(-13vh) scale(0.67);
  }

  85.7142857143% {
    transform: translateX(-15vw) translateY(32vh) scale(0.7);
  }

  86.1344537815% {
    transform: translateX(41vw) translateY(-4vh) scale(0.45);
  }

  86.5546218487% {
    transform: translateX(-45vw) translateY(33vh) scale(0.98);
  }

  86.974789916% {
    transform: translateX(5vw) translateY(-43vh) scale(0.7);
  }

  87.3949579832% {
    transform: translateX(33vw) translateY(13vh) scale(0.65);
  }

  87.8151260504% {
    transform: translateX(-34vw) translateY(-46vh) scale(0.93);
  }

  88.2352941176% {
    transform: translateX(-35vw) translateY(24vh) scale(0.82);
  }

  88.6554621849% {
    transform: translateX(1vw) translateY(28vh) scale(0.34);
  }

  89.0756302521% {
    transform: translateX(-10vw) translateY(-7vh) scale(0.48);
  }

  89.4957983193% {
    transform: translateX(-29vw) translateY(24vh) scale(0.57);
  }

  89.9159663866% {
    transform: translateX(9vw) translateY(-6vh) scale(0.34);
  }

  90.3361344538% {
    transform: translateX(-31vw) translateY(39vh) scale(0.52);
  }

  90.756302521% {
    transform: translateX(-34vw) translateY(-45vh) scale(0.29);
  }

  91.1764705882% {
    transform: translateX(-27vw) translateY(26vh) scale(0.75);
  }

  91.5966386555% {
    transform: translateX(39vw) translateY(37vh) scale(0.54);
  }

  92.0168067227% {
    transform: translateX(-36vw) translateY(-42vh) scale(0.93);
  }

  92.4369747899% {
    transform: translateX(-15vw) translateY(-28vh) scale(0.85);
  }

  92.8571428571% {
    transform: translateX(-1vw) translateY(-15vh) scale(0.57);
  }

  93.2773109244% {
    transform: translateX(-18vw) translateY(-37vh) scale(0.72);
  }

  93.6974789916% {
    transform: translateX(-32vw) translateY(-28vh) scale(0.58);
  }

  94.1176470588% {
    transform: translateX(-15vw) translateY(-7vh) scale(0.82);
  }

  94.5378151261% {
    transform: translateX(-16vw) translateY(17vh) scale(1);
  }

  94.9579831933% {
    transform: translateX(24vw) translateY(-45vh) scale(0.73);
  }

  95.3781512605% {
    transform: translateX(-12vw) translateY(0vh) scale(0.47);
  }

  95.7983193277% {
    transform: translateX(-34vw) translateY(-22vh) scale(0.64);
  }

  96.218487395% {
    transform: translateX(0vw) translateY(-12vh) scale(0.71);
  }

  96.6386554622% {
    transform: translateX(-2vw) translateY(-1vh) scale(0.75);
  }

  97.0588235294% {
    transform: translateX(47vw) translateY(-20vh) scale(0.75);
  }

  97.4789915966% {
    transform: translateX(25vw) translateY(-39vh) scale(0.91);
  }

  97.8991596639% {
    transform: translateX(-1vw) translateY(-32vh) scale(0.97);
  }

  98.3193277311% {
    transform: translateX(-27vw) translateY(-2vh) scale(0.84);
  }

  98.7394957983% {
    transform: translateX(-1vw) translateY(47vh) scale(0.92);
  }

  99.1596638655% {
    transform: translateX(-31vw) translateY(-41vh) scale(0.91);
  }

  99.5798319328% {
    transform: translateX(-23vw) translateY(-35vh) scale(0.94);
  }

  100% {
    transform: translateX(-19vw) translateY(19vh) scale(0.62);
  }
}

.firefly:nth-child(27) {
  animation-name: move27;
}

.firefly:nth-child(27)::before {
  animation-duration: 18s;
}

.firefly:nth-child(27)::after {
  animation-duration: 18s, 6450ms;
  animation-delay: 0ms, 1426ms;
}

@keyframes move27 {
  0% {
    transform: translateX(-9vw) translateY(-1vh) scale(0.55);
  }

  0.5586592179% {
    transform: translateX(1vw) translateY(25vh) scale(0.79);
  }

  1.1173184358% {
    transform: translateX(-34vw) translateY(-45vh) scale(0.49);
  }

  1.6759776536% {
    transform: translateX(-3vw) translateY(-21vh) scale(0.8);
  }

  2.2346368715% {
    transform: translateX(-7vw) translateY(32vh) scale(0.88);
  }

  2.7932960894% {
    transform: translateX(-1vw) translateY(30vh) scale(0.69);
  }

  3.3519553073% {
    transform: translateX(-11vw) translateY(-16vh) scale(0.29);
  }

  3.9106145251% {
    transform: translateX(29vw) translateY(4vh) scale(0.78);
  }

  4.469273743% {
    transform: translateX(-47vw) translateY(-33vh) scale(0.44);
  }

  5.0279329609% {
    transform: translateX(-34vw) translateY(-4vh) scale(0.28);
  }

  5.5865921788% {
    transform: translateX(-6vw) translateY(35vh) scale(0.65);
  }

  6.1452513966% {
    transform: translateX(-39vw) translateY(-4vh) scale(0.58);
  }

  6.7039106145% {
    transform: translateX(17vw) translateY(-9vh) scale(0.41);
  }

  7.2625698324% {
    transform: translateX(4vw) translateY(-4vh) scale(0.6);
  }

  7.8212290503% {
    transform: translateX(24vw) translateY(27vh) scale(0.74);
  }

  8.3798882682% {
    transform: translateX(-28vw) translateY(46vh) scale(0.98);
  }

  8.938547486% {
    transform: translateX(15vw) translateY(44vh) scale(0.72);
  }

  9.4972067039% {
    transform: translateX(-26vw) translateY(33vh) scale(0.97);
  }

  10.0558659218% {
    transform: translateX(48vw) translateY(-32vh) scale(0.83);
  }

  10.6145251397% {
    transform: translateX(-35vw) translateY(-20vh) scale(0.45);
  }

  11.1731843575% {
    transform: translateX(-24vw) translateY(23vh) scale(0.78);
  }

  11.7318435754% {
    transform: translateX(-2vw) translateY(50vh) scale(0.92);
  }

  12.2905027933% {
    transform: translateX(15vw) translateY(-28vh) scale(0.26);
  }

  12.8491620112% {
    transform: translateX(-38vw) translateY(-18vh) scale(0.44);
  }

  13.4078212291% {
    transform: translateX(4vw) translateY(-14vh) scale(0.82);
  }

  13.9664804469% {
    transform: translateX(-14vw) translateY(-20vh) scale(0.49);
  }

  14.5251396648% {
    transform: translateX(49vw) translateY(34vh) scale(0.78);
  }

  15.0837988827% {
    transform: translateX(30vw) translateY(14vh) scale(0.47);
  }

  15.6424581006% {
    transform: translateX(-16vw) translateY(40vh) scale(0.94);
  }

  16.2011173184% {
    transform: translateX(50vw) translateY(-27vh) scale(0.89);
  }

  16.7597765363% {
    transform: translateX(31vw) translateY(33vh) scale(0.53);
  }

  17.3184357542% {
    transform: translateX(33vw) translateY(10vh) scale(0.3);
  }

  17.8770949721% {
    transform: translateX(48vw) translateY(32vh) scale(0.77);
  }

  18.4357541899% {
    transform: translateX(50vw) translateY(39vh) scale(0.36);
  }

  18.9944134078% {
    transform: translateX(18vw) translateY(8vh) scale(0.43);
  }

  19.5530726257% {
    transform: translateX(3vw) translateY(31vh) scale(0.37);
  }

  20.1117318436% {
    transform: translateX(-1vw) translateY(-40vh) scale(0.72);
  }

  20.6703910615% {
    transform: translateX(-46vw) translateY(-8vh) scale(0.33);
  }

  21.2290502793% {
    transform: translateX(-29vw) translateY(-21vh) scale(0.42);
  }

  21.7877094972% {
    transform: translateX(-32vw) translateY(-26vh) scale(0.43);
  }

  22.3463687151% {
    transform: translateX(-25vw) translateY(-28vh) scale(0.68);
  }

  22.905027933% {
    transform: translateX(-38vw) translateY(-1vh) scale(0.63);
  }

  23.4636871508% {
    transform: translateX(21vw) translateY(-26vh) scale(1);
  }

  24.0223463687% {
    transform: translateX(-7vw) translateY(22vh) scale(0.79);
  }

  24.5810055866% {
    transform: translateX(-29vw) translateY(-35vh) scale(0.85);
  }

  25.1396648045% {
    transform: translateX(-12vw) translateY(39vh) scale(0.84);
  }

  25.6983240223% {
    transform: translateX(-12vw) translateY(36vh) scale(0.58);
  }

  26.2569832402% {
    transform: translateX(4vw) translateY(-5vh) scale(0.47);
  }

  26.8156424581% {
    transform: translateX(-24vw) translateY(-36vh) scale(0.95);
  }

  27.374301676% {
    transform: translateX(18vw) translateY(4vh) scale(0.44);
  }

  27.9329608939% {
    transform: translateX(35vw) translateY(-1vh) scale(0.92);
  }

  28.4916201117% {
    transform: translateX(-47vw) translateY(-41vh) scale(0.81);
  }

  29.0502793296% {
    transform: translateX(45vw) translateY(-12vh) scale(0.39);
  }

  29.6089385475% {
    transform: translateX(14vw) translateY(48vh) scale(0.72);
  }

  30.1675977654% {
    transform: translateX(-6vw) translateY(44vh) scale(0.72);
  }

  30.7262569832% {
    transform: translateX(-6vw) translateY(-9vh) scale(0.44);
  }

  31.2849162011% {
    transform: translateX(9vw) translateY(37vh) scale(0.41);
  }

  31.843575419% {
    transform: translateX(21vw) translateY(12vh) scale(0.31);
  }

  32.4022346369% {
    transform: translateX(-47vw) translateY(-27vh) scale(0.53);
  }

  32.9608938547% {
    transform: translateX(6vw) translateY(-34vh) scale(0.31);
  }

  33.5195530726% {
    transform: translateX(-41vw) translateY(-25vh) scale(0.39);
  }

  34.0782122905% {
    transform: translateX(9vw) translateY(20vh) scale(1);
  }

  34.6368715084% {
    transform: translateX(22vw) translateY(-39vh) scale(0.34);
  }

  35.1955307263% {
    transform: translateX(11vw) translateY(-31vh) scale(0.77);
  }

  35.7541899441% {
    transform: translateX(-4vw) translateY(18vh) scale(0.32);
  }

  36.312849162% {
    transform: translateX(-45vw) translateY(-49vh) scale(0.8);
  }

  36.8715083799% {
    transform: translateX(-20vw) translateY(46vh) scale(0.57);
  }

  37.4301675978% {
    transform: translateX(-30vw) translateY(-24vh) scale(0.93);
  }

  37.9888268156% {
    transform: translateX(-42vw) translateY(-21vh) scale(0.31);
  }

  38.5474860335% {
    transform: translateX(27vw) translateY(6vh) scale(0.46);
  }

  39.1061452514% {
    transform: translateX(10vw) translateY(36vh) scale(0.56);
  }

  39.6648044693% {
    transform: translateX(-23vw) translateY(-32vh) scale(0.61);
  }

  40.2234636872% {
    transform: translateX(-23vw) translateY(45vh) scale(0.61);
  }

  40.782122905% {
    transform: translateX(43vw) translateY(-28vh) scale(0.53);
  }

  41.3407821229% {
    transform: translateX(5vw) translateY(33vh) scale(0.47);
  }

  41.8994413408% {
    transform: translateX(0vw) translateY(6vh) scale(0.51);
  }

  42.4581005587% {
    transform: translateX(-44vw) translateY(46vh) scale(0.57);
  }

  43.0167597765% {
    transform: translateX(33vw) translateY(-25vh) scale(0.78);
  }

  43.5754189944% {
    transform: translateX(-24vw) translateY(-21vh) scale(0.56);
  }

  44.1340782123% {
    transform: translateX(-23vw) translateY(-34vh) scale(0.72);
  }

  44.6927374302% {
    transform: translateX(-5vw) translateY(-23vh) scale(0.63);
  }

  45.251396648% {
    transform: translateX(-26vw) translateY(-38vh) scale(0.82);
  }

  45.8100558659% {
    transform: translateX(33vw) translateY(6vh) scale(0.49);
  }

  46.3687150838% {
    transform: translateX(-1vw) translateY(-38vh) scale(0.99);
  }

  46.9273743017% {
    transform: translateX(17vw) translateY(3vh) scale(0.34);
  }

  47.4860335196% {
    transform: translateX(-44vw) translateY(6vh) scale(0.62);
  }

  48.0446927374% {
    transform: translateX(-46vw) translateY(44vh) scale(0.28);
  }

  48.6033519553% {
    transform: translateX(-47vw) translateY(8vh) scale(0.74);
  }

  49.1620111732% {
    transform: translateX(6vw) translateY(4vh) scale(0.48);
  }

  49.7206703911% {
    transform: translateX(-34vw) translateY(23vh) scale(0.63);
  }

  50.2793296089% {
    transform: translateX(-1vw) translateY(48vh) scale(0.51);
  }

  50.8379888268% {
    transform: translateX(49vw) translateY(30vh) scale(0.3);
  }

  51.3966480447% {
    transform: translateX(-47vw) translateY(49vh) scale(0.29);
  }

  51.9553072626% {
    transform: translateX(-34vw) translateY(-47vh) scale(0.9);
  }

  52.5139664804% {
    transform: translateX(46vw) translateY(-29vh) scale(0.78);
  }

  53.0726256983% {
    transform: translateX(-28vw) translateY(-7vh) scale(0.72);
  }

  53.6312849162% {
    transform: translateX(5vw) translateY(20vh) scale(0.76);
  }

  54.1899441341% {
    transform: translateX(27vw) translateY(-9vh) scale(0.6);
  }

  54.748603352% {
    transform: translateX(-16vw) translateY(-27vh) scale(0.96);
  }

  55.3072625698% {
    transform: translateX(-44vw) translateY(-31vh) scale(0.56);
  }

  55.8659217877% {
    transform: translateX(41vw) translateY(-17vh) scale(0.95);
  }

  56.4245810056% {
    transform: translateX(-11vw) translateY(-16vh) scale(0.85);
  }

  56.9832402235% {
    transform: translateX(28vw) translateY(-30vh) scale(0.31);
  }

  57.5418994413% {
    transform: translateX(5vw) translateY(-31vh) scale(0.97);
  }

  58.1005586592% {
    transform: translateX(33vw) translateY(36vh) scale(0.82);
  }

  58.6592178771% {
    transform: translateX(-23vw) translateY(16vh) scale(0.26);
  }

  59.217877095% {
    transform: translateX(38vw) translateY(43vh) scale(0.49);
  }

  59.7765363128% {
    transform: translateX(1vw) translateY(10vh) scale(0.95);
  }

  60.3351955307% {
    transform: translateX(22vw) translateY(15vh) scale(0.54);
  }

  60.8938547486% {
    transform: translateX(12vw) translateY(15vh) scale(0.54);
  }

  61.4525139665% {
    transform: translateX(-32vw) translateY(-41vh) scale(0.97);
  }

  62.0111731844% {
    transform: translateX(-33vw) translateY(-13vh) scale(0.34);
  }

  62.5698324022% {
    transform: translateX(22vw) translateY(-39vh) scale(0.83);
  }

  63.1284916201% {
    transform: translateX(16vw) translateY(0vh) scale(0.51);
  }

  63.687150838% {
    transform: translateX(-27vw) translateY(-24vh) scale(0.96);
  }

  64.2458100559% {
    transform: translateX(-29vw) translateY(-1vh) scale(0.8);
  }

  64.8044692737% {
    transform: translateX(-14vw) translateY(-40vh) scale(0.78);
  }

  65.3631284916% {
    transform: translateX(24vw) translateY(-17vh) scale(0.98);
  }

  65.9217877095% {
    transform: translateX(-36vw) translateY(0vh) scale(0.83);
  }

  66.4804469274% {
    transform: translateX(6vw) translateY(20vh) scale(0.91);
  }

  67.0391061453% {
    transform: translateX(10vw) translateY(-6vh) scale(0.35);
  }

  67.5977653631% {
    transform: translateX(-17vw) translateY(23vh) scale(0.42);
  }

  68.156424581% {
    transform: translateX(-40vw) translateY(-10vh) scale(0.4);
  }

  68.7150837989% {
    transform: translateX(12vw) translateY(49vh) scale(0.68);
  }

  69.2737430168% {
    transform: translateX(42vw) translateY(19vh) scale(0.91);
  }

  69.8324022346% {
    transform: translateX(46vw) translateY(-7vh) scale(0.92);
  }

  70.3910614525% {
    transform: translateX(-13vw) translateY(46vh) scale(0.56);
  }

  70.9497206704% {
    transform: translateX(28vw) translateY(22vh) scale(0.88);
  }

  71.5083798883% {
    transform: translateX(-17vw) translateY(2vh) scale(0.32);
  }

  72.0670391061% {
    transform: translateX(-33vw) translateY(-13vh) scale(0.46);
  }

  72.625698324% {
    transform: translateX(-10vw) translateY(24vh) scale(0.66);
  }

  73.1843575419% {
    transform: translateX(-7vw) translateY(2vh) scale(0.69);
  }

  73.7430167598% {
    transform: translateX(24vw) translateY(-40vh) scale(0.8);
  }

  74.3016759777% {
    transform: translateX(18vw) translateY(-31vh) scale(0.82);
  }

  74.8603351955% {
    transform: translateX(-36vw) translateY(0vh) scale(0.34);
  }

  75.4189944134% {
    transform: translateX(24vw) translateY(0vh) scale(0.66);
  }

  75.9776536313% {
    transform: translateX(-11vw) translateY(-16vh) scale(0.67);
  }

  76.5363128492% {
    transform: translateX(35vw) translateY(10vh) scale(0.97);
  }

  77.094972067% {
    transform: translateX(35vw) translateY(-20vh) scale(0.56);
  }

  77.6536312849% {
    transform: translateX(-36vw) translateY(5vh) scale(0.99);
  }

  78.2122905028% {
    transform: translateX(-28vw) translateY(11vh) scale(0.68);
  }

  78.7709497207% {
    transform: translateX(-42vw) translateY(-27vh) scale(0.94);
  }

  79.3296089385% {
    transform: translateX(48vw) translateY(9vh) scale(0.75);
  }

  79.8882681564% {
    transform: translateX(-17vw) translateY(-20vh) scale(0.87);
  }

  80.4469273743% {
    transform: translateX(-15vw) translateY(48vh) scale(0.9);
  }

  81.0055865922% {
    transform: translateX(-45vw) translateY(25vh) scale(0.31);
  }

  81.5642458101% {
    transform: translateX(19vw) translateY(-14vh) scale(0.95);
  }

  82.1229050279% {
    transform: translateX(-33vw) translateY(-38vh) scale(0.42);
  }

  82.6815642458% {
    transform: translateX(-9vw) translateY(-42vh) scale(0.45);
  }

  83.2402234637% {
    transform: translateX(-36vw) translateY(-35vh) scale(0.77);
  }

  83.7988826816% {
    transform: translateX(-34vw) translateY(-14vh) scale(0.65);
  }

  84.3575418994% {
    transform: translateX(7vw) translateY(44vh) scale(0.88);
  }

  84.9162011173% {
    transform: translateX(33vw) translateY(6vh) scale(0.49);
  }

  85.4748603352% {
    transform: translateX(-8vw) translateY(18vh) scale(0.45);
  }

  86.0335195531% {
    transform: translateX(1vw) translateY(-24vh) scale(0.67);
  }

  86.5921787709% {
    transform: translateX(-18vw) translateY(40vh) scale(0.36);
  }

  87.1508379888% {
    transform: translateX(32vw) translateY(19vh) scale(0.76);
  }

  87.7094972067% {
    transform: translateX(40vw) translateY(-43vh) scale(0.6);
  }

  88.2681564246% {
    transform: translateX(0vw) translateY(37vh) scale(0.36);
  }

  88.8268156425% {
    transform: translateX(3vw) translateY(-7vh) scale(0.36);
  }

  89.3854748603% {
    transform: translateX(20vw) translateY(10vh) scale(0.43);
  }

  89.9441340782% {
    transform: translateX(-29vw) translateY(33vh) scale(0.38);
  }

  90.5027932961% {
    transform: translateX(4vw) translateY(-1vh) scale(0.98);
  }

  91.061452514% {
    transform: translateX(-12vw) translateY(12vh) scale(0.78);
  }

  91.6201117318% {
    transform: translateX(0vw) translateY(29vh) scale(0.95);
  }

  92.1787709497% {
    transform: translateX(-45vw) translateY(-41vh) scale(0.33);
  }

  92.7374301676% {
    transform: translateX(-7vw) translateY(-10vh) scale(0.52);
  }

  93.2960893855% {
    transform: translateX(33vw) translateY(14vh) scale(0.54);
  }

  93.8547486034% {
    transform: translateX(-5vw) translateY(45vh) scale(0.86);
  }

  94.4134078212% {
    transform: translateX(-9vw) translateY(18vh) scale(0.6);
  }

  94.9720670391% {
    transform: translateX(-23vw) translateY(34vh) scale(0.99);
  }

  95.530726257% {
    transform: translateX(-45vw) translateY(-23vh) scale(0.26);
  }

  96.0893854749% {
    transform: translateX(-21vw) translateY(-23vh) scale(0.86);
  }

  96.6480446927% {
    transform: translateX(-26vw) translateY(-18vh) scale(0.62);
  }

  97.2067039106% {
    transform: translateX(36vw) translateY(-25vh) scale(0.46);
  }

  97.7653631285% {
    transform: translateX(22vw) translateY(22vh) scale(0.81);
  }

  98.3240223464% {
    transform: translateX(7vw) translateY(13vh) scale(0.41);
  }

  98.8826815642% {
    transform: translateX(-9vw) translateY(1vh) scale(0.95);
  }

  99.4413407821% {
    transform: translateX(-23vw) translateY(43vh) scale(0.89);
  }

  100% {
    transform: translateX(9vw) translateY(-5vh) scale(0.52);
  }
}

.firefly:nth-child(28) {
  animation-name: move28;
}

.firefly:nth-child(28)::before {
  animation-duration: 10s;
}

.firefly:nth-child(28)::after {
  animation-duration: 10s, 9447ms;
  animation-delay: 0ms, 7167ms;
}

@keyframes move28 {
  0% {
    transform: translateX(19vw) translateY(-26vh) scale(0.65);
  }

  0.3731343284% {
    transform: translateX(47vw) translateY(-46vh) scale(0.9);
  }

  0.7462686567% {
    transform: translateX(-17vw) translateY(-24vh) scale(0.29);
  }

  1.1194029851% {
    transform: translateX(-32vw) translateY(-26vh) scale(0.78);
  }

  1.4925373134% {
    transform: translateX(30vw) translateY(35vh) scale(0.63);
  }

  1.8656716418% {
    transform: translateX(-26vw) translateY(4vh) scale(0.76);
  }

  2.2388059701% {
    transform: translateX(29vw) translateY(29vh) scale(0.68);
  }

  2.6119402985% {
    transform: translateX(44vw) translateY(33vh) scale(0.9);
  }

  2.9850746269% {
    transform: translateX(-9vw) translateY(-6vh) scale(0.79);
  }

  3.3582089552% {
    transform: translateX(35vw) translateY(6vh) scale(0.4);
  }

  3.7313432836% {
    transform: translateX(-30vw) translateY(-47vh) scale(0.58);
  }

  4.1044776119% {
    transform: translateX(20vw) translateY(-26vh) scale(0.3);
  }

  4.4776119403% {
    transform: translateX(-46vw) translateY(8vh) scale(0.32);
  }

  4.8507462687% {
    transform: translateX(-30vw) translateY(-11vh) scale(0.94);
  }

  5.223880597% {
    transform: translateX(24vw) translateY(-37vh) scale(0.46);
  }

  5.5970149254% {
    transform: translateX(13vw) translateY(9vh) scale(0.42);
  }

  5.9701492537% {
    transform: translateX(29vw) translateY(7vh) scale(0.85);
  }

  6.3432835821% {
    transform: translateX(-15vw) translateY(-48vh) scale(0.89);
  }

  6.7164179104% {
    transform: translateX(-33vw) translateY(-45vh) scale(0.98);
  }

  7.0895522388% {
    transform: translateX(-48vw) translateY(28vh) scale(0.35);
  }

  7.4626865672% {
    transform: translateX(46vw) translateY(-43vh) scale(0.36);
  }

  7.8358208955% {
    transform: translateX(-37vw) translateY(-9vh) scale(0.85);
  }

  8.2089552239% {
    transform: translateX(-8vw) translateY(-30vh) scale(0.82);
  }

  8.5820895522% {
    transform: translateX(48vw) translateY(-17vh) scale(0.29);
  }

  8.9552238806% {
    transform: translateX(39vw) translateY(20vh) scale(0.36);
  }

  9.328358209% {
    transform: translateX(33vw) translateY(19vh) scale(0.75);
  }

  9.7014925373% {
    transform: translateX(44vw) translateY(-10vh) scale(0.47);
  }

  10.0746268657% {
    transform: translateX(-44vw) translateY(15vh) scale(0.88);
  }

  10.447761194% {
    transform: translateX(8vw) translateY(-36vh) scale(0.57);
  }

  10.8208955224% {
    transform: translateX(-19vw) translateY(-44vh) scale(0.31);
  }

  11.1940298507% {
    transform: translateX(-37vw) translateY(-3vh) scale(0.54);
  }

  11.5671641791% {
    transform: translateX(-25vw) translateY(-21vh) scale(0.57);
  }

  11.9402985075% {
    transform: translateX(-41vw) translateY(-30vh) scale(0.5);
  }

  12.3134328358% {
    transform: translateX(-14vw) translateY(29vh) scale(0.61);
  }

  12.6865671642% {
    transform: translateX(48vw) translateY(-3vh) scale(0.56);
  }

  13.0597014925% {
    transform: translateX(-31vw) translateY(36vh) scale(0.42);
  }

  13.4328358209% {
    transform: translateX(-37vw) translateY(-19vh) scale(0.41);
  }

  13.8059701493% {
    transform: translateX(-3vw) translateY(-1vh) scale(0.48);
  }

  14.1791044776% {
    transform: translateX(50vw) translateY(-23vh) scale(0.6);
  }

  14.552238806% {
    transform: translateX(-39vw) translateY(0vh) scale(0.57);
  }

  14.9253731343% {
    transform: translateX(-43vw) translateY(31vh) scale(0.64);
  }

  15.2985074627% {
    transform: translateX(-9vw) translateY(5vh) scale(0.45);
  }

  15.671641791% {
    transform: translateX(26vw) translateY(29vh) scale(0.57);
  }

  16.0447761194% {
    transform: translateX(43vw) translateY(26vh) scale(0.98);
  }

  16.4179104478% {
    transform: translateX(21vw) translateY(-36vh) scale(0.97);
  }

  16.7910447761% {
    transform: translateX(32vw) translateY(23vh) scale(0.86);
  }

  17.1641791045% {
    transform: translateX(49vw) translateY(-29vh) scale(0.51);
  }

  17.5373134328% {
    transform: translateX(39vw) translateY(13vh) scale(0.97);
  }

  17.9104477612% {
    transform: translateX(-25vw) translateY(-26vh) scale(0.85);
  }

  18.2835820896% {
    transform: translateX(-31vw) translateY(-27vh) scale(0.49);
  }

  18.6567164179% {
    transform: translateX(-33vw) translateY(27vh) scale(0.98);
  }

  19.0298507463% {
    transform: translateX(-11vw) translateY(-1vh) scale(0.77);
  }

  19.4029850746% {
    transform: translateX(-44vw) translateY(-24vh) scale(0.9);
  }

  19.776119403% {
    transform: translateX(-4vw) translateY(-40vh) scale(0.26);
  }

  20.1492537313% {
    transform: translateX(-46vw) translateY(-36vh) scale(0.9);
  }

  20.5223880597% {
    transform: translateX(45vw) translateY(8vh) scale(0.92);
  }

  20.8955223881% {
    transform: translateX(33vw) translateY(-35vh) scale(0.94);
  }

  21.2686567164% {
    transform: translateX(8vw) translateY(-42vh) scale(0.52);
  }

  21.6417910448% {
    transform: translateX(-34vw) translateY(47vh) scale(0.55);
  }

  22.0149253731% {
    transform: translateX(-13vw) translateY(-22vh) scale(0.65);
  }

  22.3880597015% {
    transform: translateX(8vw) translateY(20vh) scale(0.68);
  }

  22.7611940299% {
    transform: translateX(32vw) translateY(2vh) scale(0.77);
  }

  23.1343283582% {
    transform: translateX(-20vw) translateY(-14vh) scale(0.43);
  }

  23.5074626866% {
    transform: translateX(22vw) translateY(42vh) scale(0.97);
  }

  23.8805970149% {
    transform: translateX(17vw) translateY(-46vh) scale(0.83);
  }

  24.2537313433% {
    transform: translateX(-16vw) translateY(10vh) scale(0.33);
  }

  24.6268656716% {
    transform: translateX(-26vw) translateY(-40vh) scale(0.7);
  }

  25% {
    transform: translateX(-33vw) translateY(1vh) scale(0.26);
  }

  25.3731343284% {
    transform: translateX(28vw) translateY(40vh) scale(0.27);
  }

  25.7462686567% {
    transform: translateX(-37vw) translateY(12vh) scale(0.77);
  }

  26.1194029851% {
    transform: translateX(35vw) translateY(-34vh) scale(0.3);
  }

  26.4925373134% {
    transform: translateX(3vw) translateY(-27vh) scale(0.31);
  }

  26.8656716418% {
    transform: translateX(40vw) translateY(41vh) scale(0.87);
  }

  27.2388059701% {
    transform: translateX(33vw) translateY(-19vh) scale(0.65);
  }

  27.6119402985% {
    transform: translateX(40vw) translateY(-5vh) scale(0.32);
  }

  27.9850746269% {
    transform: translateX(0vw) translateY(35vh) scale(0.86);
  }

  28.3582089552% {
    transform: translateX(2vw) translateY(40vh) scale(0.72);
  }

  28.7313432836% {
    transform: translateX(19vw) translateY(-6vh) scale(0.8);
  }

  29.1044776119% {
    transform: translateX(-3vw) translateY(16vh) scale(0.41);
  }

  29.4776119403% {
    transform: translateX(36vw) translateY(48vh) scale(0.71);
  }

  29.8507462687% {
    transform: translateX(7vw) translateY(-42vh) scale(0.7);
  }

  30.223880597% {
    transform: translateX(-22vw) translateY(7vh) scale(0.84);
  }

  30.5970149254% {
    transform: translateX(-13vw) translateY(-9vh) scale(0.77);
  }

  30.9701492537% {
    transform: translateX(-11vw) translateY(2vh) scale(0.58);
  }

  31.3432835821% {
    transform: translateX(28vw) translateY(-21vh) scale(0.76);
  }

  31.7164179104% {
    transform: translateX(36vw) translateY(5vh) scale(0.79);
  }

  32.0895522388% {
    transform: translateX(22vw) translateY(-49vh) scale(0.48);
  }

  32.4626865672% {
    transform: translateX(-46vw) translateY(-33vh) scale(0.82);
  }

  32.8358208955% {
    transform: translateX(9vw) translateY(-27vh) scale(0.89);
  }

  33.2089552239% {
    transform: translateX(-8vw) translateY(20vh) scale(0.32);
  }

  33.5820895522% {
    transform: translateX(-32vw) translateY(44vh) scale(0.91);
  }

  33.9552238806% {
    transform: translateX(33vw) translateY(-49vh) scale(0.54);
  }

  34.328358209% {
    transform: translateX(-25vw) translateY(3vh) scale(0.42);
  }

  34.7014925373% {
    transform: translateX(44vw) translateY(24vh) scale(0.45);
  }

  35.0746268657% {
    transform: translateX(27vw) translateY(12vh) scale(0.56);
  }

  35.447761194% {
    transform: translateX(-34vw) translateY(-41vh) scale(0.37);
  }

  35.8208955224% {
    transform: translateX(25vw) translateY(-46vh) scale(0.48);
  }

  36.1940298507% {
    transform: translateX(-4vw) translateY(-18vh) scale(0.8);
  }

  36.5671641791% {
    transform: translateX(-29vw) translateY(-49vh) scale(1);
  }

  36.9402985075% {
    transform: translateX(35vw) translateY(1vh) scale(0.28);
  }

  37.3134328358% {
    transform: translateX(34vw) translateY(33vh) scale(0.73);
  }

  37.6865671642% {
    transform: translateX(3vw) translateY(-25vh) scale(1);
  }

  38.0597014925% {
    transform: translateX(45vw) translateY(-19vh) scale(0.45);
  }

  38.4328358209% {
    transform: translateX(-5vw) translateY(-22vh) scale(0.97);
  }

  38.8059701493% {
    transform: translateX(-13vw) translateY(-43vh) scale(0.3);
  }

  39.1791044776% {
    transform: translateX(13vw) translateY(-48vh) scale(0.6);
  }

  39.552238806% {
    transform: translateX(-7vw) translateY(-42vh) scale(0.99);
  }

  39.9253731343% {
    transform: translateX(-28vw) translateY(-36vh) scale(0.84);
  }

  40.2985074627% {
    transform: translateX(-12vw) translateY(41vh) scale(0.28);
  }

  40.671641791% {
    transform: translateX(17vw) translateY(-28vh) scale(0.62);
  }

  41.0447761194% {
    transform: translateX(23vw) translateY(-22vh) scale(0.61);
  }

  41.4179104478% {
    transform: translateX(26vw) translateY(38vh) scale(0.28);
  }

  41.7910447761% {
    transform: translateX(15vw) translateY(10vh) scale(0.73);
  }

  42.1641791045% {
    transform: translateX(17vw) translateY(-26vh) scale(0.65);
  }

  42.5373134328% {
    transform: translateX(20vw) translateY(-14vh) scale(0.4);
  }

  42.9104477612% {
    transform: translateX(20vw) translateY(-12vh) scale(0.62);
  }

  43.2835820896% {
    transform: translateX(43vw) translateY(16vh) scale(0.55);
  }

  43.6567164179% {
    transform: translateX(6vw) translateY(38vh) scale(0.76);
  }

  44.0298507463% {
    transform: translateX(-28vw) translateY(44vh) scale(0.87);
  }

  44.4029850746% {
    transform: translateX(50vw) translateY(-35vh) scale(0.48);
  }

  44.776119403% {
    transform: translateX(-20vw) translateY(24vh) scale(0.63);
  }

  45.1492537313% {
    transform: translateX(-5vw) translateY(-5vh) scale(0.91);
  }

  45.5223880597% {
    transform: translateX(-45vw) translateY(33vh) scale(0.97);
  }

  45.8955223881% {
    transform: translateX(39vw) translateY(-22vh) scale(0.52);
  }

  46.2686567164% {
    transform: translateX(-39vw) translateY(50vh) scale(0.42);
  }

  46.6417910448% {
    transform: translateX(15vw) translateY(18vh) scale(0.96);
  }

  47.0149253731% {
    transform: translateX(-21vw) translateY(-44vh) scale(0.47);
  }

  47.3880597015% {
    transform: translateX(-13vw) translateY(-41vh) scale(0.42);
  }

  47.7611940299% {
    transform: translateX(47vw) translateY(25vh) scale(0.96);
  }

  48.1343283582% {
    transform: translateX(-23vw) translateY(27vh) scale(0.47);
  }

  48.5074626866% {
    transform: translateX(13vw) translateY(11vh) scale(0.98);
  }

  48.8805970149% {
    transform: translateX(-16vw) translateY(-20vh) scale(0.42);
  }

  49.2537313433% {
    transform: translateX(-47vw) translateY(-13vh) scale(0.93);
  }

  49.6268656716% {
    transform: translateX(-49vw) translateY(42vh) scale(0.89);
  }

  50% {
    transform: translateX(14vw) translateY(5vh) scale(0.27);
  }

  50.3731343284% {
    transform: translateX(-40vw) translateY(10vh) scale(0.77);
  }

  50.7462686567% {
    transform: translateX(29vw) translateY(-7vh) scale(0.27);
  }

  51.1194029851% {
    transform: translateX(-25vw) translateY(-14vh) scale(0.42);
  }

  51.4925373134% {
    transform: translateX(11vw) translateY(-26vh) scale(0.31);
  }

  51.8656716418% {
    transform: translateX(-42vw) translateY(-17vh) scale(0.31);
  }

  52.2388059701% {
    transform: translateX(15vw) translateY(24vh) scale(0.54);
  }

  52.6119402985% {
    transform: translateX(-30vw) translateY(8vh) scale(0.78);
  }

  52.9850746269% {
    transform: translateX(4vw) translateY(-24vh) scale(0.89);
  }

  53.3582089552% {
    transform: translateX(38vw) translateY(-45vh) scale(0.71);
  }

  53.7313432836% {
    transform: translateX(17vw) translateY(-45vh) scale(0.8);
  }

  54.1044776119% {
    transform: translateX(3vw) translateY(-3vh) scale(0.96);
  }

  54.4776119403% {
    transform: translateX(42vw) translateY(50vh) scale(0.36);
  }

  54.8507462687% {
    transform: translateX(-27vw) translateY(-22vh) scale(0.74);
  }

  55.223880597% {
    transform: translateX(-26vw) translateY(9vh) scale(0.89);
  }

  55.5970149254% {
    transform: translateX(-48vw) translateY(-28vh) scale(0.44);
  }

  55.9701492537% {
    transform: translateX(17vw) translateY(7vh) scale(0.64);
  }

  56.3432835821% {
    transform: translateX(-47vw) translateY(0vh) scale(0.7);
  }

  56.7164179104% {
    transform: translateX(17vw) translateY(-26vh) scale(0.35);
  }

  57.0895522388% {
    transform: translateX(-48vw) translateY(-5vh) scale(0.89);
  }

  57.4626865672% {
    transform: translateX(-44vw) translateY(49vh) scale(0.91);
  }

  57.8358208955% {
    transform: translateX(28vw) translateY(-19vh) scale(0.63);
  }

  58.2089552239% {
    transform: translateX(-7vw) translateY(15vh) scale(0.5);
  }

  58.5820895522% {
    transform: translateX(-47vw) translateY(-1vh) scale(0.43);
  }

  58.9552238806% {
    transform: translateX(13vw) translateY(4vh) scale(0.35);
  }

  59.328358209% {
    transform: translateX(-7vw) translateY(-16vh) scale(0.86);
  }

  59.7014925373% {
    transform: translateX(-28vw) translateY(-23vh) scale(0.75);
  }

  60.0746268657% {
    transform: translateX(-32vw) translateY(9vh) scale(0.31);
  }

  60.447761194% {
    transform: translateX(18vw) translateY(1vh) scale(0.34);
  }

  60.8208955224% {
    transform: translateX(-20vw) translateY(-14vh) scale(0.89);
  }

  61.1940298507% {
    transform: translateX(43vw) translateY(3vh) scale(0.4);
  }

  61.5671641791% {
    transform: translateX(-45vw) translateY(11vh) scale(0.8);
  }

  61.9402985075% {
    transform: translateX(-40vw) translateY(32vh) scale(0.95);
  }

  62.3134328358% {
    transform: translateX(-2vw) translateY(22vh) scale(1);
  }

  62.6865671642% {
    transform: translateX(13vw) translateY(13vh) scale(0.97);
  }

  63.0597014925% {
    transform: translateX(-13vw) translateY(34vh) scale(0.43);
  }

  63.4328358209% {
    transform: translateX(21vw) translateY(12vh) scale(0.27);
  }

  63.8059701493% {
    transform: translateX(10vw) translateY(-48vh) scale(0.7);
  }

  64.1791044776% {
    transform: translateX(-47vw) translateY(-46vh) scale(0.63);
  }

  64.552238806% {
    transform: translateX(0vw) translateY(47vh) scale(0.91);
  }

  64.9253731343% {
    transform: translateX(30vw) translateY(-19vh) scale(0.83);
  }

  65.2985074627% {
    transform: translateX(32vw) translateY(8vh) scale(0.68);
  }

  65.671641791% {
    transform: translateX(-10vw) translateY(-26vh) scale(0.43);
  }

  66.0447761194% {
    transform: translateX(4vw) translateY(-36vh) scale(0.38);
  }

  66.4179104478% {
    transform: translateX(34vw) translateY(28vh) scale(0.83);
  }

  66.7910447761% {
    transform: translateX(-43vw) translateY(-41vh) scale(0.48);
  }

  67.1641791045% {
    transform: translateX(10vw) translateY(32vh) scale(0.51);
  }

  67.5373134328% {
    transform: translateX(29vw) translateY(40vh) scale(0.79);
  }

  67.9104477612% {
    transform: translateX(-14vw) translateY(-15vh) scale(0.39);
  }

  68.2835820896% {
    transform: translateX(-17vw) translateY(-17vh) scale(0.35);
  }

  68.6567164179% {
    transform: translateX(-22vw) translateY(-23vh) scale(0.45);
  }

  69.0298507463% {
    transform: translateX(-42vw) translateY(19vh) scale(0.65);
  }

  69.4029850746% {
    transform: translateX(42vw) translateY(21vh) scale(0.55);
  }

  69.776119403% {
    transform: translateX(-36vw) translateY(23vh) scale(0.39);
  }

  70.1492537313% {
    transform: translateX(-13vw) translateY(34vh) scale(0.48);
  }

  70.5223880597% {
    transform: translateX(5vw) translateY(16vh) scale(0.95);
  }

  70.8955223881% {
    transform: translateX(21vw) translateY(13vh) scale(0.34);
  }

  71.2686567164% {
    transform: translateX(26vw) translateY(15vh) scale(0.45);
  }

  71.6417910448% {
    transform: translateX(-21vw) translateY(-15vh) scale(0.99);
  }

  72.0149253731% {
    transform: translateX(-46vw) translateY(45vh) scale(0.86);
  }

  72.3880597015% {
    transform: translateX(-10vw) translateY(-16vh) scale(0.42);
  }

  72.7611940299% {
    transform: translateX(1vw) translateY(-22vh) scale(0.58);
  }

  73.1343283582% {
    transform: translateX(39vw) translateY(26vh) scale(0.85);
  }

  73.5074626866% {
    transform: translateX(-28vw) translateY(-41vh) scale(0.43);
  }

  73.8805970149% {
    transform: translateX(-28vw) translateY(-20vh) scale(0.79);
  }

  74.2537313433% {
    transform: translateX(-26vw) translateY(-10vh) scale(0.52);
  }

  74.6268656716% {
    transform: translateX(47vw) translateY(-45vh) scale(0.27);
  }

  75% {
    transform: translateX(35vw) translateY(29vh) scale(0.85);
  }

  75.3731343284% {
    transform: translateX(25vw) translateY(22vh) scale(0.46);
  }

  75.7462686567% {
    transform: translateX(46vw) translateY(-31vh) scale(0.3);
  }

  76.1194029851% {
    transform: translateX(-28vw) translateY(0vh) scale(0.8);
  }

  76.4925373134% {
    transform: translateX(49vw) translateY(3vh) scale(0.43);
  }

  76.8656716418% {
    transform: translateX(-17vw) translateY(-12vh) scale(0.6);
  }

  77.2388059701% {
    transform: translateX(-27vw) translateY(-43vh) scale(0.76);
  }

  77.6119402985% {
    transform: translateX(-5vw) translateY(49vh) scale(0.48);
  }

  77.9850746269% {
    transform: translateX(32vw) translateY(-25vh) scale(0.64);
  }

  78.3582089552% {
    transform: translateX(-14vw) translateY(34vh) scale(0.59);
  }

  78.7313432836% {
    transform: translateX(3vw) translateY(14vh) scale(0.74);
  }

  79.1044776119% {
    transform: translateX(-10vw) translateY(-33vh) scale(1);
  }

  79.4776119403% {
    transform: translateX(50vw) translateY(-34vh) scale(0.71);
  }

  79.8507462687% {
    transform: translateX(47vw) translateY(-28vh) scale(0.27);
  }

  80.223880597% {
    transform: translateX(-33vw) translateY(-14vh) scale(0.3);
  }

  80.5970149254% {
    transform: translateX(-15vw) translateY(-44vh) scale(0.64);
  }

  80.9701492537% {
    transform: translateX(-39vw) translateY(-3vh) scale(0.28);
  }

  81.3432835821% {
    transform: translateX(25vw) translateY(-31vh) scale(0.3);
  }

  81.7164179104% {
    transform: translateX(41vw) translateY(-14vh) scale(0.61);
  }

  82.0895522388% {
    transform: translateX(-32vw) translateY(44vh) scale(0.91);
  }

  82.4626865672% {
    transform: translateX(26vw) translateY(-9vh) scale(0.28);
  }

  82.8358208955% {
    transform: translateX(-1vw) translateY(-8vh) scale(0.84);
  }

  83.2089552239% {
    transform: translateX(-3vw) translateY(7vh) scale(0.81);
  }

  83.5820895522% {
    transform: translateX(13vw) translateY(49vh) scale(0.39);
  }

  83.9552238806% {
    transform: translateX(1vw) translateY(27vh) scale(0.3);
  }

  84.328358209% {
    transform: translateX(-3vw) translateY(-2vh) scale(0.72);
  }

  84.7014925373% {
    transform: translateX(-31vw) translateY(-26vh) scale(0.3);
  }

  85.0746268657% {
    transform: translateX(26vw) translateY(-15vh) scale(0.45);
  }

  85.447761194% {
    transform: translateX(-22vw) translateY(14vh) scale(0.82);
  }

  85.8208955224% {
    transform: translateX(-29vw) translateY(35vh) scale(0.91);
  }

  86.1940298507% {
    transform: translateX(-34vw) translateY(-18vh) scale(0.97);
  }

  86.5671641791% {
    transform: translateX(23vw) translateY(15vh) scale(0.39);
  }

  86.9402985075% {
    transform: translateX(22vw) translateY(14vh) scale(0.61);
  }

  87.3134328358% {
    transform: translateX(24vw) translateY(14vh) scale(0.92);
  }

  87.6865671642% {
    transform: translateX(-23vw) translateY(45vh) scale(0.96);
  }

  88.0597014925% {
    transform: translateX(10vw) translateY(-35vh) scale(1);
  }

  88.4328358209% {
    transform: translateX(-37vw) translateY(48vh) scale(0.9);
  }

  88.8059701493% {
    transform: translateX(8vw) translateY(50vh) scale(0.38);
  }

  89.1791044776% {
    transform: translateX(-36vw) translateY(-11vh) scale(0.32);
  }

  89.552238806% {
    transform: translateX(-41vw) translateY(34vh) scale(0.91);
  }

  89.9253731343% {
    transform: translateX(26vw) translateY(44vh) scale(0.32);
  }

  90.2985074627% {
    transform: translateX(-14vw) translateY(9vh) scale(0.58);
  }

  90.671641791% {
    transform: translateX(-47vw) translateY(27vh) scale(0.44);
  }

  91.0447761194% {
    transform: translateX(-23vw) translateY(-3vh) scale(0.69);
  }

  91.4179104478% {
    transform: translateX(21vw) translateY(-49vh) scale(0.28);
  }

  91.7910447761% {
    transform: translateX(-2vw) translateY(33vh) scale(0.51);
  }

  92.1641791045% {
    transform: translateX(-23vw) translateY(21vh) scale(0.94);
  }

  92.5373134328% {
    transform: translateX(-32vw) translateY(25vh) scale(0.3);
  }

  92.9104477612% {
    transform: translateX(29vw) translateY(-2vh) scale(0.38);
  }

  93.2835820896% {
    transform: translateX(19vw) translateY(-29vh) scale(0.91);
  }

  93.6567164179% {
    transform: translateX(-4vw) translateY(6vh) scale(0.31);
  }

  94.0298507463% {
    transform: translateX(-39vw) translateY(-32vh) scale(0.59);
  }

  94.4029850746% {
    transform: translateX(1vw) translateY(41vh) scale(0.56);
  }

  94.776119403% {
    transform: translateX(35vw) translateY(-16vh) scale(1);
  }

  95.1492537313% {
    transform: translateX(-35vw) translateY(31vh) scale(0.32);
  }

  95.5223880597% {
    transform: translateX(-41vw) translateY(-6vh) scale(0.92);
  }

  95.8955223881% {
    transform: translateX(-2vw) translateY(23vh) scale(0.9);
  }

  96.2686567164% {
    transform: translateX(-9vw) translateY(4vh) scale(0.79);
  }

  96.6417910448% {
    transform: translateX(-20vw) translateY(-42vh) scale(0.38);
  }

  97.0149253731% {
    transform: translateX(-41vw) translateY(-30vh) scale(0.78);
  }

  97.3880597015% {
    transform: translateX(-17vw) translateY(36vh) scale(0.79);
  }

  97.7611940299% {
    transform: translateX(43vw) translateY(-41vh) scale(0.74);
  }

  98.1343283582% {
    transform: translateX(20vw) translateY(-9vh) scale(0.97);
  }

  98.5074626866% {
    transform: translateX(-9vw) translateY(-46vh) scale(0.32);
  }

  98.8805970149% {
    transform: translateX(5vw) translateY(-25vh) scale(0.83);
  }

  99.2537313433% {
    transform: translateX(-26vw) translateY(14vh) scale(0.7);
  }

  99.6268656716% {
    transform: translateX(-8vw) translateY(6vh) scale(0.29);
  }

  100% {
    transform: translateX(18vw) translateY(3vh) scale(0.58);
  }
}

.firefly:nth-child(29) {
  animation-name: move29;
}

.firefly:nth-child(29)::before {
  animation-duration: 10s;
}

.firefly:nth-child(29)::after {
  animation-duration: 10s, 5034ms;
  animation-delay: 0ms, 6012ms;
}

@keyframes move29 {
  0% {
    transform: translateX(-48vw) translateY(-12vh) scale(0.77);
  }

  0.3610108303% {
    transform: translateX(-48vw) translateY(-42vh) scale(0.26);
  }

  0.7220216606% {
    transform: translateX(41vw) translateY(13vh) scale(0.76);
  }

  1.083032491% {
    transform: translateX(42vw) translateY(25vh) scale(0.73);
  }

  1.4440433213% {
    transform: translateX(-9vw) translateY(19vh) scale(0.33);
  }

  1.8050541516% {
    transform: translateX(-47vw) translateY(40vh) scale(0.71);
  }

  2.1660649819% {
    transform: translateX(50vw) translateY(35vh) scale(0.74);
  }

  2.5270758123% {
    transform: translateX(-26vw) translateY(-19vh) scale(0.76);
  }

  2.8880866426% {
    transform: translateX(-17vw) translateY(-38vh) scale(0.77);
  }

  3.2490974729% {
    transform: translateX(-28vw) translateY(26vh) scale(0.75);
  }

  3.6101083032% {
    transform: translateX(18vw) translateY(50vh) scale(0.48);
  }

  3.9711191336% {
    transform: translateX(-33vw) translateY(25vh) scale(0.29);
  }

  4.3321299639% {
    transform: translateX(29vw) translateY(36vh) scale(0.38);
  }

  4.6931407942% {
    transform: translateX(-18vw) translateY(40vh) scale(0.62);
  }

  5.0541516245% {
    transform: translateX(-47vw) translateY(-11vh) scale(0.47);
  }

  5.4151624549% {
    transform: translateX(-10vw) translateY(-6vh) scale(0.71);
  }

  5.7761732852% {
    transform: translateX(19vw) translateY(11vh) scale(0.98);
  }

  6.1371841155% {
    transform: translateX(25vw) translateY(14vh) scale(0.38);
  }

  6.4981949458% {
    transform: translateX(-39vw) translateY(-49vh) scale(0.61);
  }

  6.8592057762% {
    transform: translateX(39vw) translateY(-37vh) scale(0.74);
  }

  7.2202166065% {
    transform: translateX(-5vw) translateY(46vh) scale(0.91);
  }

  7.5812274368% {
    transform: translateX(5vw) translateY(-5vh) scale(0.42);
  }

  7.9422382671% {
    transform: translateX(-21vw) translateY(-15vh) scale(0.61);
  }

  8.3032490975% {
    transform: translateX(16vw) translateY(-21vh) scale(0.36);
  }

  8.6642599278% {
    transform: translateX(-19vw) translateY(47vh) scale(0.43);
  }

  9.0252707581% {
    transform: translateX(45vw) translateY(42vh) scale(0.48);
  }

  9.3862815884% {
    transform: translateX(36vw) translateY(35vh) scale(0.53);
  }

  9.7472924188% {
    transform: translateX(19vw) translateY(-31vh) scale(0.61);
  }

  10.1083032491% {
    transform: translateX(13vw) translateY(13vh) scale(0.67);
  }

  10.4693140794% {
    transform: translateX(-48vw) translateY(19vh) scale(0.27);
  }

  10.8303249097% {
    transform: translateX(-42vw) translateY(-48vh) scale(0.74);
  }

  11.1913357401% {
    transform: translateX(31vw) translateY(-47vh) scale(0.89);
  }

  11.5523465704% {
    transform: translateX(-46vw) translateY(-3vh) scale(0.77);
  }

  11.9133574007% {
    transform: translateX(-24vw) translateY(-32vh) scale(0.69);
  }

  12.274368231% {
    transform: translateX(-32vw) translateY(3vh) scale(0.49);
  }

  12.6353790614% {
    transform: translateX(44vw) translateY(-23vh) scale(0.68);
  }

  12.9963898917% {
    transform: translateX(49vw) translateY(-37vh) scale(0.97);
  }

  13.357400722% {
    transform: translateX(46vw) translateY(-17vh) scale(0.27);
  }

  13.7184115523% {
    transform: translateX(-39vw) translateY(-5vh) scale(0.67);
  }

  14.0794223827% {
    transform: translateX(-47vw) translateY(-44vh) scale(0.55);
  }

  14.440433213% {
    transform: translateX(-10vw) translateY(8vh) scale(0.68);
  }

  14.8014440433% {
    transform: translateX(0vw) translateY(41vh) scale(0.3);
  }

  15.1624548736% {
    transform: translateX(2vw) translateY(21vh) scale(0.44);
  }

  15.523465704% {
    transform: translateX(20vw) translateY(7vh) scale(0.89);
  }

  15.8844765343% {
    transform: translateX(13vw) translateY(49vh) scale(0.31);
  }

  16.2454873646% {
    transform: translateX(16vw) translateY(-15vh) scale(0.54);
  }

  16.6064981949% {
    transform: translateX(15vw) translateY(42vh) scale(0.37);
  }

  16.9675090253% {
    transform: translateX(-33vw) translateY(-40vh) scale(0.72);
  }

  17.3285198556% {
    transform: translateX(8vw) translateY(-33vh) scale(1);
  }

  17.6895306859% {
    transform: translateX(43vw) translateY(47vh) scale(0.81);
  }

  18.0505415162% {
    transform: translateX(4vw) translateY(-19vh) scale(0.79);
  }

  18.4115523466% {
    transform: translateX(-3vw) translateY(-5vh) scale(0.45);
  }

  18.7725631769% {
    transform: translateX(-32vw) translateY(2vh) scale(0.94);
  }

  19.1335740072% {
    transform: translateX(47vw) translateY(4vh) scale(0.63);
  }

  19.4945848375% {
    transform: translateX(-23vw) translateY(18vh) scale(0.42);
  }

  19.8555956679% {
    transform: translateX(-44vw) translateY(-44vh) scale(0.34);
  }

  20.2166064982% {
    transform: translateX(7vw) translateY(2vh) scale(0.75);
  }

  20.5776173285% {
    transform: translateX(-37vw) translateY(-38vh) scale(1);
  }

  20.9386281588% {
    transform: translateX(20vw) translateY(-41vh) scale(0.92);
  }

  21.2996389892% {
    transform: translateX(-16vw) translateY(-14vh) scale(0.84);
  }

  21.6606498195% {
    transform: translateX(-5vw) translateY(-6vh) scale(0.96);
  }

  22.0216606498% {
    transform: translateX(12vw) translateY(19vh) scale(0.53);
  }

  22.3826714801% {
    transform: translateX(-32vw) translateY(5vh) scale(0.34);
  }

  22.7436823105% {
    transform: translateX(17vw) translateY(-28vh) scale(0.35);
  }

  23.1046931408% {
    transform: translateX(-11vw) translateY(23vh) scale(0.79);
  }

  23.4657039711% {
    transform: translateX(46vw) translateY(17vh) scale(0.43);
  }

  23.8267148014% {
    transform: translateX(20vw) translateY(22vh) scale(0.58);
  }

  24.1877256318% {
    transform: translateX(33vw) translateY(-6vh) scale(0.96);
  }

  24.5487364621% {
    transform: translateX(-39vw) translateY(37vh) scale(0.37);
  }

  24.9097472924% {
    transform: translateX(32vw) translateY(-2vh) scale(0.81);
  }

  25.2707581227% {
    transform: translateX(38vw) translateY(18vh) scale(0.72);
  }

  25.6317689531% {
    transform: translateX(-34vw) translateY(15vh) scale(0.73);
  }

  25.9927797834% {
    transform: translateX(-20vw) translateY(9vh) scale(0.86);
  }

  26.3537906137% {
    transform: translateX(-31vw) translateY(26vh) scale(0.63);
  }

  26.714801444% {
    transform: translateX(13vw) translateY(50vh) scale(0.82);
  }

  27.0758122744% {
    transform: translateX(27vw) translateY(-32vh) scale(0.27);
  }

  27.4368231047% {
    transform: translateX(13vw) translateY(-26vh) scale(0.5);
  }

  27.797833935% {
    transform: translateX(41vw) translateY(34vh) scale(0.85);
  }

  28.1588447653% {
    transform: translateX(42vw) translateY(-4vh) scale(0.4);
  }

  28.5198555957% {
    transform: translateX(30vw) translateY(-17vh) scale(0.46);
  }

  28.880866426% {
    transform: translateX(42vw) translateY(-1vh) scale(0.32);
  }

  29.2418772563% {
    transform: translateX(-20vw) translateY(24vh) scale(0.74);
  }

  29.6028880866% {
    transform: translateX(34vw) translateY(-34vh) scale(0.59);
  }

  29.963898917% {
    transform: translateX(11vw) translateY(-7vh) scale(0.4);
  }

  30.3249097473% {
    transform: translateX(24vw) translateY(50vh) scale(0.96);
  }

  30.6859205776% {
    transform: translateX(-38vw) translateY(-38vh) scale(0.6);
  }

  31.0469314079% {
    transform: translateX(48vw) translateY(-26vh) scale(0.67);
  }

  31.4079422383% {
    transform: translateX(-17vw) translateY(-39vh) scale(0.33);
  }

  31.7689530686% {
    transform: translateX(-7vw) translateY(31vh) scale(0.38);
  }

  32.1299638989% {
    transform: translateX(31vw) translateY(2vh) scale(0.45);
  }

  32.4909747292% {
    transform: translateX(-39vw) translateY(-19vh) scale(0.49);
  }

  32.8519855596% {
    transform: translateX(-9vw) translateY(46vh) scale(0.78);
  }

  33.2129963899% {
    transform: translateX(44vw) translateY(4vh) scale(0.42);
  }

  33.5740072202% {
    transform: translateX(-12vw) translateY(-31vh) scale(0.75);
  }

  33.9350180505% {
    transform: translateX(18vw) translateY(16vh) scale(0.85);
  }

  34.2960288809% {
    transform: translateX(32vw) translateY(-10vh) scale(0.65);
  }

  34.6570397112% {
    transform: translateX(7vw) translateY(25vh) scale(0.87);
  }

  35.0180505415% {
    transform: translateX(-18vw) translateY(44vh) scale(0.31);
  }

  35.3790613718% {
    transform: translateX(-32vw) translateY(37vh) scale(0.65);
  }

  35.7400722022% {
    transform: translateX(-45vw) translateY(-8vh) scale(0.74);
  }

  36.1010830325% {
    transform: translateX(16vw) translateY(-18vh) scale(0.31);
  }

  36.4620938628% {
    transform: translateX(-19vw) translateY(38vh) scale(0.44);
  }

  36.8231046931% {
    transform: translateX(-5vw) translateY(-48vh) scale(0.85);
  }

  37.1841155235% {
    transform: translateX(45vw) translateY(-39vh) scale(0.94);
  }

  37.5451263538% {
    transform: translateX(24vw) translateY(-11vh) scale(0.85);
  }

  37.9061371841% {
    transform: translateX(3vw) translateY(12vh) scale(0.69);
  }

  38.2671480144% {
    transform: translateX(-49vw) translateY(19vh) scale(0.79);
  }

  38.6281588448% {
    transform: translateX(47vw) translateY(-8vh) scale(0.55);
  }

  38.9891696751% {
    transform: translateX(-29vw) translateY(7vh) scale(0.61);
  }

  39.3501805054% {
    transform: translateX(37vw) translateY(14vh) scale(0.52);
  }

  39.7111913357% {
    transform: translateX(-36vw) translateY(9vh) scale(0.85);
  }

  40.0722021661% {
    transform: translateX(48vw) translateY(-2vh) scale(0.74);
  }

  40.4332129964% {
    transform: translateX(-40vw) translateY(-10vh) scale(0.65);
  }

  40.7942238267% {
    transform: translateX(40vw) translateY(2vh) scale(0.88);
  }

  41.155234657% {
    transform: translateX(-23vw) translateY(26vh) scale(0.86);
  }

  41.5162454874% {
    transform: translateX(27vw) translateY(34vh) scale(0.68);
  }

  41.8772563177% {
    transform: translateX(8vw) translateY(-28vh) scale(0.68);
  }

  42.238267148% {
    transform: translateX(-27vw) translateY(38vh) scale(0.46);
  }

  42.5992779783% {
    transform: translateX(11vw) translateY(-32vh) scale(0.97);
  }

  42.9602888087% {
    transform: translateX(-24vw) translateY(24vh) scale(0.85);
  }

  43.321299639% {
    transform: translateX(40vw) translateY(-15vh) scale(0.76);
  }

  43.6823104693% {
    transform: translateX(-3vw) translateY(30vh) scale(0.91);
  }

  44.0433212996% {
    transform: translateX(-44vw) translateY(-26vh) scale(0.47);
  }

  44.40433213% {
    transform: translateX(13vw) translateY(-22vh) scale(1);
  }

  44.7653429603% {
    transform: translateX(-20vw) translateY(48vh) scale(0.3);
  }

  45.1263537906% {
    transform: translateX(-21vw) translateY(-46vh) scale(0.67);
  }

  45.4873646209% {
    transform: translateX(13vw) translateY(-38vh) scale(0.44);
  }

  45.8483754513% {
    transform: translateX(-12vw) translateY(15vh) scale(0.49);
  }

  46.2093862816% {
    transform: translateX(26vw) translateY(-29vh) scale(0.45);
  }

  46.5703971119% {
    transform: translateX(-43vw) translateY(47vh) scale(0.78);
  }

  46.9314079422% {
    transform: translateX(44vw) translateY(23vh) scale(0.6);
  }

  47.2924187726% {
    transform: translateX(-29vw) translateY(22vh) scale(0.99);
  }

  47.6534296029% {
    transform: translateX(29vw) translateY(26vh) scale(0.98);
  }

  48.0144404332% {
    transform: translateX(33vw) translateY(4vh) scale(0.53);
  }

  48.3754512635% {
    transform: translateX(-10vw) translateY(-40vh) scale(0.99);
  }

  48.7364620939% {
    transform: translateX(3vw) translateY(-47vh) scale(0.75);
  }

  49.0974729242% {
    transform: translateX(25vw) translateY(-38vh) scale(0.72);
  }

  49.4584837545% {
    transform: translateX(11vw) translateY(34vh) scale(0.96);
  }

  49.8194945848% {
    transform: translateX(-26vw) translateY(0vh) scale(0.84);
  }

  50.1805054152% {
    transform: translateX(43vw) translateY(41vh) scale(0.81);
  }

  50.5415162455% {
    transform: translateX(22vw) translateY(33vh) scale(0.43);
  }

  50.9025270758% {
    transform: translateX(16vw) translateY(32vh) scale(0.3);
  }

  51.2635379061% {
    transform: translateX(39vw) translateY(-1vh) scale(0.29);
  }

  51.6245487365% {
    transform: translateX(12vw) translateY(-3vh) scale(0.87);
  }

  51.9855595668% {
    transform: translateX(-29vw) translateY(40vh) scale(0.79);
  }

  52.3465703971% {
    transform: translateX(48vw) translateY(15vh) scale(0.44);
  }

  52.7075812274% {
    transform: translateX(46vw) translateY(-37vh) scale(0.95);
  }

  53.0685920578% {
    transform: translateX(23vw) translateY(6vh) scale(0.64);
  }

  53.4296028881% {
    transform: translateX(-34vw) translateY(-5vh) scale(0.58);
  }

  53.7906137184% {
    transform: translateX(-46vw) translateY(-30vh) scale(0.89);
  }

  54.1516245487% {
    transform: translateX(-18vw) translateY(-18vh) scale(0.47);
  }

  54.5126353791% {
    transform: translateX(-16vw) translateY(-15vh) scale(0.87);
  }

  54.8736462094% {
    transform: translateX(32vw) translateY(-15vh) scale(0.36);
  }

  55.2346570397% {
    transform: translateX(-34vw) translateY(20vh) scale(0.72);
  }

  55.59566787% {
    transform: translateX(-33vw) translateY(-11vh) scale(0.39);
  }

  55.9566787004% {
    transform: translateX(19vw) translateY(33vh) scale(0.88);
  }

  56.3176895307% {
    transform: translateX(-1vw) translateY(27vh) scale(0.53);
  }

  56.678700361% {
    transform: translateX(-11vw) translateY(-47vh) scale(0.71);
  }

  57.0397111913% {
    transform: translateX(-28vw) translateY(-23vh) scale(0.55);
  }

  57.4007220217% {
    transform: translateX(-12vw) translateY(27vh) scale(0.27);
  }

  57.761732852% {
    transform: translateX(34vw) translateY(8vh) scale(0.77);
  }

  58.1227436823% {
    transform: translateX(-39vw) translateY(-26vh) scale(0.74);
  }

  58.4837545126% {
    transform: translateX(6vw) translateY(43vh) scale(0.51);
  }

  58.844765343% {
    transform: translateX(-4vw) translateY(-28vh) scale(0.78);
  }

  59.2057761733% {
    transform: translateX(-7vw) translateY(37vh) scale(0.81);
  }

  59.5667870036% {
    transform: translateX(22vw) translateY(-15vh) scale(0.57);
  }

  59.9277978339% {
    transform: translateX(30vw) translateY(-8vh) scale(0.34);
  }

  60.2888086643% {
    transform: translateX(-17vw) translateY(-38vh) scale(0.85);
  }

  60.6498194946% {
    transform: translateX(13vw) translateY(-39vh) scale(0.43);
  }

  61.0108303249% {
    transform: translateX(9vw) translateY(16vh) scale(0.74);
  }

  61.3718411552% {
    transform: translateX(2vw) translateY(8vh) scale(0.31);
  }

  61.7328519856% {
    transform: translateX(-40vw) translateY(2vh) scale(0.92);
  }

  62.0938628159% {
    transform: translateX(-20vw) translateY(-7vh) scale(0.56);
  }

  62.4548736462% {
    transform: translateX(22vw) translateY(19vh) scale(0.63);
  }

  62.8158844765% {
    transform: translateX(41vw) translateY(44vh) scale(0.76);
  }

  63.1768953069% {
    transform: translateX(18vw) translateY(-35vh) scale(0.96);
  }

  63.5379061372% {
    transform: translateX(-30vw) translateY(-13vh) scale(0.91);
  }

  63.8989169675% {
    transform: translateX(-42vw) translateY(50vh) scale(0.55);
  }

  64.2599277978% {
    transform: translateX(49vw) translateY(20vh) scale(0.92);
  }

  64.6209386282% {
    transform: translateX(-27vw) translateY(20vh) scale(0.43);
  }

  64.9819494585% {
    transform: translateX(8vw) translateY(43vh) scale(0.72);
  }

  65.3429602888% {
    transform: translateX(17vw) translateY(-46vh) scale(0.79);
  }

  65.7039711191% {
    transform: translateX(-28vw) translateY(-37vh) scale(0.71);
  }

  66.0649819495% {
    transform: translateX(-48vw) translateY(45vh) scale(0.88);
  }

  66.4259927798% {
    transform: translateX(24vw) translateY(-14vh) scale(0.73);
  }

  66.7870036101% {
    transform: translateX(26vw) translateY(-15vh) scale(0.85);
  }

  67.1480144404% {
    transform: translateX(-25vw) translateY(-36vh) scale(0.9);
  }

  67.5090252708% {
    transform: translateX(27vw) translateY(1vh) scale(0.51);
  }

  67.8700361011% {
    transform: translateX(8vw) translateY(-48vh) scale(0.36);
  }

  68.2310469314% {
    transform: translateX(-35vw) translateY(5vh) scale(0.52);
  }

  68.5920577617% {
    transform: translateX(-25vw) translateY(48vh) scale(0.76);
  }

  68.9530685921% {
    transform: translateX(-27vw) translateY(9vh) scale(0.42);
  }

  69.3140794224% {
    transform: translateX(36vw) translateY(-32vh) scale(0.7);
  }

  69.6750902527% {
    transform: translateX(-18vw) translateY(-29vh) scale(0.91);
  }

  70.036101083% {
    transform: translateX(45vw) translateY(-35vh) scale(0.95);
  }

  70.3971119134% {
    transform: translateX(33vw) translateY(-4vh) scale(0.41);
  }

  70.7581227437% {
    transform: translateX(-15vw) translateY(-40vh) scale(0.47);
  }

  71.119133574% {
    transform: translateX(12vw) translateY(-11vh) scale(0.39);
  }

  71.4801444043% {
    transform: translateX(48vw) translateY(-7vh) scale(0.92);
  }

  71.8411552347% {
    transform: translateX(22vw) translateY(-19vh) scale(0.59);
  }

  72.202166065% {
    transform: translateX(10vw) translateY(41vh) scale(0.7);
  }

  72.5631768953% {
    transform: translateX(-21vw) translateY(34vh) scale(0.69);
  }

  72.9241877256% {
    transform: translateX(31vw) translateY(-2vh) scale(0.76);
  }

  73.285198556% {
    transform: translateX(-3vw) translateY(-12vh) scale(0.47);
  }

  73.6462093863% {
    transform: translateX(37vw) translateY(20vh) scale(0.62);
  }

  74.0072202166% {
    transform: translateX(13vw) translateY(-32vh) scale(0.29);
  }

  74.3682310469% {
    transform: translateX(-47vw) translateY(45vh) scale(0.54);
  }

  74.7292418773% {
    transform: translateX(46vw) translateY(26vh) scale(0.47);
  }

  75.0902527076% {
    transform: translateX(4vw) translateY(-37vh) scale(0.52);
  }

  75.4512635379% {
    transform: translateX(41vw) translateY(3vh) scale(0.42);
  }

  75.8122743682% {
    transform: translateX(-42vw) translateY(-39vh) scale(0.49);
  }

  76.1732851986% {
    transform: translateX(1vw) translateY(38vh) scale(1);
  }

  76.5342960289% {
    transform: translateX(-44vw) translateY(-7vh) scale(0.34);
  }

  76.8953068592% {
    transform: translateX(-27vw) translateY(16vh) scale(0.67);
  }

  77.2563176895% {
    transform: translateX(38vw) translateY(-28vh) scale(0.93);
  }

  77.6173285199% {
    transform: translateX(39vw) translateY(-23vh) scale(0.49);
  }

  77.9783393502% {
    transform: translateX(44vw) translateY(-12vh) scale(0.39);
  }

  78.3393501805% {
    transform: translateX(38vw) translateY(-7vh) scale(0.46);
  }

  78.7003610108% {
    transform: translateX(47vw) translateY(-34vh) scale(0.64);
  }

  79.0613718412% {
    transform: translateX(14vw) translateY(27vh) scale(0.95);
  }

  79.4223826715% {
    transform: translateX(16vw) translateY(-7vh) scale(0.77);
  }

  79.7833935018% {
    transform: translateX(-24vw) translateY(-10vh) scale(0.36);
  }

  80.1444043321% {
    transform: translateX(3vw) translateY(7vh) scale(0.27);
  }

  80.5054151625% {
    transform: translateX(-13vw) translateY(-31vh) scale(0.79);
  }

  80.8664259928% {
    transform: translateX(41vw) translateY(-45vh) scale(0.88);
  }

  81.2274368231% {
    transform: translateX(37vw) translateY(-32vh) scale(0.64);
  }

  81.5884476534% {
    transform: translateX(-18vw) translateY(23vh) scale(1);
  }

  81.9494584838% {
    transform: translateX(-10vw) translateY(40vh) scale(0.66);
  }

  82.3104693141% {
    transform: translateX(45vw) translateY(-3vh) scale(0.33);
  }

  82.6714801444% {
    transform: translateX(-27vw) translateY(-17vh) scale(0.89);
  }

  83.0324909747% {
    transform: translateX(36vw) translateY(-17vh) scale(0.66);
  }

  83.3935018051% {
    transform: translateX(-12vw) translateY(-30vh) scale(0.7);
  }

  83.7545126354% {
    transform: translateX(-42vw) translateY(-47vh) scale(0.68);
  }

  84.1155234657% {
    transform: translateX(-6vw) translateY(-30vh) scale(0.56);
  }

  84.476534296% {
    transform: translateX(50vw) translateY(31vh) scale(0.79);
  }

  84.8375451264% {
    transform: translateX(-12vw) translateY(29vh) scale(0.55);
  }

  85.1985559567% {
    transform: translateX(39vw) translateY(-6vh) scale(0.74);
  }

  85.559566787% {
    transform: translateX(-33vw) translateY(28vh) scale(0.39);
  }

  85.9205776173% {
    transform: translateX(8vw) translateY(36vh) scale(0.69);
  }

  86.2815884477% {
    transform: translateX(-14vw) translateY(-32vh) scale(0.31);
  }

  86.642599278% {
    transform: translateX(-28vw) translateY(-29vh) scale(0.26);
  }

  87.0036101083% {
    transform: translateX(-45vw) translateY(6vh) scale(0.33);
  }

  87.3646209386% {
    transform: translateX(-17vw) translateY(-9vh) scale(0.66);
  }

  87.725631769% {
    transform: translateX(34vw) translateY(-46vh) scale(1);
  }

  88.0866425993% {
    transform: translateX(6vw) translateY(12vh) scale(0.71);
  }

  88.4476534296% {
    transform: translateX(24vw) translateY(-6vh) scale(0.4);
  }

  88.8086642599% {
    transform: translateX(-21vw) translateY(-18vh) scale(0.74);
  }

  89.1696750903% {
    transform: translateX(31vw) translateY(-21vh) scale(0.47);
  }

  89.5306859206% {
    transform: translateX(-32vw) translateY(-10vh) scale(0.51);
  }

  89.8916967509% {
    transform: translateX(1vw) translateY(39vh) scale(0.44);
  }

  90.2527075812% {
    transform: translateX(36vw) translateY(-45vh) scale(0.41);
  }

  90.6137184116% {
    transform: translateX(46vw) translateY(-19vh) scale(0.6);
  }

  90.9747292419% {
    transform: translateX(-37vw) translateY(38vh) scale(0.42);
  }

  91.3357400722% {
    transform: translateX(-22vw) translateY(-12vh) scale(0.29);
  }

  91.6967509025% {
    transform: translateX(47vw) translateY(-9vh) scale(0.67);
  }

  92.0577617329% {
    transform: translateX(-48vw) translateY(11vh) scale(0.39);
  }

  92.4187725632% {
    transform: translateX(11vw) translateY(1vh) scale(0.8);
  }

  92.7797833935% {
    transform: translateX(-31vw) translateY(35vh) scale(0.28);
  }

  93.1407942238% {
    transform: translateX(38vw) translateY(-32vh) scale(0.63);
  }

  93.5018050542% {
    transform: translateX(29vw) translateY(-6vh) scale(0.35);
  }

  93.8628158845% {
    transform: translateX(-4vw) translateY(38vh) scale(0.32);
  }

  94.2238267148% {
    transform: translateX(23vw) translateY(-48vh) scale(0.84);
  }

  94.5848375451% {
    transform: translateX(36vw) translateY(-32vh) scale(0.74);
  }

  94.9458483755% {
    transform: translateX(37vw) translateY(-41vh) scale(0.99);
  }

  95.3068592058% {
    transform: translateX(44vw) translateY(-4vh) scale(0.73);
  }

  95.6678700361% {
    transform: translateX(-3vw) translateY(26vh) scale(0.62);
  }

  96.0288808664% {
    transform: translateX(-12vw) translateY(-13vh) scale(0.43);
  }

  96.3898916968% {
    transform: translateX(32vw) translateY(46vh) scale(0.88);
  }

  96.7509025271% {
    transform: translateX(48vw) translateY(22vh) scale(0.78);
  }

  97.1119133574% {
    transform: translateX(-35vw) translateY(22vh) scale(0.66);
  }

  97.4729241877% {
    transform: translateX(4vw) translateY(-34vh) scale(0.59);
  }

  97.8339350181% {
    transform: translateX(-49vw) translateY(-22vh) scale(0.68);
  }

  98.1949458484% {
    transform: translateX(10vw) translateY(50vh) scale(0.47);
  }

  98.5559566787% {
    transform: translateX(36vw) translateY(29vh) scale(1);
  }

  98.916967509% {
    transform: translateX(-20vw) translateY(11vh) scale(0.55);
  }

  99.2779783394% {
    transform: translateX(11vw) translateY(-27vh) scale(0.47);
  }

  99.6389891697% {
    transform: translateX(-47vw) translateY(-21vh) scale(0.7);
  }

  100% {
    transform: translateX(-10vw) translateY(15vh) scale(0.71);
  }
}

.firefly:nth-child(30) {
  animation-name: move30;
}

.firefly:nth-child(30)::before {
  animation-duration: 18s;
}

.firefly:nth-child(30)::after {
  animation-duration: 18s, 6540ms;
  animation-delay: 0ms, 4747ms;
}

@keyframes move30 {
  0% {
    transform: translateX(-20vw) translateY(-7vh) scale(0.84);
  }

  0.5555555556% {
    transform: translateX(-13vw) translateY(33vh) scale(0.31);
  }

  1.1111111111% {
    transform: translateX(-34vw) translateY(-29vh) scale(0.85);
  }

  1.6666666667% {
    transform: translateX(-38vw) translateY(-37vh) scale(0.91);
  }

  2.2222222222% {
    transform: translateX(42vw) translateY(42vh) scale(0.77);
  }

  2.7777777778% {
    transform: translateX(-49vw) translateY(-43vh) scale(0.99);
  }

  3.3333333333% {
    transform: translateX(49vw) translateY(13vh) scale(0.71);
  }

  3.8888888889% {
    transform: translateX(32vw) translateY(-22vh) scale(0.49);
  }

  4.4444444444% {
    transform: translateX(-7vw) translateY(4vh) scale(0.81);
  }

  5% {
    transform: translateX(-22vw) translateY(-32vh) scale(0.76);
  }

  5.5555555556% {
    transform: translateX(36vw) translateY(-4vh) scale(0.82);
  }

  6.1111111111% {
    transform: translateX(6vw) translateY(19vh) scale(0.72);
  }

  6.6666666667% {
    transform: translateX(4vw) translateY(32vh) scale(0.88);
  }

  7.2222222222% {
    transform: translateX(19vw) translateY(-5vh) scale(0.38);
  }

  7.7777777778% {
    transform: translateX(29vw) translateY(-39vh) scale(0.5);
  }

  8.3333333333% {
    transform: translateX(21vw) translateY(-21vh) scale(0.63);
  }

  8.8888888889% {
    transform: translateX(-37vw) translateY(-14vh) scale(0.47);
  }

  9.4444444444% {
    transform: translateX(31vw) translateY(-36vh) scale(0.69);
  }

  10% {
    transform: translateX(5vw) translateY(-4vh) scale(0.37);
  }

  10.5555555556% {
    transform: translateX(40vw) translateY(36vh) scale(0.42);
  }

  11.1111111111% {
    transform: translateX(19vw) translateY(49vh) scale(0.28);
  }

  11.6666666667% {
    transform: translateX(-28vw) translateY(-30vh) scale(0.8);
  }

  12.2222222222% {
    transform: translateX(34vw) translateY(9vh) scale(0.5);
  }

  12.7777777778% {
    transform: translateX(-44vw) translateY(-27vh) scale(0.86);
  }

  13.3333333333% {
    transform: translateX(-45vw) translateY(37vh) scale(0.7);
  }

  13.8888888889% {
    transform: translateX(1vw) translateY(-30vh) scale(0.32);
  }

  14.4444444444% {
    transform: translateX(11vw) translateY(7vh) scale(0.45);
  }

  15% {
    transform: translateX(35vw) translateY(47vh) scale(0.33);
  }

  15.5555555556% {
    transform: translateX(-42vw) translateY(5vh) scale(0.63);
  }

  16.1111111111% {
    transform: translateX(-39vw) translateY(44vh) scale(0.34);
  }

  16.6666666667% {
    transform: translateX(-42vw) translateY(3vh) scale(0.43);
  }

  17.2222222222% {
    transform: translateX(-4vw) translateY(44vh) scale(0.64);
  }

  17.7777777778% {
    transform: translateX(20vw) translateY(-36vh) scale(0.58);
  }

  18.3333333333% {
    transform: translateX(-2vw) translateY(-42vh) scale(0.84);
  }

  18.8888888889% {
    transform: translateX(-48vw) translateY(21vh) scale(0.39);
  }

  19.4444444444% {
    transform: translateX(21vw) translateY(48vh) scale(0.89);
  }

  20% {
    transform: translateX(-31vw) translateY(37vh) scale(0.99);
  }

  20.5555555556% {
    transform: translateX(8vw) translateY(24vh) scale(0.52);
  }

  21.1111111111% {
    transform: translateX(-35vw) translateY(-11vh) scale(0.63);
  }

  21.6666666667% {
    transform: translateX(-6vw) translateY(-11vh) scale(0.35);
  }

  22.2222222222% {
    transform: translateX(-45vw) translateY(-36vh) scale(0.62);
  }

  22.7777777778% {
    transform: translateX(-3vw) translateY(-22vh) scale(0.79);
  }

  23.3333333333% {
    transform: translateX(-11vw) translateY(41vh) scale(0.35);
  }

  23.8888888889% {
    transform: translateX(41vw) translateY(-6vh) scale(0.59);
  }

  24.4444444444% {
    transform: translateX(44vw) translateY(26vh) scale(0.46);
  }

  25% {
    transform: translateX(2vw) translateY(46vh) scale(0.44);
  }

  25.5555555556% {
    transform: translateX(26vw) translateY(-45vh) scale(0.57);
  }

  26.1111111111% {
    transform: translateX(-12vw) translateY(-13vh) scale(0.88);
  }

  26.6666666667% {
    transform: translateX(44vw) translateY(40vh) scale(0.78);
  }

  27.2222222222% {
    transform: translateX(-23vw) translateY(48vh) scale(0.49);
  }

  27.7777777778% {
    transform: translateX(-21vw) translateY(34vh) scale(0.46);
  }

  28.3333333333% {
    transform: translateX(3vw) translateY(-45vh) scale(0.75);
  }

  28.8888888889% {
    transform: translateX(35vw) translateY(50vh) scale(0.35);
  }

  29.4444444444% {
    transform: translateX(-13vw) translateY(33vh) scale(0.29);
  }

  30% {
    transform: translateX(31vw) translateY(-35vh) scale(0.78);
  }

  30.5555555556% {
    transform: translateX(-48vw) translateY(-23vh) scale(0.82);
  }

  31.1111111111% {
    transform: translateX(-44vw) translateY(-16vh) scale(0.92);
  }

  31.6666666667% {
    transform: translateX(-19vw) translateY(-46vh) scale(0.74);
  }

  32.2222222222% {
    transform: translateX(6vw) translateY(-32vh) scale(0.5);
  }

  32.7777777778% {
    transform: translateX(-14vw) translateY(-37vh) scale(1);
  }

  33.3333333333% {
    transform: translateX(-19vw) translateY(50vh) scale(0.69);
  }

  33.8888888889% {
    transform: translateX(-14vw) translateY(47vh) scale(0.92);
  }

  34.4444444444% {
    transform: translateX(-23vw) translateY(-45vh) scale(0.35);
  }

  35% {
    transform: translateX(39vw) translateY(30vh) scale(0.58);
  }

  35.5555555556% {
    transform: translateX(-48vw) translateY(-13vh) scale(0.39);
  }

  36.1111111111% {
    transform: translateX(-33vw) translateY(-40vh) scale(0.62);
  }

  36.6666666667% {
    transform: translateX(26vw) translateY(-15vh) scale(0.74);
  }

  37.2222222222% {
    transform: translateX(20vw) translateY(28vh) scale(0.88);
  }

  37.7777777778% {
    transform: translateX(-6vw) translateY(-43vh) scale(0.85);
  }

  38.3333333333% {
    transform: translateX(36vw) translateY(-7vh) scale(0.78);
  }

  38.8888888889% {
    transform: translateX(26vw) translateY(10vh) scale(0.76);
  }

  39.4444444444% {
    transform: translateX(17vw) translateY(42vh) scale(0.87);
  }

  40% {
    transform: translateX(8vw) translateY(25vh) scale(0.88);
  }

  40.5555555556% {
    transform: translateX(-49vw) translateY(-15vh) scale(0.5);
  }

  41.1111111111% {
    transform: translateX(-12vw) translateY(-47vh) scale(0.65);
  }

  41.6666666667% {
    transform: translateX(-17vw) translateY(-20vh) scale(0.38);
  }

  42.2222222222% {
    transform: translateX(-7vw) translateY(14vh) scale(0.35);
  }

  42.7777777778% {
    transform: translateX(41vw) translateY(49vh) scale(0.61);
  }

  43.3333333333% {
    transform: translateX(17vw) translateY(-12vh) scale(0.56);
  }

  43.8888888889% {
    transform: translateX(-17vw) translateY(38vh) scale(0.35);
  }

  44.4444444444% {
    transform: translateX(2vw) translateY(24vh) scale(0.89);
  }

  45% {
    transform: translateX(-30vw) translateY(35vh) scale(0.45);
  }

  45.5555555556% {
    transform: translateX(38vw) translateY(32vh) scale(0.63);
  }

  46.1111111111% {
    transform: translateX(16vw) translateY(42vh) scale(0.78);
  }

  46.6666666667% {
    transform: translateX(12vw) translateY(19vh) scale(0.64);
  }

  47.2222222222% {
    transform: translateX(-11vw) translateY(43vh) scale(0.32);
  }

  47.7777777778% {
    transform: translateX(13vw) translateY(-6vh) scale(0.4);
  }

  48.3333333333% {
    transform: translateX(23vw) translateY(-24vh) scale(0.72);
  }

  48.8888888889% {
    transform: translateX(25vw) translateY(46vh) scale(0.84);
  }

  49.4444444444% {
    transform: translateX(46vw) translateY(5vh) scale(0.95);
  }

  50% {
    transform: translateX(-1vw) translateY(-18vh) scale(0.75);
  }

  50.5555555556% {
    transform: translateX(-10vw) translateY(33vh) scale(0.86);
  }

  51.1111111111% {
    transform: translateX(-44vw) translateY(-41vh) scale(0.66);
  }

  51.6666666667% {
    transform: translateX(-14vw) translateY(13vh) scale(0.67);
  }

  52.2222222222% {
    transform: translateX(29vw) translateY(-16vh) scale(0.91);
  }

  52.7777777778% {
    transform: translateX(1vw) translateY(12vh) scale(0.4);
  }

  53.3333333333% {
    transform: translateX(38vw) translateY(2vh) scale(0.31);
  }

  53.8888888889% {
    transform: translateX(-27vw) translateY(-11vh) scale(0.68);
  }

  54.4444444444% {
    transform: translateX(-8vw) translateY(-1vh) scale(0.88);
  }

  55% {
    transform: translateX(-23vw) translateY(-47vh) scale(0.49);
  }

  55.5555555556% {
    transform: translateX(2vw) translateY(39vh) scale(0.74);
  }

  56.1111111111% {
    transform: translateX(-42vw) translateY(-27vh) scale(0.95);
  }

  56.6666666667% {
    transform: translateX(8vw) translateY(48vh) scale(0.89);
  }

  57.2222222222% {
    transform: translateX(-47vw) translateY(30vh) scale(1);
  }

  57.7777777778% {
    transform: translateX(29vw) translateY(13vh) scale(0.36);
  }

  58.3333333333% {
    transform: translateX(35vw) translateY(-1vh) scale(0.27);
  }

  58.8888888889% {
    transform: translateX(-23vw) translateY(2vh) scale(0.29);
  }

  59.4444444444% {
    transform: translateX(18vw) translateY(-39vh) scale(0.83);
  }

  60% {
    transform: translateX(10vw) translateY(29vh) scale(0.69);
  }

  60.5555555556% {
    transform: translateX(14vw) translateY(25vh) scale(0.76);
  }

  61.1111111111% {
    transform: translateX(35vw) translateY(-5vh) scale(0.35);
  }

  61.6666666667% {
    transform: translateX(-17vw) translateY(-48vh) scale(0.99);
  }

  62.2222222222% {
    transform: translateX(-25vw) translateY(-11vh) scale(0.69);
  }

  62.7777777778% {
    transform: translateX(-9vw) translateY(2vh) scale(0.46);
  }

  63.3333333333% {
    transform: translateX(24vw) translateY(47vh) scale(0.4);
  }

  63.8888888889% {
    transform: translateX(32vw) translateY(-49vh) scale(0.58);
  }

  64.4444444444% {
    transform: translateX(-22vw) translateY(-25vh) scale(0.3);
  }

  65% {
    transform: translateX(2vw) translateY(25vh) scale(0.86);
  }

  65.5555555556% {
    transform: translateX(-46vw) translateY(14vh) scale(0.4);
  }

  66.1111111111% {
    transform: translateX(27vw) translateY(13vh) scale(0.63);
  }

  66.6666666667% {
    transform: translateX(-11vw) translateY(34vh) scale(0.73);
  }

  67.2222222222% {
    transform: translateX(-34vw) translateY(-2vh) scale(0.31);
  }

  67.7777777778% {
    transform: translateX(25vw) translateY(43vh) scale(0.84);
  }

  68.3333333333% {
    transform: translateX(9vw) translateY(24vh) scale(0.97);
  }

  68.8888888889% {
    transform: translateX(41vw) translateY(5vh) scale(0.89);
  }

  69.4444444444% {
    transform: translateX(7vw) translateY(-4vh) scale(0.97);
  }

  70% {
    transform: translateX(47vw) translateY(-8vh) scale(0.75);
  }

  70.5555555556% {
    transform: translateX(-36vw) translateY(-31vh) scale(0.86);
  }

  71.1111111111% {
    transform: translateX(-22vw) translateY(-29vh) scale(0.97);
  }

  71.6666666667% {
    transform: translateX(-20vw) translateY(-14vh) scale(0.5);
  }

  72.2222222222% {
    transform: translateX(10vw) translateY(35vh) scale(0.56);
  }

  72.7777777778% {
    transform: translateX(30vw) translateY(-31vh) scale(0.91);
  }

  73.3333333333% {
    transform: translateX(-37vw) translateY(-3vh) scale(0.66);
  }

  73.8888888889% {
    transform: translateX(-35vw) translateY(14vh) scale(0.52);
  }

  74.4444444444% {
    transform: translateX(45vw) translateY(18vh) scale(0.59);
  }

  75% {
    transform: translateX(-14vw) translateY(-32vh) scale(0.36);
  }

  75.5555555556% {
    transform: translateX(-48vw) translateY(-5vh) scale(0.45);
  }

  76.1111111111% {
    transform: translateX(-4vw) translateY(41vh) scale(0.79);
  }

  76.6666666667% {
    transform: translateX(-29vw) translateY(8vh) scale(0.54);
  }

  77.2222222222% {
    transform: translateX(2vw) translateY(-41vh) scale(0.89);
  }

  77.7777777778% {
    transform: translateX(-17vw) translateY(-8vh) scale(0.86);
  }

  78.3333333333% {
    transform: translateX(-41vw) translateY(24vh) scale(0.54);
  }

  78.8888888889% {
    transform: translateX(49vw) translateY(-27vh) scale(0.69);
  }

  79.4444444444% {
    transform: translateX(-28vw) translateY(30vh) scale(0.26);
  }

  80% {
    transform: translateX(-25vw) translateY(-13vh) scale(0.83);
  }

  80.5555555556% {
    transform: translateX(-43vw) translateY(9vh) scale(0.93);
  }

  81.1111111111% {
    transform: translateX(43vw) translateY(48vh) scale(0.81);
  }

  81.6666666667% {
    transform: translateX(-6vw) translateY(21vh) scale(0.4);
  }

  82.2222222222% {
    transform: translateX(-40vw) translateY(36vh) scale(0.28);
  }

  82.7777777778% {
    transform: translateX(31vw) translateY(-36vh) scale(0.52);
  }

  83.3333333333% {
    transform: translateX(-44vw) translateY(-48vh) scale(0.39);
  }

  83.8888888889% {
    transform: translateX(-37vw) translateY(-22vh) scale(0.35);
  }

  84.4444444444% {
    transform: translateX(16vw) translateY(-11vh) scale(0.48);
  }

  85% {
    transform: translateX(41vw) translateY(15vh) scale(0.68);
  }

  85.5555555556% {
    transform: translateX(-5vw) translateY(24vh) scale(0.43);
  }

  86.1111111111% {
    transform: translateX(-42vw) translateY(-5vh) scale(0.38);
  }

  86.6666666667% {
    transform: translateX(11vw) translateY(-30vh) scale(0.34);
  }

  87.2222222222% {
    transform: translateX(-40vw) translateY(23vh) scale(0.98);
  }

  87.7777777778% {
    transform: translateX(-25vw) translateY(9vh) scale(0.68);
  }

  88.3333333333% {
    transform: translateX(-16vw) translateY(-39vh) scale(0.95);
  }

  88.8888888889% {
    transform: translateX(8vw) translateY(5vh) scale(0.8);
  }

  89.4444444444% {
    transform: translateX(-22vw) translateY(29vh) scale(0.82);
  }

  90% {
    transform: translateX(-44vw) translateY(-31vh) scale(0.56);
  }

  90.5555555556% {
    transform: translateX(-2vw) translateY(10vh) scale(0.8);
  }

  91.1111111111% {
    transform: translateX(-2vw) translateY(-27vh) scale(0.4);
  }

  91.6666666667% {
    transform: translateX(39vw) translateY(12vh) scale(0.43);
  }

  92.2222222222% {
    transform: translateX(-1vw) translateY(1vh) scale(1);
  }

  92.7777777778% {
    transform: translateX(-28vw) translateY(-40vh) scale(0.31);
  }

  93.3333333333% {
    transform: translateX(7vw) translateY(-1vh) scale(0.65);
  }

  93.8888888889% {
    transform: translateX(-49vw) translateY(13vh) scale(0.93);
  }

  94.4444444444% {
    transform: translateX(-13vw) translateY(23vh) scale(0.88);
  }

  95% {
    transform: translateX(-32vw) translateY(-36vh) scale(0.38);
  }

  95.5555555556% {
    transform: translateX(-7vw) translateY(-1vh) scale(0.51);
  }

  96.1111111111% {
    transform: translateX(23vw) translateY(-47vh) scale(0.8);
  }

  96.6666666667% {
    transform: translateX(-47vw) translateY(34vh) scale(0.71);
  }

  97.2222222222% {
    transform: translateX(-9vw) translateY(11vh) scale(0.54);
  }

  97.7777777778% {
    transform: translateX(50vw) translateY(44vh) scale(0.3);
  }

  98.3333333333% {
    transform: translateX(42vw) translateY(16vh) scale(0.57);
  }

  98.8888888889% {
    transform: translateX(-40vw) translateY(-7vh) scale(0.42);
  }

  99.4444444444% {
    transform: translateX(26vw) translateY(-2vh) scale(0.5);
  }

  100% {
    transform: translateX(-23vw) translateY(-25vh) scale(0.92);
  }
}

@keyframes drift {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {

  0%,
  30%,
  100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
  }

  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow;
  }
}