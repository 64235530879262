.no-longer-active__component {
  position: relative;
  z-index: 4;
  min-height: 60px;
  background: black;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;

  &__wrapper {
    display: flex;
    width: 100%;
    place-content: space-between;
    max-width: 1100px;
    padding: 16px;
    gap: 16px;

    @media (max-width: 790px) {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1401px) {
    top: 50px;
  }

  @media (min-width: 1402px) {
    top: 94px;
  }
}