.founder-popup .content {
  background: #fff;
  max-width: 520px;
  width: 100%;
  padding: 2rem 3rem;
  position: relative;
  border: 1px solid #dddddd;

  &__contact-links__wrapper {
    display: flex;
    place-content: center;
  }

  &__contact-title {
    padding-top: 1rem;
  }

  &__contact-links {
    list-style-type: none;
    padding: 0;
    display: flex;
    margin: 0;
  }

  p {
    text-align: center;
  }

  &__links {
    padding: 1rem 0;
  }

  svg {
    height: 30px;
    width: 30px;
    opacity: 0.6;
    padding: 0 0.5rem;

    &:hover {
      opacity: 1;
    }
  }
}

.om-oss {
  background: #f6f6f6;

  &__header {
    overflow: hidden;
    position: relative;
    padding-top: 44px;
    min-height: 400px;
    background-size: 400% 400%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: black;

    // @media (max-width: 912px) {
    //   min-height: 100%;
    //   height: 220px;
    //   max-height: 220px;
    // }

    h1 {
      text-align: center;
      line-height: 1;
      z-index: 1;
      font-size: 2rem;
      font-weight: 700;

      @media (max-width: 600px) {
        font-size: 1.6rem;
      }
    }

    p {
      z-index: 1;
      text-align: center;
      padding: 0 2rem;
    }
  }

  &__header__content {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    // align-items: center;
  }

  .founder {
    position: absolute;
    height: calc(100% - 94px);
    // width: 300px;
    padding-top: 94px;
    top: 0;
    // opacity: 0.8;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    @media (min-width: 992px) and (max-width: 1401px) {
      height: calc(100% - 50px);
      padding-top: 50px;
    }

    @media (max-width: 991px) {
      height: calc(100% - 100px);
      padding-top: 100px;
    }

    &__text-content {
      margin-top: 70px;
      display: flex;
      position: relative;
      z-index: 1;

      @media (max-width: 992px) {
        margin-top: 20px;
      }

      &--left {
        justify-content: flex-start;
        margin-left: 78px;
      }

      &--right {
        justify-content: flex-end;
        margin-right: 78px;
      }
    }

    &__text-content__title {
      font-size: 0.9rem;
      display: flex;

      &--left {
        justify-content: left;
      }

      &--right {
        justify-content: right;
      }
    }

    &__text-content__name {
      font-size: 1.1rem;
      padding-top: 0.5rem;
      font-family: "Dancing Script", cursive;

      &--left {
        justify-content: left;
      }

      &--right {
        justify-content: right;
      }
    }

    &__text-content__button {
      background: #606060;
      border-radius: 11px;
      color: white;
      padding: 0.2rem 0.5rem;
      font-size: 11px;
      margin: 0.5rem 0;

      &--left {
        float: right;
      }

      &--right {
        float: right;
      }

      &:hover {
        background: #4e4c4c;
      }
    }

    &__image {
      position: absolute;
      top: 0;
      height: calc(100% - (94px + 20px));
      padding-top: calc(94px + 20px);
      opacity: 0.6;

      &--right {
        right: 0;
      }

      @media (min-width: 992px) and (max-width: 1401px) {
        height: calc(100% - (50px + 20px));
        padding-top: calc(50px + 20px);
      }

      @media (max-width: 991px) {
        height: calc(100% - (100px + 20px));
        padding-top: calc(100px + 20px);
      }
    }
  }

  .om-oss__information {
    padding: 5rem 2rem;
    display: flex;
    flex-direction: column;
    place-items: center;

    &__title {
      text-align: center;
      line-height: 1;
      font-weight: 700;

      @media (max-width: 600px) {
        font-size: 1.4rem;
      }
    }

    &__text {
      text-align: center;
      max-width: 1200px;
    }
  }

  #intro {
    background: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    min-height: calc(30vh - 6rem);

    .container {
      display: flex;
      justify-content: space-between;

      @media (max-width: 900px) {
        flex-direction: column;
        padding: 0;
      }

      > p {
        @media (min-width: 901px) {
          width: calc(50% - 1.5rem);
        }
      }
    }

    h2 {
      max-width: 720px;
      font-weight: 500;
      margin: 0;

      span {
        display: block;
        margin-top: 1rem;
        font-size: 1.2rem;
        font-weight: 400;

        i {
          font-style: italic;
          display: block;
          margin-top: 0.5rem;
          min-height: 35px;

          @media (max-width: 400px) {
            min-height: 70px;
          }
        }
      }
    }
  }

  #partners {
    > div {
      max-width: 1100px;
      padding: 1px;
      margin: 0 auto;
      display: flex;

      @media (max-width: 900px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        margin: 2px;
        width: calc(100% / 3 - 4px);

        @media (max-width: 900px) {
          height: 120px;

          &:nth-child(3) {
            border-right: 0;
          }

          &:nth-child(4),
          &:nth-child(5) {
            border-bottom: 0;
          }
        }

        @media (min-width: 901px) {
          width: calc(100% / 3);
        }

        &:hover {
          svg {
            opacity: 0.5;
          }
        }
      }

      svg {
        width: 80px;
        height: 50px;
        transition: 0.3s ease-in-out;

        @media (min-width: 600px) {
          width: 120px;
          height: 60px;
        }

        path,
        circle,
        rect,
        polygon {
          fill: black;
        }
      }
    }
  }

  #follow-our-journey {
    color: black;

    .section-title {
      background: black;
    }

    .content-wrapper {
      min-height: 150px;
      padding: 1rem 0rem;
      // background-color: darkred;
      display: flex;
      place-content: space-around;

      @media (max-width: 900px) {
        flex-direction: column;
      }
    }

    .text {
      // align-self: center;
      max-width: 20rem;
      // margin: 0rem 2rem;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      align-self: center;

      @media (max-width: 900px) {
        align-self: start;
      }

      input {
        font-size: 1.1rem;
        font-weight: 400;
        transition: 0.3s ease-in-out;
        color: black;
        background: white;
        border-radius: unset;
        height: 26px;
        padding: 1px 6px;

        @media (min-width: 420px) {
          width: 15rem;
        }

        &:focus {
          background: white;
          // border: none;
          outline: none;
        }

        &::placeholder {
          color: black;
        }

        &:-ms-input-placeholder {
          color: black;
        }

        &::-ms-input-placeholder {
          color: black;
        }
      }
      button {
        height: 32px;
        background: #008484;
        color: white;
        font-size: 1.1rem;
      }
    }
  }

  #team {
    > div {
      display: flex;
      flex-wrap: wrap;
      padding: 1px;

      article {
        height: 320px;
        position: relative;
        width: calc(50% - 4px);
        overflow: hidden;
        margin: 2px;

        @media (min-width: 860px) {
          width: calc(100% / 3 - 4px);
          height: 320px;
        }

        @media (min-width: 1150px) {
          width: calc(100% / 4 - 4px);
          height: 420px;
        }

        .card-content {
          height: 320px;
          position: relative;

          @media (min-width: 1150px) {
            height: 420px;
          }

          .card-image {
            height: 100%;
            width: 100%;
            opacity: 0.25;

            div {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;

              > span {
                overflow: visible !important;
                position: unset !important;
              }

              img {
                object-fit: cover !important;
                height: 100% !important;
                width: 100% !important;
                transition: 0.3s ease-in-out;
              }
            }
          }

          .card-titles {
            position: absolute;
            left: 2rem;
            bottom: 2rem;
            color: white;
            transition: 0.3s ease-in-out;

            @media (max-width: 500px) {
              left: 1rem;
              bottom: 1rem;
            }

            h3 {
              font-size: 1.4rem;

              @media (max-width: 500px) {
                margin: 0;

                span {
                  display: none;
                }
              }
            }

            > span {
              font-size: 1.2rem;

              @media (max-width: 400px) {
                font-size: 1.1rem;
              }
            }
          }

          button {
            display: none;

            @media (max-width: 1500px) {
              display: block;
            }
          }

          .card-content-meta {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            @media (max-width: 1500px) {
              display: none;
            }
          }
        }

        .back {
          position: absolute;
          background: rgba(#222, 0.75);
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          pointer-events: none;
          opacity: 0;
          transition: 0.3s ease-in-out;

          .card-content {
            padding: 2rem;
            color: white;
            display: flex;
            flex-direction: column;
            height: calc(320px - 4rem);

            @media (min-width: 1150px) {
              height: calc(420px - 4rem);
            }

            @media (max-width: 500px) {
              height: calc(320px - 2rem);
              padding: 1rem;
            }

            button {
              border: 0;
              font-size: 14px;
              padding: 0 1rem;
              height: 35px;
              background: #333;
              border-radius: 3rem;
              color: white;
              position: absolute;
              cursor: pointer;
              bottom: 1rem;
              right: 1rem;

              @media (min-width: 501px) {
                bottom: 2rem;
                right: 2rem;
              }

              &:hover {
                background: dark(#e41513, 10%);
              }
            }

            p {
              font-size: 1.1rem;
              margin: 0;
              margin-bottom: 1rem;

              @media (min-width: 2000px) {
                margin-bottom: 2rem;
                font-size: 1.2rem;
              }
            }

            ul {
              padding: 0;
              margin: 0;
              list-style: none;

              &:empty {
                display: none;
              }

              li {
                border-top: 1px solid rgba(white, 0.1);
                padding-top: 0.5rem;
                margin-top: 0.5rem;
                color: rgba(white, 0.75);

                b {
                  color: white;
                  margin-right: 0.75;
                  display: inline-block;
                }

                a {
                  color: rgba(white, 0.75);
                }

                &:first-child {
                  border-top: 0;
                  padding-top: 0;
                  margin-top: 0;
                }
              }
            }

            .link-wrapper {
              margin-top: auto;
              display: flex;

              @media (min-width: 1651px) {
                justify-content: space-between;
              }

              a {
                background: #e41513;
                padding: 0 1rem;
                height: 35px;
                display: block;
                color: white;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 3rem;
                transition: 0.3s ease-in-out;

                @media (max-width: 1650px) {
                  margin-right: 1rem;
                }

                @media (max-width: 1500px) {
                  padding: 0;
                  width: 35px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg {
                    margin: 0 !important;
                  }
                }

                &.linkedin {
                  background: #0a66c2;
                  width: 35px;
                  height: 35px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 0;
                  padding: 0;

                  &:hover {
                    background: darken(#0a66c2, 10%);
                  }

                  svg {
                    margin: 0;
                  }
                }

                &:hover {
                  background: darken(#e41513, 10%);
                }

                span {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;

                  @media (max-width: 1650px) {
                    max-width: 110px;
                  }

                  @media (max-width: 1500px) {
                    display: none;
                  }
                }

                svg {
                  height: 17px;
                  margin-right: 0.75rem;

                  path {
                    fill: white;
                  }
                }
              }
            }
          }
        }

        &.join-us {
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: calc(100% / 2 - 4px);

          @media (min-width: 1680px) {
            width: calc(100% / 3 - 4px);
          }

          @media (min-width: 860px) and (max-width: 1150px) {
            width: calc(100% / 3 - 4px);
            height: 320px;
          }

          @media (max-width: 860px) {
            width: calc(100% - 4px);
            height: 250px;
          }

          a {
            text-align: center;
            max-width: 80%;

            h4 {
              font-size: 1.4rem;
              font-weight: 500;
              margin: 0;
              color: white;
            }

            p {
              font-size: 1.2rem;
              margin: 0 auto;
              width: 100%;
              max-width: 200px;
              margin-top: 1rem;
              color: white;

              @media (min-width: 1150px) {
                max-width: 260px;
              }
            }
          }
        }

        &:hover {
          .back {
            pointer-events: all;
            opacity: 1;
          }

          .card-image {
            img {
              filter: grayscale(50%);
            }
          }

          .card-titles {
            opacity: 0;
          }
        }

        // jimi
        &:last-child {
          .card-content {
            display: flex;
            align-items: center;
            justify-content: center;

            button,
            .card-content-meta {
              display: none;
            }

            p {
              font-size: 1.8rem;
            }
          }
        }

        &.active {
          p {
            display: none;
          }

          .card-content-meta {
            display: flex;
          }
        }
      }
    }
  }
}

.contact-dialog {
  .MuiPaper-root {
    border-radius: 0;
    padding: 1rem;
    text-align: center;
    box-shadow: none !important;
  }
}
